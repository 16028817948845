import { ECBox } from 'app/components/ECBox';
import { ECGrid } from 'app/components/ECGrid';
import emptyStateImage from './assets/emptyState.svg';
import emptyStateLocationAssignment from './assets/emptyStateLocationAssignment.svg';

export enum EmptyType {
  EMPTY_STATE = 'emptyState',
  EMPTY_STATE_LOCATION_ASSIGNMENT = 'emptyStateLocationAssignment',
}

export interface EmptyStatePageProps {
  emptyType?: EmptyType;
}

export const EmptyStatePage = ({
  emptyType = EmptyType.EMPTY_STATE,
}: EmptyStatePageProps) => {
  const mapEmptyTypeToImage = {
    [EmptyType.EMPTY_STATE]: emptyStateImage,
    [EmptyType.EMPTY_STATE_LOCATION_ASSIGNMENT]: emptyStateLocationAssignment,
  };

  return (
    <ECBox
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <ECGrid item xs={12}>
        <img src={mapEmptyTypeToImage[emptyType]} alt="Empty State" />
      </ECGrid>
      <ECGrid item xs={12}>
        {/* <ECTypography>{'Empty'}</ECTypography> */}
      </ECGrid>
    </ECBox>
  );
};
