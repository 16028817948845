import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['subscription', 'SubscriptionTrial'],
});

export const subscriptionApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    cancelSubscription: builder.mutation<void, { id: number }>({
      query: body => {
        return {
          url: '/subscription/cancel/' + body.id,
          method: 'post',
        };
      },
    }),
  }),
});

export const { useCancelSubscriptionMutation } = subscriptionApi;
