import { useCallback, useEffect, useState } from 'react';
import { ECChipAutocomplete } from '../ECChipAutocomplete';
import { ECFormControl } from '../ECForm';
import { ECGrid } from '../ECGrid';
import { ECSelect } from '../ECSelect';
import { ECStack } from '../ECStack';
import { ECTypography } from '../ECTypography';
import { ECBox } from '../ECBox';
import { ECChip } from '../ECChip';
import { ECBulletPoints } from '../ECBulletPoints';
import { useGetAssetTypesListQuery } from 'services/assetTypeApi';
import { useGetProblemListQuery } from 'services/problemApi';
import { useGetTradesListQuery } from 'services/tradeApi';
import { useGetAssetTradeProblemQuery } from 'services/assetTradeProblemApi';
import * as _ from 'lodash';
import { ECAutocomplete } from '../ECAutocomplete';
import { InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AssetTradeProblem } from 'types/AssetTradeProblem';
import { ECAutocompletePaginated } from '../ECAutocompletePaginated';
import { AssetTypeList } from 'types/AssetType';

export interface ECAssetTradeProblemCreateProps {
  existingData?: AssetTradeProblem[];
  errorMessage?: string;
  onChange?: (output: any) => void;
}

interface TradeProblemOutput {
  tradeName: string;
  problems: string[];
}

export interface AsseTradeProblemOutput {
  assetType: AssetTypeList;
  trades?: TradeProblemOutput[];
}
const DEFAULT_PARAMS = { t: 1600 };

export const ECAssetTradeProblemCreate = ({
  existingData,
  errorMessage,
  onChange,
}: ECAssetTradeProblemCreateProps) => {
  const { t } = useTranslation();
  const [output, setOutput] = useState<AsseTradeProblemOutput | null>(null);
  const [trades, setTrades] = useState<string[]>([]);
  const [problems, setProblems] = useState<string[]>([]);

  const { data: problemsList, isSuccess: isSuccessProblemsList } =
    useGetProblemListQuery(DEFAULT_PARAMS);

  const { data: tradesList, isSuccess: isSuccessTradesList } =
    useGetTradesListQuery(DEFAULT_PARAMS);

  const { data: assetTradeProblem } =
    useGetAssetTradeProblemQuery(DEFAULT_PARAMS);

  useEffect(() => {
    if (tradesList && isSuccessTradesList) {
      const selectedAssetTradeProblem = assetTradeProblem?.data?.find(
        assetTradeProblem =>
          assetTradeProblem.assetType === output?.assetType?.name,
      );

      const allTrades: string[] = _.map(
        tradesList.data.filter(
          trade =>
            !_.map(
              selectedAssetTradeProblem?.tradeProblems,
              'tradeName',
            ).includes(trade.name),
        ),
        'name',
      );
      const availableTradesToAdd: string[] = allTrades.filter(
        trade => !_.map(output?.trades, 'tradeName').includes(trade),
      );

      setTrades(availableTradesToAdd);
    }
  }, [tradesList, assetTradeProblem, isSuccessTradesList, output]);

  useEffect(() => {
    if (problemsList && isSuccessProblemsList) {
      const selectedAssetTradeProblem = assetTradeProblem?.data?.find(
        assetTradeProblem =>
          assetTradeProblem.assetType === output?.assetType?.name,
      );

      const selectedProblems = _.chain(output?.trades)
        .map('problems')
        .flatten()
        .map('label')
        .value();
      const previouslyAddedProblems = _.chain(
        selectedAssetTradeProblem?.tradeProblems,
      )
        .map('problems')
        .flatten()
        .map('name')
        .value();

      const allProblems: string[] = _.map(problemsList.data, 'name');

      const availableProblemsToAdd: string[] = _.difference(allProblems, [
        ...selectedProblems,
        ...previouslyAddedProblems,
      ]);

      setProblems(availableProblemsToAdd);
    }
  }, [
    problemsList,
    isSuccessProblemsList,
    assetTradeProblem,
    existingData,
    output,
  ]);

  useEffect(() => {
    onChange?.(output);
  }, [output]);

  const handleDeleteTrade = useCallback(
    trade => () => {
      if (output) {
        const updatedTrades =
          output?.trades?.filter(
            selectedTrade => selectedTrade.tradeName !== trade.tradeName,
          ) ?? output?.trades;

        setOutput({
          ...output,
          trades: updatedTrades,
        });
      }
    },
    [output],
  );

  const handleAddProblemToTrade = useCallback(
    (trade, problems) => {
      if (output) {
        const updatedTrades: TradeProblemOutput[] =
          output?.trades?.map(selectedTrade => {
            if (selectedTrade.tradeName === trade.tradeName) {
              return {
                ...selectedTrade,
                problems,
              };
            }
            return selectedTrade;
          }) ?? [];

        setOutput({
          ...output,
          trades: updatedTrades,
        });
      }
    },
    [output],
  );

  return (
    <ECBox display="flex" flexDirection="column" sx={{ width: '100%' }}>
      <ECBulletPoints>
        <ECFormControl sx={{ width: '100%' }}>
          <ECAutocompletePaginated
            sx={{ width: '100%' }}
            fieldName="assetType"
            value={output?.assetType}
            placeholder={t('translation:assetTradeProblem.asset.placeholder')}
            variant="filled"
            useQuery={useGetAssetTypesListQuery}
            obAlias="assttp.name"
            onChange={newValue => {
              setOutput({
                assetType: newValue,
                trades: [],
              });
            }}
            error={!!errorMessage}
            validationMessage={
              errorMessage && !output?.assetType ? errorMessage : undefined
            }
          />
        </ECFormControl>

        <ECTypography mt={1} variant="subtitle2" alignSelf="center">
          {t('translation:assetTradeProblem.description')}
        </ECTypography>

        {output?.trades?.map(trade => (
          <ECGrid container mt={2} columnSpacing={2}>
            <ECGrid item xs>
              <ECBox display="flex" flex={1} height="100%" alignItems="center">
                <ECChip
                  variant="outlined"
                  label={trade.tradeName}
                  onDelete={handleDeleteTrade(trade)}
                />
              </ECBox>
            </ECGrid>

            <ECGrid item xs>
              <ECChipAutocomplete
                options={problems.map(problem => ({ label: problem }))}
                variant="filled"
                title={t('translation:assetTradeProblem.problem.label')}
                placeholder={t(
                  'translation:assetTradeProblem.problem.placeholder',
                )}
                onChange={newProblems =>
                  handleAddProblemToTrade(
                    trade,
                    newProblems.map(newProblem => ({
                      label: newProblem.label,
                    })),
                  )
                }
                errorMessage={
                  errorMessage &&
                  _.isEmpty(
                    output.trades?.find(
                      selectedTrade =>
                        selectedTrade.tradeName === trade.tradeName,
                    )?.problems,
                  )
                    ? errorMessage
                    : undefined
                }
              />
            </ECGrid>
          </ECGrid>
        ))}

        {output?.assetType && trades.length > 0 && (
          <ECStack width="100%" mt={2} direction="row" spacing={2}>
            <ECAutocomplete
              disablePortal
              options={trades}
              placeholder={t('translation:assetTradeProblem.trade.placeholder')}
              label={t('translation:assetTradeProblem.trade.label')}
              onChange={(event, newValue) => {
                const updatedTrades = [
                  ...(output?.trades || []),
                  { tradeName: newValue, problems: [] },
                ];

                setOutput({
                  ...output,
                  trades: updatedTrades,
                });
              }}
            />

            <ECFormControl sx={{ width: '100%' }}>
              <InputLabel>
                {t('translation:assetTradeProblem.problem.label')}
              </InputLabel>
              <ECSelect
                label={t('translation:assetTradeProblem.problem.label')}
                variant="filled"
                disabled
              />
            </ECFormControl>
          </ECStack>
        )}
      </ECBulletPoints>
    </ECBox>
  );
};
