import { ECTabContainer } from '../../components';
import { StyleConstants } from 'styles/StyleConstants';
import { ManagedAssetsPage } from '../ManagedAssets';
import { P } from 'types/Permission';
import { useCustomerUser } from 'app/hooks/customerUser.use-case';
import { ForbiddenPage } from '../ForbiddenPage';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

export function AssetTabsPage() {
  const isCustomer = useCustomerUser();
  const { t } = useTranslation();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Helmet
        titleTemplate={`${t('translation:pages.assets.title')} - %s`}
        defaultTitle={t('translation:pages.assets.title')}
      />
      <ECTabContainer
        tabsAsLinks
        mt={StyleConstants.NAV_BAR_HEIGHT}
        p={2}
        variant="standard"
        color="marketingSuggestion"
        noPadding
        tabs={[
          {
            value: 'managed-assets',
            label: 'Managed Assets',
            content: <ManagedAssetsPage marginTop={false} />,
            scopes: [P.MenuManagedAssets],
            scores: [P.MenuManagedAssets],
            link: 'managed-assets',
          },
          {
            value: 'general-assets',
            label: 'General Assets',
            content: <Outlet />,
            scopes: [P.GetAllGeneralAssets],
            scores: [P.GetAllGeneralAssets],
            link: 'general-assets',
          },
        ]}
      />
    </>
  );
}
