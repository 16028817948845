import { ECUsersTable } from '../ECUsersTable';
import { ECBox } from '../ECBox';
import { useGetInternalReviewCustomersQuery } from 'services/workOrdersApi';

export interface Props {
  customModalTitle?: string;
}

export function ECAssignedReviewersTableModal({ customModalTitle }: Props) {
  const { data, isFetching, isLoading } = useGetInternalReviewCustomersQuery(
    {},
  );

  const modifiedData = data?.map((item: any) => {
    return {
      ...item,
      fullName: item?.firstName + ' ' + item?.lastName,
      email: item?.email,
      jobTitle: {
        name: item?.jobTitle,
        id: item?.jobTitleId,
      },
    };
  });

  return (
    <ECBox id={`popover-users-table`}>
      <ECUsersTable
        users={modifiedData || []}
        isLoading={isFetching || isLoading}
        hideSearchHeadContent={true}
        customModalTitle={customModalTitle}
      />
    </ECBox>
  );
}
