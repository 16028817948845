export interface TimeTrackStatusChangeParams {
  prevStatus: number;
  currentStatus: number;
  woId?: number;
  trackTime: Date;
}

export interface TimeTrackData {
  id: number;
  WOId: number;
  currentStatus: number;
  icon?: string;
  description?: string;
  name: TimeTrackStatus;
  prevStatus?: number;
  trackTime: string;
  userId: number;
}

export interface NextTimeTrackStatusData {
  WOId?: number;
  icon?: string;
  description?: string;
  name: TimeTrackStatus;
  statusToId: number;
}

export interface TimeTrackStatusResponse {
  currentStatus: TimeTrackData;
  nextStatus: NextTimeTrackStatusData[];
}

export enum TimeTrackStatus {
  ClockIn = 'Clock In',
  ClockOut = 'Clock Out',
  CheckIn = 'Check In',
  CheckOut = 'Check Out',
  StartDrive = 'Start Drive',
  EndDrive = 'End Drive',
  StartBreak = 'Start Break',
  EndBreak = 'End Break',
}
