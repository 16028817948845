import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ECBox,
  ECButton,
  ECIconButton,
  ECStack,
  ECTabContainer,
  ECTextField,
  ECTree,
  ECTypography,
  TreeNode,
} from 'app/components';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { useTranslation } from 'react-i18next';
import { useCreateCommentMutation } from 'services/commentApi';
import { P } from 'types/Permission';
import _ from 'lodash';
import {
  ArrowDropDown,
  ArrowDropUp,
  Close,
  Notifications,
} from '@mui/icons-material';
import { CircularProgress, Popover } from '@mui/material';
import { findNode, findNodeById } from 'utils/tree';
import { useCustomerUser } from 'app/hooks/customerUser.use-case';
import { useGetUsersByRoleNameAndModuleIdQuery } from 'services/userApi';
import { useGetModuleDetailsByIdQuery } from 'services/moduleApi';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { ECRichTextField } from 'app/components/ECRichTextField';
import { DashboardActionProps } from 'types/Dashboard';

const SEARCH_DEBOUNCE_TIME = 500;

function AddNoteContent({
  onClose,
  onCancel,
  selectedRows,
  module: moduleName,
}: DashboardActionProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = selectedRows?.[0]?.id;

  const isCustomer = useCustomerUser();

  const [initialTree, setInitialTree] = useState<any[]>([]);
  const [moduleRequestId, setModuleRequestId] = useState<any>();
  const [tree, setTree] = useState<TreeNode[]>([
    {
      label: '',
      nodeId: 0,
      organizationType: 0,
      parentNodeId: 0,
      children: [],
    },
  ]);
  const [nodesIds, setNodesIds] = useState<number[]>([]);
  const [currentCompanyInitialTree, setCurrentCompanyInitialTree] = useState<
    any[]
  >([]);
  const [currentCompanyTree, setCurrentCompanyTree] = useState<TreeNode[]>([
    {
      label: '',
      nodeId: 0,
      organizationType: 0,
      parentNodeId: 0,
      children: [],
    },
  ]);
  const [currentCompanyNodesIds, setCurrentCompanyNodesIds] = useState<
    number[]
  >([]);

  const { data: moduleData } = useGetModuleDetailsByIdQuery({
    id: id || '',
    moduleName: `${isCustomer ? '' : 'sp/'}${moduleName || ''}`,
  });

  useEffect(() => {
    if (moduleData !== undefined) {
      setModuleRequestId(
        moduleName != 'asset' ? moduleData?.requestId : moduleData?.id,
      );
    }
  }, [moduleData, moduleName]);

  const {
    data: allUsers,
    isSuccess: isSuccessUsers,
    isLoading: isLoadingUsers,
  } = useGetUsersByRoleNameAndModuleIdQuery({
    moduleName,
    role: 'DIRECT_NOTES',
    userType: 'CSP',
    moduleId: id,
  });

  const users = useMemo(() => {
    if (isCustomer) {
      return allUsers?.customersInfo;
    }
    return allUsers?.spUsersInfo;
  }, [allUsers, isCustomer]);

  const otherUsers = useMemo(() => {
    if (isCustomer) {
      return allUsers?.spUsersInfo;
    }
    return allUsers?.customersInfo;
  }, [allUsers, isCustomer]);

  const usersToMention = useMemo(() => {
    return [
      ...(allUsers?.customersInfo || []),
      ...(allUsers?.spUsersInfo || []),
    ].map(user => {
      return {
        id: user.id,
        name: user.fullName,
        jobTitle: user.jobTitle?.name,
      };
    });
  }, [allUsers?.customersInfo, allUsers?.spUsersInfo]);

  const notifiedCollapsedRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const handleCloseNotifiedModal = () => {
    setAnchorEl(null);
  };

  const [doCreate, { isLoading: isLoadingCreate, isSuccess, isError }] =
    useCreateCommentMutation();

  const selectedUsers: TreeNode[] = useMemo(() => {
    return _.compact(
      _.filter(
        nodesIds.map(nodeId => findNodeById(initialTree, nodeId)),
        node => !node?.children?.length && !!node,
      ),
    );
  }, [nodesIds, initialTree]);

  const selectedCurrentCompanyUsers: TreeNode[] = useMemo(() => {
    return _.compact(
      _.filter(
        currentCompanyNodesIds.map(nodeId =>
          findNodeById(currentCompanyInitialTree, nodeId),
        ),
        node => !node?.children?.length && !!node,
      ),
    );
  }, [currentCompanyNodesIds, currentCompanyInitialTree]);

  const [newComment, setNewComment] = useState('');
  const isEmptyHtmlComment = !newComment
    ?.replaceAll('<p>', '')
    .replaceAll('</p>', '')
    .replaceAll('&nbsp;', '')
    .replaceAll('\n', '')?.length;

  const handleSend = useCallback(() => {
    if (isEmptyHtmlComment) return;

    const normalizedTextWithMention = newComment?.replace(
      /<span data-type="mention" data-id="(\d+)" data-label="[^"]*">@[^<]*<\/span>/g,
      (_, id) => `@[${id}]`,
    );

    const recipientIds = [
      ...selectedUsers,
      ...selectedCurrentCompanyUsers,
    ]?.map(selectedUser => selectedUser.nodeId);

    doCreate({
      requestId: moduleRequestId,
      module: moduleName,
      moduleResourceId: moduleData?.id,
      body: normalizedTextWithMention,
      recipientIds,
    });
    setCurrentCompanyNodesIds([]);
    setNodesIds([]);
    setNewComment('');
  }, [
    moduleName,
    moduleData?.id,
    moduleRequestId,
    doCreate,
    newComment,
    selectedUsers,
    selectedCurrentCompanyUsers,
    isEmptyHtmlComment,
  ]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'The comment was successfully added',
        }),
      );
      onClose();
    } else if (isError) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: 'Error sending comment',
        }),
      );
      onClose();
    }
  }, [dispatch, isError, isSuccess, onClose]);

  const mappedCurrentCompanyTree: TreeNode[] = useMemo(() => {
    const usersGroupedByJobTitle = _.chain(users)
      .filter(user => !!user?.jobTitleId)
      .groupBy('jobTitle.id')
      .map((value, key) => ({
        jobTitle: value[0].jobTitle,
        users: value,
      }))
      .value();

    return usersGroupedByJobTitle.map(usersByJobTitle => {
      const nodeId = usersByJobTitle.jobTitle?.id
        ? new Date().getTime() + usersByJobTitle.jobTitle.id
        : 0;

      return {
        label: `${usersByJobTitle.jobTitle?.name} (${usersByJobTitle.users.length})`,
        nodeId,
        parentNodeId: undefined,
        children: usersByJobTitle.users.map(user => ({
          label: `${user.firstName} ${user.lastName}`,
          data: {
            email: user.email,
          },
          nodeId: user.id,
          parentNodeId: nodeId,
          children: [],
        })),
      };
    });
  }, [users]);

  const mappedTree: TreeNode[] = useMemo(() => {
    const usersGroupedByJobTitle = _.chain(otherUsers)
      .filter(user => !!user?.jobTitleId)
      .groupBy('jobTitle.id')
      .map((value, key) => ({
        jobTitle: value[0].jobTitle,
        users: value,
      }))
      .value();

    return usersGroupedByJobTitle.map(usersByJobTitle => {
      const nodeId = usersByJobTitle.jobTitle?.id
        ? new Date().getTime() + usersByJobTitle.jobTitle.id
        : 0;

      return {
        label: `${usersByJobTitle.jobTitle?.name} (${usersByJobTitle.users.length})`,
        nodeId,
        parentNodeId: undefined,
        children: usersByJobTitle.users.map(user => ({
          label: `${user.firstName} ${user.lastName}`,
          data: {
            email: user.email,
          },
          nodeId: user.id,
          parentNodeId: nodeId,
          children: [],
        })),
      };
    });
  }, [otherUsers]);

  useEffect(() => {
    if (mappedTree && isSuccessUsers) {
      setTree(mappedTree);
      setInitialTree(mappedTree);
    }
  }, [isSuccessUsers, mappedTree]);

  useEffect(() => {
    if (mappedCurrentCompanyTree && isSuccessUsers) {
      setCurrentCompanyTree(mappedCurrentCompanyTree);
      setCurrentCompanyInitialTree(mappedCurrentCompanyTree);
    }
  }, [isSuccessUsers, mappedCurrentCompanyTree]);

  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (search === undefined) {
      return;
    }

    if (mappedTree) {
      if (search === '') {
        setTree(mappedTree);
        return;
      }

      const filteredNodes = findNode(mappedTree, search, ['email']);
      setTree(filteredNodes);
    }
  }, [search, mappedTree]);

  const debouncedHandleSearchChange = useRef(
    _.debounce(e => {
      setSearch(e.target.value);
    }, SEARCH_DEBOUNCE_TIME),
  ).current;

  const [currentCompanySearch, setCurrentCompanySearch] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (currentCompanySearch === undefined) {
      return;
    }

    if (mappedCurrentCompanyTree) {
      if (currentCompanySearch === '') {
        setCurrentCompanyTree(mappedCurrentCompanyTree);
        return;
      }

      const filteredNodes = findNode(
        mappedCurrentCompanyTree,
        currentCompanySearch,
        ['email'],
      );
      setCurrentCompanyTree(filteredNodes);
    }
  }, [currentCompanySearch, mappedCurrentCompanyTree]);

  const debouncedHandleCurrentCompanySearchChange = useRef(
    _.debounce(e => {
      setCurrentCompanySearch(e.target.value);
    }, SEARCH_DEBOUNCE_TIME),
  ).current;

  const handleOnMentionUser = useCallback(
    (user: any) => {
      const isTaggedUserCustomer = allUsers?.customersInfo?.some(
        u => u.id === user.id,
      );

      if (isTaggedUserCustomer) {
        //customers
        setCurrentCompanyNodesIds(prev => {
          if (prev.includes(user.id)) {
            return prev;
          }
          return [...prev, user.id];
        });
      } else {
        setNodesIds(prev => {
          if (prev.includes(user.id)) {
            return prev;
          }
          return [...prev, user.id];
        });
      }
    },
    [setCurrentCompanyNodesIds, setNodesIds, allUsers?.customersInfo],
  );

  return (
    <ECBox
      display="flex"
      bgcolor={theme => theme.palette.background.default}
      flexDirection="column"
      height="100%"
      pt={3}
      pl={3}
      pr={3}
    >
      <ECBox display="flex" justifyContent="flex-start" mb={2}>
        <ECTypography variant="h4">Add Comment </ECTypography>
      </ECBox>
      <ECBox
        display="flex"
        flexDirection="column"
        maxHeight="50vh"
        flex={1}
        gap={2}
      >
        <ECBox display="flex" flexDirection="column" mb={2}>
          {['workorder', 'proposal', 'invoice'].includes(moduleName) && (
            <ECBox display="flex" justifyContent="flex-end">
              <ECBox
                ref={notifiedCollapsedRef}
                display="flex"
                bgcolor={theme =>
                  theme.palette.marketingSuggestion.marketingSuggestion
                }
                px={1}
                height="48px"
                justifyContent="center"
                alignItems="center"
                gap={1}
                sx={{
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                }}
              >
                <Notifications
                  sx={theme => ({ color: theme.palette.common.white })}
                />
                <ECTypography color={theme => theme.palette.common.white}>
                  NOTIFIED:{' '}
                  {selectedUsers.length + selectedCurrentCompanyUsers.length}{' '}
                  Selected
                </ECTypography>

                <ECIconButton
                  size="small"
                  type="white"
                  noPadding
                  sx={theme => ({
                    color: theme.palette.common.white,
                    padding: 0,
                  })}
                  onClick={() => setAnchorEl(notifiedCollapsedRef.current)}
                >
                  {open ? <ArrowDropUp /> : <ArrowDropDown />}
                </ECIconButton>
              </ECBox>
            </ECBox>
          )}
          <ECRichTextField
            sx={{ width: '100%', borderColor: 'black', minWidth: '600px' }}
            placeholder={t('translation:richTextField.placeholder')}
            isLoading={isLoadingUsers || isLoadingCreate}
            onSend={handleSend}
            onChange={setNewComment}
            scopes={[P.AddComment]}
            disableSendButton={isLoadingUsers || isLoadingCreate}
            mentionSuggestionUsers={usersToMention}
            mentionOnSelect={handleOnMentionUser}
            moduleId={id}
            isSendButtonVisible={false}
          />
        </ECBox>
      </ECBox>

      <ECBox
        display="flex"
        width="100%"
        justifyContent="flex-end"
        alignItems="start"
      >
        <ECStack direction="row" justifyContent="right" spacing={2}>
          <ECButton
            type="button"
            variant="text"
            sx={{ height: 42 }}
            onClick={onCancel}
          >
            {t('translation:dynamicForm.cancel')}
          </ECButton>
          <ECButton
            variant="contained"
            sx={{
              height: 42,
              width: 'auto',
            }}
            onClick={handleSend}
            disabled={isEmptyHtmlComment || isLoadingCreate}
          >
            {isLoadingCreate ? (
              <CircularProgress />
            ) : (
              t('translation:dynamicForm.submit')
            )}
          </ECButton>
        </ECStack>
      </ECBox>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseNotifiedModal}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <ECBox display="flex" flexDirection="column" width={380}>
          <ECBox
            display="flex"
            bgcolor={theme =>
              theme.palette.marketingSuggestion.marketingSuggestion
            }
            justifyContent="space-between"
            alignItems="center"
            p={2}
          >
            <ECTypography
              variant="h6"
              color={theme => theme.palette.common.white}
            >
              Notified
            </ECTypography>

            <ECBox display="flex" gap={2} alignItems="center">
              <ECTypography
                variant="body2"
                color={theme => theme.palette.common.white}
              >
                {selectedUsers.length + selectedCurrentCompanyUsers.length}{' '}
                selected
              </ECTypography>

              <ECIconButton type="white" onClick={handleCloseNotifiedModal}>
                <Close />
              </ECIconButton>
            </ECBox>
          </ECBox>

          <ECTabContainer
            p={2}
            variant="standard"
            noPadding
            tabs={[
              {
                value: '1',
                label: t('translation:noteTab.users', {
                  count: selectedCurrentCompanyUsers?.length ?? 0,
                }),
                content: (
                  <ECBox
                    display="flex"
                    width="100%"
                    height={330}
                    flexDirection="column"
                    overflow="auto"
                  >
                    <ECBox px={2} pb={1}>
                      <ECTextField
                        fullWidth
                        placeholder="Search..."
                        defaultValue={currentCompanySearch}
                        onChange={debouncedHandleCurrentCompanySearchChange}
                      />
                    </ECBox>
                    <ECTree
                      tree={currentCompanyTree}
                      showSelectAllOption
                      keepSelectedNodes={currentCompanySearch !== undefined}
                      initialSelectedNodesIds={currentCompanyNodesIds}
                      selectedNodes={currentCompanyNodesIds}
                      onSelectedNodesIdsChange={setCurrentCompanyNodesIds}
                      placeholder={t('translation:noteTab.noUsersAvailable')}
                    />
                  </ECBox>
                ),
              },
              {
                value: '2',
                label: isCustomer
                  ? t('translation:noteTab.sp', {
                      count: selectedUsers?.length ?? 0,
                    })
                  : t('translation:noteTab.customer', {
                      count: selectedUsers?.length ?? 0,
                    }),
                content: (
                  <ECBox
                    display="flex"
                    width="100%"
                    height={330}
                    flexDirection="column"
                    overflow="auto"
                  >
                    <ECBox px={2} pb={1}>
                      <ECTextField
                        fullWidth
                        placeholder="Search..."
                        defaultValue={search}
                        onChange={debouncedHandleSearchChange}
                      />
                    </ECBox>
                    <ECTree
                      tree={tree}
                      showSelectAllOption
                      keepSelectedNodes={search !== undefined}
                      initialSelectedNodesIds={nodesIds}
                      selectedNodes={nodesIds}
                      onSelectedNodesIdsChange={setNodesIds}
                      placeholder={t('translation:noteTab.noUsersAvailable')}
                    />
                  </ECBox>
                ),
              },
            ]}
          />
        </ECBox>
      </Popover>
    </ECBox>
  );
}

export const addNoteAction = {
  buttonLabel: 'Add Comment',
  disabledRule: (selectionLength: number) => selectionLength !== 1,
  modalContent: requirePermissionWrapper<DashboardActionProps>(AddNoteContent),
  scopes: [P.AddComment],
};
