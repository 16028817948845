import { ECBox } from 'app/components/ECBox';
import { ECGrid } from 'app/components/ECGrid';
import { Helmet } from 'react-helmet-async';
import DashboardTable from './DashboardTable';
import NavigationBar from './NavigationBar';
import { useGetCategoryListQuery } from 'services/categoryApi';
import {
  useGetUnassignedWorkOrdersQuery,
  useGetPendingWorkOrdersCountQuery,
  useGetUnassignedWorkOrdersCountQuery,
  useGetPendingWorkOrdersQuery,
  useGetWorkordersAwaitingInvoiceCountQuery,
  useGetWorkordersAwaitingInvoiceQuery,
  useGetPendingProposalsQuery,
  useGetPendingProposalsCountQuery,
  useGetPendingInvoicesQuery,
  useGetPendingInvoicesCountQuery,
  useGetPastEtaWorkordersQuery,
  useGetPastEtaWoCountQuery,
} from 'services/dashboardApi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  approveAction,
  addNoteAction,
  cancelAction,
  rejectAction,
  reassignAction,
  QueryTag,
} from './actions';
import { P } from 'types/Permission';
import { useCustomerUser } from 'app/hooks/customerUser.use-case';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { ForbiddenPage } from '../ForbiddenPage';
import { setTitle } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { usePersistedDashboardFilters } from './DashboardHooks';
import { DashboardTables } from 'utils/constants';
import { DashboardDrawer } from './DashboardDrawer';
import { DashboardTableFilter } from 'types/Dashboard';

interface DashboardPageProps {
  selectedDrawer?: DashboardTables;
}

const SMALL_COUNT = 5;

export function DashboardPage({ selectedDrawer }: DashboardPageProps) {
  const dispatch = useDispatch();

  const [drawer, setDrawer] = useState<DashboardTables>();
  const [filters, setFilters] = useState<DashboardTableFilter[]>([]);

  const unassignedWOFilter = useMemo(
    () => filters.find(filter => filter.table === DashboardTables.unassignedWO),
    [filters],
  );
  const pendingWOFilter = useMemo(
    () => filters.find(filter => filter.table === DashboardTables.pendingWO),
    [filters],
  );
  const pastEtaWoFilter = useMemo(
    () => filters.find(filter => filter.table === DashboardTables.pastEtaWo),
    [filters],
  );
  const pendingProposalsFilter = useMemo(
    () =>
      filters.find(filter => filter.table === DashboardTables.pendingProposals),
    [filters],
  );
  const pendingInvoicesFilter = useMemo(
    () =>
      filters.find(filter => filter.table === DashboardTables.pendingInvoices),
    [filters],
  );
  const awaitingInvoiceFilter = useMemo(
    () => ({
      ...filters.find(
        filter => filter.table === DashboardTables.awaitingInvoice,
      ),
    }),
    [filters],
  );

  const {
    getDashboardPersistedFilters,
    saveDashboardFilters,
    updateDashboardFilter,
  } = usePersistedDashboardFilters();

  const [hasWorkorderPermission, hasProposalPermission, hasInvoicePermission] =
    useHasPermission([P.GetWorkOrder, P.GetProposal, P.GetInvoice]);

  const { data: unassignedWorkorders } = useGetUnassignedWorkOrdersQuery(
    {
      ...unassignedWOFilter,
      t: SMALL_COUNT,
    },
    {
      skip: !hasWorkorderPermission || !filters.length,
    },
  );
  const { data: pendingWorkorders } = useGetPendingWorkOrdersQuery(
    {
      ...pendingWOFilter,
      t: SMALL_COUNT,
    },
    {
      skip: !hasWorkorderPermission || !filters.length,
    },
  );
  const { data: awaitingInvoice } = useGetWorkordersAwaitingInvoiceQuery(
    {
      ...awaitingInvoiceFilter,
      t: SMALL_COUNT,
    },
    {
      skip: !hasWorkorderPermission || !filters.length,
    },
  );
  const { data: pendingProposals } = useGetPendingProposalsQuery(
    {
      ...pendingProposalsFilter,
      t: SMALL_COUNT,
    },
    {
      skip: !hasProposalPermission || !filters.length,
    },
  );
  const { data: pendingInvoices } = useGetPendingInvoicesQuery(
    {
      ...pendingInvoicesFilter,
      t: SMALL_COUNT,
    },
    {
      skip: !hasInvoicePermission || !filters.length,
    },
  );
  const { data: pastEtaWorkorders } = useGetPastEtaWorkordersQuery(
    {
      ...pastEtaWoFilter,
      t: SMALL_COUNT,
    },
    {
      skip: !hasWorkorderPermission || !filters.length,
    },
  );

  const { data: pastEtaWoCount, isLoading: isLoadingPastEtaWoCount } =
    useGetPastEtaWoCountQuery();
  const {
    data: unassignedWorkordersCount,
    isLoading: loadingUnassignedWoCount,
  } = useGetUnassignedWorkOrdersCountQuery(
    {
      ...unassignedWOFilter,
    },
    {
      skip: !hasWorkorderPermission || !filters.length,
    },
  );
  const { data: pendingWorkordersCount, isLoading: loadingPendingWoCount } =
    useGetPendingWorkOrdersCountQuery(
      {
        ...pendingWOFilter,
      },
      {
        skip: !hasWorkorderPermission || !filters.length,
      },
    );
  const { data: awaitingInvoiceCount, isLoading: loadingAwaitingInvoiceCount } =
    useGetWorkordersAwaitingInvoiceCountQuery(
      {
        ...awaitingInvoiceFilter,
      },
      {
        skip: !hasWorkorderPermission || !filters.length,
      },
    );
  const {
    data: pendingProposalsCount,
    isLoading: isLoadingPendingProposalsCount,
  } = useGetPendingProposalsCountQuery(
    {
      ...pendingProposalsFilter,
    },
    {
      skip: !hasProposalPermission || !filters.length,
    },
  );
  const {
    data: pendingInvoicesCount,
    isLoading: isLoadingPendingInvoicesCount,
  } = useGetPendingInvoicesCountQuery(
    {
      ...pendingInvoicesFilter,
    },
    {
      skip: !hasInvoicePermission || !filters.length,
    },
  );

  const isLargeViewport = useMediaQuery('(min-width:1440px)');

  const approveActionMemoProposal = useMemo(() => {
    return approveAction([P.ChangeProposalStatus]);
  }, []);

  const rejectActionMemoProposal = useMemo(() => {
    return rejectAction([P.ChangeProposalStatus]);
  }, []);

  const approveActionMemoInvoice = useMemo(() => {
    return approveAction([P.ChangeInvoiceStatus]);
  }, []);

  const rejectActionMemoInvoice = useMemo(() => {
    return rejectAction([P.ChangeInvoiceStatus]);
  }, []);

  const handleExpandTable = useCallback((target: DashboardTables) => {
    setDrawer(target);
  }, []);

  const { data: categories, isSuccess: isSuccessCategories } =
    useGetCategoryListQuery({ st: 1 });

  const [preSelectionDone, setPreSelectionDone] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccessCategories && categories && !preSelectionDone) {
      let preSelected = categories.data.map(category => category.id);
      let newFilter = [
        {
          table: DashboardTables.unassignedWO,
          cat: preSelected,
        },
        {
          table: DashboardTables.pendingWO,
          cat: preSelected,
        },
        {
          table: DashboardTables.pastEtaWo,
          cat: preSelected,
        },
        {
          table: DashboardTables.pendingProposals,
          cat: preSelected,
          assignedToMe: 1,
        },
        {
          table: DashboardTables.pendingInvoices,
          cat: preSelected,
          assignedToMe: 1,
        },
        {
          table: DashboardTables.awaitingInvoice,
          cat: preSelected,
        },
      ];
      const persistedFilters = getDashboardPersistedFilters();
      if (persistedFilters) {
        setFilters(persistedFilters);
      } else {
        setFilters(newFilter);
        saveDashboardFilters(newFilter);
      }
      setPreSelectionDone(true);
    }
  }, [isSuccessCategories, categories, preSelectionDone]);

  useEffect(() => {
    dispatch(setTitle('Dashboard'));
  }, []);

  const onDrawerClose = useCallback(() => {
    setDrawer(undefined);
  }, []);

  const handleFilterChange = ({
    table,
    ...filterParams
  }: DashboardTableFilter) => {
    const untouchedFilters = filters.filter(filter => filter.table !== table);
    setFilters([
      ...untouchedFilters,
      {
        table,
        ...filterParams,
      },
    ]);
    updateDashboardFilter(table, {
      table,
      ...filterParams,
    });
  };

  // useEffect to read selectedDrawer from props and set the drawer state
  useEffect(() => {
    if (selectedDrawer) {
      setDrawer(selectedDrawer);
    }
  }, [selectedDrawer]);

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="user dashboard" />
      </Helmet>
      <ECBox px={3} justifyItems="center" height="100%">
        <NavigationBar />
        <ECGrid
          container
          display="grid"
          gridTemplateColumns={`repeat(${isLargeViewport ? 2 : 1}, 1fr)`}
          gap={3}
          mt={3}
        >
          {unassignedWorkorders && (
            <ECGrid item scopesOptional={[P.GetAllWorkOrders, P.EditWorkOrder]}>
              <DashboardTable
                actions={[reassignAction, cancelAction]}
                count={
                  loadingUnassignedWoCount ? '-' : unassignedWorkordersCount
                }
                categories={categories}
                tableData={unassignedWorkorders}
                onExpand={handleExpandTable}
                dashboardId={DashboardTables.unassignedWO}
                queryTag={[
                  QueryTag.UnassignedWorkorders,
                  QueryTag.PendingWorkorders,
                  QueryTag.PastEtaWo,
                ]}
                onFiltersChange={handleFilterChange}
                filters={unassignedWOFilter}
              />
            </ECGrid>
          )}
          {pendingProposals && (
            <ECGrid item scopesOptional={[P.GetAllProposals, P.EditProposal]}>
              <DashboardTable
                actions={[
                  addNoteAction,
                  approveActionMemoProposal,
                  rejectActionMemoProposal,
                ]}
                count={
                  isLoadingPendingProposalsCount ? '-' : pendingProposalsCount
                }
                categories={categories}
                tableData={pendingProposals}
                onExpand={handleExpandTable}
                dashboardId={DashboardTables.pendingProposals}
                queryTag={QueryTag.PendingProposals}
                onFiltersChange={handleFilterChange}
                filters={pendingProposalsFilter}
              />
            </ECGrid>
          )}
          {pendingWorkorders && (
            <ECGrid item scopesOptional={[P.GetAllWorkOrders, P.EditWorkOrder]}>
              <DashboardTable
                actions={[reassignAction, cancelAction]}
                count={loadingPendingWoCount ? '-' : pendingWorkordersCount}
                categories={categories}
                tableData={pendingWorkorders}
                onExpand={handleExpandTable}
                dashboardId={DashboardTables.pendingWO}
                queryTag={[
                  QueryTag.UnassignedWorkorders,
                  QueryTag.PendingWorkorders,
                  QueryTag.PastEtaWo,
                ]}
                onFiltersChange={handleFilterChange}
                filters={pendingWOFilter}
              />
            </ECGrid>
          )}
          {pastEtaWorkorders && (
            <ECGrid item scopesOptional={[P.GetAllWorkOrders, P.EditWorkOrder]}>
              <DashboardTable
                actions={[addNoteAction, reassignAction, cancelAction]}
                count={isLoadingPastEtaWoCount ? '-' : pastEtaWoCount}
                categories={categories}
                tableData={pastEtaWorkorders}
                onExpand={handleExpandTable}
                dashboardId={DashboardTables.pastEtaWo}
                queryTag={[
                  QueryTag.UnassignedWorkorders,
                  QueryTag.PendingWorkorders,
                  QueryTag.PastEtaWo,
                ]}
                onFiltersChange={handleFilterChange}
                filters={pastEtaWoFilter}
              />
            </ECGrid>
          )}
          {pendingInvoices && (
            <ECGrid item scopesOptional={[P.GetAllInvoices, P.EditInvoice]}>
              <DashboardTable
                actions={[
                  addNoteAction,
                  approveActionMemoInvoice,
                  rejectActionMemoInvoice,
                ]}
                count={
                  isLoadingPendingInvoicesCount ? '-' : pendingInvoicesCount
                }
                categories={categories}
                tableData={pendingInvoices}
                onExpand={handleExpandTable}
                dashboardId={DashboardTables.pendingInvoices}
                queryTag={QueryTag.PendingInvoices}
                onFiltersChange={handleFilterChange}
                filters={pendingInvoicesFilter}
              />
            </ECGrid>
          )}
          {awaitingInvoice && (
            <ECGrid item scopesOptional={[P.GetAllWorkOrders, P.EditWorkOrder]}>
              <DashboardTable
                actions={[addNoteAction, cancelAction]}
                categories={categories}
                count={loadingAwaitingInvoiceCount ? '-' : awaitingInvoiceCount}
                tableData={awaitingInvoice}
                onExpand={handleExpandTable}
                dashboardId={DashboardTables.awaitingInvoice}
                queryTag={QueryTag.AwaitingInvoice}
                onFiltersChange={handleFilterChange}
                filters={awaitingInvoiceFilter as any}
              />
            </ECGrid>
          )}
          <ECGrid item></ECGrid>
        </ECGrid>
      </ECBox>
      {drawer !== undefined && (
        <DashboardDrawer
          open={drawer !== undefined}
          onClose={onDrawerClose}
          handleCloseModal={onDrawerClose}
          handleClearModal={onDrawerClose}
          handleFilterChange={handleFilterChange}
          categories={categories}
          filters={filters}
          drawer={drawer}
          approveActionMemoProposal={approveActionMemoProposal}
          rejectActionMemoProposal={rejectActionMemoProposal}
          rejectActionMemoInvoice={rejectActionMemoInvoice}
          unassignedWorkordersCount={unassignedWorkordersCount}
          pendingWorkordersCount={pendingWorkordersCount}
          pastEtaWoCount={pastEtaWoCount}
          pendingProposalsCount={pendingProposalsCount}
          pendingInvoicesCount={pendingInvoicesCount}
          awaitingInvoiceCount={awaitingInvoiceCount}
        />
      )}
    </>
  );
}
