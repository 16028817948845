import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';

export const useSupplierIntegrationSnackbar = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!location.pathname.includes('purchase-orders')) return;

    const params = new URLSearchParams(location.search);
    const successMessage = params.get('message');
    const errorMessage = params.get('errorMessage');

    if (successMessage) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: successMessage,
        }),
      );
    } else if (errorMessage) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: errorMessage,
        }),
      );
    }

    if (successMessage || errorMessage) {
      window.history.replaceState(null, '', location.pathname);
    }
  }, [location, dispatch]);
};
