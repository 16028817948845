import { ECAutocompletePaginated } from '../ECAutocompletePaginated';
import { ECBox } from '../ECBox';
import { ECTextField } from '../ECTextField';
import { ECTypography } from '../ECTypography';
import { useGetPurchaseOrderItemsForDropdownQuery } from 'services/purchaseOrderApi';
import ECCurrencyField from '../ECCurrencyField';
import {
  useGetMeasurementUnitsListQuery,
  useLazyGetValidatePartNumberQuery,
} from 'services/inventoryApi';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';
import { ECAutocomplete } from '../ECAutocomplete';
import { ECButton } from '../ECButton';

export interface PurchaseOrderItemValue {
  id?: number;
  name?: string;
  partNumber?: string;
  lastCostPerUnit?: number;
  quantity?: number;
  unitCost?: number;
  subTotal?: number;
  measurementUnit?: any;
  isNewItem?: boolean;
  isDuplicatedPartNumber?: boolean;
  isDuplicatedManufacturePartNumber?: boolean;
  isDuplicatedSupplierPartNumber?: boolean;
  manufacturePartNumber?: string;
  supplierPartNumber?: string;
  note?: string;
}

export interface ECPurchaseOrderItemProps {
  value?: PurchaseOrderItemValue;
  readOnly?: boolean;
  errorMessage?: string;
  filterOptionsIds?: any[];
  onChange: (value: PurchaseOrderItemValue) => void;
  onDelete: () => void;
}

export const ECPurchaseOrderItem = ({
  value,
  readOnly,
  filterOptionsIds,
  errorMessage,
  onChange,
  onDelete,
}: ECPurchaseOrderItemProps) => {
  const {
    data: dataMeasurementUnits,
    isLoading: isLoadingMeasurementUnits,
    isSuccess: isSuccessMeasurementUnits,
  } = useGetMeasurementUnitsListQuery();

  const [trigger, result] = useLazyGetValidatePartNumberQuery();
  const {
    data: dataValidatePartNumber,
    isLoading,
    originalArgs: originalArgsPartNumber,
  } = result;

  const isMeasurmentUnitAlreadyDefined = useRef(value?.measurementUnit?.id);

  const debouncedCheckDuplicate = useRef(
    debounce((partNumber, fieldName) => {
      trigger({ inputPartNumber: partNumber, fieldName });
    }, 500),
  ).current;

  useEffect(() => {
    if (!!dataValidatePartNumber && !isLoading && originalArgsPartNumber) {
      const duplicatePartNumberLookupKey = {
        partNumber: 'isDuplicatedPartNumber',
        manufacturePartNumber: 'isDuplicatedManufacturePartNumber',
        supplierPartNumber: 'isDuplicatedSupplierPartNumber',
      };
      onChange({
        ...value,
        [duplicatePartNumberLookupKey[originalArgsPartNumber.fieldName]]:
          dataValidatePartNumber?.isDuplicate,
      });
    }
  }, [dataValidatePartNumber, isLoading, originalArgsPartNumber]);

  const handlePartNumberChange = (
    fieldName: 'partNumber' | 'manufacturePartNumber' | 'supplierPartNumber',
    fieldValue: string,
  ) => {
    debouncedCheckDuplicate(fieldValue, fieldName);
    onChange({
      ...value,
      [fieldName]: fieldValue,
    });
  };

  return (
    <ECBox
      display="flex"
      flexDirection="column"
      bgcolor={theme => theme.palette.common.white}
      padding={2}
      borderRadius={3}
      flex={1}
    >
      <ECBox display="flex" flexDirection="row" flex={1} gap={1}>
        {value?.isNewItem ? (
          <ECTextField
            variant={readOnly ? 'standard' : 'filled'}
            sx={{ width: '100%', mb: 1 }}
            value={value?.name}
            placeholder="Item Name*"
            label="Item Name*"
            inputProps={{
              readOnly,
            }}
            onChange={e =>
              onChange({
                ...value,
                name: e.target.value,
              })
            }
            error={!!errorMessage && !value?.name}
          />
        ) : (
          <ECAutocompletePaginated
            sx={{ width: '100%', mb: 1 }}
            fieldName="purchaseOrderItem"
            value={value}
            variant="filled"
            placeholder="Item Name*"
            label="Item Name*"
            filterOptionsIds={filterOptionsIds}
            readOnly={readOnly}
            useQuery={useGetPurchaseOrderItemsForDropdownQuery}
            queryParams={{
              sb: 'invntry.name',
              ob: 'invntry.name',
              o: 'a',
            }}
            onChange={newValue => {
              onChange(newValue);
            }}
            renderCustomOption={option => {
              return (
                <ECBox display="flex" flexDirection="column">
                  <ECTypography>{option?.name}</ECTypography>
                  <ECTypography variant="caption" color="text.secondary">
                    ID: {option?.viewId}
                  </ECTypography>
                </ECBox>
              );
            }}
            error={!!errorMessage && !value}
            validationMessage={
              !!errorMessage && !value ? errorMessage : undefined
            }
          />
        )}
        {readOnly ? null : (
          <ECButton variant="text" color="error" onClick={onDelete}>
            REMOVE
          </ECButton>
        )}
      </ECBox>

      <ECBox display="flex" flexDirection="row" gap={1}>
        <ECTextField
          variant={readOnly || !value?.isNewItem ? 'standard' : 'filled'}
          sx={{ width: '100%', mb: 1 }}
          value={value?.partNumber}
          placeholder="Customer Part #"
          label="Customer Part #"
          inputProps={{
            readOnly: readOnly || !value?.isNewItem,
          }}
          onChange={e => handlePartNumberChange('partNumber', e.target.value)}
          error={value?.isDuplicatedPartNumber}
          helperText={
            value?.isDuplicatedPartNumber ? 'Customer Part # already exist' : ''
          }
        />
        <ECTextField
          variant={readOnly || !value?.isNewItem ? 'standard' : 'filled'}
          sx={{ width: '100%', mb: 1 }}
          value={value?.manufacturePartNumber}
          placeholder="Manufacturer Part #"
          label="Manufacturer Part #"
          inputProps={{
            readOnly: readOnly || !value?.isNewItem,
          }}
          onChange={e =>
            handlePartNumberChange('manufacturePartNumber', e.target.value)
          }
          error={value?.isDuplicatedManufacturePartNumber}
          helperText={
            value?.isDuplicatedManufacturePartNumber
              ? 'Manufacture Part # already exist'
              : ''
          }
        />
        <ECTextField
          variant={readOnly || !value?.isNewItem ? 'standard' : 'filled'}
          sx={{ width: '100%', mb: 1 }}
          value={value?.supplierPartNumber}
          placeholder="Supplier Part #"
          label="Supplier Part #"
          inputProps={{
            readOnly: readOnly || !value?.isNewItem,
          }}
          onChange={e =>
            handlePartNumberChange('supplierPartNumber', e.target.value)
          }
          error={value?.isDuplicatedSupplierPartNumber}
          helperText={
            value?.isDuplicatedSupplierPartNumber
              ? 'Supplier Part # already exist'
              : ''
          }
        />
      </ECBox>

      <ECBox display="flex" flexDirection="row" gap={1}>
        <ECTextField
          variant={readOnly ? 'standard' : 'filled'}
          sx={{ width: '100%', mb: 1 }}
          value={value?.quantity}
          placeholder="Quantity*"
          label="Quantity*"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          inputProps={{
            readOnly,
          }}
          onKeyDown={e => {
            if (e.key === '-') {
              e.preventDefault();
            }
          }}
          onChange={e =>
            onChange({
              ...value,
              quantity: Number(e.target.value),
              subTotal: +(
                Number(e.target.value) * Number(value?.unitCost || 0)
              ).toFixed(2),
            })
          }
          error={!!errorMessage && !value?.quantity}
          helperText={
            errorMessage && !value?.quantity ? errorMessage : undefined
          }
        />
        {isMeasurmentUnitAlreadyDefined.current ? (
          <ECTextField
            variant={readOnly ? 'standard' : 'filled'}
            sx={{ width: '100%', mb: 1 }}
            value={value?.measurementUnit.name}
            label="Unit Type"
            inputProps={{
              readOnly: readOnly || !value?.isNewItem,
            }}
          />
        ) : (
          <ECAutocomplete
            disablePortal
            id="unitType"
            label="Unit Type"
            sx={{ width: '100%', mb: 1 }}
            options={dataMeasurementUnits ?? []}
            groupBy={option => option.group}
            value={
              value?.measurementUnit?.id ? value?.measurementUnit : undefined
            }
            onChange={(event, measurementUnit) => {
              onChange({
                ...value,
                measurementUnit,
              });
            }}
          />
        )}
        <ECCurrencyField
          id="preCost"
          variant={'standard'}
          label={'$ Pre Cost/Unit'}
          style={{
            width: '100%',
            marginTop: 8,
          }}
          readOnly={
            readOnly || Boolean(value?.lastCostPerUnit) || value?.isNewItem
          }
          hiddenLabel
          value={value?.lastCostPerUnit}
          onChange={(e, newValue) => {
            onChange({
              ...value,
              lastCostPerUnit: Number(e.target.value),
            });
          }}
        />
        <ECCurrencyField
          id="unitCost"
          variant={readOnly ? 'standard' : 'filled'}
          label={'Unit Cost'}
          style={{
            width: '100%',
          }}
          readOnly={readOnly}
          value={value?.unitCost}
          onChange={(e, newValue) => {
            onChange({
              ...value,
              unitCost: newValue as any,
              subTotal: +(Number(newValue) * (value?.quantity || 0)).toFixed(2),
            });
          }}
          error={!!errorMessage && !value?.unitCost}
          validationMessage={
            errorMessage && !value?.unitCost ? errorMessage : undefined
          }
        />
        <ECCurrencyField
          id="subtotal"
          variant={'standard'}
          label={'Subtotal'}
          style={{
            width: '100%',
            marginTop: 8,
          }}
          readOnly={true}
          value={value?.subTotal}
        />
      </ECBox>

      <ECTextField
        variant={'filled'}
        sx={{ width: '100%', mb: 1 }}
        value={value?.note}
        placeholder="Note"
        label="Note"
        multiline={true}
        rows={1.5}
        inputProps={{
          readOnly: readOnly,
          maxLength: 200,
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        onChange={e =>
          onChange({
            ...value,
            note: e.target.value,
          })
        }
      />
    </ECBox>
  );
};
