import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import { ECEasyFormFieldType, FieldTypes } from 'app/components/ECForm';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyGetAreaListQuery } from 'services/areaApi';
import { useLazyGetServiceRequestAssetsListQuery } from 'services/assetApi';
import { useGetServiceRequestBranchesListQuery } from 'services/branchApi';
import { populateDropDownFields } from 'utils/pageUtils';
import _ from 'lodash';
import { useCompanyCustomerProfile } from 'app/hooks/useCompanyCustomerProfile.use-case';
import { NoLocationAlert } from './NoLocationAlert';
import { OpenWOCardModal } from './openWOCardModal';

const fancyFormElementsCreate = require('./fancy_form_config_step1_create.json');

interface Step1Props {
  existingData?: any;
  readOnly?: boolean;
  onClose?: () => void;
  onSubmit?: (data: any) => void;
}

const ASSETS_PER_PAGE = 50;

export const Step1 = ({
  existingData,
  readOnly = false,
  onClose,
  onSubmit,
}: Step1Props) => {
  const { isGeneralAsset } = existingData;
  const [clickedWOCardId, setClickedWOCardId] = useState<number | undefined>(
    undefined,
  );
  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      return {
        ...field,
        value: _.get(existingData, field.fieldName),
        readOnly:
          readOnly &&
          (isGeneralAsset
            ? field.fieldName === 'asset'
              ? true
              : false
            : field.type === FieldTypes.Autocomplete ||
              field.type === FieldTypes.SelectPaginated),
      };
    },
    [existingData],
  );

  const [selectedBranch, setSelectedBranch] = useState<any>(
    existingData?.asset?.branch?.name,
  );
  const [selectedArea, setSelectedArea] = useState<any>(
    existingData?.area?.name,
  );
  const currentAssets = useRef<any[]>([]);
  const [assetPage, setAssetPage] = useState(0);
  const [assetSearchText, setAssetSearchText] = useState<string | undefined>(
    undefined,
  );

  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsCreate.fields?.map(handleExistingData),
  );

  const { data: branches } = useGetServiceRequestBranchesListQuery(
    isGeneralAsset ? existingData.asset.id : { st: 1 },
  );

  const customerProfile = useCompanyCustomerProfile();
  const areaEnabled = customerProfile?.enableArea;
  const [triggerArea, areaResult] = useLazyGetAreaListQuery();
  const { data: areas, isSuccess: isSuccessArea } = areaResult;

  const [triggerAssets, assetsResult] = useLazyGetServiceRequestAssetsListQuery(
    {},
  );
  const {
    data: assets,
    isSuccess: isSuccessAssets,
    isFetching: isFetchingAssets,
  } = assetsResult;

  const fieldToGetAlias = assets?.config.fields.filter(
    f =>
      f.name === 'name' || f.name === 'assetType.name' || f.name === 'qrCode',
  );

  useEffect(() => {
    // this is used to populate autocomplete query and also make area not visible if area not enabled
    // for some reason it disables first field
    setFormFields([
      {
        ...formFields?.[0],
        visible: false,
      },
      {
        ...formFields?.[0],
        value: {
          id: existingData?.asset?.branch?.id || existingData?.asset?.branchId,
          name: existingData
            ? existingData?.asset?.branch?.name ||
              existingData?.asset?.branchName
            : formFields?.[0]?.value,
        },
        customFieldProps: {
          emptyDataAlert: <NoLocationAlert />,
        },
        queryParams: isGeneralAsset
          ? existingData.asset.id
          : { st: 1, withoutOnHold: 1 },
        shouldUseOriginalQueryParams: isGeneralAsset ? true : false,
        useQuery: useGetServiceRequestBranchesListQuery,
        obAlias: 'brnch.name',
      },
      {
        ...formFields?.[1],
        value: existingData?.area?.name,
        visible: areaEnabled,
      },
      {
        ...formFields?.[2],
      },
    ]);
  }, [existingData, isGeneralAsset, areaEnabled]);

  useEffect(() => {
    if (!selectedBranch) {
      return;
    }

    if (!selectedArea && selectedBranch) {
      setFormFields(prevFormFields =>
        prevFormFields.map(field => {
          if (field.fieldName === 'asset' && _.isEmpty(existingData)) {
            return {
              ...field,
              value: null,
              readOnly: isGeneralAsset,
            };
          }

          return {
            ...field,
            disabled: false,
          };
        }),
      );
      currentAssets.current = [];
      setAssetPage(0);
      setAssetSearchText(undefined);
      triggerAssets({
        branchId: selectedBranch.id,
        p: 0,
        t: ASSETS_PER_PAGE,
      });
      return;
    }

    const newFormFields = formFields.map(field => {
      if (field.fieldName === 'asset' && _.isEmpty(existingData)) {
        return {
          ...field,
          value: null,
        };
      }

      return {
        ...field,
      };
    });
    setFormFields(newFormFields);
    currentAssets.current = [];
    setAssetSearchText(undefined);
    setAssetPage(0);
    triggerAssets({
      areaId: selectedArea?.id,
      branchId: selectedBranch?.id,
      p: 0,
      t: ASSETS_PER_PAGE,
    });
  }, [selectedArea]);

  useEffect(() => {
    if (!selectedBranch) {
      setFormFields(prevFormFields =>
        prevFormFields.map(field => {
          if (field.fieldName === 'area.name' && _.isEmpty(existingData)) {
            return {
              ...field,
              disabled: true,
              value: null,
            };
          }

          if (field.fieldName === 'asset' && _.isEmpty(existingData)) {
            return {
              ...field,
              disabled: true,
              value: null,
              readOnly: isGeneralAsset,
            };
          }

          return {
            ...field,
            disabled: false,
          };
        }),
      );
      currentAssets.current = [];
      setAssetSearchText(undefined);
      setAssetPage(0);
      return;
    }

    if (areaEnabled) {
      triggerArea({
        branchId: selectedBranch?.id,
        branchTypeId: selectedBranch?.branchTypeId,
      });
    }

    setFormFields(prevFormFields =>
      prevFormFields.map(field => {
        if (field.fieldName === 'area.name' && _.isEmpty(existingData)) {
          return {
            ...field,
            disabled: true,
            value: null,
          };
        }

        if (field.fieldName === 'asset' && _.isEmpty(existingData)) {
          return {
            ...field,
            disabled: true,
            value: null,
            readOnly: isGeneralAsset,
          };
        }

        return {
          ...field,
        };
      }),
    );
    currentAssets.current = [];
    setAssetSearchText(undefined);
    setAssetPage(0);
    triggerAssets({
      branchId: selectedBranch.id,
      p: 0,
      t: ASSETS_PER_PAGE,
    });
  }, [selectedBranch]);

  useEffect(() => {
    const searchParams = fieldToGetAlias?.reduce(
      (prevParams, fieldAlias, index) => ({
        ...prevParams,
        [`sb${index > 0 ? index : ''}`]: fieldAlias?.alias ?? fieldAlias?.name,
        [`s${index > 0 ? index : ''}`]: `*${assetSearchText}*`,
        [`sg${index > 0 ? index : ''}`]: '1',
        [`sglo${index > 0 ? index : ''}`]: 'or',
      }),
      {},
    );

    if (selectedBranch?.id && selectedArea?.id) {
      if (
        assets?.config.pagination.totalCount &&
        assets?.config.pagination.totalCount > currentAssets.current.length
      ) {
        triggerAssets({
          areaId: selectedArea?.id,
          branchId: selectedBranch?.id,
          p: assetPage,
          t: ASSETS_PER_PAGE,
          ...searchParams,
        });
        return;
      }
    }

    if (
      assets?.config.pagination.totalCount &&
      assets?.config.pagination.totalCount > currentAssets.current.length
    ) {
      if (selectedBranch?.id && !isFetchingAssets) {
        triggerAssets({
          branchId: selectedBranch.id,
          p: assetPage,
          t: ASSETS_PER_PAGE,
          ...searchParams,
        });
      }
    }
  }, [assetPage, assetSearchText]);

  const currentAssetSearchText = useRef<string | undefined>('');
  const handleLoadMoreData = (fieldName: string, newValue?: string) => {
    if (newValue !== currentAssetSearchText.current) {
      setAssetPage(0);
      currentAssets.current = [];
      currentAssetSearchText.current = newValue;
      setAssetSearchText(newValue);
    } else {
      currentAssetSearchText.current = newValue;
      setAssetSearchText(newValue);
      setAssetPage(currentValue => currentValue + 1);
    }
  };

  useEffect(() => {
    if (isSuccessArea) {
      const [updatedFormFields, _] = populateDropDownFields({
        responseData: areas,
        createFormFields: formFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'area.name',
      });

      setFormFields(
        updatedFormFields.map(field => ({
          ...field,
          disabled:
            (field.fieldName === 'asset' || field.fieldName === 'area.name') &&
            !selectedBranch,
        })),
      );
    }
  }, [areas, isSuccessArea]);

  useEffect(() => {
    if (isSuccessAssets && !isFetchingAssets) {
      if (
        assets.config.pagination.totalCount === currentAssets.current.length
      ) {
        setFormFields(prevFormFields =>
          prevFormFields.map(field => {
            if (field.fieldName === 'asset') {
              return {
                ...field,
                noMoreDataToFetch: true,
              };
            }
            return field;
          }),
        );
        return;
      }
      if (assets.data.length === 0) {
        setFormFields(prevFormFields =>
          prevFormFields.map(field => {
            if (field.fieldName === 'asset') {
              return {
                ...field,
                noMoreDataToFetch: true,
              };
            }
            return field;
          }),
        );
        return;
      }

      const accumulatedAssets = _.uniqBy(
        [...currentAssets.current, ...assets.data],
        'id',
      );
      currentAssets.current = accumulatedAssets;

      const [updatedFormFields, _fields] = populateDropDownFields({
        responseData: accumulatedAssets,
        responseConfig: assets.config,
        createFormFields: formFields,
        dataValuesFieldName: 'id',
        formFieldName: 'asset',
      });

      setFormFields(
        updatedFormFields.map(field => ({
          ...field,
          disabled:
            (field.fieldName === 'asset' || field.fieldName === 'area.name') &&
            !selectedBranch,
        })),
      );
    }
  }, [assets, isSuccessAssets, isFetchingAssets]);

  const [selectedAreaName, setSelectedAreaName] = useState(
    existingData?.asset?.area?.name || '',
  );

  useEffect(() => {
    const selectedArea = areas?.data.find(
      area => area.name === selectedAreaName,
    );
    setSelectedArea(selectedArea);
  }, [selectedAreaName, areas]);

  const handleOutputChange = (output: ECEasyFormFieldType[]) => {
    const selectedAreaName = output.find(
      field => field.fieldName === 'area.name',
    )?.value;

    const selectedBranch = output.find(
      field => field.fieldName === 'asset.branch.name',
    )?.value;

    setSelectedBranch(selectedBranch);
    setSelectedAreaName(selectedAreaName as string);

    // if the asset field has no branchId, use the selected branch
    const assetField = output.find(field => field.fieldName === 'asset');
    if (assetField && assetField.value && !assetField.value?.branchId) {
      assetField.value = {
        ...assetField.value,
        branchId: selectedBranch?.id,
      };
    }
    if (assetField) {
      const clickedWOId = assetField?.value?.clickedWOId;
      setClickedWOCardId(clickedWOId);
    }
    setFormFields(
      output.map(field => ({
        ...field,
        readOnly: isGeneralAsset && field.fieldName === 'asset',
      })),
    );
  };

  const submit = useCallback(() => {
    const doSubmit = async data => {
      let branch = data['asset.branch.name'];
      if (!branch && isGeneralAsset && branches?.data) {
        branch = branches?.data?.[0];
      }

      const area = areas?.data.find(area => area.name === data.area);
      onSubmit?.({
        ...data,
        branch: {
          ...branch,
          storeNumberAndName: `${
            branch?.storeNumber ? `${branch.storeNumber} - ` : ''
          }${branch?.name ? branch.name : ''}`,
        },
        area: {
          name: 'Not Applicable',
          ...area,
        },
      });
    };

    return [doSubmit, {}];
  }, [onSubmit, branches, areas]);

  const shouldShowSubmitSubtitle = useMemo(() => {
    return (
      formFields.find(field => field.fieldName === 'asset')?.value
        ?.openWoCount > 0
    );
  }, [formFields]);

  return (
    <>
      <ECEasyFormCreate
        useCreateMutation={submit}
        formConfig={fancyFormElementsCreate.config}
        formFields={formFields}
        onChange={handleOutputChange}
        onClose={onClose}
        isLoading={isFetchingAssets}
        onLoadMoreData={handleLoadMoreData}
        shouldShowSubmitSubtitle={shouldShowSubmitSubtitle}
      />
      {clickedWOCardId ? (
        <OpenWOCardModal
          workOrderId={clickedWOCardId}
          isOpen={Boolean(clickedWOCardId)}
          onClose={() => setClickedWOCardId(undefined)}
        />
      ) : null}
    </>
  );
};
