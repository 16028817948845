import { useMediaQuery, useTheme } from '@mui/material';
import {
  ECBox,
  ECCopyright,
  ECMainBox,
  ECNotLoggedInBar,
  ECPoliceAndTerms,
  ECLoginBanner,
  ECLink,
  ECLoginFooterLinks,
} from '..';
import Logo from '../../../assets/ecotrak-big-logo.svg';
import { StyleConstants } from 'styles/StyleConstants';
import { SxProps, Theme } from '@mui/material/styles';

export interface ECMfaTemplateProps {
  children: React.ReactNode;
  childrenSx?: SxProps<Theme>;
}

export const ECMfaTemplate = ({ children, childrenSx }: ECMfaTemplateProps) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ECMainBox
      component={'main'}
      sx={theme => ({
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: theme.palette.marketingSuggestion.marketingSuggestion,
        overflowX: 'hidden',
        position: 'relative',
      })}
    >
      <ECNotLoggedInBar />

      <ECBox
        display="flex"
        mt={'3.75rem'}
        width="100%"
        p={2}
        position="absolute"
      ></ECBox>
      <ECLink href="https://ecotrak.com/">
        <img
          src={Logo}
          alt="logo"
          style={{
            height: isMobileSize ? '5rem' : '205px',
            zIndex: 1,
            marginTop: isMobileSize ? '-2.5rem' : StyleConstants.NAV_BAR_HEIGHT,
          }}
        />
      </ECLink>
      <ECBox
        sx={{
          width: '474px',
          maxWidth: '90%',
          marginTop: '25px',
          ...childrenSx,
        }}
      >
        {children}
      </ECBox>
      <ECLoginBanner />
      <ECCopyright sx={{ mt: 8, mb: 0, bottom: 0, marginTop: 'auto' }} />
      <ECPoliceAndTerms sx={{ mb: 4 }} />

      <ECLoginFooterLinks visible={isMobileSize} />
    </ECMainBox>
  );
};
