import { useDispatch } from 'react-redux';
import { ECEasyFormFieldType, ECPaper } from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EditForm2 } from 'app/components/ECDynamicPageTemplate/EditForm2';
import { PaymentType } from 'types/paymentMethod';
import { useUpdatePaymentMethodMutation } from 'services/paymentMethod';
import { invalidateCompanyProfile } from 'utils/invalidateCache';
import { profileApi, useGetProfileQuery } from 'services/profileApi';
import { limitStringMaxCharacters } from 'utils/strings/limit-string-max-characters';
import { useGetUsersListQuery } from 'services/userApi';
import _ from 'lodash';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const editFormElements = require('./fancy_form_config_edit.json');
const detailsFormElements = require('./fancy_form_config_details.json');

export function PaymentMethodEditDrawer({
  openPaymentMethodDrawer,
  handleClose,
  existingData,
}: {
  openPaymentMethodDrawer: boolean;
  handleClose: () => void;
  existingData?: {
    assigneeUsers?: any[];
    default: number;
    expiration: string;
    id: number;
    lastFour: string;
    name: string;
    routingNumber?: string;
    subscription?: any;
    type: PaymentType;
  };
}) {
  const dispatch = useDispatch();
  const [editFormElementsFields, setEditFormElementsFields] = useState<
    ECEasyFormFieldType[]
  >(editFormElements.fields);
  const [detailsFormElementsFields, setDetailsFormElementsFields] = useState<
    ECEasyFormFieldType[]
  >(detailsFormElements.fields);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const isCreditCard = existingData?.type === 'CC';
  const isSubscriptionPaymentMethod = Boolean(existingData?.default);

  const [
    doUpdatePaymentMethod,
    {
      data: updatePaymentMethodData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdatePaymentMethodMutation();

  const useUpdatePaymentMethod = useCallback(() => {
    const doUpdate = async data => {
      const cardName = data?.name;
      const selected = data?.subscriptionPaymentMethod?.includes(
        'Subscription Payment Method',
      );
      const assigneeUserIds = data?.selectedUsersToAssignedPaymentMethod?.map(
        user => user.id,
      );

      doUpdatePaymentMethod({
        id: existingData?.id ?? 0,
        cardName,
        selected,
        assigneeUserIds,
      });
    };

    return [
      doUpdate,
      {
        data: updatePaymentMethodData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
      },
    ];
  }, [
    doUpdatePaymentMethod,
    existingData,
    updatePaymentMethodData,
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
  ]);

  const useDeletePaymentMethod = useCallback(() => {
    const doDelete = async data => {
      doUpdatePaymentMethod({
        id: existingData?.id ?? 0,
        remove: true,
      });
    };

    return [
      doDelete,
      {
        data: updatePaymentMethodData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
      },
    ];
  }, [
    doUpdatePaymentMethod,
    existingData,
    updatePaymentMethodData,
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
  ]);

  useEffect(() => {
    if (isUpdateSuccess) {
      invalidateCompanyProfile();
      dispatch(profileApi.util.invalidateTags(['Profile']));
    }
  }, [isUpdateSuccess]);

  const { data: companyUsers, isFetching } = useGetUsersListQuery({
    p: 0,
    t: 500,
    ob: 'cmpny.name',
    o: 'a',
  });

  const usersList: any[] = useMemo(() => {
    if (!companyUsers?.data || isFetching) return [];

    const result = [...companyUsers.data]
      .sort((a, b) =>
        a.firstName?.toLowerCase().localeCompare(b?.firstName.toLowerCase()),
      )
      .map(item => {
        const truncatedName = limitStringMaxCharacters(item.fullName || '', 30);
        return {
          label: truncatedName,
          name: truncatedName,
          id: item.id,
        };
      });
    return _.uniqBy(result, 'id');
  }, [companyUsers, isFetching]);

  const { data: companyProfile } = useGetProfileQuery(undefined, {
    skip: !openPaymentMethodDrawer,
  });
  const paymentNames =
    companyProfile?.paymentInfo?.map(payment => payment.name) || [];

  useEffect(() => {
    if (!openPaymentMethodDrawer || !existingData) {
      return;
    }
    // ********* Details Form *********
    const detailsUpdated = [...detailsFormElementsFields];
    const detailsPaymentTypeField = detailsUpdated.find(
      field => field.fieldName === 'type',
    );
    if (detailsPaymentTypeField) {
      detailsPaymentTypeField.value = isCreditCard ? 'Credit Card' : 'ACH';
    }

    const detailsLastFourField = detailsUpdated.find(
      field => field.fieldName === 'lastFour',
    );
    if (detailsLastFourField) {
      detailsLastFourField.value = '************' + existingData.lastFour;
      detailsLastFourField.placeholder = isCreditCard
        ? 'Credit Card Number'
        : 'Account Number';
      detailsLastFourField.label = isCreditCard
        ? 'Credit Card Number'
        : 'Account Number';
      detailsLastFourField.endAdornmentIcon = <CreditCardIcon />;
    }

    const detailsRoutingNumberField = detailsUpdated.find(
      field => field.fieldName === 'routingNumber',
    );
    if (detailsRoutingNumberField) {
      detailsRoutingNumberField.visible = !isCreditCard;
    }

    const assignedUsersField = detailsUpdated.find(
      field => field.fieldName === 'selectedUsersToAssignedPaymentMethod',
    );

    const preselectedAssignedUsers = usersList.filter(user =>
      existingData?.assigneeUsers?.some(assignee => assignee.id === user.id),
    );

    if (assignedUsersField) {
      assignedUsersField.options = usersList;
      assignedUsersField.value = preselectedAssignedUsers;
    }

    setDetailsFormElementsFields(detailsUpdated);
    // ********* Edit Form *********
    const updated = [...editFormElementsFields];

    const editPaymentTypeField = updated.find(
      field => field.fieldName === 'type',
    );
    if (editPaymentTypeField) {
      editPaymentTypeField.value = isCreditCard ? 'Credit Card' : 'ACH';
    }

    const editLastFourField = updated.find(
      field => field.fieldName === 'lastFour',
    );
    if (editLastFourField) {
      editLastFourField.value = '************' + existingData.lastFour;
      editLastFourField.placeholder = isCreditCard
        ? 'Credit Card Number'
        : 'Account Number';
      editLastFourField.label = isCreditCard
        ? 'Credit Card Number'
        : 'Account Number';
    }

    const editRoutingNumberField = updated.find(
      field => field.fieldName === 'routingNumber',
    );
    if (editRoutingNumberField) {
      editRoutingNumberField.visible = !isCreditCard;
    }

    const editSubscribeField = updated.find(
      field => field.fieldName === 'subscriptionPaymentMethod',
    );
    const editSubscribeSwitchField = updated.find(
      field => field.fieldName === 'default',
    );
    if (editSubscribeField && editSubscribeSwitchField) {
      editSubscribeField.visible = !isSubscriptionPaymentMethod;
      editSubscribeSwitchField.visible = isSubscriptionPaymentMethod;

      if (!isSubscriptionPaymentMethod) {
        editSubscribeField.value = [];
      }
    }
    const editAssignedUsersField = updated.find(
      field => field.fieldName === 'selectedUsersToAssignedPaymentMethod',
    );

    if (editAssignedUsersField) {
      editAssignedUsersField.options = usersList;
      editAssignedUsersField.value = preselectedAssignedUsers;
    }
    setEditFormElementsFields(updated);
  }, [existingData, openPaymentMethodDrawer, usersList]);

  const handleOnChange = useCallback((output, fieldName) => {
    if (fieldName === 'name') {
      const nameField = output.find(field => field.fieldName === 'name');
      const nameAlreadyExists =
        paymentNames.includes(nameField?.value) &&
        nameField?.value !== existingData?.name;
      setDisableSaveButton(nameAlreadyExists);
      nameField.hideValidationMessage = !nameAlreadyExists;
      if (nameAlreadyExists) {
        nameField.validationMessage = 'This name already exists. Try again.';
        nameField.isValid = false;
      }
    }
  }, []);

  return (
    <ECDrawerDetails
      open={openPaymentMethodDrawer}
      anchor="right"
      onClose={handleClose}
    >
      <ECPaper
        sx={{
          height: '100%',
          paddingTop: '64px',
          boxShadow: 'none',
        }}
        role="presentation"
      >
        <EditForm2
          row={existingData}
          formConfig={editFormElements.config}
          formFields={editFormElementsFields}
          detailsConfig={detailsFormElements.config}
          detailsFields={detailsFormElementsFields}
          useUpdateMutation={useUpdatePaymentMethod}
          useDeleteMutation={
            isSubscriptionPaymentMethod ? null : useDeletePaymentMethod
          }
          saveButtonDisabled={disableSaveButton}
          onChange={handleOnChange}
          onClose={handleClose}
        />
      </ECPaper>
    </ECDrawerDetails>
  );
}
