import { ECBox } from 'app/components/ECBox';
import { ECStack } from 'app/components/ECStack';
import { Helmet } from 'react-helmet-async';
import { useCustomerUser } from 'app/hooks/customerUser.use-case';
import { ForbiddenPage } from '../ForbiddenPage';
import ReviewRecords from './ReviewYourRecords';
import DashboardHelpCenter from './DashboardHelpCenter';
import ProfileSetup from './ProfileSetup';
import Banners from './Banners';

export function OnboardingPage() {
  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="User dashboard" />
      </Helmet>

      <ECBox p={3}>
        <ECStack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          gap={3}
          alignItems="flex-start"
        >
          <ECBox flexShrink={0} width={{ xs: '100%', sm: '100%', md: 880 }}>
            <ReviewRecords />
            <Banners />
          </ECBox>

          <ECBox flex={1} minWidth={350} width={{ xs: '100%', sm: '100%' }}>
            <ProfileSetup />
          </ECBox>
        </ECStack>
      </ECBox>
      <ECBox p={3} sx={{ marginTop: -5 }}>
        <DashboardHelpCenter />
      </ECBox>
    </>
  );
}
