import { useCallback, useEffect, useState } from 'react';
import { ECBox, ECEasyFormCreate, ECEasyFormFieldType } from 'app/components';
import { useMediaQuery, useTheme } from '@mui/material';
import _ from 'lodash';
interface Step3Props {
  existingData?: any;
  onSubmit?: (data: any, output: any) => void;
}

const fancyFormElementsCreate = require('./fancy_form_config_step3_create.json');

const defaultIndustrialSectorType = 'RESTAURANT';

export const Step3 = ({ onSubmit, existingData }: Step3Props) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      if (!field) return;

      if (field.type === 'group' && field.subFields) {
        return { ...field, subFields: field.subFields.map(handleExistingData) };
      }

      return {
        ...field,
        value:
          field.fieldName === 'video'
            ? field.value || ''
            : existingData?.industryCode || '',
      };
    },
    [existingData],
  );

  const [formFields, setFormFields] = useState<any[]>(
    () => fancyFormElementsCreate.fields?.map(handleExistingData) || [],
  );

  useEffect(() => {
    setFormFields(
      fancyFormElementsCreate.fields?.map(handleExistingData) || [],
    );
  }, [existingData, handleExistingData]);

  const submitForm = useCallback(
    (formData, output) => {
      const doSubmit = async data => {
        onSubmit?.(
          {
            ...existingData,
            industryCode: data.industrialSector || defaultIndustrialSectorType,
          },
          output,
        );
      };
      return [doSubmit, {}];
    },
    [onSubmit, existingData],
  );

  return (
    <ECBox
      paddingInline={isMobileSize ? 0 : `15%`}
      paddingLeft={isMobileSize ? 0 : `20%`}
      alignItems="center"
      justifyContent="center"
      display="flex"
      flexDirection="column"
    >
      <ECEasyFormCreate
        useCreateMutation={submitForm}
        formConfig={fancyFormElementsCreate.config}
        formFields={formFields}
        existingData={existingData}
        hideCancelButton
        showSaveButton={false}
        showWideSaveButton
      />
    </ECBox>
  );
};
