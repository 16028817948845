import { QueryParams } from 'types/QueryParams';
import { WarrantyMFUpdateDTO } from 'types/Warranty';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['WarrantyComponent'],
});

export const warrantyApi = apiWithTag.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    getAssetsWarrantyComponent: builder.query<
      any,
      { id: string; queryParams: QueryParams }
    >({
      query: params => {
        return {
          url: `asset/${params?.id}/warranty/component`,
          params: params?.queryParams,
        };
      },
      providesTags: ['WarrantyComponent'],
    }),

    getAssetsWarranty: builder.query<any, string>({
      query: params => {
        return {
          url: `asset/${params}/warranty`,
        };
      },
      providesTags: ['WarrantyComponent'],
    }),

    updateWarrantyPeriodTable: builder.mutation<void, WarrantyMFUpdateDTO>({
      query: body => {
        return {
          url: `asset/${body?.id}/warranty`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['WarrantyComponent'],
    }),

    getWarrantyPeriods: builder.query<any, void>({
      query: params => {
        return {
          url: `/lookup/period/warranty`,
        };
      },
    }),

    getWarrantyTypes: builder.query<any, void>({
      query: params => {
        return {
          url: `/lookup/warranty/type`,
        };
      },
    }),
  }),
});

export const {
  useGetAssetsWarrantyComponentQuery,
  useLazyGetAssetsWarrantyComponentQuery,
  useGetAssetsWarrantyQuery,
  useUpdateWarrantyPeriodTableMutation,
  useGetWarrantyPeriodsQuery,
  useGetWarrantyTypesQuery,
} = warrantyApi;
