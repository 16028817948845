import parse from 'html-react-parser';
import { customDateFormatter } from 'utils/dates';

// If entry is an array, try to get only labels or names.
export function getHistoryEntry(body: any, bodyKey: string) {
  if (body?.[bodyKey] === null || body?.[bodyKey] === undefined) return;
  let historyEntry = JSON.stringify(body[bodyKey]);
  if (Array.isArray(body[bodyKey])) {
    historyEntry = body[bodyKey]
      .map((item: any) => item?.label || item?.name || item)
      ?.join(', ');
  }

  const hasInitialPTag = historyEntry?.slice(1, 4) === '<p>';
  if (hasInitialPTag) {
    historyEntry = `"${historyEntry?.slice(4)?.replace('</p>', '')}`;
  }

  historyEntry = historyEntry
    .replaceAll('no-reply@ecotrak.com', '')
    .replaceAll('<', '')
    .replaceAll('>', '')
    .replaceAll('\\r', '')
    .replaceAll(/\\"/g, '"');

  if (historyEntry.startsWith('"') && historyEntry.endsWith('"')) {
    historyEntry = historyEntry.slice(1, -1);
  }

  if (bodyKey === 'resetDateAndTime') {
    historyEntry = customDateFormatter({
      raw: historyEntry,
      format: 'MMM d, yyyy hh:mm a',
      fallback: '-',
    });
  }

  return parse(historyEntry.replaceAll('\\n', ''));
}

// if it is an array, check if it has at least one item
export function isValidHistoryEntry(body: any, bodyKey: string) {
  if (Array.isArray(body[bodyKey])) {
    return body[bodyKey]?.length > 0;
  }
  return true;
}

export function removeLeadingTrailingQuotes(str: any) {
  if (typeof str === 'string' && str.startsWith('"') && str.endsWith('"')) {
    return str.slice(1, -1);
  }
  return str;
}

export function checkIfValidArrayEntry(previousBody, currentBody, bodyKey) {
  if (
    Array.isArray(previousBody?.[bodyKey]) &&
    Array.isArray(currentBody?.[bodyKey])
  ) {
    const lastBody = previousBody[bodyKey]?.join(',');
    const currentBodyStr = currentBody[bodyKey]?.join(',');
    return (
      lastBody !== currentBodyStr &&
      previousBody[bodyKey]?.length !== currentBody[bodyKey]?.length
    );
  }

  return true;
}

export const hasWarrantyFeatures = obj =>
  obj &&
  Object.keys(obj).some(
    key => (key.startsWith('material') || key.startsWith('labor')) && obj[key],
  );

export const hasValidWarrantyFeatures = obj =>
  obj &&
  Object.keys(obj).some(
    key =>
      (key.startsWith('material') || key.startsWith('labor')) &&
      obj[key] &&
      !String(obj[key]).includes('undefined') &&
      !String(obj[key]).includes('-'),
  );

export const hasManufacturerWarranty = (body, beforeBody) =>
  [body, beforeBody].some(
    obj =>
      obj &&
      (Object.keys(obj).some(key => key.startsWith('period') && obj[key]) ||
        'note' in obj),
  );

export const isObjectOrArray = (value: any) =>
  (typeof value === 'object' && value !== null) || Array.isArray(value);

export const formatValue = (value: any, module: string): string => {
  const expectedModule = 'asset';
  if (Array.isArray(value)) {
    if (value.every(item => ['string', 'number'].includes(typeof item))) {
      return value.join(', ');
    } else {
      return value.map(item => formatValue(item, module)).join('\n\n');
    }
  } else if (typeof value === 'object' && value !== null) {
    return Object.entries(value)
      .map(([key, val]) =>
        module !== expectedModule
          ? `${key}: ${formatValue(val, module)}`
          : formatValue(val, module),
      )
      .join('\n');
  }
  return String(value);
};

export const parseSupplierContacts = (module, data) => {
  if (module !== 'supplier') return [];

  const { supplierContacts, locationAddress, ...otherFieldsToRender } =
    data ?? {};
  const supplierContactsObjects = supplierContacts?.reduce(
    (acc, item, index) => {
      const indexToUse = index + 1;
      acc = {
        ...acc,
        [`contact${indexToUse}FirstName`]: item.firstName,
        [`contact${indexToUse}LastName`]: item.lastName,
        [`contact${indexToUse}Email`]: item.email,
        [`contact${indexToUse}PhoneNumber`]: item.phone,
        [`contact${indexToUse}JobTitle`]: item.jobTitle,
      };
      return acc;
    },
    {},
  );
  if (typeof otherFieldsToRender.status === 'boolean')
    otherFieldsToRender.status = otherFieldsToRender.status
      ? 'Active'
      : 'Inactive';
  otherFieldsToRender.location = locationAddress;
  return [supplierContactsObjects, otherFieldsToRender];
};
