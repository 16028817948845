import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { StatusGroupNames } from 'types/StatusGroups';
import { ConfirmStatusChangeModal } from './confirm-only';
import { DashboardActionProps } from 'types/Dashboard';

export const approveAction = permissions => ({
  buttonLabel: 'Approve',
  disabledRule: (selectionLength: number) => selectionLength < 1,
  modalContent: requirePermissionWrapper<DashboardActionProps>(props => (
    <ConfirmStatusChangeModal
      {...props}
      title={`Confirm you want to approve ${props.module}s`}
      confirmationMessage={`All ${props.module}s selected will be Approved.`}
      statusTo={StatusGroupNames.Approved}
    />
  )),
  scopes: permissions,
});
