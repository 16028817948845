import { Checkbox, IconButton, InputAdornment, Stack } from '@mui/material';
import { ECTypography } from '../ECTypography';
import { setActiveFilter } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { ECMenuItem } from '../ECSelect';
import { ECTextField } from '../ECTextField';
import { ECBox } from '../ECBox';
import { ECAutocompleteStyled } from '../ECAutocomplete';
import { ECPopover } from '../ECPopover';
import { ArrowDropDown } from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetServiceProviderCustomerListQuery } from 'services/serviceProviderApi';
import _ from 'lodash';
import { ECPopper } from '../ECPopper';
import { ECChip } from '../ECChip';
import { ECIconButton } from '../ECIconButton';

export const ECCustomersFilter = () => {
  const dispatch = useDispatch();

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const [anchorEl, setAnchorElManufacturer] = useState<HTMLDivElement | null>(
    null,
  );
  const [options, setOptions] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState('');

  const open = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);

  const idPopover = useMemo(() => {
    return open ? 'popover' : undefined;
  }, [open]);

  const inputRef = useRef(null);

  const handleClickManufacturer = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      setAnchorElManufacturer(inputRef?.current);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorElManufacturer(null);
  }, []);

  const { data: customers, isFetching } = useGetServiceProviderCustomerListQuery({
    params: {
      t: 0,
      p: page,
      ob: 'cmpny.name',
      st: 1,
    },
  });

  useEffect(() => {
    const customersData: any[] = customers?.data as any;
    if (customersData && !isFetching) {
      setOptions(prev => {
        return _.uniqBy(
          [
            ...prev,
            ...(customersData.map(internalTech => ({
              id: internalTech?.id,
              label: internalTech?.name,
              fieldName: internalTech?.name,
            })) || []),
          ],
          'id',
        );
      });
    }
  }, [customers, isFetching]);

  const optionsWithAll = useMemo(() => {
    return [
      {
        label: 'all',
        fieldName: 'all',
        id: 0,
      },
      ...(options || []),
    ];
  }, [options]);

  const inputChip = useMemo(() => {
    if (
      !activeFilter?.customers?.length ||
      activeFilter?.customers?.length === options?.length
    ) {
      return <ECChip sx={{ width: '48px', height: '24px', }} label="All" color="Light Grey" />;
    }

    return <ECChip sx={{ width: '48px', height: '24px' }}  label={activeFilter?.customers?.length} color="Dark Blue" />;
  }, [activeFilter?.customers, options]);

  const handleChangeManufacturer = useCallback(
    (event, value, reason) => {
      if (value[value?.length - 1]?.id === -1) {
        return;
      }

      setSearchText('');
      if (value[value?.length - 1]?.fieldName === 'all') {
        dispatch(
          setActiveFilter({
            customers:
              activeFilter?.customers?.length === options?.length
                ? []
                : options?.map(option => ({
                    id: option.id,
                    name: option.fieldName || option.name,
                  })),
            isSelectAllCustomers: !(
              activeFilter?.customers?.length === options?.length
            ),
          }),
        );
        return;
      }
      dispatch(
        setActiveFilter({
          customers: value?.map(v => ({
            id: v.id,
            name: v.fieldName || v.name,
          })),
          isSelectAllCustomers: value?.length === options?.length,
        }),
      );
    },
    [optionsWithAll, activeFilter?.customers, options],
  );

  const renderPopper = useCallback(props => {
    return (
      <ECPopper
        {...props}
        sx={{
          transform: 'translate3d(8px, 53px, 0px) !important',
          minWidth: 'fit-content !important',
          mt: '8px !important',
          '& .MuiPopover-paper': {
            boxShadow: 'none',
          },
          '.MuiAutocomplete-listbox': {
            boxShadow: 'none',
          },
          '.MuiPaper-elevation1': {
            boxShadow: 'none',
          },
          '.MuiPaper-elevation': {
            boxShadow: 'none',
          },
        }}
      />
    );
  }, []);

  return (
    <Stack
      direction="row"
      alignItems="center"
      width="200px"
      height="40px"
      border="1px solid #E0E0E0"
      borderRadius="4px"
      display='flex'
      justifyContent='space-around'
    >
      {optionsWithAll && (
        <>
          <ECTypography
            color={theme => theme.palette.text.secondary}
            variant="caption"
          >
            Customers: 
          </ECTypography>
          <ECBox display="flex" alignItems='center' onClick={handleClickManufacturer} ref={inputRef}>
            {inputChip}  
            <ECIconButton
              type="default"
              size="small"
              noPadding
              sx={{ marginLeft: '5px' }}
            >
              <ArrowDropDown />
            </ECIconButton>
          </ECBox>
          <ECPopover
            id={idPopover}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{
              bgcolor: 'transparent',
              '& .MuiPopover-paper': {
                minWidth: '350px',
                bgcolor: 'transparent',
              },
            }}
          >
            <ECBox
              display="flex"
              bgcolor="white !important"
              height="470px"
              width="350px"
            >
              <ECAutocompleteStyled
                multiple
                open
                disablePortal
                sx={{
                  width: '350px',
                  '&.MuiAutocomplete-popper': {
                    transform: 'translate3d(8px, 53px, 0px) !important',
                  },
                }}
                options={optionsWithAll}
                value={activeFilter?.customers || []}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                ListboxProps={{
                  sx: {
                    height: '400px',
                    bgcolor: 'white',
                    '.MuiAutocomplete-listbox': {
                      height: '400px',
                      bgcolor: 'white',
                    },
                  },
                }}
                disableCloseOnSelect
                onChange={handleChangeManufacturer}
                renderInput={params => (
                  <ECBox display="flex" padding={1} bgcolor="white">
                    <ECTextField
                      {...params}
                      placeholder="Search"
                      sx={{
                        minWidth: 'fit-content !important',
                        padding: '0px !important',
                        '.MuiFilledInput-root': {
                          paddingRight: '0px !important',
                        },
                      }}
                      hiddenLabel
                      variant="filled"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <></>,
                      }}
                    />
                  </ECBox>
                )}
                renderTags={() => {
                  return '';
                }}
                PopperComponent={renderPopper}
                renderOption={(props, option, state) => {
                  if (option?.fieldName === 'all') {
                    return (
                      <ECMenuItem
                        {...props}
                        key={option?.id || option}
                        id={option?.fieldName || option}
                      >
                        <ECTypography
                          variant="body1"
                          color={theme => theme.palette.primary.main}
                        >
                          {activeFilter?.customers?.length ===
                          options?.length
                            ? 'Deselect All'
                            : 'Select All'}
                        </ECTypography>
                      </ECMenuItem>
                    );
                  }

                  return (
                    <ECMenuItem
                      {...props}
                      data-name={
                        option?.id === optionsWithAll?.[0]?.id ? 'first' : ''
                      }
                      sx={{ width: '100%' }}
                      key={option?.id || option}
                      id={option?.fieldName || option}
                      disabled={option?.disabled}
                    >
                      <Checkbox
                        sx={{ p: 0, m: 0, mr: 1 }}
                        checked={state.selected}
                      />
                      {option?.label}
                    </ECMenuItem>
                  );
                }}
              />
            </ECBox>
          </ECPopover>
        </>
      )}
    </Stack>
  );
};
