import { useCallback, useEffect, useState } from 'react';
import {
  useUpdateServiceProviderByIdMutation,
  useGetServiceProviderCustomerByIdQuery,
} from 'services/serviceProviderApi';
import {
  ECBox,
  ECButton,
  ECTypography,
  ECWorkflowTemplate,
} from 'app/components';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import {
  Outlet,
  useParams,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router';
import { useGetProfileQuery } from 'services/profileApi';
import { ECPopover } from 'app/components/ECPopover';
import { useGetSignUpRequiredFieldsQuery } from 'services/spAuthApi';

const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const formElementsDetails = require('./fancy_form_config_details.json');

export function CustomerDetailsPage() {
  const navigate = useNavigate();
  const match = useMatch('/panel/*');
  const { id } = useParams();
  const location = useLocation();
  const { from } = location.state || { from: 'customer-management' };
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);

  const isDetailsTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('notes') &&
    !window.location.href.includes('activity');

  const {
    data: customer,
    refetch,
    isLoading: isLoadingCustomer,
  } = useGetServiceProviderCustomerByIdQuery(id ? Number(id) : 0, {
    skip: !isDetailsTab,
  });

  // needs to refresh the data when the tab is changed, because attachments changes
  // the required docs chip
  useEffect(() => {
    if (isDetailsTab) {
      refetch();
    }
  }, [isDetailsTab]);

  const { data: dataCompanyDetails, isSuccess: isSucceccCompanyProfile } =
    useGetProfileQuery();

  const {
    data: dataSignupRequiredFields,
    isSuccess: isSucceccSignupRequiredFields,
  } = useGetSignUpRequiredFieldsQuery(dataCompanyDetails?.id ?? 0, {
    skip: !dataCompanyDetails?.id,
  });
  useEffect(() => {
    if (
      from === 'pendingPage' &&
      dataCompanyDetails &&
      isSucceccCompanyProfile &&
      dataSignupRequiredFields &&
      isSucceccSignupRequiredFields
    ) {
      const arraySignupRequiredFields = Array.isArray(dataSignupRequiredFields)
        ? dataSignupRequiredFields
        : [dataSignupRequiredFields];

      // below changes after the hardcode in the BE changes,
      const replacements: { [key: string]: string } = {
        sic: 'sicId',
        remittanceEmail: 'emailRemittance',
        remittancePhone: 'phoneRemittance',
      };

      const modifiedArray = arraySignupRequiredFields.map(
        item => replacements[item] || item,
      );

      const isAnyFieldEmpty = modifiedArray.some(
        key => !dataCompanyDetails[key],
      );
      setShowEditProfileModal(isAnyFieldEmpty);
    }
  }, [
    from,
    dataCompanyDetails,
    isSucceccCompanyProfile,
    setShowEditProfileModal,
    dataSignupRequiredFields,
    isSucceccSignupRequiredFields,
  ]);

  const [
    doUpdateSPDetails,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset,
    },
  ] = useUpdateServiceProviderByIdMutation();

  const useUpdateSPDetails = useCallback(() => {
    const doUpdate = async data => {
      doUpdateSPDetails({
        id: id ? +id : 0,
        vendorId: data.vendorId,
        flagsIds: [],
        status: 1,
      });
    };

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset,
      },
    ];
  }, [
    id,
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    reset,
    doUpdateSPDetails,
  ]);

  return (
    <>
      <ECWorkflowTemplate
        tabsAsLinks
        title={customer?.name || ''}
        summaryData={[
          {
            id: 'companyName',
            label: 'Company Name',
            data: customer?.name,
            type: SummaryFieldTypes.Text,
          },
          {
            id: 'id',
            label: 'Customer ID',
            data: customer?.id,
            type: SummaryFieldTypes.Text,
          },
          {
            id: 'ratesAndTerms',
            label: 'Rates & Terms',
            data: customer?.rateAndTermsStatus,
            type: SummaryFieldTypes.SingleChip,
            showAddedLabel: true,
          },
          {
            id: 'spRequiredDocuments',
            label: 'SP Required Documents',
            data: customer?.requiredDocsStatus,
            type: SummaryFieldTypes.SingleChip,
            showAddedLabel: true,
          },
        ]}
        editConfig={fancyFormElementsEdit.data.config}
        editFields={fancyFormElementsEdit.data.fields}
        detailsConfig={formElementsDetails.data.config}
        detailsFields={formElementsDetails.data.fields}
        useUpdateMutation={useUpdateSPDetails}
        detailsData={customer}
        attachmentTabIdAttribute="profileId"
        historyTabIdAttribute="profileId"
        attachmentsTitle="Docs & Attachments"
        imgSrc={customer?.logo || ''}
        showLogoOnError={true}
        hideNoteTab={true}
        hideActivityTab={true}
        additionalTabs={[
          {
            value: 'rates-and-terms',
            link: 'rates-and-terms',
            label: 'Rates And Terms',
            editable: true,
            content: <Outlet />,
          },
          {
            value: 'assets',
            link: 'assets',
            label: 'Assets',
            editable: true,
            content: <Outlet />,
          },
        ]}
        showSkeletonLoader={isLoadingCustomer}
      />
      <ECPopover
        open={showEditProfileModal}
        onClose={() => setShowEditProfileModal(false)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ marginLeft: '10%' }}
      >
        <ECBox display="flex" flexDirection="column" p={2}>
          <ECTypography variant="body1" width={'300px'}>
            {`${customer?.name} requires some additional fields to be populated in your company profile`}
          </ECTypography>
          <ECBox mt={2} display="flex" justifyContent="flex-end">
            <ECButton
              variant="text"
              onClick={() => setShowEditProfileModal(false)}
            >
              Close
            </ECButton>
            <ECButton
              variant="contained"
              color="info"
              onClick={() => {
                navigate(`${match?.pathnameBase}/sp/configuration/profile`, {
                  state: { from: 'customerDetailPage' },
                });
              }}
            >
              Edit Profile
            </ECButton>
          </ECBox>
        </ECBox>
      </ECPopover>
    </>
  );
}
