import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ECTableCell, ECTableRow } from 'app/components/ECTable';
import { SortableItemProps } from 'types/core/ECTable';
import { EditableDynamicTableCell } from './ECEditableDynamicTableCell';
import { DynamicTableCell } from './ECDynamicTableCell';
import { ECIconButton } from 'app/components/ECIconButton';
import { DragIndicator } from '@mui/icons-material';

export const SortableItem: React.FC<SortableItemProps> = props => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.row?.id || '',
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const {
    row,
    rowIndex,
    cols,
    isEditable,
    onRowPress,
    onChange,
    onDuplicatePress,
    onRemove,
    tableLayoutFilter,
    t,
    customFieldsData,
    customEditFieldsData,
  } = props;

  return (
    <ECTableRow
      style={style}
      ref={setNodeRef}
      key={`table-row-${row?.id}-${rowIndex}`}
      sx={{
        '&:hover': {
          backgroundColor: theme => theme.palette.table.selectedRow,
          cursor: 'pointer',
        },
      }}
      onClick={() => onRowPress?.(row)}
    >
      {cols.map((col, index) => {
        if (
          col.visible &&
          (!tableLayoutFilter?.body?.[0]?.visibleColumns ||
            tableLayoutFilter?.body?.[0]?.visibleColumns?.includes(col.title))
        ) {
          return isEditable && !col.disabled ? (
            <EditableDynamicTableCell
              col={col}
              index={index}
              row={row}
              isNestedCell={false}
              onChange={onChange}
              customEditFieldsData={customEditFieldsData}
            />
          ) : (
            <DynamicTableCell
              col={col}
              index={index}
              row={row}
              parentRow={undefined}
              isNestedCell={false}
              onRowPress={onRowPress}
              onDuplicatePress={onDuplicatePress}
              onRemove={onRemove}
              customFieldsData={customFieldsData}
              // rowIndex
              t={t}
            />
          );
        }
        return null;
      })}
      <ECTableCell
        key={row?.id || `rowIndex-${rowIndex}`}
        scope="row"
        sx={{ minWidth: '10px' }}
      >
        <ECIconButton
          size="small"
          sx={{ color: theme => theme.palette.button.active }}
          {...listeners}
          {...attributes}
        >
          <DragIndicator />
        </ECIconButton>
      </ECTableCell>
    </ECTableRow>
  );
};
