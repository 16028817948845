import { ECDynamicPageTemplate } from 'app/components';
import { useEffect, useState } from 'react';
import { useGetProblemListQuery } from 'services/problemApi';
import {
  useAddProblemToAssetTypeMutation,
  useLazyGetProblemToAssetListQuery,
  useEditProblemTypeMutation,
} from 'services/assetTypeToProblemApi';

import { populateDropDownFields } from 'utils/pageUtils';
import { loadPageScopes } from 'utils/pageScopes';

import { useTranslation } from 'react-i18next';

const tableConfig = require('./tableConfig.json');
const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');

interface ProblemToAssetTypesPageProps {
  marginTop?: boolean;
}

export function ProblemToAssetTypesPage({
  marginTop = true,
}: ProblemToAssetTypesPageProps) {
  const { t } = useTranslation();
  const [formFields, setFormFields] = useState(
    fancyFormElementsEdit.data.fields,
  );

  useEffect(() => {
    loadPageScopes('assetTypeProblem');
  }, []);

  const { data, error, isLoading, isSuccess } = useGetProblemListQuery({});

  useEffect(() => {
    if (data && isSuccess) {
      populateDropDownFields({
        responseData: data,
        createFormFields: fancyFormElementsCreate.data.fields,
        editFormFields: fancyFormElementsEdit.data.fields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'name',
        formFieldName: 'problems',
      });
    }
  }, [data, isSuccess]);

  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      useLazyGetListQuery={useLazyGetProblemToAssetListQuery}
      useCreateMutation={useAddProblemToAssetTypeMutation}
      useUpdateMutation={useEditProblemTypeMutation}
      tableConfig={tableConfig.data.config}
      //pageTitle={'Problem to Asset Group'}
      pageTitle={t('translation:pages.problemToAssetGroup.title')}
      createFormConfig={fancyFormElementsCreate.data.config}
      createFormFields={fancyFormElementsCreate.data.fields}
      editFormConfig={fancyFormElementsEdit.data.config}
      editFormFields={formFields}
      downloadFileName="problem-asset.csv"
      enableExport={true}
      marginTop={marginTop}
      shouldNotUseActiveFilter
    />
  );
}
