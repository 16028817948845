import {
  ECBox,
  ECButton,
  ECCard,
  ECCardContent,
  ECTypography,
  LinearProgressWithLabel,
} from 'app/components';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useGetChecklistQuery } from 'services/dashboardApi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useCreateServiceRequestContext } from 'store/contexts';

type ProfileSetupStepProps = {
  step: number;
  title: string;
  type: 'add' | 'view';
  onClick: () => void;
  CustomButton?: JSX.Element;
};

const ProfileSetupStep = ({
  onClick,
  step,
  title,
  type,
  CustomButton,
}: ProfileSetupStepProps) => {
  return (
    <ECCard
      sx={{
        marginBottom: 1,
      }}
    >
      <ECCardContent
        sx={theme => ({
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          background:
            type === 'view'
              ? `${theme.palette.graphic.alert.success}1A`
              : 'none',
          '&:hover': {
            background:
              type === 'add'
                ? `${theme.palette.border.primary}`
                : `${theme.palette.graphic.alert.success}1A`,
          },
        })}
      >
        {type === 'add' && (
          <ECTypography
            sx={theme => ({
              background: theme.palette.background.darkBg,
              fontSize: '20px',
              width: '32px',
              color: theme.palette.common.white,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              marginRight: '24px',
            })}
          >
            {step}
          </ECTypography>
        )}
        {type === 'view' && (
          <CheckCircleIcon
            fontSize="large"
            sx={theme => ({
              color: theme.palette.text.alert.success,
              marginRight: '18px',
            })}
          />
        )}
        <ECTypography
          variant="subtitle2"
          sx={{
            width: '80%',
          }}
        >
          {title}
        </ECTypography>
        {CustomButton ? (
          CustomButton
        ) : (
          <ECButton variant="text" onClick={onClick}>
            {type === 'add' && (
              <>
                <AddIcon />
                Add
              </>
            )}
            {type === 'view' && <>View</>}
          </ECButton>
        )}
      </ECCardContent>
    </ECCard>
  );
};

export default function ProfileSetup() {
  const [steps, setSteps] = useState<any>(null);
  const { data, isSuccess } = useGetChecklistQuery({});
  const [completedPercent, setCompletedPercent] = useState(0);

  const { dispatch } = useCreateServiceRequestContext();

  const handleOpenServiceRequestDrawer = () => {
    dispatch({ type: 'open' });
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!isSuccess || !data) return;

    const {
      assignServiceProvider,
      inviteServiceProvider,
      location,
      paymentMethod,
      user,
      workorder,
    } = data;
    const stepsConfig = [
      {
        step: 1,
        title: 'Add Locations',
        type: location ? 'view' : 'add',
        path: `/panel/locations?add=${!location}`,
      },
      {
        step: 2,
        title: 'Invite Service Providers',
        type: inviteServiceProvider ? 'view' : 'add',
        path: `/panel/service-providers/service-providers?add=${!inviteServiceProvider}`,
      },
      {
        step: 3,
        title: 'Assign Service Providers',
        type: assignServiceProvider ? 'view' : 'add',
        path: '/panel/service-providers/trade-assignment',
        CustomButton: (
          <ECButton
            variant="text"
            onClick={() =>
              navigate('/panel/service-providers/trade-assignment')
            }
          >
            <PersonAddAltIcon />
            Assign
          </ECButton>
        ),
      },
      {
        step: 4,
        title: 'Add payment method',
        type: paymentMethod ? 'view' : 'add',
        path: `/panel/configuration/profile/billing?add=${!paymentMethod}`,
      },
      {
        step: 5,
        title: 'Create a Service Request',
        type: workorder ? 'view' : 'add',
        path: '/panel/work-orders',
        CustomButton: (
          <ECButton onClick={handleOpenServiceRequestDrawer} variant="text">
            <AddIcon />
            Create
          </ECButton>
        ),
      },
      {
        step: 6,
        title: 'Add Users',
        type: user ? 'view' : 'add',
        path: `/panel/configuration/users/management?add=${!user}`,
      },
    ];

    setSteps(
      stepsConfig.map(({ path, ...rest }) => ({
        ...rest,
        onClick: () => navigate?.(path),
      })),
    );
    const completedCount = Object.values({
      assignServiceProvider,
      inviteServiceProvider,
      location,
      paymentMethod,
      user,
      workorder,
    }).filter(Boolean).length;

    setCompletedPercent((completedCount / stepsConfig.length) * 100);
  }, [data, isSuccess]);

  return (
    <ECBox
      sx={{ padding: 3, borderRadius: 2, boxShadow: 3, minHeight: 600 }}
      flexWrap="wrap"
      width="100%"
      flexDirection={'column'}
      justifyContent={'flex-start'}
    >
      <ECBox display="flex" justifyContent="space-between" alignItems="center">
        <ECTypography variant="h6" gutterBottom fontWeight="bold" fontSize={16}>
          Complete to Place a Service Request
        </ECTypography>
      </ECBox>
      <ECBox paddingInline={'2.5px'} marginBottom={1}>
        <LinearProgressWithLabel
          value={completedPercent}
          sx={{ width: '100%' }}
        />
      </ECBox>
      <ECBox>
        {steps &&
          steps?.map(step => (
            <ProfileSetupStep
              key={step.step}
              onClick={step.onClick}
              step={step.step}
              title={step.title}
              type={step.type}
              CustomButton={step?.CustomButton}
            />
          ))}
      </ECBox>
    </ECBox>
  );
}
