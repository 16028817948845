import { ECBox, ECDynamicPageTemplate } from 'app/components';
import { useParams } from 'react-router';
import {
  useLazyGetAssetCircuitsListQuery,
  useLazyGetAssetByIdQuery,
} from 'services/assetApi';
import { Skeleton } from '@mui/material';
import {
  useGetWORefrigerantTrackingQuery,
  useGetWorkOrderByIdQuery,
} from 'services/workOrdersApi';
import { useCustomerUser } from 'app/hooks/customerUser.use-case';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';
import { useEffect, useMemo, useState } from 'react';
import { RTCard } from './RTCard';

export function RefrigerantTrackingPage() {
  const { id } = useParams();
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);
  const isCustomer = useCustomerUser();
  const [hasEditWorkorderPermission] = useHasPermission([P.EditWorkOrder]);

  const { data: workOrder } = useGetWorkOrderByIdQuery(
    { id: Number(id) },
    { skip: !id },
  );

  const [triggerAsset, resultAsset] = useLazyGetAssetByIdQuery({});
  const { data: assetObject, isSuccess: isSuccessAsset } = resultAsset;

  const isInternalManagedWO = Boolean(workOrder?.internal);

  useEffect(() => {
    if (workOrder?.assetId) {
      triggerAsset(workOrder.assetId);
    }
  }, [workOrder?.assetId, triggerAsset]);

  const canEditRTOnWorkOrder = useMemo(() => {
    if (!isSuccessAsset) return false;
    if (isCustomer)
      return (
        hasEditWorkorderPermission &&
        isInternalManagedWO &&
        Boolean(assetObject?.refrigerantTrackingEnable)
      );
    return (
      hasEditWorkorderPermission &&
      Boolean(assetObject?.refrigerantTrackingEnable)
    );
  }, [
    hasEditWorkorderPermission,
    isCustomer,
    isInternalManagedWO,
    assetObject?.refrigerantTrackingEnable,
    isSuccessAsset,
  ]);

  const { data: initialData, isLoading } = useGetWORefrigerantTrackingQuery(
    id,
    {
      skip: !id,
    },
  );

  const isVerificationAndTechnicianCompleted = useMemo(() => {
    return (
      !!initialData?.initialVerification?.id &&
      !!initialData?.finalVerification?.id &&
      !!initialData?.technician
    );
  }, [
    initialData?.initialVerification?.id,
    initialData?.finalVerification?.id,
    initialData?.technician,
  ]);

  const rtCardProps = {
    woId: id ?? '',
    initialData,
    shouldShowEditButton: canEditRTOnWorkOrder,
    onButtonClick: (updateRTMode: boolean) =>
      setUpdateButtonClicked(updateRTMode),
    isCustomer,
    isVerificationAndTechnicianCompleted,
  };

  const isCentered = isCustomer ? false : !isVerificationAndTechnicianCompleted;

  return (
    <>
      {isLoading ? (
        <ECBox pl={1}>
          <Skeleton
            variant="rectangular"
            height={32}
            width={'25%'}
            sx={{ marginBottom: 2, marginTop: 1 }}
          />
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton
              variant="rectangular"
              key={index}
              height={32}
              width={'50%'}
              sx={{ marginBottom: 0.5 }}
            />
          ))}
        </ECBox>
      ) : isCustomer ? (
        isInternalManagedWO && (
          <RTCard {...rtCardProps} isEditButtonCentered={false} />
        )
      ) : (
        <RTCard {...rtCardProps} isEditButtonCentered={isCentered} />
      )}
      {(initialData?.circuits?.length === 0 ||
        ((!isInternalManagedWO || !isVerificationAndTechnicianCompleted) &&
          !updateButtonClicked) ||
        (!isCustomer &&
          !isVerificationAndTechnicianCompleted &&
          !updateButtonClicked)) && (
        <ECDynamicPageTemplate
          shouldCacheLazyQuery
          marginTop={false}
          pageTitle="Refrigerant Charges"
          useLazyGetListQuery={useLazyGetAssetCircuitsListQuery}
          idToGetListBy={id}
          useUpdateMutation={{}}
          useCreateMutation={{}}
          hideSimpleSearchBar
          useSkeletonLoading
        />
      )}
    </>
  );
}
