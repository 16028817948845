import { ECBox } from 'app/components/ECBox';
import { ECButton } from 'app/components/ECButton';
import { ECRequirePermission } from 'app/components/ECRequirePermission';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { ECDrawerDetailsContent } from 'app/components/ECDrawerDetailsContent';
import { useCallback, useState } from 'react';
import { AddNewForm } from './AddNewForm';
import { Add } from '@mui/icons-material';
import { P } from 'types/Permission';
import { useGetProfileQuery } from 'services/profileApi';
import { BranchLimitUpgradeModal } from './BranchLimitUpgradeModal';

type AddNewProps = {
  shouldOpenDrawer?: boolean;
};

export const AddNew = ({ shouldOpenDrawer = false }: AddNewProps) => {
  const [open, setOpen] = useState(shouldOpenDrawer);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const { data } = useGetProfileQuery();

  const hasReachedLimit = data?.hitMaxBranchLimit;
  const maxBranchCount = data?.subscription?.maxBranchCount;

  const handleOpen = useCallback(() => {
    if (hasReachedLimit) {
      setShowUpgradeModal(true);
    } else {
      setOpen(true);
    }
  }, [hasReachedLimit]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCloseUpgradeModal = useCallback(() => {
    setShowUpgradeModal(false);
  }, []);

  return (
    <>
      <ECRequirePermission scopes={[P.AddBranch]}>
        <ECButton startIcon={<Add />} onClick={handleOpen} variant="outlined">
          Add New
        </ECButton>
      </ECRequirePermission>

      <ECDrawerDetails open={open} anchor="right" onClose={handleClose}>
        <ECBox pt={5} minHeight="100vh">
          <ECDrawerDetailsContent
            data={{}}
            EditForm={<AddNewForm onClose={handleClose} />}
            showOnlyDetailsTab={true}
            onClose={handleClose}
            editSelected={true}
            showEditButton={false}
            isCreateNewStep={true}
          />
        </ECBox>
      </ECDrawerDetails>

      <BranchLimitUpgradeModal
        isOpen={showUpgradeModal}
        onClose={handleCloseUpgradeModal}
        maxBranchCount={maxBranchCount}
      />
    </>
  );
};
