import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { ECCheckbox } from 'app/components/ECCheckbox';
import { FieldTypes } from 'app/components/ECForm';
import {
  ECTable,
  ECTableBody,
  ECTableContainer,
  ECTableHead,
  ECTableRow,
  ECTHCell,
} from 'app/components/ECTable';
import { EmptyStatePage } from 'app/pages/EmptyStatePage';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  useSaveFilterMutation,
  useUpdateFilterMutation,
} from 'services/filterApi';
import { RootState } from 'store/configureStore';
import { themes } from 'styles/theme/themes';
import type { ECTableContentProps, row } from 'types/core/ECTable';
import { getConfigStateFromApiOrMock } from 'utils/pageUtils';
import { SortableColumn } from './ECSortableColumn';
import { ECTableDraggableRows } from './ECTableDraggableRow';
import { ECTableRows } from './ECTableRows';
import { isEqual, uniqBy } from 'lodash';
import { sortColumns } from 'utils/core/table';
/**
 * The table component itself, should be responsible
 * only for rendering the data, it's empty state
 * and providing the actions to the page
 */
export const ECTableContent: React.FC<ECTableContentProps> = props => {
  const {
    config,
    data,
    onRowPress,
    onDuplicatePress,
    isEmptyState,
    isEditable,
    additionalChildren,
    headerBackgroundColor = themes.light.palette.table.table,
    onChange,
    onRemove,
    isDraggable = false,
    onDragEnd,
    multiSelectTable,
    showSelectAllPageRows = true,
    emptyType,
    onMultiTableSelect,
    multiSelectedRows,
    allChecked,
    isDraggableColumns,
    selectRowsMaxCount,
    shouldNotUseActiveFilter,
    customFieldsData,
    customEditFieldsData,
    preSelectRowFilter,
    setSort,
    sort = { fieldName: '', value: '' },
    setSearchParams,
    tableLayoutFilter,
  } = props;

  const { t } = useTranslation();

  const parsedConfig = useMemo(() => {
    return getConfigStateFromApiOrMock(
      { config, cols: [] },
      { ...config, cols: [] },
      t,
    );
  }, [config]);

  const { cols } = parsedConfig;

  const [isOpenRowNumber, setIsOpenRowNumber] = useState<number>(-1);
  const [opennedRowId, setOpennedRowId] = useState<number>(-1);
  const [selectedRows, setSelectedRows] = useState<any[]>(
    getInitialSelectedRows(
      allChecked,
      multiSelectTable,
      preSelectRowFilter,
      data,
    ),
  );

  const isEveryDataRowSelected = useMemo(() => {
    return (
      data?.length > 0 &&
      data?.every(row =>
        selectedRows?.some(selectedRow => selectedRow.id === row.id),
      )
    );
  }, [data, selectedRows]);

  const unselectedDataRows = useMemo(() => {
    return (
      (data || [])?.filter(
        row => !selectedRows?.some(selectedRow => selectedRow.id === row.id),
      ) || []
    );
  }, [selectedRows, data]);

  const handleCheckBoxClick = useCallback((row: row) => {
    setSelectedRows(prevSelectedRows => {
      let newSelectedRows;
      let isUnselecting = false;
      if (prevSelectedRows.some(selectedRow => selectedRow.id === row.id)) {
        newSelectedRows = prevSelectedRows.filter(
          selectedRow => selectedRow.id !== row.id,
        );
        isUnselecting = true;
      } else {
        newSelectedRows = [...prevSelectedRows, row];
      }

      if (
        !isEqual(newSelectedRows, prevSelectedRows) &&
        (onMultiTableSelect || multiSelectedRows)
      ) {
        onMultiTableSelect?.(newSelectedRows, isUnselecting, row);
        multiSelectedRows?.(newSelectedRows);
      }
      return newSelectedRows;
    });
  }, []);

  useEffect(() => {
    // To select all rows when allChecked
    if (allChecked && multiSelectTable) {
      setSelectedRows(prev => {
        if (!isEqual(data, prev)) {
          onMultiTableSelect?.(data);
          multiSelectedRows?.(data);
        }

        return data;
      });
    }

    // To open row when opennedRowId is set
    const index = data?.findIndex(row => row.id === opennedRowId) ?? -1;
    if (index !== opennedRowId) {
      setIsOpenRowNumber(index);
    }

    // To select rows when preSelectRowsFieldname is set // test first
    if (
      multiSelectTable &&
      preSelectRowFilter &&
      data?.length > 0 &&
      !allChecked
    ) {
      const rows = data.filter(preSelectRowFilter) || [];
      setSelectedRows(prev => {
        const newSelectedRows = uniqBy([...prev, ...rows], 'id');
        if (!isEqual(newSelectedRows, prev)) {
          onMultiTableSelect?.(newSelectedRows);
          multiSelectedRows?.(newSelectedRows);
        }

        return newSelectedRows;
      });
    }
  }, [allChecked, data, multiSelectTable]);

  const orderedColumns = useMemo(
    () => sortColumns(cols, tableLayoutFilter, isDraggableColumns),
    [cols, tableLayoutFilter, isDraggableColumns],
  );

  const { endpoint } = useSelector((state: RootState) => state.page.filter);
  const [doSaveFilter] = useSaveFilterMutation();
  const [doUpdateFilter] = useUpdateFilterMutation();
  const handleDragEndColumn = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over?.id && active.id !== over?.id) {
      const oldIndex = orderedColumns.findIndex(x => x?.title === active.id);
      const newIndex = orderedColumns.findIndex(x => x?.title === over?.id);

      const newColumnsOrder = arrayMove(orderedColumns, oldIndex, newIndex);

      if (!endpoint || shouldNotUseActiveFilter) {
        return;
      }

      if (
        newColumnsOrder?.length > 0 &&
        !isEqual(
          newColumnsOrder?.map(col => col?.title),
          tableLayoutFilter?.body?.[0]?.columnsOrder,
        )
      ) {
        if (tableLayoutFilter) {
          doUpdateFilter({
            endpoint,
            filterId: tableLayoutFilter?._id,
            body: {
              name: '',
              body: [
                {
                  ...tableLayoutFilter?.body?.[0],
                  type: FieldTypes.Text,
                  label: '',
                  fieldName: '',
                  value: '',
                  isTableLayout: true,
                  columnsOrder: newColumnsOrder?.map(col => col?.title),
                },
              ],
            },
          });
        } else {
          doSaveFilter({
            endpoint,
            body: {
              name: '',
              body: [
                {
                  type: FieldTypes.Text,
                  label: '',
                  fieldName: '',
                  value: '',
                  isTableLayout: true,
                  columnsOrder: newColumnsOrder?.map(col => col?.title),
                },
              ],
            },
          });
        }
      }
    }
  };

  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 100,
      },
    }),
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 100,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleSelectAllRows = useCallback(() => {
    let isUnselecting = false;
    if (isEveryDataRowSelected) {
      const newSelectedRows = selectedRows?.filter(
        sr => !data?.some(dataRow => dataRow.id === sr.id),
      );
      isUnselecting = true;
      setSelectedRows(newSelectedRows);
      onMultiTableSelect?.(newSelectedRows, isUnselecting);
      multiSelectedRows?.(newSelectedRows);
    } else {
      const newSelectedRows = [...selectedRows, ...unselectedDataRows];
      setSelectedRows(newSelectedRows);
      onMultiTableSelect?.(newSelectedRows, isUnselecting);
      multiSelectedRows?.(newSelectedRows);
    }
  }, [selectedRows, data, isEveryDataRowSelected]);

  return (
    <>
      {!isEmptyState && (
        <ECTableContainer
          sx={{
            maxHeight: 'inherit',
            height: 'auto',
          }}
        >
          {additionalChildren}

          <ECTable stickyHeader aria-label="simple table">
            <ECTableHead
              sx={theme => ({
                borderRadius: '1rem 0 0 0',
              })}
            >
              <ECTableRow sx={{ height: 26 }}>
                {multiSelectTable && (
                  <ECTHCell
                    sx={{
                      bgcolor: headerBackgroundColor
                        ? headerBackgroundColor
                        : theme => theme.palette.grey[300],
                    }}
                    key={`select-all-checkbox`}
                    align="left"
                  >
                    {showSelectAllPageRows && (
                      <ECCheckbox
                        // every element in the data array is selected
                        name="select-all-rows"
                        checked={isEveryDataRowSelected}
                        disabled={
                          selectRowsMaxCount &&
                          (selectedRows.length >= selectRowsMaxCount ||
                            data.length > selectRowsMaxCount)
                            ? true
                            : false
                        }
                        indeterminate={
                          selectRowsMaxCount &&
                          (selectedRows.length >= selectRowsMaxCount ||
                            data.length > selectRowsMaxCount)
                            ? true
                            : false
                        }
                        onChange={handleSelectAllRows}
                      />
                    )}
                  </ECTHCell>
                )}
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEndColumn || (() => false)}
                >
                  <SortableContext
                    items={
                      orderedColumns?.map(col => ({
                        ...col,
                        id: col.title,
                      })) || []
                    }
                    strategy={horizontalListSortingStrategy}
                  >
                    {orderedColumns?.map((col, index) => {
                      return (
                        <SortableColumn
                          key={`sortable-column-${col?.title}`}
                          index={index}
                          tableLayoutFilter={tableLayoutFilter}
                          headerBackgroundColor={headerBackgroundColor}
                          sort={sort}
                          setSearchParams={setSearchParams}
                          row={undefined}
                          setSort={setSort}
                          col={col}
                          isDraggableColumns={isDraggableColumns}
                          setOppnenedRowId={setOpennedRowId}
                        />
                      );
                    })}
                  </SortableContext>
                </DndContext>
              </ECTableRow>
            </ECTableHead>
            <ECTableBody>
              {isDraggable ? (
                <ECTableDraggableRows
                  orderedColumns={orderedColumns}
                  data={data}
                  sensors={sensors}
                  onDragEnd={onDragEnd}
                  onRowPress={onRowPress}
                  isEditable={isEditable}
                  onChange={onChange}
                  tableLayoutFilter={tableLayoutFilter}
                  t={t}
                  customFieldsData={customFieldsData}
                  customEditFieldsData={customEditFieldsData}
                />
              ) : (
                <ECTableRows
                  orderedColumns={orderedColumns}
                  data={data}
                  isEditable={isEditable}
                  onChange={onChange}
                  onRowPress={onRowPress}
                  onDuplicatePress={onDuplicatePress}
                  onRemove={onRemove}
                  t={t}
                  customFieldsData={customFieldsData}
                  multiSelectTable={multiSelectTable}
                  selectedRows={selectedRows}
                  allChecked={allChecked}
                  handleCheckBoxClick={handleCheckBoxClick}
                  setIsOpenRowNumber={setIsOpenRowNumber}
                  isOpenRowNumber={isOpenRowNumber}
                  isDraggableColumns={isDraggableColumns}
                  setOpennedRowId={setOpennedRowId}
                  customEditFieldsData={customEditFieldsData}
                  headerBackgroundColor={headerBackgroundColor}
                  setSearchParams={setSearchParams}
                  tableLayoutFilter={tableLayoutFilter}
                  sort={sort}
                  setSort={setSort}
                  isDisabled={
                    selectRowsMaxCount &&
                    selectedRows.length >= selectRowsMaxCount
                      ? true
                      : false
                  }
                  isEveryDataRowSelected={isEveryDataRowSelected}
                />
              )}
            </ECTableBody>
          </ECTable>
        </ECTableContainer>
      )}
      {isEmptyState && <EmptyStatePage emptyType={emptyType} />}
    </>
  );
};

const getInitialSelectedRows = (
  allChecked?: boolean,
  multiSelectTable?: boolean,
  preSelectRowFilter?: ((row: any) => boolean) | undefined,
  data: any[] = [],
) => {
  if (allChecked && multiSelectTable) {
    return data;
  }
  if (
    multiSelectTable &&
    preSelectRowFilter &&
    data?.length > 0 &&
    !allChecked
  ) {
    const rows = data.filter(preSelectRowFilter) || [];
    return uniqBy(rows, 'id');
  }
  return [];
};
