import {
  AccountCircle,
  Add,
  Email,
  Help,
  HomeRepairService,
  People,
  Phone,
  Search,
  SwapHoriz,
} from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  ECAppBar,
  ECBox,
  ECButton,
  ECCardActions,
  ECCardContent,
  ECIconButton,
  ECLink,
  ECMenuItem,
  ECPaper,
  ECSearch,
  ECSelect,
  ECStack,
  ECStyledInputBase,
  ECToolbar,
  ECTypography,
} from '..';
import ecotrakLogo from '../../../assets/ecotrak-logo.svg';
import ecotrakShortLogo from '../../../assets/ecotrak-short-logo.svg';
import { removeToken } from 'services/utils';
import { ECPopover } from '../ECPopover';
import { useCallback, useEffect, useState } from 'react';
import { NotificationList } from 'types/Notification';
import {
  useLazyGetNotificationListQuery,
  useGetUnreadNotificationsCountQuery,
  useReadAllNotificationMutation,
  useUpdateNotificationMutation,
} from 'services/notificationApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { ECNotificationPopoverContent } from '../ECNotificationPopoverContent';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { IconButton, Popover } from '@mui/material';
import { useCustomerUser, useLanguage } from 'app/hooks';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { setUser } from 'store/slice/user';
import { useDispatch } from 'react-redux';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { ServiceRequestCreate } from './service-request-create';
import { StyleConstants } from 'styles/StyleConstants';
import { useCreateServiceRequestContext } from '../../../store/contexts';
import {
  GLOBAL_SEARCH_MODULE_OPTIONS,
  GLOBAL_SEARCH_MODULE_OPTION_VALUES,
  GlobalSearchModules,
  setGlobalFilter,
} from 'store/slice/page';
import {
  removeOverrideUser,
  storeOverrideUser,
  findOverrideUser,
} from 'services/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import SynumaIcon from './assets/SynumaIcon.svg';
import { ECDownloadReportsButton } from './components/ECDownloadReportsButton';
import MenuIcon from '@mui/icons-material/Menu';
import { ECModal } from '../ECModal';
import { PaymentMethodCreateDrawer } from '../../pages/BillingPage/PaymentMethodCreateDrawer';
import {
  CompanySubscriptionStatusCode,
  CompanySubscriptionStatusLabel,
} from 'types/Subscription';
import { useCompanySubscriptionStatusCode } from '../../hooks/useCompanySubscriptionStatusCode.use-case';
import { ECTimeTrackingButton } from './components/ECTimeTrackingButton';

interface ECLoggedInBarProps {
  open?: boolean;
}

const GLOBAL_SEARCH_URL = '/panel/search';
const HOME_PAGE_URL = '/panel/work-orders';
const DASHBOARD_PAGE_URL = '/panel/dashboard';

export const ECLoggedInBar = ({ open }: ECLoggedInBarProps) => {
  const { t } = useTranslation();
  const dispatchRedux = useDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showMoreButtons, setShowMoreButtons] = useState(false);
  const [hasUserOverride, setHasUserOverride] = useState(false);
  const [isOpenAddPaymentDrawer, setIsOpenAddPaymentDrawer] = useState(false);
  const pageTitle = useSelector((state: RootState) => state.page.title);

  useEffect(() => {
    if (findOverrideUser()) {
      setHasUserOverride(true);
    }
  }, []);

  const { module, globalQuery } = useSelector(
    (state: RootState) => state.page.globalFilter,
  );

  const location = useLocation();
  const navigate = useNavigate();

  const isCustomer = useCustomerUser();
  useLanguage();

  const { data: userProfile, isSuccess: isSuccessUserProfile } =
    useGetUserProfileQuery();

  const toggleShowMore = () => setShowMoreButtons(!showMoreButtons);

  useEffect(() => {
    if (userProfile && isSuccessUserProfile) {
      dispatchRedux(setUser(userProfile));
    }
  }, [userProfile, isSuccessUserProfile, dispatchRedux]);

  const handleGoToHome = () => {
    navigate(HOME_PAGE_URL);
  };

  const {
    state: { isOpen },
    dispatch,
  } = useCreateServiceRequestContext();

  const handleOpenServiceRequestDrawer = () => {
    dispatch({ type: 'open' });
  };

  const handleCloseServiceRequestDrawer = () => {
    dispatch({ type: 'close' });
  };

  const companySubscriptionCode = useCompanySubscriptionStatusCode();
  const companyHasPaymentMethod =
    companySubscriptionCode !== CompanySubscriptionStatusCode.BuildNoPayment;

  const handleRunGlobalSearch = useCallback(() => {
    dispatchRedux(setGlobalFilter({ mustTriggerListQuery: true }));
    if (!location.pathname.includes(GLOBAL_SEARCH_URL)) {
      navigate(GLOBAL_SEARCH_URL);
    }
  }, [location, navigate, dispatchRedux]);

  const handleGlobalSearchKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleRunGlobalSearch();
      }
    },
    [handleRunGlobalSearch],
  );

  const isHomePage = location.pathname.includes(DASHBOARD_PAGE_URL);

  const userOverrideSource = localStorage.getItem('overrideUserSource');

  return (
    <ECAppBar
      position="fixed"
      elevation={0}
      color="primary"
      sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <ECToolbar
        sx={{
          backgroundColor: theme =>
            theme.palette.marketingSuggestion.marketingSuggestion,
          maxHeight: '64px !important',
        }}
      >
        <ECBox height="100%">
          <ECStack direction="row" spacing={3} alignItems="center">
            <img
              src={isHomePage ? ecotrakLogo : ecotrakShortLogo}
              style={{ cursor: 'pointer' }}
              onClick={handleGoToHome}
              alt="Ecotrak LLC"
            />

            <ECTypography
              color={theme => theme.palette.other.background}
              fontSize={isHomePage ? 34 : 24}
              fontWeight={700}
              variant="h5"
            >
              {pageTitle}
            </ECTypography>

            {isCustomer && (
              <ECButton
                variant="contained"
                startIcon={<Add />}
                sx={{ whiteSpace: 'nowrap' }}
                onClick={handleOpenServiceRequestDrawer}
              >
                {t('translation:navBar.createServiceRequest')}
              </ECButton>
            )}
          </ECStack>
        </ECBox>
        <ECBox sx={{ flexGrow: 1 }} />

        <ECDownloadReportsButton />

        <ECTimeTrackingButton />

        <ECBox display="flex">
          {isCustomer && (
            <>
              <ECSelect
                id="global-search-module-select"
                name="global-search-module-select-name"
                sx={theme => ({ backgroundColor: theme.palette.common.white })}
                placeholder="Select a module"
                label="Module"
                labelId="global-search-module-select-label"
                value={module}
                renderValue={value => {
                  const optionIndex =
                    GLOBAL_SEARCH_MODULE_OPTION_VALUES?.findIndex(
                      optionValue => optionValue === value,
                    ) || GlobalSearchModules.All;

                  const optionLabel =
                    GLOBAL_SEARCH_MODULE_OPTIONS?.[optionIndex]?.label ??
                    GLOBAL_SEARCH_MODULE_OPTIONS?.[optionIndex];

                  return (
                    <ECBox display="flex" alignItems="center">
                      <ECTypography>{optionLabel}</ECTypography>
                    </ECBox>
                  );
                }}
                onChange={event => {
                  dispatchRedux(
                    setGlobalFilter({ module: event.target.value as number }),
                  );
                }}
              >
                {GLOBAL_SEARCH_MODULE_OPTIONS.map((option, index) => {
                  return (
                    <ECMenuItem
                      value={GLOBAL_SEARCH_MODULE_OPTION_VALUES[index]}
                      key={option.label}
                    >
                      <ECBox
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <ECTypography>{option.label ?? option}</ECTypography>
                      </ECBox>
                    </ECMenuItem>
                  );
                })}
              </ECSelect>
              <ECSearch
                sx={theme => ({
                  backgroundColor:
                    theme.palette.marketingSuggestion.marketingSuggestion,
                })}
              >
                <ECStyledInputBase
                  sx={theme => ({
                    border: 1,
                    borderRadius: 1,
                    borderColor: theme.palette.background.paper,
                    color: theme.palette.common.white,
                    height: '100%',
                  })}
                  onKeyDown={handleGlobalSearchKeyDown}
                  onChange={e =>
                    dispatchRedux(
                      setGlobalFilter({
                        globalQuery: e.target.value,
                        mustTriggerListQuery: false,
                      }),
                    )
                  }
                  value={globalQuery}
                  placeholder={t('translation:table.search')}
                  inputProps={{ 'aria-label': 'search', maxLength: 500 }}
                />
              </ECSearch>

              <ECIconButton
                size="large"
                aria-label="global search"
                sx={{ color: 'background.default' }}
                onClick={handleRunGlobalSearch}
              >
                <Search sx={theme => ({ color: theme.palette.common.white })} />
              </ECIconButton>
            </>
          )}

          <ECIconButton
            size="large"
            id="account-menu-button"
            aria-label="account menu"
            aria-haspopup="true"
            sx={{ color: 'background.default' }}
            onClick={event => {
              setAnchorEl(event.currentTarget);
              toggleShowMore();
            }}
          >
            <AccountCircle
              sx={theme => ({ color: theme.palette.common.white })}
            />
          </ECIconButton>
          <ECPopover
            open={showMoreButtons}
            anchorEl={anchorEl}
            sx={{
              '& .MuiPopover-paper': {
                borderRadius: 1,
                minWidth: '300px',
              },
            }}
            onClose={toggleShowMore}
            id="account-menu-popover"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <ECMenuItem
              sx={{
                color: 'text.primary',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
              onClick={() => {
                if (hasUserOverride) {
                  removeOverrideUser();
                  localStorage.removeItem('userProfile');
                  localStorage.removeItem('userProfileFromCache');
                  localStorage.removeItem('userProfileLastCallTimestamp');
                  localStorage.removeItem('companyProfile');
                  localStorage.removeItem('companyProfileFromCache');
                  localStorage.removeItem('companyProfileLastCallTimestamp');
                  localStorage.removeItem('customSessionTimeoutTimestamp');
                  localStorage.removeItem('customSSOSessionTimeoutTimestamp');
                  window.location.href = '/home';
                }
              }}
            >
              <SwapHoriz sx={{ mr: 1 }} />
              <ECTypography variant="body1" noWrap>
                {hasUserOverride
                  ? 'Return to original user to swap to another account.'
                  : 'Accounts'}
              </ECTypography>
            </ECMenuItem>
            <ECMenuItem
              key={userProfile?.id}
              sx={{
                color: 'text.primary',
                backgroundColor: theme =>
                  theme.palette.primary.outlinedRestingBackground,
                '&:hover': {
                  backgroundColor: theme =>
                    theme.palette.primary.outlinedHoverBackground,
                },
                maxWidth: '380px',
              }}
            >
              <ECBox display="flex" flexDirection="row" alignItems="center">
                {isCustomer ? (
                  <People sx={{ mr: 1 }} />
                ) : (
                  <HomeRepairService sx={{ mr: 1 }} />
                )}
                <ECBox>
                  <ECBox sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <ECTypography>{userProfile?.fullName}</ECTypography>
                    <ECTypography>
                      {userProfile?.email ? `(${userProfile?.email})` : ''}
                    </ECTypography>
                  </ECBox>
                  {hasUserOverride &&
                    companySubscriptionCode &&
                    userOverrideSource === 'page' &&
                    isCustomer && (
                      <ECTypography variant="caption" noWrap fontSize={14}>
                        Ecotrak{' '}
                        {
                          CompanySubscriptionStatusLabel[
                            companySubscriptionCode
                          ]
                        }
                      </ECTypography>
                    )}
                </ECBox>
              </ECBox>
            </ECMenuItem>
            {!hasUserOverride &&
              userProfile?.impersonatedUsers &&
              userProfile?.impersonatedUsers.map((user, index) => {
                return (
                  <ECMenuItem
                    key={user.id}
                    sx={{
                      color: 'text.primary',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                    onClick={() => {
                      storeOverrideUser(user.username, 'switchAccounts');
                      localStorage.removeItem('userProfile');
                      localStorage.removeItem('userProfileFromCache');
                      localStorage.removeItem('userProfileLastCallTimestamp');
                      localStorage.removeItem('companyProfile');
                      localStorage.removeItem('companyProfileFromCache');
                      localStorage.removeItem(
                        'companyProfileLastCallTimestamp',
                      );
                      localStorage.removeItem('customSessionTimeoutTimestamp');
                      localStorage.removeItem(
                        'customSSOSessionTimeoutTimestamp',
                      );
                      window.location.href = '/home';
                    }}
                  >
                    {user?.userType === 'SP' ? (
                      <HomeRepairService sx={{ mr: 1, ml: 2 }} />
                    ) : (
                      <People sx={{ mr: 1, ml: 2 }} />
                    )}
                    <ECTypography variant="body1" noWrap>
                      {user?.firstName} {user?.lastName}
                    </ECTypography>
                  </ECMenuItem>
                );
              })}

            {userProfile?.synuma?.enableSynuma === 1 && (
              <ECMenuItem
                sx={{
                  color: 'text.primary',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
                onClick={() => {
                  if (userProfile?.synuma?.synumaUrl)
                    window.open(userProfile?.synuma?.synumaUrl, '_blank');
                }}
              >
                <img src={SynumaIcon} alt="Synuma" />
                <ECTypography
                  variant="body1"
                  noWrap
                  sx={{
                    ml: 1,
                  }}
                >
                  {'Project Management'}
                </ECTypography>
              </ECMenuItem>
            )}

            <ECMenuItem
              sx={{
                color: 'text.primary',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
              onClick={() => {
                window.open('https://helpcenter.ecotrak.com/en', '_blank');
              }}
            >
              <Help sx={{ mr: 1 }} />
              <ECTypography variant="body1" noWrap>
                {'Help'}
              </ECTypography>
            </ECMenuItem>
            <ECMenuItem
              sx={{
                color: 'text.primary',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
              onClick={() => {
                removeToken();
                removeOverrideUser();
                localStorage.removeItem('userProfile');
                localStorage.removeItem('userProfileFromCache');
                localStorage.removeItem('userProfileLastCallTimestamp');
                localStorage.removeItem('companyProfile');
                localStorage.removeItem('companyProfileFromCache');
                localStorage.removeItem('companyProfileLastCallTimestamp');
                localStorage.removeItem('customSessionTimeoutTimestamp');
                localStorage.removeItem('customSSOSessionTimeoutTimestamp');
                window.location.replace('/');
              }}
            >
              <LogoutIcon sx={{ mr: 1 }} />
              <ECTypography variant="body1" noWrap>
                {'Logout'}
              </ECTypography>
            </ECMenuItem>
          </ECPopover>
        </ECBox>
      </ECToolbar>

      <PaymentMethodCreateDrawer
        openPaymentMethodDrawer={isOpenAddPaymentDrawer}
        handleClose={() => {
          localStorage.removeItem('userProfile');
          localStorage.removeItem('userProfileFromCache');
          localStorage.removeItem('userProfileLastCallTimestamp');
          localStorage.removeItem('companyProfile');
          localStorage.removeItem('companyProfileFromCache');
          localStorage.removeItem('companyProfileLastCallTimestamp');
          localStorage.removeItem('customSessionTimeoutTimestamp');
          localStorage.removeItem('customSSOSessionTimeoutTimestamp');
          window.location.replace('/panel/configuration/profile/billing');
          setIsOpenAddPaymentDrawer(false);
        }}
        shouldShowAsFullPage={false}
      />
      <ECModal
        isOpen={isOpen && !companyHasPaymentMethod}
        onClose={handleCloseServiceRequestDrawer}
        noPadding
        noPaddingBottom
      >
        <ECBox px={4} py={2} sx={{ width: '36rem', maxWidth: '100%' }}>
          <ECTypography variant="h6">
            Add a Payment Method to place a Service Request
          </ECTypography>
        </ECBox>
        <ECBox
          justifyContent={'flex-end'}
          display={'flex'}
          gap={'8px'}
          padding={'16px'}
          marginTop={'0px'}
        >
          <ECButton size="large" onClick={handleCloseServiceRequestDrawer}>
            Cancel
          </ECButton>
          <ECButton
            onClick={() => {
              setIsOpenAddPaymentDrawer(true);
              handleCloseServiceRequestDrawer();
            }}
            variant="contained"
            size="large"
          >
            Add a Payment Method
          </ECButton>
        </ECBox>
      </ECModal>
      <ECDrawerDetails
        open={isOpen && !!companyHasPaymentMethod}
        anchor="right"
        onClose={handleCloseServiceRequestDrawer}
      >
        <ECBox
          sx={theme => ({
            height: '100%',
            paddingTop: StyleConstants.NAV_BAR_HEIGHT,
          })}
        >
          <ServiceRequestCreate onClose={handleCloseServiceRequestDrawer} />
        </ECBox>
      </ECDrawerDetails>
    </ECAppBar>
  );
};

export const renderContactUsPopoverContent = (t, handleClose) => (
  <ECPaper
    sx={theme => ({
      borderRadius: 1,
      maxWidth: '444px',
      p: 1,
      bgcolor: theme.palette.common.white,
    })}
  >
    <ECCardContent>
      <ECTypography variant="h6" mb={3}>
        {t('translation:contactUsPopover.title')}
      </ECTypography>

      <ECTypography variant="body1" mb={2}>
        {t('translation:contactUsPopover.text1')}
      </ECTypography>

      <ECBox
        display="flex"
        justifyContent="space-between"
        mb={2}
        minWidth={'350px'}
      >
        <ECLink
          startIcon={
            <Phone sx={theme => ({ color: theme.palette.action.active })} />
          }
          notClickable
          sx={{
            textAlign: 'center',
            display: 'flex',
            lineHeight: '27px !important',
          }}
        >
          {t('translation:contactUsPopover.phone1')}
        </ECLink>

        <ECLink
          href={`mailto:${t('translation:contactUsPopover.email')}`}
          startIcon={
            <Email sx={theme => ({ color: theme.palette.action.active })} />
          }
        >
          {t('translation:contactUsPopover.email')}
        </ECLink>
      </ECBox>
    </ECCardContent>

    <ECCardActions>
      <ECBox display="flex" justifyContent="flex-end" width="100%">
        <ECButton onClick={handleClose} variant="contained">
          OK
        </ECButton>
      </ECBox>
    </ECCardActions>
  </ECPaper>
);

export const ECNotLoggedInBar = () => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );

  const handleContactUsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'contact-us-popover' : undefined;

  const openMenu = Boolean(menuAnchorEl);
  const idMenu = openMenu ? 'menu-popover' : undefined;

  const menuStack = (stackDirection: 'row' | 'column') => (
    <ECStack
      direction={stackDirection}
      sx={theme => ({
        background: theme.palette.marketingSuggestion.marketingSuggestion,
        minWidth: '138px',
      })}
    >
      <ECButton
        variant="text"
        href="https://helpcenter.ecotrak.com/en"
        sx={theme => ({
          color: `${theme.palette.common.white} !important`,
        })}
      >
        {t('translation:navBar.help')}
      </ECButton>
      <ECButton
        variant="text"
        href="https://ecotrak.com/aboutus/"
        sx={theme => ({
          color: `${theme.palette.common.white} !important`,
        })}
      >
        {t('translation:navBar.about')}
      </ECButton>
      {/* Update `Contact Us` link when available */}
      <ECButton
        variant="text"
        sx={theme => ({
          color: `${theme.palette.common.white} !important`,
        })}
        onClick={handleContactUsClick}
      >
        {t('translation:navBar.contact')}
      </ECButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ECBox
          sx={{
            position: 'relative',
            mt: '10px',
            '&::before': {
              backgroundColor: 'white',
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 12,
              height: 12,
              top: -6,
              transform: 'rotate(45deg)',
              right: 'calc(10%)',
            },
          }}
        />
        {renderContactUsPopoverContent(t, handleClose)}
      </Popover>
    </ECStack>
  );

  return (
    <ECAppBar elevation={0} variant="outlined" position="fixed" color="primary">
      <ECToolbar>
        <ECBox height={'100%'}>
          <ECLink href="https://ecotrak.com/">
            <img src={ecotrakLogo} alt="Ecotrac LLC" />
          </ECLink>
        </ECBox>
        <ECBox sx={{ flexGrow: 1 }} />
        <ECBox display="flex">
          {!isMobileSize ? (
            menuStack('row')
          ) : (
            <>
              <IconButton onClick={handleMenuClick}>
                <MenuIcon
                  sx={{
                    color: menuAnchorEl
                      ? theme.palette.primary.main
                      : '#FFFFFF',
                    width: '35px',
                    height: '35px',
                  }}
                />
              </IconButton>

              <Popover
                id={idMenu}
                open={openMenu}
                anchorEl={menuAnchorEl}
                onClose={handleCloseMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                {menuStack('column')}
              </Popover>
            </>
          )}
        </ECBox>
      </ECToolbar>
    </ECAppBar>
  );
};

interface ECNavBarType {
  open?: boolean;
  notifications?: NotificationList[];
}

export const ECNavBar = (props: ECNavBarType) => {
  const token = localStorage.getItem('idToken');

  if (token === null) {
    return <ECNotLoggedInBar />;
  } else {
    return <ECLoggedInBar />;
  }
};

export const ECTechTrakNotLoggedInBar = () => {
  return (
    <ECAppBar
      elevation={0}
      position="relative"
      variant="outlined"
      color="primary"
    >
      <ECToolbar>
        <img src={ecotrakShortLogo} alt="Ecotrac LLC" />
      </ECToolbar>
    </ECAppBar>
  );
};
