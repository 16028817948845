import { ECBox } from '../ECBox';
import { ECButton } from '../ECButton';
import { renderContactUsPopoverContent } from '../ECNavBar';
import { ECStack } from '../ECStack';
import { Popover } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ECLoginFooterLinks = ({ visible = true }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  if (!visible) return null;

  const handleContactUsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'contact-us-popover' : undefined;

  return (
    <ECStack direction="row" justifyContent="space-between">
      <ECButton
        variant="text"
        href="https://helpcenter.ecotrak.com/en"
        sx={theme => ({
          color: `${theme.palette.common.white} !important`,
          margin: '0 10px',
        })}
      >
        {t('translation:navBar.help')}
      </ECButton>
      <ECButton
        variant="text"
        href="https://ecotrak.com/aboutus/"
        sx={theme => ({
          color: `${theme.palette.common.white} !important`,
          margin: '0 10px',
        })}
      >
        {t('translation:navBar.about')}
      </ECButton>
      {/* Update `Contact Us` link when available */}
      <ECButton
        variant="text"
        sx={theme => ({
          color: `${theme.palette.common.white} !important`,
          margin: '0 10px',
        })}
        onClick={handleContactUsClick}
      >
        {t('translation:navBar.contact')}
      </ECButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ECBox
          sx={{
            position: 'relative',
            mt: '10px',
            '&::before': {
              backgroundColor: 'white',
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 12,
              height: 12,
              top: -6,
              transform: 'rotate(45deg)',
              right: 'calc(10%)',
            },
          }}
        />

        {renderContactUsPopoverContent(t, handleClose)}
      </Popover>
    </ECStack>
  );
};
