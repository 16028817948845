import { ComputedInventoryItem, InventoryItemShape } from 'types/Inventory';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const makeId = (locationId: string) => {
  const result =
    'w' + locationId + uuidv4().replace(/-/g, '') + uuidv4().replace(/-/g, ''); // Two times, because we need to make sure that the id is 45 characters long
  return result.substring(0, 45);
};

export function flatToNested(flatObj) {
  const result = {};

  for (const objectPath in flatObj) {
    const parts = objectPath.split('.');
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      target = target[`${part}`] = target[`${part}`] || {};
    }
    target[parts[0]] = flatObj[objectPath];
  }

  return result;
}
// receives a nested object and returns a flat objct
// {a:1, b:{b1:2, b2:3}} => {a: 1, b.b1: 2, b.b2: 3}
export function nestedToFlat<ResultType>(nestedObj): ResultType {
  const result = {};
  for (const key in nestedObj) {
    if (typeof nestedObj[key] === 'object' && !Array.isArray(nestedObj[key])) {
      const innerObj: any = nestedToFlat(nestedObj[key]);
      for (const innerKey in innerObj) {
        result[`${key}.${innerKey}`] = innerObj[innerKey];
      }
    } else {
      result[key] = nestedObj[key];
    }
  }
  return result as ResultType;
}

const lowercaseWords = [
  '',
  'a',
  'an',
  'is',
  'the',
  'and',
  'or',
  'of',
  'in',
  'on',
  'at',
  'for',
  'to',
  'with',
  'from',
  'by',
  'about',
  'when',
  'as',
  'not',
  'within',
];
const uppercaseWords = ['nte', 'ext', 'pdf'];

export const camelCaseToWords = (str: string) => {
  const strSplitted = str.replace(/([A-Z]|[0-9])/g, ' $1').split(' ');

  return strSplitted
    .map(word => {
      const lowerWord = word.toLowerCase();
      if (lowercaseWords.includes(lowerWord)) {
        return lowerWord;
      }
      if (uppercaseWords.includes(lowerWord)) return lowerWord.toUpperCase();

      return word?.[0]?.toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const spProfileModules = ['serviceprovider', 'customer'];

export const convertComputedInventoryItemsToInventoryShape = (
  allocatedItems: ComputedInventoryItem[],
): InventoryItemShape[] => {
  return allocatedItems.map((item: ComputedInventoryItem) => {
    const { inventoryStorage, inventoryItem } = item.inventoryItemStorage;
    return {
      id: item.id,
      count: item.count,
      price: item.price,
      inventoryId: inventoryItem.id,
      inventoryStorageId: inventoryStorage.id,
      name: inventoryItem.name,
      storageLocation: inventoryStorage.name,
      total: item.total,
      unitSymbol: item.unitSymbol,
    };
  });
};

export const groupAndProcessInventoryItems = (allocatedInventory: any) => {
  const groupedInventory = allocatedInventory.reduce((acc: any, item: any) => {
    if (!acc[item.inventoryId]) {
      acc[item.inventoryId] = {
        inventoryId: item.inventoryId,
        name: _.truncate(item.name, { length: 50 }),
        count: item.count,
        price: item.price,
        total: item.total,
        unitSymbol: item.unitSymbol,
      };
    } else {
      acc[item.inventoryId].count += item.count;
      acc[item.inventoryId].total += item.total;
    }

    return acc;
  }, {});

  return Object.values(groupedInventory);
};

export const isEmptyValue = value => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' &&
      !(value instanceof Date) &&
      Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (typeof value === 'number' && value === 0)
  );
};

export const isDefined = (value: any): boolean => {
  return value !== undefined;
};
