import {
  CompanyConfiguration,
  CompanyEntity,
  CompanyProfile,
  CompanyQueryParams,
} from 'types/Company';
import { formatPhone } from 'utils/strings/phone';
import { BaseType } from 'types/BaseType';
import { Asset } from 'types/Asset';
import { QueryParams } from 'types/QueryParams';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Profile', 'ProfileById', 'CompanyConfiguration'],
});

export const profileApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getProfile: builder.query<CompanyProfile, void>({
      query: () => {
        return {
          url: 'company/profile',
        };
      },
      transformResponse: (response: CompanyProfile) => {
        const formattedPhone =
          response.phone?.length > 0
            ? formatPhone(
                response.phone?.startsWith('+')
                  ? response.phone
                  : '+' + response.phone,
              )
            : '';
        const formattedPhone24 =
          response.phone24?.length > 0
            ? formatPhone(
                response.phone24?.startsWith('+')
                  ? response.phone24
                  : '+' + response.phone24,
              )
            : '';
        const trasformedResponseForForm: CompanyProfile = {
          ...response,
          ...(response?.contacts?.length > 0
            ? { contact: response.contacts[0] }
            : {}),
          sic: response?.sic
            ? { ...response.sic, name: response.sic?.code }
            : { id: -1 },
          formattedPhone,
          formattedPhone24,
          moduleName: 'company',
          isBillingSameAsAddress: response.isBillingSameAsAddress
            ? ['Use same for Billing Address']
            : [],
        };
        return trasformedResponseForForm;
      },
      providesTags: ['Profile'],
      keepUnusedDataFor: 300,
    }),

    getProfileById: builder.query<CompanyProfile, number>({
      query: id => {
        if (!id) {
          return {
            url: '',
          };
        }

        return {
          url: `company/${id}`,
          keepUnusedDataFor: 300,
        };
      },
      providesTags: ['ProfileById'],
    }),

    getServiceProvidersByCompany: builder.query<
      CompanyEntity[],
      CompanyQueryParams
    >({
      query: queryParams => ({
        url: `/company/sp`,
        params: queryParams,
      }),
    }),

    updateProfile: builder.mutation<void, CompanyProfile>({
      query: body => {
        let address = body.address;
        if (
          !address?.line1 ||
          !address.cityName ||
          !address?.stateProvinceCode ||
          !address?.countryCode
        ) {
          delete body.address;
        }
        let billingAddress = body.billingAddress;
        if (
          !billingAddress?.line1 ||
          !billingAddress.cityName ||
          !billingAddress?.stateProvinceCode ||
          !billingAddress?.countryCode
        ) {
          delete body.billingAddress;
        }
        if (
          address &&
          (address.zipCodePostalCode === '' || !address.zipCodePostalCode)
        ) {
          address['zipCodePostalCode'] = null;
        }
        if (
          billingAddress &&
          (billingAddress.zipCodePostalCode === '' ||
            !billingAddress.zipCodePostalCode)
        ) {
          billingAddress['zipCodePostalCode'] = null;
        }
        return {
          url: 'company/profile/serviceprovider',
          method: 'put',
          body,
        };
      },
      transformResponse: response => {
        localStorage.removeItem('companyProfileLastCallTimestamp');
        return;
      },
      invalidatesTags: ['Profile', 'ProfileById'],
    }),

    getCompanyAssets: builder.query<
      BaseType<Asset[]>,
      { id: number; queryParams: QueryParams }
    >({
      query: ({ id, queryParams }) => {
        if (!id) {
          return {
            url: '',
          };
        }

        return {
          url: `company/${id}/assets`,
          params: queryParams,
        };
      },
      transformResponse: (response: BaseType<Asset[]>) => {
        return {
          ...response,
          data: response.data?.map(asset => ({
            ...asset,
            moduleName: !!(asset as any)?.generalAssetId
              ? 'general-asset'
              : 'asset',
            photo:
              asset?.attachments?.[0]?.thumbnailURL ||
              asset?.attachments?.[0]?.url,
            estimatedUsefulLifeText: asset.estimatedUsefulLife
              ? `${asset.estimatedUsefulLife} ${
                  asset.eulPeriodCode === 'Y' ? 'Years' : 'Months'
                }`
              : '',
          })),
        };
      },
    }),

    // get company config for page Settings
    getCompanyConfiguration: builder.query<CompanyConfiguration, void>({
      query: () => {
        return {
          url: 'company/configuration',
        };
      },
      providesTags: ['CompanyConfiguration'],
    }),
    updateCompanyConfiguration: builder.mutation<void, any>({
      query: body => {
        return {
          url: 'company/configuration',
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['CompanyConfiguration'],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useGetProfileByIdQuery,
  useLazyGetProfileByIdQuery,
  useUpdateProfileMutation,
  useGetCompanyAssetsQuery,
  useLazyGetCompanyAssetsQuery,
  useLazyGetServiceProvidersByCompanyQuery,
  useGetServiceProvidersByCompanyQuery,
  useGetCompanyConfigurationQuery,
  useUpdateCompanyConfigurationMutation,
} = profileApi;
