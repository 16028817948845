import { ECBox, ECButton, ECRequirePermission } from 'app/components';
import { ECExportAction } from 'app/components/core/Actions/ECExportAction';
import { ECColumnVisibilityFilter } from 'app/components/core/Filters/ECColumnVisibilityFilter';
import { ECToggleFilter } from 'app/components/core/Filters/ECToggleFilter';
import { P } from 'types/Permission';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import { ECCategoryFilter } from 'app/components/core/Filters/ECCategoryFilter';
import { ECOrgStructureFilter } from 'app/components/core/Filters/ECOrgStructureFilter';
import { ECDateFilter } from 'app/components/core/Filters/ECDateFilter';
import { ToggleFilterProps } from 'types/core/Filters';
import { useMemo } from 'react';
import { orderBy } from 'lodash';
import { useGetAllWorkflowStatusQuery } from 'services/workflowApi';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { ECAdvancedFilter } from 'app/components/core/Filters/ECAdvancedFilter';
import { ECStatusFilter } from 'app/components/core/Filters/ECStatusFilter';

export const WorkOrdersPageHeader: React.FC<{
  disableEditButton: boolean;
  setIsBulkEditDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ disableEditButton, setIsBulkEditDrawerOpen }) => {
  const { t } = useTranslation();

  const endpoint = useSelector(
    (state: RootState) => state.page.filter.endpoint,
  );

  const { data: workflowStatuses } = useGetAllWorkflowStatusQuery('workorder');
  const { data: categories } = useGetCategoryListQuery({ st: 1 });

  const statusFilterOptions = useMemo(() => {
    return workflowStatuses
      ? orderBy(workflowStatuses, 'name')?.map(status => ({
          id: status?.id,
          label: status.name,
          fieldName: workflowStatuses
            ?.filter(
              workflowStatus =>
                workflowStatus.name === status.name && workflowStatus.groupId,
            )
            ?.map(workflowStatus => workflowStatus.groupId)
            ?.join(','),
          isInitialSelected: true,
        }))
      : undefined;
  }, [workflowStatuses]);

  const statusFilterOptionsWithDuplicatesRemoved = useMemo(() => {
    return statusFilterOptions?.filter(
      (status, index, self) =>
        index === self.findIndex(t => t.label === status.label),
    );
  }, [statusFilterOptions]);

  const categoryFilterOptions = useMemo(() => {
    return (
      orderBy(categories?.data, 'name')
        ?.filter(category => {
          return category?.module?.name !== 'Inventory';
        })
        ?.map(category => ({
          ...category,
          id: category?.id,
          label: category?.name,
          fieldName: category?.id?.toString(),
          isInitialSelected: true,
        })) || []
    );
  }, [categories]);

  const handleAwaitingInvoiceToggle: ToggleFilterProps['sideEffectFilter'] =
    isFilterActive => {
      if (isFilterActive) {
        return {
          statuses:
            statusFilterOptionsWithDuplicatesRemoved
              ?.filter(status => status.label === 'Completed')
              ?.map(st => st.fieldName) || [],
        };
      }
      return {
        statuses: undefined,
      };
    };

  return (
    <ECBox
      flexDirection={'column'}
      gap={1}
      display={'flex'}
      justifyContent={'flex-end'}
      width={'100%'}
      paddingTop={1}
      paddingBottom={1}
    >
      <ECBox
        flexDirection={'row'}
        gap={2}
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        flexWrap={'wrap'}
      >
        <ECToggleFilter
          label="Awaiting Invoice"
          alias="awaitingInvoice"
          sideEffectFilter={handleAwaitingInvoiceToggle}
        />
        <ECAdvancedFilter />
        <ECExportAction
          exportParams={{
            jobName: 'workorder',
            exportModule: 'WORKORDER',
          }}
        />
        <ECColumnVisibilityFilter />
      </ECBox>
      <ECBox
        flexDirection={'row'}
        gap={2}
        display={'flex'}
        justifyContent={'space-between'}
        width={'100%'}
        flexWrap={'wrap'}
      >
        <ECBox flexDirection={'row'} gap={2} display={'flex'}>
          <ECRequirePermission scopes={[P.EditWorkOrder, P.GetWorkOrder]}>
            <ECButton
              variant="outlined"
              startIcon={<Edit />}
              disabled={disableEditButton}
              onClick={() => {
                if (!disableEditButton) {
                  setIsBulkEditDrawerOpen(true);
                }
              }}
              sx={{
                height: '42px',
              }}
            >
              {t('translation:dynamicForm.bulkEdit')}
            </ECButton>
          </ECRequirePermission>
        </ECBox>
        <ECBox
          flexDirection={'row'}
          gap={2}
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'flex-end'}
        >
          <ECCategoryFilter categoryFilterOptions={categoryFilterOptions} />
          <ECStatusFilter
            statusFilterOptions={statusFilterOptionsWithDuplicatesRemoved || []}
            endpoint={endpoint}
          />
          <ECOrgStructureFilter />
          <ECDateFilter
            dateFilterFieldsOptions={[
              {
                id: 1,
                label: 'Created Date',
                fieldName: 'wrkordr.createdAt',
              },
              {
                id: 2,
                label: 'Completed Date',
                fieldName: 'wrkordr.completedDate',
              },
            ]}
          />
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
