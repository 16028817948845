import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragIndicator } from '@mui/icons-material';
import { ECBox } from 'app/components/ECBox';
import { ECTableSortLabel, ECTHCell } from 'app/components/ECTable';
import { ECTypography } from 'app/components/ECTypography';
import { SortableColumnProps } from 'types/core/ECTable';

export const SortableColumn: React.FC<SortableColumnProps> = ({
  col,
  tableLayoutFilter,
  headerBackgroundColor,
  sort,
  setSearchParams,
  setSort,
  index,
  isDraggableColumns,
  setOppnenedRowId,
  row,
}) => {
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: col.title,
  });
  const fieldName = col.sortAlias || col.alias || col.fieldName;
  const isCurrentSort = sort.orderBy === fieldName;
  return (
    <ECTHCell
      style={{
        transform: transform
          ? CSS.Transform.toString({
              ...transform,
              scaleX: 1,
              scaleY: 1,
            })
          : undefined,
        transition,
        zIndex: isDragging ? 1000 : undefined,
      }}
      ref={setNodeRef}
      sx={{
        bgcolor: headerBackgroundColor
          ? headerBackgroundColor
          : theme => theme.palette.grey[300],
        padding: '0 32px 0 16px',
      }}
      key={`${col?.title}-${index}`}
      align="left"
    >
      <ECBox
        {...(isDraggableColumns && listeners)}
        {...(isDraggableColumns && attributes)}
        display={'flex'}
        flexDirection={'column'}
      >
        <ECBox
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          position={'relative'}
          sx={{
            ':hover .drag-indicator': {
              color: theme => theme.palette.button.active,
            },
          }}
        >
          {isDraggableColumns && (
            <DragIndicator
              className="drag-indicator"
              sx={{
                cursor: isDragging ? 'grabbing' : 'grab',
                color: theme => theme.palette.text.tertiary,
                position: 'absolute',
                top: 0,
                left: -24,
                transition: 'color 0.3s',
              }}
            />
          )}
          <ECTypography
            variant={'body2'}
            sx={{
              fontVariantCaps: 'normal',
              fontWeight: 'bold',
              fontSize: 14,
              color: theme => theme.palette.text.primary,
              cursor: isDragging ? 'grabbing' : 'grab',
            }}
          >
            {col.title}
          </ECTypography>{' '}
          {col.sortable && (
            <ECTableSortLabel
              active={true}
              direction={
                sort?.orderBy === fieldName && sort?.order === 'DESC'
                  ? 'desc'
                  : 'asc'
              }
              onMouseUp={() => {
                setSort?.(curSort => {
                  return {
                    ...curSort,
                    orderBy: fieldName,
                    order:
                      curSort.orderBy === fieldName && curSort.order === 'DESC'
                        ? 'ASC'
                        : 'DESC',
                  };
                });
                setSearchParams?.(prev => ({
                  perPage: prev?.get('perPage') || '50',
                  page: '0',
                }));
                setOppnenedRowId(row?.id ?? -1);
              }}
              sx={{
                '.MuiSvgIcon-root': {
                  fill: theme =>
                    isCurrentSort
                      ? theme.palette.button.active
                      : theme.palette.text.tertiary,
                  transition: 'fill 0.3s',
                },
                '.MuiSvgIcon-root:hover': {
                  fill: theme => theme.palette.button.active,
                },
              }}
            />
          )}
        </ECBox>
      </ECBox>
    </ECTHCell>
  );
};
