import { ECButton } from 'app/components/ECButton';
import { ECAlert, ECAlertTitle } from 'app/components/ECAlert';
import { ECBox } from 'app/components/ECBox';
import { ECDrawerEdit } from 'app/components/ECDrawerEdit';
import { ECPaper } from 'app/components/ECPaper';
import { useCallback, useState } from 'react';
import { StyleConstants } from 'styles/StyleConstants';
import { ECApprovalHistory } from '..';

export interface ECApprovalHistoryProps {
  // query response is ANY
  approvalHistory: any;
  disabled?: boolean;
}
/* 
  TODO
  If you are implementing this with the query call in the component
  just make a check in the approvalHistory prop
  If it has approvalHistory, no need to query, otherwise query
*/
export const ECApprovalHistoryButton = (props: ECApprovalHistoryProps) => {
  const { approvalHistory, disabled } = props;

  const [isApprovalHistoryVisible, setIsApprovalHistoryVisible] =
    useState(false);

  const handleOpenApprovalHistoryModal = useCallback(() => {
    setIsApprovalHistoryVisible(true);
  }, []);
  const handleCloseApprovalHistoryModal = useCallback(() => {
    setIsApprovalHistoryVisible(false);
  }, []);

  return (
    <>
      <ECAlert
        severity="info"
        variant="outlined"
        disabled={disabled}
        sx={{
          width: '100%',
          alignItems: 'center',
          '.MuiAlert-message': {
            paddingRight: '26px',
            width: '100% !important',
            padding: 0,
          },
        }}
      >
        <ECBox
          display={'flex'}
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <ECAlertTitle
            sx={{
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            Approval History
          </ECAlertTitle>
          <ECButton
            variant="text"
            onClick={handleOpenApprovalHistoryModal}
            sx={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            disabled={disabled}
          >
            View
          </ECButton>
        </ECBox>
      </ECAlert>
      <ECDrawerEdit
        open={isApprovalHistoryVisible}
        anchor="right"
        onClose={handleCloseApprovalHistoryModal}
        PaperProps={{
          sx: {
            minWidth: '700px',
          },
        }}
      >
        <ECPaper
          sx={{
            paddingTop: StyleConstants.NAV_BAR_HEIGHT,
            width: '100%',
            minHeight: '100%',
            overflow: 'auto',
          }}
          role="presentation"
        >
          <ECApprovalHistory
            handleCloseModal={handleCloseApprovalHistoryModal}
            data={approvalHistory}
          />
        </ECPaper>
      </ECDrawerEdit>
    </>
  );
};
