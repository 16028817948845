import { useState, useCallback, useEffect } from 'react';
import { ECBox } from '../ECBox';
import { ECTextField } from '../ECTextField';
import { ECSelect } from '../ECSelect';
import { ECMenuItem } from '../ECSelect';
import { ECFormControl } from '../ECForm';
import {
  Typography,
  TextField,
  InputAdornment,
  Input,
  FormHelperText,
  Grid,
  InputLabel,
} from '@mui/material';
import { DateTime } from 'luxon';
import { ECButton } from '../ECButton';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { ECCircularProgress } from '../ECCircularProgress';
import { ECStack } from '../ECStack';
import { useUpdateWarrantyPeriodTableMutation } from 'services/warrantyApi';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { isNumeric } from 'utils/is_numeric';

const compareDatesMessage = periodTable => {
  const expirationDate = DateTime.fromISO(periodTable.expirationDate);
  const currentDate = DateTime.now();

  if (expirationDate <= currentDate) {
    return (
      <Typography color="error" variant="subtitle1">
        Expired
      </Typography>
    );
  } else {
    const formattedDate = expirationDate.toFormat('MMM dd, yyyy');
    return (
      <Typography variant="subtitle1">Exp. Date: {formattedDate}</Typography>
    );
  }
};

function changeDateFormat(res) {
  return DateTime.fromISO(res).toFormat('MMM dd, yyyy');
}

const compareDatesShowDate = periodTable => {
  const expirationDate = DateTime.fromISO(periodTable.expirationDate);
  const now = DateTime.now();

  if (expirationDate <= now) {
    return String.fromCharCode(160);
  } else {
    return changeDateFormat(expirationDate.toISO());
  }
};

interface Props {
  values: any;
  notes?: any;
  readOnly?: boolean;
  optionsWarrantyType?: any;
  optionsPeriod?: any;
  baseSx?: any;
  onClose?: () => void;
  assetId?: string;
  showNotes?: boolean;
}

export const ECManufacturerWarrantyPeriodTable: React.FC<Props> = ({
  values,
  notes,
  readOnly,
  optionsWarrantyType,
  optionsPeriod,
  baseSx,
  onClose,
  assetId,
  showNotes = true,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [data, setData] = useState(values);
  const [notesData, setNotesData] = useState(notes);

  useEffect(() => {
    setData(values);
  }, [values]);

  useEffect(() => {
    setNotesData(notes);
  }, [notes]);

  const handleAddNew = useCallback(() => {
    setData(prev => [
      ...prev,
      {
        duration: 12,
        type: '',
        period: 'Month(s)',
      },
    ]);
  }, [data]);

  useEffect(() => {
    // having by default 1 row if its empty
    if (!readOnly && data.length === 0) {
      handleAddNew();
    }
  }, [data, readOnly, handleAddNew]);

  const handleRemove = useCallback(
    index => {
      setData(prev => {
        const newData = _.cloneDeep(prev);

        newData.splice(index, 1);

        return newData;
      });
    },
    [data],
  );

  const handleChange = (change, field, index) => {
    setData(prev => {
      const newData = _.cloneDeep(prev);

      newData[index][field] = change;

      if (field === 'type') newData[index].validationMessageWarrantyType = '';
      if (field === 'duration') newData[index].validationMessageDuration = '';

      return newData;
    });
  };

  const [
    doUpdate,
    {
      error: updateError,
      isLoading: isLoadingUpdate,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateWarrantyPeriodTableMutation?.(data) || [undefined, {}];

  const handleClickSave = useCallback(() => {
    let isValid = true;
    const validateUpdate = data.map(dt => {
      const newData = { ...dt };
      if (!newData.type) {
        isValid = false;
        newData.validationMessageWarrantyType = 'Warranty Type is required!';
      }
      if (!newData.duration) {
        isValid = false;
        newData.validationMessageDuration = 'Timeframe is required!';
      }
      return newData;
    });
    if (!isValid) {
      setData(validateUpdate);
      return;
    }

    doUpdate({
      id: assetId,
      notes: notesData,
      periods: data.map(period => {
        return {
          assetId,
          duration: period.duration,
          periodCode: optionsPeriod.find(x => x.description === period.period)
            ?.code,
          warrantyTypeId: optionsWarrantyType.find(x => x.name === period.type)
            ?.id,
        };
      }),
    });
  }, [data, doUpdate, assetId, optionsWarrantyType, optionsPeriod, notesData]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `${t('translation:form.warranty.manufacturerWarranty')} ${t(
            'translation:dynamicForm.updateSuccess',
          )}`,
        }),
      );
      onClose?.();
    }
  }, [isUpdateSuccess]);

  if ((!values || values.length === 0) && !readOnly) {
    return (
      <Typography variant="subtitle1" style={{ marginBottom: '5rem' }}>
        No Warranty Found
      </Typography>
    );
  }

  return !readOnly ? (
    <Grid container gap={4} sx={{ marginBottom: '2rem' }}>
      {values.map(value => {
        return (
          <Grid md={5.75}>
            <FormHelperText id="standard-weight-helper-text">
              {value.rowLabel}
            </FormHelperText>
            <Input
              aria-label=""
              fullWidth
              id="standard-adornment-weight"
              value={value.timeFrame}
              multiline={true}
              maxRows={2}
              endAdornment={
                <InputAdornment position="end">
                  {compareDatesMessage(value)}
                </InputAdornment>
              }
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                readOnly: true,
                'aria-label': 'weight',
              }}
            />
          </Grid>
        );
      })}
      {showNotes && (
        <Grid md={12}>
          <FormHelperText id="standard-weight-helper-text">
            Notes
          </FormHelperText>
          <TextField
            margin="normal"
            fullWidth
            id="standard-multiline-static"
            multiline
            rows={4}
            value={notes}
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
          />
        </Grid>
      )}
    </Grid>
  ) : (
    <>
      <ECBox display={'flex'} flexWrap={'wrap'} justifyContent="space-between">
        {data.map((value, ind) => {
          return (
            <ECBox minWidth={'550px'} my={1} maxWidth={'50%'}>
              <Typography color="rgba(0, 0, 0, 0.6)">
                Period {ind + 1}
              </Typography>
              <ECBox display="flex" justifyContent="space-between">
                <ECTextField
                  variant={'filled'}
                  disabled={false}
                  value={value.duration}
                  style={{ width: '10%', minWidth: '100px' }}
                  inputProps={{
                    maxLength: 3,
                  }}
                  onChange={e => {
                    let v: number | string = e.target.value;
                    v = v.replace(/[^0-9]/g, '');
                    if (v.length > 0 && isNumeric(v)) {
                      v = Number(v);
                    }

                    handleChange(v === 0 ? 1 : v, 'duration', ind);
                  }}
                  error={value.validationMessageDuration}
                  helperText={
                    value.validationMessageDuration
                      ? value.validationMessageDuration
                      : null
                  }
                />

                <ECFormControl variant={'filled'}>
                  <ECSelect
                    variant="filled"
                    value={value.period}
                    defaultValue={'Month(s)'}
                    onChange={e => handleChange(e.target.value, 'period', ind)}
                    sx={{ ...baseSx, minWidth: '120px', width: '25%' }}
                  >
                    {optionsPeriod.map(option => (
                      <ECMenuItem
                        key={option.description}
                        value={option.description}
                      >
                        {option.description}
                      </ECMenuItem>
                    ))}
                  </ECSelect>
                  {typeof value === 'object' &&
                    value?.validationMessagePeriod && (
                      <FormHelperText
                        sx={theme => ({
                          color: theme.palette.graphic.alert.error,
                        })}
                      >
                        {value?.validationMessagePeriod}
                      </FormHelperText>
                    )}
                </ECFormControl>

                <ECFormControl variant={'filled'}>
                  <InputLabel id={`${value?.type}-${ind}`}>
                    Warranty Type
                  </InputLabel>
                  <ECSelect
                    variant="filled"
                    value={value?.type}
                    defaultValue={''}
                    labelId={`${value?.type}-${ind}`}
                    label={'Warranty Type'}
                    onChange={e => handleChange(e.target.value, 'type', ind)}
                    sx={{ minWidth: '217px', width: '100%' }}
                  >
                    {optionsWarrantyType.map(option => (
                      <ECMenuItem key={option.name} value={option.name}>
                        {option.name}
                      </ECMenuItem>
                    ))}
                  </ECSelect>
                  {typeof value === 'object' &&
                    value?.validationMessageWarrantyType && (
                      <FormHelperText
                        sx={theme => ({
                          color: theme.palette.graphic.alert.error,
                        })}
                      >
                        {value?.validationMessageWarrantyType}
                      </FormHelperText>
                    )}
                </ECFormControl>

                <ECBox minWidth={'100px'} display="flex" alignItems={'center'}>
                  <ECButton
                    sx={theme => ({
                      color: theme.palette.graphic.alert.error,
                    })}
                    variant="text"
                    onClick={() => handleRemove(ind)}
                  >
                    <ECBox
                      sx={theme => ({
                        color: theme.palette.graphic.alert.error,
                      })}
                    >
                      {t('translation:form.markupMaterial.remove')}
                    </ECBox>
                  </ECButton>
                </ECBox>
              </ECBox>
            </ECBox>
          );
        })}
      </ECBox>
      {data.length < 20 && (
        <ECBox>
          <ECButton
            variant="text"
            onClick={handleAddNew}
            disabled={data.some(x => !x.type)}
          >
            {t('translation:form.warranty.addPeriod')}
          </ECButton>
        </ECBox>
      )}
      {showNotes && (
        <Grid md={12}>
          <FormHelperText id="standard-weight-helper-text">
            Notes
          </FormHelperText>
          <TextField
            margin="normal"
            fullWidth
            id="standard-multiline-static"
            variant="filled"
            multiline
            rows={4}
            defaultValue={notes}
            onChange={e => setNotesData(e.target.value)}
            inputProps={{
              maxLength: 1000,
            }}
            InputProps={{
              readOnly: false,
            }}
          />
        </Grid>
      )}
      <ECBox
        display="flex"
        width="100%"
        justifyContent="flex-end"
        alignItems="start"
        px={3}
        pb={3}
      >
        <ECStack direction="row" justifyContent="right" spacing={2}>
          <ECButton
            type="button"
            variant="text"
            sx={{ height: 42 }}
            onClick={onClose}
          >
            {t('translation:dynamicForm.cancel')}
          </ECButton>
          <ECButton
            variant="contained"
            disabled={isLoadingUpdate}
            sx={{
              height: 42,
              width: 'auto',
            }}
            onClick={handleClickSave}
          >
            {isLoadingUpdate ? (
              <ECCircularProgress
                size={'2rem'}
                sx={{ color: 'background.paper' }}
              />
            ) : (
              t('translation:dynamicForm.save')
            )}
          </ECButton>
        </ECStack>
      </ECBox>
    </>
  );
};
