import {
  ECEasyFormFieldType,
  ECLoadingProgress,
  ECPaper,
} from 'app/components';
import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { useUpdateMultipleStatusMutation } from 'services/dashboardApi';
import { themes } from 'styles/theme/themes';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import {
  BulkStatusActions,
  FlagBulkEditOptionEnum,
  StatusActions,
} from 'types/WorkflowStatus';
import { QueryTag } from '../DashboardPage/actions';
import {
  invoiceApi,
  useBulkInvoiceFlagsChangeMutation,
  useUpdateBulkInvoiceMutation,
} from 'services/invoiceApi';
import { useGetApprovalActionsQuery } from 'services/approvalApi';
import { populateDropDownFields } from 'utils/pageUtils';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { StyleConstants } from 'styles/StyleConstants';
import { useScheduledJob } from 'app/hooks/useScheduledJob.use-case';

const fancyFormElementsBulkEdit = require('./fancy_form_config_bulk_edit.json');
const fancyFormElementsBulkEditDone = require('./fancy_form_config_bulk_done.json');

interface BulkEditDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  selectedRows?: any[];
}

export const BulkEditDrawer = ({
  isOpen,
  selectedRows,
  onClose,
}: BulkEditDrawerProps) => {
  const dispatch = useDispatch();
  const [isDone, setIsDone] = useState(false);
  const [newCategoryValue, setNewCategoryValue] = useState<number>(-1);
  const [actionStatus, setActionStatus] = useState<BulkStatusActions>(
    BulkStatusActions.None,
  );
  const [doneFields, setDoneFields] = useState<any[]>(
    fancyFormElementsBulkEditDone.fields,
  );
  const [comment, setComment] = useState(null);
  const [flagsValue, setFlagsValue] = useState<any>([]);
  const [flagsOperationTypeValue, setFlagsOperationTypeValue] =
    useState<FlagBulkEditOptionEnum>();
  const [formFields, setFormFields] = useState<any[]>(
    _.cloneDeep(fancyFormElementsBulkEdit.fields),
  );

  const {
    data: flags,
    isLoading: isLoadingFlags,
    isSuccess: isSuccessFlags,
  } = useGetFlagsListQuery({});

  useEffect(() => {
    if (flags && isSuccessFlags) {
      const localEditFormElements = [...formFields];
      let radioFields = localEditFormElements?.find(
        field => field.fieldName === 'radioGroup',
      )?.options;
      let flagsGroupSubFields = radioFields?.find(
        field => field.groupName === 'flagsGroup',
      )?.fields;
      const flagsField = flagsGroupSubFields?.find(
        field => field.fieldName === 'flags',
      );

      flagsField.options =
        flags?.data.map(d => ({
          label: d.label,
          color: d.color,
          id: d.id,
        })) || [];
      flagsField.optionValues = flags?.data.map(d => d.label) || [];
    }
  }, [isSuccessFlags, flags, isOpen]);

  const { data: categoryListData, isSuccess: isSuccessCategoryListData } =
    useGetCategoryListQuery({ st: 1 }, { skip: !isOpen });

  const { data: actionsList } = useGetApprovalActionsQuery(undefined, {
    skip: !isOpen,
  });

  const actions = useMemo(() => {
    return actionsList?.filter(
      action => action.name !== StatusActions.NOTFIXED,
    );
  }, [actionsList]);

  const categoryFilterOptions = useMemo(() => {
    return (
      _.orderBy(categoryListData?.data, 'name')?.map(category => ({
        ...category,
        id: category?.id,
        label: category?.name,
        fieldName: category?.name,
        isInitialSelected: true,
      })) || []
    );
  }, [categoryListData]);

  useEffect(() => {
    if (categoryListData && isSuccessCategoryListData) {
      const localEditFormElements = [...formFields];

      let radioFields = localEditFormElements?.find(
        field => field.fieldName === 'radioGroup',
      )?.options;
      let categoryGroupSubFields = radioFields?.find(
        field => field.groupName === 'categoryGroup',
      )?.fields;
      populateDropDownFields({
        responseData: categoryFilterOptions,
        createFormFields: categoryGroupSubFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'category',
      });
      setFormFields(localEditFormElements);
    }
  }, [categoryListData, isSuccessCategoryListData, categoryFilterOptions]);

  useEffect(() => {
    if (actions) {
      const localEditFormElements = [...formFields];
      let radioFields = localEditFormElements?.find(
        field => field.fieldName === 'radioGroup',
      )?.options;
      let statusGroupSubFields = radioFields?.find(
        field => field.groupName === 'statusGroup',
      )?.fields;
      populateDropDownFields({
        responseData: actions,
        createFormFields: statusGroupSubFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'invoiceStatus',
      });
      setFormFields(localEditFormElements);
    }
  }, [actions]);

  useEffect(() => {
    if (isOpen) {
      const updated = [...formFields];
      const woCountLabelField = updated?.find(
        field => field.fieldName === 'invoiceCountLabel',
      );
      if (woCountLabelField) {
        woCountLabelField.label = 'Invoices Selected: ' + selectedRows?.length;
      }
      const selectedInvoicesField = updated?.find(
        field => field.fieldName === 'selectedInvoices',
      );
      selectedInvoicesField.value =
        selectedRows?.map(d => ({
          label: d.id,
          color: 'Grey',
          id: d.id,
        })) || [];

      setActionStatus(BulkStatusActions.None);
      setFormFields(updated);
    }
  }, [isOpen, selectedRows]);

  const [
    doUpdateMutation,
    { data: updateStausData, isSuccess, isError, isLoading, reset },
  ] = useUpdateMultipleStatusMutation();

  const [
    doFlagsChangeMutation,
    {
      data: flagsChangeData,
      isSuccess: flagsChangeSuccess,
      isError: flagsChangeError,
      isLoading: flagsChangeLoading,
      reset: resetFlagsChange,
    },
  ] = useBulkInvoiceFlagsChangeMutation();

  const {
    scheduledJobData,
    isScheduledJobCompleted,
    currentJobProgress,
    showScheduledJobProgress,
    clearJobIdAndProgress,
    isScheduledJobSuccess,
  } = useScheduledJob({
    isLoadingJobInfo: isLoading,
    isJobInfoSuccess: isSuccess,
    jobInfo: updateStausData,
  });

  const isLoadingDrawer =
    isLoading || showScheduledJobProgress || flagsChangeLoading;

  const module = 'invoice';
  const queryTag = QueryTag.PendingInvoices;

  const [
    doInvoiceCategoryChangeMutation,
    {
      data: invoiceCategoryChangeData,
      isSuccess: invoiceCategoryChangeSuccess,
      isLoading: invoiceCategoryChangeLoading,
      reset: resetInvoiceCategoryChange,
    },
  ] = useUpdateBulkInvoiceMutation();

  const useBulkEdit = useCallback(() => {
    const bulkEdit = async data => {
      delete data.undefined;

      if (!!data?.category) {
        setNewCategoryValue(data?.category);
        setActionStatus(BulkStatusActions.None);
        const params = {
          itemIds: data?.selectedInvoices?.map(invoice => invoice.id),
          categoryId: data.category,
        };
        doInvoiceCategoryChangeMutation(params);
      } else if (!!data?.invoiceStatus) {
        const selectedStatus = actions?.find(
          action => action.id === data?.invoiceStatus,
        );
        const statusUpdateList = {
          itemIds: data?.selectedInvoices?.map(invoice => invoice.id),
          actionId: selectedStatus?.id,
          note: data?.rejectReason,
        };
        setComment(data?.rejectReason);
        setActionStatus(
          selectedStatus?.name === StatusActions.APPROVE
            ? BulkStatusActions.Approved
            : BulkStatusActions.Rejected,
        );
        doUpdateMutation({ module, statusUpdateList });
      } else if (data?.flags && data?.flagOperationType) {
        setFlagsValue(data?.flags);
        setFlagsOperationTypeValue(
          FlagBulkEditOptionEnum[data?.flagOperationType] as any,
        );

        const params: any = {
          itemIds: data?.selectedInvoices?.map(invoice => invoice.id),
          flagIds: data?.flags.map(flag => flag.id),
          flagOptionId: FlagBulkEditOptionEnum[data?.flagOperationType],
        };

        doFlagsChangeMutation(params);
      }
    };
    return [
      bulkEdit,
      {
        updateStausData,
        isError: false,
        error: null,
        isLoading: false,
        isSuccess: false,
      },
    ];
  }, [
    doUpdateMutation,
    doFlagsChangeMutation,
    doInvoiceCategoryChangeMutation,
    updateStausData,
    actions,
  ]);

  useEffect(() => {
    let operationRows = (selectedRows || []).map(
      ({ id, flags, workflowStatus, request }) => ({
        itemId: id,
        statusTo: actionStatus,
        flagsFrom: flags,
        statusFrom: workflowStatus.name,
        categoryIdFrom: request?.category?.id,
        CategoryNameFrom: request?.category?.name,
      }),
    );
    if (actionStatus === BulkStatusActions.Rejected) {
      operationRows = operationRows.map(row => ({
        ...row,
        rejectionReason: comment,
      }));
    }
    if (!isLoading && isScheduledJobSuccess && isScheduledJobCompleted) {
      const rejectApprovePerform = (
        itemId: number,
        success: boolean,
        reason?: string,
      ) => {
        return operationRows?.map(row =>
          row.itemId === itemId
            ? {
                ...row,
                statusTo: actionStatus,
                statusOutcome: success,
                statusFailReason: success ? undefined : reason,
              }
            : row,
        );
      };
      scheduledJobData?.success?.forEach(itemId => {
        operationRows = rejectApprovePerform(itemId, true);
      });

      scheduledJobData?.failed?.forEach(item => {
        operationRows = rejectApprovePerform(item?.id, false, item?.reason);
      });
    }
    if (invoiceCategoryChangeSuccess && !invoiceCategoryChangeLoading) {
      const categortToName = categoryListData?.data?.find(
        item => item.id === newCategoryValue,
      )?.name;
      const categoryChangePerform = (
        itemId: number,
        success: boolean,
        reason?: string,
      ) => {
        return operationRows?.map(row =>
          row.itemId === itemId
            ? {
                ...row,
                categoryTo: categortToName,
                categoryOutcome: success,
                categoryFailReason: success ? undefined : reason,
              }
            : row,
        );
      };
      (invoiceCategoryChangeData as any)?.success?.forEach(itemId => {
        operationRows = categoryChangePerform(itemId, true);
      });

      (invoiceCategoryChangeData as any)?.failed?.forEach(item => {
        operationRows = categoryChangePerform(item?.id, false, item?.reason);
      });
    }

    if (actionStatus !== BulkStatusActions.None && newCategoryValue > -1) {
      if (
        isSuccess &&
        !isLoading &&
        invoiceCategoryChangeSuccess &&
        !invoiceCategoryChangeLoading
      ) {
        const updatedFields = [...doneFields];
        const bulkActivityViewField = updatedFields?.find(
          field => field.fieldName === 'bulkActivityView',
        );
        bulkActivityViewField.value = operationRows;
        setDoneFields(updatedFields);
        setIsDone(true);
        reset();
        resetInvoiceCategoryChange();
      }
    }

    if (actionStatus !== BulkStatusActions.None && newCategoryValue === -1) {
      if (!isLoading && isScheduledJobSuccess && isScheduledJobCompleted) {
        const updatedFields = [...doneFields];
        const bulkActivityViewField = updatedFields?.find(
          field => field.fieldName === 'bulkActivityView',
        );
        bulkActivityViewField.value = operationRows;
        setDoneFields(updatedFields);
        setIsDone(true);
        reset();
      }
    }
    if (actionStatus === BulkStatusActions.None && newCategoryValue > -1) {
      if (invoiceCategoryChangeSuccess && !invoiceCategoryChangeLoading) {
        const updatedFields = [...doneFields];
        const bulkActivityViewField = updatedFields?.find(
          field => field.fieldName === 'bulkActivityView',
        );
        bulkActivityViewField.value = operationRows;
        setDoneFields(updatedFields);
        setIsDone(true);
        resetInvoiceCategoryChange();
        reset();
      }
    }

    if (flagsChangeSuccess && !flagsChangeLoading) {
      const flagsValuesIds = flagsValue?.map(flag => flag.id);
      operationRows = operationRows.map(row => ({
        ...row,
        flagsOutcome: true,
        flagsFrom:
          flagsOperationTypeValue !==
          FlagBulkEditOptionEnum['Add Selected Flags']
            ? row.flagsFrom?.map(flag => ({
                label: flag.label,
                isStrikeThrough:
                  flagsOperationTypeValue ===
                    FlagBulkEditOptionEnum['Override with Selected Flags'] ||
                  flagsValuesIds?.includes(flag.id),
                id: flag.id,
              }))
            : [],
        flagsTo:
          flagsOperationTypeValue ===
          FlagBulkEditOptionEnum['Remove Selected Flags']
            ? row.flagsFrom
                ?.filter(flag => !flagsValuesIds?.includes(flag.id))
                ?.map(flag => ({
                  label: flag.label,
                  id: flag.id,
                }))
            : flagsValue?.map(flag => ({
                label: flag.label,
                id: flag.id,
              })),
      }));
      const updatedFields = [...doneFields];
      const bulkActivityViewField = updatedFields?.find(
        field => field.fieldName === 'bulkActivityView',
      );
      bulkActivityViewField.value = operationRows;
      setIsDone(true);
      setDoneFields(updatedFields);
      resetFlagsChange();
    }
  }, [
    updateStausData,
    isError,
    isLoading,
    isSuccess,
    onClose,
    reset,
    comment,
    selectedRows,
    doneFields,
    invoiceCategoryChangeSuccess,
    invoiceCategoryChangeLoading,
    actionStatus,
    newCategoryValue,
    flagsChangeSuccess,
    flagsChangeLoading,
    categoryListData?.data,
    invoiceCategoryChangeData,
    resetInvoiceCategoryChange,
    flagsValue,
    resetFlagsChange,
    flagsOperationTypeValue,
    isScheduledJobCompleted,
  ]);

  const handleOutputChange = (
    output: ECEasyFormFieldType[],
    fieldName?: string,
  ) => {
    if (fieldName === 'invoiceStatus') {
      const radioGroupField = output?.find(
        field => field.fieldName === 'radioGroup',
      );
      const statusGroupFields = radioGroupField?.options?.find(
        field => field.groupName === 'statusGroup',
      )?.fields;
      const statusField = statusGroupFields?.find(
        field => field.fieldName === 'invoiceStatus',
      );

      const selectedStatus = actions?.find(
        action => action.id === statusField?.value,
      );
      const isSelectedStatusReject =
        selectedStatus?.name === StatusActions.REJECT;

      const rejectReasonField = statusGroupFields?.find(
        field => field.fieldName === 'rejectReason',
      );
      rejectReasonField.required = isSelectedStatusReject;
      rejectReasonField.visible = isSelectedStatusReject;

      setFormFields(output);
    }
  };

  const handleClose = useCallback(() => {
    dispatch(invoiceApi.util.invalidateTags([queryTag]));
    setIsDone(false);
    setActionStatus(BulkStatusActions.None);
    setNewCategoryValue(-1);
    reset();
    resetInvoiceCategoryChange();
    clearJobIdAndProgress();
    setFormFields(_.cloneDeep(fancyFormElementsBulkEdit.fields));
    onClose?.();

    return {
      data: null,
      isError: false,
      error: null,
      isLoading: false,
      isSuccess: false,
    };
  }, [
    dispatch,
    invoiceApi.util,
    queryTag,
    setIsDone,
    setActionStatus,
    setNewCategoryValue,
    reset,
    resetInvoiceCategoryChange,
    onClose,
  ]);

  const useDone = useCallback(() => {
    return [
      () => {
        handleClose();
      },
      {
        data: null,
        isError: false,
        error: null,
        isLoading: false,
        isSuccess: false,
      },
    ];
  }, [
    dispatch,
    invoiceApi.util,
    queryTag,
    setIsDone,
    setActionStatus,
    setNewCategoryValue,
    reset,
    resetInvoiceCategoryChange,
    onClose,
  ]);

  return (
    <ECDrawerDetails open={isOpen} anchor="right" onClose={handleClose}>
      <ECPaper
        sx={{ height: '100%', paddingTop: StyleConstants.NAV_BAR_HEIGHT }}
        role="presentation"
      >
        <ECEasyFormCreate
          useCreateMutation={isDone ? useDone : useBulkEdit}
          formConfig={
            isDone
              ? fancyFormElementsBulkEditDone.config
              : fancyFormElementsBulkEdit.config
          }
          formFields={isDone ? doneFields : formFields}
          onClose={handleClose}
          drawerTitleBarBGColor={themes.light.palette.other.divider}
          CustomLoadingScreen={
            <ECLoadingProgress
              operationProgress={currentJobProgress}
              linearWithLabel
            />
          }
          CustomErrorScreen={
            <ECLoadingProgress
              operationProgress={currentJobProgress}
              linearWithLabel
              error
            />
          }
          CustomSuccessScreen={
            <ECLoadingProgress
              operationProgress={currentJobProgress}
              linearWithLabel
              success
            />
          }
          isLoading={isLoadingDrawer}
          onChange={handleOutputChange}
          hideCancelButton={isDone}
        />
      </ECPaper>
    </ECDrawerDetails>
  );
};
