import { ECBox, ECDynamicPageTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  useGetAssetByIdQuery,
  useGetAssetCircuitDetailsQuery,
  useLazyGetAssetCircuitsListQuery,
  useGetAssetCircuitsListQuery,
} from 'services/assetApi';
import { RTCircuitsCard } from './RTCircuitsCard';
import { Skeleton } from '@mui/material';

export function RefrigerantChargesPage() {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data: circuitDetails, isLoading: isLoadingCircuitDetails } =
    useGetAssetCircuitDetailsQuery({ id: Number(id) }, { skip: !id });

  // assetDetails is used to grab the branchId and assetTypeId
  const { data: assetDetails } = useGetAssetByIdQuery(Number(id), {
    skip: !id,
  });

  // need the circuits list to send to the EDIT drawer
  const { data: circuitsList } = useGetAssetCircuitsListQuery(
    { id: Number(id), queryParams: {} },
    { skip: !id },
  );

  return (
    <>
      {isLoadingCircuitDetails ? (
        <ECBox pl={1}>
          <Skeleton
            variant="rectangular"
            height={32}
            width={'25%'}
            sx={{ marginBottom: 2, marginTop: 1 }}
          />
          {Array.from(new Array(3)).map((_, index) => (
            <Skeleton
              variant="rectangular"
              key={index}
              height={32}
              width={'50%'}
              sx={{ marginBottom: 0.5 }}
            />
          ))}
        </ECBox>
      ) : (
        <RTCircuitsCard
          title={t('Refrigerant Charge')}
          generalStatus={{
            name: circuitDetails?.generalStatusText,
            color: circuitDetails?.generalStatusColor,
            severity: circuitDetails?.generalStatusColor,
          }}
          epaApplianceType={{
            ...circuitDetails?.refrigerantEPA,
            id: circuitDetails?.refrigerantEPAId,
          }}
          refrigerantInitalSp={{
            ...circuitDetails?.refrigerantInitalSp,
            id: circuitDetails?.refrigerantInitalSpId,
          }}
          circuits={circuitsList?.data || []}
          showCircuits={false}
          assetId={Number(id)}
          branchId={assetDetails?.branchId}
          assetTypeId={assetDetails?.assetTypeId}
          chargeLabel="Initial Capacity"
        />
      )}
      <ECDynamicPageTemplate
        shouldCacheLazyQuery
        marginTop={false}
        pageTitle="Refrigerant Charges"
        useLazyGetListQuery={useLazyGetAssetCircuitsListQuery}
        idToGetListBy={id}
        useUpdateMutation={{}}
        useCreateMutation={{}}
        hideSimpleSearchBar
        useSkeletonLoading
        // I'm usingGlobalSearch using this to hide the DIV
        usingGlobalSearch
        withDrawer={false}
      />
    </>
  );
}
