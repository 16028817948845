import { Branches } from '../Branches';
import { ECTabContainer } from '../../components';
import { StyleConstants } from 'styles/StyleConstants';
import { P } from 'types/Permission';
import { useCustomerUser } from 'app/hooks/customerUser.use-case';
import { ForbiddenPage } from '../ForbiddenPage';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import { useMemo } from 'react';

export function LocationsTabsPage() {
  const isCustomer = useCustomerUser();
  const { t } = useTranslation();

  const selectedTab = useMemo(() => {
    const pageTabOptions = [
      'locations',
      'regions',
      'districts',
      'regions-districts',
    ];

    const curPath = window.location.href.split('/').pop();
    const curPathWithoutParams = curPath?.split('?')[0];

    if (curPathWithoutParams && pageTabOptions.includes(curPathWithoutParams)) {
      return curPathWithoutParams;
    }
    return pageTabOptions[0];
  }, []);

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Helmet
        titleTemplate={`${t('translation:pages.locations.title')} - %s`}
        defaultTitle={t('translation:pages.locations.title')}
      />
      <ECTabContainer
        tabsAsLinks
        mt={StyleConstants.NAV_BAR_HEIGHT}
        p={2}
        variant="standard"
        color="marketingSuggestion"
        noPadding
        selectedTab={selectedTab}
        tabs={[
          {
            value: 'locations',
            label: 'Locations',
            content: <Branches marginTop={false} />,
            scopes: [P.GetAllBranches],
            link: 'management',
          },
          {
            value: 'regions',
            label: 'Regions',
            content: <Outlet />,
            // scopes: [P.GetAllBranches], get regions permission
            link: 'regions',
          },
          {
            value: 'districts',
            label: 'Districts',
            content: <Outlet />,
            // scopes: [P.GetAllBranches], get districts permission
            link: 'districts',
          },
          {
            value: 'regions-districts',
            label: 'Regions - Districts',
            scopes: [P.GetOrg],
            content: <Outlet />,
            link: 'regions-districts',
          },
        ]}
      />
    </>
  );
}
