import { ECDynamicPageTemplate } from 'app/components';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useLazyGetCustomersBranchListForSpQuery } from 'services/branchApi';

import { loadPageScopes } from 'utils/pageScopes';

const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const fancyFormElementsDetails = require('./fancy_form_config_details.json');

export function SPCustomersLocationsPage() {
  useEffect(() => {
    loadPageScopes('branch');
  }, []);
  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      pageTitle={t('translation:pages.customersLocations.title')}
      useLazyGetListQuery={useLazyGetCustomersBranchListForSpQuery}
      useCreateMutation={() => false}
      useUpdateMutation={() => false}
      editFormConfig={fancyFormElementsEdit.data.config}
      editFormFields={fancyFormElementsEdit.data.fields}
      detailsConfig={fancyFormElementsDetails.data.config}
      detailsFields={fancyFormElementsDetails.data.fields}
      // enableExport={true}
      marginTop={false}
      withDrawer={false}
      shouldNotUseActiveFilter
    />
  );
}
