import { ECDynamicPageTemplate, presetColors } from 'app/components';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  useCreateFlagsMutation,
  useLazyGetFlagsListQuery,
  useUpdateFlagsMutation,
} from 'services/flagsApi';
import { setFilterEndpoint } from 'store/slice/page';
import { loadPageScopes } from 'utils/pageScopes';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const fancyFormElementsDetails = require('./fancy_form_config_details.json');

export function FlagsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('flag');
  }, []);

  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/flag'));
  }, [dispatch]);

  const [
    doCreateFlag,
    {
      data: createFlagData,
      isError: isCreateError,
      error: createError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      reset: resetCreateFlag,
    },
  ] = useCreateFlagsMutation();

  const [
    doUpdateFlag,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset,
    },
  ] = useUpdateFlagsMutation();

  const useCreateFlag = useCallback(() => {
    const doCreate = data => {
      const colorName = data.color;
      const hexCode = presetColors.find(
        color => color.title === colorName,
      )?.color;
      data.hexCode = hexCode;
      doCreateFlag(data);
    };

    return [
      doCreate,
      {
        data: createFlagData,
        isError: isCreateError,
        error: createError,
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
        reset: resetCreateFlag,
      },
    ];
  }, [
    createFlagData,
    isCreateError,
    createError,
    isCreateLoading,
    isCreateSuccess,
    resetCreateFlag,
    doCreateFlag,
  ]);

  const useUpdateFlag = useCallback(() => {
    const doUpdate = async data => {
      const colorName = data.color;
      const hexCode = presetColors.find(
        color => color.title === colorName,
      )?.color;
      data.hexCode = hexCode;
      doUpdateFlag(data);
    };

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    reset,
    doUpdateFlag,
  ]);

  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      pageTitle={t('translation:pages.flags.title')}
      useLazyGetListQuery={useLazyGetFlagsListQuery}
      showStatusActiveFilter={true}
      useCreateMutation={useCreateFlag}
      useUpdateMutation={useUpdateFlag}
      createFormConfig={fancyFormElementsCreate.data.config}
      createFormFields={fancyFormElementsCreate.data.fields}
      editFormConfig={fancyFormElementsEdit.data.config}
      editFormFields={fancyFormElementsEdit.data.fields}
      detailsConfig={fancyFormElementsDetails.data.config}
      detailsFields={fancyFormElementsDetails.data.fields}
      marginTop={false}
      shouldNotUseActiveFilter
    />
  );
}
