import { ECDynamicPageTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import { loadPageScopes } from 'utils/pageScopes';
import {
  useCreateRequestForPricingMutation,
  useLazyGetRequestForPricingListQuery,
} from 'services/requestForPricingApi';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';
import { useGetAllWorkflowStatusQuery } from 'services/workflowApi';
import _ from 'lodash';
import { AddNew } from './Create/AddNew';

export function RequestForPricingPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('rfp');
    dispatch(setFilterEndpoint('/filter/rfp'));
  }, []);

  const {
    data: workflowStatuses,
    isFetching: isFetchingWorkflowStatuses,
    isSuccess: isSuccessWorkflowStatuses,
  } = useGetAllWorkflowStatusQuery('rfp');

  const statusFilterOptions = useMemo(() => {
    return workflowStatuses
      ? _.orderBy(workflowStatuses, 'name')?.map(status => ({
          id: status?.id,
          label: status.name,
          fieldName: workflowStatuses
            ?.filter(
              workflowStatus =>
                workflowStatus.name === status.name && workflowStatus.groupId,
            )
            ?.map(workflowStatus => workflowStatus.groupId)
            ?.join(','),
          isInitialSelected: true,
        }))
      : undefined;
  }, [workflowStatuses]);

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.requestForPricing.title')}
      useLazyGetListQuery={useLazyGetRequestForPricingListQuery}
      useCreateMutation={useCreateRequestForPricingMutation}
      isDraggableColumns
      showColumnVisibilityFilter
      withDrawer={false}
      showDateFilter
      dateFilterFieldsOptions={[
        {
          id: 1,
          label: 'Created Date',
          fieldName: 'rfp.createdAt',
        },
        {
          id: 2,
          label: 'Awarded Date',
          fieldName: 'rfp.awardedAt',
        },
      ]}
      showStatusFilter
      statusFilterAlias="wrkflwstts.groupNameId"
      statusFilterOptions={statusFilterOptions}
      shouldTriggerLazyQuery={
        statusFilterOptions &&
        !isFetchingWorkflowStatuses &&
        isSuccessWorkflowStatuses
      }
      customContent={{
        header: <AddNew />,
      }}
      shouldCacheLazyQuery
    />
  );
}
