import { ToggleButton, ToggleButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { requirePermissionWrapper } from 'app/hoc/require-permission';

interface ECToggleButtonProps extends ToggleButtonProps {
  selectedBgColor?: string;
  hoverColor?: string;
}

export const ToggleButtonStyled = styled(ToggleButton)<ECToggleButtonProps>(
  ({ theme, selectedBgColor, hoverColor }) => ({
    '&.Mui-selected': {
      backgroundColor: selectedBgColor || theme.palette.primary.main,
      color: theme.palette.background.default,
      '&:hover': {
        backgroundColor: hoverColor || theme.palette.primary.dark,
      },
    },
  }),
);

export const ECToggleButton =
  requirePermissionWrapper<ECToggleButtonProps>(ToggleButtonStyled);
