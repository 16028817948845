import { ColType, ECTableCell, ECTRCell } from 'app/components/ECTable';
import { EditableDynamicTableCellProps } from 'types/core/ECTable';
import { TableCellEditableFieldsMap } from './ECTableCellFields.editable.map';
import { ECTextField } from 'app/components/ECTextField';
import { get } from 'lodash';

/**
 * EditableDynamicTableCell is responsible for rendering
 * the editable cells of the table dynamically
 * based on the map of possible editable fields
 */
export const EditableDynamicTableCell: React.FC<
  EditableDynamicTableCellProps
> = ({ col, index, row, isNestedCell, onChange, customEditFieldsData }) => {
  const cellData = get(row, col.dataPath);
  if (index === 0) {
    return (
      <ECTRCell
        sx={{ pl: isNestedCell ? 20 : 2, minWidth: '10px' }}
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
      >
        {cellData}
      </ECTRCell>
    );
  }
  const componentToRender = TableCellEditableFieldsMap[col.type as ColType];
  if (!componentToRender) {
    return (
      <ECTableCell
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECTextField
          key={`${col?.title}-${col?.fieldName}-${row?.id}`}
          onChange={e => {
            e.preventDefault();
            onChange?.(e.target.value, col, row, undefined, index);
            e.currentTarget.focus();
          }}
          value={cellData}
        />
      </ECTableCell>
    );
  }
  return componentToRender({
    col,
    index,
    row,
    onChange,
    cellData,
    customData: customEditFieldsData,
  });
};
