import { ECBox } from '../../ECBox';
import {
  useGetWorkOrderByIdQuery,
  workOrdersApi,
} from 'services/workOrdersApi';
import { ECModal } from 'app/components/ECModal';
import { ECWorkflowSummary } from 'app/components/ECWorkflowSummary';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import { ECLink, ECRequirePermission } from 'app/components';
import { useMatch } from 'react-router';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { P } from 'types/Permission';
import FullscreenIcon from '@mui/icons-material/Launch';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

interface OpenWOCardModalProps {
  workOrderId: number;
  isOpen: boolean;
  onClose: (event?: any, reason?: any) => void;
}

export const OpenWOCardModal = ({
  workOrderId,
  isOpen,
  onClose,
}: OpenWOCardModalProps) => {
  const match = useMatch('/panel/*');
  const dispatch = useDispatch();

  useEffect(() => {
    if (workOrderId) {
      dispatch(workOrdersApi.util.invalidateTags(['WorkOrderById']));
    }
  }, [dispatch, workOrderId]);

  const { data: workOrder, isLoading: isLoadingWoById } =
    useGetWorkOrderByIdQuery(
      { id: workOrderId, includeLastCommentUserInfo: 1 },
      {
        skip: !workOrderId,
      },
    );

  return (
    <ECRequirePermission scopes={[P.GetWorkOrder]}>
      <ECModal
        showCloseButton
        isOpen={isOpen}
        onClose={onClose}
        sx={{ maxWidth: '650px', width: '100%' }}
        p={2}
        header={
          <ECBox
            display="flex"
            alignItems="center"
            width="100%"
            pl={1}
          >
            <ECLink
              href={`${match?.pathnameBase}/workorder/${workOrderId}`}
              fontSize={18}
              target="_blank"
              rel="noopener noreferrer"
            >
              Work Order #{workOrderId} <FullscreenIcon fontSize="small" />
            </ECLink>
          </ECBox>
        }
        showDivider
      >
        {isLoadingWoById ? (
          <ECBox display="flex" justifyContent="center" mt={3}>
            <ECCircularProgress />
          </ECBox>
        ) : (
          <ECWorkflowSummary
            showWarrantyAndLogo={false}
            fieldsOptionalSx={{
              minWidth: '100% !important',
            }}
            summaryFieldsData={[
              {
                id: 'status',
                label: 'WO Status',
                data: workOrder?.workflowStatus,
                type: SummaryFieldTypes.Status,
              },
              {
                id: 'location',
                label: 'Location',
                data: workOrder?.locationName,
                type: SummaryFieldTypes.Text,
              },
              {
                id: 'storeNumber',
                label: 'Store Number',
                data: workOrder?.locationStoreNumber,
                type: SummaryFieldTypes.Text,
              },
              {
                id: 'serviceProvider',
                label: 'Service Provider',
                data: workOrder?.serviceProviderName || 'Unassigned',
                type: SummaryFieldTypes.Text,
              },
              {
                id: 'asset',
                label: 'Asset',
                data: workOrder?.assetName,
                type: SummaryFieldTypes.Text,
              },
              {
                id: 'eta',
                label: 'ETA',
                data: workOrder?.eta,
                type: SummaryFieldTypes.Date,
              },
              {
                id: 'nte',
                label: 'NTE',
                data: workOrder?.nte,
                type: SummaryFieldTypes.Currency,
              },
              {
                id: 'category',
                label: 'Category',
                data: workOrder?.category,
                type: SummaryFieldTypes.Text,
              },
              {
                id: 'createdDate',
                label: 'Created Date',
                data: workOrder?.workOrderDate,
                type: SummaryFieldTypes.Date,
              },
              {
                id: 'ageOfWO',
                label: 'Age of Work Order',
                data: workOrder?.workOrderAge,
                type: SummaryFieldTypes.Text,
              },
              {
                id: 'priority',
                label: 'Priority',
                data: workOrder?.priorityName,
                type: SummaryFieldTypes.Priority,
              },
              {
                id: 'lastComment',
                label: 'Last Comment',
                data: workOrder?.lastComment,
                type: SummaryFieldTypes.ChipText,
                isVisible: !!workOrder?.lastComment,
              },
            ]}
          />
        )}
      </ECModal>
    </ECRequirePermission>
  );
};
