import { ECEasyFormCreate } from 'app/components';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import {
  useGetAssetConditionListQuery,
  useReplaceAssetMutation,
} from 'services/assetApi';
import { useGetDesignatedSPsQuery } from 'services/serviceProviderApi';
import { populateDropDownFields } from 'utils/pageUtils';
import { useGetManufactureListQuery } from 'services/manufactureApi';
import { useGetLocationsQuery } from 'services/locationsApi';
import { useGetAreaListQuery } from 'services/areaApi';
import { useCompanyCustomerProfile } from 'app/hooks/useCompanyCustomerProfile.use-case';
import { DateTime } from 'luxon';
import {
  useGetAssetTypesListQuery,
  useGetPeriodEULListQuery,
  useGetPriorityListQuery,
} from 'services/assetTypeApi';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { Asset } from 'types/Asset';
import { themes } from 'styles/theme/themes';
import { makeId } from 'utils/common';

interface ReplaceAssetProps {
  oldAsset: Asset;
  handleClose: () => void;
  // images have no type in Asset Details page
  oldAssetImages: any;
}

const fancyFormElementsReplace = require('./fancy_form_config_replace.json');

const ReplaceAssetContent = React.memo(
  ({ oldAsset, handleClose, oldAssetImages }: ReplaceAssetProps) => {
    const navigate = useNavigate();

    useEffect(() => {
      // change config title to match replacing asset name
      fancyFormElementsReplace.config.title = `Replace ${oldAsset?.name}`;
    }, [oldAsset]);

    // we use branchId and assetTypeId to get the designated SPs
    const [branchId, setBranchId] = useState(oldAsset?.branchId);
    const [assetTypeId, setAssetTypeId] = useState(oldAsset?.assetTypeId);

    // need to set default branchId and assetTypeId from old asset to search SPs
    useEffect(() => {
      setBranchId(oldAsset.branchId);
      setAssetTypeId(oldAsset.assetTypeId);
    }, []);

    const { data: designatedSpsData, isSuccess: isSuccessDesignatedSPs } =
      useGetDesignatedSPsQuery(
        {
          branchId: branchId || 0,
          assetTypeId: assetTypeId,
        },
        {
          skip: !branchId || !assetTypeId,
        },
      );
    useLayoutEffect(() => {
      const spField = fancyFormElementsReplace.fields.find(
        field => field.fieldName === 'spWarrantyCompany',
      );
      spField.useQuery = useGetDesignatedSPsQuery;
      spField.queryParams = {
        branchId: branchId || 0,
        assetTypeId: assetTypeId,
      };
    }, [isSuccessDesignatedSPs, designatedSpsData]);

    useLayoutEffect(() => {
      const manufactureFieldReplace = fancyFormElementsReplace.fields.find(
        field => field.fieldName === 'manufacture',
      );
      manufactureFieldReplace.useQuery = useGetManufactureListQuery;
    }, []);

    const { data: dataAssetCondition, isSuccess: isSuccessAssetCondition } =
      useGetAssetConditionListQuery();

    useLayoutEffect(() => {
      if (isSuccessAssetCondition && dataAssetCondition) {
        populateDropDownFields({
          responseData: dataAssetCondition,
          createFormFields: fancyFormElementsReplace.fields,
          dataOptionsFieldName: 'name',
          dataValuesFieldName: 'id',
          formFieldName: 'condition.name',
        });
      }
    }, [isSuccessAssetCondition, dataAssetCondition]);

    const customerProfile = useCompanyCustomerProfile();
    const areaEnabled = customerProfile?.enableArea;

    useLayoutEffect(() => {
      const branchField = fancyFormElementsReplace.fields.find(
        field => field.fieldName === 'branch',
      );
      branchField.useQuery = useGetLocationsQuery;
      branchField.obAlias = 'brnch.name';
    }, []);

    useLayoutEffect(() => {
      const areaField = fancyFormElementsReplace.fields.find(
        field => field.fieldName === 'area',
      );
      if (areaEnabled) {
        if (areaField) {
          areaField.useQuery = useGetAreaListQuery;
          areaField.obAlias = 'area.name';
        }
      } else {
        areaField.visible = false;
      }
    }, [areaEnabled]);

    useLayoutEffect(() => {
      if (oldAsset) {
        const warrantyStartDateFieldReplace =
          fancyFormElementsReplace.fields.find(
            field => field.fieldName === 'warrantyStartDateComponent',
          );
        const installationDateReplaceIndex =
          warrantyStartDateFieldReplace?.options?.findIndex(
            field => field.fieldName === 'installationDate',
          );

        warrantyStartDateFieldReplace.options[installationDateReplaceIndex] = {
          ...warrantyStartDateFieldReplace.options[
            installationDateReplaceIndex
          ],
          value: DateTime.now().toISODate(), // today
        };

        const manufactureWarrantyDateReplaceIndex =
          warrantyStartDateFieldReplace?.options?.findIndex(
            field => field.fieldName === 'purchaseDate',
          );
        warrantyStartDateFieldReplace.options[
          manufactureWarrantyDateReplaceIndex
        ] = {
          ...warrantyStartDateFieldReplace.options[
            manufactureWarrantyDateReplaceIndex
          ],
          value: oldAsset?.purchaseDate,
        };

        const storeOpenDateReplaceIndex =
          warrantyStartDateFieldReplace?.options?.findIndex(
            field => field.fieldName === 'storeOpenDate',
          );
        if (
          warrantyStartDateFieldReplace?.options?.[storeOpenDateReplaceIndex]
        ) {
          warrantyStartDateFieldReplace.options[storeOpenDateReplaceIndex] = {
            ...warrantyStartDateFieldReplace.options[storeOpenDateReplaceIndex],
            value: oldAsset?.branch?.openDate,
            required: true,
          };
        }

        warrantyStartDateFieldReplace.value =
          warrantyStartDateFieldReplace.options[installationDateReplaceIndex];
      }
    }, [oldAsset]);

    useLayoutEffect(() => {
      const assetTypeField = fancyFormElementsReplace.fields.find(
        field => field.fieldName === 'assetType',
      );
      assetTypeField.useQuery = useGetAssetTypesListQuery;
      assetTypeField.obAlias = 'assttp.name';
    }, []);

    useLayoutEffect(() => {
      const createFormFields = fancyFormElementsReplace.fields;
      let priorityField = createFormFields.find(
        field => field.fieldName === 'priority',
      );
      priorityField.useQuery = useGetPriorityListQuery;
      priorityField.obAlias = 'prrt.name';
    }, []);

    const { data: flagsData, isSuccess: isSuccessFlag } = useGetFlagsListQuery(
      {},
    );
    useEffect(() => {
      if (flagsData && isSuccessFlag) {
        let replaceSelectField = fancyFormElementsReplace.fields.find(
          field => field.fieldName === 'flags',
        );
        replaceSelectField.options =
          flagsData?.data.map(d => ({
            label: d.label,
            color: d.color,
            id: d.id,
          })) || [];
        replaceSelectField.optionValues =
          flagsData?.data.map(d => d.label) || [];
      }
    }, [isSuccessFlag, flagsData]);

    const { data: eulPeriodData, isSuccess: isEulPeriodSuccess } =
      useGetPeriodEULListQuery();
    useLayoutEffect(() => {
      if (isEulPeriodSuccess && eulPeriodData) {
        const periodValues = eulPeriodData?.map(period => ({
          label: period.description,
          id: period.code,
        }));

        const eulGroupFieldReplace = fancyFormElementsReplace.fields.find(
          field => field.groupName === 'estimationsGroup',
        );
        if (eulGroupFieldReplace) {
          const periodFieldReplace = eulGroupFieldReplace.subFields.find(
            subField => subField.fieldName === 'eulPeriodCode',
          );
          periodFieldReplace.options = periodValues;
        }
      }
    }, [eulPeriodData, isEulPeriodSuccess]);

    const [
      doReplaceAsset,
      {
        data: replaceAssetData,
        isError: isReplaceError,
        error: replaceError,
        isLoading: isReplaceLoading,
        isSuccess: isReplaceSuccess,
        reset: resetReplaceAsset,
      },
    ] = useReplaceAssetMutation();

    const useReplaceAsset = useCallback(() => {
      const doReplace = async data => {
        const areaId = data?.area?.id || oldAsset?.areaId;
        const replaceAssetData = {
          ...data,
          id: oldAsset?.id,
          areaId,
          UUID: makeId(data?.branch?.id || oldAsset?.branchId || '0'),
        };
        delete replaceAssetData.undefined;

        replaceAssetData.migrateQRCode =
          replaceAssetData.migrateQRCode.includes(
            'Migrate QR code to new Asset',
          );

        if (
          data.copyAttachmentsFormAsset.includes('Copy Attachments from Asset')
        ) {
          replaceAssetData.files = [
            ...replaceAssetData.assetFiles,
            ...(replaceAssetData.files || []),
          ];
        }
        delete replaceAssetData.copyAttachmentsFormAsset;
        delete replaceAssetData.assetFiles;

        doReplaceAsset(replaceAssetData);
      };

      return [
        doReplace,
        {
          data: replaceAssetData,
          isError: isReplaceError,
          error: replaceError,
          isLoading: isReplaceLoading,
          isSuccess: isReplaceSuccess,
        },
      ];
    }, [
      oldAsset,
      replaceAssetData,
      isReplaceError,
      replaceError,
      isReplaceLoading,
      isReplaceSuccess,
      doReplaceAsset,
    ]);

    const match = useMatch('/panel/*');
    useEffect(() => {
      if (replaceAssetData?.id) {
        resetReplaceAsset();
        navigate(
          `${match?.pathnameBase}/${oldAsset?.moduleName}/${replaceAssetData.id}`,
        );
      }
    }, [
      replaceAssetData?.id,
      oldAsset?.moduleName,
      match?.pathnameBase,
      resetReplaceAsset,
      navigate,
    ]);

    // the initial values for the form with specific logics for the values based on oldAsset
    const replaceAssetExistingData = useMemo(() => {
      const today = DateTime.now().toISODate();
      return {
        ...oldAsset,
        // condition should default to EXCELLENT
        condition:
          dataAssetCondition?.find(
            ac => ac.name.toLowerCase() === 'excellent',
          ) || oldAsset?.condition,

        // purchaseDate (manufacturer date), installationDate and warrantyStartDate is default TODAY as well
        purchaseDate: today,
        installationDate: today,
        warrantyStartDate: today,
        files: null,
        assetFiles: oldAssetImages,
        // asset type by default is not complete
        assetType: {
          ...oldAsset?.assetType,
          period: { code: oldAsset?.eulPeriodCode },
          eul: oldAsset?.estimatedUsefulLife,
          id: oldAsset?.assetTypeId,
        },

        // priority by default is asset group priority
        priority: oldAsset?.assetType.priority,

        // nte and warranty NTE are also asset group's
        nte: oldAsset?.assetType.nte,
        warrantyNte: oldAsset?.assetType.warrantyNte,

        // QR code needs to be cleared
        qrCode: '',
      };
    }, [oldAsset, dataAssetCondition, oldAssetImages]);

    const [forceRefresh, setForceRefresh] = useState(new Date());

    // onChange exists to change asset type and branch ID to fetch designated SPs
    const handleOnChangeForm = useCallback((output, fieldName) => {
      if (fieldName === 'assetType') {
        const assetType = output.find(field => field.fieldName === 'assetType')
          ?.value as any;
        const assetTypeId = assetType?.id;
        // we set the assetTypeId state to get new designated SPs
        setAssetTypeId(assetTypeId);
      }

      // if fieldName changed is branch we set the branchId state to get new designated SPs
      if (fieldName === 'branch') {
        const branch = output.find(field => field.fieldName === 'branch')
          ?.value as any;
        const branchId = branch?.id;
        setBranchId(branchId);
      }

      if (fieldName === 'copyAttachmentsFormAsset') {
        const copyAttachmentBox = output.find(
          field => field.groupName === 'copyAttachmentBox',
        );
        const copyAttachmentsFormAsset = copyAttachmentBox.subFields.find(
          field => field.fieldName === 'copyAttachmentsFormAsset',
        );
        const copyAttachmentsFormAssetEnabled =
          copyAttachmentsFormAsset.value.includes(
            'Copy Attachments from Asset',
          );
        const assetFilesField = output.find(
          field => field.fieldName === 'assetFiles',
        );
        assetFilesField.visible = copyAttachmentsFormAssetEnabled;
      }

      if (fieldName === 'migrateQRCode') {
        const qrCodeBox = output.find(field => field.groupName === 'qrCodeBox');
        const migrateQRCode = qrCodeBox.subFields.find(
          field => field.fieldName === 'migrateQRCode',
        );
        const migrateQRCodeEnabled = migrateQRCode.value.includes(
          'Migrate QR code to new Asset',
        );

        const qrCodeField = output.find(field => field.fieldName === 'qrCode');
        qrCodeField.visible = !migrateQRCodeEnabled;
      }

      // need the forceRefresh to make sure the form is updated. Priority was not visually changing
      setForceRefresh(new Date());
    }, []);

    return (
      <ECEasyFormCreate
        useCreateMutation={useReplaceAsset}
        formConfig={fancyFormElementsReplace.config}
        formFields={fancyFormElementsReplace.fields}
        existingData={replaceAssetExistingData}
        onClose={handleClose}
        onChange={handleOnChangeForm}
        stickyFooter={false}
        drawerTitleBarBGColor={themes.light.palette.other.divider}
      />
    );
  },
);

export const ReplaceAsset =
  requirePermissionWrapper<ReplaceAssetProps>(ReplaceAssetContent);
