import { Download, Refresh } from '@mui/icons-material';
import { ECBox } from 'app/components/ECBox';
import { ECPopover } from 'app/components/ECPopover';
import { ECToggleButton } from 'app/components/ECToggleButton';
import { ECTypography } from 'app/components/ECTypography';
import { DownloadItemStatus, ECDownloadListItem } from './ECDownloadListItem';
import { useGetReportsExportListQuery } from 'services/exportApi';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { ECIconButton } from 'app/components/ECIconButton';
import { DateTime } from 'luxon';

const REPORTS_EXPORT_POLLING_INTERVAL = 1 * 60 * 1000; // 1 min
const MAX_INTERVAL_FOR_AUTO_REFRESH_IN_MIN = 5;

export const ECDownloadReportsButton = () => {
  const [anchorElDownload, setAnchorElDownload] =
    useState<HTMLButtonElement | null>(null);
  const [shouldSkipQuery, setShouldSkipQuery] = useState(false);
  const [shouldShowRefreshButton, setShouldShowRefreshButton] = useState(false);

  const {
    data: reportsByModule,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useGetReportsExportListQuery(undefined, {
    // Skip doesn't work as expected.
    // When a query is skipped, its data is set to undefined as discussed here https://github.com/reduxjs/redux-toolkit/issues/2871
    // So we set pollingInterval to 0 as a workaround to achieve the same behavior as skip true.
    pollingInterval:
      shouldSkipQuery || shouldShowRefreshButton
        ? 0
        : REPORTS_EXPORT_POLLING_INTERVAL,
  });

  useEffect(() => {
    if (error && !isFetching) {
      setShouldSkipQuery(false);
    }
  }, [error, isFetching]);

  useEffect(() => {
    const ongoingExports = Object.entries(reportsByModule || {})?.reduce(
      (acc, [module, reports]) => {
        const ongoingReports = reports?.filter(report =>
          ['In Progress', 'Queued'].includes(report.status),
        );

        if (ongoingReports.length) {
          acc[module] = ongoingReports;
        }

        return acc;
      },
      {},
    );

    if (!Object.keys(ongoingExports)?.length) {
      return;
    }

    const isAllOnGoingRequestsNeedRefresh = Object.entries(
      ongoingExports || {},
    )?.every(([module, reports]) => {
      return (reports as any)?.every(
        report =>
          Math.abs(
            DateTime.fromISO(report.createdAt).diffNow('minutes').as('minutes'),
          ) >= MAX_INTERVAL_FOR_AUTO_REFRESH_IN_MIN,
      );
    });

    setShouldShowRefreshButton(isAllOnGoingRequestsNeedRefresh);
  }, [isLoading, reportsByModule, error]);

  useEffect(() => {
    if (!isLoading && !!reportsByModule && !error) {
      const readyForDownloadExports = Object.entries(
        reportsByModule || {},
      )?.reduce((acc, [module, reports]) => {
        const readyForDownloadReports = reports?.filter(
          report => report.status === 'Ready for Download',
        );

        if (readyForDownloadReports.length) {
          acc[module] = readyForDownloadReports;
        }

        return acc;
      }, {});

      const ongoingExports = Object.entries(reportsByModule || {})?.reduce(
        (acc, [module, reports]) => {
          const ongoingReports = reports?.filter(report =>
            ['In Progress', 'Queued'].includes(report.status),
          );

          if (ongoingReports.length) {
            acc[module] = ongoingReports;
          }

          return acc;
        },
        {},
      );

      setShouldSkipQuery(!Object.keys(ongoingExports)?.length);

      const downloadedExportsIds = JSON.parse(
        localStorage.getItem('downloadedExportsIds') || '[]',
      );
      const newDownloadedReportsIds: string[] = [];
      Object.entries(readyForDownloadExports).forEach(([module, reports]) => {
        (reports as any)?.forEach((report, index) => {
          const hasDownloaded = downloadedExportsIds?.includes(
            report.uuid?.toString(),
          );

          if (!hasDownloaded) {
            // Timeout is necessary because some browsers don't accept fast clicks.
            setTimeout(
              () => {
                const hiddenElement = document.createElement('a');

                hiddenElement.id = report.uuid;
                hiddenElement.href = report.link || '';
                hiddenElement.download = report.title;

                document.body.appendChild(hiddenElement);

                hiddenElement.click();
                document.body.removeChild(hiddenElement);
              },
              1000 * (index + 1),
            );

            newDownloadedReportsIds.push(report.uuid);
          }
        });
      });

      if (newDownloadedReportsIds.length) {
        localStorage.setItem(
          'downloadedExportsIds',
          JSON.stringify([
            ...(downloadedExportsIds || []),
            ...newDownloadedReportsIds,
          ]),
        );
      }
    }
  }, [isLoading, reportsByModule, error]);

  const handleDownloadIconClick = (event: React.MouseEvent<any>) => {
    setAnchorElDownload(event?.currentTarget);
  };

  const handleCloseDownloadPopup = () => {
    setAnchorElDownload(null);
  };

  const openDownloadPopup = Boolean(anchorElDownload);
  const idDownloadPopup = 'download-popover';

  if (_.isEmpty(reportsByModule) && !isLoading) {
    return null;
  }

  return (
    <ECBox display="flex" mx={2}>
      <ECToggleButton
        value="download"
        size="small"
        sx={{
          backgroundColor: 'white',
          '&:hover': {
            bgcolor: theme => theme.palette.common.white,
          },
        }}
        selected={!!anchorElDownload}
        onClick={e => handleDownloadIconClick(e)}
      >
        <Download />
      </ECToggleButton>

      <ECPopover
        open={openDownloadPopup}
        anchorEl={anchorElDownload}
        sx={{
          '& .MuiPopover-paper': {
            width: '378px',
            bgcolor: theme => theme.palette.common.white,
          },
        }}
        onClose={handleCloseDownloadPopup}
        id={idDownloadPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ECBox display="flex" justifyContent="space-between" px={3} py={2}>
          <ECTypography variant="h6">Exporting</ECTypography>

          {shouldShowRefreshButton && (
            <ECIconButton onClick={refetch} size="small" isLoading={isFetching}>
              <Refresh />
            </ECIconButton>
          )}
        </ECBox>

        <ECBox display="flex" flexDirection="column" px={3} py={2}>
          <ECTypography variant="body2">
            Your files are almost ready! We'll automatically start the download
            in your browser once it's finished. You can track the progress by
            clicking the Download icon in the top right corner of the top bar.
          </ECTypography>
        </ECBox>

        <ECBox display="flex" flexDirection="column" px={3} py={2} gap={2}>
          {Object.entries(reportsByModule || {})?.map(([module, reports]) => {
            return (
              <ECDownloadListItem
                title={module}
                items={reports?.map(report => ({
                  id: report.id?.toString(),
                  uuid: report.uuid,
                  title: report.job,
                  status: DownloadItemStatus[report.status],
                  link: report.link,
                }))}
                onRetryClick={item => console.log('retry item : ', item)}
              />
            );
          })}
        </ECBox>
      </ECPopover>
    </ECBox>
  );
};
