import { ECBox, ECMainBox, ECNotLoggedInBar } from 'app/components';
import { PaymentMethodCreateDrawer } from '../BillingPage/PaymentMethodCreateDrawer';
import { useMediaQuery, useTheme } from '@mui/material';

export const AddPaymentPage = () => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ECMainBox
      component={'main'}
      sx={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
        overflowX: 'hidden',
      }}
    >
      <ECNotLoggedInBar />

      <ECBox
        sx={{ marginTop: '5%', paddingBottom: 2 }}
        paddingInline={isMobileSize ? 0 : `15%`}
      >
        <PaymentMethodCreateDrawer
          openPaymentMethodDrawer={true}
          handleClose={() => {
            localStorage.removeItem('userProfile');
            localStorage.removeItem('userProfileFromCache');
            localStorage.removeItem('userProfileLastCallTimestamp');
            localStorage.removeItem('companyProfile');
            localStorage.removeItem('companyProfileFromCache');
            localStorage.removeItem('companyProfileLastCallTimestamp');
            localStorage.removeItem('customSessionTimeoutTimestamp');
            localStorage.removeItem('customSSOSessionTimeoutTimestamp');
            window.location.replace('/');
          }}
          shouldShowAsFullPage={true}
        />
      </ECBox>
    </ECMainBox>
  );
};
