export const NAV_BAR_HEIGHT_PX = 64;
export const TABS_HEIGHT_IN_PX = 261;
export const DELINQUENT_BANNER_HEIGHT = '72px';

export enum DashboardTables {
  unassignedWO,
  pendingWO,
  pastEtaWo,
  pendingProposals,
  pendingInvoices,
  awaitingInvoice,
}

export enum Modules {
  WORKORDER,
  INVOICE,
  PROPOSAL,
}

export const chipColors = {
  Red: '#e31b0c',
  Pink: '#ec76ae',
  Orange: '#ed6c02',
  Yellow: '#ffb400',
  Purple: '#6c3ff6',
  Grey: '#5c5c5c',
  'Dark Green': '#3bb23e',
  'Light Green': '#62bd66',
  'Dark Blue': '#0374cb',
  'Light Blue': '#6cbfff',
};

export const bulkEditButtonStyle = {
  height: '42px',
};

export const DEFAULT_MIN_SEARCH_LENGTH = 1;
