import { QueryParams } from 'types/QueryParams';
import {
  Category,
  CategoryCreate,
  CategoryCreateFormData,
  CategoryUpdate,
  CategoryUpdateFormData,
} from 'types/Category';
import { BaseType } from 'types/BaseType';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Category'],
});

export const categoryApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getCategoryList: builder.query<BaseType<Category[]>, QueryParams>({
      query: params => {
        return {
          url: 'category',
          params,
        };
      },
      transformResponse: (response: BaseType<Category[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          moduleName: 'category',
        }));

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['Category'],
      keepUnusedDataFor: 300,
    }),
    getCategoryById: builder.query<Category, number>({
      query: id => {
        return {
          url: `category/${id}`,
        };
      },
      transformResponse: (response: Category) => {
        return {
          ...response,
          moduleName: 'category',
        };
      },
    }),
    createCategory: builder.mutation<
      void,
      CategoryCreate & CategoryCreateFormData
    >({
      query: (formData: CategoryCreateFormData) => {
        const { name, approvalProcess } = formData;
        const body: CategoryCreate = {
          name,
          approvalProcessId: approvalProcess.id,
        };

        return {
          url: 'category',
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['Category'],
    }),
    updateCategory: builder.mutation<void, CategoryUpdate>({
      query: (formData: CategoryUpdateFormData) => {
        const { id, name, status } = formData;
        const body: CategoryUpdate = { name, status };
        return {
          url: `category/${id}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['Category'],
    }),
  }),
});

export const {
  useGetCategoryListQuery,
  useGetCategoryByIdQuery,
  useLazyGetCategoryListQuery,
  useLazyGetCategoryByIdQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
} = categoryApi;
