import { alpha, darken, lighten } from '@material-ui/core';
import { createTheme } from '@mui/material/styles';

const v2Theme = createTheme({
  palette: {
    background: {
      primary: '#F9F8F5',
      secondary: '#FFFFFC',
      tertiary: '#E8E8E8',
      saveChangesFooter: '#EBDAC6',
      tables: '#EBDAC6',
      smDrawerHeader: '#61899F',
      darkBg: '#201D1C',
      sectionBg: '#4C8FC31A',
    },
    table: {
      tableDarkRowTable: '#F8F8F8',
      selectedRow: alpha('#61899F', 0.1),
      table: '#CAD8DF',
    },
    button: {
      actionPrimary: '#61899F',
      actionSecondary: '#201D1C',
      hover: '#4B6A7B',
      hoverOutlinedText: alpha('#61899F', 0.1),
      active: '#154F6F',
      disable: '#E8E8E8',
    },
    text: {
      primary: '#201D1C',
      secondary: '#777777',
      tertiary: '#BBBBBB',
      links: '#3485C5',
      header: '#201D1C',
      disabled: '#D2D2D2',
      button: {
        actionPrimary: '#61899F',
        actionSecondary: '#201D1C',
        actionDisable: '#A4A4A4',
      },
      alert: {
        info: '#2F6792',
        warning: '#985014',
        success: '#467855',
        error: '#7A262E',
      },
    },
    border: {
      primary: '#EEEADC',
      secondary: '#201D1C',
      tertiary: '#000000',
      disable: '#E8E8E8',
      element: '#777777',
      button: {
        actionPrimary: '#61899F',
        actionPrimaryLight: '#96B0BF',
        actionSecondary: '#201D1C',
        disable: '#E8E8E8',
      },
    },
    graphic: {
      primary: '#201D1C',
      secondary: '#777777',
      tertiary: '#BBBBBB',
      button: {
        actionPrimary: '#61899F',
        actionSecondary: '#201D1C',
      },
      alert: {
        error: '#E74A4F',
        warning: '#ED6C02',
        info: '#3485C5',
        success: '#32C175',
      },
    },
    primary: {
      main: '#61899F',
      light: '#5CA0FF',
      dark: '#201D1C',
      contrast: '#FFFFFF',
      containedHoverBackground: darken('#61899F', 0.3),
      outlinedHoverBackground: alpha('#61899F', 0.08),
      outlinedRestingBackground: alpha('#61899F', 0.5),
    },
    secondary: {
      main: '#00C1B4',
      light: '#5FF4E6',
      dark: '#009084',
      containedHoverBackground: darken('#00C1B4', 0.3),
      outlinedHoverBackground: alpha('#00C1B4', 0.08),
      outlinedRestingBackground: alpha('#00C1B4', 0.5),
    },
    error: {
      main: '#F44336',
      dark: '#E31B0C',
      light: '#E74A4F',
      containedHoverBackground: darken('#F44336', 0.3),
      outlinedHoverBackground: alpha('#F44336', 0.08),
      outlinedRestingBackground: alpha('#F44336', 0.5),
      content: darken('#F44336', 0.6),
      background: lighten('#F44336', 0.9),
    },
    info: {
      main: '#0090FF',
      dark: '#0063CB',
      light: '#3485C5',
      containedHoverBackground: darken('#0090FF', 0.3),
      outlinedHoverBackground: alpha('#0090FF', 0.08),
      outlinedRestingBackground: alpha('#0090FF', 0.5),
      content: darken('#0090FF', 0.6),
      background: lighten('#0090FF', 0.9),
    },
    warning: {
      main: '#ED6C02',
      dark: '#C77700',
      light: '#ED6C02',
      containedHoverBackground: darken('#ED6C02', 0.3),
      outlinedHoverBackground: alpha('#ED6C02', 0.08),
      outlinedRestingBackground: alpha('#ED6C02', 0.5),
      content: darken('#ED6C02', 0.6),
      background: lighten('#ED6C02', 0.9),
      title: '#985014',
    },
    success: {
      main: '#4CAF50',
      dark: '#3B873E',
      light: '#32C175',
      containedHoverBackground: darken('#4CAF50', 0.3),
      outlinedHoverBackground: alpha('#4CAF50', 0.08),
      outlinedRestingBackground: alpha('#4CAF50', 0.5),
      content: darken('#4CAF50', 0.6),
      background: lighten('#4CAF50', 0.9),
    },
    action: {
      active: alpha('#000000', 0.54),
      hover: alpha('#000000', 0.04),
      selected: alpha('#000000', 0.08),
      disabled: alpha('#F9F8F5', 0.3),
      disabledBackground: alpha('#777777', 0.3),
      focus: alpha('#000000', 0.12),
    },
    other: {
      divider: alpha('#000000', 0.12),
      outlineBorder: alpha('#000000', 0.23),
      standardInputLine: alpha('#000000', 0.42),
      backdropOverlay: alpha('#000000', 0.5),
      ratingActive: '#FFB400',
      snackbarBackground: '#323232',
      selectedEdgeColor: '#FF0072',
      selectedNodeColor: '#8EF584',
      selectedNodeSourceColor: '#FC72B0',
      selectedNodeTargetColor: '#7DABF5',
      darkActiveWarranty: '#357B38',
      darkPartialWarranty: '#A64C01',
      darkNoWarranty: '#AB2F26',
      darkInfoWarranty: '#61899F',
      lightActiveWarranty: '#EDF7ED',
      lightPartialWarranty: '#FEF3EB',
      lightNoWarranty: '#FEECEB',
      lightInfoWarranty: alpha('#61899F', 0.08),
      background: '#FFFFFC',
      backgroundTable: '#CAD8DF',
      ecYellow: '#FFE921',
      loaderTableHeader: '#EBDAC6',
    },
    marketingSuggestion: {
      marketingSuggestion: '#201D1C',
      mainBackground: '#F9F8F5',
    },
    flowKit: {
      purple: '#7B61FF',
    },
  },
  typography: {
    fontFamily: 'Grotesk',
    button: {
      fontWeight: 'bold',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: () => ({
          color: v2Theme.palette.text.primary,
        }),
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 4,
      },
      styleOverrides: {
        root: {
          color: '#000000DE',
          borderRadius: '10px',
          boxShadow:
            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#CAD8DF',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.disabled) {
            return {
              color: `${v2Theme.palette.text.button.actionDisable} !important`,
            };
          }
          if (ownerState.variant === 'text') {
            return {
              color:
                ownerState.color === 'error'
                  ? `${v2Theme.palette.error.dark} !important`
                  : `${v2Theme.palette.primary.main} !important`,
            };
          }

          if (ownerState.variant === 'contained') {
            return {
              backgroundColor:
                ownerState.color === 'error'
                  ? v2Theme.palette.error.dark
                  : ownerState.color === 'success'
                    ? undefined
                    : v2Theme.palette.primary.main,
              '&:hover': {
                backgroundColor:
                  ownerState.color === 'error'
                    ? v2Theme.palette.error.containedHoverBackground
                    : ownerState.color === 'success'
                      ? undefined
                      : v2Theme.palette.primary.containedHoverBackground,
              },
            };
          }

          if (ownerState.variant === 'outlined') {
            return {
              backgroundColor: v2Theme.palette.common.white,
              border: `1px solid ${v2Theme.palette.primary.main} !important`,
              color: `${v2Theme.palette.primary.main} !important`,
            };
          }
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        icon: ({ ownerState }) => {
          if (ownerState.severity === 'error') {
            return {
              color: `${v2Theme.palette.graphic.alert.error} !important`,
            };
          }

          if (ownerState.severity === 'warning') {
            return {
              color: `${v2Theme.palette.graphic.alert.warning} !important`,
            };
          }

          if (ownerState.severity === 'info') {
            return {
              color: `${v2Theme.palette.graphic.alert.info} !important`,
            };
          }

          if (ownerState.severity === 'success') {
            return {
              color: `${v2Theme.palette.graphic.alert.success} !important`,
            };
          }
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.severity === 'error') {
            return {
              color: `${v2Theme.palette.text.alert.error} !important`,
            };
          }

          if (ownerState.severity === 'warning') {
            return {
              color: `${v2Theme.palette.text.alert.warning} !important`,
            };
          }

          if (ownerState.severity === 'info') {
            return {
              color: `${v2Theme.palette.text.alert.info} !important`,
            };
          }

          if (ownerState.severity === 'success') {
            return {
              color: `${v2Theme.palette.text.alert.success} !important`,
            };
          }
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'gray',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: () => ({
          color: 'gray',
          '&.Mui-disabled': {
            color: `${v2Theme.palette.text.button.actionDisable} !important`,
            opacity: 0.3,
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '&::before': () => ({
            borderColor: v2Theme.palette.other.standardInputLine,
          }),
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: ({ ownerState }) => {
          if (ownerState.variant === 'filled')
            return {
              transform: 'translate(12px, 7px) scale(0.75) !important',
            };
        },
        root: () => ({
          '&.Mui-disabled': {
            color: v2Theme.palette.text.secondary,
            opacity: 0.3,
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: () => ({
          '&.Mui-disabled': {
            color: v2Theme.palette.background.tertiary,
            opacity: 0.3,
          },
        }),
        input: () => ({
          '&.Mui-disabled': {
            color: v2Theme.palette.text.primary,
            '-webkit-text-fill-color': v2Theme.palette.text.primary,
          },
        }),
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 744,
      laptop: 1023,
    },
  },
});

export const themes = {
  light: v2Theme,
  dark: v2Theme,
};
