import { ECAlert, ECAlertTitle } from '../ECAlert';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { ECButton } from '../ECButton';
import { AutoGraph } from '@mui/icons-material';
import { ECLink } from '../ECLink';
import { formatDateOnlyForTable } from 'utils/strings/formatDate';
import { NAV_BAR_HEIGHT_PX, DELINQUENT_BANNER_HEIGHT } from 'utils/constants';
import { useGetProfileQuery } from 'services/profileApi';

export const ECFreeTrialBanner = () => {
  const companyProfile = useGetProfileQuery();

  const companySubscriptionInfo = companyProfile.data?.subscription;

  const isFreeTrialUser = companySubscriptionInfo?.companyHasTrialSubscription;

  if (!isFreeTrialUser) return null;

  return (
    <ECAlert
      severity="info"
      sx={themes => ({
        alignItems: 'center',
        '.MuiAlert-message': {
          paddingRight: '26px',
          width: '100% !important',
          height: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        marginTop: `${NAV_BAR_HEIGHT_PX}px`,
        marginBottom: `-${DELINQUENT_BANNER_HEIGHT}`,
        paddingY: 2,
        backgroundColor: themes.palette.info.background + ' !important',
        color: themes.palette.info.content + ' !important',
      })}
    >
      <ECBox
        display={'flex'}
        flexWrap={'wrap'}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <ECBox>
          <ECAlertTitle>
            <ECTypography
              variant="body2"
              marginRight={'10px'}
              fontWeight={'bold'}
            >
              You have {companySubscriptionInfo.daysNumber} days left on your
              Free Trial.
            </ECTypography>
          </ECAlertTitle>
          <ECTypography variant="body2" marginRight={'10px'}>
            Your free trial ends on{' '}
            {formatDateOnlyForTable(
              companySubscriptionInfo.nextChargeDate || '',
              true,
            )}
            .
          </ECTypography>
        </ECBox>
        {/* <ECLink
          color="inherit"
          href="https://www.ecotrak.com/resources/gated-content/contact-us-form"
        >
          <ECButton
            variant="contained"
            color="inherit"
            sx={{
              fontWeight: 'bold',
              minWidth: '218px',
            }}
            endIcon={<AutoGraph />}
          >
            Upgrade Your Plan
          </ECButton>
        </ECLink> */}
      </ECBox>
    </ECAlert>
  );
};
