import { Branch } from './Branch';

interface OrganizationBase {
  parentId: number;
  name: string;
  organizationType?: number;
  organizationTypeId?: number;
}

export interface Organization extends OrganizationBase {
  id: number;
  companyId: number;
  createdBy: number;
  parentId: number;
  children: Organization[];
}

export interface OrganizationCreate extends OrganizationBase {
  organizationTypeId?: number;
}

export interface OrganizationUpdate extends OrganizationBase {
  id: number;
}

export interface OrganizationDelete {
  id: number;
}

export interface OrganizationType {
  id: number;
  name: string;
  code: string;
}

export interface LocationAssignmentCustomerBranches extends Branch {
  isAssigned: boolean;
  address: string;
}

export interface SPCustomerBranches {
  config: any;
  data: LocationAssignmentCustomerBranches[];
  assignmentStatus: string;
  assignedBranchCount: number;
  allCustomerBranchCount: number;
}

export enum OrganizationTypeCode {
  Branch = 'BRANCH',
  Region = 'REGION',
  District = 'DISTRICT',
  Company = 'COMPANY',
}

export enum OrganizationIdTypeEnum {
  District = 3,
  Region = 2,
  Division = 5,
}

export interface CreateRegionsDistrictsParams {
  regionId: number;
  districtIds: number[];
}

export interface UpdateRegionsDistrictsParams {
  regionId: number;
  districtIdsToAdd?: number[];
  districtIdsToRemove?: number[];
}
