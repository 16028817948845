import { AttachmentCategory } from 'types/Attachment';
import { ECBox } from '../ECBox';
import { ECPhotoField } from '../ECPhotoField';
import { ECTypography } from '../ECTypography';
import { useCallback } from 'react';
import Compressor from 'compressorjs';
import _ from 'lodash';

export enum FilePickerTypes {
  Photo = 'photo',
  Document = 'document',
  Video = 'video',
}

// below is the list accepted by the BE
export const mimeTypes = {
  acx: 'application/internet-property-stream',
  ai: 'application/postscript',
  aif: 'audio/x-aiff',
  aifc: 'audio/x-aiff',
  aiff: 'audio/x-aiff',
  asf: 'video/x-ms-asf',
  asr: 'video/x-ms-asf',
  asx: 'video/x-ms-asf',
  au: 'audio/basic',
  avi: 'video/x-msvideo',
  axs: 'application/olescript',
  bas: 'text/plain',
  bcpio: 'application/x-bcpio',
  bin: 'application/octet-stream',
  bmp: 'image/bmp',
  c: 'text/plain',
  cat: 'application/vnd.ms-pkiseccat',
  cdf: 'application/x-cdf',
  cer: 'application/x-x509-ca-cert',
  class: 'application/octet-stream',
  clp: 'application/x-msclip',
  cmx: 'image/x-cmx',
  cod: 'image/cis-cod',
  cpio: 'application/x-cpio',
  crd: 'application/x-mscardfile',
  crl: 'application/pkix-crl',
  crt: 'application/x-x509-ca-cert',
  csh: 'application/x-csh',
  css: 'text/css',
  dcr: 'application/x-director',
  der: 'application/x-x509-ca-cert',
  dir: 'application/x-director',
  dll: 'application/x-msdownload',
  dms: 'application/octet-stream',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  dot: 'application/msword',
  dvi: 'application/x-dvi',
  dxr: 'application/x-director',
  eps: 'application/postscript',
  etx: 'text/x-setext',
  evy: 'application/envoy',
  exe: 'application/octet-stream',
  fif: 'application/fractals',
  flr: 'x-world/x-vrml',
  gif: 'image/gif',
  gtar: 'application/x-gtar',
  gz: 'application/x-gzip',
  h: 'text/plain',
  hdf: 'application/x-hdf',
  hlp: 'application/winhlp',
  hqx: 'application/mac-binhex40',
  hta: 'application/hta',
  htc: 'text/x-component',
  htm: 'text/html',
  html: 'text/html',
  htt: 'text/webviewhtml',
  ico: 'image/x-icon',
  ief: 'image/ief',
  iii: 'application/x-iphone',
  ins: 'application/x-internet-signup',
  isp: 'application/x-internet-signup',
  jfif: 'image/pipeg',
  jpe: 'image/jpeg',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  js: 'application/x-javascript',
  latex: 'application/x-latex',
  lha: 'application/octet-stream',
  lsf: 'video/x-la-asf',
  lsx: 'video/x-la-asf',
  lzh: 'application/octet-stream',
  m13: 'application/x-msmediaview',
  m14: 'application/x-msmediaview',
  m3u: 'audio/x-mpegurl',
  man: 'application/x-troff-man',
  mdb: 'application/x-msaccess',
  me: 'application/x-troff-me',
  mht: 'message/rfc822',
  mhtml: 'message/rfc822',
  mid: 'audio/mid',
  mny: 'application/x-msmoney',
  mov: 'video/quicktime',
  movie: 'video/x-sgi-movie',
  mp2: 'video/mpeg',
  mp3: 'audio/mpeg',
  mpa: 'video/mpeg',
  mpe: 'video/mpeg',
  mpeg: 'video/mpeg',
  mpg: 'video/mpeg',
  mpp: 'application/vnd.ms-project',
  mpv2: 'video/mpeg',
  ms: 'application/x-troff-ms',
  mvb: 'application/x-msmediaview',
  nws: 'message/rfc822',
  oda: 'application/oda',
  p10: 'application/pkcs10',
  p12: 'application/x-pkcs12',
  p7b: 'application/x-pkcs7-certificates',
  p7c: 'application/x-pkcs7-mime',
  p7m: 'application/x-pkcs7-mime',
  p7r: 'application/x-pkcs7-certreqresp',
  p7s: 'application/x-pkcs7-signature',
  pbm: 'image/x-portable-bitmap',
  pdf: 'application/pdf',
  pfx: 'application/x-pkcs12',
  pgm: 'image/x-portable-graymap',
  pko: 'application/ynd.ms-pkipko',
  pma: 'application/x-perfmon',
  pmc: 'application/x-perfmon',
  pml: 'application/x-perfmon',
  pmr: 'application/x-perfmon',
  pmw: 'application/x-perfmon',
  pnm: 'image/x-portable-anymap',
  png: 'image/png',
  pot: 'application/vnd.ms-powerpoint',
  ppm: 'image/x-portable-pixmap',
  pps: 'application/vnd.ms-powerpoint',
  ppt: 'application/vnd.ms-powerpoint',
  prf: 'application/pics-rules',
  ps: 'application/postscript',
  pub: 'application/x-mspublisher',
  qt: 'video/quicktime',
  ra: 'audio/x-pn-realaudio',
  ram: 'audio/x-pn-realaudio',
  ras: 'image/x-cmu-raster',
  rgb: 'image/x-rgb',
  rmi: 'audio/mid',
  roff: 'application/x-troff',
  rtf: 'application/rtf',
  rtx: 'text/richtext',
  scd: 'application/x-msschedule',
  sct: 'text/scriptlet',
  setpay: 'application/set-payment-initiation',
  setreg: 'application/set-registration-initiation',
  sh: 'application/x-sh',
  shar: 'application/x-shar',
  sit: 'application/x-stuffit',
  snd: 'audio/basic',
  spc: 'application/x-pkcs7-certificates',
  spl: 'application/futuresplash',
  src: 'application/x-wais-source',
  sst: 'application/vnd.ms-pkicertstore',
  stl: 'application/vnd.ms-pkistl',
  stm: 'text/html',
  svg: 'image/svg+xml',
  sv4cpio: 'application/x-sv4cpio',
  sv4crc: 'application/x-sv4crc',
  t: 'application/x-troff',
  tar: 'application/x-tar',
  tcl: 'application/x-tcl',
  tex: 'application/x-tex',
  texi: 'application/x-texinfo',
  texinfo: 'application/x-texinfo',
  tgz: 'application/x-compressed',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  tr: 'application/x-troff',
  trm: 'application/x-msterminal',
  tsv: 'text/tab-separated-values',
  txt: 'text/plain',
  uls: 'text/iuls',
  ustar: 'application/x-ustar',
  vcf: 'text/x-vcard',
  vrml: 'x-world/x-vrml',
  wav: 'audio/x-wav',
  wcm: 'application/vnd.ms-works',
  wdb: 'application/vnd.ms-works',
  wks: 'application/vnd.ms-works',
  wmf: 'application/x-msmetafile',
  wps: 'application/vnd.ms-works',
  wri: 'application/x-mswrite',
  wrl: 'x-world/x-vrml',
  wrz: 'x-world/x-vrml',
  xaf: 'x-world/x-vrml',
  xbm: 'image/x-xbitmap',
  xla: 'application/vnd.ms-excel',
  xlc: 'application/vnd.ms-excel',
  xlm: 'application/vnd.ms-excel',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xlt: 'application/vnd.ms-excel',
  xlw: 'application/vnd.ms-excel',
  xof: 'x-world/x-vrml',
  xpm: 'image/x-xpixmap',
  xwd: 'image/x-xwindowdump',
  z: 'application/x-compress',
  zip: 'application/zip',
  csv: 'text/csv',
  mp4: 'video/mp4',
  heic: 'image/heic',
};

export function validateFileType(originalName: string) {
  const fileOriginalNameSplit = originalName
    ?.split('.')
    ?.pop()
    ?.replace('.', '')
    ?.toLowerCase();
  const contentType = fileOriginalNameSplit
    ? mimeTypes[fileOriginalNameSplit]
    : false;
  return contentType;
}

export interface ImagePreview {
  originalName: string;
  id?: number;
  type?: AttachmentCategory;
  preview: string;
  processedFile?: any;
  name?: string;
  description?: string;
  fileType?: string;
  category?: string;
  thumbnailURL?: string;
  attachmentType?: AttachmentCategory;
  expiresOn?: any;
  lastModified?: any;
  documentRequiredId?: boolean | number;
  uploadedBy?: string;
  uploadDate?: string;
  documentType?: string;
}

export interface ECFilePickerProps {
  sx?: any;
  label: string;
  type?: FilePickerTypes;
  convertToBase64?: boolean;
  fieldName?: string;
  moduleName?: string;
  isReadOnly?: boolean;
  isDownloadEnabled?: boolean;
  isDeletePreviewEnabled?: boolean;
  isDeleteEnabled?: boolean;
  images?: ImagePreview[];
  srcTitle?: string;
  isValid?: boolean;
  isOnChangeError?: boolean;
  isOnChangeSuccess?: boolean;
  confirmationDeleteMessage?: string;
  showDeleteAlert?: boolean;
  deleteAlertTitle?: string;
  deleteAlertText?: string;
  onChange?: (files: any) => void;
  onDelete?: (photoId: number) => void;
  onAttachmentClick?: (id: number) => () => void;
  isCustomerOwnRequiredDocs?: boolean;
  hasAddAttachmentsPermission?: boolean;
  disabledUpload?: boolean;
  hideDragDropAreaWhenHaveFilesUploaded?: boolean;
}

const MAX_SIZE_IN_BYTES = 10000000; // 10MB
const MAX_SIZE_IN_BYTES_IMAGE = 2000000; // 2MB
const MAX_WIDTH_SIZE = 2048;
const MAX_HEIGHT_SIZE = 2048;

export const ECFilePicker = ({
  type = FilePickerTypes.Document,
  convertToBase64,
  fieldName,
  sx,
  isReadOnly,
  isDownloadEnabled,
  isDeletePreviewEnabled = true,
  isDeleteEnabled,
  images,
  moduleName,
  srcTitle,
  label,
  isOnChangeError,
  isOnChangeSuccess,
  confirmationDeleteMessage,
  showDeleteAlert = false,
  deleteAlertTitle,
  deleteAlertText,
  onAttachmentClick,
  onChange,
  onDelete,
  isValid = true,
  isCustomerOwnRequiredDocs,
  hasAddAttachmentsPermission,
  disabledUpload,
  hideDragDropAreaWhenHaveFilesUploaded,
}: ECFilePickerProps) => {
  const converToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const processFileFn = {
    [FilePickerTypes.Photo]: convertToBase64 ? converToBase64 : null,
    [FilePickerTypes.Document]: null,
  };

  const handleFileInput = async e => {
    const rawFile = e.target.files[0];

    if (rawFile && !validateFileType(rawFile?.name)) {
      alert('Invalid file type');
      return;
    }

    if (
      e.target.files[0]?.size > MAX_SIZE_IN_BYTES_IMAGE &&
      e.target.files[0]?.type?.includes('image')
    ) {
      const result = await new Promise((resolve, reject) => {
        new Compressor(e.target.files[0], {
          convertSize: MAX_SIZE_IN_BYTES,
          width: MAX_WIDTH_SIZE,
          height: MAX_HEIGHT_SIZE,
          resize: 'contain',
          success: resolve,
          error: reject,
        });
      });

      const processedFile = new File([result as any], (result as any).name);
      e.target.value = null;
      const url = URL.createObjectURL(rawFile);
      onChange?.([
        ...(images ?? []),
        {
          processedFile,
          rawFile: processedFile,
          url,
          preview: url,
          name: processedFile?.name || '',
          originalName: processedFile.name || '',
          attachmentType: (result as any).type.includes('image')
            ? AttachmentCategory.Photo
            : AttachmentCategory.Document,
          fileType: (result as any).type?.split('/')[1],
        },
      ]);
      return;
    } else if (e.target.files[0]?.size > MAX_SIZE_IN_BYTES) {
      alert('File size cannot exceed 10MB');
      return;
    }
    const processedFile = (await processFileFn[type]?.(rawFile)) ?? rawFile;

    e.target.value = null;
    const url = URL.createObjectURL(rawFile);
    onChange?.([
      ...(images ?? []),
      {
        processedFile: processedFile,
        rawFile,
        url,
        preview: url,
        name: processedFile.name,
        originalName: processedFile.name,
        attachmentType: processedFile.type.includes('image')
          ? AttachmentCategory.Photo
          : AttachmentCategory.Document,
        fileType: processedFile.type?.split('/')[1],
      },
    ]);
  };

  const handleFileDrop = async file => {
    if (file && !validateFileType(file?.name)) {
      alert('Invalid file type');
      return;
    }

    if (file?.size > MAX_SIZE_IN_BYTES) {
      if (file?.type?.includes('image')) {
        const result = await new Promise((resolve, reject) => {
          new Compressor(file, {
            convertSize: MAX_SIZE_IN_BYTES,
            width: MAX_WIDTH_SIZE,
            height: MAX_HEIGHT_SIZE,
            resize: 'contain',
            success: resolve,
            error: reject,
          });
        });

        const processedFile = new File([result as any], (result as any).name);
        const url = URL.createObjectURL(file);
        onChange?.([
          ...(images ?? []),
          {
            processedFile,
            file: processedFile,
            url,
            preview: url,
            name: processedFile?.name || '',
            originalName: processedFile.name || '',
            attachmentType: (result as any).type.includes('image')
              ? AttachmentCategory.Photo
              : AttachmentCategory.Document,
            fileType: (result as any).type?.split('/')[1],
          },
        ]);
        return;
      } else {
        alert('File size cannot exceed 10MB');
        return;
      }
    }

    const processedFile = (await processFileFn[type]?.(file)) ?? file;

    if (!file) return;

    const url = URL.createObjectURL(file);

    onChange?.([
      ...(images ?? []),
      {
        processedFile: processedFile,
        rawFile: file,
        url,
        preview: url,
        name: processedFile.name,
        originalName: processedFile.name,
        attachmentType: processedFile.type.includes('image')
          ? AttachmentCategory.Photo
          : AttachmentCategory.Document,
        fileType: processedFile.type?.split('/')[1],
      },
    ]);
  };

  const handleDeletePreview = useCallback(
    (photoUrl: string) => () => {
      const newImgPreviews =
        images?.filter(img => img.preview !== photoUrl) ?? [];
      onChange?.(newImgPreviews);
    },
    [images],
  );

  const handleDelete = useCallback(
    (photoId: number) => () => {
      onDelete?.(photoId);
    },
    [images],
  );

  if (
    isReadOnly ||
    (!_.isNil(hasAddAttachmentsPermission) && !hasAddAttachmentsPermission)
  ) {
    switch (type) {
      case FilePickerTypes.Document:
      case FilePickerTypes.Photo:
        if (!images?.length) {
          return (
            <ECTypography variant="subtitle2">No attachment yet.</ECTypography>
          );
        }
        return (
          <ECPhotoField
            hideDragDropAreaWhenHaveFilesUploaded={
              hideDragDropAreaWhenHaveFilesUploaded
            }
            isReadOnly
            photos={images}
            title={srcTitle}
            moduleName={moduleName}
            onClick={onAttachmentClick}
            isCustomerOwnRequiredDocs={isCustomerOwnRequiredDocs}
          />
        );
      case FilePickerTypes.Video:
        return <></>;
    }
  }

  return (
    <ECBox width={'100%'}>
      {label && (
        <ECTypography
          variant="caption"
          color={theme => {
            return isValid
              ? theme.palette.text.secondary
              : theme.palette.graphic.alert.error;
          }}
        >
          {label}
        </ECTypography>
      )}
      <ECPhotoField
        isReadOnly={false}
        isDownloadEnabled={isDownloadEnabled}
        isDeleteEnabled={isDeleteEnabled}
        isDeletePreviewEnabled={isDeletePreviewEnabled}
        photos={images}
        title={srcTitle}
        onClick={onAttachmentClick}
        onDelete={handleDelete}
        onDeletePreview={handleDeletePreview}
        confirmationDeleteMessage={confirmationDeleteMessage}
        showDeleteAlert={showDeleteAlert}
        deleteAlertTitle={deleteAlertTitle}
        deleteAlertText={deleteAlertText}
        moduleName={moduleName}
        fieldName={fieldName}
        onFileInput={handleFileInput}
        onFileDrop={handleFileDrop}
        isCustomerOwnRequiredDocs={isCustomerOwnRequiredDocs}
        disabledUpload={disabledUpload}
        hideDragDropAreaWhenHaveFilesUploaded={
          hideDragDropAreaWhenHaveFilesUploaded
        }
      />
    </ECBox>
  );
};
