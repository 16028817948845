import { Checkbox, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { setActiveFilter } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ECMenuItem, ECSelect } from 'app/components/ECSelect';
import { ECOnlyMeFilter } from 'app/components/ECTable/ECOnlyMeFilter';
import { ECTypography } from 'app/components/ECTypography';
import { ECChip } from 'app/components/ECChip';

interface ECStatusFilterProps {
  statusFilterOptions: any[];
  endpoint: string;
}

export const ECStatusFilter: React.FC<ECStatusFilterProps> = ({
  statusFilterOptions,
  endpoint,
}) => {
  const dispatch = useDispatch();
  const [statusFilterOpen, setStatusFilterOpen] = useState<boolean>(false);
  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[endpoint];
  }, [activeFilterFromSelector, endpoint]);

  const [activeStatuses, setActiveStatuses] = useState<string[]>(
    activeFilter?.statuses || [],
  );
  const statusFilterOptionsMemoized = useMemo(() => {
    return statusFilterOptions.map(option => {
      return (
        <ECMenuItem key={option?.fieldName} value={option?.fieldName}>
          <ListItemIcon>
            <Checkbox
              checked={activeStatuses?.some(s => s === option?.fieldName)}
            />
          </ListItemIcon>
          <ListItemText
            sx={{
              '& span': {
                maxWidth: '170px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            primary={option?.label}
          />
          <ECOnlyMeFilter
            activeFilter={{
              statuses: [option?.fieldName],
              filterPath: endpoint,
            }}
            closePopover={() => {
              setActiveStatuses([option?.fieldName]);
              setStatusFilterOpen(false);
            }}
          />
        </ECMenuItem>
      );
    });
  }, [statusFilterOptions, activeFilter?.statuses, activeStatuses]);

  const handleOpen = useCallback(() => {
    setStatusFilterOpen(true);
  }, []);

  useEffect(() => {
    setActiveStatuses(
      activeFilter?.statuses?.filter(
        (status, index, self) => index === self.findIndex(t => t === status),
      ) || [],
    );
  }, [activeFilter]);

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      width="200px"
      height="40px"
      border="1px solid #E0E0E0"
      borderRadius="4px"
      display="flex"
      justifyContent="space-around"
    >
      {!!statusFilterOptions?.length && (
        <>
          <ECTypography
            color={theme => theme.palette.text.secondary}
            variant="caption"
          >
            Status:
          </ECTypography>
          <ECSelect
            labelId="mutiple-select-label"
            multiple
            variant="standard"
            disableUnderline
            open={statusFilterOpen}
            onOpen={handleOpen}
            value={activeStatuses || []}
            MenuProps={{
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              anchorOrigin: {
                vertical: 40,
                horizontal: 90,
              },
            }}
            onClose={() => {
              if (
                activeStatuses.length === 0 ||
                statusFilterOptions.length === activeStatuses.length
              ) {
                dispatch(
                  setActiveFilter({
                    statuses: [],
                    filterPath: endpoint,
                  }),
                );
              } else {
                dispatch(
                  setActiveFilter({
                    statuses: activeStatuses,
                    filterPath: endpoint,
                  }),
                );
              }
              setStatusFilterOpen(false);
            }}
            onChange={e => {
              const value = e.target.value as any;
              if (value[value.length - 1] === 'all') {
                setActiveStatuses(
                  activeStatuses.filter(st => st !== 'all').length ===
                    statusFilterOptions.length
                    ? []
                    : statusFilterOptions.map(st => st.fieldName),
                );
                return;
              }
              setActiveStatuses(value);
            }}
            displayEmpty
            renderValue={(selected: any) => {
              if (
                !selected?.length ||
                selected?.length === statusFilterOptions?.length
              ) {
                return (
                  <ECChip
                    sx={{ width: '48px', height: '24px' }}
                    label="All"
                    color="Light Grey"
                  />
                );
              }

              return (
                <ECChip
                  sx={{ width: '48px', height: '24px' }}
                  label={selected?.length}
                  color="Dark Blue"
                />
              );
            }}
          >
            <ECMenuItem value="all">
              <ECTypography
                variant="body1"
                color={theme => theme.palette.primary.main}
              >
                {statusFilterOptions.length === activeStatuses.length
                  ? 'Deselect All'
                  : 'Select All'}
              </ECTypography>
            </ECMenuItem>
            {statusFilterOptionsMemoized}
          </ECSelect>
        </>
      )}
    </Stack>
  );
};
