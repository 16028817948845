import { Stack } from '@mui/material';
import { ECSwitch } from 'app/components/ECSwitch';
import { ECTypography } from 'app/components/ECTypography';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { setActiveFilter } from 'store/slice/page';
import { ToggleFilterProps } from 'types/core/Filters';

export const ECToggleFilter: React.FC<ToggleFilterProps> = ({
  alias,
  label,
  sideEffectFilter,
}) => {
  const dispatch = useDispatch();
  const { activeFilter: activeFilterFromSelector, endpoint } = useSelector(
    (state: RootState) => state.page.filter,
  );
  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[endpoint];
  }, [window.location.href, activeFilterFromSelector]);

  const [isFilterActive, setIsFilterActive] = useState<boolean>(
    activeFilter?.[alias],
  );

  useEffect(() => {
    dispatch(
      setActiveFilter({
        [alias]: isFilterActive,
        filterPath: endpoint,
        ...sideEffectFilter?.(isFilterActive),
      }),
    );
  }, [isFilterActive]);

  return (
    <Stack
      direction="row"
      spacing={1}
      pr={1.5}
      alignItems="center"
      justifyContent="right"
    >
      <ECSwitch
        id="id-show-awaiting-invoice-filter"
        name="name-awaiting-invoice-filter"
        checked={isFilterActive}
        value={isFilterActive}
        onChange={() => {
          setIsFilterActive(!isFilterActive);
        }}
      />
      <ECTypography
        sx={theme => ({
          color: theme.palette.text.primary,
        })}
      >
        {label}
      </ECTypography>
    </Stack>
  );
};
