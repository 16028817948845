import {
  useCreateUserMutation,
  useGetInternalTechsCompaniesQuery,
  useLazyGetUsersListQuery,
  useUpdateUserMutation,
} from 'services/userApi';
import {
  ECDynamicPageTemplate,
  ECEasyFormFieldType,
  ECIconButton,
} from 'app/components';
import { useLazyGetJobTitlesByCompanyIdQuery } from 'services/jobTitleApi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadPageScopes } from 'utils/pageScopes';
import { useDispatch } from 'react-redux';
import {
  setFilterEndpoint,
  setSnackbar,
  setActiveFilter,
} from 'store/slice/page';
import { useGetTimezonesQuery } from 'services/lookupApi';
import { useLazyGetEmailExistsQuery } from 'services/authApi';
import { isValidEmail } from 'utils/validate-fields';
import { normalizeZipcode } from 'utils/strings/zipcode';
import SendIcon from '@mui/icons-material/Send';
import { useForgotPasswordMutation } from 'services/authApi';
import { ECTooltip } from 'app/components/ECTooltip';
import { useGetProfileQuery } from 'services/profileApi';
import { useGetIdentityProviderFromCompanyIdQuery } from 'services/identityProvider';
import { useQuery } from 'app/hooks/usequery';

const fancyFormElementsCreate = require('./form_config_create.json');

export function AdminUsersPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();

  const [csvFileName, setCsvFileName] = useState('users.csv');
  const [formFields, setFormFields] = useState(fancyFormElementsCreate.fields);

  const [userAvailable, setUserAvailable] = useState(false);

  const [validPassword, setValidPassword] = useState(false);

  const [triggerEmail] = useLazyGetEmailExistsQuery({});
  const shouldOpenDrawer = query.get('add') === 'true';
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(0);

  const [triggerJobTitlesData, resultJobTitlesData] =
    useLazyGetJobTitlesByCompanyIdQuery();
  const { data: jobTitlesData, isSuccess: isSuccessJobTitles } =
    resultJobTitlesData;

  const {
    data: userInternalCompaies,
    isSuccess: isSuccessUserInternalCompaies,
  } = useGetInternalTechsCompaniesQuery({
    t: 10000,
    st: 1,
    ob: 'cmpny.name',
    o: 'a',
    own: '1',
  });

  const options = useMemo(() => {
    if (!userInternalCompaies) return [];

    const arrayUserInternalCompanies = Array.isArray(userInternalCompaies)
      ? userInternalCompaies
      : [userInternalCompaies];

    return arrayUserInternalCompanies.map((item, index) => {
      if (index === 0) {
        return {
          label: item.name,
          name: item.name,
          id: item.id,
        };
      } else {
        return {
          label: item.name,
          name: item.name,
          id: item.id,
          group: 'Internal Company',
        };
      }
    });
  }, [userInternalCompaies]);

  useEffect(() => {
  if (!shouldOpenDrawer || isDrawerOpen) return;
  setIsDrawerOpen(true);
  }, [shouldOpenDrawer, isDrawerOpen]);

  useEffect(() => {
    if (isDrawerOpen) {
      const companyField = formFields
        ?.find(item => item.groupName === 'companyJobtitleGroup')
        ?.subFields?.find(item => item.fieldName === 'company');
      if (options.length === 1) {
        // there is not any internal company
        companyField.visible = false;
        companyField.required = false;
      } else {
        companyField.visible = true;
        companyField.options = options;
        companyField.optionValues = options.map(op => op.id);
        companyField.value = userCompanyObject;
      }
      setSelectedCompanyId(options?.[0]?.id);
      triggerJobTitlesData(options?.[0]?.id);
    }
  }, [options, isDrawerOpen]);

  useEffect(() => {
    if (jobTitlesData && isSuccessJobTitles) {
      const updatedFormFields = [...formFields];
      const companyField = updatedFormFields
        ?.find(item => item.groupName === 'companyJobtitleGroup')
        ?.subFields?.find(item => item.fieldName === 'company');
      const jobTitleField = updatedFormFields
        ?.find(item => item.groupName === 'companyJobtitleGroup')
        ?.subFields?.find(item => item.fieldName === 'jobTitle');
      jobTitleField.options =
        jobTitlesData?.data?.map(({ name, id }) => ({
          label: name,
          id,
        })) || [];
      companyField.value = options?.find(item => item.id === selectedCompanyId);
      jobTitleField.value = null;
      setFormFields(updatedFormFields);
    }
  }, [jobTitlesData, isSuccessJobTitles]);

  const handleOutputChange = (
    output: ECEasyFormFieldType[],
    fieldName?: string,
  ) => {
    if (fieldName !== 'company') {
      return;
    }
    const companyField = output
      ?.find(item => item.groupName === 'companyJobtitleGroup')
      ?.subFields?.find(item => item.fieldName === 'company');
    const jobTitleField = output
      ?.find(item => item.groupName === 'companyJobtitleGroup')
      ?.subFields?.find(item => item.fieldName === 'jobTitle');

    const companyId = companyField?.value?.id;
    if (jobTitleField) {
      jobTitleField.disabled = !companyId;
      if (companyId) {
        setSelectedCompanyId(companyId);
        triggerJobTitlesData(companyId);
      } else {
        setSelectedCompanyId(0);
        jobTitleField.options = [];
        jobTitleField.value = null;
      }
    }
    setFormFields(output);
  };

  const initialFormFields = fancyFormElementsCreate.fields;

  const resetFields = useCallback(() => {
    setIsDrawerOpen(false);
    setFormFields(initialFormFields);
  }, []);

  const { data: companyDetails, isSuccess: isSuccessCompanyDetails } =
    useGetProfileQuery();

  const { data: identityProvider, isSuccess: isSuccessIdentityProvider } =
    useGetIdentityProviderFromCompanyIdQuery(companyDetails?.id);

  const userCompanyObject = useMemo(() => {
    if (isSuccessCompanyDetails && companyDetails) {
      return {
        label: companyDetails?.name,
        fieldName: companyDetails?.name,
        name: companyDetails?.name,
        id: companyDetails?.id,
      };
    }
    return null;
  }, [companyDetails, isSuccessCompanyDetails]);

  useEffect(() => {
    if (isSuccessCompanyDetails && companyDetails) {
      dispatch(
        setActiveFilter({
          manufacturer: [userCompanyObject],
          isSelectAllManufacturers: false,
        }),
      );
    }
  }, [companyDetails, isSuccessCompanyDetails]);

  useEffect(() => {
    if (!isSuccessIdentityProvider) return;
    if (identityProvider?.status === 1) {
      if (
        formFields?.fields?.length > 0 &&
        Array.isArray(formFields.fields[0].subFields) &&
        formFields.fields[0].subFields.filter(
          field => field.fieldName === 'ssoUser',
        )?.length === 0
      ) {
        formFields.fields[0].subFields.push({
          type: 'switch',
          label: 'Enable SSO',
          value: true,
          fieldName: 'ssoUser',
        });
      }
    }
  }, [isSuccessIdentityProvider]);

  const [signinUser, { isError, error, isLoading, isSuccess: isSucessResend }] =
    useForgotPasswordMutation();

  const onValidatePassword = (isValidPassword: boolean) => {
    setValidPassword(isValidPassword);
  };

  const renderResendInviteActionButton = (row: any) => {
    if (row?.lastLogin) {
      return null;
    } else {
      return (
        <ECIconButton
          squared
          withBorder
          withWhiteBackground
          sx={{ color: theme => theme.palette.primary.main }}
          key={`resend-invite-admin-page-${row.id}`}
          onClick={() => {
            signinUser({
              email: `${row?.email}`?.toLowerCase(),
            });
          }}
        >
          <>
            <ECTooltip title={'Resend new user invitation'}>
              <SendIcon />
            </ECTooltip>
          </>
        </ECIconButton>
      );
    }
  };

  useEffect(() => {
    if (isSucessResend) {
      dispatch(
        setSnackbar({
          message: 'The new user registration was successfully sent',
          severity: 'success',
        }),
      );
    }
  }, [isSucessResend]);

  const handleBlurEmail = async (value: string, field: any) => {
    setUserAvailable(false);
    // being used to trigger re-render of component
    setCsvFileName('');
    if (value?.length > 0 && !isValidEmail(value)) {
      field.isValid = false;
      field.validationMessage = t(
        'translation:form.validation.correctFormatEmail',
        {
          name: field.placeholder,
        },
      );
    } else {
      field.isValid = true;
      field.validationMessage = null;
      setUserAvailable(true);
      const responseUserExists = await triggerEmail({
        email: value,
        ignoreUserPool: true,
      });
      if (responseUserExists?.data?.existed) {
        field.isValid = false;
        field.validationMessage = t('translation:pages.users.alreadyExists');
        setUserAvailable(false);
      }
    }
    // being used to trigger re-render of component
    setCsvFileName('users.csv');
  };

  useEffect(() => {
    loadPageScopes('user');
    dispatch(setFilterEndpoint('/filter/management'));
    const emailField = formFields?.find(field => field.fieldName === 'email');
    emailField.onBlur = handleBlurEmail;

    const passwordField = formFields?.find(
      field => field.fieldName === 'password',
    );
    passwordField.onValidatePassword = onValidatePassword;
  }, []);

  const [
    doAddUser,
    {
      data: createUserData,
      isError: isAddError,
      error: addError,
      isLoading: isAddLoading,
      isSuccess: isAddSuccess,
      reset: resetAdd,
    },
  ] = useCreateUserMutation();

  const { data: timezonesData, isSuccess: isSuccessTimezones } =
    useGetTimezonesQuery();

  useEffect(() => {
    if (timezonesData && isSuccessTimezones) {
      const timezones = (timezonesData as any)?.data ?? timezonesData;
      const timezoneField = formFields?.find(
        field => field.fieldName === 'timezone',
      );
      timezoneField.options = timezones.map(({ timezone }) => ({
        label: timezone,
        id: timezone,
      }));
    }
  }, [timezonesData, isSuccessTimezones]);

  const useCreateUser = useCallback(() => {
    const doCreate = async data => {
      if (!userAvailable) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: t('translation:pages.users.alreadyExists'),
          }),
        );
        return;
      }
      if (!validPassword) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: t('translation:pages.users.provideValidPassword'),
          }),
        );
        return;
      }
      data.timezone = data.timezone?.id;
      data.nte = data.nte ? Number(data.nte).toFixed(2) : null;
      data.userName = data.email;
      data.zipCodePostalCode = normalizeZipcode(data.zipCodePostalCode);
      doAddUser(data);
    };

    return [
      doCreate,
      {
        data: createUserData,
        isError: isAddError,
        error: addError,
        isLoading: isAddLoading,
        isSuccess: isAddSuccess,
        reset: resetAdd,
      },
    ];
  }, [
    createUserData,
    addError,
    isAddError,
    isAddLoading,
    isAddSuccess,
    userAvailable,
    validPassword,
    resetAdd,
  ]);

  const editedFormFields = useMemo(() => {
    formFields.find(it => it.fieldName === 'identityProviderIdHide').value =
      identityProvider?.status ? false : true;
    return formFields;
  }, [identityProvider, formFields]);

  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      pageTitle={t('translation:panelDrawer.user')}
      useLazyGetListQuery={useLazyGetUsersListQuery}
      showStatusActiveFilter={true}
      statusFilterInitialSelected
      useCreateMutation={useCreateUser}
      useUpdateMutation={useUpdateUserMutation}
      createFormConfig={fancyFormElementsCreate.config}
      createFormFields={editedFormFields}
      downloadFileName={csvFileName}
      enableExport
      exportModuleName="users"
      editFormAdditionalProcess={({ row, formConfig, formFields }) => {
        if (formConfig) {
          formConfig.title = `${row?.firstName} ${row?.lastName}`;
        }
      }}
      hasDetails
      marginTop={false}
      renderCustomActionComponent={renderResendInviteActionButton}
      withDrawer={false}
      moduleName="users"
      showManufacturerFilter
      useManufacturerFilterQuery={useGetInternalTechsCompaniesQuery}
      userCompany={userCompanyObject}
      onChange={handleOutputChange}
      onDrawerClose={resetFields}
      onAddNewClick={() => setIsDrawerOpen(true)}
      shouldNotUseActiveFilter
      shouldDrawerOpen={shouldOpenDrawer}
    />
  );
}
