import { ECBox } from 'app/components/ECBox';
import {
  ECTable,
  ECTableBody,
  ECTableHead,
  ECTableRow,
} from 'app/components/ECTable';
import { SortableColumn } from './ECSortableColumn';
import { NestedTableProps, row } from 'types/core/ECTable';
import { DynamicTableCell } from './ECDynamicTableCell';

export const NestedTable: React.FC<NestedTableProps> = ({
  row,
  cols,
  nestedColsDataPath,
  tableLayoutFilter,
  headerBackgroundColor,
  sort,
  setSearchParams,
  setSort,
  isDraggableColumns,
  setOppnenedRowId,
  onRowPress,
  shouldUseSearchParams,
  onDuplicatePress,
  onRemove,
  t,
  customFieldsData,
}) => {
  return (
    <ECBox key={`${row?.id}-${row?.fieldName}-${row?.label}`}>
      <ECTable size="small">
        <ECTableHead sx={{ backgroundColor: '#E5E5E5' }}>
          <ECTableRow>
            {cols?.map((col, index) => (
              <SortableColumn
                key={index}
                index={index}
                tableLayoutFilter={tableLayoutFilter}
                headerBackgroundColor={headerBackgroundColor}
                sort={sort}
                setSearchParams={setSearchParams}
                setSort={setSort}
                col={col}
                row={row}
                isDraggableColumns={isDraggableColumns}
                setOppnenedRowId={setOppnenedRowId}
              />
            ))}
          </ECTableRow>
        </ECTableHead>
        <ECTableBody>
          {row?.[nestedColsDataPath]?.map(
            (nestedRow: row, nestedRowIndex: number) => (
              <ECTableRow
                onClick={() => onRowPress?.({ ...row, ...nestedRow })}
                key={`nested-row-${nestedRowIndex}`}
                sx={() => ({
                  cursor: 'pointer',
                  borderBottom:
                    nestedRowIndex === row[nestedColsDataPath].length - 1
                      ? 11
                      : 1,
                  borderColor: '#E5E5E5',
                })}
              >
                {cols?.map((col, colIndex) => (
                  <DynamicTableCell
                    col={col}
                    index={colIndex}
                    row={nestedRow}
                    parentRow={row}
                    isNestedCell
                    onRowPress={onRowPress}
                    onDuplicatePress={onDuplicatePress}
                    onRemove={onRemove}
                    t={t}
                    customFieldsData={customFieldsData}
                  />
                ))}
              </ECTableRow>
            ),
          )}
        </ECTableBody>
      </ECTable>
    </ECBox>
  );
};
