import { useChallengeMutation, useSigninUserMutation } from 'services/authApi';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  ECAlertError,
  ECEasyForm,
  FieldTypes,
  ECMfaTemplate,
  ECBox,
  ECLink,
  ECTypography,
  ECAlert,
  ECAlertTitle,
} from 'app/components';
import { Helmet } from 'react-helmet-async';
import { Help } from '@mui/icons-material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/material';
import { DateTime } from 'luxon';

const CodeSendSuccess = props => (
  <ECAlert severity="success">
    <ECAlertTitle>Code Sent</ECAlertTitle>
    <ECTypography>{props.children}</ECTypography>
  </ECAlert>
);

export function MfaPage() {
  const location = useLocation();
  const [err, setErr] = useState<string | null>(null);
  const [isResendCode, setIsResendCode] = useState<boolean>(false);

  const { sentEmailLogin, password } = location.state || {
    sentEmailLogin: null,
  };
  const [mfaInfo, setMfaInfo] = useState<{
    challengeName: string | null;
    email: string | null;
    session: string | null;
    username: string | null;
  } | null>(null);

  const theme = useTheme();

  const navigate = useNavigate();

  const [challenge, { data, isError, isLoading, isSuccess, error }] =
    useChallengeMutation({});
  const [
    signinUser,
    {
      data: dataSign,
      isSuccess: isSucessResendCode,
      isError: isErrorResendCode,
      error: errorResendCode,
    },
  ] = useSigninUserMutation({});

  const idToken = useRef(data?.idToken?.jwtToken);
  const refreshToken = useRef(data?.refreshToken?.token);
  const userName = useRef(data?.accessToken?.payload?.username);
  const email = useRef(data?.idToken?.payload?.email);
  const authTimeout = useRef(data?.company?.authTimeout);

  useEffect(() => {
    if (isSucessResendCode && dataSign) {
      idToken.current = dataSign?.idToken?.jwtToken;
      refreshToken.current = dataSign?.refreshToken?.token;
      userName.current = dataSign?.accessToken?.payload?.username;
      email.current = dataSign?.idToken?.payload?.email;
      authTimeout.current = dataSign?.company?.authTimeout;

      setMfaInfo({
        challengeName: dataSign?.mfaInfo?.challengeName,
        email: dataSign?.mfaInfo?.email,
        session: dataSign?.mfaInfo?.session,
        username: dataSign?.mfaInfo?.username,
      });
    }
    setIsResendCode(isSucessResendCode);
  }, [isSucessResendCode, dataSign]);

  useEffect(() => {
    if (isSuccess && data) {
      localStorage.setItem('idToken', data?.idToken?.jwtToken);
      localStorage.setItem('refreshToken', data?.refreshToken?.token);
      localStorage.setItem('sessionInfo', data?.accessToken?.payload?.username);
      localStorage.setItem(
        'sessionInfoUserName',
        data?.accessToken?.payload?.username,
      );
      if (data?.company?.authTimeout > 0) {
        localStorage.setItem(
          'customSessionTimeoutTimestamp',
          DateTime.now()
            .plus({ hours: data?.company?.authTimeout })
            .toMillis()
            .toString(),
        );
      } else {
        localStorage.removeItem('customSessionTimeoutTimestamp');
      }
      navigate('/home', { state: location.state });
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if ((isError || isErrorResendCode) && !err) {
      setErr(
        (error as any)?.data?.message ||
          (error as any)?.error ||
          (errorResendCode as any)?.data?.message ||
          (errorResendCode as any)?.error ||
          'Unknown Error',
      );
      setIsResendCode(false);
    }
  }, [isError, isErrorResendCode]);

  useEffect(() => {
    const challengeName = localStorage.getItem('challengeName');
    const email = localStorage.getItem('email');
    const session = localStorage.getItem('session');
    const username = localStorage.getItem('username');
    setMfaInfo({
      challengeName,
      email,
      session,
      username,
    });
  }, []);

  const fields = useMemo(() => {
    return [
      {
        type: FieldTypes.Text,
        label: '6-Digit Authentication Code',
        placeholder: '',
        value: '',
        fieldName: 'code',
        mask: '000000',
      },
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Mfa Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECMfaTemplate>
        <ECEasyForm
          pattern="standard"
          drawerTitleBarBGColor="transparent"
          config={{
            actionButtonsContainerStyle: { display: 'none' },
            variant: 'standard',
            title: 'Two-factor Authentication',
            subtitle:
              'Enter the authentication code from your email ' + mfaInfo?.email,
            cols: 1,
            titleStyle: {
              color: theme.palette.common.white,
            },
            subtitleStyle: {
              color: theme.palette.common.white,
            },
          }}
          fullHeight={false}
          fields={fields}
          isSendingData={isLoading}
          isLoadingForm={false}
          onChange={fields => {
            const { value } = fields[0];
            if (value.length === 6) {
              challenge({ code: value, ...mfaInfo });
              setErr(null);
              setIsResendCode(false);
            }
          }}
          onFormSubmit={(formData, fields) => {
            const code = formData.get('code') as string;
            if (code) {
              challenge({ code, ...mfaInfo });
              setErr(null);
              setIsResendCode(false);
            }
          }}
        />

        {err && (
          <ECBox
            style={{
              paddingInline: '24px',
              marginBottom: '24px',
            }}
          >
            <ECAlertError> {err} </ECAlertError>
          </ECBox>
        )}
        {isResendCode && (
          <ECBox
            style={{
              paddingInline: '24px',
              marginBottom: '24px',
            }}
          >
            <CodeSendSuccess>
              A verification code has been sent to your email.
            </CodeSendSuccess>
          </ECBox>
        )}

        <ECBox
          px={2}
          display={'flex'}
          flexDirection={'row'}
          gap={'8px'}
          justifyContent={'center'}
          sx={theme => ({
            color: `${theme.palette.common.white}`,
          })}
        >
          <Help />{' '}
          <ECTypography color={theme.palette.common.white}>
            Didn't get a Code?
          </ECTypography>
          <ECLink
            style={{
              color: theme.palette.common.white,
              textDecoration: 'underline',
            }}
            onClick={() => {
              signinUser({
                username: sentEmailLogin?.toLowerCase(),
                password,
              });
              setErr(null);
              setIsResendCode(true);
            }}
          >
            Resend Code
          </ECLink>
        </ECBox>
      </ECMfaTemplate>
    </>
  );
}
