import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { hasPermission } from 'utils/permissions';
import { P } from 'types/Permission';
import { checkPermissionScore } from 'app/hoc/require-permission';

export const useHasPermission = (permissions: P[]) => {
  const user = useSelector((state: RootState) => state?.user);

  if (!user?.permissions) {
    return [false];
  }

  return permissions.map(permission =>
    hasPermission({
      userPermissions: user.permissions.map(p => p.action),
      scopes: [permission],
    }),
  );
};

export const useHasPermissionScore = (scores: P[]) => {
  const user = useSelector((state: RootState) => state?.user);
  if (!scores) {
    return true;
  }

  const scoreCompany = user?.subscriptionScoreCompany;

  const subscriptionScores = scores.map(action =>
    action == P.Unset
      ? 0
      : user?.permissions?.find(permission => permission.action === action)
          ?.subscriptionScore,
  );

  return subscriptionScores.map(
    subscriptionScore =>
      subscriptionScore == undefined ||
      scoreCompany == undefined ||
      scoreCompany >= subscriptionScore,
  );
};
