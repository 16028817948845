import React from 'react';
import { FormHelperText, TextField } from '@mui/material';
import { ECAutocompleteStyled } from '../ECAutocomplete';
import { ECChip } from '../ECChip';
import { ECMenuItem } from '../ECSelect';
import { ECStack } from '../ECStack';
import { ECTypography } from '../ECTypography';
import { Chip } from 'types/Chip';
import { ECTooltip } from '../ECTooltip';
import { ECBox } from '../ECBox';
import { Info } from '@mui/icons-material';

interface ECChipAutocompleteProps {
  options: Chip[];
  title?: string;
  placeholder?: string;
  values?: Chip[];
  defaultValues?: any[];
  variant?: 'standard' | 'filled' | 'outlined';
  errorMessage?: string;
  readOnly?: boolean;
  getOptionLabel?: (option: any) => string;
  onChange?: (value: Chip[], event?: any, action?: any) => void;
  onChangeSearch?: (value: string) => void;
  loading?: boolean;
  searchString?: string | undefined;
  freeSolo?: boolean;
  limitTags?: number;
  open?: boolean;
  sx?: any;
  paperComponent?: any;
  groupBy?: any;
  groupByTransform?: any;
  optionNameAttribute?: string;
  toolTip?: any;
}

export const ECChipAutocomplete: React.FC<ECChipAutocompleteProps> = React.memo(
  ({
    options,
    title,
    placeholder,
    values,
    defaultValues,
    variant = 'filled',
    errorMessage,
    readOnly,
    loading,
    getOptionLabel: getOptionLabelInit,
    onChange,
    onChangeSearch,
    freeSolo,
    searchString,
    limitTags,
    paperComponent,
    groupBy,
    groupByTransform,
    optionNameAttribute = 'name',
    toolTip,
    ...autocompleteProps
  }) => {
    const getOptionLabel = getOptionLabelInit
      ? getOptionLabelInit
      : option => option.label;

    const toolTipMaxWidth = toolTip?.maxWidth;

    const toolTipStyle = {
      fontSize: '14px',
      ...(toolTipMaxWidth && { maxWidth: toolTipMaxWidth }),
      width: 'fit-content',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      display: 'block',
      textIndent: '1em',
    };

    return (
      <>
        <ECAutocompleteStyled
          {...autocompleteProps}
          multiple
          disableCloseOnSelect
          id="tags-standard"
          options={
            groupBy && groupByTransform ? groupByTransform(options) : options
          }
          loading={loading}
          PaperComponent={paperComponent}
          value={values}
          groupBy={
            groupBy && groupByTransform ? option => groupBy(option) : undefined
          }
          readOnly={readOnly}
          isOptionEqualToValue={(option, value) =>
            option.id
              ? option.id === value?.id
              : getOptionLabel(option) === getOptionLabel(value)
          }
          filterSelectedOptions
          renderOption={(props, option, { inputValue, selected }) => {
            if (selected) {
              return <></>;
            }
            if (inputValue !== '') {
              const searchIndex = (
                option.label || option?.[optionNameAttribute]
              )
                ?.toLowerCase()
                .indexOf(inputValue.toLowerCase());

              return (
                <ECMenuItem {...props} key={option.fieldName}>
                  {searchIndex !== -1 && inputValue ? (
                    <>
                      {(
                        option.label || option?.[optionNameAttribute]
                      )?.substring(0, searchIndex)}
                      <strong>
                        {(
                          option.label || option?.[optionNameAttribute]
                        )?.substring(
                          searchIndex,
                          searchIndex + inputValue.length,
                        )}
                      </strong>
                      {(
                        option.label || option?.[optionNameAttribute]
                      )?.substring(searchIndex + inputValue.length)}
                    </>
                  ) : (
                    option.label || option?.[optionNameAttribute]
                  )}
                </ECMenuItem>
              );
            }
            return (
              <ECMenuItem
                {...props}
                key={option?.id}
                id={option?.id}
                disabled={option?.disabled}
              >
                {
                  <ECStack dir="column">
                    <ECTypography variant="body1">
                      {getOptionLabel?.(option) ??
                        (typeof option === 'string' ? option : '')}
                    </ECTypography>
                    {option?.subLabel && (
                      <ECTypography
                        variant="body2"
                        color={option?.subLabelColor}
                      >
                        {option?.subLabel}
                      </ECTypography>
                    )}
                  </ECStack>
                }
              </ECMenuItem>
            );
          }}
          getOptionLabel={getOptionLabel}
          renderInput={params => (
            <TextField
              {...params}
              variant={variant}
              label={title}
              placeholder={
                defaultValues && !!defaultValues.length
                  ? 'Add more'
                  : placeholder
              }
              onChange={e => {
                onChangeSearch?.(e.target.value);
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    {toolTip && (
                      <ECTooltip
                        placement={toolTip.placement || 'top'}
                        title={
                          <ECBox sx={toolTipStyle}>{toolTip.content}</ECBox>
                        }
                        arrow
                      >
                        {React.isValidElement(toolTip.icon) ? (
                          toolTip.icon
                        ) : (
                          <Info
                            sx={theme => ({
                              color: theme.palette.action.active,
                              cursor: 'pointer',
                              marginLeft: '0.5rem',
                            })}
                          />
                        )}
                      </ECTooltip>
                    )}
                  </>
                ),
              }}
              sx={theme => ({
                '.MuiInputLabel-root': {
                  color: errorMessage
                    ? theme.palette.graphic.alert.error
                    : theme.palette.text.secondary,
                },
                '.MuiInputLabel-root:after': {
                  color: errorMessage
                    ? theme.palette.graphic.alert.error
                    : theme.palette.text.secondary,
                },
                '.MuiAutocomplete-input': {
                  minWidth: readOnly
                    ? '0px !important'
                    : 'fit-content !important',
                },
                '.MuiAutocomplete-endAdornment': {
                  visibility: readOnly ? 'hidden' : 'visible',
                },
              })}
            />
          )}
          limitTags={limitTags}
          renderTags={(value, getTagProps) => {
            return value?.map((option: Chip, index: number) => (
              <ECChip
                key={`${option.label}-${index}`}
                label={option.label}
                color={option?.color}
                variant="outlined"
                {...getTagProps({ index })}
                onDelete={
                  readOnly ? undefined : getTagProps({ index }).onDelete
                }
              />
            ));
          }}
          freeSolo={freeSolo}
          onChange={(event, newValues, action, option) => {
            onChange?.(newValues, event, action);
          }}
        />
        <FormHelperText
          sx={theme => ({ color: theme.palette.graphic.alert.error })}
        >
          {errorMessage}
        </FormHelperText>
      </>
    );
  },
);
