import { ECImage } from '../ECImage';
import { ECBox } from '../ECBox';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useMemo, useRef, useState } from 'react';
import { ECButton } from '../ECButton';
import { ECStack } from '../ECStack';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { ECTypography } from '../ECTypography';
import { useTranslation } from 'react-i18next';
import { ZoomIn, ZoomOut } from '@mui/icons-material';
import { useTextExtraction } from '../../hooks/useTextExtraction';
import { ECExtractedTextDisplay } from '../ECExtractedTextDisplay';

export interface ECAvtImageProps {
  src: string;
  alt?: string;
  showLogoOnError?: boolean;
  handleOnChange?: (value: any) => void;
  id?: number;
  customWidth?: string;
  customHeight?: string;
  imageTools?: boolean;
  helperText?: string;
  docType?: string | null;
  enableTextExtract?: boolean;
}

export const ECAvtImage = ({
  src,
  alt,
  showLogoOnError = true,
  handleOnChange,
  id,
  customWidth = '19.5rem',
  customHeight = '17.5rem',
  imageTools = false,
  helperText,
  docType,
  enableTextExtract = false,
}) => {
  const { t } = useTranslation();
  const [rotation, setRotation] = useState(0);

  const { extractedWords, isProcessing, extractTextFromImage } =
    useTextExtraction();

  const zoomInRef = useRef<any>();
  const zoomOutRef = useRef<any>();

  const handleRotate = () => {
    setRotation(prevRotation => prevRotation - 90);
  };

  const handleExtractText = () => {
    extractTextFromImage(src);
  };

  const renderImageContent = useMemo(() => {
    return (
      <ECImage
        src={src}
        alt={alt}
        sx={{
          maxWidth: '100%',
          transform: `rotate(${rotation}deg)`,
          objectFit: 'contain',
          width: customWidth,
          height: customHeight,
        }}
        showLogoOnError={showLogoOnError}
      />
    );
  }, [src, alt, customWidth, customHeight, showLogoOnError, rotation]);

  return (
    <>
      <ECStack>
        <ECBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: customWidth,
            height: customHeight,
            border: '1px solid rgba(0, 0, 0, 0.26)',
            borderRadius: '4px',
            margin: '0 1.5rem 1.5rem 0',
            overflow: 'hidden',
            cursor: imageTools ? 'grab' : 'pointer',
          }}
          key={id}
          onClick={() => {
            handleOnChange({
              id: id,
              url: src,
              action: 'image_change',
            });
          }}
        >
          {imageTools ? (
            <TransformWrapper
              limitToBounds={true}
              wheel={{
                step: 0.04,
                smoothStep: 0.04,
              }}
            >
              {({ zoomIn, zoomOut, resetTransform, setTransform, ...rest }) => {
                zoomInRef.current = zoomIn;
                zoomOutRef.current = zoomOut;

                return (
                  <TransformComponent>{renderImageContent}</TransformComponent>
                );
              }}
            </TransformWrapper>
          ) : (
            renderImageContent
          )}
        </ECBox>
        {!imageTools && (
          <ECStack>
            <ECBox display={'flex'} width={customWidth}>
              <ECTypography variant="subtitle2" mr={1}>
                {t('translation:pages.avt.imageDocType')}:
              </ECTypography>

              <ECTypography variant="subtitle2" fontWeight={400}>
                {docType}
              </ECTypography>
            </ECBox>
            <ECBox display={'flex'} width={customWidth}>
              <ECButton
                size="small"
                onClick={() =>
                  handleOnChange({ id, url: null, action: 'doctype_change' })
                }
              >
                {t('translation:pages.avt.updateDocument')}
              </ECButton>
            </ECBox>
          </ECStack>
        )}
        <ECBox
          display={'flex'}
          justifyContent={'space-between'}
          width={customWidth}
        >
          {helperText && (
            <ECTypography
              sx={{
                maxWidth: `calc(${customWidth} - 4rem)`,
                marginBottom: '1rem',
              }}
              variant="caption"
            >
              {helperText}
            </ECTypography>
          )}
          {imageTools && (
            <ECBox display="flex" gap={2}>
              <ECButton
                variant="outlined"
                size="small"
                sx={{
                  width: '3rem',
                  height: '3rem',
                  padding: '.5rem',
                  minWidth: 'unset',
                }}
                onClick={() => zoomInRef?.current?.()}
              >
                <ZoomIn
                  sx={{
                    fontSize: '1.5rem',
                  }}
                />
              </ECButton>

              <ECButton
                variant="outlined"
                size="small"
                sx={{
                  width: '3rem',
                  height: '3rem',
                  padding: '.5rem',
                  minWidth: 'unset',
                }}
                onClick={() => zoomOutRef?.current?.()}
              >
                <ZoomOut
                  sx={{
                    fontSize: '1.5rem',
                  }}
                />
              </ECButton>

              <ECButton
                variant="outlined"
                size="small"
                sx={{
                  width: '3rem',
                  height: '3rem',
                  padding: '.5rem',
                  minWidth: 'unset',
                }}
                onClick={handleRotate}
              >
                <RotateLeftIcon
                  sx={{
                    transform: `rotate(${rotation}deg)`,
                    fontSize: '1.5rem',
                  }}
                />
              </ECButton>
            </ECBox>
          )}
        </ECBox>
        {enableTextExtract && (
          <>
            <ECBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
              width={customWidth}
              mt={1}
            >
              <ECButton
                variant="outlined"
                size="small"
                disabled={isProcessing}
                onClick={handleExtractText}
                sx={{
                  width: '100%',
                  height: '3rem',
                  marginBottom: '.5rem',
                }}
              >
                {isProcessing ? 'Processing...' : 'Extract Text'}
              </ECButton>

              {extractedWords.length > 0 && (
                <ECExtractedTextDisplay
                  words={extractedWords}
                  isProcessing={isProcessing}
                />
              )}
            </ECBox>
          </>
        )}
      </ECStack>
    </>
  );
};
