import { Helmet } from 'react-helmet-async';
import { useTheme, useMediaQuery } from '@mui/material';
import {
  ECBox,
  ECTypography,
  ECImage,
  ECButton,
  ECAlert,
  ECLink,
  ECNotLoggedInBar,
  ECMainBox,
} from 'app/components';
import ecotrakLogo from './../../../assets/ecotrak-logo.svg';
import emaTyping from './assets/ema-typing.gif';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { ECMobileDownloadButtons } from 'app/components/ECMobileDownloadButtons';

export function SignUpWizardCompletion() {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleOnClickButton = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <>
      <Helmet>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        numberOfPieces={130}
        tweenDuration={200}
        gravity={0.02}
      />
      <ECMainBox
        component="main"
        display="flex"
        width="100vw"
        height="100vh"
        alignItems="center"
        flexDirection="column"
        justifyContent={'center'}
        p={2}
        minWidth={'410px !important'}
      >
        <ECNotLoggedInBar />
        {!isMobileSize && (
          <ECBox
            display="flex"
            flex={2}
            flexDirection="column"
            justifyContent="center"
            alignSelf={'flex-start'}
            sx={{
              gap: {
                mobile: 1,
                tablet: 2,
                laptop: 4,
              },
              paddingX: {
                mobile: 2,
                tablet: 4,
                laptop: 10,
              },
            }}
          >
            <img height={50} src={ecotrakLogo} alt="Ecotrac LLC" />
          </ECBox>
        )}
        <ECBox
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          flexDirection={isMobileSize ? 'column' : 'row'}
        >
          <ECBox maxWidth={'758px'}>
            <ECTypography
              variant="h4"
              fontWeight={700}
              marginBottom={2}
              fontSize={isMobileSize ? '40px' : '50px'}
              color={theme => theme.palette.info.dark}
            >
              Nice Work!
            </ECTypography>
            <ECTypography
              variant="h4"
              fontWeight="bold"
              marginBottom={2}
              fontSize={isMobileSize ? '32px' : '40px'}
            >
              Building your Profile...
            </ECTypography>
            <ECTypography
              fontSize={isMobileSize ? '16px' : '20px'}
              fontWeight="bold"
            >
              We’re building your Ecotrak account, with everything you need to
              hit the ground running. Just a few seconds more, and you’ll be
              ready to dive into tools that make facility management simpler.
            </ECTypography>
          </ECBox>
          <ECBox
            sx={{
              position: 'relative',
            }}
          >
            <ECImage
              src={emaTyping}
              sx={{
                maxHeight: isMobileSize ? '291px' : '444px',
              }}
            />
          </ECBox>
        </ECBox>
        {!isMobileSize && (
          <ECBox
            display={'flex'}
            width={'100%'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <ECButton
              variant="contained"
              sx={{ width: '400px' }}
              onClick={handleOnClickButton}
            >
              Log In
            </ECButton>
          </ECBox>
        )}

        {isMobileSize && <ECMobileDownloadButtons showAlert />}

        <ECBox
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          marginTop={isMobileSize ? '15px' : '65px'}
        >
          <ECAlert
            variant="outlined"
            severity="info"
            sx={{ width: '100%', maxWidth: '1202px' }}
          >
            <ECTypography variant="body2">
              Need help with Ecotrak?{' '}
              <ECLink href="https://helpcenter.ecotrak.com/en" target="_blank">
                Click Here
              </ECLink>
            </ECTypography>
          </ECAlert>
        </ECBox>
      </ECMainBox>
    </>
  );
}
