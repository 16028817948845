import {
  ECBox,
  ECButton,
  ECDynamicPageTemplate,
  ECEasyForm,
  ECEasyFormFieldType,
  ECTypography,
} from 'app/components';
import { useTranslation } from 'react-i18next';
import { useLazyGetAllPurchaseOrderListQuery } from 'services/purchaseOrderApi';
import { CustomPurchaseOrderHeader } from './CustomPurchaseOrderHeader';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { compact, orderBy } from 'lodash';
import { useGetAllWorkflowStatusQuery } from 'services/workflowApi';
import { ECModal } from 'app/components/ECModal';
import { useGetInventoryStorageLocationsListForDropdownQuery } from 'services/inventoryApi';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';
import { useGetProfileQuery } from 'services/profileApi';
import { ECSupplierIntegrationButton } from 'app/components/ECSupplierIntegrationButton';
import { SupplierIntegration } from 'types/Company';
import { useLazyGetSupplierIntegrationLinkQuery } from 'services/supplierApi';

const formElementsShipTo = require('./form_config_ship_to.json');

export const PurchaseOrders = ({ marginTop = true }) => {
  const { t } = useTranslation();

  const [formElementsShipToFields, setFormElementsShipToFields] = useState<
    ECEasyFormFieldType[]
  >(formElementsShipTo.fields);

  const [hasSupplierIntegrationPartsOrderingPurchase] = useHasPermission([
    P.AddSupplierIntegrationPartsOrderPurchase,
  ]);

  const { data: companyProfile } = useGetProfileQuery();
  const supplierIntegrationList = companyProfile?.si;

  const [showShipToModal, setShowShipToModal] = useState(false);
  const [selectedSupplierIntegration, setSelectedSupplierIntegration] =
    useState<SupplierIntegration | null>(null);

  const [shouldTriggerLazyQuery, setShouldTriggerLazyQuery] = useState(false);

  const [lastRequestId, setLastRequestId] = useState<string | undefined>();

  const [
    triggerSupplierIntegrationLink,
    {
      data: supplierIntegrationLink,
      isFetching: isFetchingSupplierIntegrationLink,
      isSuccess: isSuccessSupplierIntegrationLink,
      isError: isErrorSupplierIntegrationLink,
      isLoading: isLoadingSupplierIntegrationLink,
      requestId: currentRequestId,
      error: supplierIntegrationLinkError,
    },
  ] = useLazyGetSupplierIntegrationLinkQuery();

  const {
    data: workflowStatuses,
    isFetching: isFetchingWorkflowStatuses,
    isSuccess: isSuccessWorkflowStatuses,
  } = useGetAllWorkflowStatusQuery('purchase-order');

  useEffect(() => {
    const updatedFormFieldsShipTo = [...formElementsShipToFields];
    const shipToField = updatedFormFieldsShipTo?.find(
      field => field.fieldName === 'shipTo',
    );
    if (shipToField) {
      shipToField.useQuery =
        useGetInventoryStorageLocationsListForDropdownQuery;
      shipToField.queryParams = {
        sb: 'invntrystrg.name',
        ob: 'invntrystrg.name',
        o: 'a',
      };
    }

    const billToField = updatedFormFieldsShipTo?.find(
      field => field.fieldName === 'billTo',
    );
    if (billToField) {
      billToField.useQuery =
        useGetInventoryStorageLocationsListForDropdownQuery;
      billToField.queryParams = {
        sb: 'invntrystrg.name',
        ob: 'invntrystrg.name',
        o: 'a',
      };
    }

    setFormElementsShipToFields(updatedFormFieldsShipTo);
  }, []);

  useEffect(() => {
    setShouldTriggerLazyQuery(
      !isFetchingWorkflowStatuses && isSuccessWorkflowStatuses,
    );
  }, [isFetchingWorkflowStatuses, isSuccessWorkflowStatuses]);

  const statusFilterOptions = useMemo(() => {
    return workflowStatuses
      ? orderBy(workflowStatuses, 'name')?.map(status => ({
          id: status?.id,
          label: status.name,
          fieldName: workflowStatuses
            ?.filter(
              workflowStatus =>
                workflowStatus.name === status.name && workflowStatus.groupId,
            )
            ?.map(workflowStatus => workflowStatus.groupId)
            ?.join(','),
          isInitialSelected: true,
        }))
      : undefined;
  }, [workflowStatuses]);

  const statusFilterOptionsWithDuplicatesRemoved = useMemo(() => {
    return statusFilterOptions?.filter(
      (status, index, self) =>
        index === self.findIndex(t => t.label === status.label),
    );
  }, [statusFilterOptions]);

  useEffect(() => {
    if (
      !isLoadingSupplierIntegrationLink &&
      isSuccessSupplierIntegrationLink &&
      !isErrorSupplierIntegrationLink &&
      supplierIntegrationLink?.redirectUrl &&
      currentRequestId === lastRequestId
    ) {
      setShowShipToModal(false);
      window.open(supplierIntegrationLink.redirectUrl, '_blank');
    }
  }, [
    isLoadingSupplierIntegrationLink,
    isSuccessSupplierIntegrationLink,
    isErrorSupplierIntegrationLink,
    supplierIntegrationLink,
    currentRequestId,
    lastRequestId,
    supplierIntegrationLinkError,
  ]);

  const partsOrderingButtons = useMemo(() => {
    if (!hasSupplierIntegrationPartsOrderingPurchase) {
      return null;
    }

    const handleSupplierIntegrationClick = (supplierKey: string) => {
      setShowShipToModal(true);
      const selectedSupIntegration = supplierIntegrationList?.[supplierKey];

      if (selectedSupIntegration) {
        setSelectedSupplierIntegration(selectedSupIntegration);
      }
    };

    return (
      <ECSupplierIntegrationButton
        siData={supplierIntegrationList || {}}
        handleOnClick={handleSupplierIntegrationClick}
        buttonLabel={t('translation:pages.purchaseOrders.orderFromSupplier')}
        variant="outlined"
        color="primary"
      />
    );
  }, [hasSupplierIntegrationPartsOrderingPurchase, supplierIntegrationList, t]);

  const handleCloseShipToModal = () => {
    setShowShipToModal(false);
    setSelectedSupplierIntegration(null);
    setFormElementsShipToFields(prevFields =>
      prevFields.map(field => ({
        ...field,
        value: Array.isArray(field.value) ? [] : null,
        disabled: field.fieldName === 'billTo' ? false : field.disabled,
      })),
    );
  };

  const handleFormSubmit = (formData: any, output: any) => {
    const storageLocationId = output?.find(f => f.fieldName === 'shipTo')?.value
      ?.id;
    const billingLocationIds =
      output?.find(f => f.fieldName === 'billTo')?.value?.id || '';
    if (selectedSupplierIntegration?.supplierName && storageLocationId) {
      triggerSupplierIntegrationLink({
        supplierIntegrationCode: selectedSupplierIntegration.supplierName,
        inventoryStorageId: storageLocationId,
        billingLocationIds,
      }).then(result => {
        if ('requestId' in result) {
          setLastRequestId(result.requestId);
        }
      });
    }
  };

  const handleOutputChange = useCallback(
    (output: ECEasyFormFieldType[], fieldName?: string) => {
      const isSameAsShipToCheckboxChecked = compact(
        output?.find(f => f.fieldName === 'isBillingSameAsShipTo')?.value,
      )?.length;

      if (fieldName === 'isBillingSameAsShipTo') {
        const billTo = output?.find(f => f.fieldName === 'billTo');
        if (billTo) {
          billTo.disabled = !!isSameAsShipToCheckboxChecked;
        }
      }

      if (isSameAsShipToCheckboxChecked) {
        const shipTo = output?.find(f => f.fieldName === 'shipTo');
        const billTo = output?.find(f => f.fieldName === 'billTo');

        if (billTo && shipTo?.value) {
          billTo.value = shipTo.value;
        }
      }

      setFormElementsShipToFields([...output]);
    },
    [],
  );

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={t('translation:pages.purchaseOrders.purchaseOrderList')}
        useLazyGetListQuery={useLazyGetAllPurchaseOrderListQuery}
        useCreateMutation={undefined}
        useUpdateMutation={undefined}
        editFormConfig={[] as any}
        editFormFields={[] as any}
        createFormConfig={undefined as any}
        createFormFields={undefined as any}
        detailsConfig={[] as any}
        detailsFields={[] as any}
        onRowClick={null as any}
        onDrawerClose={null as any}
        showOnlyAttachmentsTab={true}
        marginTop={marginTop}
        customContent={{
          header: (
            <CustomPurchaseOrderHeader
              partsOrderingButtons={partsOrderingButtons}
            />
          ),
        }}
        shouldTriggerLazyQuery={shouldTriggerLazyQuery}
        statusFilterOptions={statusFilterOptionsWithDuplicatesRemoved}
        statusFilterAlias="wrkflwstts.groupNameId"
        showStatusFilter
        showOnlyDetailsTab
        statusFilterInitialSelected
        shouldCacheLazyQuery
        withDrawer={false}
        showColumnVisibilityFilter
        enableExport
        enableTableCsvExport
        moduleName="purchase-orders"
        conditionalLinearProgressColor
      />

      <ECModal isOpen={showShipToModal} onClose={handleCloseShipToModal}>
        <ECBox
          display="flex"
          flexDirection="column"
          sx={{
            width: '50vw',
            minHeight: '30vh',
            overflowY: 'hidden',
          }}
        >
          <ECTypography variant="h5">
            {t('translation:pages.purchaseOrders.shipToModalTitle', {
              supplierName: selectedSupplierIntegration?.title,
            })}
          </ECTypography>

          <ECEasyForm
            pattern="filled"
            fullHeight={false}
            noPadding
            noPaddingX
            noPaddingY
            config={formElementsShipTo.config}
            fields={formElementsShipToFields}
            isSendingData={false}
            isLoadingForm={
              isLoadingSupplierIntegrationLink ||
              isFetchingSupplierIntegrationLink
            }
            showSaveButton={true}
            onFormSubmit={handleFormSubmit}
            onChange={handleOutputChange}
            additionalActions={
              <ECButton
                type="button"
                variant="text"
                onClick={handleCloseShipToModal}
              >
                {t('translation:dynamicForm.cancel')}
              </ECButton>
            }
          />
        </ECBox>
      </ECModal>
    </>
  );
};
