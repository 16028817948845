import {
  useChallengeMutation,
  useLazyDecideUserLoginQuery,
} from 'services/authApi';
import { useNavigate, useLocation } from 'react-router-dom';
import { ECEasyForm, FieldTypes } from 'app/components/ECForm';
import { ECMfaTemplate } from 'app/components/ECMfaTemplate';
import { ECBox } from 'app/components/ECBox';
import { ECAlert, ECAlertTitle, ECAlertError } from 'app/components/ECAlert';
import { ECTypography } from 'app/components/ECTypography';
import { Helmet } from 'react-helmet-async';
import { Help } from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { ECLink } from 'app/components/ECLink';
import { ResendTimer } from 'app/components/ECResendTimer';

const CodeSendSuccess = props => (
  <ECAlert severity="success">
    <ECAlertTitle>Code Sent</ECAlertTitle>
    <ECTypography>{props.children}</ECTypography>
  </ECAlert>
);

export function OTPPage() {
  const location = useLocation();
  const [err, setErr] = useState<string | null>(null);
  const [isResendCode, setIsResendCode] = useState<boolean>(false);

  const sentEmailLogin = location.state?.sentEmailLogin;
  const [OTPInfo, setOTPInfo] = useState<{
    challengeName: string | null;
    email: string | null;
    session: string | null;
    username: string | null;
  } | null>(null);

  const theme = useTheme();

  const navigate = useNavigate();

  const [challenge, { data, isError, isLoading, isSuccess, error }] =
    useChallengeMutation({});
  const [
    signinUser,
    {
      data: dataSign,
      isSuccess: isSucessResendCode,
      isError: isErrorResendCode,
      error: errorResendCode,
    },
  ] = useLazyDecideUserLoginQuery({});

  useEffect(() => {
    if (isSucessResendCode && dataSign) {
      setOTPInfo({
        challengeName: dataSign?.otp?.challengeName,
        email: dataSign?.otp?.email,
        session: dataSign?.otp?.session,
        username: dataSign?.otp?.username,
      });
    }
    setIsResendCode(isSucessResendCode);
  }, [isSucessResendCode, dataSign]);

  useEffect(() => {
    if (isSuccess && data) {
      localStorage.setItem('idToken', data?.idToken?.jwtToken);
      localStorage.setItem('refreshToken', data?.refreshToken?.token);
      localStorage.setItem('sessionInfo', data?.accessToken?.payload?.username);
      localStorage.setItem(
        'sessionInfoUserName',
        data?.accessToken?.payload?.username,
      );
      if (data?.company?.authTimeout > 0) {
        localStorage.setItem(
          'customSessionTimeoutTimestamp',
          DateTime.now()
            .plus({ hours: data?.company?.authTimeout })
            .toMillis()
            .toString(),
        );
      } else {
        localStorage.removeItem('customSessionTimeoutTimestamp');
      }
      navigate('/home', { state: location.state });
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if ((isError || isErrorResendCode) && !err) {
      setErr(
        (error as any)?.data?.message ||
          (error as any)?.error ||
          (errorResendCode as any)?.data?.message ||
          (errorResendCode as any)?.error ||
          'Unknown Error',
      );
      setIsResendCode(false);
    }
  }, [isError, isErrorResendCode]);

  useEffect(() => {
    const challengeName = localStorage.getItem('challengeName');
    const email = localStorage.getItem('email');
    const session = localStorage.getItem('session');
    const username = localStorage.getItem('username');
    setOTPInfo({
      challengeName,
      email,
      session,
      username,
    });
  }, []);

  const fields = useMemo(() => {
    return [
      {
        type: FieldTypes.Text,
        label: 'Authentication Code',
        placeholder: '',
        value: '',
        fieldName: 'code',
        mask: '00000000',
      },
    ];
  }, []);

  const onChangeFields = useCallback(
    fields => {
      const { value } = fields[0];
      if (value.length === 8) {
        challenge({ code: value, ...OTPInfo });
        setErr(null);
        setIsResendCode(false);
      }
    },
    [challenge, OTPInfo, setErr, setIsResendCode],
  );

  const onFormSubmit = useCallback(
    formData => {
      const code = formData.get('code') as string;
      if (code) {
        challenge({ code, ...OTPInfo });
        setErr(null);
        setIsResendCode(false);
      }
    },
    [challenge, OTPInfo, setErr, setIsResendCode],
  );

  const handleOnClickResendCode = useCallback(() => {
    signinUser({
      email: sentEmailLogin?.toLowerCase(),
    });
    setErr(null);
    setIsResendCode(true);
  }, [signinUser, sentEmailLogin]);

  return (
    <>
      <Helmet>
        <title>OTP Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECMfaTemplate
        childrenSx={{
          minWidth: '576px',
        }}
      >
        <ECEasyForm
          pattern="standard"
          drawerTitleBarBGColor="transparent"
          config={{
            actionButtonsContainerStyle: { display: 'none' },
            variant: 'standard',
            title: 'One Time Password Authentication',
            subtitle:
              'Enter the authentication code from your email ' + OTPInfo?.email,
            cols: 1,
            titleStyle: {
              color: theme.palette.common.white,
            },
            subtitleStyle: {
              color: theme.palette.common.white,
            },
          }}
          fullHeight={false}
          fields={fields}
          isSendingData={isLoading}
          isLoadingForm={false}
          onChange={onChangeFields}
          onFormSubmit={onFormSubmit}
        />

        {err && (
          <ECBox
            style={{
              paddingInline: '24px',
              marginBottom: '24px',
            }}
          >
            <ECAlertError> {err} </ECAlertError>
          </ECBox>
        )}
        {isResendCode && (
          <ECBox
            style={{
              paddingInline: '24px',
              marginBottom: '24px',
            }}
          >
            <CodeSendSuccess>
              A verification code has been sent to your email.
            </CodeSendSuccess>
          </ECBox>
        )}

        <ResendTimer>
          {(startTimer, timeLeft, isdisabled) => (
            <ECBox
              px={2}
              display={'flex'}
              flexDirection={'row'}
              gap={1}
              justifyContent={'center'}
              color={theme.palette.common.white}
            >
              <Help />{' '}
              <ECTypography color={theme.palette.common.white}>
                Didn't get a Code?
              </ECTypography>
              <ECLink
                sx={{
                  color: theme.palette.common.white + ' !important',
                  textDecoration: 'underline',
                }}
                notClickable={isdisabled}
                onClick={() => {
                  handleOnClickResendCode();
                  startTimer();
                }}
              >
                Resend Code {timeLeft > 0 && `in ${timeLeft}s`}
              </ECLink>
            </ECBox>
          )}
        </ResendTimer>
      </ECMfaTemplate>
    </>
  );
}
