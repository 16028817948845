import { EditForm2 } from 'app/components/ECDynamicPageTemplate/EditForm2';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useGetWorkOrderAutomationPreferenceQuery,
  useGetPeriodQuery,
} from 'services/lookupApi';
import {
  profileApi,
  useGetCompanyConfigurationQuery,
  useGetProfileQuery,
  useUpdateCompanyConfigurationMutation,
} from 'services/profileApi';
import { loadPageScopes } from 'utils/pageScopes';
import { useDispatch } from 'react-redux';
import { setSnackbar, setTitle } from 'store/slice/page';
import { useTranslation } from 'react-i18next';
import { invalidateCompanyProfile } from 'utils/invalidateCache';
import { ActivityType } from 'types/Activity';
import { P } from 'types/Permission';
import { historyApi } from 'services/historyApi';

const editForm = require('./fancy_form_config_edit.json');
const detailsForm = require('./fancy_form_config_details.json');

const dropdownFieldsNames = [
  'spRejectWoAutomationPreferenceName',
  'pmRejectWoAutomationPreferenceName',
];

const groupDropdownFieldsNames = [
  'priorityWoAutomationPreferenceName',
  'pmPriorityWoAutomationPreferenceName',
];

const tableFields = ['workOrderAllotedTime', 'maintenanceWorkOrderAllotedTime'];

function hasDifferenceInOriginalValues(
  values,
  fieldName,
  valueChange,
  originalValues,
) {
  if (!originalValues) return false;

  // first check dropdowns as its faster
  const hasDifferentValuesForDropdown = dropdownFieldsNames.some(f => {
    return (
      values.find(value => value.fieldName === f).value !== originalValues[f]
    );
  });
  if (hasDifferentValuesForDropdown) {
    return true;
  }

  // check subfield dropdowns
  const hasDifferentValuesForGroupDropdown = groupDropdownFieldsNames.some(
    f => {
      const groupName =
        f === 'priorityWoAutomationPreferenceName'
          ? 'tableWorkOrderAutomation'
          : 'tablePmWorkOrder';
      const groupField = values.find(value => value.groupName === groupName);
      const dropdownField = groupField.subFields.find(sf => sf.fieldName === f);
      return dropdownField.value !== originalValues[f];
    },
  );

  if (hasDifferentValuesForGroupDropdown) {
    return true;
  }

  // then check tables
  const hasDifferentTableValues = tableFields.some(tf => {
    const groupName =
      tf === 'workOrderAllotedTime'
        ? 'tableWorkOrderAutomation'
        : 'tablePmWorkOrder';
    const groupField = values.find(value => value.groupName === groupName);
    const workOrderAllotedTimeValues = groupField.subFields.find(
      sf => sf.fieldName === tf,
    ).value;
    const originalTableValue = originalValues[tf];
    const hasDifferentValuesForTable = workOrderAllotedTimeValues.some(
      (v, i) => {
        // when changing allot time unit, we don't store the CODE so this check can be done
        if (!v.allottedUnit.code) return true;
        return (
          Number(v.allottedTime) !== Number(originalTableValue[i].allottedTime)
        );
      },
    );
    return hasDifferentValuesForTable;
  });

  return hasDifferentTableValues;
}

export function SettingsPage() {
  const { data: companyConfiguration } = useGetCompanyConfigurationQuery();
  const { data: companyProfile } = useGetProfileQuery();
  const isEazyTrakUser = companyProfile?.subscription?.isEzTrak;
  const isDispatchEnabled = companyProfile?.customerProfile?.enableDispatch;

  let isInventoryEnabled = false;

  if (companyConfiguration) {
    isInventoryEnabled = !!companyConfiguration.enableInventory;
  }

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [detailFormFields, setDetailFormFields] = useState(
    detailsForm.data.fields,
  );
  const [editFormFields, setEditFormFields] = useState(editForm.data.fields);

  useEffect(() => {
    dispatch(setTitle(t('translation:panelDrawer.generalSettings')));
  }, [t, dispatch]);

  useEffect(() => {
    loadPageScopes('generalConfiguration');
  }, []);

  // lookup queries for dropdowns
  const {
    data: workOrderAutomationOptions,
    isSuccess: isWorkOrderAutomationOptionsSuccess,
  } = useGetWorkOrderAutomationPreferenceQuery();
  const { data: periodOptions, isSuccess: isPeriodOptionsSuccess } =
    useGetPeriodQuery();

  // populate the dropdowns with the options from the lookup queries
  useEffect(() => {
    const updatedEditFormFields = Array.isArray(editFormFields)
      ? [...editFormFields]
      : Object.values(editFormFields);
    if (isWorkOrderAutomationOptionsSuccess) {
      const mappedOptions = workOrderAutomationOptions.map(o => ({
        label: o.name,
        value: o.id,
      }));
      const mappedOptionsValues = mappedOptions.map(o => o.label);
      // for reject we only use the options that have rejectWo = 1
      const rejectMappedOptions = workOrderAutomationOptions
        .filter(o => o.rejectWo === 1)
        .map(o => ({ label: o.name, value: o.id }));
      const rejectMappedOptionsValues = rejectMappedOptions.map(o => o.label);

      // spRejectWoAutomationPreferenceName
      const spRejectWoAutomationPreferenceNameField =
        updatedEditFormFields.find(
          f => f.fieldName === 'spRejectWoAutomationPreferenceName',
        );
      spRejectWoAutomationPreferenceNameField.options = rejectMappedOptions;
      spRejectWoAutomationPreferenceNameField.optionValues =
        rejectMappedOptionsValues;
      // pmRejectWoAutomationPreferenceName
      const pmRejectWoAutomationPreferenceNameField =
        updatedEditFormFields.find(
          f => f.fieldName === 'pmRejectWoAutomationPreferenceName',
        );
      pmRejectWoAutomationPreferenceNameField.options = rejectMappedOptions;
      pmRejectWoAutomationPreferenceNameField.optionValues =
        rejectMappedOptionsValues;

      // find the other two dropdowns in the group fields
      const workOrderAutomationTableGroupField = updatedEditFormFields.find(
        f => f.groupName === 'tableWorkOrderAutomation',
      );
      const pmWorkOrderGroupField = updatedEditFormFields.find(
        f => f.groupName === 'tablePmWorkOrder',
      );

      // priorityWoAutomationPreferenceName
      const priorityWoAutomationPreferenceNameField =
        workOrderAutomationTableGroupField.subFields.find(
          sf => sf.fieldName === 'priorityWoAutomationPreferenceName',
        );
      priorityWoAutomationPreferenceNameField.options = mappedOptions;
      priorityWoAutomationPreferenceNameField.optionValues =
        mappedOptionsValues;

      // pmPriorityWoAutomationPreferenceName
      const pmPriorityWoAutomationPreferenceNameField =
        pmWorkOrderGroupField.subFields.find(
          sf => sf.fieldName === 'pmPriorityWoAutomationPreferenceName',
        );
      pmPriorityWoAutomationPreferenceNameField.options = mappedOptions;
      pmPriorityWoAutomationPreferenceNameField.optionValues =
        mappedOptionsValues;
    }
    setEditFormFields(updatedEditFormFields);
  }, [isWorkOrderAutomationOptionsSuccess]);

  useEffect(() => {
    const updatedEditFormFields = Array.isArray(editFormFields)
      ? [...editFormFields]
      : Object.values(editFormFields);
    if (isPeriodOptionsSuccess) {
      const mappedOptions = periodOptions.map(o => ({
        label: o.description,
        value: o.code,
      }));
      const mappedOptionsValues = mappedOptions.map(o => o.label);

      // find the two tables in the fields
      const workOrderAutomationTableGroupField = updatedEditFormFields.find(
        f => f.groupName === 'tableWorkOrderAutomation',
      );
      const pmWorkOrderGroupField = updatedEditFormFields.find(
        f => f.groupName === 'tablePmWorkOrder',
      );

      // for tables, need to get the COL fields inside tableConfig

      // workOrderAllotedTime
      const workOrderAllotedTimeTableField =
        workOrderAutomationTableGroupField.subFields.find(
          sf => sf.fieldName === 'workOrderAllotedTime',
        );
      const workOrderAllotedTimeTableFieldNestedField =
        workOrderAllotedTimeTableField.tableConfig.cols.find(
          c => c.fieldName === 'allottedUnit',
        );
      workOrderAllotedTimeTableFieldNestedField.options = mappedOptions;
      workOrderAllotedTimeTableFieldNestedField.optionValues =
        mappedOptionsValues;

      // maintenanceWorkOrderAllotedTime
      const maintenanceWorkOrderAllotedTimeField =
        pmWorkOrderGroupField.subFields.find(
          sf => sf.fieldName === 'maintenanceWorkOrderAllotedTime',
        );
      const maintenanceWorkOrderAllotedTimeTableField =
        maintenanceWorkOrderAllotedTimeField.tableConfig.cols.find(
          c => c.fieldName === 'allottedUnit',
        );
      maintenanceWorkOrderAllotedTimeTableField.options = mappedOptions;
      maintenanceWorkOrderAllotedTimeTableField.optionValues =
        mappedOptionsValues;
    }
    setEditFormFields(updatedEditFormFields);
  }, [isPeriodOptionsSuccess]);

  const [isEdit, setIsEdit] = useState(false);

  // we need to keep track of changes in dropdowns or in the table in order to show confirmation modal
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] =
    useState(false);

  // we handle the form change to disable the table when alloted time dropdown is NO CHANGE
  const handleFormChange = useCallback(
    (values, fieldName, valueChange) => {
      // if any dropdown or table value changed, show confirmation modal
      // separating dropdowns and table because the check is different
      // dropdowns
      if (isEdit && companyConfiguration) {
        if (
          dropdownFieldsNames.includes(fieldName) ||
          groupDropdownFieldsNames.includes(fieldName)
        ) {
          // if value selected is different from the original value, show confirmation modal
          if (valueChange !== companyConfiguration[fieldName]) {
            setShouldShowConfirmationModal(true);
          } else {
            // else check if any other dropdown is different from the original value
            setShouldShowConfirmationModal(
              hasDifferenceInOriginalValues(
                values,
                fieldName,
                valueChange,
                companyConfiguration,
              ),
            );
          }
        }

        // tables
        // tables don't differentiate which row or field is changing so we always checks any difference in the arrays
        if (
          fieldName === 'workOrderAllotedTime' ||
          fieldName === 'maintenanceWorkOrderAllotedTime'
        ) {
          setShouldShowConfirmationModal(
            hasDifferenceInOriginalValues(
              values,
              fieldName,
              valueChange,
              companyConfiguration,
            ),
          );
        }
      }

      if (fieldName === 'priorityWoAutomationPreferenceName') {
        const workOrderAllotedTimeField = values
          .find(v => v.groupName === 'tableWorkOrderAutomation')
          .subFields.find(sf => sf.fieldName === 'workOrderAllotedTime');

        // if changing to No Change, disabled table
        if (valueChange === 'No Change') {
          workOrderAllotedTimeField.disabled = true;
        } else {
          workOrderAllotedTimeField.disabled = false;
        }
      }

      if (fieldName === 'pmPriorityWoAutomationPreferenceName') {
        const pmWorkOrderAllotedTimeField = values
          .find(v => v.groupName === 'tablePmWorkOrder')
          .subFields.find(
            sf => sf.fieldName === 'maintenanceWorkOrderAllotedTime',
          );

        // if changing to No Change, disabled table
        if (valueChange === 'No Change') {
          pmWorkOrderAllotedTimeField.disabled = true;
        } else {
          pmWorkOrderAllotedTimeField.disabled = false;
        }
      }
    },
    [isEdit && companyConfiguration],
  );

  // useEffect to disable the tables in case the dropdown is No Change from the endpoint response
  useEffect(() => {
    if (companyConfiguration) {
      const priorityWoAutomationPreferenceName =
        companyConfiguration.priorityWoAutomationPreferenceName;
      const pmPriorityWoAutomationPreferenceName =
        companyConfiguration.pmPriorityWoAutomationPreferenceName;

      handleFormChange(
        editFormFields,
        'priorityWoAutomationPreferenceName',
        priorityWoAutomationPreferenceName,
      );
      handleFormChange(
        editFormFields,
        'pmPriorityWoAutomationPreferenceName',
        pmPriorityWoAutomationPreferenceName,
      );
    }
  }, [companyConfiguration]);

  // useEffect to hide the onboarding field in case it is not an EazyTrak user
  useEffect(() => {
    const editFormFields = editForm.data.fields;
    const onboardingEditField = editFormFields.find(
      f => f.fieldName === 'enableOnboardingDashboard',
    );
    if (onboardingEditField) {
      onboardingEditField.visible = isEazyTrakUser;
    }

    const detailFormFields = detailsForm.data.fields;
    const onboardingDetailField = detailFormFields.find(
      f => f.fieldName === 'enableOnboardingDashboard',
    );
    if (onboardingDetailField) {
      onboardingDetailField.visible = isEazyTrakUser;
    }
  }, [isEazyTrakUser]);

  const [
    doUpdateConfiguration,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdate,
    },
  ] = useUpdateCompanyConfigurationMutation();

  const useUpdate = useCallback(() => {
    const doUpdate = async data => {
      const {
        invoiceDateRequired,
        invoiceDocumentRequired,
        proposalDocumentRequired,
        woUnassignCommentRequired,
        enableArea,
        enableBudget,
        enableWoReview,
        spCheckInTimeEnabled,
        enableInvoicePdfAttachment,
        enableInventoryMarkup,
        enablePriceEdit,
        enableOnboardingDashboard,
        enableTimeTrack,
      } = data;

      let isAllottedTimesAreValid = true;

      if (data?.priorityWoAutomationPreferenceName !== 'No Change')
        data?.workOrderAllotedTime?.map(w => {
          if (
            w?.allottedTime === null ||
            w?.allottedTime < 0 ||
            !w.allottedUnit?.description
          ) {
            isAllottedTimesAreValid = false;
          }
        });
      if (data?.pmPriorityWoAutomationPreferenceName !== 'No Change')
        data?.maintenanceWorkOrderAllotedTime?.map(w => {
          if (
            w?.allottedTime === null ||
            w?.allottedTime < 0 ||
            !w.allottedUnit?.description
          ) {
            isAllottedTimesAreValid = false;
          }
        });

      if (!isAllottedTimesAreValid) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message:
              'Both Allotted time and Period are required on a Priority to Save',
          }),
        );
        return;
      }

      const body = {
        invoiceDateRequired,
        invoiceDocumentRequired,
        proposalDocumentRequired,
        woUnassignCommentRequired,
        enableArea,
        enableBudget,
        enableWoReview,
        spCheckInTimeEnabled,
        paidOptions: {
          workorderSurvey: !!data?.paidOptions?.workorderSurvey,
        },
        priorityWoAutomationPreferenceId: workOrderAutomationOptions?.find(
          o => o.name === data.priorityWoAutomationPreferenceName,
        )?.id,
        pmPriorityWoAutomationPreferenceId: workOrderAutomationOptions?.find(
          o => o.name === data.pmPriorityWoAutomationPreferenceName,
        )?.id,
        spRejectWoAutomationPreferenceId: workOrderAutomationOptions?.find(
          o => o.name === data.spRejectWoAutomationPreferenceName,
        )?.id,
        pmRejectWoAutomationPreferenceId: workOrderAutomationOptions?.find(
          o => o.name === data.pmRejectWoAutomationPreferenceName,
        )?.id,
        workOrderAllotedTime: data.workOrderAllotedTime.map(w => {
          // if allottedUnit.code is not in response, find it in options by description
          const code =
            w.allottedUnit?.code ||
            periodOptions?.find(
              o => o.description === w.allottedUnit?.description,
            )?.code;
          const allottedTime = w?.allottedTime;
          return {
            id: w.id,
            allottedTime: !allottedTime && code ? 0 : allottedTime,
            code: allottedTime && !code ? periodOptions?.[0]?.code : code,
          };
        }),
        maintenanceWorkOrderAllotedTime:
          data.maintenanceWorkOrderAllotedTime.map(m => {
            // if allottedUnit.code is not in response, find it in options by description
            const code =
              m.allottedUnit?.code ||
              periodOptions?.find(
                o => o.description === m.allottedUnit?.description,
              )?.code;
            const allottedTime = m?.allottedTime;
            return {
              id: m.id,
              allottedTime: !allottedTime && code ? 0 : allottedTime,
              code: allottedTime && !code ? periodOptions?.[0]?.code : code,
            };
          }),
        enableInvoicePdfAttachment,
        enableInventoryMarkup: isInventoryEnabled
          ? enableInventoryMarkup
          : undefined,
        enablePriceEdit: isInventoryEnabled ? enablePriceEdit : undefined,
        enableOnboardingDashboard,
        enableTimeTrack: isDispatchEnabled ? enableTimeTrack : undefined,
      };

      const isValidMaintenanceWOrkOrderAllottedTime =
        body.maintenanceWorkOrderAllotedTime?.every(
          item => item.allottedTime > 0,
        );
      const isValidWorkOrderAllottedTime = body.workOrderAllotedTime?.every(
        item => item.allottedTime > 0,
      );

      if (
        !isValidMaintenanceWOrkOrderAllottedTime ||
        !isValidWorkOrderAllottedTime
      ) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: 'AllottedTime can not be zero',
          }),
        );
        return;
      }
      doUpdateConfiguration(body);
    };
    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: resetUpdate,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    doUpdateConfiguration,
    resetUpdate,
    workOrderAutomationOptions,
    periodOptions,
  ]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setIsEdit(false);
      setShouldShowConfirmationModal(false);
      dispatch(profileApi.util.invalidateTags(['Profile']));
      invalidateCompanyProfile();
    }
  }, [isUpdateSuccess]);

  const handleOnClose = useCallback(() => {
    setIsEdit(prev => !prev);
    setShouldShowConfirmationModal(false);
  }, []);

  const submissionModal = useMemo(() => {
    return {
      title:
        'The WO Automation will apply to all New Work Orders. Existing Work Orders will be unchanged.',
      buttonText: 'Save',
      bodyText: '',
      isVisible: shouldShowConfirmationModal,
    };
  }, [shouldShowConfirmationModal]);

  useEffect(() => {
    if (!isUpdateLoading && isUpdateSuccess) {
      dispatch(historyApi.util.invalidateTags(['History']));
    }
  }, [isUpdateLoading, isUpdateSuccess]);

  useEffect(() => {
    const updatedDetailFormFields = Array.isArray(detailFormFields)
      ? [...detailFormFields]
      : Object.values(detailFormFields);

    const updatedEditFormFields = Array.isArray(editFormFields)
      ? [...editFormFields]
      : Object.values(editFormFields);

    const inventoryMarkupDetailField = updatedDetailFormFields?.find(
      f => f.fieldName === 'enableInventoryMarkup',
    );
    const inventoryMarkupEditField = updatedEditFormFields?.find(
      f => f.fieldName === 'enableInventoryMarkup',
    );

    const inventoryPriceEditDetailField = updatedDetailFormFields?.find(
      f => f.fieldName === 'enablePriceEdit',
    );
    const inventoryPriceEditEditField = updatedEditFormFields?.find(
      f => f.fieldName === 'enablePriceEdit',
    );

    if (
      inventoryMarkupDetailField &&
      inventoryMarkupEditField &&
      inventoryPriceEditDetailField &&
      inventoryPriceEditEditField
    ) {
      inventoryMarkupDetailField.visible = isInventoryEnabled;
      inventoryMarkupEditField.visible = isInventoryEnabled;
      inventoryPriceEditDetailField.visible = isInventoryEnabled;
      inventoryPriceEditEditField.visible = isInventoryEnabled;
    }

    const technicianTimeTrackingDetailField = updatedDetailFormFields?.find(
      f => f.fieldName === 'enableTimeTrack',
    );

    const technicianTimeTrackingEditField = updatedEditFormFields?.find(
      f => f.fieldName === 'enableTimeTrack',
    );

    if (technicianTimeTrackingDetailField && technicianTimeTrackingEditField) {
      technicianTimeTrackingDetailField.visible = isDispatchEnabled;
      technicianTimeTrackingEditField.visible = isDispatchEnabled;
    }

    setDetailFormFields(updatedDetailFormFields);
    setEditFormFields(updatedEditFormFields);
  }, [companyConfiguration, isInventoryEnabled, isDispatchEnabled]);

  return (
    <>
      {companyConfiguration && (
        <EditForm2
          row={companyConfiguration}
          formConfig={editForm.data.config}
          formFields={editFormFields}
          detailsConfig={detailsForm.data.config}
          detailsFields={detailFormFields}
          useUpdateMutation={useUpdate}
          onChange={handleFormChange}
          showCloseButton={false}
          onlyEdit={isEdit}
          onClose={handleOnClose}
          onEditButtonClick={setIsEdit}
          submissionConfirmationModal={submissionModal}
          drawerTitleBarBGColor="transparent"
          showActivityButton
          activityType={ActivityType.Company}
          activityPermissionScopes={[P.GetCompanyConfiguration]}
        />
      )}
    </>
  );
}
