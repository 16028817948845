import { ECBox } from '../ECBox';
import { ECButton } from '../ECButton';
import { ECTypography } from '../ECTypography';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';

interface ExtractedTextDisplayProps {
  words: string[];
  isProcessing: boolean;
}

export const ECExtractedTextDisplay = ({
  words,
  isProcessing,
}: ExtractedTextDisplayProps) => {
  const dispatch = useDispatch();

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatch(
      setSnackbar({
        severity: 'success',
        message: `Copied "${text}" to clipboard!`,
      }),
    );
  };

  return (
    <ECBox
      sx={theme => ({
        marginTop: '1rem',
        padding: '0.5rem',
        backgroundColor: theme.palette.other.background,
        borderRadius: '4px',
        minHeight: '3rem',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '0.5rem',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.border.primary}`,
        width: '100%',
      })}
    >
      {isProcessing ? (
        <ECTypography variant="caption">Extracting text...</ECTypography>
      ) : words.length > 0 ? (
        words.map((word, index) => (
          <ECButton
            key={index}
            size="small"
            sx={{
              padding: '0.15rem 0.25rem',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            variant="outlined"
            onClick={() => handleCopyToClipboard(word)}
          >
            {word}
          </ECButton>
        ))
      ) : (
        <ECTypography variant="caption">No text extracted yet</ECTypography>
      )}
    </ECBox>
  );
};
