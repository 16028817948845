import {
  ECBox,
  ECButton,
  ECDynamicPageTemplate,
  ECTypography,
} from 'app/components';
import { useCustomerUser } from 'app/hooks/customerUser.use-case';
import { useEffect, useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCreateApprovalMutation,
  useLazyGetApprovalListQuery,
  useLazyGetApprovalStepsQuery,
  useUpdateApprovalMutation,
} from 'services/approvalApi';
import { useGetBranchTypesQuery } from 'services/branchApi';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { loadPageScopes } from 'utils/pageScopes';
import { populateDropDownFields } from 'utils/pageUtils';
import { ForbiddenPage } from '../ForbiddenPage';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { useDispatch } from 'react-redux';
import { historyApi } from 'services/historyApi';
import { StepsLkpModule } from 'types/Approval';
import { ECModal } from 'app/components/ECModal';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const fancyFormElementsDetails = require('./fancy_form_config_details.json');

export function ApprovalPage() {
  loadPageScopes('approval');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [row, setRow] = useState<any>();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [approvalData, setApprovalData] = useState<any | null>(null);
  const [initialApprovalData, setInitialApprovalData] = useState({
    invoice: {
      superApproval: [],
      specialApproval: [],
      workFlow: [],
    },
    proposal: {
      superApproval: [],
      specialApproval: [],
      workFlow: [],
    },
  });

  const { data: branchTypeData, isSuccess: branchTypeSuccess } =
    useGetBranchTypesQuery({});

  const { data: userProfileData } = useGetUserProfileQuery();

  if (branchTypeSuccess && branchTypeData) {
    populateDropDownFields({
      responseData: branchTypeData,
      createFormFields: fancyFormElementsCreate.data.fields,
      editFormFields: fancyFormElementsEdit.data.fields,
      dataOptionsFieldName: 'name',
      dataValuesFieldName: 'id',
      formFieldName: 'branchTypes',
      isPopulateForChipsComponent: true,
    });
  }

  const { data: categoryListData, isSuccess: categoryListIsSuccess } =
    useGetCategoryListQuery({ st: 1 });

  useEffect(() => {
    let editSelectField = fancyFormElementsEdit.data.fields.find(
      field => field.fieldName === 'requestCategory',
    );

    let createSelectField = fancyFormElementsCreate.data.fields.find(
      field => field.fieldName === 'requestCategory',
    );

    editSelectField.options =
      categoryListData?.data.map(d => ({ label: d.name, id: d.id })) || [];
    editSelectField.optionValues =
      categoryListData?.data.map(d => d.name) || [];

    createSelectField.options =
      categoryListData?.data.map(d => ({ label: d.name, id: d.id })) || [];
    createSelectField.optionValues =
      categoryListData?.data.map(d => d.name) || [];
  }, [categoryListIsSuccess, categoryListData]);

  const [
    doUpdateApproval,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdateApproval,
    },
  ] = useUpdateApprovalMutation();

  const branchTypeIdsMatch = (row, newData) => {
    const { branchTypeIds } = newData.approval;
    const branchTypes = row?.branchTypes?.map(branchType => branchType.id);

    if (branchTypeIds?.length !== branchTypes?.length) {
      return false;
    }

    return branchTypeIds.every(branchTypeId =>
      branchTypes?.includes(branchTypeId),
    );
  };

  const requestCategoryMatch = (row, newData) => {
    const { requestCategory } = newData.approval;
    const requestCategories = row?.requestCategory?.map(
      requestCategory => requestCategory?.id,
    );

    if (requestCategories?.length !== requestCategory.length) {
      return false;
    }

    return requestCategory.every(item => requestCategories.includes(item));
  };

  const isNameOrDescriptionChanged = (row, newData) => {
    const isBranchTypeIdsMatch = branchTypeIdsMatch(row, newData);
    const isRequestCategoryMatch = requestCategoryMatch(row, newData);
    const otherFieldsUnchanged =
      row?.requireStepsInvoice === newData?.approval?.requireStepsInvoice &&
      row?.requireStepsProposal === newData?.approval?.requireStepsProposal &&
      row?.id === newData?.approval?.id &&
      row?.amount === newData?.approval?.amount &&
      isBranchTypeIdsMatch &&
      isRequestCategoryMatch;
    return otherFieldsUnchanged;
  };

  const getAllDataIds = data => {
    const extractIds = approvals => {
      return (
        approvals?.flatMap(item =>
          Array.isArray(item.id) ? item.id : [item.id],
        ) || []
      );
    };
    const invoiceSuperApprovalIds = extractIds(data.invoice?.superApproval);
    const invoiceSpecialApprovalIds = extractIds(data.invoice?.specialApproval);
    const invoiceWorkFlowIds = extractIds(data.invoice?.workFlow);

    const proposalSuperApprovalIds = extractIds(data.proposal?.superApproval);
    const proposalSpecialApprovalIds = extractIds(
      data.proposal?.specialApproval,
    );
    const proposalWorkFlowIds = extractIds(data.proposal?.workFlow);

    const allIds = [
      ...invoiceSuperApprovalIds,
      ...invoiceSpecialApprovalIds,
      ...invoiceWorkFlowIds,
      ...proposalSuperApprovalIds,
      ...proposalSpecialApprovalIds,
      ...proposalWorkFlowIds,
    ];

    return allIds;
  };

  const initialApprovalFlatData = useMemo(() => {
    const mergeArrays = approvals => {
      return (
        approvals?.flatMap(item =>
          Array.isArray(item.id)
            ? item.id.map(id => ({ ...item, id }))
            : [{ ...item, id: item.id }],
        ) || []
      );
    };

    const invoiceSuperApprovalItems = mergeArrays(
      initialApprovalData.invoice?.superApproval,
    );
    const invoiceSpecialApprovalItems = mergeArrays(
      initialApprovalData.invoice?.specialApproval,
    );
    const invoiceWorkFlowItems = mergeArrays(
      initialApprovalData.invoice?.workFlow,
    );

    const proposalSuperApprovalItems = mergeArrays(
      initialApprovalData.proposal?.superApproval,
    );
    const proposalSpecialApprovalItems = mergeArrays(
      initialApprovalData.proposal?.specialApproval,
    );
    const proposalWorkFlowItems = mergeArrays(
      initialApprovalData.proposal?.workFlow,
    );

    const mergedArray = [
      ...invoiceSuperApprovalItems,
      ...invoiceSpecialApprovalItems,
      ...invoiceWorkFlowItems,
      ...proposalSuperApprovalItems,
      ...proposalSpecialApprovalItems,
      ...proposalWorkFlowItems,
    ];

    return mergedArray;
  }, [initialApprovalData]);

  const arraysEqual = (a, b) => {
    if (a.length !== b.length) return false;
    const sortedA = [...a].sort();
    const sortedB = [...b].sort();
    return sortedA.every((value, index) => value === sortedB[index]);
  };

  const getStepTabUnchanged = data => {
    const stepDataIds = data?.steps?.flatMap(step =>
      Array.isArray(step.id) ? step.id : [step.id],
    );
    const initialStepIds = initialApprovalFlatData?.map(item => item?.id);

    // Check if stepDataIds and initialStepIds are equal
    if (!arraysEqual(stepDataIds, initialStepIds)) {
      return false;
    }

    // Use .map() to iterate over initialApprovalFlatData and check each item against data.steps
    return initialApprovalFlatData
      ?.map(item => {
        const stepItem = data?.steps?.find(step =>
          Array.isArray(step.id)
            ? step.id.includes(item.id)
            : step.id === item.id,
        );

        const isJobTitleArray =
          Array.isArray(item?.jobTitle) && Array.isArray(stepItem?.jobTitle);
        const isJobTitleEqual = isJobTitleArray
          ? item?.jobTitle?.every(job =>
              stepItem?.jobTitle?.some(stepJob => stepJob.id === job.id),
            )
          : item?.jobTitle?.id === stepItem?.jobTitle?.id;

        // Return whether all properties match
        return (
          item?.activeDays === stepItem?.activeDays &&
          item?.limit === stepItem?.limit &&
          item?.actions?.length === (stepItem?.actions?.length ?? 0) &&
          isJobTitleEqual &&
          item?.actions?.every(action =>
            stepItem?.actions?.some(stepAction => stepAction.id === action.id),
          )
        );
      })
      .every(result => result); // Check if every item's properties matched
  };

  const handleConfirmUpdate = useCallback(() => {
    doUpdateApproval(approvalData);
    dispatch(historyApi.util.invalidateTags(['ApprovalProcessActivity']));
    setIsConfirmationModalOpen(false);
  }, [doUpdateApproval, approvalData, dispatch]);

  const useUpdateApproval = () => {
    const doUpdate = useCallback(
      async data => {
        const newData = { ...data };
        const requests = newData.approval.requestCategory.map(
          reqCat => reqCat?.id,
        );
        newData.approval.requestCategory = requests;
        newData.approval.branchTypeIds = newData.approval.branchTypes?.map(
          branchType => branchType?.id,
        );
        newData.steps = newData?.steps?.reduce?.(
          (acc, step) =>
            acc.concat(
              Array.isArray(step?.jobTitle)
                ? [
                    ...step?.jobTitle?.map(jobTitle => ({
                      ...step,
                      jobTitleId: jobTitle.id,
                    })),
                  ]
                : { ...step },
            ),
          [],
        );
        setApprovalData(newData);
        const nameOrDescriptionChangedInFirstTab = isNameOrDescriptionChanged(
          row,
          newData,
        );
        const stepTabUnchanged = getStepTabUnchanged(data);
        if (nameOrDescriptionChangedInFirstTab && stepTabUnchanged) {
          doUpdateApproval(newData);
          dispatch(historyApi.util.invalidateTags(['ApprovalProcessActivity']));
          setIsConfirmationModalOpen(false);
        } else {
          setIsConfirmationModalOpen(true);
        }
      },
      [handleConfirmUpdate, row, initialApprovalData, setApprovalData],
    );

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: resetUpdateApproval,
      },
    ];
  };

  const handleCloseConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(false);
  }, []);

  const resetRow = useCallback(() => {
    setRow({});
  }, []);

  const [
    doCreateApproval,
    {
      isError: isCreateError,
      error: createError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      reset: resetCreateApproval,
    },
  ] = useCreateApprovalMutation();

  const useCreateApproval = useCallback(() => {
    const doCreate = async data => {
      const newData = { ...data };
      const requests = newData.approval.requestCategory.map(
        reqCat => reqCat.id,
      );

      newData.approval.requestCategory = requests;
      if (newData.approval.branchTypes?.length)
        newData.approval.branchTypeIds = newData.approval.branchTypes?.map(
          branchType => branchType?.id,
        );

      newData.lkpModuleName = StepsLkpModule.Invoice;

      newData.steps = newData?.steps?.reduce?.(
        (acc, step) =>
          acc.concat(
            Array.isArray(step?.jobTitle)
              ? [
                  ...step?.jobTitle?.map(jobTitle => ({
                    ...step,
                    jobTitleId: jobTitle.id,
                  })),
                ]
              : { ...step },
          ),
        [],
      );

      doCreateApproval(newData);
      dispatch(historyApi.util.invalidateTags(['ApprovalProcessActivity']));
    };

    return [
      doCreate,
      {
        isError: isCreateError,
        error: createError,
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
        reset: resetCreateApproval,
      },
    ];
  }, [
    isCreateError,
    createError,
    isCreateLoading,
    isCreateSuccess,
    doCreateApproval,
    categoryListData,
    resetCreateApproval,
  ]);

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <ECDynamicPageTemplate
        shouldCacheLazyQuery
        pageTitle={t('translation:pages.approval.title')}
        useLazyGetListQuery={useLazyGetApprovalListQuery}
        showStatusActiveFilter={true}
        useCreateMutation={useCreateApproval}
        useUpdateMutation={useUpdateApproval}
        createFormConfig={fancyFormElementsCreate.data.config}
        createFormFields={fancyFormElementsCreate.data.fields}
        editFormConfig={fancyFormElementsEdit.data.config}
        editFormFields={fancyFormElementsEdit.data.fields}
        detailsConfig={fancyFormElementsDetails.data.config}
        detailsFields={fancyFormElementsDetails.data.fields}
        hasDetails={true}
        showImageAttachment={false}
        showSteps={true}
        showEditButton={userProfileData?.roles?.some(
          role =>
            role.code?.toUpperCase() === 'CUSTOMER_MANAGE_APPROVAL_WORKFLOW',
        )}
        onRowClick={setRow}
        onDrawerClose={resetRow}
        shouldNotUseActiveFilter
        initialApprovalData={initialApprovalData}
        setInitialApprovalData={setInitialApprovalData}
        showDrawerDetailTitle={false}
      />
      <ECModal
        isOpen={isConfirmationModalOpen}
        onClose={handleCloseConfirmationModal}
      >
        <ECBox p={2}>
          <ECTypography variant="h6">
            {/* {saveConfirmationModal.title} */}
          </ECTypography>

          <ECTypography>
            Updating the Approval Workflow will result in a{' '}
            <b>Reset to all Pending Invoices and Proposals</b>, would you like
            to Proceed?
          </ECTypography>

          <ECBox mt={2} display="flex" gap={2} justifyContent="flex-end">
            <ECButton variant="text" onClick={handleCloseConfirmationModal}>
              Cancel
            </ECButton>
            <ECButton variant="contained" onClick={handleConfirmUpdate}>
              Confirm
            </ECButton>
          </ECBox>
        </ECBox>
      </ECModal>
    </>
  );
}
