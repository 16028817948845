import { ECTabContainer } from 'app/components';
import { useCustomerUser } from 'app/hooks/customerUser.use-case';
import { useTranslation } from 'react-i18next';
import { StyleConstants } from 'styles/StyleConstants';
import { ForbiddenPage } from '../ForbiddenPage';
import { Outlet } from 'react-router';
import { useMemo } from 'react';
import { P } from 'types/Permission';

export function AccountingPage() {
  const { t } = useTranslation();
  const selectedTab = useMemo(() => {
    const pageTabOptions = ['exports', 'gl-codes'];
    const curPath = window.location.href.split('/').pop();
    const curPathWithoutParams = curPath?.split('?')[0];

    if (curPathWithoutParams && pageTabOptions.includes(curPathWithoutParams)) {
      return curPathWithoutParams;
    }
    return pageTabOptions[0];
  }, []);

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }
  return (
    <ECTabContainer
      tabsAsLinks
      mt={StyleConstants.NAV_BAR_HEIGHT}
      p={2}
      variant="standard"
      color="marketingSuggestion"
      noPadding
      selectedTab={selectedTab}
      tabs={[
        {
          value: 'exports',
          link: 'exports',
          label: t('translation:panelDrawer.exports'),
          content: <Outlet />,
          scopes: [P.ViewLambdaExports],
        },
        {
          value: 'gl-codes',
          link: 'gl-codes',
          label: t('translation:panelDrawer.generalLedgerCodes'),
          content: <Outlet />,
          scopes: [P.GetGlCodes],
        },
        // {
        //   value: 'budget-settings',
        //   link: 'budget-settings',
        //   label: t('translation:panelDrawer.budgetSettings'),
        //   content: <WIPPage />,
        // },
        // {
        //   value: 'approval-workflow',
        //   link: 'approval-workflow',
        //   label: t('translation:panelDrawer.approvalWorkflow'),
        //   content: <Outlet />,
        // },
      ]}
    />
  );
}
