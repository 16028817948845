import { ECDynamicPageTemplate } from 'app/components';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  organizationApi,
  useCreateRegionsDistrictsMutation,
  useGetDistrictsQuery,
  useGetRegionsQuery,
  useLazyGetRegionsDistrictsQuery,
  useUpdateRegionsDistrictsMutation,
} from 'services/organizationApi';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import { historyApi } from 'services/historyApi';
import { ActivityType } from 'types/Activity';
import { themes } from 'styles/theme/themes';

const formConfigCreate = require('./form_config_create.json');
const formConfigEdit = require('./form_config_edit.json');
const formConfigDetails = require('./form_config_details.json');

export const RegionsDistrictsPage = ({ marginTop = true }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [row, setRow] = useState<any>({});

  useEffect(() => {
    const regionField = formConfigCreate.fields.find(
      field => field.fieldName === 'region',
    );
    if (regionField) {
      regionField.useQuery = useGetRegionsQuery;
      regionField.obAlias = 'orgnztn.name';
    }
  }, []);

  useEffect(() => {
    const districtsField = formConfigCreate.fields.find(
      field => field.fieldName === 'districts',
    );
    if (districtsField) {
      districtsField.useQuery = useGetDistrictsQuery;
      districtsField.obAlias = 'orgnztn.name';
      districtsField.queryParams = { noRegionParent: true };
    }
  }, []);

  useEffect(() => {
    const districtsField = formConfigEdit.fields.find(
      field => field.fieldName === 'districts',
    );
    if (districtsField) {
      districtsField.useQuery = useGetDistrictsQuery;
      districtsField.obAlias = 'orgnztn.name';
      districtsField.queryParams = { noRegionParent: true };
    }
  }, []);

  const [
    doAdd,
    {
      data: dataAdd,
      isError: isErrorAdd,
      error: errorAdd,
      isLoading: isLoadingAdd,
      isSuccess: isSuccessAdd,
      reset: resetAdd,
    },
  ] = useCreateRegionsDistrictsMutation();

  const [
    doUpdate,
    {
      data: dataUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
      isLoading: isLoadingUpdate,
      isSuccess: isSuccessUpdate,
      reset: resetUpdate,
    },
  ] = useUpdateRegionsDistrictsMutation();

  const useCreateMutation = useCallback(() => {
    const create = async data => {
      doAdd({
        regionId: data?.region?.id,
        districtIds: _.map(data?.districts, 'id'),
      });
    };

    return [
      create,
      {
        data: dataAdd,
        isError: isErrorAdd,
        error: errorAdd,
        isLoading: isLoadingAdd,
        isSuccess: isSuccessAdd,
        reset: resetAdd,
      },
    ];
  }, [
    dataAdd,
    errorAdd,
    isErrorAdd,
    isLoadingAdd,
    isSuccessAdd,
    resetAdd,
    doAdd,
  ]);

  const useUpdateMutation = useCallback(() => {
    const update = async data => {
      const districtIdsToAdd = _.difference(data.districts, row?.districts);

      const districtIdsToRemove = _.difference(row?.districts, data.districts);

      doUpdate({
        regionId: row?.id,
        districtIdsToAdd: _.map(districtIdsToAdd, 'id'),
        districtIdsToRemove: _.map(districtIdsToRemove, 'id'),
      });
    };
    return [
      update,
      {
        data: dataUpdate,
        isError: isErrorUpdate,
        error: errorUpdate,
        isLoading: isLoadingUpdate,
        isSuccess: isSuccessUpdate,
        reset: resetUpdate,
      },
    ];
  }, [
    dataUpdate,
    errorUpdate,
    isErrorUpdate,
    isLoadingUpdate,
    isSuccessUpdate,
    row,
    resetUpdate,
    doUpdate,
  ]);

  useEffect(() => {
    if (!isLoadingAdd && isSuccessAdd) {
      dispatch(organizationApi.util.invalidateTags(['Districts']));
      dispatch(historyApi.util.invalidateTags(['History']));
    }
  }, [isLoadingAdd, isSuccessAdd]);

  useEffect(() => {
    if (!isLoadingUpdate && isSuccessUpdate) {
      dispatch(organizationApi.util.invalidateTags(['Districts']));
      dispatch(historyApi.util.invalidateTags(['History']));
    }
  }, [isLoadingUpdate, isSuccessUpdate]);

  return (
    <>
      <ECDynamicPageTemplate
        shouldCacheLazyQuery
        pageTitle={t('translation:pages.locations.regionsDistricts')}
        useLazyGetListQuery={useLazyGetRegionsDistrictsQuery}
        useCreateMutation={useCreateMutation}
        useUpdateMutation={useUpdateMutation}
        editFormConfig={formConfigEdit.config}
        editFormFields={formConfigEdit.fields}
        createFormConfig={formConfigCreate.config}
        createFormFields={formConfigCreate.fields}
        detailsConfig={formConfigDetails.config}
        detailsFields={formConfigDetails.fields}
        onRowClick={setRow}
        onDrawerClose={null as any}
        marginTop={marginTop}
        showOnlyDetailsTab
        showActivityButton
        shouldNotUseActiveFilter
        activityType={ActivityType.Organization}
        drawerTitleBarBGColor={themes.light.palette.other.divider}
        showDrawerDetailTitle={false}
      />
    </>
  );
};
