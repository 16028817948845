import { ECBox } from '../ECBox';
import { ECDivider } from '../ECDivider';
import { ECTypography } from '../ECTypography';
import { WorkOrderCard } from 'types/WorkOrders';
import { ECWorkflowStatusBadge } from '../ECWorkflowStatusBadge';
import { ECChipListTableCell } from '../ECTable/ECChipTableCell';
import { formatDateForTable } from 'utils/strings/formatDate';
import _ from 'lodash';
import { ECStack } from '../ECStack';
import { ECButton } from '../ECButton';

interface ECAssetWorkOrderCardProps {
  workOrder: WorkOrderCard;
  onClickViewButton?: (asset: WorkOrderCard) => void;
}

export const ECAssetWorkOrderCard = ({
  workOrder,
  onClickViewButton,
}: ECAssetWorkOrderCardProps) => {
  const renderAttribute = (name: string, value?: string) => {
    if (!value) {
      return;
    }

    return (
      <ECBox display="flex" my={1}>
        <ECTypography fontWeight="bold">{name}:&nbsp;</ECTypography>

        <ECTypography variant="body1">{value}</ECTypography>
      </ECBox>
    );
  };

  const handleClick = () => {
    onClickViewButton?.(workOrder);
  };

  if (!workOrder) {
    return <></>;
  }

  return (
    <ECBox p={1} key={workOrder.id}>
      <ECBox
        display="flex"
        borderColor={theme => theme.palette.other.divider}
        boxShadow={2}
        p={1}
        border="1px"
        borderRadius={2}
        bgcolor={theme => theme.palette.common.white}
        sx={theme => ({
          transition: 'transform 0.2s, box-shadow 0.2s',
          ':hover': {
            boxShadow: 1,
            transform: 'scale(1.001)',
            border: `1px solid ${theme.palette.button.hover}`,
            backgroundColor: theme.palette.table.selectedRow,
            '.view-button': {
              opacity: 1,
              pointerEvents: 'auto',
            },
          },
        })}
      >
        <ECBox display="flex" flexDirection="column" width="100%">
          <ECStack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            sx={{ paddingLeft: '1rem' }}
          >
            <ECTypography ml={2} variant="h6">
              WO ID #{workOrder.id}
            </ECTypography>
            <ECButton
              variant="text"
              onClick={handleClick}
              className="view-button"
              sx={{
                opacity: 0,
                transition: 'opacity 0.2s',
                pointerEvents: 'none',
              }}
            >
              View
            </ECButton>
          </ECStack>
          <ECDivider />

          <ECBox display="flex" px={2} py={1}>
            <ECBox display="flex" flexDirection="column" flex={1}>
              {renderAttribute(
                'ETA',
                formatDateForTable(
                  (_.isNil(workOrder.eta)
                    ? workOrder.originalEta
                    : workOrder.eta) ?? '',
                ),
              )}
              {renderAttribute('NTE', `$${workOrder.nte?.toFixed(2)}`)}
              {renderAttribute('Age of Work Order', workOrder.ageOfWorkOrder)}
              {renderAttribute('Problem', workOrder.request?.problem?.name)}
            </ECBox>

            <ECBox display="flex" flexDirection="column" flex={1}>
              {workOrder.workflowStatus?.name && (
                <ECBox mt={1}>
                  <ECWorkflowStatusBadge
                    status={workOrder.workflowStatus?.name}
                  />
                </ECBox>
              )}
              {renderAttribute(
                'Service Provider',
                workOrder.serviceProvider?.name || 'Unassigned',
              )}
              {renderAttribute('Category', workOrder.request?.category?.name)}
              {!!workOrder?.flags?.length && (
                <ECBox>
                  <ECChipListTableCell
                    col={{ dataPath: 'flags', chipTitleDataPath: 'label' }}
                    customWidthCollapseChips={150}
                    row={workOrder}
                    renderAsTableCell={false}
                  />
                </ECBox>
              )}
            </ECBox>
          </ECBox>
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
