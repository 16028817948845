import { ECTypography } from '../ECTypography';
import { ECBox } from '../ECBox';
import { useMemo } from 'react';

export interface Step {
  id: number;
  name: string;
  subLabel?: string;
  isSelected?: boolean;
  showDivider?: boolean;
  onClick?: (name: number) => void;
  subLabelBold?: boolean;
  showOnlyIsSelectedSubLabel?: boolean;
  disabledBackgroundColor?: string;
  hasBreakLineName?: boolean;
}

export const ECStepItem = ({
  id,
  name,
  subLabel,
  isSelected,
  showDivider,
  onClick,
  subLabelBold,
  showOnlyIsSelectedSubLabel = false,
  disabledBackgroundColor,
  hasBreakLineName,
}: Step) => {
  const handleClick = () => {
    onClick?.(id);
  };

  const SubLabelComponent = useMemo(
    () =>
      subLabel ? (
        <ECBox marginLeft="5px">
          <ECTypography
            variant="subtitle2"
            whiteSpace="nowrap"
            sx={{
              fontWeight: subLabelBold ? 500 : 'normal',
            }}
          >
            {subLabel}
          </ECTypography>
        </ECBox>
      ) : null,
    [subLabel, subLabelBold],
  );
  return (
    <>
      {showDivider && (
        <ECBox
          display="flex"
          width="100%"
          height="1px"
          bgcolor={theme =>
            isSelected ? theme.palette.primary.main : theme.palette.grey[400]
          }
          mx={2}
        />
      )}
      <ECBox
        sx={{ cursor: onClick ? 'pointer' : 'auto' }}
        display="flex"
        justifyContent={subLabel ? 'space-around' : 'center'}
        alignItems={subLabel ? 'center' : 'flex-start'}
        onClick={handleClick}
        flexDirection={hasBreakLineName ? 'column' : 'initial'}
        gap={hasBreakLineName ? '10px' : ''}
      >
        <ECBox
          width="24px"
          height="24px"
          borderRadius="12px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={hasBreakLineName && isSelected ? '35px' : ''}
          bgcolor={theme =>
            isSelected
              ? theme.palette.primary.main
              : disabledBackgroundColor
                ? disabledBackgroundColor
                : theme.palette.text.disabled
          }
        >
          <ECTypography
            variant="caption"
            color={theme => theme.palette.other.background}
          >
            {name}
          </ECTypography>
        </ECBox>

        {showOnlyIsSelectedSubLabel && !isSelected ? null : SubLabelComponent}
      </ECBox>
    </>
  );
};
