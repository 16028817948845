import { ECBox, ECEasyFormFieldType } from 'app/components';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { EditForm2 } from 'app/components/ECDynamicPageTemplate/EditForm2';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router';
import {
  useGetBranchByIdQuery,
  useUpdateBranchMutation,
} from 'services/branchApi';

const formElementsOfLeaseDetails = require('./fancy_form_config_lease_details.json');
const formElementsOfLeaseEdit = require('./fancy_form_config_lease_edit.json');

const processAddressUpdate = formFields => {
  const leftSideFields = formFields.find(
    field => field.groupName === 'leftSideFields',
  )?.subFields;
  const addressCountryCode1Field = leftSideFields?.find(
    field => field.fieldName === 'leaseLandlordAddress.countryCode',
  );

  const addressDetailGroup = leftSideFields?.find(
    field => field.groupName === 'addressGroup',
  )?.subFields;

  const addressCityNameField = addressDetailGroup?.find(
    field => field.fieldName === 'leaseLandlordAddress.cityName',
  );

  const addressStateProvinceField = addressDetailGroup?.find(
    field => field.fieldName === 'leaseLandlordAddress.stateProvinceCode',
  );
  const addressZipPostalCodeField = addressDetailGroup?.find(
    field => field.fieldName === 'leaseLandlordAddress.zipCodePostalCode',
  );
  const canUpdateAddressFields = !!(
    addressCityNameField &&
    addressStateProvinceField &&
    addressZipPostalCodeField &&
    addressCountryCode1Field
  );

  return {
    canUpdateAddressFields,
    addressCountryCode1Field,
    addressCityNameField,
    addressStateProvinceField,
    addressZipPostalCodeField,
    formFields,
  };
};

export function LeasePage() {
  const props: any = useOutletContext();
  const { id } = useParams();

  const isLeaseTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('activity') &&
    !window.location.href.includes('details');

  const { data: branch, isSuccess: isBranchSuccess } = useGetBranchByIdQuery(
    { id: Number(id) },
    { skip: !id || !isLeaseTab },
  );

  const [doUpdate, { data, isError, error, isSuccess, reset, isLoading }] =
    useUpdateBranchMutation();

  const [editFormField, setEditFormField] = useState<ECEasyFormFieldType[]>(
    formElementsOfLeaseEdit.fields,
  );

  const updateLocationLease = useCallback(() => {
    const doUpdateBranch = async data => {
      if (
        data?.leaseOption1PeriodCode === -1 ||
        _.isNil(data?.leaseOption1PeriodCode) ||
        data?.leaseOption1Value === '0' ||
        data?.leaseOption1Value === ''
      ) {
        data.leaseOption1PeriodCode = null;
        data.leaseOption1Value = null;
      }
      if (
        data?.leaseOption2PeriodCode === -1 ||
        _.isNil(data?.leaseOption2PeriodCode) ||
        data?.leaseOption2Value === '0' ||
        data?.leaseOption2Value === ''
      ) {
        data.leaseOption2PeriodCode = null;
        data.leaseOption2Value = null;
      }
      doUpdate?.(data);
    };
    return [
      doUpdateBranch,
      { data, isError, error, isSuccess, reset, isLoading },
    ];
  }, [data, isError, error, isSuccess, reset, isLoading, doUpdate]);

  const handleOutputChange = useCallback(
    (output: ECEasyFormFieldType[], fieldName?: string, valueChange?: any) => {
      const isCountryCodeFieldName =
        fieldName === 'leaseLandlordAddress.countryCode';
      if (
        fieldName === 'leaseLandlordAddress.line1' ||
        isCountryCodeFieldName
      ) {
        const {
          canUpdateAddressFields,
          addressCityNameField,
          addressCountryCode1Field,
          addressStateProvinceField,
          addressZipPostalCodeField,
        } = processAddressUpdate(output);
        if (!!valueChange && canUpdateAddressFields) {
          addressCityNameField.required = true;
          addressStateProvinceField.required = true;
          addressCountryCode1Field.required = true;
          addressZipPostalCodeField.required = true;
        } else if (canUpdateAddressFields && !valueChange) {
          addressCityNameField.required = false;
          addressStateProvinceField.required = false;
          addressZipPostalCodeField.required = false;
          addressCountryCode1Field.required = true;
          !isCountryCodeFieldName && (addressCountryCode1Field.value = null);
        }
      }
    },
    [],
  );

  const handleEditButtonClick = useCallback(
    isEditing => {
      if (isEditing && branch) {
        const updatedFormFields = [...editFormField];

        const {
          canUpdateAddressFields,
          addressCityNameField,
          addressCountryCode1Field,
          addressStateProvinceField,
          addressZipPostalCodeField,
        } = processAddressUpdate(updatedFormFields);
        if (canUpdateAddressFields) {
          if (branch.leaseLandlordAddress?.line1) {
            addressCityNameField.required = true;
            addressStateProvinceField.required = true;
            addressCountryCode1Field.required = true;
            addressZipPostalCodeField.required = true;
          } else {
            addressCityNameField.required = false;
            addressStateProvinceField.required = false;
            addressCountryCode1Field.required = false;
            addressZipPostalCodeField.required = false;
          }

          setEditFormField(updatedFormFields);
        }
      }
    },
    [branch, editFormField],
  );

  return branch ? (
    <EditForm2
      row={branch}
      showTitle={false}
      formConfig={formElementsOfLeaseEdit.config}
      formFields={editFormField}
      detailsConfig={formElementsOfLeaseDetails.config}
      detailsFields={formElementsOfLeaseDetails.fields}
      useUpdateMutation={updateLocationLease}
      onChange={handleOutputChange}
      hideToolBar
      checkEqualFields={false}
      showEditButton={props?.onlyEdit}
      onlyEdit={props?.onlyEdit}
      onEditButtonClick={handleEditButtonClick}
      onClose={() => {
        props?.onClose();
      }}
      {...props}
    />
  ) : (
    <ECBox display="flex" justifyContent="center">
      <ECCircularProgress size={50} sx={{ marginTop: '100px' }} />
    </ECBox>
  );
}
