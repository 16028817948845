import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { P } from 'types/Permission';
import { StatusGroupNames } from 'types/StatusGroups';
import { BulkApprovalStatusUpdateAction } from 'types/WorkflowStatus';

import { ECButton } from 'app/components/ECButton';
import { ECEasyForm } from 'app/components/ECForm';
import {
  useUpdateMultipleStatusMutation,
  dashboardApi,
} from 'services/dashboardApi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { DashboardActionProps } from 'types/Dashboard';

const form = require('./cancel_form.json');

function CancelContent({
  onClose,
  onCancel,
  selectedRows,
  module,
  queryTag,
  invalidateQuery,
  customAction,
  isLoadingCustomAction,
}: DashboardActionProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [doUpdate, { data: updateStausData, isSuccess, isError, isLoading }] =
    useUpdateMultipleStatusMutation();

  const [clearAction, setClearAction] = useState<() => void>();

  const handleSubmit = useCallback(
    (formData, fieldData, clearOnSuccess?: () => void) => {
      const note = fieldData.find(data => data.fieldName === 'note')?.value;
      if (customAction) {
        customAction(note);
        setClearAction(() => clearOnSuccess);
      } else {
        const itemIds = selectedRows.map(row => row.id);

        const statusUpdateList: BulkApprovalStatusUpdateAction = {
          itemIds,
          statusGroupName: StatusGroupNames.Cancelled,
          note,
        };
        doUpdate({ module, statusUpdateList });
        setClearAction(() => clearOnSuccess);
      }
    },
    [doUpdate, module, selectedRows, customAction],
  );

  useEffect(() => {
    clearAction?.();
    const failedCount = updateStausData?.failed?.length;
    const successCount = updateStausData?.success?.length;
    const failedIds = updateStausData?.failed?.map(item => item.id);

    if (isSuccess && !isLoading && failedCount === 0 && successCount > 0) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `${successCount} ${module}(s) were cancelled.`,
        }),
      );
      if (queryTag) {
        dispatch(dashboardApi.util.invalidateTags([queryTag]));
      }
      invalidateQuery?.();
      onClose();
    } else if (isSuccess && !isLoading && failedCount > 0 && successCount > 0) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: `${successCount} ${module}(s) were cancelled. ${failedIds?.join(
            ', ',
          )} ${module}(s) couldn't be cancelled.`,
        }),
      );
      if (queryTag) {
        dispatch(dashboardApi.util.invalidateTags([queryTag]));
      }
      onClose();
    } else if (
      isSuccess &&
      !isLoading &&
      failedCount > 0 &&
      successCount === 0
    ) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: `${failedIds?.join(
            ', ',
          )} ${module}(s) couldn't be cancelled.`,
        }),
      );
      if (queryTag) {
        dispatch(dashboardApi.util.invalidateTags([queryTag]));
      }
      onClose();
    } else if (isError && !isLoading) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: `Error cancelling some ${module}s`,
        }),
      );
      onClose();
    }
  }, [dispatch, isError, isLoading, isSuccess, onClose]);

  const formConfig = useMemo(() => {
    if (selectedRows?.length === 1) {
      return {
        ...form.config,
        title: 'Cancel Work Order',
        subtitle:
          'The selected work order will be canceled. Please confirm if you would like to proceed.',
      };
    } else {
      return form.config;
    }
  }, [selectedRows]);

  return (
    <ECEasyForm
      pattern="modal"
      config={formConfig}
      fields={form.fields}
      isSendingData={isLoading || (isLoadingCustomAction as boolean)}
      isLoadingForm={false}
      onFormSubmit={handleSubmit}
      additionalActions={
        <ECButton
          type="button"
          variant="text"
          sx={{ height: 42 }}
          onClick={onCancel}
        >
          {t('translation:dynamicForm.cancel')}
        </ECButton>
      }
      drawerTitleBarBGColor="transparent"
    />
  );
}

export const cancelAction = {
  buttonLabel: 'Cancel',
  disabledRule: (selectionLength: number) => selectionLength < 1,
  modalContent: requirePermissionWrapper<DashboardActionProps>(CancelContent),
  scopes: [P.ChangeWorkorderStatus],
};
