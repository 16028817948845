import { ECBox } from 'app/components/ECBox';
import { StyleConstants } from 'styles/StyleConstants';
import { ECTableLayoutProps } from 'types/core/ECTable';
import { ECTableLayoutFooter } from './ECTableLayoutFooter';
import { ECTableLayoutHeader } from './ECTableLayoutHeader';
import { themes } from 'styles/theme/themes';
import { useRef } from 'react';
import { TableSkeletonContainer } from './ECTableSkeleton';

/**
 * Component responsible for the sizing and placement
 * of the componenents of the table
 */
export const ECTableLayout: React.FC<ECTableLayoutProps> = ({
  pagination,
  onPaginationChange,
  children,
  hidePagination,
  hidePerPageSelector,
  isLoading,
  headerChildren,
  isEmptyState,
  isSendingRequest,
  disableTableContent,
  hideHeaderContent,
  afterPaginationChildren,
  beforePaginationChildren,
  belowPaginationChildren,
  marginTop,
  isFetching,
}) => {
  /**
   * We use those refs to know which size the main
   * table should have after evaluating the header
   * and footer content
   */
  const headerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  return (
    <ECBox
      sx={{
        mt: marginTop ? marginTop : StyleConstants.NAV_BAR_HEIGHT,
        minWidth: 0,
        width: '100%',
        height: '100%',
        maxHeight: window.innerHeight - StyleConstants.NAV_BAR_HEIGHT_PX_VALUE,
      }}
    >
      {!hideHeaderContent && (
        <ECBox
          ref={headerRef}
          sx={{
            opacity: isLoading ? StyleConstants.OPACITY_DISABLED : '',
            transition: 'opacity 0.5s',
          }}
        >
          <ECTableLayoutHeader
            color={themes.light.palette.background.default}
            children={headerChildren}
          />
        </ECBox>
      )}
      {isLoading || isFetching ? (
        <TableSkeletonContainer
          height={
            window.innerHeight -
            StyleConstants.NAV_BAR_HEIGHT_PX_VALUE -
            (headerRef.current?.offsetHeight ?? 0) -
            (footerRef.current?.offsetHeight ?? 0)
          }
        />
      ) : (
        <ECBox
          sx={{
            boxShadow: isEmptyState ? 0 : 1,
            overflow: 'hidden',
            animation: 'fade-in 0.5s',
            height: 'auto',
            borderRadius: '12px',
            maxHeight:
              window.innerHeight -
              StyleConstants.NAV_BAR_HEIGHT_PX_VALUE -
              (headerRef.current?.offsetHeight ?? 0) -
              (footerRef.current?.offsetHeight ?? 0),
          }}
          ref={tableRef}
        >
          {children}
        </ECBox>
      )}

      <ECBox
        ref={footerRef}
        sx={{
          opacity: isLoading ? StyleConstants.OPACITY_DISABLED : '',
          transition: 'opacity 0.5s',
        }}
      >
        <ECTableLayoutFooter
          totalResults={pagination.totalResults ?? -1}
          page={pagination.page}
          perPage={pagination.perPage}
          onPaginationChange={onPaginationChange}
          disableTableContent={disableTableContent}
          hidePerPageSelector={hidePerPageSelector}
          isSendingRequest={isSendingRequest}
          afterPaginationChildren={afterPaginationChildren}
          beforePaginationChildren={beforePaginationChildren}
          belowPaginationChildren={belowPaginationChildren}
          hidePagination={hidePagination}
          isPaginated={pagination.isPaginated}
          isEmptyState={isEmptyState}
        />
      </ECBox>
    </ECBox>
  );
};
