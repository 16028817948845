import { Check, Close } from '@mui/icons-material';
import { Popover } from '@mui/material';
import {
  ECBox,
  ECButton,
  ECDynamicPageTemplate,
  ECTypography,
} from 'app/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  useAcceptServiceProviderInvitateMutation,
  useLazyGetServiceProviderReceivedInvitatesListQuery,
  useRejectServiceProviderInvitationMutation,
} from 'services/serviceProviderApi';
import { setFilterEndpoint, setSnackbar } from 'store/slice/page';
import { InvitationStatus } from 'types/ServiceProviderTypes';
import { loadPageScopes } from 'utils/pageScopes';
import { useMatch, useNavigate } from 'react-router';

export function CustomerPendingPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const match = useMatch('/panel/*');

  const dispatch = useDispatch();

  const [row, setSelectedRow] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [isAcceptModalClicked, setIsAcceptModalClicked] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    loadPageScopes('invitation');
    dispatch(setFilterEndpoint('/filter/customer-pending'));
  }, []);

  const handleCloseModal = () => {
    setAnchorEl(null);
  };

  const [
    doAcceptInvitation,
    {
      error: acceptError,
      isError: isAcceptError,
      isLoading: isAcceptLoading,
      isSuccess: isAcceptSuccess,
      reset: resetAccept,
    },
  ] = useAcceptServiceProviderInvitateMutation();

  const [
    doRejectInvitation,
    {
      error: rejectError,
      isError: isRejectError,
      isLoading: isRejectLoading,
      isSuccess: isRejectSuccess,
      reset: resetReject,
    },
  ] = useRejectServiceProviderInvitationMutation();

  const handleAccept = (row: any) => () => {
    doAcceptInvitation(row.inviteCode);
  };

  const handleReject = (row: any) => () => {
    doRejectInvitation(row.inviteCode);
  };

  useEffect(() => {
    if (isAcceptSuccess && !isAcceptLoading) {
      resetAccept();
      navigate(`${match?.pathnameBase}/sp/customer/${row.companyId}`, {
        state: { from: 'pendingPage' },
      });
    }
  }, [isAcceptSuccess, isAcceptLoading]);

  useEffect(() => {
    if (isRejectSuccess && !isRejectLoading) {
      handleCloseModal();
      resetReject();
    }
  }, [isRejectSuccess, isRejectLoading, resetReject]);

  useEffect(() => {
    if ((isRejectError && rejectError) || (isAcceptError && acceptError)) {
      handleCloseModal();
      resetReject();
      dispatch(
        setSnackbar({
          severity: 'error',
          message:
            (rejectError as any)?.data?.message ||
            (acceptError as any)?.data?.message,
        }),
      );
    }
  }, [isRejectError, rejectError, isAcceptError, acceptError]);

  const renderInvitationsActions = (row: any) => {
    return (
      <ECBox
        display="flex"
        gap={2}
        key={`${row.id}-invitations-actions-cust-pending`}
      >
        <ECButton
          variant="outlined"
          startIcon={
            <Check
              sx={{ color: theme => theme.palette.graphic.alert.success }}
            />
          }
          sx={theme => ({
            border: 1,
            borderColor: `${theme.palette.success.outlinedRestingBackground} !important`,
            color: `${theme.palette.graphic.alert.success} !important`,
          })}
          onClick={e => {
            setAnchorEl(e.currentTarget);
            setIsAcceptModalClicked(true);
            setSelectedRow(row);
          }}
        >
          {t('translation:table.actions.accept')}
        </ECButton>

        <ECButton
          variant="outlined"
          color="error"
          startIcon={
            <Close sx={{ color: theme => theme.palette.graphic.alert.error }} />
          }
          sx={theme => ({
            border: 1,
            borderColor: `${theme.palette.error.outlinedRestingBackground} !important`,
            color: `${theme.palette.graphic.alert.error} !important`,
          })}
          onClick={e => {
            setAnchorEl(e.currentTarget);
            setIsAcceptModalClicked(false);
            setSelectedRow(row);
          }}
        >
          {t('translation:table.actions.reject')}
        </ECButton>
      </ECBox>
    );
  };

  return (
    <>
      <ECDynamicPageTemplate
        shouldCacheLazyQuery
        pageTitle={t('translation:pages.customersPending.title')}
        useLazyGetListQuery={
          useLazyGetServiceProviderReceivedInvitatesListQuery
        }
        useCreateMutation={{}}
        downloadFileName="pending-customers.csv"
        showStatusActiveFilter
        // enableExport
        editFormAdditionalProcess={({ row, formConfig, formFields }) => {
          formConfig.title = row?.name;
        }}
        customParams={{
          status: InvitationStatus.Rejected,
        }}
        customActiveFilter={{
          paramName: 'status',
          value: InvitationStatus.Pending,
        }}
        statusFilterInitialSelected
        activeStatusFilterActiveLabel={'Pending'}
        activeStatusFilterInactiveLabel={'Rejected'}
        renderCustomActionComponent={renderInvitationsActions}
        marginTop={false}
        withDrawer={false}
        shouldNotUseActiveFilter
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ECBox
          boxShadow={2}
          sx={{
            position: 'relative',
            mt: '10px',
            backgroundColor: 'green',
            '&::before': {
              bgcolor: theme => theme.palette.common.white,
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 12,
              height: 12,
              top: -6,
              transform: 'rotate(45deg)',
              left: 'calc(10%)',
            },
          }}
        />
        <ECBox p={1} bgcolor="transparent" mt={-1}>
          <ECBox
            sx={theme => ({
              borderRadius: 1,
              boxShadow: 2,
              maxWidth: '444px',
              bgcolor: theme.palette.common.white,
            })}
          >
            <ECBox display="flex" flexDirection="column" p={2}>
              <ECTypography variant="h6" px={1}>
                {isAcceptModalClicked ? 'Accept' : 'Reject'} the invitation to
                perform work for {row?.custName || 'customer'}?
              </ECTypography>

              {!isAcceptModalClicked && (
                <ECTypography variant="body1" mt={3} px={1}>
                  By rejecting this invitation you will need to be reinvited by{' '}
                  {row?.custName || 'customer'} in order to be considered for
                  any new opportunities.
                </ECTypography>
              )}

              <ECBox mt={2} display="flex" gap={2} justifyContent="flex-end">
                <ECButton variant="text" onClick={handleCloseModal}>
                  Cancel
                </ECButton>
                {isAcceptModalClicked ? (
                  <ECButton
                    variant="contained"
                    sx={theme => ({
                      bgcolor: theme.palette.graphic.alert.success,
                      color: theme.palette.common.white,
                      '&:hover': {
                        bgcolor: theme.palette.success.dark,
                      },
                    })}
                    onClick={handleAccept(row)}
                  >
                    Accept
                  </ECButton>
                ) : (
                  <ECButton
                    variant="contained"
                    sx={theme => ({
                      bgcolor: theme.palette.graphic.alert.error,
                      color: theme.palette.common.white,
                      '&:hover': {
                        bgcolor: theme.palette.error.dark,
                      },
                    })}
                    onClick={handleReject(row)}
                  >
                    Reject
                  </ECButton>
                )}
              </ECBox>
            </ECBox>
          </ECBox>
        </ECBox>
      </Popover>
    </>
  );
}
