import { FilterAlt } from '@mui/icons-material';
import { ECBox } from 'app/components/ECBox';
import { ECIconButton } from 'app/components/ECIconButton';
import { ECDashboardCategoryPopoverContent } from 'app/components/ECDashboardCategoryPopoverContent';
import { ECPopover } from 'app/components/ECPopover';
import { useState } from 'react';
import { BaseType } from 'types/BaseType';
import { Category } from 'types/Category';
import { DashboardTableFilter } from 'types/Dashboard';
import { DashboardTables } from 'utils/constants';

interface ECDashboardCategoryFilterProps {
  dashboardId: DashboardTables;
  initialSelected?: number[];
  categories?: BaseType<Category[]>;
  onFiltersChange?: (filter: DashboardTableFilter) => void;
}

export const ECDashboardCategoryFilter = ({
  dashboardId,
  initialSelected = [],
  categories,
  onFiltersChange,
}: ECDashboardCategoryFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterModal = () => {
    setAnchorEl(null);
  };

  const isFilterModalOpen = Boolean(anchorEl);
  const id = isFilterModalOpen ? 'filter-popover' : undefined;

  const [selectedFilters, setSelectedFilters] =
    useState<number[]>(initialSelected);

  const handleChange = (selectedValues: number[]) => {
    setSelectedFilters(selectedValues);
    onFiltersChange?.({
      table: dashboardId,
      cat: selectedValues,
    });
  };

  return (
    <ECBox>
      <ECIconButton onClick={handleFilterClick} size="small">
        <FilterAlt />
      </ECIconButton>

      <ECPopover
        id={id}
        open={isFilterModalOpen}
        anchorEl={anchorEl}
        onClose={handleCloseFilterModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ECBox display="flex" p={2} flexDirection="column">
          <ECDashboardCategoryPopoverContent
            selectedValues={selectedFilters}
            categories={categories}
            onChange={handleChange}
          />
        </ECBox>
      </ECPopover>
    </ECBox>
  );
};
