import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetBulkActionScheduleJobQuery } from 'services/scheduleApi';

interface UseScheduledJobProps {
  id?: string;
  isLoadingJobInfo?: boolean;
  isJobInfoSuccess?: boolean;
  jobInfo?: { id: string };
}

// Polling Interval of 15000ms => 15s
const SCHEDULED_JOB_POLLING_INTERVAL = 15000;

export const useScheduledJob = ({
  id,
  isLoadingJobInfo,
  isJobInfoSuccess,
  jobInfo,
}: UseScheduledJobProps) => {
  const [jobId, setJobId] = useState(id);
  const [jobProgress, setJobProgress] = useState(0);

  const {
    data: scheduledJob,
    isSuccess: isScheduledJobSuccess,
    isFetching: isScheduledJobFetching,
    isLoading: isScheduledJobLoading,
  } = useGetBulkActionScheduleJobQuery(
    {
      id: jobId,
    },
    {
      skip: !jobId || jobProgress === 100,
      pollingInterval:
        jobProgress === 100 ? undefined : SCHEDULED_JOB_POLLING_INTERVAL,
    },
  );

  const isScheduledJobCompleted = useMemo(
    () => scheduledJob?.progress >= 100,
    [scheduledJob],
  );

  const clearJobIdAndProgress = useCallback(() => {
    setJobId(undefined);
    setJobProgress(0);
  }, []);

  useEffect(() => {
    if (!id && isJobInfoSuccess && !isLoadingJobInfo && jobInfo?.id) {
      setJobId(jobInfo?.id);
    }
  }, [isLoadingJobInfo, isJobInfoSuccess]);

  useEffect(() => {
    if (scheduledJob && jobProgress !== scheduledJob.progress) {
      setJobProgress(scheduledJob.progress);
    }
  }, [scheduledJob]);

  const showScheduledJobProgress =
    isScheduledJobSuccess ||
    (!isScheduledJobCompleted && !!jobId) ||
    (jobInfo && !jobId);

  return {
    scheduledJobData: scheduledJob?.returnvalue,
    scheduledJob,
    isScheduledJobSuccess,
    showScheduledJobProgress,
    isScheduledJobFetching,
    isScheduledJobLoading,
    isScheduledJobCompleted,
    jobId,
    setJobId,
    currentJobProgress: jobProgress,
    clearJobIdAndProgress,
  };
};
