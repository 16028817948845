import { useEffect, useState, ReactNode } from 'react';

interface ResendTimerProps {
  duration?: number; // Duration in seconds (default: 60)
  onTimerStart?: () => void; // Callback when timer starts
  children: (
    startTimer: () => void,
    timeLeft: number,
    isdisabled: boolean,
  ) => ReactNode;
}

export function ResendTimer({
  duration = 60,
  onTimerStart,
  children,
}: ResendTimerProps) {
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timeLeft]);

  const startTimer = () => {
    setTimeLeft(duration);
    onTimerStart?.();
  };

  return <>{children(startTimer, timeLeft, timeLeft > 0)}</>;
}
