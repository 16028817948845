import { emptyApi } from './emptyApi';
import {
  TimeTrackStatusChangeParams,
  TimeTrackStatusResponse,
} from 'types/TimeTrack';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['CurrentTrackTimeStatus'],
});

export const timeTrackApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    updateTimeTrackStatus: builder.mutation<void, TimeTrackStatusChangeParams>({
      query: body => {
        return {
          url: 'timetrack/status/change',
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['CurrentTrackTimeStatus'],
    }),
    getTimeTrackCurrentStatus: builder.query<TimeTrackStatusResponse, void>({
      query: () => {
        return {
          url: 'timetrack/current',
        };
      },
      providesTags: ['CurrentTrackTimeStatus'],
    }),
  }),
});

export const {
  useUpdateTimeTrackStatusMutation,
  useGetTimeTrackCurrentStatusQuery,
} = timeTrackApi;
