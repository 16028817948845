import { OrganizationIdTypeEnum } from 'types/Organization';
import { AutoCompleteDropDownOption } from 'types/User';
import { isEmptyValue } from 'utils/common';
import { DateTime } from 'luxon';
import { ActiveFilter, Context } from 'types/core/Filters';

/**
 * handler for the default case. Just get the alias, the value
 * and append it to the queryParams
 */
export const handleDefaultFilter = (
  alias: string,
  value: any,
  queryParams: Record<string, any>,
) => {
  queryParams[alias] = value;
  return 0;
};

/**
 * handler for the toggle case. Just get the alias, the value
 * and append it to the queryParams as a boolean number
 */
export const handleToggleFilter = (
  alias: string,
  value: any,
  queryParams: Record<string, any>,
  activeFilter?: ActiveFilter,
  searchIndex: number = 0,
  searchGroupIndex: number = 0,
  customParams?: Record<string, any>,
) => {
  if (value) {
    queryParams[alias] = +Boolean(value);
  }
  return 0;
};

/**
 * handler for the arraySearch. It get's the alias, the value
 * and append the default search cases to the queryParams
 * Can be expanded to include extra params through customParams
 */
export const handleArraySearchFilter = (
  alias: string,
  value: any,
  queryParams: Record<string, any>,
  activeFilter: ActiveFilter,
  searchIndex: number,
  searchGroupIndex: number,
  customParams?: Record<string, any>,
) => {
  let sIndex = searchIndex;
  if (value && Array.isArray(value)) {
    value.forEach(valueElement => {
      queryParams[`s${sIndex || ''}`] = valueElement;
      queryParams[`sb${sIndex || ''}`] = alias;
      queryParams[`sg${sIndex || ''}`] = searchGroupIndex;
      queryParams[`sglo${sIndex || ''}`] = 'or';
      sIndex += 1;
      if (customParams) {
        Object.keys(customParams).forEach(customParamKey => {
          queryParams[customParamKey] = customParams[customParamKey];
        });
      }
    });
  }
  return sIndex;
};

/**
 * Handler for days filters. If it's positive, it get's the days
 * between today and today + value. If it's negative, it
 * gets the days between today and today - value.
 */
export const handleDaysFilter = (
  alias: string,
  value: any,
  queryParams: Record<string, any>,
  activeFilter: ActiveFilter,
  searchIndex: number,
  searchGroupIndex: number,
  customParams?: Record<string, any>,
) => {
  const dateSearchGroup = customParams?.dateSearchGroup;
  queryParams[`sb${searchIndex || ''}`] = alias;
  queryParams[`sbo${searchIndex || ''}`] = 'between';
  queryParams[`s${searchIndex || ''}`] =
    value > 0
      ? `${DateTime.now().toFormat('yyyy-MM-dd')},${DateTime.now().plus({ days: value }).toFormat('yyyy-MM-dd')}`
      : `${DateTime.now().plus({ days: value }).toFormat('yyyy-MM-dd')},${DateTime.now().toFormat('yyyy-MM-dd')}`;
  queryParams[`sg${searchIndex || ''}`] = dateSearchGroup || searchGroupIndex;
  queryParams[`sglo${searchIndex || ''}`] = 'or';
  return 1;
};

/**
 * Handles the default date search. Should be triggered by
 * the date range filter, since it's common to all date searchs
 * and has the final filter based on the value of the other
 * filters, as dateAfter, dateBefore, dateAt, etc.
 */
export const handleDefaultDateSearchFilter = (
  alias: string,
  value: any,
  queryParams: Record<string, any>,
  activeFilter: ActiveFilter,
  searchIndex: number,
  searchGroupIndex: number,
  customParams?: Record<string, any>,
) => {
  let sIndex = searchIndex;
  if (!activeFilter) return sIndex;
  const dateSearchGroup = customParams?.dateSearchGroup;
  const dateAliasFromProp = customParams?.dateAlias;
  const isMongoDBSearch = !!dateSearchGroup;
  if (activeFilter.dateAfter && !activeFilter.dateBefore) {
    queryParams[`sb${sIndex || ''}`] = activeFilter.dateAlias;
    queryParams[`sbo${sIndex || ''}`] = isMongoDBSearch ? 'gt' : 'gte';
    queryParams[`s${sIndex || ''}`] = isMongoDBSearch
      ? DateTime.fromJSDate(new Date(activeFilter.dateAfter))
          .set({ hour: 23, minute: 59 })
          .toMillis()
      : DateTime.fromJSDate(new Date(activeFilter.dateAfter)).toFormat(
          'yyyy-MM-dd',
        );
    queryParams[`sg${sIndex || ''}`] = dateSearchGroup || searchGroupIndex;
    queryParams[`sglo${sIndex || ''}`] = 'or';
    sIndex += 1;
  }
  if (!activeFilter.dateAfter && activeFilter.dateBefore) {
    queryParams[`sb${sIndex || ''}`] =
      activeFilter.dateAlias || dateAliasFromProp;
    queryParams[`sbo${sIndex || ''}`] = isMongoDBSearch ? 'lt' : 'lte';
    queryParams[`s${sIndex || ''}`] = isMongoDBSearch
      ? DateTime.fromJSDate(new Date(activeFilter.dateBefore))
          .set({ hour: 0, minute: 0 })
          .toMillis()
      : DateTime.fromJSDate(new Date(activeFilter.dateBefore)).toFormat(
          'yyyy-MM-dd',
        );
    queryParams[`sg${sIndex || ''}`] = dateSearchGroup || searchGroupIndex;
    queryParams[`sglo${sIndex || ''}`] = 'or';
    sIndex += 1;
  }
  if (
    activeFilter.dateAfter &&
    activeFilter.dateBefore &&
    DateTime.fromJSDate(new Date(activeFilter.dateAfter)).toFormat(
      'yyyy-MM-dd',
    ) !==
      DateTime.fromJSDate(new Date(activeFilter.dateBefore)).toFormat(
        'yyyy-MM-dd',
      )
  ) {
    if (
      isMongoDBSearch &&
      DateTime.fromJSDate(new Date(activeFilter.dateAfter)).isValid &&
      DateTime.fromJSDate(new Date(activeFilter.dateBefore)).isValid
    ) {
      queryParams[`sb${sIndex || ''}`] =
        activeFilter.dateAlias || dateAliasFromProp;
      queryParams[`sbo${sIndex || ''}`] = 'gt';
      queryParams[`s${sIndex || ''}`] = queryParams[`s${searchIndex || ''}`] =
        DateTime.fromJSDate(new Date(activeFilter.dateAfter))
          .set({ hour: 0, minute: 0 })
          .toMillis();
      queryParams[`sg${sIndex || ''}`] = dateSearchGroup || searchGroupIndex;
      queryParams[`sglo${sIndex || ''}`] = 'or';
      sIndex += 1;

      queryParams[`sb${sIndex || ''}`] =
        activeFilter.dateAlias || dateAliasFromProp;
      queryParams[`sbo${sIndex || ''}`] = 'lt';
      queryParams[`s${sIndex || ''}`] = DateTime.fromJSDate(
        new Date(activeFilter.dateBefore),
      )
        .set({ hour: 23, minute: 59 })
        .toMillis();
      queryParams[`sg${sIndex || ''}`] = dateSearchGroup || searchGroupIndex;
      queryParams[`sglo${sIndex || ''}`] = 'or';
    } else {
      queryParams[`sb${sIndex || ''}`] =
        activeFilter.dateAlias || dateAliasFromProp;
      queryParams[`sbo${sIndex || ''}`] = 'between';
      queryParams[`s${sIndex || ''}`] =
        `${DateTime.fromJSDate(new Date(activeFilter.dateAfter)).toFormat('yyyy-MM-dd')},${DateTime.fromJSDate(new Date(activeFilter.dateBefore)).toFormat('yyyy-MM-dd')}`;
      queryParams[`sg${sIndex || ''}`] = dateSearchGroup || searchGroupIndex;
      queryParams[`sglo${sIndex || ''}`] = 'or';
    }
    sIndex += 1;
  }
  if (
    activeFilter.dateAt ||
    (activeFilter.dateAfter &&
      activeFilter.dateBefore &&
      DateTime.fromJSDate(new Date(activeFilter.dateAfter)).toFormat(
        'yyyy-MM-dd',
      ) ===
        DateTime.fromJSDate(new Date(activeFilter.dateBefore)).toFormat(
          'yyyy-MM-dd',
        ))
  ) {
    const date =
      activeFilter.dateAt ||
      activeFilter.dateAfter ||
      (activeFilter.dateBefore as string | Date);
    if (isMongoDBSearch) {
      queryParams[`sb${sIndex || ''}`] =
        activeFilter.dateAlias || dateAliasFromProp;
      queryParams[`sbo${sIndex || ''}`] = 'gt';
      queryParams[`s${sIndex || ''}`] = DateTime.fromJSDate(new Date(date))
        .set({ hour: 0, minute: 0 })
        .toMillis();
      queryParams[`sg${sIndex || ''}`] = dateSearchGroup || searchGroupIndex;
      queryParams[`sglo${sIndex || ''}`] = 'or';
      sIndex += 1;

      queryParams[`sb${sIndex || ''}`] =
        activeFilter.dateAlias || dateAliasFromProp;
      queryParams[`sbo${sIndex || ''}`] = 'lt';
      queryParams[`s${sIndex || ''}`] = DateTime.fromJSDate(new Date(date))
        .set({ hour: 23, minute: 59 })
        .toMillis();
      queryParams[`sg${sIndex || ''}`] = dateSearchGroup || searchGroupIndex;
      queryParams[`sglo${sIndex || ''}`] = 'or';
    } else {
      queryParams[`sb${sIndex || ''}`] =
        activeFilter.dateAlias || dateAliasFromProp;
      queryParams[`s${sIndex || ''}`] =
        `${DateTime.fromJSDate(new Date(date)).toFormat('yyyy-MM-dd')},${DateTime.fromJSDate(new Date(date)).toFormat('yyyy-MM-dd')}`;
      queryParams[`sbo${sIndex || ''}`] = 'between';
      queryParams[`sg${sIndex || ''}`] = dateSearchGroup || searchGroupIndex;
      queryParams[`sglo${sIndex || ''}`] = 'or';
    }
    sIndex += 1;
  }
  return sIndex;
};

/**
 * Handler for filters that it's values should be used against
 * the company filter search
 */
export const handleCompanyFilter = (
  alias: string,
  value: any,
  queryParams: Record<string, any>,
  activeFilter?: ActiveFilter,
  searchIndex: number = 0,
  searchGroupIndex: number = 0,
  customParams?: Record<string, any>,
) => {
  if (value) {
    queryParams[`cf${searchIndex || ''}`] = value;
  }
  return 0;
};

/**
 * Specific handler for the hierarchy filter. It's a special case.
 */
export const handleHierarchyFilter = (
  alias: string,
  value: any,
  queryParams: Record<string, any>,
  activeFilter?: ActiveFilter,
  searchIndex: number = 0,
  searchGroupIndex: number = 0,
  customParams?: Record<string, any>,
) => {
  let sIndex = searchIndex;
  if (!activeFilter) return sIndex;
  queryParams[`sb${sIndex || ''}`] = 'prrt.id';

  const regionParentIds = activeFilter.hierarchy
    ?.filter(org => org.organizationType === OrganizationIdTypeEnum.Region)
    ?.map(org => org.nodeId);
  const districtParentIds = activeFilter.hierarchy
    ?.filter(org => org.organizationType === OrganizationIdTypeEnum.District)
    ?.map(org => org.nodeId);

  if (regionParentIds?.length) {
    queryParams.regionParentIds = regionParentIds;
  }
  if (districtParentIds?.length) {
    queryParams.districtParentIds = districtParentIds;
  }
  sIndex += 1;
  return sIndex;
};

/**
 * Handles the search fields filter, setting the search params
 * for each field in the given array
 */
export const handleSearchFieldsFilter = (
  alias: string,
  searchQuery: any,
  queryParams: Record<string, any>,
  activeFilter?: ActiveFilter,
  searchIndex: number = 0,
  searchGroupIndex: number = 0,
  customParams?: Record<string, any>,
  context?: Context,
) => {
  let sIndex = searchIndex;
  if (!activeFilter) return sIndex;
  const filterFields = activeFilter?.filterFields || [];
  const fields = (context?.columns || []).filter(col =>
    Boolean(col.searchable),
  );
  const searchableFields = [...filterFields, ...fields];
  searchableFields?.forEach(
    ({ exactSearch, alias, isViewId, searchGroup, value, fieldName }) => {
      const toSearch = value ?? searchQuery;
      const aliasToUse = alias ?? fieldName;
      if (toSearch && !isEmptyValue(toSearch)) {
        (Array.isArray(aliasToUse) ? aliasToUse : [aliasToUse])?.forEach(
          aliasName => {
            queryParams[`sb${sIndex || ''}`] = aliasName;

            // usedValue is the simpleSearchQuery
            let usedSearchValue = String(
              (toSearch as AutoCompleteDropDownOption)?.id ?? toSearch,
            );
            /* If the field is a view id, we need to remove the parts that are not part of the real id. Example: WO-135 -> real id is 13*/
            if (isViewId && usedSearchValue?.match(/^\w+-\d+$/)) {
              usedSearchValue = usedSearchValue.replaceAll(/\D/g, '');
              usedSearchValue = usedSearchValue.substring(
                0,
                usedSearchValue.length - 1,
              );
            }
            const isMongoDBSearch = !!searchGroup;
            // If it is not an exact search, will use a partial search (LIKE %value%)
            if (!exactSearch && !isViewId && !isMongoDBSearch) {
              usedSearchValue = encodeURI(`*${usedSearchValue}*`);
            }
            if (activeFilter.isAdvanced && isMongoDBSearch) {
              queryParams['slo'] = 'and';
            }

            const SPACE_URL_ENCODING = '%20';
            const usedValueArray =
              usedSearchValue[0] === '*'
                ? usedSearchValue
                    ?.slice(1, usedSearchValue.length - 1)
                    ?.split(',')
                    ?.filter(val => val)
                : usedSearchValue
                    ?.replaceAll(';', ',')
                    ?.split(',')
                    ?.filter(val => val);
            if (usedValueArray?.length > 1) {
              usedValueArray.forEach((usedValueElement, index) => {
                const isOnlyNumbers = usedValueElement
                  ?.replace(SPACE_URL_ENCODING, '')
                  .match(/^\d+$/);
                if (isOnlyNumbers && !exactSearch) {
                  return;
                }

                queryParams[`sb${sIndex || ''}`] = (
                  toSearch as AutoCompleteDropDownOption
                )?.id
                  ? `${aliasName}.id`
                  : aliasName;
                const searchValue =
                  usedValueElement?.slice(0, 3) === SPACE_URL_ENCODING
                    ? usedValueElement?.slice(3)
                    : usedValueElement;
                queryParams[`s${sIndex || ''}`] =
                  exactSearch || isMongoDBSearch
                    ? `${searchValue?.trim()}`
                    : `*${searchValue?.trim()}*`;
                queryParams[`sg${sIndex || ''}`] =
                  searchGroup || searchGroupIndex;
                queryParams[`sglo${sIndex || ''}`] = 'or';
                sIndex += 1;
              });
            } else {
              // workaround to include fields below in search even if they are all numbers
              // Transforming the values into an array and checking didnt work
              const isStoreNumberSearch = aliasName?.includes('storeNumber'); // hotfix for search while the query performance is not improved in the BE.
              const isInventorySearch = aliasName?.includes('invntry');
              const isInvoiceNumberSearch =
                aliasName?.includes('invoiceNumber');
              const isPMIdSearch = aliasName?.includes('pmId');
              const isQrCodeSearch = aliasName?.includes('qrCode');
              const isSearialNumberSearch = aliasName?.includes('serialNumber');
              const isPhoneNumberSearch = aliasName?.includes('phone');
              const isGlCodeSearch = aliasName?.includes('glCode');
              const isBranchNameSearch = aliasName?.includes('brnch.name');
              const isOnlyNumbers = usedSearchValue
                ?.replaceAll('*', '')
                .match(/^\d+$/);
              if (
                isOnlyNumbers &&
                !exactSearch &&
                !activeFilter.isAdvanced &&
                // !globalQuery &&
                !isGlCodeSearch &&
                !isStoreNumberSearch &&
                !isInventorySearch &&
                !isInvoiceNumberSearch &&
                !isPMIdSearch &&
                !isQrCodeSearch &&
                !isSearialNumberSearch &&
                !isPhoneNumberSearch &&
                !isBranchNameSearch
              ) {
                return;
              }

              queryParams[`s${sIndex || ''}`] = usedSearchValue; // checks if it's an value commes from a dropdown list. in that case value is an object
              queryParams[`sg${sIndex || ''}`] =
                searchGroup || searchGroupIndex;
              queryParams[`sglo${sIndex || ''}`] = activeFilter.isAdvanced
                ? 'and'
                : 'or';
              sIndex += 1;
            }
          },
        );
      }
    },
  );
  return sIndex;
};

/**
 * Specific handler for the active status filter.
 * Needs to be split from the others because of the possible formating
 * of the statuses.
 */
export const handleActiveStatusFilter = (
  alias: string,
  value: any,
  queryParams: Record<string, any>,
  activeFilter: ActiveFilter = {},
  searchIndex: number = 0,
  searchGroupIndex: number = 0,
) => {
  let sIndex = searchIndex;
  value?.forEach(status => {
    const isDigitsOnly = text => /^\d+$/.test(text);
    const isUnshiftedNumbersArray = string => {
      const stringArray = string?.split(',');
      return (
        stringArray?.length > 1 &&
        stringArray?.every(isDigitsOnly) &&
        stringArray
      );
    };
    if (isUnshiftedNumbersArray(status)) {
      status?.split(',').forEach(statusElement => {
        queryParams[`sb${sIndex || ''}`] = alias;
        queryParams[`s${sIndex || ''}`] = isDigitsOnly(statusElement)
          ? Number(statusElement)
          : statusElement;
        queryParams[`sg${sIndex || ''}`] = searchGroupIndex;
        queryParams[`sglo${sIndex || ''}`] = 'or';
        sIndex += 1;
      });
      return;
    }

    queryParams[`sb${sIndex || ''}`] = alias;
    queryParams[`s${sIndex || ''}`] = isDigitsOnly(status)
      ? Number(status)
      : status;
    queryParams[`sg${sIndex || ''}`] = searchGroupIndex;
    queryParams[`sglo${sIndex || ''}`] = 'or';
    sIndex += 1;
  });
  return sIndex;
};
