export interface SubscriptionTrial {
  id: number;
  companyId: number;
  daysNumber: number;
  nextChargeDate: string;
  startTime: string;
}

export enum CompanySubscriptionStatusCode {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Delinquent = 'DELINQUENT',
  Suspended = 'SUSPENDED',
  BuildNoPayment = 'BUILD_NO_PAYMENT',
  Build = 'BUILD',
  Grow = 'GROW',
  Scale = 'SCALE',
  Enterprise = 'ENTERPRISE',
}

export const CompanySubscriptionStatusLabel: Record<
  CompanySubscriptionStatusCode,
  string
> = {
  [CompanySubscriptionStatusCode.Active]: 'Active',
  [CompanySubscriptionStatusCode.Inactive]: 'Inactive',
  [CompanySubscriptionStatusCode.Delinquent]: 'Delinquent',
  [CompanySubscriptionStatusCode.Suspended]: 'Suspended',
  [CompanySubscriptionStatusCode.BuildNoPayment]: 'Build No Payment',
  [CompanySubscriptionStatusCode.Build]: 'Build',
  [CompanySubscriptionStatusCode.Grow]: 'Grow',
  [CompanySubscriptionStatusCode.Scale]: 'Scale',
  [CompanySubscriptionStatusCode.Enterprise]: 'Enterprise',
};
