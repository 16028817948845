import { useLocaleCurrency } from 'app/hooks';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';

interface SpendReplacementCardProps {
  spendColor?: string | null;
  legendColors?: {
    Green: string;
    Orange: string;
    Red: string;
  } | null;
  spend?: number | null;
  replacementCost?: number | null;
}

export const SpendReplacementCard: React.FC<SpendReplacementCardProps> = ({
  spendColor,
  legendColors,
  spend,
  replacementCost,
}) => {
  const maxValue = Math.max(spend ?? 0, replacementCost ?? 0);
  const spendPercentage = ((spend ?? 0) / maxValue) * 100;
  const replacementPercentage = ((replacementCost ?? 0) / maxValue) * 100;

  const { currencySymbol = '$' } = useLocaleCurrency() ?? {};

  return (
    <ECBox
      borderRadius={4}
      p={2}
      bgcolor="white"
      display="flex"
      flexDirection="column"
      width={'100%'}
      mb={2}
    >
      <ECTypography variant="h5" fontWeight={400} color="#111928">
        Spend vs Replacement
      </ECTypography>
      <ECTypography variant="body1" fontWeight={400} color="#6B7280" mt={1}>
        The difference between Total Spend and Replacement Cost.
      </ECTypography>
      <ECBox mt={2}>
        <ECBox display="flex" alignItems="center">
          <ECTypography
            minWidth={100}
            variant="body2"
            fontWeight="bold"
            color="#6B7280"
            mr={2}
          >
            Replacement
            <br />
            Cost
          </ECTypography>
          <ECBox
            sx={{
              width: `${replacementPercentage}%`,
              minWidth: '.1%',
              backgroundColor: theme =>
                theme.palette.primary.outlinedRestingBackground,
              height: 40,
              borderRadius: '4px',
            }}
          />
          <ECTypography
            variant="body2"
            fontWeight="bold"
            color="#6B7280"
            ml={2}
          >
            {`${currencySymbol} ${replacementCost?.toLocaleString() ?? ''}`}
          </ECTypography>
        </ECBox>

        <ECBox display="flex" alignItems="center" mt={2}>
          <ECTypography
            minWidth={100}
            variant="body2"
            fontWeight="bold"
            color="#6B7280"
            mr={2}
          >
            Spend
          </ECTypography>
          <ECBox
            sx={{
              width: `${spendPercentage}%`,
              minWidth: '.1%',
              backgroundColor: spendColor,
              height: 40,
              borderRadius: '4px',
            }}
          />
          <ECTypography
            variant="body2"
            fontWeight="bold"
            color="#6B7280"
            ml={2}
          >
            {`${currencySymbol} ${spend?.toLocaleString() ?? ''}`}
          </ECTypography>
        </ECBox>
      </ECBox>

      <ECBox sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
        <ECBox sx={{ display: 'flex', alignItems: 'center', margin: '0 1rem' }}>
          <ECBox
            sx={{
              width: 10,
              height: 10,
              backgroundColor: legendColors?.Green,
              marginRight: 1,
              borderRadius: '50%',
            }}
          />
          <ECTypography color="#6B7280" variant="body2">
            Repair
          </ECTypography>
        </ECBox>
        <ECBox sx={{ display: 'flex', alignItems: 'center', margin: '0 1rem' }}>
          <ECBox
            sx={{
              width: 10,
              height: 10,
              backgroundColor: legendColors?.Orange,
              marginRight: 1,
              borderRadius: '50%',
            }}
          />
          <ECTypography color="#6B7280" variant="body2">
            Plan Replacement
          </ECTypography>
        </ECBox>
        <ECBox sx={{ display: 'flex', alignItems: 'center', margin: '0 1rem' }}>
          <ECBox
            sx={{
              width: 10,
              height: 10,
              backgroundColor: legendColors?.Red,
              marginRight: 1,
              borderRadius: '50%',
            }}
          />
          <ECTypography color="#6B7280" variant="body2">
            Replace
          </ECTypography>
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
