import { ECBox, ECButton, ECImage } from 'app/components';
import findServiceImage from './assets/findService.png';
import howItWorksImage from './assets/howItWorks.png';
import BuildIcon from '@mui/icons-material/Build';
import { useCallback } from 'react';

const imageStyles = {
  width: '100%',
  maxHeight: '-webkit-fill-available',
  borderRadius: '8px',
  objectFit: 'cover',
};

export default function Banners() {
  const handleRedirect = useCallback(() => {
    window.location.replace('service-providers/sp-directory');
  }, []);

  return (
    <ECBox>
      <ECBox sx={{ mt: 2 }}>
        <ECImage sx={imageStyles} alt="How It Works" src={howItWorksImage} />
      </ECBox>

      <ECBox
        sx={{
          mt: 2,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ECImage
          sx={imageStyles}
          alt="Find a Service Provider"
          src={findServiceImage}
        />
        <ECButton
          variant="contained"
          startIcon={<BuildIcon />}
          onClick={handleRedirect}
          sx={{
            position: 'absolute',
            top: '72%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: 48,
            width: 300,
          }}
        >
          Service Provider Directory
        </ECButton>
      </ECBox>
    </ECBox>
  );
}
