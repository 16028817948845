import { DateTime } from 'luxon';

const parseDate = (
  input: number | string | Date | null | undefined,
  fallbackFormats: string[] = ['MM/dd/yyyy, hh:mm a', 'MM/dd/yyyy'],
): DateTime | null => {
  if (!input) return null;

  if (typeof input === 'number') return DateTime.fromMillis(input);
  if (input instanceof Date) return DateTime.fromJSDate(input);
  if (typeof input === 'string') {
    let dt = DateTime.fromISO(input);
    if (!dt.isValid) {
      for (const format of fallbackFormats) {
        dt = DateTime.fromFormat(input, format);
        if (dt.isValid) break;
      }
    }
    return dt.isValid ? dt : null;
  }

  return null;
};

export const formatDateForTable = (
  rawDate: string | Date | null | undefined,
  format = 'MMM d, yyyy hh:mm a',
): string => {
  if (!rawDate) return '';

  let dt: DateTime;

  if (rawDate instanceof Date) {
    dt = DateTime.fromJSDate(rawDate);
  } else if (typeof rawDate === 'string') {
    dt = DateTime.fromISO(rawDate);
    if (!dt.isValid) {
      dt = DateTime.fromFormat(rawDate, 'MM/dd/yyyy, hh:mm a');
    }
    if (!dt.isValid) {
      dt = DateTime.fromFormat(rawDate, 'MM/dd/yyyy');
    }
  } else {
    return '';
  }

  return dt.isValid ? dt.toFormat(format) : '';
};

export const formatDateOnlyForTable = (
  input: string | Date | number,
  useFullMonth?: boolean,
): string => {
  const dt = parseDate(input);
  return dt
    ? dt.toFormat(useFullMonth ? 'MMMM d, yyyy' : 'MMM d, yyyy')
    : 'Not available';
};

export const formatDateForComment = (input: number | string | Date): string => {
  const dt = parseDate(input);
  return dt ? dt.toFormat('MMMM d, yyyy hh:mm a') : '';
};

export const formatDateForHistory = (input: number | string | Date): string => {
  const dt = parseDate(input);
  return dt ? dt.toFormat('MMMM d, yyyy hh:mm a') : '';
};

export const formatDateForCancelSubscription = (
  input: number | string | Date,
): string => {
  const dt = parseDate(input);
  return dt ? dt.toFormat('MM/dd/yyyy') : '';
};

export const formatAgeForTable = (input: number | string | Date): string => {
  const dt = parseDate(input);
  return dt ? dt.toRelative() ?? '' : '';
};

// new figma designs are pointing to that format, we should check what should be used;
export const formatDateForTable_2 = (
  input: string | Date | number,
  withHours = true,
): string => {
  const dt = parseDate(input);
  const formatStr = `dd/MM/yyyy${withHours ? ' hh:mm a' : ''}`;
  return dt ? dt.toFormat(formatStr) : '-';
};

export const isoDateWithTimezoneAtZero = (
  input: string | Date | number | null | undefined,
): string => {
  const dt = parseDate(input);
  return dt ? dt.toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : '';
};
