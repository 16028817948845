import { ECBox, ECStack } from 'app/components';
import React, { useState, useEffect, useMemo } from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import SectorToggleButton from './SectorToggleButton';
import { useTranslation } from 'react-i18next';

interface IndustrialSectorProps {
  onChange?: (value: any) => void;
  value?: any | null;
}

export const IndustrialSector: React.FC<IndustrialSectorProps> = ({
  onChange,
  value,
}) => {
  const { t } = useTranslation();
  const sectorTypes = useMemo(
    () => ({
      Restaurant: {
        label: t('translation:IndustrySectorTypes.Restaurant.label'),
        subTitle: t('translation:IndustrySectorTypes.Restaurant.subTitle'),
        code: 'RESTAURANT',
      },
      ConvenienceStores: {
        label: t('translation:IndustrySectorTypes.ConvenienceStores.label'),
        subTitle: t(
          'translation:IndustrySectorTypes.ConvenienceStores.subTitle',
        ),
        code: 'CONVENIENCE_STORES',
      },
      Retail: {
        label: t('translation:IndustrySectorTypes.Retail.label'),
        subTitle: t('translation:IndustrySectorTypes.Retail.subTitle'),
        code: 'RETAIL',
      },
      Grocery: {
        label: t('translation:IndustrySectorTypes.Grocery.label'),
        subTitle: t('translation:IndustrySectorTypes.Grocery.subTitle'),
        code: 'GROCERY',
      },
      CarWash: {
        label: t('translation:IndustrySectorTypes.CarWash.label'),
        subTitle: t('translation:IndustrySectorTypes.CarWash.subTitle'),
        code: 'CAR_WASH',
      },
    }),
    [t],
  );

  const [selectedSector, setSelectedSector] = useState<
    (typeof sectorTypes)[keyof typeof sectorTypes]
  >(sectorTypes.Restaurant);

  useEffect(() => {
    if (value && value !== selectedSector?.code) {
      const sector = Object.values(sectorTypes).find(
        sector => sector.code === value,
      );
      setSelectedSector(sector || sectorTypes.Restaurant);
    }
  }, [value]);

  useEffect(() => {
    onChange?.(selectedSector?.code);
  }, [selectedSector]);

  const handleSectorChange = sector => {
    setSelectedSector(sector);
  };

  return (
    <ECBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%', padding: '0 16px 0 16px' }}
    >
      <ECStack
        width="100%"
        mt={2}
        direction="column"
        spacing={0}
        sx={{ paddingRight: 5 }}
      >
        {Object.keys(sectorTypes)?.map(sector => {
          const sectorName = sectorTypes[sector]?.label;
          return (
            <SectorToggleButton
              key={sectorName}
              value={sectorTypes[sector]?.code}
              selected={selectedSector?.label === sectorName}
              onClick={() => handleSectorChange(sectorTypes[sector])}
              icon={
                sectorName === sectorTypes.Restaurant.label ? (
                  <LunchDiningIcon />
                ) : sectorName === sectorTypes.ConvenienceStores.label ? (
                  <ShoppingBasketIcon />
                ) : sectorName === sectorTypes.Retail.label ? (
                  <LocalOfferIcon />
                ) : sectorName === sectorTypes.Grocery.label ? (
                  <ShoppingCartIcon />
                ) : (
                  <LocalCarWashIcon />
                )
              }
              title={sectorName}
              subtitle={sectorTypes[sector].subTitle}
            />
          );
        })}
      </ECStack>
    </ECBox>
  );
};
