import { Stack } from '@mui/material';
import {
  DateRangeFilterType,
  ECDateRangePicker,
} from 'app/components/ECDateTimePicker';
import { ECMenuItem, ECSelect } from 'app/components/ECSelect';
import { FilterFieldOption } from 'app/components/ECTable';
import { compact } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { setActiveFilter } from 'store/slice/page';

type ECDateFilterProps = {
  dateFilterFieldsOptions: FilterFieldOption[];
  dateRangeDefaultSelectedOption?: string;
};

export const ECDateFilter: React.FC<ECDateFilterProps> = ({
  dateFilterFieldsOptions,
  dateRangeDefaultSelectedOption,
}) => {
  const dispatch = useDispatch();

  const { activeFilter: activeFilterFromSelector, endpoint } = useSelector(
    (state: RootState) => state.page.filter,
  );
  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[endpoint];
  }, [window.location.href, activeFilterFromSelector]);

  const [createdDateRange, setCreatedDateRange] = useState<{
    dateRange: any[];
    dateRangeFilterType?: DateRangeFilterType;
    dateAlias: string;
    dateAliasLabel: string;
    selectedOption?: string;
  }>({
    dateRange: compact([
      activeFilter?.dateAt ||
        activeFilter?.dateAfter ||
        activeFilter?.dateBefore,
      activeFilter?.dateBefore || activeFilter?.dateAfter,
    ]),
    dateRangeFilterType:
      activeFilter?.dateRangeFilterType || DateRangeFilterType.Between,
    dateAlias:
      activeFilter?.dateAlias || dateFilterFieldsOptions?.[0]?.fieldName || '',
    dateAliasLabel:
      activeFilter?.dateAliasLabel || dateFilterFieldsOptions?.[0]?.label || '',
    selectedOption:
      activeFilter?.selectedOption ||
      dateRangeDefaultSelectedOption ||
      'All time',
  });

  useEffect(() => {
    let dateRange = {};
    if (createdDateRange?.dateRangeFilterType === DateRangeFilterType.Between) {
      dateRange = {
        dateAt: null,
        dateAfter: createdDateRange?.dateRange?.[0]?.toString(),
        dateBefore: createdDateRange?.dateRange?.[1]?.toString(),
      };
    } else if (
      createdDateRange?.dateRangeFilterType === DateRangeFilterType.After
    ) {
      dateRange = {
        dateAt: null,
        dateAfter: createdDateRange?.dateRange?.[0]?.toString(),
        dateBefore: null,
      };
    } else if (
      createdDateRange?.dateRangeFilterType === DateRangeFilterType.Before
    ) {
      dateRange = {
        dateAt: null,
        dateAfter: null,
        dateBefore: createdDateRange?.dateRange?.[0]?.toString(),
      };
    } else if (
      createdDateRange?.dateRangeFilterType === DateRangeFilterType.Exact
    ) {
      dateRange = {
        dateAt: createdDateRange?.dateRange?.[0]?.toString(),
        dateAfter: null,
        dateBefore: null,
      };
    } else {
      dateRange = {
        dateAt: null,
        dateAfter: null,
        dateBefore: null,
      };
    }

    dispatch(
      setActiveFilter({
        ...dateRange,
        dateAlias: createdDateRange?.dateAlias || activeFilter?.dateAlias,
        dateAliasLabel:
          createdDateRange?.dateAliasLabel || activeFilter?.dateAliasLabel,
        dateRangeFilterType:
          createdDateRange?.dateRangeFilterType ||
          activeFilter?.dateRangeFilterType,
        selectedOption:
          createdDateRange?.selectedOption || activeFilter?.selectedOption,
        filterPath: endpoint,
      }),
    );
  }, [createdDateRange]);

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      width="392px"
      height="40px"
      border="1px solid #E0E0E0"
      borderRadius="4px"
      display="flex"
      justifyContent="space-around"
    >
      {!!dateFilterFieldsOptions?.length && (
        <ECSelect
          defaultValue={
            createdDateRange?.dateAlias ||
            dateFilterFieldsOptions?.[0]?.fieldName
          }
          variant="standard"
          MenuProps={{
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            anchorOrigin: {
              vertical: 40,
              horizontal: 110,
            },
          }}
          disableUnderline
          onChange={e => {
            setCreatedDateRange(prev => ({
              ...prev,
              dateAliasLabel:
                dateFilterFieldsOptions?.find(
                  option => option.fieldName === e.target.value,
                )?.label || '',
              dateAlias: e.target.value as string,
            }));
          }}
        >
          {dateFilterFieldsOptions?.map((option, index) => (
            <ECMenuItem
              value={option.fieldName}
              key={`${option.fieldName}-${option.label}`}
              id={`${option.fieldName}-${option.label}`}
            >
              {option.label}
            </ECMenuItem>
          ))}
        </ECSelect>
      )}
      <ECDateRangePicker
        defaultSelectedOption={
          createdDateRange?.selectedOption || dateRangeDefaultSelectedOption
        }
        defaultSelectedDates={createdDateRange?.dateRange}
        onChange={(dateRange, dateRangeFilterType, optionLabel) => {
          setCreatedDateRange(prev => ({
            ...prev,
            dateRange,
            dateRangeFilterType,
            selectedOption: optionLabel || prev.selectedOption,
          }));
        }}
      />
    </Stack>
  );
};
