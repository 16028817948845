import * as _ from 'lodash';
import { ECBox } from '../ECBox';
import { ECStack } from '../ECStack';

export interface ECBulletPointsProps {
  bulletPointColor?: 'primary' | 'secondary';
  children: any;
}

export const ECBulletPoints = ({
  bulletPointColor = 'primary',
  children,
}: ECBulletPointsProps) => {
  const cleanedChildren: JSX.Element[] = _.flatten(_.compact(children));

  return (
    <>
      <ECStack dir="column">
        {cleanedChildren.map((child, index) => (
          <ECBox display="flex">
            <ECStack dir="column" mr={2}>
              <ECBox
                bgcolor={theme =>
                  index === 0 ? 'transparent' : theme.palette.grey[400]
                }
                width="1px"
                display="flex"
                flex={2}
                maxHeight={`${child?.props?.mt * 8}px`}
                minHeight={`${child?.props?.mt * 8}px`}
                mr={2}
                ml={child?.props?.hideBulletPoint ? 0.7 : 0}
                alignSelf="center"
              />
              <ECBox
                bgcolor={theme =>
                  index === 0 ? 'transparent' : theme.palette.grey[400]
                }
                width="1px"
                display="flex"
                flex={2}
                mr={2}
                ml={child?.props?.hideBulletPoint ? 0.7 : 0}
                alignSelf="center"
              />
              {!child?.props?.hideBulletPoint && (
                <ECBox
                  height={12}
                  width={12}
                  borderRadius={6}
                  bgcolor={theme => theme.palette[bulletPointColor]?.main}
                  mr={2}
                  my={1}
                />
              )}
              <ECBox
                bgcolor={theme =>
                  index === cleanedChildren.length - 1
                    ? 'transparent'
                    : theme.palette.grey[400]
                }
                width="1px"
                display="flex"
                flex={2}
                mr={2}
                ml={child?.props?.hideBulletPoint ? 0.7 : 0}
                alignSelf="center"
              />
            </ECStack>

            {child}
          </ECBox>
        ))}
      </ECStack>
    </>
  );
};
