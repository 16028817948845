import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ECButton, ECEasyForm } from 'app/components';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { setSnackbar } from 'store/slice/page';
import { P } from 'types/Permission';
import { QueryTag } from '.';
import * as _ from 'lodash';
import { useWorkOrderActionClickMutation } from 'services/workOrdersApi';
import { dashboardApi } from 'services/dashboardApi';
import { normalizeTextWithMention } from '../../../components/ECRichTextField';
import { StatusGroupNames } from '../../../../types/StatusGroups';
import { isEmptyHtmlForm } from '../../../../utils/validate-fields';
import { DashboardActionProps } from 'types/Dashboard';
const fancyFormNotFixedWO = require('../form/fancy_form_config_not_fixed_workorder.json');

function NotFixedContent({
  onClose,
  onCancel,
  queryTag,
  module,
  selectedRows,
  invalidateQuery,
  onSubmit,
  workflowNextStatus,
}: DashboardActionProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [doUpdate, { isSuccess, isError, isLoading }] =
    useWorkOrderActionClickMutation();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const [clearAction, setClearAction] = useState<() => void>();

  const handleSubmit = useCallback(
    (formData, fieldData, clearOnSuccess?: () => void) => {
      const id = selectedRows[0]?.id;
      const addCommentField = fieldData.find(data => data.fieldName === 'note');

      const note = addCommentField?.comment;
      const normalizedTextWithMention = normalizeTextWithMention(note);

      const recipientIds = addCommentField?.selectedUsers?.map(
        user => user.nodeId,
      );

      const notFixedStatusTo = workflowNextStatus?.find(
        status => status.statusGroupName === StatusGroupNames.NotFixed,
      )?.statusTo;

      if (!notFixedStatusTo) {
        setSnackbar({
          severity: 'error',
          message: 'Not fixed action not found. Please try again.',
        });
        return;
      }

      doUpdate({
        id,
        statusTo: notFixedStatusTo,
        note: normalizedTextWithMention,
        recipientIds,
      });
      setClearAction(clearOnSuccess);
    },
    [selectedRows, doUpdate, workflowNextStatus, module],
  );

  useEffect(() => {
    const addCommentField = fancyFormNotFixedWO.fields.find(
      data => data.fieldName === 'note',
    );

    addCommentField.moduleId = selectedRows?.[0]?.id;
  }, []);

  useEffect(() => {
    clearAction?.();
    if (isSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Successfully updated work order status',
        }),
      );
      if (queryTag) {
        dispatch(
          dashboardApi.util.invalidateTags(_.flatten(queryTag) as QueryTag[]),
        );
      }
      invalidateQuery?.();
      onSubmit?.();
      onClose();
    } else if (isError) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: `Failed to update WO ${selectedRows[0]?.id} status`,
        }),
      );
      onClose();
    }
  }, [dispatch, isError, isSuccess, onClose]);

  return (
    <ECEasyForm
      pattern="modal"
      config={fancyFormNotFixedWO.config}
      fields={fancyFormNotFixedWO.fields}
      isSendingData={isLoading}
      isLoadingForm={false}
      saveButtonDisabled={saveButtonDisabled}
      onChange={(_output, _fieldName, value) => {
        const isCommentEmpty = isEmptyHtmlForm(value.comment);

        setSaveButtonDisabled(isCommentEmpty);
      }}
      onFormSubmit={handleSubmit}
      saveButtonColor="error"
      additionalActions={
        <ECButton
          type="button"
          variant="text"
          sx={{ height: 42 }}
          onClick={onCancel}
        >
          {t('translation:dynamicForm.cancel')}
        </ECButton>
      }
    />
  );
}

export const notFixedStatusAction = {
  buttonLabel: 'Not Fixed',
  disabledRule: (selection: number) => selection !== 1,
  modalContent: requirePermissionWrapper<DashboardActionProps>(NotFixedContent),
  scopes: [P.ChangeWorkorderStatus],
};
