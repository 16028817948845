import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { P } from 'types/Permission';
import {
  ActiveFilter,
  Context,
  GlobalFilter,
  PageScopes,
  PageState,
  Snackbar,
} from 'types/core/Filters';

export const GLOBAL_SEARCH_MODULE_OPTIONS = [
  {
    label: 'All',
  },
  {
    label: 'Work Orders',
  },
  {
    label: 'Proposals',
  },
  {
    label: 'Invoices',
  },
];

export const enum GlobalSearchModules {
  All = 0,
  WorkOrders = 1,
  Proposals = 2,
  Invoices = 3,
}

export const GLOBAL_SEARCH_MODULE_OPTION_VALUES = [
  GlobalSearchModules.All,
  GlobalSearchModules.WorkOrders,
  GlobalSearchModules.Proposals,
  GlobalSearchModules.Invoices,
];

export const initialState: PageState = {
  scopes: {
    canGetAll: P.Unset,
    canAdd: P.Unset,
    canEdit: P.Unset,
    canDelete: P.Unset,
    canGetById: P.Unset,
  },
  title: '',
  filter: {
    activeFilter: {},
    tableLayout: {},
    endpoint: '',
  },
  globalFilter: {
    module: 0,
    globalQuery: '',
  },
  snackbar: {
    id: '',
    severity: 'error',
    message: '',
  },
  context: {},
};

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },
    setActiveFilter(state, action: PayloadAction<ActiveFilter>) {
      const path = action.payload.filterPath || window.location.href;

      const pathWithoutParams = path.split('?')?.[0];
      const storedFilter = state.filter.activeFilter?.[pathWithoutParams] || {};
      const newActiveFilter = {
        ...storedFilter,
        ...action.payload,
        // if name is not passed here the previous name is kept on the screen
        // yes we can use spread operator but just for clarity for other developersw
        name: action.payload.name ?? storedFilter.name,
        isEdited: action.payload.isEdited ?? false,
        isAdvanced: action.payload.isAdvanced ?? storedFilter.isAdvanced,
        filterFields: action.payload.filterFields ?? storedFilter.filterFields,
        isSelectedAllHierarchy:
          action.payload.isSelectedAllHierarchy ??
          storedFilter.isSelectedAllHierarchy,
        isSelectAllAssetTypes:
          action.payload.isSelectAllAssetTypes ??
          storedFilter.isSelectAllAssetTypes,
        isSelectAllLocations:
          action.payload.isSelectAllLocations ??
          storedFilter.isSelectAllLocations,
        isSelectAllManufacturers:
          action.payload.isSelectAllManufacturers ??
          storedFilter.isSelectAllManufacturers,
        isSelectAllInternalTechs:
          action.payload.isSelectAllInternalTechs ??
          storedFilter.isSelectAllInternalTechs,
        categories:
          action.payload.categories === null
            ? action.payload.categories
            : action.payload.categories ?? storedFilter.categories,
        selfPerforming:
          action.payload.selfPerforming ?? storedFilter.selfPerforming,
        assignedToMe: action.payload.assignedToMe ?? storedFilter.assignedToMe,
        assignedLocations:
          action.payload.assignedLocations ?? storedFilter.assignedLocations,
        missingVendorId:
          action.payload.missingVendorId ?? storedFilter.missingVendorId,
        assetType: action.payload.assetType ?? storedFilter.assetType,
        dateAlias: action.payload.dateAlias ?? storedFilter.dateAlias,
        statusAlias: action.payload.statusAlias ?? storedFilter.statusAlias,
        endpoint: action.payload.endpoint ?? storedFilter.endpoint,
        concepts: action.payload.concepts ?? storedFilter.concepts,
        isSelectAllConcepts:
          action.payload.isSelectAllConcepts ??
          storedFilter.isSelectAllConcepts,
        setBy: action.payload.setBy ?? storedFilter.setBy,
      };

      state.filter.activeFilter = {
        ...state.filter.activeFilter,
        [pathWithoutParams]: { ...newActiveFilter },
      };
    },
    setActiveTableLayout(state, action: PayloadAction<ActiveFilter>) {
      state.filter.tableLayout = { ...action.payload };
    },
    setCleanActiveFilter(state) {
      state.filter.activeFilter = { ...initialState.filter.activeFilter };
    },
    setGlobalFilter(state, action: PayloadAction<GlobalFilter>) {
      const newGlobalFilter = {
        // if name is not passed here the previous name is kept on the screen
        // yes we can use spread operator but just for clarity for other developers
        module: action.payload.module ?? state.globalFilter.module,
        globalQuery:
          action.payload.globalQuery ?? state.globalFilter.globalQuery,
        mustTriggerListQuery:
          action.payload.mustTriggerListQuery ??
          state.globalFilter.mustTriggerListQuery,
      };
      state.globalFilter = newGlobalFilter;
    },
    setFilterEndpoint(
      state,
      action: PayloadAction<
        string | { endpoint: string; activeStatus?: boolean }
      >,
    ) {
      state.filter.endpoint =
        action.payload instanceof Object
          ? action.payload.endpoint
          : action.payload;
    },
    setPageScopes(state, action: PayloadAction<PageScopes>) {
      state.scopes = { ...action.payload };
    },
    setSnackbar(state, action: PayloadAction<Omit<Snackbar, 'id'>>) {
      state.snackbar = { ...action.payload, id: new Date().toString() };
    },
    /**
     * Context is used to store shared state or data between components
     * to prevent prop drilling
     * @param state
     * @param action
     */
    setPageContext(state, action: PayloadAction<Context>) {
      const path = action.payload.contextPath || window.location.pathname;
      const storedContext = state.context[path] || {};
      state.context[path] = {
        ...storedContext,
        ...action.payload,
      };
    },
  },
});

export const {
  setActiveFilter,
  setCleanActiveFilter,
  setGlobalFilter,
  setFilterEndpoint,
  setTitle,
  setPageScopes,
  setSnackbar,
  setPageContext,
} = pageSlice.actions;
export default pageSlice.reducer;
