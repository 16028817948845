import { useCallback, useEffect, useMemo } from 'react';
import { ECStack } from '../ECStack';
import { ECTypography } from '../ECTypography';
import { useDispatch } from 'react-redux';
import { setActiveFilter } from 'store/slice/page';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { ECTab, ECTabContext, ECTabList } from '../ECTabs';

interface ECSetByProps {
  setByOptions: string[];
  customTitle?: string;
}

export const ECSetBy = ({ setByOptions, customTitle }: ECSetByProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setActiveFilter({
        setBy: setByOptions?.[0],
      }),
    );
  }, []);

  const { activeFilter: activeFilterFromSelector } = useSelector(
    (state: RootState) => state.page.filter,
  );

  const activeFilter = useMemo(() => {
    return (
      activeFilterFromSelector?.[window.location.href?.split('?')?.[0]]
        ?.setBy ?? setByOptions?.[0]
    );
  }, [window.location.href, activeFilterFromSelector]);

  const handleSelectOption = useCallback(
    (e: React.SyntheticEvent, option: string) => {
      dispatch(
        setActiveFilter({
          setBy: option,
        }),
      );
    },
    [],
  );

  return (
    <ECTabContext value={activeFilter}>
      <ECStack direction="row" spacing={1} alignItems="center">
        {customTitle && (
          <ECTypography
            variant="h6"
            color={theme => theme.palette.text.primary}
          >
            {customTitle}
          </ECTypography>
        )}
        <ECTabList
          sx={theme => ({
            '.MuiTab-root': {
              border: 1,
              borderRight: 0,
              borderColor: theme => theme.palette.primary.main,
              '&:first-child': {
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
              },
              '&:last-child': {
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                borderRight: 1,
              },
              color: theme => theme.palette.primary.main,
            },
            '& .MuiTabs-indicator': {
              backgroundColor: 'transparent',
            },
            '& .Mui-selected': {
              color: theme => theme.palette.primary.main,
              backgroundColor: theme =>
                theme.palette.primary.outlinedHoverBackground,
            },
          })}
          onChange={handleSelectOption}
          variant="standard"
          aria-label="tabs container"
        >
          {setByOptions?.map((option, index) => {
            return (
              <ECTab
                key={`ECSetBy-${option}-${index}`}
                value={option}
                label={option}
              />
            );
          })}
        </ECTabList>
      </ECStack>
    </ECTabContext>
  );
};
