import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider as XLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { styled } from '@mui/material/styles';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { normalizeToDateTime } from 'utils/dates';

export const ECDatePicker = styled(DatePicker)<any>(({ theme }) => ({}));

export interface ECBasicDatePickerProps {
  value: any;
  label: string;
  onChange: (value: any) => void;
  sx?: any;
  error?: boolean | null;
  helperText?: string | null;
  fieldName?: string;
  readOnly?: boolean;
  errorMessage?: string | null;
  required?: boolean | null;
  minDate?: Date | string | null;
  maxDate?: Date | string | null;
  dateValueFormat?: string;
  disabled?: boolean;
}

export const ECBasicDatePicker = (props: ECBasicDatePickerProps) => {
  const {
    value,
    label,
    onChange,
    readOnly = false,
    error,
    errorMessage,
    required,
    minDate,
    maxDate,
    dateValueFormat = 'yyyy-MM-dd',
    disabled,
  } = props;

  const [showError, setShowError] = useState(error);
  const [dateErrorMessage, setDateErrorMessage] = useState(errorMessage);

  useEffect(() => {
    if (error) {
      setShowError(error);
    }
  }, [error]);

  useEffect(() => {
    if (errorMessage) {
      setDateErrorMessage(errorMessage);
    }
  }, [errorMessage]);

  const handleDateChange = (date: DateTime | null) => {
    onChange(date);
    setShowError(false);

    if (date === null) {
      if (required) {
        setDateErrorMessage(errorMessage);
        setShowError(true);
      }
    } else if (!date.isValid) {
      setDateErrorMessage('Date format is incorrect!');
      setShowError(true);
    }
  };

  return (
    <XLocalizationProvider dateAdapter={AdapterLuxon}>
      <ECDatePicker
        slotProps={{
          textField: {
            variant: readOnly ? 'standard' : 'filled',
            sx: { width: '100%' },
            error: showError,
            helperText: showError ? dateErrorMessage : null,
          },
        }}
        minDate={normalizeToDateTime(minDate, dateValueFormat)}
        maxDate={normalizeToDateTime(maxDate, dateValueFormat)}
        label={label}
        value={normalizeToDateTime(value, dateValueFormat)}
        onChange={handleDateChange}
        readOnly={readOnly}
        disabled={disabled}
      />
    </XLocalizationProvider>
  );
};
