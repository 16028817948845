import { useEffect, useMemo, useState } from 'react';
import { Check } from '@mui/icons-material';
import { ECBox, ECButton, ECLink, ECTypography } from 'app/components';
import { POConfirmationModal } from './POConfirmationModal';
import { P } from 'types/Permission';
import { useServiceProviderUser } from 'app/hooks';
import { PurchaseOrderStatusType } from 'app/components/ECPurchaseOrderItemsTable';
import { useCreateLinkedPurchaseOrderMutation } from 'services/purchaseOrderApi';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { ECModal } from 'app/components/ECModal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

export const SetPOToComplete = ({
  purchaseOrder,
  openCreateInvoiceDrawer,
}: {
  purchaseOrder: Record<any, any>;
  openCreateInvoiceDrawer: (data: any) => void;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [purchaseOrderConfirmation, setPurchaseOrderConfirmation] = useState({
    open: false,
    createNewPO: false,
  });

  const canShowCompleteButton = useMemo(() => {
    const hasItemsWithQuantityReceived =
      purchaseOrder?.purchaseOrderItems?.some(
        item => item.quantityReceived > 0,
      );

    return (
      hasItemsWithQuantityReceived &&
      [
        PurchaseOrderStatusType.PARTIALLY_RECONCILED,
        PurchaseOrderStatusType.RECONCILED,
      ].includes(purchaseOrder?.workflowStatusName)
    );
  }, [purchaseOrder]);

  const [doCreate, { data, isSuccess, isLoading }] =
    useCreateLinkedPurchaseOrderMutation();

  const handlePOConfirm = () => {
    setPurchaseOrderConfirmation(prev => ({ ...prev, open: false }));
    if (
      purchaseOrder.workflowStatusName ==
        PurchaseOrderStatusType.PARTIALLY_RECONCILED &&
      purchaseOrderConfirmation.createNewPO
    ) {
      doCreate(purchaseOrder.id);
    }
    if (
      !purchaseOrderConfirmation.createNewPO ||
      purchaseOrder.workflowStatusName == PurchaseOrderStatusType.RECONCILED
    ) {
      openCreateInvoiceDrawer(null);
    }
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      openCreateInvoiceDrawer(data);
    }
  }, [isSuccess]);

  return (
    <>
      <ECModal isOpen={isLoading} onClose={() => {}}>
        <ECBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
            width: '50vw',
            gap: '20px',
          }}
        >
          <ECCircularProgress size={60} />
          <ECTypography
            sx={{
              color: theme => theme.palette.primary.main,
              textWrap: 'nowrap',
            }}
          >
            Creating your New Purchase Order and Updating the existing.
          </ECTypography>
        </ECBox>
      </ECModal>
      {canShowCompleteButton && (
        <ECButton
          startIcon={
            <Check
              sx={theme => ({ color: theme.palette.graphic.alert.success })}
            />
          }
          variant="outlined"
          onClick={() => {
            setPurchaseOrderConfirmation(prev => ({
              createNewPO: true,
              open: true,
            }));
          }}
          sx={theme => ({
            color: `${theme.palette.graphic.alert.success} !important`,
            border: `1px solid  ${theme.palette.graphic.alert.success} !important`,
            marginRight: '8px',
          })}
          scopes={[P.GetPurchaseOrder, P.EditPurchaseOrder]}
        >
          Set To Complete
        </ECButton>
      )}

      <POConfirmationModal
        isOpen={purchaseOrderConfirmation.open}
        onConfirm={handlePOConfirm}
        purchaseOrder={purchaseOrder}
        closeModal={() => {
          setPurchaseOrderConfirmation(prev => ({ ...prev, open: false }));
        }}
        newPOSwitch={purchaseOrderConfirmation.createNewPO}
        setNewPOSwitch={value => {
          setPurchaseOrderConfirmation(prev => ({
            ...prev,
            createNewPO: value,
          }));
        }}
        POStatus={purchaseOrder?.workflowStatusName}
      />
    </>
  );
};
