import React, { useRef, useState } from 'react';
import { Box, Menu } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ECButton, ECButtonProps } from '../ECButton';
import { ECMenuItem } from '../ECSelect';
import { SupplierIntegration } from '../../../types/Company';

export interface ECSupplierIntegrationButtonProps extends ECButtonProps {
  siData: {
    [key: string]: SupplierIntegration;
  };
  handleOnClick: (supplierKey: string) => void;
  buttonLabel?: string;
}

export const ECSupplierIntegrationButton: React.FC<
  ECSupplierIntegrationButtonProps
> = ({
  siData,
  handleOnClick,
  buttonLabel = 'Order From Supplier',
  ...buttonProps
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = (supplierKey: string) => {
    handleOnClick(supplierKey);
    handleCloseMenu();
  };
  const enabledSuppliers = Object.entries(siData).filter(
    ([_, supplier]) => supplier.enabled,
  );

  return (
    <>
      {!!enabledSuppliers.length && (
        <ECButton
          ref={buttonRef}
          onClick={handleOpenMenu}
          {...buttonProps}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            width: 'auto',
          }}
        >
          <Box display="flex" alignItems="center">
            <Box mr={2}>{buttonLabel}</Box>
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderLeft: `1px solid`,
                borderLeftColor: theme => theme.palette.primary.main,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ArrowDropDownIcon />
            </Box>
          </Box>
        </ECButton>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            mt: 0.25,
            width: 300,
          },
        }}
      >
        {Object.entries(siData).map(([key, supplier]) => (
          <ECMenuItem key={key} onClick={() => onMenuItemClick(key)}>
            <img
              src={supplier.logo}
              alt={supplier.supplierName}
              style={{ width: 35, height: 35, marginRight: 20 }}
            />
            {supplier.title}
          </ECMenuItem>
        ))}
      </Menu>
    </>
  );
};
