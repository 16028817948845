import React, { ReactNode, useState } from 'react';
import { ECPopover } from '../ECPopover';
import { get } from 'lodash';
import { camelCaseToPhrase } from 'utils/strings/camelcase-to-phrase';
import { ECChip } from '../ECChip';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { themes } from 'styles/theme/themes';
import parse from 'html-react-parser';
import { ECTableCell } from '../ECTable';

interface Column {
  title: string;
  fieldName: string;
  dataPath: string;
}

interface ECChipTextTableCellProps {
  col: Column;
  row: any;
  customPopupHeader?: string;
  extraComponent?: ReactNode;
  shouldShowModalOnClick?: boolean;
}

const convertToString = (input: any): string => {
  if (typeof input === 'string') return input;
  if (Array.isArray(input)) {
    return input.map(convertToString).join(' ');
  }
  if (
    React.isValidElement(input) &&
    input.props &&
    typeof input.props === 'object' &&
    'children' in input.props
  ) {
    return convertToString(input.props.children);
  }
  return String(input);
};

const getStringLength = (input: any): number => {
  if (typeof input === 'string') return input.length;
  if (Array.isArray(input)) {
    return input.reduce((count, item) => count + getStringLength(item), 0);
  }
  if (
    React.isValidElement(input) &&
    input.props &&
    typeof input.props === 'object' &&
    'children' in input.props
  ) {
    return getStringLength(input.props.children);
  }

  return 0;
};

const ECChipTextTableCell: React.FC<ECChipTextTableCellProps> = ({
  col,
  row,
  customPopupHeader,
  extraComponent,
  shouldShowModalOnClick = false,
}) => {
  const [openDetails, setOpenDetails] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const rawData = get(row, col.dataPath) || '';

  const containsHTML = /<\/?[a-z][\s\S]*>/i.test(rawData);

  const parsedData = containsHTML
    ? parse(rawData.replace(/<p>|<\/p>|&nbsp;|\n/g, ''))
    : rawData.replace(/<p>|<\/p>|&nbsp;|\n/g, '');

  const data = convertToString(parsedData);

  const headerText = camelCaseToPhrase(col.dataPath?.split('.').pop() ?? '');

  const handleChipClick = event => {
    const customPopupHeaderLength = customPopupHeader?.length || 0;
    const dataLength = getStringLength(data);
    if (dataLength + customPopupHeaderLength <= 25 && !shouldShowModalOnClick)
      return;
    event.stopPropagation();

    setOpenDetails(!openDetails);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setOpenDetails(false);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ECTableCell
        key={col.title}
        scope="row"
        sx={{ height: '100%', alignItems: 'center', borderBottom: 'none' }}
      >
        <ECChip
          color={
            openDetails ? 'Dark Blue' : themes.light.palette.background.tertiary
          }
          label={customPopupHeader ? `${customPopupHeader}: ${data}` : data}
          sx={{
            color: openDetails ? 'white' : 'black',
            fontWeight: 'bold',
            height: '30px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '14rem',
          }}
          onClick={handleChipClick}
          aria-describedby="text-details-modal"
        />
      </ECTableCell>
      <ECPopover
        id={'text-details-modal'}
        open={openDetails}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <ECBox
          p={2}
          sx={{
            width: '30rem',
          }}
        >
          <ECTypography variant="h6" textTransform="capitalize">
            {headerText}
          </ECTypography>
          <ECTypography variant="body1">{data}</ECTypography>
          {extraComponent ? extraComponent : null}
        </ECBox>
      </ECPopover>
    </React.Fragment>
  );
};

export default React.memo(ECChipTextTableCell);
