import React, { useEffect, useState } from 'react';
import { P, CompanyConfigurationFields } from 'types/Permission';
import {
  hasOptionalPermission,
  hasRequiredPermission,
  hasCompanyConfiguration,
  checkPermissionScore,
} from 'app/hoc/require-permission';
import { history } from 'utils/history';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { useCompanyCustomerProfile } from 'app/hooks/useCompanyCustomerProfile.use-case';
export interface ECRequirePermissionProps {
  children: React.ReactNode;
  scopes?: P[];
  scopesOptional?: P[];
  scores?: P[];
  companyConfigScopes?: CompanyConfigurationFields[];
  redirectNoPermission?: boolean;
}

export const ECRequirePermission = (props: ECRequirePermissionProps) => {
  const {
    children,
    scopes = [],
    scores = [],
    scopesOptional = [],
    companyConfigScopes = [],
    redirectNoPermission = false,
  } = props;

  const { data, isSuccess, isFetching } = useGetUserProfileQuery(undefined, {
    pollingInterval: 1000 * 60 * 15, // 15 minutes
    skip: !window.location.pathname.includes('panel'),
  });
  const [userPermissions, setUserPermissions] = useState<string[]>([]);

  useEffect(() => {
    if (isSuccess && Array.isArray(data?.permissions)) {
      setUserPermissions(data.permissions.map(p => p.action));
    }
  }, [data, isSuccess]);

  const companyConfiguration = useCompanyCustomerProfile();

  const isPermitted =
    (!!!scores.length || checkPermissionScore(scores, data)) &&
    hasRequiredPermission({
      userPermissions,
      scopes,
    }) &&
    hasOptionalPermission({
      userPermissions,
      scopes: scopesOptional,
    }) &&
    hasCompanyConfiguration({
      companyConfiguration,
      configs: companyConfigScopes,
    });

  if (
    userPermissions &&
    userPermissions.length > 0 &&
    redirectNoPermission &&
    !isPermitted &&
    history &&
    !isFetching &&
    history.navigate
  ) {
    history.navigate('/panel/error', {
      state: { statusCode: 401 },
    });
    return <></>;
  }

  return isPermitted ? <>{children}</> : <></>;
};
