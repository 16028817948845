import { ColType, ECTableRow, ECTRCell } from 'app/components/ECTable';
import { Fragment, memo } from 'react';
import { ECTableCheckBox } from './ECTableCheckBox';
import { ECIconButton } from 'app/components/ECIconButton';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { EditableDynamicTableCell } from './ECEditableDynamicTableCell';
import { DynamicTableCell } from './ECDynamicTableCell';
import { ECCollapse } from 'app/components/ECCollapse';
import { NestedTable } from './ECNestedTable';
import { ECTableRowMemoProps } from 'types/core/ECTable';

export const ECTableRowMemo: React.FC<ECTableRowMemoProps> = memo(
  ({
    orderedColumns,
    isEditable,
    onChange,
    onRowPress,
    onDuplicatePress,
    onRemove,
    t,
    customFieldsData,
    multiSelectTable,
    allChecked,
    setIsOpenRowNumber,
    isOpenRowNumber,
    isDraggableColumns,
    setOpennedRowId,
    customEditFieldsData,
    headerBackgroundColor,
    setSearchParams,
    tableLayoutFilter,
    sort,
    setSort,
    handleCheckBoxClick,
    isDisabled,
    selectedRows,
    row,
    rowIndex,
    collapsibleCol,
  }) => {
    return (
      <Fragment key={`${row?.id}-${rowIndex}`}>
        <ECTableRow
          key={`${row?.id}-${rowIndex}`}
          sx={{
            '&:hover': {
              backgroundColor: theme => theme.palette.table.selectedRow,
              cursor: 'pointer',
            },
            borderBottom: row.shouldShowDivision ? 11 : 1,
            borderColor: theme => theme.palette.divider,
          }}
          onClick={() =>
            orderedColumns.find(col => col.type === ColType.Collapsible)
              ? setIsOpenRowNumber(isOpenRowNumber === rowIndex ? -1 : rowIndex)
              : onRowPress?.(row)
          }
        >
          {multiSelectTable && (
            <ECTRCell
              key={`${row?.id}-checkbox`}
              scope="row"
              sx={{ minWidth: 0, width: 10 }}
            >
              <ECTableCheckBox
                row={row}
                rowIndex={rowIndex}
                isDisabled={isDisabled}
                allChecked={allChecked}
                handleCheckBoxClick={handleCheckBoxClick}
                selectedRows={selectedRows}
              />
            </ECTRCell>
          )}
          {orderedColumns.map((col, index) => {
            if (col.type === ColType.Collapsible) {
              return (
                <Fragment key={`${col?.title}-${index}`}>
                  <ECTRCell
                    key={`${col?.title}-${index}`}
                    scope="row"
                    sx={{ minWidth: 0, width: 10 }}
                  >
                    <ECIconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        setIsOpenRowNumber(
                          isOpenRowNumber === rowIndex ? -1 : rowIndex,
                        );
                      }}
                    >
                      {isOpenRowNumber === rowIndex ? (
                        <KeyboardArrowUp
                          sx={theme => ({
                            color: theme.palette.primary.light,
                          })}
                        />
                      ) : (
                        <KeyboardArrowDown
                          sx={theme => ({
                            color: theme.palette.primary.light,
                          })}
                        />
                      )}
                    </ECIconButton>
                  </ECTRCell>
                </Fragment>
              );
            }
            return isEditable && !col.disabled ? (
              <EditableDynamicTableCell
                col={col}
                index={index}
                row={row}
                isNestedCell={false}
                onChange={onChange}
                customEditFieldsData={customEditFieldsData}
                key={`editable-cell-${rowIndex}-${col.title}`}
              />
            ) : (
              <DynamicTableCell
                col={col}
                index={index}
                rowIndex={rowIndex}
                row={row}
                parentRow={undefined}
                isNestedCell={false}
                onRowPress={onRowPress}
                onDuplicatePress={onDuplicatePress}
                onRemove={onRemove}
                t={t}
                customFieldsData={customFieldsData}
                key={`dynamic-cell-${rowIndex}-${col.title}`}
              />
            );
          })}
        </ECTableRow>
        {collapsibleCol && (
          <ECTableRow sx={{ backgroundColor: 'transparent' }}>
            <ECTRCell
              sx={{ p: 0, height: 'auto !important' }}
              colSpan={collapsibleCol?.colSpan}
            >
              <ECCollapse
                in={isOpenRowNumber === rowIndex}
                timeout="auto"
                unmountOnExit
              >
                <NestedTable
                  row={row}
                  cols={collapsibleCol?.nestedCols}
                  nestedColsDataPath={collapsibleCol?.dataPath}
                  tableLayoutFilter={tableLayoutFilter}
                  headerBackgroundColor={headerBackgroundColor}
                  sort={sort}
                  setSearchParams={setSearchParams}
                  setSort={setSort}
                  isDraggableColumns={isDraggableColumns}
                  setOppnenedRowId={setOpennedRowId}
                  onRowPress={onRowPress}
                  customFieldsData={customFieldsData}
                  key={`nested-cell-${rowIndex}`}
                />
              </ECCollapse>
            </ECTRCell>
          </ECTableRow>
        )}
      </Fragment>
    );
  },
);
