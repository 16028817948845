import {
  Area,
  AreaCreate,
  AreaCreateFormData,
  AreaUpdate,
  AreaUpdateFormData,
} from 'types/Area';
import { BaseType } from 'types/BaseType';
import { QueryParams } from 'types/QueryParams';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Area'],
});

export const areaApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getAreaList: builder.query<BaseType<Area[]>, { id?: number } & QueryParams>(
      {
        query: params => {
          return {
            url: 'area',
            params,
          };
        },
        transformResponse: (response: BaseType<Area[]>) => {
          const dataWithModuleName = response.data.map(row => ({
            ...row,
            moduleName: 'area',
          }));

          return {
            ...response,
            data: dataWithModuleName,
          };
        },
        providesTags: ['Area'],
        keepUnusedDataFor: 300,
      },
    ),
    createArea: builder.mutation<void, AreaCreate>({
      query: (formData: AreaCreateFormData) => {
        const { name } = formData;
        const body: AreaCreate = { name };
        return {
          url: 'area',
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['Area'],
    }),
    updateArea: builder.mutation<void, AreaUpdate>({
      query: (formData: AreaUpdateFormData) => {
        const { id, name, status } = formData;
        const body: AreaUpdate = { name, status };
        return {
          url: `area/${id}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['Area'],
    }),
    getAreaById: builder.query<Area, Area>({
      query: row => {
        const { id } = row;
        return {
          url: `area/${id}`,
        };
      },
      transformResponse: (response: Area) => {
        return {
          ...response,
          moduleName: 'area',
        };
      },
    }),
  }),
});

export const {
  useGetAreaListQuery,
  useLazyGetAreaListQuery,
  useCreateAreaMutation,
  useUpdateAreaMutation,
  useLazyGetAreaByIdQuery,
} = areaApi;
