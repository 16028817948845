import { ECBox, ECMainBox, ECNotLoggedInBar } from '..';
import { ECFooter } from '../ECFooter';

export interface ECSignUpWizardTemplateProps {
  children: React.ReactNode;
}

export const ECSignUpWizardTemplate = ({
  children,
}: ECSignUpWizardTemplateProps) => {
  return (
    <ECMainBox
      component={'main'}
      sx={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
        overflowX: 'hidden',
        backgroundColor: theme => theme.palette.background.primary,
      }}
    >
      <ECNotLoggedInBar />

      <ECBox sx={{ marginTop: '3%' }}>{children}</ECBox>

      <ECFooter />
    </ECMainBox>
  );
};
