import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ECStack } from '../ECStack';
import { ECTextField } from '../ECTextField';
import { ECTree, TreeNode } from '../ECTree';
import { ECBox } from '../ECBox';
import { ECRichTextField } from '../ECRichTextField';
import {
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
  useGetCommentListQuery,
} from 'services/commentApi';
import { ECComment } from '../ECComment';
import { ECTypography } from '../ECTypography';
import { ECCircularProgress } from '../ECCircularProgress';
import { useTranslation } from 'react-i18next';
import { P } from 'types/Permission';
import { useInView } from 'react-intersection-observer';
import {
  ArrowDropDown,
  ArrowDropUp,
  Close,
  Notifications,
} from '@mui/icons-material';
import { ECIconButton } from '../ECIconButton';
import { Popover } from '@mui/material';
import { ECTabContainer } from 'app/components';
import { findNodeById, findNode } from 'utils/tree';
import { useGetUsersByRoleNameAndModuleIdQuery } from 'services/userApi';
import _ from 'lodash';
import { useCustomerUser, useServiceProviderUser } from 'app/hooks';
import { useGetModuleDetailsByIdQuery } from 'services/moduleApi';
import { useParams } from 'react-router';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { isEmptyHtmlForm } from 'utils/validate-fields';
import { workOrdersApi } from 'services/workOrdersApi';
import { useDispatch } from 'react-redux';

const SEARCH_DEBOUNCE_TIME = 500;
const MODULES_TO_SHOW_NOTIFY_USERS = ['workorder', 'proposal', 'invoice'];
export const MODULES_TO_NOT_USE_REQUEST_ID = ['asset', 'purchase-order'];
export interface NoteTabProps {
  label?: string;
  placeholder?: string;
  onChange?: any;
  helperText?: string | null;
  fieldName?: string;
  sx?: any;
  moduleName: string;
  moduleNameToFetchDetails?: string;
  row?: any;
}

const COMMENTS_POLLING_INTERVAL = 30 * 1000; // 30 seconds
const COMMENTS_PAGE_SIZE = 5;

const NoteTabContent = ({
  label,
  placeholder,
  onChange,
  helperText,
  fieldName,
  moduleName,
  moduleNameToFetchDetails,
  row: rowFromProps,
  ...props
}: NoteTabProps) => {
  const { id = '' } = useParams();
  const { t } = useTranslation();

  const isCustomer = useCustomerUser();

  const [currentComment, setCurrentComment] = useState<any | undefined>();
  const [page, setPage] = useState(1);
  const [initialTree, setInitialTree] = useState<any[]>([]);
  const [moduleRequestId, setModuleRequestId] = useState<any>();
  const dispatch = useDispatch();
  const [tree, setTree] = useState<TreeNode[]>([
    {
      label: '',
      nodeId: 0,
      organizationType: 0,
      parentNodeId: 0,
      children: [],
    },
  ]);
  const [nodesIds, setNodesIds] = useState<number[]>([]);
  const [currentCompanyInitialTree, setCurrentCompanyInitialTree] = useState<
    any[]
  >([]);
  const [currentCompanyTree, setCurrentCompanyTree] = useState<TreeNode[]>([
    {
      label: '',
      nodeId: 0,
      organizationType: 0,
      parentNodeId: 0,
      children: [],
    },
  ]);
  const [currentCompanyNodesIds, setCurrentCompanyNodesIds] = useState<
    number[]
  >([]);
  const isServiceProvider = useServiceProviderUser();

  const { data: comments, isLoading: isLoadingComments } =
    useGetCommentListQuery(
      {
        requestId: moduleRequestId,
        module: moduleName,
        t: COMMENTS_PAGE_SIZE * page,
      },
      {
        skip: !moduleRequestId || !moduleName,
        pollingInterval: COMMENTS_POLLING_INTERVAL,
      },
    );

  const { data: moduleData, isLoading: isLoadingModuleDetails } =
    useGetModuleDetailsByIdQuery(
      {
        id: id || '',
        moduleName: `${isCustomer ? '' : 'sp/'}${
          moduleNameToFetchDetails || moduleName || ''
        }`,
      },
      {
        skip: !id,
      },
    );

  useEffect(() => {
    if (moduleData !== undefined) {
      setModuleRequestId(
        MODULES_TO_NOT_USE_REQUEST_ID.includes(moduleName)
          ? moduleData?.id
          : moduleData?.requestId,
      );
    }
  }, [moduleData]);

  const { ref, inView } = useInView();

  const {
    data: allUsers,
    isSuccess: isSuccessUsers,
    isLoading: isLoadingUsersToNotify,
  } = useGetUsersByRoleNameAndModuleIdQuery(
    {
      moduleName,
      role: 'DIRECT_NOTES',
      userType: 'CSP',
      moduleId: id,
    },
    {
      skip:
        !id ||
        !moduleName ||
        !MODULES_TO_SHOW_NOTIFY_USERS.includes(moduleName),
    },
  );

  const users = useMemo(() => {
    if (isCustomer) {
      return allUsers?.customersInfo;
    }
    return allUsers?.spUsersInfo;
  }, [allUsers, isCustomer]);

  const usersToMention = useMemo(() => {
    return [
      ...(allUsers?.customersInfo || []),
      ...(allUsers?.spUsersInfo || []),
    ].map(user => {
      return {
        id: user.id,
        name: user.fullName,
        jobTitle: user.jobTitle?.name,
      };
    });
  }, [allUsers?.customersInfo, allUsers?.spUsersInfo]);

  const otherUsers = useMemo(() => {
    if (isCustomer) {
      return allUsers?.spUsersInfo;
    }
    return allUsers?.customersInfo;
  }, [allUsers, isCustomer]);

  const notifiedCollapsedRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const handleCloseNotifiedModal = () => {
    setAnchorEl(null);
  };

  // preselected users is now returned in the comments response
  const preSelectedUsers = comments?.selectedRecipients;
  const isFetchingPreSelectedUsers = isLoadingComments;

  const [doCreate, { isLoading: isLoadingCreate }] = useCreateCommentMutation();

  const [
    doDelete,
    {
      isError: isErrorDelete,
      error: errorDelete,
      isLoading: isLoadingDelete,
      isSuccess: isSuccessDelete,
    },
  ] = useDeleteCommentMutation();

  const [
    doUpdate,
    {
      isError: isErrorUpdate,
      error: errorUpdate,
      isLoading: isLoadingUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateCommentMutation();

  useEffect(() => {
    if (preSelectedUsers?.length && !isFetchingPreSelectedUsers) {
      if (!isCustomer && !isLoadingComments && !comments?.data?.length) {
        setNodesIds([]);
      } else {
        setNodesIds(preSelectedUsers);
      }
      setCurrentCompanyNodesIds(preSelectedUsers);
    }
  }, [preSelectedUsers, isFetchingPreSelectedUsers, isLoadingCreate]);

  const selectedUsers: TreeNode[] = useMemo(() => {
    return _.compact(
      _.filter(
        nodesIds.map(nodeId => findNodeById(initialTree, nodeId)),
        node => !node?.children?.length && !!node,
      ),
    );
  }, [nodesIds, initialTree]);

  const selectedCurrentCompanyUsers: TreeNode[] = useMemo(() => {
    return _.compact(
      _.filter(
        currentCompanyNodesIds.map(nodeId =>
          findNodeById(currentCompanyInitialTree, nodeId),
        ),
        node => !node?.children?.length && !!node,
      ),
    );
  }, [currentCompanyNodesIds, currentCompanyInitialTree]);

  const handleSend = useCallback(
    comment => {
      const isEmptyHtmlComment = isEmptyHtmlForm(comment);

      if (moduleName && !isEmptyHtmlComment) {
        if (currentComment?.commentId) {
          doUpdate({
            requestId: moduleRequestId,
            module: moduleName,
            commentId: currentComment.commentId,
            body: comment,
          });
        } else {
          const recipientIds = [
            ...selectedUsers,
            ...selectedCurrentCompanyUsers,
          ]?.map(selectedUser => selectedUser.nodeId);

          doCreate({
            requestId: moduleRequestId,
            module: moduleName,
            moduleResourceId: moduleData?.id,
            body: comment,
            recipientIds,
          });
        }
        setCurrentCompanyNodesIds([]);
        setNodesIds([]);
        setCurrentComment(undefined);
        dispatch(
          workOrdersApi.util.invalidateTags([
            'WorkOrderById',
            'DispatchColumn',
          ]),
        );
      }
    },
    [
      moduleName,
      moduleData?.id,
      moduleRequestId,
      doCreate,
      doUpdate,
      selectedUsers,
      selectedCurrentCompanyUsers,
      currentComment,
      dispatch,
    ],
  );

  const handleDelete = (commentId: string) => () => {
    setCurrentComment(undefined);
    if (moduleName) {
      doDelete({
        requestId: moduleRequestId,
        module: moduleName,
        commentId,
      });
    }
  };

  const handleEdit = (commentId: string, body: string) => () => {
    setCurrentComment({ commentId, body });
  };

  useEffect(() => {
    if (inView) {
      setPage(prevPage => prevPage + 1);
    }
  }, [inView]);

  const mappedCurrentCompanyTree: TreeNode[] = useMemo(() => {
    const usersGroupedByJobTitle = _.chain(users)
      .filter(user => !!user?.jobTitleId)
      .groupBy('jobTitle.id')
      .map((value, key) => ({
        jobTitle: value[0].jobTitle,
        users: value,
      }))
      .value();

    return usersGroupedByJobTitle.map(usersByJobTitle => {
      const nodeId = usersByJobTitle.jobTitle?.id
        ? new Date().getTime() + usersByJobTitle.jobTitle.id
        : 0;

      return {
        label: `${usersByJobTitle.jobTitle?.name} (${usersByJobTitle.users.length})`,
        nodeId,
        parentNodeId: undefined,
        children: usersByJobTitle.users.map(user => ({
          label: `${user.firstName} ${user.lastName}`,
          data: {
            email: user.email,
          },
          nodeId: user.id,
          parentNodeId: nodeId,
          children: [],
        })),
      };
    });
  }, [users]);

  const mappedTree: TreeNode[] = useMemo(() => {
    const usersGroupedByJobTitle = _.chain(otherUsers)
      .filter(user => !!user?.jobTitleId)
      .groupBy('jobTitle.id')
      .map((value, key) => ({
        jobTitle: value[0].jobTitle,
        users: value,
      }))
      .value();

    return usersGroupedByJobTitle.map(usersByJobTitle => {
      const nodeId = usersByJobTitle.jobTitle?.id
        ? new Date().getTime() + usersByJobTitle.jobTitle.id
        : 0;

      return {
        label: `${usersByJobTitle.jobTitle?.name} (${usersByJobTitle.users.length})`,
        nodeId,
        parentNodeId: undefined,
        children: usersByJobTitle.users.map(user => ({
          label: `${user.firstName} ${user.lastName}`,
          data: {
            email: user.email,
          },
          nodeId: user.id,
          parentNodeId: nodeId,
          children: [],
        })),
      };
    });
  }, [otherUsers]);

  const allOtherCompanyNodesIds = useMemo(() => {
    return _.flatMap(_.flatMap(mappedTree, 'children'), 'nodeId');
  }, [mappedTree]);

  useEffect(() => {
    if (
      isCustomer &&
      !!comments?.config &&
      !comments?.config?.pagination?.totalCount
    ) {
      setNodesIds(allOtherCompanyNodesIds);
    }
  }, [isCustomer, isLoadingComments, comments, allOtherCompanyNodesIds]);

  useEffect(() => {
    if (
      !isCustomer &&
      !!comments?.config &&
      !comments?.config?.pagination?.totalCount
    ) {
      setNodesIds([]);
    }
  }, [isCustomer, isLoadingComments, comments, mappedCurrentCompanyTree]);

  useEffect(() => {
    if (mappedTree && isSuccessUsers) {
      setTree(mappedTree);
      setInitialTree(mappedTree);
    }
  }, [isSuccessUsers, mappedTree]);

  useEffect(() => {
    if (mappedCurrentCompanyTree && isSuccessUsers) {
      setCurrentCompanyTree(mappedCurrentCompanyTree);
      setCurrentCompanyInitialTree(mappedCurrentCompanyTree);
    }
  }, [isSuccessUsers, mappedCurrentCompanyTree]);

  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (search === undefined) {
      return;
    }

    if (mappedTree) {
      if (search === '') {
        setTree(mappedTree);
        return;
      }

      const filteredNodes = findNode(mappedTree, search, ['email']);
      setTree(filteredNodes);
    }
  }, [search, mappedTree]);

  const debouncedHandleSearchChange = useRef(
    _.debounce(e => {
      setSearch(e.target.value);
    }, SEARCH_DEBOUNCE_TIME),
  ).current;

  const [currentCompanySearch, setCurrentCompanySearch] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (currentCompanySearch === undefined) {
      return;
    }

    if (mappedCurrentCompanyTree) {
      if (currentCompanySearch === '') {
        setCurrentCompanyTree(mappedCurrentCompanyTree);
        return;
      }

      const filteredNodes = findNode(
        mappedCurrentCompanyTree,
        currentCompanySearch,
        ['email'],
      );
      setCurrentCompanyTree(filteredNodes);
    }
  }, [currentCompanySearch, mappedCurrentCompanyTree]);

  const debouncedHandleCurrentCompanySearchChange = useRef(
    _.debounce(e => {
      setCurrentCompanySearch(e.target.value);
    }, SEARCH_DEBOUNCE_TIME),
  ).current;

  const handleOnMentionUser = useCallback(
    (user: any) => {
      setCurrentCompanyNodesIds(prev => {
        if (prev.includes(user.id)) {
          return prev;
        }
        return [...prev, user.id];
      });
    },
    [setCurrentCompanyNodesIds],
  );

  return (
    <ECBox
      display="flex"
      bgcolor={theme => theme.palette.background.default}
      flexDirection="column"
      height="100%"
      p={3}
    >
      <ECBox
        display="flex"
        flexDirection="column"
        maxHeight="80vh"
        justifyContent="space-between"
        flex={1}
        gap={2}
      >
        <ECStack
          direction="column-reverse"
          spacing={4}
          height="100%"
          overflow="auto"
        >
          {isLoadingComments && (
            <ECBox display="flex" justifyContent="center">
              <ECCircularProgress />
            </ECBox>
          )}
          {!isLoadingComments &&
            !!comments &&
            comments.data?.length > 0 &&
            comments.data?.map((comment, index) => (
              <ECComment
                key={`comment-${index}`}
                id={comment._id}
                author={comment.createdByUser}
                module={comment.subModule}
                moduleId={comment.subModuleRefId}
                date={comment.createdAt}
                text={comment.body}
                recipients={comment.recipients}
                onDelete={handleDelete}
                onEdit={handleEdit}
              />
            ))}
          {!!comments &&
            comments?.data?.length > 0 &&
            page * COMMENTS_PAGE_SIZE <
              comments?.config?.pagination?.totalCount && (
              <ECBox
                display="flex"
                justifyContent="center"
                ref={ref}
                height={70}
              >
                <ECCircularProgress sx={{ margin: 1 }} size="2rem" />
              </ECBox>
            )}
          {!isLoadingComments && !!comments && comments.data?.length === 0 && (
            <ECTypography variant="subtitle2">
              {t('translation:noteTab.empty')}
            </ECTypography>
          )}
        </ECStack>

        <ECBox display="flex" flexDirection="column">
          {MODULES_TO_SHOW_NOTIFY_USERS.includes(moduleName) && (
            <ECBox display="flex" justifyContent="flex-end">
              <ECBox
                ref={notifiedCollapsedRef}
                display="flex"
                bgcolor={theme =>
                  theme.palette.marketingSuggestion.marketingSuggestion
                }
                px={1}
                height="48px"
                justifyContent="center"
                alignItems="center"
                gap={1}
                sx={{
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                }}
              >
                <Notifications
                  sx={theme => ({ color: theme.palette.common.white })}
                />
                <ECTypography color={theme => theme.palette.common.white}>
                  NOTIFIED:{' '}
                  {selectedUsers.length + selectedCurrentCompanyUsers.length}{' '}
                  Selected
                </ECTypography>

                <ECIconButton
                  size="small"
                  type="white"
                  noPadding
                  sx={theme => ({
                    color: theme.palette.common.white,
                    padding: 0,
                  })}
                  onClick={() => setAnchorEl(notifiedCollapsedRef.current)}
                >
                  {open ? <ArrowDropUp /> : <ArrowDropDown />}
                </ECIconButton>
              </ECBox>
            </ECBox>
          )}
          <ECRichTextField
            sx={{ width: '100%', borderColor: 'black' }}
            placeholder={t('translation:richTextField.placeholder')}
            defaultValue={currentComment?.body}
            isLoading={
              isLoadingUpdate ||
              isLoadingCreate ||
              isLoadingModuleDetails ||
              isLoadingUsersToNotify ||
              isLoadingComments
            }
            onSend={handleSend}
            scopes={[P.AddComment]}
            disableSendButton={isLoadingUsersToNotify || isLoadingComments}
            mentionSuggestionUsers={usersToMention}
            mentionOnSelect={handleOnMentionUser}
            moduleId={id}
          />
        </ECBox>
      </ECBox>

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseNotifiedModal}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <ECBox display="flex" flexDirection="column" width={380}>
          <ECBox
            display="flex"
            bgcolor={theme =>
              theme.palette.marketingSuggestion.marketingSuggestion
            }
            justifyContent="space-between"
            alignItems="center"
            p={2}
          >
            <ECTypography
              variant="h6"
              color={theme => theme.palette.common.white}
            >
              Notified
            </ECTypography>

            <ECBox display="flex" gap={2} alignItems="center">
              <ECTypography
                variant="body2"
                color={theme => theme.palette.common.white}
              >
                {selectedUsers.length + selectedCurrentCompanyUsers.length}{' '}
                selected
              </ECTypography>

              <ECIconButton type="white" onClick={handleCloseNotifiedModal}>
                <Close />
              </ECIconButton>
            </ECBox>
          </ECBox>

          <ECTabContainer
            p={2}
            variant="standard"
            noPadding
            tabs={[
              {
                value: '1',
                label: t('translation:noteTab.users', {
                  count: selectedCurrentCompanyUsers?.length ?? 0,
                }),
                content: (
                  <ECBox
                    display="flex"
                    width="100%"
                    height={330}
                    flexDirection="column"
                    overflow="auto"
                  >
                    <ECBox px={2} pb={1}>
                      <ECTextField
                        fullWidth
                        placeholder="Search..."
                        defaultValue={currentCompanySearch}
                        onChange={debouncedHandleCurrentCompanySearchChange}
                      />
                    </ECBox>
                    <ECTree
                      tree={currentCompanyTree}
                      showSelectAllOption
                      keepSelectedNodes={currentCompanySearch !== undefined}
                      initialSelectedNodesIds={currentCompanyNodesIds}
                      selectedNodes={currentCompanyNodesIds}
                      onSelectedNodesIdsChange={setCurrentCompanyNodesIds}
                      placeholder={t('translation:noteTab.noUsersAvailable')}
                    />
                  </ECBox>
                ),
              },
              {
                value: '2',
                label: isCustomer
                  ? t('translation:noteTab.sp', {
                      count: selectedUsers?.length ?? 0,
                    })
                  : t('translation:noteTab.customer', {
                      count: selectedUsers?.length ?? 0,
                    }),
                content: (
                  <ECBox
                    display="flex"
                    width="100%"
                    height={330}
                    flexDirection="column"
                    overflow="auto"
                  >
                    <ECBox px={2} pb={1}>
                      <ECTextField
                        fullWidth
                        placeholder="Search..."
                        defaultValue={search}
                        onChange={debouncedHandleSearchChange}
                      />
                    </ECBox>
                    <ECTree
                      tree={tree}
                      showSelectAllOption={!isServiceProvider}
                      keepSelectedNodes={search !== undefined}
                      initialSelectedNodesIds={nodesIds}
                      selectedNodes={nodesIds}
                      onSelectedNodesIdsChange={setNodesIds}
                      placeholder={t('translation:noteTab.noUsersAvailable')}
                    />
                  </ECBox>
                ),
              },
            ]}
          />
        </ECBox>
      </Popover>
    </ECBox>
  );
};

export const NoteTab = requirePermissionWrapper<NoteTabProps>(NoteTabContent);
