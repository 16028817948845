import { ECBox } from 'app/components/ECBox';
import { ECAlert, ECAlertTitle } from 'app/components/ECAlert';
import { ECTypography } from 'app/components/ECTypography';
import { ECLink } from 'app/components/ECLink';
import { useCreateServiceRequestContext } from 'store/contexts';
import { useNavigate } from 'react-router-dom';

interface NoSPAlertProps {
  tradeName?: string;
}

export const NoSPAlert = ({ tradeName }: NoSPAlertProps) => {
  const navigate = useNavigate();

  const { dispatch } = useCreateServiceRequestContext();

  const handleNavigate = url => {
    dispatch({ type: 'close' });
    navigate('/panel/service-providers/' + url);
  };

  return (
    <ECAlert
      severity="warning"
      sx={{
        '.MuiAlert-message': {
          paddingRight: '26px',
          width: '100% !important',
          height: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        paddingY: 2,
      }}
    >
      <ECBox
        display={'flex'}
        flexWrap={'wrap'}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <ECBox>
          <ECAlertTitle
            sx={theme => ({
              color: theme.palette.text.alert.warning,
            })}
          >
            <ECTypography
              variant="body2"
              marginRight={'10px'}
              fontWeight={'bold'}
              sx={theme => ({
                color: theme.palette.text.alert.warning,
              })}
              fontSize={16}
            >
              No Service Providers set up to service this Trade
            </ECTypography>
          </ECAlertTitle>
          <ECTypography
            variant="body2"
            sx={theme => ({
              color: theme.palette.text.alert.warning,
            })}
            alignItems={'center'}
          >
            <strong>•&nbsp;&nbsp;&nbsp; Step 1</strong> – Find a Service
            Provider to work with your Company in{' '}
            <ECLink
              underline="always"
              onClick={() => handleNavigate('service-providers')}
            >
              Service Providers
            </ECLink>
            {' > '}
            <ECLink
              underline="always"
              onClick={() => handleNavigate?.('sp-directory')}
            >
              Service Providers Directory
            </ECLink>
            <br />
            <strong>•&nbsp;&nbsp;&nbsp; Step 2</strong> – After the Service
            Provider Accepts, add a Service Provider for this trade{' '}
            {tradeName && `(${tradeName})`} in{' '}
            <ECLink
              underline="always"
              onClick={() => handleNavigate('service-providers')}
            >
              Service Providers
            </ECLink>
            {' > '}
            <ECLink
              underline="always"
              onClick={() => handleNavigate?.('trade-assignment')}
            >
              Trade Assignment
            </ECLink>
            .
          </ECTypography>
        </ECBox>
      </ECBox>
    </ECAlert>
  );
};
