import { ECDynamicPageTemplate } from 'app/components';
import { t } from 'i18next';
import { useEffect } from 'react';

import {
  useLazyGetBranchTypesQuery,
  useCreateBranchTypeMutation,
  useUpdateBranchTypeMutation,
} from 'services/branchApi';

import { loadPageScopes } from 'utils/pageScopes';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const fancyFormElementsDetails = require('./fancy_form_config_details.json');

export function ConceptsPage() {
  useEffect(() => {
    loadPageScopes('branchType');
  }, []);
  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      pageTitle={t('translation:panelDrawer.branchTypes')}
      useLazyGetListQuery={useLazyGetBranchTypesQuery}
      useCreateMutation={useCreateBranchTypeMutation}
      useUpdateMutation={useUpdateBranchTypeMutation}
      createFormConfig={fancyFormElementsCreate.data.config}
      createFormFields={fancyFormElementsCreate.data.fields}
      editFormConfig={fancyFormElementsEdit.data.config}
      editFormFields={fancyFormElementsEdit.data.fields}
      detailsConfig={fancyFormElementsDetails.data.config}
      detailsFields={fancyFormElementsDetails.data.fields}
      downloadFileName="branch-type.csv"
      enableExport={true}
      marginTop={false}
      showStatusActiveFilter={true}
      shouldNotUseActiveFilter
    />
  );
}
