import { useCallback, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ECWorkflowTemplate } from 'app/components';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import { Outlet, useParams } from 'react-router';
import {
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useEditUserFlagsMutation,
  usersApi,
} from 'services/userApi';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { useGetTimezonesQuery } from 'services/lookupApi';
import { normalizeZipcode } from 'utils/strings/zipcode';
import { useGetJobTitlesListQuery } from 'services/jobTitleApi';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { equalCustomizer } from 'utils/validate-fields';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';
import { isValidEmail } from 'utils/validate-fields';

const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const formElementsDetails = require('./fancy_form_config_details.json');

export function ServiceProviderUsersDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const hasEditUserPermission = useHasPermission([P.EditUser]);

  const [validations, setValidations] = useState<any>([]);

  const [isActive, setIsActive] = useState<boolean>(false);

  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});

  const {
    data: user,
    isSuccess,
    isLoading: isLoadingUser,
  } = useGetUserByIdQuery(id ? Number(id) : 0);

  const { data: flagsData, isSuccess: isSuccessFlag } = useGetFlagsListQuery(
    {},
  );

  const { data: timezonesData, isSuccess: isSuccessTimezones } =
    useGetTimezonesQuery();

  const { data: jobTitlesData, isSuccess: isSuccessJobTitles } =
    useGetJobTitlesListQuery({});

  const [jobTitlesOptions, setJobTitlesOptions] = useState<any>([]);

  const initializeFields = () => {
    setSummaryFieldsValues({
      firstName: user?.firstName,
      lastName: user?.lastName,
      jobTitle: user?.jobTitle?.id,
      email: user?.email,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      initializeFields();
      setIsActive(!!user?.status);
    }
  }, [user, isSuccess]);

  useEffect(() => {
    if (jobTitlesData && isSuccessJobTitles) {
      const jobTitles = (jobTitlesData as any)?.data ?? jobTitlesData;
      const jobTitlesOptions =
        jobTitles?.length > 0
          ? jobTitles.map(({ name, id }) => ({
              label: name,
              data: id,
            }))
          : [];
      if (
        user?.jobTitle &&
        !jobTitlesOptions.find(jt => jt.data === user?.jobTitle?.id)
      ) {
        jobTitlesOptions.push({
          label: user?.jobTitle?.label,
          data: user?.jobTitle?.id,
        });
      }
      setJobTitlesOptions(jobTitlesOptions);
    }
  }, [jobTitlesData, isSuccessJobTitles, user?.jobTitle]);

  useEffect(() => {
    if (flagsData && isSuccessFlag) {
      let editSelectField = fancyFormElementsEdit.data.fields.find(
        field => field.fieldName === 'flags',
      );
      editSelectField.options =
        flagsData?.data.map(d => ({
          label: d.label,
          color: d.color,
          id: d.id,
        })) || [];
    }
  }, [isSuccessFlag, flagsData]);

  useEffect(() => {
    if (timezonesData && isSuccessTimezones) {
      const timezones = (timezonesData as any)?.data ?? timezonesData;
      const timezoneField = fancyFormElementsEdit?.data.fields?.find(
        field => field.fieldName === 'timezone',
      );
      timezoneField.options = timezones.map(({ timezone }) => ({
        label: timezone,
        id: timezone,
      }));
    }
  }, [timezonesData, isSuccessTimezones]);

  const [
    doUpdateUserDetails,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset,
    },
  ] = useUpdateUserMutation();

  const [
    doEditUserFlags,
    {
      isError: isUpdateFlagsError,
      error: updateFlagsError,
      isLoading: isUpdateFlagsLoading,
      isSuccess: isUpdateFlagsSuccess,
    },
  ] = useEditUserFlagsMutation();

  const useUpdateUserDetails = useCallback(() => {
    const doUpdate = async data => {
      if (!summaryFieldsValues.firstName) {
        setValidations([
          {
            field: 'firstName',
            validationMessage: 'First Name is required!',
          },
        ]);
        return false;
      }

      if (!isValidEmail(summaryFieldsValues.email)) {
        setValidations([
          {
            field: 'email',
            validationMessage: 'Email is invalid!',
          },
        ]);
        return false;
      }

      setValidations([]);

      const flagsIds = data.flags?.map(flag => flag?.id);

      data = { ...user, ...data };

      let userUpdateBodyComparison: any = {
        firstName: summaryFieldsValues?.firstName ?? '',
        lastName: summaryFieldsValues?.lastName ?? '',
        jobTitleId:
          summaryFieldsValues.jobTitle?.data ?? summaryFieldsValues.jobTitle,
        email: summaryFieldsValues?.email ?? '',
        status: isActive ? 1 : 0,
        phone: data?.phone ?? '',
        phoneExt: data?.phoneExt ?? '',
        nte: data?.nte ?? '',
        flagsIds,
        line1: data?.line1 ?? '',
        line2: data?.line2 ?? '',
        zipCodePostalCode: data?.zipCodePostalCode
          ? normalizeZipcode(data?.zipCodePostalCode)
          : '',
        cityName: data?.cityName ?? '',
        stateProvinceCode: data?.stateProvinceCode ?? '',
        countryCode: data?.countryCode ?? '',
        timezone: data?.timezone?.id ?? data?.timezone,
      };

      let originalUserBody: any = {
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        jobTitleId: user?.jobTitle?.id,
        email: user?.email ?? '',
        status: user?.status,
        phone: user?.phone ?? '',
        phoneExt: user?.phoneExt ?? '',
        nte: user?.nte ?? '',
        flagsIds: user?.flags?.map(flag => flag.id),
        line1: user?.line1 ?? '',
        line2: user?.line2 ?? '',
        zipCodePostalCode: user?.zipCodePostalCode
          ? normalizeZipcode(user?.zipCodePostalCode)
          : '',
        cityName: user?.cityName ?? '',
        stateProvinceCode: user?.stateProvinceCode ?? '',
        countryCode: user?.countryCode ?? '',
        timezone: user?.timezone,
      };

      let isEqual = true;
      Object.keys(userUpdateBodyComparison).forEach(key => {
        if (
          !_.isEqualWith(
            originalUserBody[key],
            userUpdateBodyComparison[key],
            equalCustomizer,
          )
        ) {
          isEqual = false;
        }
      });

      if (isEqual) {
        dispatch(
          setSnackbar({
            severity: 'success',
            message: t('translation:dynamicForm.updateNothing'),
          }),
        );
        return;
      }

      const deleteFlagsIds: number[] = _.difference(
        user?.flags?.map(flag => flag.id),
        data?.flags.map(flag => flag.id),
      );
      const createFlagsIds: number[] = _.difference(
        data?.flags.map(flag => flag.id),
        user?.flags?.map(flag => flag.id) || [],
      );

      if (deleteFlagsIds.length > 0 || createFlagsIds.length > 0) {
        await doEditUserFlags({
          userId: Number(id),
          flags: data?.flags || [],
        });
      }

      const newUpdatedDto: any = userUpdateBodyComparison;

      delete newUpdatedDto.flagsIds;

      if (Object.keys(newUpdatedDto).length) {
        doUpdateUserDetails({
          ...newUpdatedDto,
          id: Number(id),
          deleteAddress: Boolean(
            !userUpdateBodyComparison.line1 && originalUserBody.line1,
          ),
        });
      } else {
        dispatch(usersApi.util.invalidateTags(['User', 'UserById']));
      }
    };

    return [
      doUpdate,
      {
        isError: isUpdateError || isUpdateFlagsError,
        error: updateError || updateFlagsError,
        isLoading: isUpdateLoading || isUpdateFlagsLoading,
        isSuccess: isUpdateSuccess || isUpdateFlagsSuccess,
        reset,
      },
    ];
  }, [
    id,
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    isUpdateFlagsError,
    updateFlagsError,
    isUpdateFlagsLoading,
    isUpdateFlagsSuccess,
    user,
    isActive,
    summaryFieldsValues,
    reset,
    doUpdateUserDetails,
  ]);

  const handleSummaryFieldChange = (fieldName: string) => (value: any) => {
    if (fieldName === 'email') {
      if (!isValidEmail(value)) {
        setValidations([
          {
            field: 'email',
            validationMessage: 'Email is invalid!',
          },
        ]);
      } else {
        setValidations([]);
      }
    }

    setSummaryFieldsValues(prevSummaryFieldsValues => ({
      ...prevSummaryFieldsValues,
      [fieldName]: value,
    }));
  };

  const handleStatusChange = (active: boolean) => {
    setIsActive(active);
  };

  useEffect(() => {
    if (isUpdateError && updateError) {
      const error = updateError as any;

      if (
        error?.data?.fieldNameWithError === 'email' &&
        error?.data?.description
      ) {
        setValidations([
          {
            field: 'email',
            validationMessage: error?.data?.description,
          },
        ]);
      }
    }
  }, [isUpdateError, updateError]);

  const summaryData = useMemo(() => {
    return [
      {
        id: 'state',
        label: 'State',
        data: isActive,
        defaultData: isActive,
        isEditable: true,
        type: SummaryFieldTypes.State,
        onChange: handleStatusChange,
      },
      {
        id: 'firstName',
        label: 'First Name',
        data: summaryFieldsValues?.firstName,
        isEditable: true,
        type: SummaryFieldTypes.Text,
        isValid: !validations?.some(v => v.field === 'firstName'),
        validationMessage: validations?.find(v => v.field === 'firstName')
          ?.validationMessage,
        onChange: handleSummaryFieldChange('firstName'),
      },
      {
        id: 'lastName',
        label: 'Last Name',
        data: summaryFieldsValues?.lastName,
        isEditable: true,
        type: SummaryFieldTypes.Text,
        onChange: handleSummaryFieldChange('lastName'),
      },
      {
        id: 'email',
        name: 'email',
        label: 'Email',
        data: summaryFieldsValues?.email,
        isEditable: hasEditUserPermission[0],
        type: SummaryFieldTypes.EmailField,
        onChange: handleSummaryFieldChange('email'),
        isValid: !validations?.some(v => v.field === 'email'),
        validationMessage: validations?.find(v => v.field === 'email')
          ?.validationMessage,
      },
      {
        id: 'jobTitle',
        label: 'Job Title',
        data: user?.jobTitle?.id,
        options: jobTitlesOptions,
        type: SummaryFieldTypes.Select,
        isEditable: true,
        onChange: handleSummaryFieldChange('jobTitle'),
      },
    ];
  }, [
    user,
    summaryFieldsValues,
    jobTitlesOptions,
    isActive,
    validations,
    hasEditUserPermission,
  ]);

  return (
    <ECWorkflowTemplate
      tabsAsLinks
      title={
        user?.fullName ||
        `${t('translation:workflow.sidebar.user')} #${user?.id}`
      }
      summaryData={summaryData}
      onExitEditModeWithoutSave={initializeFields}
      editConfig={fancyFormElementsEdit.data.config}
      editFields={fancyFormElementsEdit.data.fields}
      detailsConfig={formElementsDetails.data.config}
      detailsFields={formElementsDetails.data.fields}
      detailsData={user}
      useUpdateMutation={useUpdateUserDetails}
      imgSrc={user?.logo || ''}
      showLogoOnError={false}
      checkNothingToUpdateEditForm={false}
      additionalTabs={[
        {
          value: 'location-assignment',
          editable: true,
          label: t('translation:pages.locationAssignment.title'),
          content: <Outlet />,
          link: 'location-assignment',
        },
      ]}
      notesTabname="Comments"
      historyTabname="Activity"
      hideNoteTab={true}
      hideActivityTab={true}
      showSkeletonLoader={isLoadingUser}
    />
  );
}
