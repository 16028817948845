import React from 'react';
import { ECBox } from '../ECBox';
import { ECCircularProgress } from '../ECCircularProgress';
import {
  ECTHCell,
  ECTable,
  ECTableBody,
  ECTableCell,
  ECTableHead,
  ECTableRow,
} from '../ECTable';
import { ECTypography } from '../ECTypography';
import * as _ from 'lodash';
import { EmptyStatePage } from 'app/pages/EmptyStatePage';

export interface UsersData {
  users: any[];
  config?: any;
  hideSearchHeadContent?: boolean;
  isLoading?: boolean;
  customModalTitle?: string;
}

const tableConfigUsers = require('./tableConfigUsers.json');

export function ECUsersTable({
  users,
  config,
  isLoading = false,
  customModalTitle,
}: UsersData) {
  const cols = config?.cols || tableConfigUsers.config.cols;

  return isLoading ? (
    <ECBox
      height={150}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <ECCircularProgress size={28} />
    </ECBox>
  ) : users.length > 0 ? (
    <>
      <ECTypography variant="h6" fontWeight="bold" mb={1}>
        {customModalTitle || tableConfigUsers?.config?.header?.title}
      </ECTypography>
      <ECTable>
        <ECTableHead>
          {cols.map((col, index) => {
            return (
              <ECTHCell
                sx={{
                  bgcolor: theme => theme.palette.other.backgroundTable,
                  borderTopLeftRadius: index === 0 ? 15 : 0,
                  borderTopRightRadius: index === cols.length - 1 ? 15 : 0,
                }}
                key={`${col?.title}-${index}`}
                align="left"
              >
                <ECBox display={'flex'} flexDirection={'column'}>
                  <ECBox
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                  >
                    <ECTypography
                      variant={'body2'}
                      sx={{
                        fontVariantCaps: 'normal',
                        fontWeight: 'bold',
                        fontSize: 14,
                        color: theme => theme.palette.text.primary,
                      }}
                    >
                      {col.title}
                    </ECTypography>{' '}
                  </ECBox>
                </ECBox>
              </ECTHCell>
            );
          })}
        </ECTableHead>
        <ECTableBody>
          {users?.map((row, rowIndex) => {
            return (
              <React.Fragment key={`${row?.id}-${rowIndex}`}>
                <ECTableRow
                  key={`${row?.id}-${rowIndex}`}
                  sx={{
                    '&:hover': {
                      backgroundColor: theme =>
                        theme.palette.primary.outlinedHoverBackground,
                      cursor: 'pointer',
                    },
                    borderBottom: row.shouldShowDivision ? 11 : 1,
                    borderColor: '#E5E5E5',
                  }}
                >
                  {cols.map((col, index) => {
                    const cellData = _.get(row, col.dataPath);
                    return (
                      <ECTableCell
                        key={index}
                        scope="row"
                        sx={{ minWidth: '10px', ...col.optionalSx }}
                      >
                        <ECTypography noWrap>{cellData}</ECTypography>
                      </ECTableCell>
                    );
                  })}
                </ECTableRow>
              </React.Fragment>
            );
          })}
        </ECTableBody>
      </ECTable>
    </>
  ) : (
    <EmptyStatePage />
  );
}
