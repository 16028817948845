import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ECBox,
  ECWorkflowTemplate,
  FailureWarrantyOutput,
} from 'app/components';
import {
  SummaryFieldTypes,
  SummaryFieldsData,
} from 'app/components/ECWorkflowSummaryField';
import { useLazyGetAttachmentQuery } from 'services/attachmentApi';
import {
  useUpdateInvoiceMutation,
  useGetNextStatusListQuery,
  useUpdateStatusMutation,
  useGetWarrantyComponentForInvoiceIdQuery,
} from 'services/invoiceApi';
import { useGetProblemListByCompanyIdAndAssetTypeIdQuery } from 'services/problemApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import { populateDropDownFields } from 'utils/pageUtils';
import { CostTableData } from '../../components/ECWorkflowCostTable';
import { WorkflowNextStatus } from 'types/WorkflowStatus';
import { useGetRepairsListForAssetQuery } from 'services/repairApi';
import { useGetAssetWarrantyQuery } from 'services/assetApi';
import { approvalApi, useGetApprovalHistoryQuery } from 'services/approvalApi';
import { useDispatch } from 'react-redux';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { setSnackbar } from 'store/slice/page';
import { ECUsersTableModal } from 'app/components/ECUsersTableModal';
import { Popover } from '@mui/material';
import sumCurrencyFields from 'utils/sum-currency-fields';
import { moduleApi, useGetModuleDetailsByIdQuery } from 'services/moduleApi';
import { customDateFormatter, formatDecimalHours } from 'utils/dates';
import { ECApprovalHistoryButton } from 'app/components/ECApprovalHistory/ECApprovalHistoryButton';
import { WorkflowStatus } from 'app/components/ECWorkflowStatusBadge';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';
import { groupAndProcessInventoryItems } from 'utils/common';
import _ from 'lodash';
import { useGetServiceProviderRatesAndTermsQuery } from 'services/serviceProviderApi';
import { useGetRejectWarrantyReasonsQuery } from 'services/lookupApi';

const formElementsEdit = require('./fancy_form_config_edit.json');
const detailsForm = require('./details-form.json');

export function ServiceProviderInvoicesDetailsPage() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const [formFields, setFormFields] = useState<any[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [openZeroTotalInfo, setOpenZeroTotalInfo] = useState<boolean>(false);

  const isDetailsTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('notes') &&
    !window.location.href.includes('activity');

  const [isResubmitAction, setIsResubmitAction] = useState(false);
  const { data: invoiceFromQuery, isLoading: isLoadingInvoiceFromQuery } =
    useGetModuleDetailsByIdQuery(
      { id: id ?? '', moduleName: 'sp/invoice' },
      { skip: !id },
    );
  const { data: warrantyComponents } = useGetWarrantyComponentForInvoiceIdQuery(
    id ?? '',
    { skip: !id || !isDetailsTab || !isEditMode },
  );
  const { data: ratesAndTerms } = useGetServiceProviderRatesAndTermsQuery(
    {
      spId: invoiceFromQuery?.spCompanyId,
      companyId: invoiceFromQuery?.request?.companyId,
    },
    {
      skip: !invoiceFromQuery?.spCompanyId,
    },
  );
  const { data: categoryListData } = useGetCategoryListQuery({ st: 0 });

  const invoice = useMemo(() => {
    if (invoiceFromQuery) {
      const mailingAddress =
        invoiceFromQuery?.request.asset?.branch?.mailingAddress;
      const addressStreet = `${mailingAddress?.line1}${
        mailingAddress?.line2 ? ` ${mailingAddress?.line2}` : ''
      }${mailingAddress?.line3 ? ` ${mailingAddress?.line3}` : ''}`;

      return {
        ...invoiceFromQuery,
        address: `${addressStreet}, ${mailingAddress?.cityName}, ${mailingAddress?.stateProvinceCode} ${mailingAddress?.zipCodePostalCode}`,
        phone: invoiceFromQuery.request.asset.branch?.phone,
        problem: invoiceFromQuery.request.problem?.name,
        warrantyComponents: invoiceFromQuery.warrantyComponents?.map(wc => ({
          ...wc,
          repairName: wc.repair?.name,
          warranty: {
            noWarranty: wc.noWarranty,
            resolution: wc.resolution?.name,
            period: wc.duration,
            periodLabel: wc.period?.description,
          },
        })),
      };
    }
  }, [invoiceFromQuery]);

  const { data: problems } = useGetProblemListByCompanyIdAndAssetTypeIdQuery(
    {
      assetTypeId: invoice?.request?.asset?.assetType?.id,
      companyId: invoice?.request?.companyId,
    },
    {
      skip:
        !isDetailsTab ||
        !isEditMode ||
        !invoice?.request?.asset?.assetType?.id ||
        !invoice?.request?.companyId,
    },
  );

  const dropdownCategoryList = useMemo(() => {
    if (categoryListData) {
      const categories = categoryListData?.data?.map(category => ({
        label: category.name,
        data: category.id,
      }));

      // if current category is not on list, add it
      if (
        invoice?.request.category?.name &&
        invoice?.request.category?.id &&
        !categories?.some(
          category => category.data === invoice?.request.category?.id,
        )
      ) {
        categories?.push({
          label: invoice?.request.category?.name,
          data: invoice?.request.category?.id,
        });
      }
      return categories;
    } else {
      return [];
    }
  }, [
    categoryListData,
    invoice?.request.category?.name,
    invoice?.request.category?.id,
  ]);

  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});

  const [attachmentTrigger, attachmentsResult] = useLazyGetAttachmentQuery();
  const { data: attachments } = attachmentsResult;
  const assetProfileAttachment = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.assetProfilePhoto,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  const { data: workflowStatusList } = useGetNextStatusListQuery(
    Number(id) || 0,
    { skip: !isDetailsTab || !id },
  );

  const [
    doUpdateStatus,
    { isLoading: isLoadingActions, isSuccess: isSuccessUpdateAction },
  ] = useUpdateStatusMutation();

  useEffect(() => {
    if (isSuccessUpdateAction) {
      dispatch(approvalApi.util.invalidateTags(['Approval']));
      dispatch(
        setSnackbar({
          severity: 'success',
          message: isResubmitAction
            ? 'Invoice was successfully resubmitted'
            : 'Invoice status updated successfully',
        }),
      );
    }
  }, [isSuccessUpdateAction]);

  const { data: failures } = useGetRepairsListForAssetQuery(
    invoice?.request.asset?.id?.toString() ?? '',
    { skip: !isDetailsTab || !isEditMode || !invoice?.request.asset?.id },
  );

  const { data: assetWarranty } = useGetAssetWarrantyQuery(
    invoice?.request.asset?.id || null,
    { skip: !isDetailsTab || !invoice?.request.asset?.id },
  );

  const { data: approvalHistory, isSuccess: isSuccessApprovalHistory } =
    useGetApprovalHistoryQuery(
      {
        id: Number(id) || 0,
        module: 'invoice',
      },
      {
        skip: !isDetailsTab || !id,
      },
    );

  // useEffect to add the view approval history button to the form
  useEffect(() => {
    // DETAILS FORM
    const approvalHistoryField = detailsForm.fields
      ?.find(field => field.groupName === 'leftSideFieldsGroup')
      ?.subFields?.find(f => f.fieldName === 'approvalHistoryButton');

    if (approvalHistoryField) {
      approvalHistoryField.value = (
        <ECApprovalHistoryButton approvalHistory={approvalHistory} />
      );
    }

    detailsForm.fields = [...detailsForm.fields];
  }, [approvalHistory]);

  useEffect(() => {
    if (!invoice) return;

    /* details form */
    const leftSideFields = detailsForm.fields?.find(
      field => field.groupName === 'leftSideFieldsGroup',
    ).subFields;

    const warrantyUsedField = leftSideFields?.find(
      field => field.fieldName === 'warrantyUsed',
    );
    if (warrantyUsedField) {
      warrantyUsedField.visible = !invoice?.request?.pmId;
    }

    const rejectWarrantyReasonField = leftSideFields?.find(
      field => field.fieldName === 'rejectWarrantyReason.name',
    );
    if (rejectWarrantyReasonField) {
      rejectWarrantyReasonField.visible =
        !invoice?.request?.pmId && !invoice.warrantyUsed;
    }

    const rejectWarrantyReasonOtherField = leftSideFields?.find(
      field => field.fieldName === 'rejectWarrantyReasonOther',
    );

    if (rejectWarrantyReasonOtherField) {
      rejectWarrantyReasonOtherField.visible =
        !invoice?.request?.pmId &&
        !invoice.warrantyUsed &&
        invoice.rejectWarrantyReason?.name?.toLowerCase() === 'other';
    }

    /* edit form */
    const leftSideFieldsGroupFields = formElementsEdit.fields?.find(
      field => field.groupName === 'leftSideFieldsGroup',
    ).subFields;

    const warrantyUsedEditField = leftSideFieldsGroupFields?.find(
      field => field.fieldName === 'warrantyUsed',
    );
    if (warrantyUsedEditField) {
      warrantyUsedEditField.visible = !invoice?.request?.pmId;
    }

    const rejectWarrantyReasonEditField = leftSideFieldsGroupFields?.find(
      field => field.fieldName === 'rejectWarrantyReason',
    );
    if (rejectWarrantyReasonEditField) {
      rejectWarrantyReasonEditField.visible =
        !invoice?.request?.pmId && !invoice.warrantyUsed;
      rejectWarrantyReasonEditField.required =
        !invoice?.request?.pmId &&
        !invoice.warrantyUsed &&
        ['active', 'partial'].includes(
          assetWarranty?.[0]?.status?.toLowerCase(),
        );
    }

    const rejectWarrantyReasonOtherEditField = leftSideFieldsGroupFields?.find(
      field => field.fieldName === 'rejectWarrantyReasonOther',
    );

    if (rejectWarrantyReasonOtherEditField) {
      rejectWarrantyReasonOtherEditField.visible =
        !invoice?.request?.pmId &&
        !invoice.warrantyUsed &&
        invoice.rejectWarrantyReason?.name?.toLowerCase() === 'other';
      rejectWarrantyReasonOtherEditField.required =
        !invoice?.request?.pmId &&
        !invoice.warrantyUsed &&
        invoice.rejectWarrantyReason?.name?.toLowerCase() === 'other';
    }
  }, [
    invoice?.warrantyUsed,
    invoice?.rejectWarrantyReason,
    !invoice?.request?.pmId,
    assetWarranty,
  ]);

  useEffect(() => {
    // EDIT FORM
    const leftSideFieldsGroupEditFields = formElementsEdit.fields?.find(
      field => field.groupName === 'leftSideFieldsGroup',
    ).subFields;
    const approvalHistoryField = leftSideFieldsGroupEditFields?.find(
      f => f.fieldName === 'approvalHistoryButton',
    );

    if (approvalHistoryField) {
      approvalHistoryField.value = (
        <ECApprovalHistoryButton approvalHistory={approvalHistory} disabled />
      );
    }

    const rejectWarrantyReasonField = leftSideFieldsGroupEditFields?.find(
      field => field.fieldName === 'rejectWarrantyReason',
    );
    if (rejectWarrantyReasonField) {
      rejectWarrantyReasonField.useQuery = useGetRejectWarrantyReasonsQuery;
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const onClickViewUsersTable = useCallback((field, event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  useEffect(() => {
    const assignedToField = detailsForm?.fields[0]?.subFields?.find(
      x => x.fieldName === 'assignedTo',
    );
    if (assignedToField) {
      assignedToField.onClickViewUsersTable = onClickViewUsersTable;
    }
  }, [onClickViewUsersTable]);

  const assignedToUsers = useMemo(() => {
    return approvalHistory?.currentAssignees?.map(assignee => {
      return {
        fullName: assignee.fullName,
        jobTitle: assignee.jobTitle,
        email: assignee.email,
      };
    });
  }, [isSuccessApprovalHistory, approvalHistory]);

  useEffect(() => {
    if (invoice) {
      if (!!invoice?.requiredFields?.invoiceDateRequired) {
        const leftSideFieldsGroupEditFields = formElementsEdit?.fields?.find(
          field => field.groupName === 'rightSideFieldsGroup',
        ).subFields;

        const invoiceDateField = leftSideFieldsGroupEditFields?.find(
          field => field.fieldName === 'invoiceDate',
        );
        invoiceDateField.required = true;
      }

      if (!!invoice?.requiredFields?.invoiceDocumentRequired) {
        const attachmentField = formElementsEdit?.fields?.find(
          field => field.fieldName === 'files',
        );
        attachmentField.required = true;
      }

      formElementsEdit.fields = [...formElementsEdit.fields];
      setFormFields(formElementsEdit.fields);
    }
  }, [invoice]);

  useEffect(() => {
    setFormFields(
      formElementsEdit.fields?.map(field => {
        if (field.type === 'group') {
          return field.subFields?.map(subField => {
            if (invoice?.mutableFields?.includes(subField.fieldName)) {
              subField.readOnly = false;
            } else if (subField.fieldName === 'rejectWarrantyReason') {
              subField.readOnly = !invoice?.mutableFields?.includes(
                'rejectWarrantyReasonId',
              );
            } else {
              subField.readOnly = true;
            }
          });
        }

        if (invoice?.mutableFields?.includes(field.fieldName)) {
          field.readOnly = false;
        } else {
          field.readOnly = true;
        }

        return field;
      }),
    );
  }, [invoice?.mutableFields]);

  const [
    doUpdateInvoice,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdateInvoice,
    },
  ] = useUpdateInvoiceMutation();

  useEffect(() => {
    if (
      (!isLoadingActions && isSuccessUpdateAction) ||
      (!isUpdateLoading && isUpdateSuccess)
    ) {
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
    }
  }, [
    isLoadingActions,
    isSuccessUpdateAction,
    isUpdateLoading,
    isUpdateSuccess,
  ]);

  const hasEditInvoicePermission = useHasPermission([P.EditInvoice]);

  const costTableData = useMemo((): CostTableData | undefined => {
    const itemsRepair = [
      {
        label: 'Labor',
        value: invoice?.costLabor,
        dataPath: 'costLabor',
        readOnly: true,
      },
      {
        label: 'Labor Charge',
        value: invoice?.costLaborItems,
        dataPath: 'costLaborItems',
        extraData: ratesAndTerms,
        readOnly: !invoice?.mutableFields?.includes('costLabor'),
      },
      {
        label: 'Material',
        value: invoice?.costMaterial,
        dataPath: 'costMaterial',
        readOnly: !invoice?.mutableFields?.includes('costMaterial'),
      },
      {
        label: 'Freight',
        value: invoice?.costFreight,
        dataPath: 'costFreight',
        readOnly: !invoice?.mutableFields?.includes('costFreight'),
      },
      {
        label: 'Tax',
        value: invoice?.costTax,
        dataPath: 'costTax',
        readOnly: !invoice?.mutableFields?.includes('costTax'),
      },
      {
        label: 'Other',
        value: invoice?.costOther,
        dataPath: 'costOther',
        readOnly: !invoice?.mutableFields?.includes('costOther'),
      },
    ];
    const isCostTableEditable =
      isEditMode && itemsRepair.some(item => !item.readOnly);
    return (
      invoice && {
        nte: invoice.request.workorders?.[0]?.nte,
        openZeroTotalInfo: openZeroTotalInfo,
        editingTotalCost: isCostTableEditable,
        onClickAwayZeroTotalInfo: () => {
          if (openZeroTotalInfo) {
            setOpenZeroTotalInfo(false);
          }
        },
        itemsRepair,
        itemsInventory: groupAndProcessInventoryItems(
          invoice.allocatedInventory,
        )?.map(inventory =>
          typeof inventory === 'object'
            ? { ...inventory, readOnly: true }
            : inventory,
        ),

        shouldShowAddCredit:
          hasEditInvoicePermission && invoice.invoiceCreditEnabled,
        // if status is PENDING APPROVAL hide the add credit button
        shouldShowAddCreditButton:
          invoice.workflowStatus.name === WorkflowStatus.Approved &&
          !invoice?.coupaSetting?.enableCoupa,

        totalWithDiscountAmount: invoice.totalWithDiscount,
        creditAmount: invoice.credit,
        invoiceId: Number(id),
      }
    );
  }, [
    invoice,
    openZeroTotalInfo,
    id,
    hasEditInvoicePermission,
    ratesAndTerms,
    isEditMode,
  ]);

  const { data: flagsData, isSuccess: isSuccessFlag } = useGetFlagsListQuery(
    {},
    {
      skip:
        !isDetailsTab ||
        !isEditMode ||
        !invoice?.mutableFields?.includes('flags'),
    },
  );
  useEffect(() => {
    if (flagsData && isSuccessFlag) {
      let editSelectField = formElementsEdit.fields?.find(
        field => field.fieldName === 'flags',
      );
      editSelectField.options =
        flagsData?.data.map(d => ({
          label: d.label,
          color: d.color,
          id: d.id,
        })) || [];
      editSelectField.optionValues = flagsData?.data.map(d => d.label) || [];

      formElementsEdit.fields = [...formElementsEdit.fields];
      setFormFields(formElementsEdit.fields);
    }
  }, [isSuccessFlag, flagsData]);

  useEffect(() => {
    if (problems && invoice?.request.category.name !== 'Maintenance') {
      const [_newCreateForm, newEditForm] = populateDropDownFields({
        responseData: problems || [],
        editFormFields: formElementsEdit.fields[0].subFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'problem',
      });

      formElementsEdit.fields[0].subFields = newEditForm;
      formElementsEdit.fields = [...formElementsEdit.fields];
      setFormFields(formElementsEdit.fields);
    }
  }, [problems]);

  useEffect(() => {
    if (failures) {
      const groupedFieldsEdit = formElementsEdit.fields?.find(
        field => field.type === 'group',
      ).subFields;
      const warrantyComponentsEditField = groupedFieldsEdit.find(
        field => field.fieldName === 'warrantyComponents',
      );

      warrantyComponentsEditField.options = failures.map(failure => ({
        name: failure.name,
        id: failure.id,
      }));

      formElementsEdit.fields = [...formElementsEdit.fields];
      setFormFields(formElementsEdit.fields);
    }
  }, [failures]);

  useEffect(() => {
    if (warrantyComponents) {
      const groupedFieldsEdit = formElementsEdit.fields?.find(
        field => field.type === 'group',
      ).subFields;
      const warrantyComponentsEditField = groupedFieldsEdit.find(
        field => field.fieldName === 'warrantyComponents',
      );

      const groupedFieldsDetails = detailsForm.fields?.find(
        field => field.type === 'group',
      ).subFields;
      const warrantyComponentsDetailsField = groupedFieldsDetails.find(
        field => field.fieldName === 'warrantyComponents',
      );

      const warrantyComponentsValue: FailureWarrantyOutput[] =
        warrantyComponents?.map(warrantyComponent => ({
          repairId: warrantyComponent.repair?.id,
          repairName: warrantyComponent.repair?.name,
          warranty: {
            id: warrantyComponent.id,
            noWarranty: warrantyComponent.noWarranty,
            period: warrantyComponent.duration,
            periodLabel: warrantyComponent.period?.description,
            periodCode: warrantyComponent.period?.code,
            resolutionId: warrantyComponent.resolution?.id,
            resolution: warrantyComponent.resolution?.name,
          },
        }));

      if (warrantyComponentsEditField) {
        warrantyComponentsEditField.value = warrantyComponentsValue;
        warrantyComponentsEditField.overrideRowValue = true;
      }

      if (warrantyComponentsDetailsField) {
        warrantyComponentsDetailsField.value = warrantyComponentsValue;
        warrantyComponentsDetailsField.overrideRowValue = true;
      }

      formElementsEdit.fields = [...formElementsEdit.fields];
      setFormFields(formElementsEdit.fields);
    }
  }, [warrantyComponents]);

  const updateStatus = useCallback(
    async (data: WorkflowNextStatus) => {
      setIsResubmitAction(data?.name === 'Resubmit');
      doUpdateStatus({
        id: Number(id),
        statusTo: data.statusTo,
        actionId: data.actionId,
      });
    },
    [doUpdateStatus, id],
  );

  const useUpdateInvoice = useCallback(() => {
    const doUpdate = async data => {
      const costLaborItemsMap =
        data.costLaborItems?.map(item => Number(item.totalCost) || 0) || [];
      setOpenZeroTotalInfo(false);
      const problemId = data.problem?.id || data.problemId;
      const assetId = data.asset?.id || data.assetId;
      const invoice: any = {
        id: Number(id) ?? 0,
        invoiceNumber: summaryFieldsValues.spInvoiceNumber,
        costLaborItems: data.costLaborItems?.map(item => ({
          ...item,
          costTypeId: item?.costType?.id,
          unitCount: Number(item?.unitCount),
          unitCost: Number(item?.unitCost),
          totalCost: Number(item?.totalCost),
        })),
        costLaborItemsTotal:
          costLaborItemsMap?.reduce((a, b) => a + b, 0)?.toFixed(2) || 0,
        costTax: data.costTax,
        costMaterial: data.costMaterial,
        costOther: data.costOther,
        costFreight: data.costFreight,
        requestId: data.requestId,
        description: data.description,
        invoiceDate: data.invoiceDate,
        problemId,
        assetId,
        categoryId: summaryFieldsValues.categoryId,
        warrantyUsed: data.warrantyUsed ? 1 : 0,
        flagsIds: data.flags.map(flag => flag.id),
        warrantyComponents: data.warrantyComponents?.map(warrantyComponent => ({
          id: warrantyComponent?.warranty?.id,
          repairId: warrantyComponent?.repairId,
          assetId,
          noWarranty: warrantyComponent?.warranty?.noWarranty ? 1 : 0,
          resolutionId: warrantyComponent?.warranty?.resolutionId,
          duration: warrantyComponent?.warranty?.period,
          periodCode: warrantyComponent?.warranty?.periodCode,
        })),
        rejectWarrantyReasonId: data.warrantyUsed
          ? undefined
          : data.rejectWarrantyReason?.id,
        rejectWarrantyReasonOther:
          data.warrantyUsed || data.rejectWarrantyReason?.name !== 'Other'
            ? undefined
            : data.rejectWarrantyReasonOther,
      };
      const totalCost = costTableData
        ? sumCurrencyFields(
            ...costTableData?.itemsRepair.map(entry => {
              let dataPath = entry.dataPath as string;
              if (entry.dataPath === 'costLaborItems')
                dataPath = 'costLaborItemsTotal';
              return invoice[dataPath as string] ?? 0;
            }),
          )
        : 0;
      if (totalCost === 0) {
        setOpenZeroTotalInfo(true);
        const zeroInfoBox = document.querySelector('#ec-costtable-total');
        // If the total cost is not visible, scroll to the element for the pop-up to be visible
        if (zeroInfoBox && zeroInfoBox.getBoundingClientRect()?.top < 0) {
          zeroInfoBox?.scrollIntoView();
        }
        return;
      }

      delete invoice.costLabor;
      doUpdateInvoice(invoice);
    };
    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isSuccess: isUpdateSuccess,
        isLoading: isUpdateLoading,
        reset: resetUpdateInvoice,
      },
    ];
  }, [
    doUpdateInvoice,
    summaryFieldsValues,
    id,
    isUpdateError,
    isUpdateLoading,
    isUpdateSuccess,
    updateError,
    costTableData,
    resetUpdateInvoice,
  ]);

  const invoiceDetailsData = useMemo(() => {
    // if BE returns assigneTo empty it means that the field should not be shown
    const assignedTo = invoice?.assignedTo ? assignedToUsers : null;

    const onSiteTime =
      invoice?.onSiteTime > 0 ? formatDecimalHours(invoice.onSiteTime) : '--';

    const approvedDate =
      invoice?.workflowStatus?.name === WorkflowStatus.Approved
        ? customDateFormatter({ raw: invoice.approvedDate, fallback: '-' })
        : '-';

    return {
      ...invoice,
      moduleName: 'invoice',
      assignedTo,
      onSiteTime,
      approvedDate,
    };
  }, [invoice, assignedToUsers]);

  const handleSummaryFieldChange = (fieldName: string) => (value: any) => {
    setSummaryFieldsValues({ ...summaryFieldsValues, [fieldName]: value });
  };

  const invoiceSummaryData = useMemo(() => {
    const summaryFields: SummaryFieldsData[] = [
      {
        id: 'status',
        label: 'Status',
        type: SummaryFieldTypes.Text,
        data: invoice?.workflowStatus.name,
      },
      {
        id: 'location',
        label: 'Location',
        type: SummaryFieldTypes.Text,
        data: invoice?.request?.asset?.branch?.name || '',
      },
      {
        id: 'spInvoiceNumber',
        label: 'SP Invoice Number',
        maxLength: 100,
        type: SummaryFieldTypes.Text,
        data: summaryFieldsValues?.spInvoiceNumber,
        onChange: handleSummaryFieldChange('spInvoiceNumber'),
        isEditable: invoice?.mutableFields?.includes('spInvoiceCode'),
      },
      {
        id: 'storeNumber',
        label: 'Store Number',
        type: SummaryFieldTypes.Text,
        data: invoice?.request?.asset?.branch?.storeNumber || '',
      },
      {
        id: 'asset',
        label: 'Asset',
        type: SummaryFieldTypes.Text,
        data: invoice?.request.asset?.name,
      },
      {
        id: 'customer',
        label: 'Customer',
        type: SummaryFieldTypes.Text,
        data: invoice?.request?.customer?.name || '',
      },
    ];
    if (dropdownCategoryList.length) {
      summaryFields.push({
        id: 'category',
        label: 'Category',
        data: summaryFieldsValues?.categoryId,
        type: SummaryFieldTypes.Select,
        isEditable: invoice?.mutableFields?.includes('requestCategoryId'),
        onChange: optionData =>
          handleSummaryFieldChange('categoryId')(optionData?.data),
        options: dropdownCategoryList,
        required: true,
      });
    } else {
      summaryFields.push({
        id: 'category',
        label: 'Category',
        type: SummaryFieldTypes.Text,
        data: invoice?.request.category.name,
      });
    }
    if (invoice?.coupaSetting?.coupaInvoiceUrl) {
      summaryFields.push({
        id: 'coupaId',
        label: '',
        coupaLinkText: 'CLICK HERE TO VIEW THE INVOICE IN COUPA',
        type: SummaryFieldTypes.CoupaCode,
        data: invoice?.coupaId,
        href: invoice?.coupaSetting?.coupaInvoiceUrl || '',
      });
    }
    return summaryFields;
  }, [invoice, summaryFieldsValues, dropdownCategoryList]);

  useEffect(() => {
    if (invoice) {
      setSummaryFieldsValues({
        spInvoiceNumber: invoice.invoiceNumber,
        categoryId: invoice.request.category.id,
      });
    }
  }, [invoice]);

  const invoiceAsset = useMemo(() => {
    return invoice?.request.asset?.id && !invoice?.request?.pmId
      ? {
          href: String(invoice?.request.asset?.id),
          text: invoice?.request.asset?.name || '',
          order: 4,
        }
      : undefined;
  }, [invoice]);

  const invoiceWorkOrder = useMemo(() => {
    return invoice
      ? {
          ...invoice?.workorderLink,
          order: 3,
        }
      : undefined;
  }, [invoice]);

  const invoicePm = useMemo(() => {
    return invoice?.request?.pmId
      ? {
          href: invoice?.request?.pmId?.toString(),
          text:
            invoice?.request?.pm?.name ||
            invoice?.request?.pmId.toString() ||
            '',
          order: 5,
        }
      : undefined;
  }, [invoice]);

  const invoiceProposal = useMemo(() => {
    return invoice
      ? {
          content: invoice.proposals,
          order: 2,
        }
      : undefined;
  }, [invoice]);

  const invoices = useMemo(() => {
    return invoice
      ? {
          content: [
            {
              href: invoice.id.toString(),
              text: invoice.viewId,
            },
          ],
          order: 1,
        }
      : undefined;
  }, [invoice]);

  const isAsset = useMemo(
    () => _.isNil(invoice?.request?.asset?.generalAsset),
    [invoice?.request?.asset?.generalAsset],
  );

  const moduleName = useMemo(() => {
    return isAsset ? 'asset' : 'general-asset';
  }, [isAsset]);

  useEffect(() => {
    if (invoice) {
      attachmentTrigger({
        module: moduleName,
        moduleId:
          invoice?.request?.asset?.generalAsset?.id ||
          invoice?.request?.asset?.id,
        category: AttachmentCategory.Photo,
      });
    }

    if (invoice?.request.category.name === 'Maintenance') {
      const groupedFieldsEdit = formElementsEdit.fields?.find(
        field => field.type === 'group',
      ).subFields;

      const groupedFieldsDetails = detailsForm.fields?.find(
        field => field.type === 'group',
      ).subFields;

      // problem override
      const problemFieldEdit = groupedFieldsEdit.find(
        field => field.fieldName === 'problem',
      );

      problemFieldEdit.overrideRowValue = true;
      problemFieldEdit.type = 'text_field';
      problemFieldEdit.value = 'Scheduled Service';
      problemFieldEdit.readOnly = true;

      const problemFieldDetails = groupedFieldsDetails.find(
        field => field.fieldName === 'problem',
      );

      problemFieldDetails.overrideRowValue = true;
      problemFieldDetails.type = 'text_field';
      problemFieldDetails.value = 'Scheduled Service';

      // failure types override

      const warrantyComponentsEditField = groupedFieldsEdit.find(
        field => field.fieldName === 'warrantyComponents',
      );
      warrantyComponentsEditField.overrideRowValue = true;
      warrantyComponentsEditField.type = 'text_field';
      warrantyComponentsEditField.value = ['Scheduled Service'];
      warrantyComponentsEditField.readOnly = true;

      const warrantyComponentsDetailsField = groupedFieldsDetails.fields?.find(
        field => field.fieldName === 'warrantyComponents',
      );
      if (warrantyComponentsDetailsField) {
        warrantyComponentsDetailsField.overrideRowValue = true;
        warrantyComponentsDetailsField.type = 'text_field';
        warrantyComponentsDetailsField.value = ['Scheduled Service'];
      }

      formElementsEdit.fields = [...formElementsEdit.fields];
      setFormFields(formElementsEdit.fields);
    }
  }, [invoice]);

  // on change edit form to handle the warranty used in repair fields
  const handleOnChange = useCallback(
    (output, fieldname, value) => {
      // if user changes warrantyUsed toggle, show or hide the rejectWarrantyReason field
      if (
        fieldname === 'warrantyUsed' ||
        fieldname === 'rejectWarrantyReason'
      ) {
        const leftSideFieldsGroupEditFields = output.find(
          field => field.groupName === 'leftSideFieldsGroup',
        ).subFields;

        const rejectWarrantyReasonOtherField =
          leftSideFieldsGroupEditFields?.find(
            field => field.fieldName === 'rejectWarrantyReasonOther',
          );

        if (fieldname === 'warrantyUsed') {
          const rejectWarrantyReasonField = leftSideFieldsGroupEditFields?.find(
            field => field.fieldName === 'rejectWarrantyReason',
          );

          if (rejectWarrantyReasonField)
            rejectWarrantyReasonField.visible = !value;
          rejectWarrantyReasonField.required =
            !value &&
            ['active', 'partial'].includes(
              assetWarranty?.[0]?.status?.toLowerCase(),
            );
          if (rejectWarrantyReasonOtherField)
            rejectWarrantyReasonOtherField.visible =
              !value &&
              rejectWarrantyReasonField?.value?.name?.toLowerCase() === 'other';
        }

        if (
          fieldname === 'rejectWarrantyReason' &&
          rejectWarrantyReasonOtherField
        ) {
          rejectWarrantyReasonOtherField.visible =
            value.name?.toLowerCase() === 'other';
          rejectWarrantyReasonOtherField.required =
            value.name?.toLowerCase() === 'other';
        }
      }
    },
    [assetWarranty],
  );

  return (
    <>
      <ECWorkflowTemplate
        tabsAsLinks
        summaryData={invoiceSummaryData}
        showAddCommentButton
        title={`Invoice #${invoice?.viewId}`}
        detailsData={invoiceDetailsData}
        detailsConfig={detailsForm.config}
        detailsFields={detailsForm.fields}
        editConfig={formElementsEdit.config}
        editFields={formFields}
        onChange={handleOnChange}
        imgSrc={invoice?.assetProfilePhoto || assetProfileAttachment?.url}
        profileAttachment={assetProfileAttachment}
        moduleName={moduleName}
        moduleId={id}
        warrantyBadgeType={assetWarranty?.[0]?.status}
        useUpdateMutation={useUpdateInvoice}
        isEditAllowed={
          hasEditInvoicePermission[0] && !!invoice?.mutableFields?.length
        }
        showEditTitleStartAdornment
        asset={invoiceAsset}
        workOrder={invoiceWorkOrder}
        proposals={invoiceProposal}
        pm={invoicePm}
        invoices={invoices}
        costTableData={costTableData}
        actions={workflowStatusList}
        onEditButtonClick={setIsEditMode}
        onActionClick={updateStatus}
        disableAditionalActionWhenEditing
        checkNothingToUpdateEditForm={false}
        isLoadingActions={isLoadingActions}
        showSkeletonLoader={isLoadingInvoiceFromQuery}
      />
      {isDetailsTab && (
        <Popover
          id={'popover-users-table'}
          open={open}
          anchorEl={anchorEl}
          onClose={onCloseModal}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            minHeight: '300px',
            minWidth: '300px',
          }}
        >
          <ECBox
            minHeight={150}
            minWidth={650}
            maxHeight={400}
            sx={{
              '&::before': {
                backgroundColor: 'white',
                content: '""',
                display: 'block',
                position: 'absolute',
                width: 12,
                height: 12,
                top: -6,
                transform: 'rotate(45deg)',
                left: 'calc(50% - 6px)',
              },
            }}
            p={2}
          >
            <ECUsersTableModal
              module="proposal"
              preExistingUsers={assignedToUsers}
              id={invoice?.id}
            />
          </ECBox>
        </Popover>
      )}
    </>
  );
}
