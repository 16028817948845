import {
  ECEasyFormFieldType,
  ECLoadingProgress,
  ECPaper,
} from 'app/components';
import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { useUpdateMultipleStatusMutation } from 'services/dashboardApi';
import { themes } from 'styles/theme/themes';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import {
  BulkStatusActions,
  FlagBulkEditOptionEnum,
  StatusActions,
} from 'types/WorkflowStatus';
import { QueryTag } from '../DashboardPage/actions';
import {
  proposalApi,
  useBulkProposalFlagsChangeMutation,
} from 'services/proposalApi';
import { useGetApprovalActionsQuery } from 'services/approvalApi';
import { populateDropDownFields } from 'utils/pageUtils';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { StyleConstants } from 'styles/StyleConstants';
import { useScheduledJob } from 'app/hooks/useScheduledJob.use-case';

const fancyFormElementsBulkEdit = require('./fancy_form_config_bulk_edit.json');
const fancyFormElementsBulkEditDone = require('./fancy_form_config_bulk_done.json');

interface BulkEditDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  selectedRows?: any[];
}

export const BulkEditDrawer = ({
  isOpen,
  selectedRows,
  onClose,
}: BulkEditDrawerProps) => {
  const dispatch = useDispatch();
  const [isDone, setIsDone] = useState(false);
  const [actionStatus, setActionStatus] = useState<BulkStatusActions>(
    BulkStatusActions.None,
  );
  const [doneFields, setDoneFields] = useState<any[]>(
    fancyFormElementsBulkEditDone.fields,
  );
  const [comment, setComment] = useState(null);
  const [flagsValue, setFlagsValue] = useState<any>([]);
  const [flagsOperationTypeValue, setFlagsOperationTypeValue] =
    useState<FlagBulkEditOptionEnum>();
  const [formFields, setFormFields] = useState<any[]>(
    _.cloneDeep(fancyFormElementsBulkEdit.fields),
  );

  const {
    data: flags,
    isLoading: isLoadingFlags,
    isSuccess: isSuccessFlags,
  } = useGetFlagsListQuery({});

  useEffect(() => {
    if (flags && isSuccessFlags) {
      const localEditFormElements = [...formFields];
      let radioFields = localEditFormElements?.find(
        field => field.fieldName === 'radioGroup',
      )?.options;
      let flagsGroupSubFields = radioFields?.find(
        field => field.groupName === 'flagsGroup',
      )?.fields;
      const flagsField = flagsGroupSubFields?.find(
        field => field.fieldName === 'flags',
      );

      flagsField.options =
        flags?.data.map(d => ({
          label: d.label,
          color: d.color,
          id: d.id,
        })) || [];
      flagsField.optionValues = flags?.data.map(d => d.label) || [];
    }
  }, [isSuccessFlags, flags, isOpen]);

  useEffect(() => {
    if (isOpen) {
      const updated = [...formFields];
      const woCountLabelField = updated?.find(
        field => field.fieldName === 'proposalCountLabel',
      );
      if (woCountLabelField) {
        woCountLabelField.label = 'Proposals Selected: ' + selectedRows?.length;
      }
      const selectedProposalsField = updated?.find(
        field => field.fieldName === 'selectedProposals',
      );
      selectedProposalsField.value =
        selectedRows?.map(d => ({
          label: d.id,
          color: 'Grey',
          id: d.id,
        })) || [];
      setFormFields(updated);
    }
  }, [isOpen, selectedRows]);

  const [
    doUpdateMutation,
    {
      data: updateStausData,
      isSuccess,
      isError,
      isLoading,
      reset: resetStatus,
    },
  ] = useUpdateMultipleStatusMutation();

  const [
    doFlagsChangeMutation,
    {
      data: flagsChangeData,
      isSuccess: flagsChangeSuccess,
      isError: flagsChangeError,
      isLoading: flagsChangeLoading,
      reset: resetFlagsChange,
    },
  ] = useBulkProposalFlagsChangeMutation();

  const {
    scheduledJobData,
    isScheduledJobCompleted,
    showScheduledJobProgress,
    clearJobIdAndProgress,
    isScheduledJobSuccess,
  } = useScheduledJob({
    isLoadingJobInfo: isLoading,
    isJobInfoSuccess: isSuccess,
    jobInfo: updateStausData,
  });
  const isLoadingDrawer =
    isLoading || showScheduledJobProgress || flagsChangeLoading;

  const module = 'proposal';
  const { data: actionsList } = useGetApprovalActionsQuery(undefined, {
    skip: !isOpen,
  });

  const actions = useMemo(() => {
    return actionsList?.filter(
      action => action.name !== StatusActions.NOTFIXED,
    );
  }, [actionsList]);

  useEffect(() => {
    if (actions) {
      const localEditFormElements = [...formFields];
      let radioFields = localEditFormElements?.find(
        field => field.fieldName === 'radioGroup',
      )?.options;
      let statusGroupSubFields = radioFields?.find(
        field => field.groupName === 'statusGroup',
      )?.fields;
      populateDropDownFields({
        responseData: actions,
        createFormFields: statusGroupSubFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'statusCommand',
      });
      setFormFields(localEditFormElements);
    }
  }, [actions]);

  const useBulkEdit = useCallback(() => {
    const bulkEdit = async data => {
      if (data?.statusCommand) {
        const selectedStatus = actions?.find(
          action => action.id === data?.statusCommand,
        );

        const statusUpdateList = {
          itemIds: data?.selectedProposals?.map(row => row.id),
          actionId: selectedStatus?.id,
          note: data?.rejectReason,
        };

        setComment(data?.rejectReason);

        setActionStatus(
          selectedStatus?.name === StatusActions.APPROVE
            ? BulkStatusActions.Approved
            : selectedStatus?.name === StatusActions.NOTFIXED
              ? BulkStatusActions.NotFixed
              : BulkStatusActions.Rejected,
        );
        doUpdateMutation({ module, statusUpdateList });
      } else if (data?.flags && data?.flagOperationType) {
        setFlagsValue(data?.flags);
        setFlagsOperationTypeValue(
          FlagBulkEditOptionEnum[data?.flagOperationType] as any,
        );

        const params: any = {
          itemIds: data?.selectedProposals?.map(invoice => invoice.id),
          flagIds: data?.flags.map(flag => flag.id),
          flagOptionId: FlagBulkEditOptionEnum[data?.flagOperationType],
        };

        doFlagsChangeMutation(params);
      }
    };
    return [bulkEdit, { updateStausData, isSuccess, isError, isLoading }];
  }, [
    doUpdateMutation,
    doFlagsChangeMutation,
    updateStausData,
    isSuccess,
    isError,
    isLoading,
    actions,
  ]);

  const handleOutputChange = (
    output: ECEasyFormFieldType[],
    fieldName?: string,
  ) => {
    if (fieldName === 'statusCommand') {
      const radioGroupField = output?.find(
        field => field.fieldName === 'radioGroup',
      );
      const statusGroupFields = radioGroupField?.options?.find(
        field => field.groupName === 'statusGroup',
      )?.fields;
      const statusField = statusGroupFields?.find(
        field => field.fieldName === 'statusCommand',
      );

      const selectedStatus = actions?.find(
        action => action.id === statusField?.value,
      );
      const isSelectedStatusReject =
        selectedStatus?.name === StatusActions.REJECT;

      const rejectReasonField = statusGroupFields?.find(
        field => field.fieldName === 'rejectReason',
      );
      rejectReasonField.required = isSelectedStatusReject;
      rejectReasonField.visible = isSelectedStatusReject;

      setFormFields(output);
    }
  };

  const handleClose = useCallback(() => {
    resetStatus();
    resetFlagsChange();
    setIsDone(false);
    clearJobIdAndProgress();
    setFormFields(_.cloneDeep(fancyFormElementsBulkEdit.fields));
    onClose?.();
  }, [onClose, resetStatus, resetFlagsChange, clearJobIdAndProgress]);

  useEffect(() => {
    let operationRows = (selectedRows || []).map(
      ({ id, flags, workflowStatus, request }) => ({
        itemId: id,
        statusTo: actionStatus,
        flagsFrom: flags,
        statusFrom: workflowStatus.name,
        categoryIdFrom: request?.category?.id,
        CategoryNameFrom: request?.category?.name,
        statusOutcome: actionStatus ? true : null,
      }),
    );
    if (actionStatus === BulkStatusActions.Rejected) {
      operationRows = operationRows.map(row => ({
        ...row,
        rejectionReason: comment,
      }));
    }
    if (!isLoading && isScheduledJobSuccess && isScheduledJobCompleted) {
      const rejectApprovePerform = (
        itemId: number,
        success: boolean,
        reason?: string,
      ) => {
        return operationRows?.map(row =>
          row.itemId === itemId
            ? {
                ...row,
                statusTo: actionStatus,
                statusOutcome: success,
                statusFailReason: success ? undefined : reason,
              }
            : row,
        );
      };
      scheduledJobData?.success?.forEach(itemId => {
        operationRows = rejectApprovePerform(itemId, true);
      });

      scheduledJobData?.failed?.forEach(item => {
        operationRows = rejectApprovePerform(item?.id, false, item?.reason);
      });

      const updatedFields = [...doneFields];
      const bulkActivityViewField = updatedFields?.find(
        field => field.fieldName === 'bulkActivityView',
      );
      bulkActivityViewField.value = operationRows;
      setDoneFields(updatedFields);
      setIsDone(true);
      resetStatus();
    }

    if (flagsChangeSuccess && !flagsChangeLoading) {
      const flagsValuesIds = flagsValue?.map(flag => flag.id);
      operationRows = operationRows.map(row => ({
        ...row,
        flagsOutcome: true,
        statusOutcome: null,
        flagsFrom:
          flagsOperationTypeValue !==
          FlagBulkEditOptionEnum['Add Selected Flags']
            ? row.flagsFrom?.map(flag => ({
                label: flag.label,
                isStrikeThrough:
                  flagsOperationTypeValue ===
                    FlagBulkEditOptionEnum['Override with Selected Flags'] ||
                  flagsValuesIds?.includes(flag.id),
                id: flag.id,
              }))
            : [],
        flagsTo:
          flagsOperationTypeValue ===
          FlagBulkEditOptionEnum['Remove Selected Flags']
            ? row.flagsFrom
                ?.filter(flag => !flagsValuesIds?.includes(flag.id))
                ?.map(flag => ({
                  label: flag.label,
                  id: flag.id,
                }))
            : flagsValue?.map(flag => ({
                label: flag.label,
                id: flag.id,
              })),
      }));
      const updatedFields = [...doneFields];
      const bulkActivityViewField = updatedFields?.find(
        field => field.fieldName === 'bulkActivityView',
      );
      bulkActivityViewField.value = operationRows;
      setIsDone(true);
      setDoneFields(updatedFields);
      resetFlagsChange();
    }
  }, [
    updateStausData,
    isError,
    isLoading,
    isSuccess,
    onClose,
    resetStatus,
    resetFlagsChange,
    selectedRows,
    doneFields,
    flagsChangeSuccess,
    flagsChangeLoading,
    actionStatus,
    flagsValue,
    flagsOperationTypeValue,
    isScheduledJobCompleted,
    isScheduledJobSuccess,
    scheduledJobData,
  ]);

  useEffect(() => {
    if (isSuccess && isScheduledJobCompleted) {
      dispatch(proposalApi.util.invalidateTags(['Proposal', 'ProposalById']));
    }
  }, [isSuccess, dispatch, isScheduledJobCompleted]);

  const useDone = useCallback(() => {
    return [
      () => {
        handleClose();
      },
      {
        data: null,
        isError: false,
        error: null,
        isLoading: false,
        isSuccess: false,
      },
    ];
  }, [handleClose]);

  return (
    <ECDrawerDetails open={isOpen} anchor="right" onClose={handleClose}>
      <ECPaper
        sx={{
          height: '100%',
          paddingTop: StyleConstants.NAV_BAR_HEIGHT,
          boxShadow: 'none',
        }}
        role="presentation"
      >
        <ECEasyFormCreate
          useCreateMutation={isDone ? useDone : useBulkEdit}
          formConfig={
            isDone
              ? fancyFormElementsBulkEditDone.config
              : fancyFormElementsBulkEdit.config
          }
          formFields={isDone ? doneFields : formFields}
          onClose={handleClose}
          drawerTitleBarBGColor={themes.light.palette.other.divider}
          CustomLoadingScreen={<ECLoadingProgress />}
          CustomErrorScreen={<ECLoadingProgress />}
          CustomSuccessScreen={<ECLoadingProgress />}
          isLoading={isLoadingDrawer}
          onChange={handleOutputChange}
          hideCancelButton={isDone}
          showSuccessSnackBar={false}
        />
      </ECPaper>
    </ECDrawerDetails>
  );
};
