import { InputAdornment } from '@mui/material';
import { setActiveFilter } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close, SearchOutlined } from '@mui/icons-material';
import { FilterFieldOption } from 'app/components/ECTable';
import { ECBox } from 'app/components/ECBox';
import { ECTextField } from 'app/components/ECTextField';
import { ECIconButton } from 'app/components/ECIconButton';
import { ECButton } from 'app/components/ECButton';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { DEFAULT_MIN_SEARCH_LENGTH } from 'utils/constants';

interface ECSimpleSearchProps {
  cols?: any[];
  hiddenCols?: any[];
  minSearchLength?: number;
  onFilterSubmit?: () => void;
  statusFilterOptions?: FilterFieldOption[];
  isSendingRequest?: boolean;
}

export const ECSimpleSearch = ({
  minSearchLength = DEFAULT_MIN_SEARCH_LENGTH,
}: ECSimpleSearchProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const pageState = useSelector((state: RootState) => state.page);

  const endpoint = useMemo(() => {
    return pageState.filter.endpoint;
  }, [pageState]);

  const activeFilter = useMemo(() => {
    return pageState.filter.activeFilter?.[endpoint];
  }, [pageState]);

  const pageContext = useMemo(() => {
    return pageState.context[window.location.pathname];
  }, [pageState]);

  const [simpleSearchQuery, setSimpleSearchQuery] = useState<string>(
    activeFilter?.simpleSearchQuery || '',
  );

  const [showSimpleSearchErrorMessage, setShowSimpleSearchErrorMessage] =
    useState<boolean>(false);

  const cleanupSimpleSearch = useCallback(() => {
    setSimpleSearchQuery('');
    setShowSimpleSearchErrorMessage(false);
    dispatch(
      setActiveFilter({
        filterFields: [],
        isAdvanced: false,
        simpleSearchQuery: '',
        filterPath: endpoint,
      }),
    );
  }, [endpoint]);

  const handleRunSimpleSearch = useCallback(() => {
    if (pageContext?.isLoadingRequest) return;
    if (simpleSearchQuery?.trim()?.length < minSearchLength) {
      setShowSimpleSearchErrorMessage(true);
      return;
    }

    setShowSimpleSearchErrorMessage(false);
    dispatch(
      setActiveFilter({
        isAdvanced: false,
        simpleSearchQuery,
        filterPath: endpoint,
      }),
    );
  }, [simpleSearchQuery, minSearchLength]);

  return (
    <>
      <ECBox
        borderColor={theme => theme.palette.action.active}
        sx={{
          tabIndex: '0',
          display: 'flex',
          width: '440px',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: 0,
          p: 0.5,
        }}
      >
        <ECTextField
          variant="standard"
          sx={{
            minWidth: '100%',
            '.MuiFormHelperText-root': {
              color: theme => theme.palette.graphic.alert.error,
            },
          }}
          placeholder={t('translation:table.search')}
          value={simpleSearchQuery}
          helperText={
            showSimpleSearchErrorMessage
              ? t('translation:table.atLeastNChars', {
                  minLength: minSearchLength,
                })
              : undefined
          }
          onKeyDown={event => {
            if (event.key === 'Enter') {
              handleRunSimpleSearch();
            }
          }}
          onChange={event => {
            setSimpleSearchQuery(event.target.value);
            if (!event.target.value) {
              cleanupSimpleSearch();
            }
          }}
          InputProps={{
            endAdornment: simpleSearchQuery ? (
              <InputAdornment position="end">
                <ECIconButton onClick={cleanupSimpleSearch}>
                  <Close />
                </ECIconButton>
              </InputAdornment>
            ) : (
              <></>
            ),
          }}
        />
      </ECBox>
      <ECButton
        variant="contained"
        sx={{ height: '40px', width: '40px', minWidth: '40px' }}
        onClick={handleRunSimpleSearch}
      >
        {pageContext?.isLoadingRequest ? (
          <ECCircularProgress
            size="24px"
            sx={{
              color: theme => theme.palette.background.paper,
            }}
          />
        ) : (
          <SearchOutlined />
        )}
      </ECButton>
    </>
  );
};
