import { ECBox } from '../ECBox';
import { ECCard, ECCardContent, ECCardHeader } from '../ECCard';
import { ECTypography } from '../ECTypography';
import { ECWarrantyBadge } from '../ECWarrantyBadge';
import { Invoice } from './index';
import { customDateFormatter } from 'utils/dates';
import { WarrantyBadgeTypes } from '../ECWarrantyBadge';

interface PreviousInvoiceCardProps {
  data?: Invoice[] | [];
  warrantyBadgeType?: WarrantyBadgeTypes | null;
}

export const PreviousInvoiceCard: React.FC<PreviousInvoiceCardProps> = ({
  data,
  warrantyBadgeType,
}) => {
  const recentInvoices = data?.slice(0, 3);

  return (
    <ECBox display="flex" flexDirection="column" gap={2} width={'100%'}>
      {recentInvoices?.map(invoice => (
        <ECCard
          key={invoice.id}
          sx={{
            borderRadius: '4px',
            background: 'white',
          }}
        >
          <ECCardHeader
            titleTypographyProps={{
              variant: 'h6',
              fontWeight: 'bold',
              color: 'textPrimary',
            }}
            title={
              <ECBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <ECTypography variant="h6">
                  Previous Invoice: {invoice.id}
                </ECTypography>
                {warrantyBadgeType && (
                  <ECWarrantyBadge warrantyBadgeType={warrantyBadgeType} />
                )}
              </ECBox>
            }
            sx={{ borderBottom: '1px solid #E5E5E5' }}
          />
          <ECCardContent>
            <ECTypography variant="body2" color="textSecondary">
              Date(order by):{' '}
              {customDateFormatter({
                raw: invoice.invoiceDate,
                format: 'dd/MM/yyyy',
              })}
            </ECTypography>
            <ECTypography variant="body2" fontWeight="400" mt={2}>
              Failure Types
            </ECTypography>
            {invoice.warrantyComponents.length === 0 ? (
              <ECTypography>No Warranty</ECTypography>
            ) : (
              invoice.warrantyComponents.map((component, index) => (
                <ECBox key={index} ml={2} mt={1}>
                  <ECTypography variant="body2" fontWeight="bold">
                    {component?.repair?.name}
                  </ECTypography>
                  <ECTypography variant="body2">
                    {component?.duration
                      ? `Warranty Period: ${component.duration} ${component.period.description}`
                      : 'No Warranty'}
                  </ECTypography>
                  <ECTypography variant="body2">
                    Resolution: {component?.resolution?.name}
                  </ECTypography>
                </ECBox>
              ))
            )}
          </ECCardContent>
        </ECCard>
      ))}
    </ECBox>
  );
};
