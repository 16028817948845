/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Chart, registerables } from 'chart.js';
import AppNavigator from './navigators/AppNavigator';
import { ECSnackbar } from './components';
import { Portal } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';

Chart.register(...registerables);

export function App() {
  const { i18n } = useTranslation();

  const { id, ...snackbarProps } = useSelector(
    (state: RootState) => state.page.snackbar,
  );

  return (
    <div id="app-container">
      <Helmet
        titleTemplate="Ecotrak - %s"
        defaultTitle="Ecotrak Web"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Ecotrak Web" />
      </Helmet>

      <AppNavigator />

      <Portal>
        <ECSnackbar id={id} isOpen={!!id} {...snackbarProps} />
      </Portal>
    </div>
  );
}
