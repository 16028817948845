import { ECBox } from 'app/components/ECBox';
import { ECStepsContainer } from 'app/components/ECSteps';
import { ECTypography } from 'app/components/ECTypography';
import { useMemo, useState } from 'react';
import { Step1 } from './step-1';
import { Step2 } from './step-2';
import { Step3 } from './step-3';
import { StyleConstants } from 'styles/StyleConstants';
import { Close } from '@mui/icons-material';
import { ECIconButton } from 'app/components';
import { useTheme } from '@mui/material';

interface EditProps {
  onClose?: () => void;
}

export const AddNewForm = ({ onClose }: EditProps) => {
  const [data, setData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const theme = useTheme();

  const renderTabs = useMemo(
    () => [
      {
        value: 1,
        label: '1',
        subLabel: 'Basic Information',
        subLabelBold: true,
        content: (
          <Step1
            onClose={onClose}
            existingData={data}
            onSubmit={data => {
              setData(data);
              setCurrentStep(2);
            }}
          />
        ),
      },
      {
        value: 2,
        label: '2',
        subLabel: 'Lease Details',
        subLabelBold: true,
        content: (
          <Step2
            existingData={data}
            onClose={onClose}
            onSubmit={data => {
              setData(data);
              setCurrentStep(3);
            }}
          />
        ),
      },
      {
        value: 3,
        label: '3',
        subLabel: 'Review',
        subLabelBold: true,
        content: <Step3 existingData={data} onClose={onClose} />,
      },
    ],
    [data, onClose],
  );

  return (
    <ECBox
      display="flex"
      position="relative"
      height="100%"
      flexDirection="column"
    >
      <ECBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={4}
        py={2}
        bgcolor={theme => theme.palette.other.divider}
        height={StyleConstants.DRAWER_TITLE_HEIGHT}
      >
        <ECTypography fontWeight="bold" variant="h4">
          Add Location
        </ECTypography>
        <ECIconButton
          sx={{
            background: theme.palette.common.white,
            borderRadius: 3,
            color: `${theme.palette.text.button.actionPrimary} !important`,
          }}
          squared
          onClick={() => {
            onClose?.();
          }}
        >
          <Close
            sx={{
              color: theme.palette.button.actionSecondary,
            }}
          />
        </ECIconButton>
      </ECBox>

      <ECStepsContainer
        stepsContainerProps={{
          px: 3,
          pt: 4,
        }}
        tabs={renderTabs}
        currentStep={currentStep}
        onChangeStep={setCurrentStep}
        selectableSteps
      />
    </ECBox>
  );
};
