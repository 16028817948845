import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ECBox } from '../ECBox';
import { ECTextField } from '../ECTextField';
import { ECTree, TreeNode } from '../ECTree';
import { ECTypography } from '../ECTypography';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  ArrowDropDown,
  ArrowDropUp,
  Close,
  Notifications,
} from '@mui/icons-material';
import { Popover } from '@mui/material';
import { findNode, findNodeById } from 'utils/tree';
import { useCustomerUser } from 'app/hooks/customerUser.use-case';
import { useGetUsersByRoleNameAndModuleIdQuery } from 'services/userApi';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ECRichTextField } from '../ECRichTextField';
import { ECIconButton } from '../ECIconButton';
import { ECTabContainer } from '../ECTabs';

const SEARCH_DEBOUNCE_TIME = 500;
const COMMENT_MAX_LENGTH = 1000;

export interface ECAddCommentValueType {
  moduleId: number | undefined;
  moduleName: string | undefined;
  selectedUsers: TreeNode[];
  comment: string | undefined;
}

interface ECAddCommentProps {
  onSelectedValuesChange?: (value: ECAddCommentValueType) => void;
  moduleId: number | undefined;
  moduleName: string | undefined;
  value?: ECAddCommentValueType | null;
  required?: boolean;
  validationMessage?: string;
  maxLength?: number;
  useRichTextEditor?: boolean;
  customTitle?: string;
  variant?: any;
  placeholder?: string;
  disabled?: boolean;
  isSendButtonVisible?: boolean;
}

export const ECAddComment: React.FC<ECAddCommentProps> = ({
  onSelectedValuesChange,
  moduleId,
  moduleName,
  required,
  validationMessage,
  maxLength,
  useRichTextEditor = false,
  customTitle,
  variant,
  placeholder,
  disabled,
  isSendButtonVisible,
}) => {
  const { t } = useTranslation();
  const [newComment, setNewComment] = useState('');
  const isCustomer = useCustomerUser();
  const [initialTree, setInitialTree] = useState<any[]>([]);
  const [commentMaxLength] = useState(
    maxLength ? maxLength : COMMENT_MAX_LENGTH,
  );
  const [tree, setTree] = useState<TreeNode[]>([
    {
      label: '',
      nodeId: 0,
      organizationType: 0,
      parentNodeId: 0,
      children: [],
    },
  ]);
  const [nodesIds, setNodesIds] = useState<number[]>([]);
  const [currentCompanyInitialTree, setCurrentCompanyInitialTree] = useState<
    any[]
  >([]);
  const [currentCompanyTree, setCurrentCompanyTree] = useState<TreeNode[]>([
    {
      label: '',
      nodeId: 0,
      organizationType: 0,
      parentNodeId: 0,
      children: [],
    },
  ]);
  const [currentCompanyNodesIds, setCurrentCompanyNodesIds] = useState<
    number[]
  >([]);
  const { data: allUsers, isSuccess: isSuccessUsers } =
    useGetUsersByRoleNameAndModuleIdQuery(
      {
        moduleName: moduleName || '',
        role: 'DIRECT_NOTES',
        userType: 'CSP',
        moduleId: moduleId?.toString() || '0',
      },
      { skip: !moduleName },
    );

  const users = useMemo(() => {
    if (isCustomer) {
      return allUsers?.customersInfo;
    }
    return allUsers?.spUsersInfo;
  }, [allUsers, isCustomer]);

  const otherUsers = useMemo(() => {
    if (isCustomer) {
      return allUsers?.spUsersInfo;
    }
    return allUsers?.customersInfo;
  }, [allUsers, isCustomer]);

  const usersToMention = useMemo(() => {
    if (!useRichTextEditor) return [];
    return [
      ...(allUsers?.customersInfo || []),
      ...(allUsers?.spUsersInfo || []),
    ].map(user => {
      return {
        id: user.id,
        name: user.fullName,
        jobTitle: user.jobTitle?.name,
      };
    });
  }, [allUsers?.customersInfo, allUsers?.spUsersInfo, useRichTextEditor]);

  const notifiedCollapsedRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const handleCloseNotifiedModal = () => {
    setAnchorEl(null);
  };

  const selectedUsers: TreeNode[] = useMemo(() => {
    return _.compact(
      _.filter(
        nodesIds.map(nodeId => findNodeById(initialTree, nodeId)),
        node => !node?.children?.length && !!node,
      ),
    );
  }, [nodesIds, initialTree]);

  const selectedCurrentCompanyUsers: TreeNode[] = useMemo(() => {
    return _.compact(
      _.filter(
        currentCompanyNodesIds.map(nodeId =>
          findNodeById(currentCompanyInitialTree, nodeId),
        ),
        node => !node?.children?.length && !!node,
      ),
    );
  }, [currentCompanyNodesIds, currentCompanyInitialTree]);

  useEffect(() => {
    if (typeof onSelectedValuesChange === 'function') {
      onSelectedValuesChange({
        moduleId,
        moduleName,
        selectedUsers: [...selectedUsers, ...selectedCurrentCompanyUsers],
        comment: newComment,
      });
    }
  }, [
    newComment,
    selectedUsers,
    selectedCurrentCompanyUsers,
    onSelectedValuesChange,
  ]);

  const onCommentChangeHandler = event => {
    const commentValue = useRichTextEditor ? event : event?.target?.value;
    if (commentValue?.length < commentMaxLength) {
      setNewComment(commentValue);
    }
  };

  const mappedCurrentCompanyTree: TreeNode[] = useMemo(() => {
    const usersGroupedByJobTitle = _.chain(users)
      .filter(user => !!user?.jobTitleId)
      .groupBy('jobTitle.id')
      .map((value, key) => ({
        jobTitle: value[0]?.jobTitle,
        users: value,
      }))
      .value();

    return usersGroupedByJobTitle.map(usersByJobTitle => {
      const nodeId = usersByJobTitle.jobTitle?.id
        ? new Date().getTime() + usersByJobTitle.jobTitle.id
        : 0;

      return {
        label: `${usersByJobTitle?.jobTitle?.name} (${usersByJobTitle?.users?.length})`,
        nodeId,
        parentNodeId: undefined,
        children: usersByJobTitle?.users?.map(user => ({
          label: `${user.firstName} ${user.lastName}`,
          data: {
            email: user.email,
          },
          nodeId: user.id,
          parentNodeId: nodeId,
          children: [],
        })),
      };
    });
  }, [users]);

  const mappedTree: TreeNode[] = useMemo(() => {
    const usersGroupedByJobTitle = _.chain(otherUsers)
      .filter(user => !!user?.jobTitleId)
      .groupBy('jobTitle.id')
      .map((value, key) => ({
        jobTitle: value[0]?.jobTitle,
        users: value,
      }))
      .value();

    return usersGroupedByJobTitle.map(usersByJobTitle => {
      const nodeId = usersByJobTitle.jobTitle?.id
        ? new Date().getTime() + usersByJobTitle.jobTitle.id
        : 0;

      return {
        label: `${usersByJobTitle?.jobTitle?.name} (${usersByJobTitle?.users?.length})`,
        nodeId,
        parentNodeId: undefined,
        children: usersByJobTitle?.users?.map(user => ({
          label: `${user.firstName} ${user.lastName}`,
          data: {
            email: user.email,
          },
          nodeId: user.id,
          parentNodeId: nodeId,
          children: [],
        })),
      };
    });
  }, [otherUsers]);

  useEffect(() => {
    if (mappedTree && isSuccessUsers) {
      setTree(mappedTree);
      setInitialTree(mappedTree);
    }
  }, [isSuccessUsers, mappedTree]);

  useEffect(() => {
    if (mappedCurrentCompanyTree && isSuccessUsers) {
      setCurrentCompanyTree(mappedCurrentCompanyTree);
      setCurrentCompanyInitialTree(mappedCurrentCompanyTree);
    }
  }, [isSuccessUsers, mappedCurrentCompanyTree]);

  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (_.isNil(search)) {
      return;
    }

    if (mappedTree) {
      if (search === '') {
        setTree(mappedTree);
        return;
      }

      const filteredNodes = findNode(mappedTree, search, ['email']);
      setTree(filteredNodes);
    }
  }, [search, mappedTree]);

  const debouncedHandleSearchChange = useRef(
    _.debounce(e => {
      setSearch(e.target.value);
    }, SEARCH_DEBOUNCE_TIME),
  ).current;

  const [currentCompanySearch, setCurrentCompanySearch] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (currentCompanySearch === undefined) {
      return;
    }

    if (mappedCurrentCompanyTree) {
      if (currentCompanySearch === '') {
        setCurrentCompanyTree(mappedCurrentCompanyTree);
        return;
      }

      const filteredNodes = findNode(
        mappedCurrentCompanyTree,
        currentCompanySearch,
        ['email'],
      );
      setCurrentCompanyTree(filteredNodes);
    }
  }, [currentCompanySearch, mappedCurrentCompanyTree]);

  const debouncedHandleCurrentCompanySearchChange = useRef(
    _.debounce(e => {
      setCurrentCompanySearch(e.target.value);
    }, SEARCH_DEBOUNCE_TIME),
  ).current;

  const handleOnMentionUser = useCallback(
    (user: any) => {
      if (!useRichTextEditor) return;
      // need to check if the user is an SP or a customer

      const isTaggedUserCustomer = allUsers?.customersInfo?.some(
        u => u.id === user.id,
      );
      if (isTaggedUserCustomer) {
        setCurrentCompanyNodesIds(prev => {
          if (prev.includes(user.id)) {
            return prev;
          }
          return [...prev, user.id];
        });
      } else {
        setNodesIds(prev => {
          if (prev.includes(user.id)) {
            return prev;
          }
          return [...prev, user.id];
        });
      }
    },
    [setNodesIds, allUsers, setCurrentCompanyNodesIds, useRichTextEditor],
  );

  return (
    <ECBox
      display="flex"
      bgcolor={theme => theme.palette.background.default}
      flexDirection="column"
      height="100%"
    >
      <ECBox display="flex" justifyContent="flex-start" mb={-5}>
        <ECTypography variant="h5">
          {customTitle ? customTitle : t('translation:dynamicForm.addComment')}
        </ECTypography>
      </ECBox>
      <ECBox
        display="flex"
        flexDirection="column"
        maxHeight="50vh"
        flex={1}
        gap={2}
      >
        <ECBox display="flex" flexDirection="column">
          {moduleName &&
            ['workorder', 'proposal', 'invoice', 'rfp'].includes(
              moduleName,
            ) && (
              <ECBox display="flex" justifyContent="flex-end">
                <ECBox
                  ref={notifiedCollapsedRef}
                  display="flex"
                  bgcolor={theme =>
                    theme.palette.marketingSuggestion.marketingSuggestion
                  }
                  px={1}
                  height="48px"
                  justifyContent="center"
                  alignItems="center"
                  gap={1}
                  sx={{
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                  }}
                >
                  <Notifications
                    sx={theme => ({ color: theme.palette.common.white })}
                  />
                  <ECTypography color={theme => theme.palette.common.white}>
                    {t('translation:dynamicForm.notified')}:{' '}
                    {selectedUsers.length + selectedCurrentCompanyUsers.length}{' '}
                    {t('translation:dynamicForm.selected')}
                  </ECTypography>
                  <ECIconButton
                    size="small"
                    type="white"
                    noPadding
                    sx={theme => ({
                      color: theme.palette.common.white,
                      padding: 0,
                    })}
                    onClick={() => setAnchorEl(notifiedCollapsedRef.current)}
                  >
                    {open ? <ArrowDropUp /> : <ArrowDropDown />}
                  </ECIconButton>
                </ECBox>
              </ECBox>
            )}
          {useRichTextEditor ? (
            <ECRichTextField
              sx={{ width: '100%', minWidth: '600px' }}
              placeholder={`${placeholder || t('translation:richTextField.placeholder')} ${required ? '*' : ''}`}
              defaultValue={newComment}
              error={!!validationMessage}
              onChange={onCommentChangeHandler}
              isSendButtonVisible={isSendButtonVisible}
              mentionSuggestionUsers={usersToMention}
              mentionOnSelect={handleOnMentionUser}
              moduleId={moduleId}
            />
          ) : (
            <ECTextField
              sx={{ width: '100%', minWidth: '600px' }}
              placeholder={`${placeholder || t('translation:richTextField.placeholder')} ${required ? '*' : ''}`}
              value={newComment}
              multiline={true}
              variant={variant}
              error={!!validationMessage}
              disabled={disabled}
              onChange={onCommentChangeHandler}
              rows={6}
            />
          )}
        </ECBox>
      </ECBox>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseNotifiedModal}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <ECBox display="flex" flexDirection="column" width={380}>
          <ECBox
            display="flex"
            bgcolor={theme =>
              theme.palette.marketingSuggestion.marketingSuggestion
            }
            justifyContent="space-between"
            alignItems="center"
            p={2}
          >
            <ECTypography
              variant="h6"
              color={theme => theme.palette.common.white}
            >
              Notified
            </ECTypography>

            <ECBox display="flex" gap={2} alignItems="center">
              <ECTypography
                variant="body2"
                color={theme => theme.palette.common.white}
              >
                {selectedUsers.length + selectedCurrentCompanyUsers.length}{' '}
                selected
              </ECTypography>

              <ECIconButton type="white" onClick={handleCloseNotifiedModal}>
                <Close />
              </ECIconButton>
            </ECBox>
          </ECBox>

          <ECTabContainer
            p={2}
            variant="standard"
            noPadding
            tabs={[
              {
                value: '1',
                label: t('translation:noteTab.users', {
                  count: selectedCurrentCompanyUsers?.length ?? 0,
                }),
                content: (
                  <ECBox
                    display="flex"
                    width="100%"
                    height={330}
                    flexDirection="column"
                    overflow="auto"
                  >
                    {allUsers && isSuccessUsers ? (
                      <>
                        <ECBox px={2} pb={1}>
                          <ECTextField
                            fullWidth
                            placeholder="Search..."
                            defaultValue={currentCompanySearch}
                            onChange={debouncedHandleCurrentCompanySearchChange}
                          />
                        </ECBox>
                        <ECTree
                          tree={currentCompanyTree}
                          showSelectAllOption
                          keepSelectedNodes={currentCompanySearch !== undefined}
                          initialSelectedNodesIds={currentCompanyNodesIds}
                          selectedNodes={currentCompanyNodesIds}
                          onSelectedNodesIdsChange={setCurrentCompanyNodesIds}
                          placeholder={t(
                            'translation:noteTab.noUsersAvailable',
                          )}
                        />
                      </>
                    ) : (
                      <ECBox
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height={330}
                      >
                        <CircularProgress />
                      </ECBox>
                    )}
                  </ECBox>
                ),
              },
              {
                value: '2',
                label: isCustomer
                  ? t('translation:noteTab.sp', {
                      count: selectedUsers?.length ?? 0,
                    })
                  : t('translation:noteTab.customer', {
                      count: selectedUsers?.length ?? 0,
                    }),
                content: (
                  <ECBox
                    display="flex"
                    width="100%"
                    height={330}
                    flexDirection="column"
                    overflow="auto"
                  >
                    <ECBox px={2} pb={1}>
                      <ECTextField
                        fullWidth
                        placeholder="Search..."
                        defaultValue={search}
                        onChange={debouncedHandleSearchChange}
                      />
                    </ECBox>
                    <ECTree
                      tree={tree}
                      showSelectAllOption
                      keepSelectedNodes={search !== undefined}
                      initialSelectedNodesIds={nodesIds}
                      selectedNodes={nodesIds}
                      onSelectedNodesIdsChange={setNodesIds}
                      placeholder={t('translation:noteTab.noUsersAvailable')}
                    />
                  </ECBox>
                ),
              },
            ]}
          />
        </ECBox>
      </Popover>
    </ECBox>
  );
};
