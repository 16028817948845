import {
  Contacts,
  MonetizationOn,
  Description,
  StoreMallDirectory,
  Construction,
  CollectionsBookmark,
} from '@mui/icons-material';
import { ECCardButton, ECBox } from 'app/components';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { P } from 'types/Permission';

export default function NavigationBar() {
  const navigate = useNavigate();
  const { data: userData } = useGetUserProfileQuery();

  const scoreCompany = userData?.subscriptionScoreCompany;

  const checkPermissionScore = action => {
    const subscriptionScore = userData?.permissions?.find(
      permission => permission.action === action,
    )?.subscriptionScore;
    return (
      subscriptionScore !== undefined &&
      scoreCompany !== undefined &&
      scoreCompany < subscriptionScore
    );
  };
  const hasScoreGetAllWorkOrders = checkPermissionScore(P.GetAllWorkOrders);
  const hasScoreGetAllProposals = checkPermissionScore(P.GetAllProposals);
  const hasScoreGetAllInvoices = checkPermissionScore(P.GetAllInvoices);
  const hasScoreGetAllAssets = checkPermissionScore(P.GetAllAssets);
  const hasScoreGetAllServiceProviders = checkPermissionScore(
    P.GetAllServiceProviders,
  );
  const hasScoreGetAllBranches = checkPermissionScore(P.GetAllBranches);

  const navigateTo = useCallback(
    (url: string) => {
      navigate('/panel/' + url);
    },
    [navigate],
  );

  return (
    <ECBox
      display="flex"
      gap={2}
      py={2}
      px={3}
      mt={3}
      border="1px solid rgba(0, 0, 0, .26)"
      borderRadius={1}
      justifyContent="space-between"
      flexWrap="wrap"
      width={'100%'}
    >
      <ECCardButton
        title={t('translation:pages.dashboard.workOrders')}
        icon={<Contacts fontSize={'large'} />}
        onClick={() => navigateTo('work-orders')}
        disabled={hasScoreGetAllWorkOrders}
      />
      <ECCardButton
        title={t('translation:pages.dashboard.proposals')}
        icon={<Description fontSize={'large'} />}
        onClick={() => navigateTo('proposals')}
        disabled={hasScoreGetAllProposals}
      />
      <ECCardButton
        title={t('translation:pages.dashboard.invoices')}
        icon={<MonetizationOn fontSize={'large'} />}
        onClick={() => navigateTo('invoices')}
        disabled={hasScoreGetAllInvoices}
      />
      <ECCardButton
        title={t('translation:pages.dashboard.assets')}
        icon={<CollectionsBookmark fontSize={'large'} />}
        onClick={() => navigateTo('assets/')}
        disabled={hasScoreGetAllAssets}
      />
      <ECCardButton
        title={t('translation:pages.dashboard.manageSP')}
        icon={<Construction fontSize={'large'} />}
        onClick={() => navigateTo('service-providers')}
        disabled={hasScoreGetAllServiceProviders}
      />
      <ECCardButton
        title={t('translation:pages.dashboard.locations')}
        icon={<StoreMallDirectory fontSize={'large'} />}
        onClick={() => navigateTo('locations')}
        disabled={hasScoreGetAllBranches}
      />
    </ECBox>
  );
}
