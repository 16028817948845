import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLazyGetWorkOrdersListQuery } from 'services/workOrdersApi';
import { loadPageScopes } from 'utils/pageScopes';
import { setFilterEndpoint, setTitle } from 'store/slice/page';
import { useCustomerUser } from 'app/hooks/customerUser.use-case';
import { ForbiddenPage } from '../ForbiddenPage';
import { BulkEditDrawer } from './BulkEditDrawer';
import { ECTableTemplate } from 'app/components/core/ECTable/ECTableTemplate';
import { row } from 'types/core/ECTable';
import { Helmet } from 'react-helmet-async';
import { WorkOrdersPageHeader } from './PageHeader';

export function WorkOrdersPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('workOrder');
    dispatch(setFilterEndpoint('/filter/workorder'));
    dispatch(setTitle(t('translation:pages.workOrders.title')));
  }, []);

  const isCustomer = useCustomerUser();

  const [selectedRows, setSelectedRows] = useState<row[]>([]);
  const [disableEditButton, setDisableEditButton] = useState(true);

  const [isBulkEditDrawerOpen, setIsBulkEditDrawerOpen] = useState(false);
  const handleClose = useCallback(() => {
    setIsBulkEditDrawerOpen(false);
    setSelectedRows([]);
    setDisableEditButton(true);
  }, []);

  const handleSelectedRows = useCallback((rows: row[]) => {
    const shouldDisable = rows.length === 0;
    setDisableEditButton(shouldDisable);
    setSelectedRows(rows);
  }, []);

  const headerChildren = useMemo(() => {
    return (
      <WorkOrdersPageHeader
        disableEditButton={disableEditButton}
        setIsBulkEditDrawerOpen={setIsBulkEditDrawerOpen}
      />
    );
  }, [disableEditButton, setIsBulkEditDrawerOpen]);

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('translation:pages.workOrders.title')}</title>
        <meta name="description" content="Work Orders List Page" />
      </Helmet>
      <ECTableTemplate
        useLazyGetListQuery={useLazyGetWorkOrdersListQuery}
        isDraggableColumns
        multiSelectTable
        headerChildren={headerChildren}
        multiSelectedRows={handleSelectedRows}
        customFieldsData={{
          moduleName: 'WORKORDER',
        }}
      />
      <BulkEditDrawer
        isOpen={isBulkEditDrawerOpen}
        onClose={handleClose}
        selectedRows={selectedRows}
      />
    </React.Fragment>
  );
}
