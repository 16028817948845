import Dialog, { DialogProps } from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import {
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogContentText,
  DialogContentTextProps,
  DialogTitle,
  DialogTitleProps,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { ECButton } from '../ECButton';
import React from 'react';

export const ECDialog = styled(Dialog)<DialogProps>(({ theme }) => ({}));

export const ECDialogTitle = styled(DialogTitle)<DialogTitleProps>(
  ({ theme }) => ({}),
);

export const ECDialogContent = styled(DialogContent)<DialogContentProps>(
  ({ theme }) => ({}),
);

export const ECDialogContentText = styled(
  DialogContentText,
)<DialogContentTextProps>(({ theme }) => ({}));

export const ECDialogActions = styled(DialogActions)<DialogActionsProps>(
  ({ theme }) => ({}),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface ECConfirmationDialogProps {
  title?: string;
  text: string;
  open: boolean;
  onClose: () => void;
  onAcceptClick: () => void;
  onCancelClick: () => void;
  acceptButtonTitle?: string;
  cancelButtonTitle?: string;
  acceptButtonColor?: string;
  acceptButtonVariant?: string;
  sx?: React.CSSProperties;
}

export const ECConfirmationDialog = props => {
  const {
    title,
    text,
    open,
    onClose,
    onAcceptClick,
    onCancelClick,
    acceptButtonTitle = 'Accept',
    cancelButtonTitle = 'Close',
    acceptButtonColor = 'inherit',
    acceptButtonVariant = 'contained',
    sx = {},
  } = props;

  return (
    <ECDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      sx={sx}
    >
      {title && <ECDialogTitle>{title}</ECDialogTitle>}
      <ECDialogContent>
        <ECDialogContentText id="alert-dialog-slide-description">
          {text}
        </ECDialogContentText>
      </ECDialogContent>
      <ECDialogActions>
        <ECButton onClick={onCancelClick}>{cancelButtonTitle}</ECButton>
        <ECButton
          variant={acceptButtonVariant}
          color={acceptButtonColor}
          onClick={onAcceptClick}
        >
          {acceptButtonTitle}
        </ECButton>
      </ECDialogActions>
    </ECDialog>
  );
};
