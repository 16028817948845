import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardContent, Typography, Box, Paper } from '@mui/material';
import {
  useGetPastEtaWoCountQuery,
  useGetPendingInvoicesCountQuery,
  useGetPendingProposalsCountQuery,
} from 'services/dashboardApi';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { DashboardTables } from 'utils/constants';
import { DashboardDrawer } from '../DashboardPage/DashboardDrawer';
import { approveAction, rejectAction } from '../DashboardPage/actions';
import { P } from 'types/Permission';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { DashboardTableFilter } from 'types/Dashboard';

interface RecordCardProps {
  label: string;
  count?: number;
  isLoading?: boolean;
  action?: () => void;
}

const RecordCard: React.FC<RecordCardProps> = ({
  count,
  label,
  isLoading,
  action,
}) => {
  return (
    <Card
      sx={{
        minWidth: 200,
        width: { xs: '100%', sm: '100%', md: '32%' },
        borderRadius: 2,
        textAlign: 'center',
        boxShadow: 3,
        display: 'flex',
        cursor: 'pointer',
      }}
      onClick={action}
    >
      <CardContent
        sx={themes => ({
          display: 'flex',
          alignItems: 'center',
          backgroundColor: themes.palette.marketingSuggestion.mainBackground,
          width: '100%',
          paddingBottom: '16px !important',
          '&:hover': {
            backgroundColor: themes.palette.button.hoverOutlinedText,
            boxShadow: 6,
            borderColor: 'rgba(0, 0, 0, 0.3)',

            color: themes.palette.button.active,
          },
          '&:hover .numberBox': {
            backgroundColor: themes.palette.other.backgroundTable,
          },
        })}
      >
        <Typography
          variant="h4"
          bgcolor={themes => themes.palette.background.secondary}
          className="numberBox"
          color="inherit"
          height="62px"
          minWidth="41px"
          borderRadius={3}
          display={'flex'}
          justifyContent={'center'}
          p={1}
          alignItems={'center'}
        >
          {isLoading ? <ECCircularProgress size={20} /> : count ?? '-'}
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight={'bold'}
          fontSize={16}
          ml={2}
          color="inherit !important"
        >
          {label}
        </Typography>
      </CardContent>
    </Card>
  );
};

const ReviewRecords: React.FC = () => {
  const [drawer, setDrawer] = useState<DashboardTables | undefined>(undefined);

  const {
    data: proposalPendingCount,
    isLoading: isLoadingProposalPendingCount,
  } = useGetPendingProposalsCountQuery({
    cat: [],
    assignedToMe: 0,
  });

  const {
    data: invoicesPendingApprovalCount,
    isLoading: isLoadingInvoicesPendingApprovalCount,
  } = useGetPendingInvoicesCountQuery({
    cat: [],
    assignedToMe: 0,
  });

  const { data: pastEtaWoCount, isLoading: isLoadingPastEtaWoCount } =
    useGetPastEtaWoCountQuery();

  const handleOpenDrawer = useCallback((selectedDrawer: DashboardTables) => {
    setDrawer(selectedDrawer);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setDrawer(undefined);
  }, []);

  const [filters, setFilters] = useState<DashboardTableFilter[]>([]);
  const handleFilterChange = ({
    table,
    ...filterParams
  }: DashboardTableFilter) => {
    const untouchedFilters = filters.filter(filter => filter.table !== table);
    setFilters([
      ...untouchedFilters,
      {
        table,
        ...filterParams,
      },
    ]);
  };
  const { data: categories, isSuccess: isSuccessCategories } =
    useGetCategoryListQuery({ st: 1 });

  const [preSelectionDone, setPreSelectionDone] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccessCategories && categories && !preSelectionDone) {
      let preSelected = categories.data.map(category => category.id);
      let newFilter = [
        {
          table: DashboardTables.unassignedWO,
          cat: preSelected,
        },
        {
          table: DashboardTables.pendingWO,
          cat: preSelected,
        },
        {
          table: DashboardTables.pastEtaWo,
          cat: preSelected,
        },
        {
          table: DashboardTables.pendingProposals,
          cat: preSelected,
          assignedToMe: 0,
        },
        {
          table: DashboardTables.pendingInvoices,
          cat: preSelected,
          assignedToMe: 0,
        },
        {
          table: DashboardTables.awaitingInvoice,
          cat: preSelected,
          assignedToMe: 0,
        },
      ];
      setFilters(newFilter);
      setPreSelectionDone(true);
    }
  }, [isSuccessCategories, categories, preSelectionDone]);

  const approveActionMemoProposal = useMemo(() => {
    return approveAction([P.ChangeProposalStatus]);
  }, []);

  const rejectActionMemoProposal = useMemo(() => {
    return rejectAction([P.ChangeProposalStatus]);
  }, []);

  const rejectActionMemoInvoice = useMemo(() => {
    return rejectAction([P.ChangeInvoiceStatus]);
  }, []);
  return (
    <>
      <Paper sx={{ padding: 3, borderRadius: 2, boxShadow: 3, flexShrink: 0 }}>
        <Typography variant="h6" gutterBottom fontWeight={'bold'} fontSize={16}>
          Review your Records
        </Typography>
        <Box display="flex" gap={2} flexWrap={'wrap'} justifyContent="center">
          <RecordCard
            count={pastEtaWoCount}
            label="Work Orders past ETA"
            isLoading={isLoadingPastEtaWoCount}
            action={() => handleOpenDrawer(DashboardTables.pastEtaWo)}
          />
          <RecordCard
            count={proposalPendingCount}
            label="Proposals Pending"
            isLoading={isLoadingProposalPendingCount}
            action={() => handleOpenDrawer(DashboardTables.pendingProposals)}
          />
          <RecordCard
            count={invoicesPendingApprovalCount}
            isLoading={isLoadingInvoicesPendingApprovalCount}
            label="Invoice Pending Approval"
            action={() => handleOpenDrawer(DashboardTables.pendingInvoices)}
          />
        </Box>
      </Paper>
      <DashboardDrawer
        open={drawer !== undefined}
        onClose={handleCloseDrawer}
        handleClearModal={handleCloseDrawer}
        filters={filters}
        handleFilterChange={handleFilterChange}
        drawer={drawer}
        pastEtaWoCount={pastEtaWoCount}
        pendingProposalsCount={proposalPendingCount}
        pendingInvoicesCount={invoicesPendingApprovalCount}
        approveActionMemoProposal={approveActionMemoProposal}
        rejectActionMemoProposal={rejectActionMemoProposal}
        rejectActionMemoInvoice={rejectActionMemoInvoice}
        shouldUseOwnCount
      />
    </>
  );
};

export default ReviewRecords;
