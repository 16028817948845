export enum StyleConstants {
  NAV_BAR_HEIGHT = '4rem',
  NAV_BAR_HEIGHT_PX_VALUE = 64,
  DRAWER_TITLE_HEIGHT = '64px',
  GLOBAL_SEARCH_HEIGHT = '5rem',
  HEADER_COMPONENTS_HEIGHT = 330,
  TABLE_ROW_MAX_HEIGHT = '52px',
  TABLE_ROW_MAX_WIDTH = '400px',
  TABLE_CELL_X_MARGIN = 16,
  OPACITY_DISABLED = '0.3',
  DROPDOWN_MENU_MAX_HEIGHT = '544px',
  OPACITY_SOFT_DISABLED = '0.5',
}
