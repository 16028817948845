import Alert, { AlertProps } from '@mui/material/Alert';
import AlertTitle, { AlertTitleProps } from '@mui/material/AlertTitle';
import { styled } from '@mui/material/styles';
import { ECTypography } from '../ECTypography';

// Alert Props with DISABLED props
interface ECAlertProps extends AlertProps {
  disabled?: boolean;
  title?: string;
  items?: any[];
  description?: string;
  descriptionSx?: any;
}

export const ECAlert = styled(Alert)<ECAlertProps>(({ theme, disabled }) => ({
  opacity: disabled ? 0.5 : 1,
}));

export const ECAlertTitle = styled(AlertTitle)<AlertTitleProps>(
  ({ theme }) => ({}),
);

export const ECAlertWithTitle = (
  props: AlertProps | Pick<AlertTitleProps, 'title'>,
) => (
  <ECAlert {...props}>
    <ECAlertTitle>{props.title}</ECAlertTitle>
  </ECAlert>
);

export const ECAlertError = props => (
  <ECAlert severity="error">
    <ECAlertTitle>{props.title || 'Error'}</ECAlertTitle>
    <strong>{props.children}</strong>
  </ECAlert>
);

export const ECAlertSuccess = props => (
  <ECAlert severity="success">
    <ECAlertTitle>Success</ECAlertTitle>
    <strong>{props.children}</strong>
  </ECAlert>
);

export const ECCustomAlert = ({
  severity = 'info',
  variant,
  title,
  description,
  items,
  descriptionSx,
}: ECAlertProps) => (
  <ECAlert severity={severity} variant={variant} sx={{ borderRadius: 3 }}>
    {title && (
      <ECAlertTitle
        sx={{
          fontWeight: 'bold',
          color: theme => theme.palette.text.alert[severity],
        }}
      >
        {title}
      </ECAlertTitle>
    )}
    {description && (
      <ECTypography
        sx={{
          color: theme => theme.palette.text.alert[severity],
          ...descriptionSx,
        }}
      >
        {description}
      </ECTypography>
    )}
    {items && (
      <ul
        style={{
          marginTop: '-10px',
          marginLeft: '-10px',
          marginBottom: '-5px',
        }}
      >
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    )}
  </ECAlert>
);
