import { store } from 'store/configureStore';
import { setPageScopes } from 'store/slice/page';
import { P } from 'types/Permission';

export function loadPageScopes(entity: PageEntityType) {
  store.dispatch(setPageScopes(pageRequiredScopes[entity]));
}

const createObject = (
  canGetAll: P | undefined = undefined,
  canGetById: P | undefined = undefined,
  canAdd: P | undefined = undefined,
  canEdit: P | undefined = undefined,
  canDelete: P | undefined = undefined,
) => {
  return { canGetAll, canGetById, canAdd, canEdit, canDelete };
};

export type PageEntityType = keyof typeof pageRequiredScopes;

const pageRequiredScopes = {
  user: createObject(
    P.GetAllUsers,
    P.GetUser,
    P.AddUser,
    P.EditUser,
    P.DeleteUser,
  ),
  role: createObject(
    P.GetAllUsers,
    P.GetUser,
    P.AddUser,
    P.EditUser,
    P.DeleteUser,
  ),
  userRole: createObject(
    P.GetAllUsers,
    P.GetUser,
    P.AddRolesToUser,
    P.AddRolesToUser,
    P.RemoveRolesFromUser,
  ),
  jobTitleRole: createObject(
    P.GetAllJobTitlesWithRoles,
    P.AddRolesToJobTitle,
    P.AddRolesToJobTitle,
    P.AddRolesToJobTitle,
    P.DeleteRolesFromJobTitle,
  ),
  asset: createObject(
    P.GetAllAssets,
    P.GetAsset,
    P.AddAsset,
    P.EditAsset,
    P.DeleteAsset,
  ),
  assetGeneral: createObject(
    P.GetAllGeneralAssets,
    P.GetGeneralAsset,
    P.AddGeneralAsset,
    P.EditGeneralAsset,
    P.DeleteGeneralAsset,
  ),
  branch: createObject(
    P.GetAllBranches,
    P.GetBranch,
    P.AddBranch,
    P.EditBranch,
    P.DeleteBranch,
  ),
  problem: createObject(
    P.GetAllProblemTypes,
    P.GetProblemType,
    P.AddProblemType,
    P.EditProblemType,
    P.DeleteProblemType,
  ),
  trade: createObject(
    P.GetAllTrades,
    P.GetTrade,
    P.AddTrade,
    P.EditTrade,
    P.DeleteTrade,
  ),
  inventory: createObject(P.GetInventory, undefined, P.AddInventory),
  inventoryStorage: createObject(
    P.GetInventoryStorage,
    undefined,
    P.AddInventoryStorage,
  ),
  warranty: createObject(
    P.GetAllWarranties,
    P.GetWarranty,
    P.AddWarranty,
    P.EditWarranty,
    P.DeleteWarranty,
  ),
  workOrder: createObject(
    P.GetAllWorkOrders,
    P.GetWorkOrder,
    P.AddWorkOrder,
    P.EditWorkOrder,
    P.DeleteWorkOrder,
  ),
  jobTitle: createObject(
    P.GetAllJobTitle,
    P.GetJobTitle,
    P.AddJobTitle,
    P.EditJobTitle,
    P.DeleteJobTitle,
  ),
  organization: createObject(
    P.GetOrgTreeByCompany,
    P.UpdateOrganization,
    P.AddOrganization,
    P.UpdateOrganization,
    P.DeleteOrganization,
  ),
  preventiveMaintenance: createObject(
    P.GetPreventiveMaintenancesList,
    P.GetPreventiveMaintenance,
    P.AddPreventiveMaintenance,
    P.EditPreventiveMaintenance,
    P.DeletePreventiveMaintenance,
  ),
  assetType: createObject(
    P.GetAllAssetTypes,
    P.GetAssetType,
    P.AddAssetType,
    P.EditAssetType,
    P.DeleteAssetType,
  ),
  assetTypeProblem: createObject(
    P.GetAllAssetTypesWithProblems,
    P.Unset,
    P.AddProblemToAssetType,
    P.Unset,
    P.DeleteProblemTypeFromAssetType,
  ),
  assetTypeRepair: createObject(
    P.GetAllAssetTypesWithRepairs,
    P.Unset,
    P.AddRepairToAssetType,
    P.Unset,
    P.DeleteRepairTypeFromAssetType,
  ),
  branchType: createObject(
    P.GetAllBranchCategories,
    P.GetBranchCategory,
    P.AddBranchCategory,
    P.EditBranchCategory,
    P.DeleteBranchCategory,
  ),
  failure: createObject(
    P.GetAllRepairTypes,
    P.GetRepairType,
    P.AddRepairType,
    P.EditRepairType,
    P.DeleteRepairType,
  ),
  flag: createObject(
    P.GetAllFlags,
    P.GetFlag,
    P.AddFlag,
    P.EditFlag,
    P.DeleteFlag,
  ),
  area: createObject(
    P.GetAllAreas,
    P.GetArea,
    P.AddArea,
    P.EditArea,
    P.DeleteArea,
  ),
  category: createObject(
    P.GetAllCategories,
    P.GetCategory,
    P.AddCategory,
    P.EditCategory,
    P.DeleteCategory,
  ),
  assetTypeName: createObject(
    P.GetAllAssetTypesWithNames,
    P.Unset,
    P.AddAssetTemplateName,
    P.EditAssetType,
    P.DeleteAssetTemplateNames,
  ),
  assetProblemTroubleshoot: createObject(
    P.GetAllTroubleshoot,
    P.GetTroubleshoot,
    P.AddProblemTroubleshootToAssetGroup,
    P.EditTroubleshoot,
    P.RemoveProblemTroubleshootFromAssetGroup,
  ),
  troubleshooting: createObject(
    P.GetAllTroubleshoot,
    P.GetTroubleshoot,
    P.AddTroubleshoot,
    P.EditTroubleshoot,
    P.DeleteTroubleshoot,
  ),
  priorities: createObject(
    P.GetAllPriorities,
    P.GetPriority,
    P.AddPriority,
    P.EditPriority,
    P.DeletePriority,
  ),
  approval: createObject(
    P.GetAllApproval,
    P.GetApproval,
    P.AddApproval,
    P.EditApproval,
    P.DeleteApproval,
  ),
  rfp: createObject(P.GetAllRFPs, P.GetRFP, P.AddRFP, P.EditRFP, P.DeleteRFP),
  serviceProviders: createObject(
    P.GetAllCompanyServiceProviders,
    P.GetServiceProvider,
    P.AddServiceProvider,
    P.UpdateServiceProvider,
    P.DeleteServiceProvider,
  ),
  serviceProviderCustomers: createObject(
    P.GetAllCustomers,
    // below permissions have to be created and changed
    P.GetServiceProvider,
    P.AddServiceProvider,
    P.UpdateServiceProvider,
    P.DeleteServiceProvider,
  ),
  invitation: createObject(P.GetServiceProviderInvitation),
  // : createObject('', '', '', '', ''),
  generalConfiguration: createObject(
    P.GetCompanyConfiguration,
    P.GetCompanyConfiguration,
    P.AddCompanyConfiguration,
    P.EditCompanyConfiguration,
    undefined,
  ),
};
