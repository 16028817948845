import { ECBox } from 'app/components/ECBox';
import { ECTablePagination } from 'app/components/ECTable';
import { StyleConstants } from 'styles/StyleConstants';
import { ECTableLayoutFooterProps } from 'types/core/ECTable';

export const ECTableLayoutFooter: React.FC<ECTableLayoutFooterProps> = ({
  page,
  perPage,
  onPaginationChange,
  disableTableContent,
  hidePerPageSelector,
  isSendingRequest,
  totalResults,
  afterPaginationChildren,
  beforePaginationChildren,
  belowPaginationChildren,
  hidePagination,
  isEmptyState,
  isPaginated,
}) => {
  return (
    <ECBox display="flex" flexDirection="column">
      <ECBox>
        {beforePaginationChildren}
        {isPaginated && !hidePagination && (
          <ECTablePagination
            count={totalResults}
            page={page}
            rowsPerPage={perPage}
            onPageChange={(event, page) => onPaginationChange?.({ page })}
            onRowsPerPageChange={event =>
              onPaginationChange?.({ perPage: parseInt(event.target.value) })
            }
            component={ECBox}
            sx={{
              display: 'flex',
              opacity: disableTableContent
                ? StyleConstants.OPACITY_DISABLED
                : isEmptyState
                  ? '0'
                  : '',
              pointerEvents: disableTableContent ? 'none' : '',
              ...(hidePerPageSelector && {
                '& .MuiTablePagination-selectLabel': {
                  display: 'none',
                },
                '& .MuiTablePagination-input': {
                  display: 'none',
                },
              }),
            }}
            labelDisplayedRows={({ from, to, count, page }) => {
              // if count is -1 this means BE is not making total count
              if (count === -1) {
                return `${from}-${to} of many`;
              }

              // On the last page, the BE is returning only the count of the page
              // So this is a workaround to show the correct number of rows
              if (count !== -1 && count < from) {
                return `${from}-${to + perPage * page} of ${count + perPage * page}`;
              }

              return `${from}-${to} of ${count}`;
            }}
            slotProps={{
              select: {
                name: 'rows-per-page-select',
              },
              actions: {
                previousButton: {
                  disabled: isSendingRequest || page === 0,
                },
                nextButton: {
                  disabled:
                    isSendingRequest ||
                    (Number(totalResults) !== -1 &&
                      Number(page) * Number(perPage) + Number(perPage) >=
                        Number(totalResults)),
                },
              },
            }}
          />
        )}
        {afterPaginationChildren}
      </ECBox>
      {belowPaginationChildren}
    </ECBox>
  );
};
