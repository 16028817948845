import { ECAlert, ECAlertTitle } from '../ECAlert';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { ECButton } from '../ECButton';
import { themes } from 'styles/theme/themes';
import { Payment } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useCompanySubscriptionStatusCode } from 'app/hooks/useCompanySubscriptionStatusCode.use-case';
import { NAV_BAR_HEIGHT_PX, DELINQUENT_BANNER_HEIGHT } from 'utils/constants';
import { CompanySubscriptionStatusCode } from 'types/Subscription';

export const ECDelinquentBanner = () => {
  const navigate = useNavigate();

  const customerProfileSubscriptionCode = useCompanySubscriptionStatusCode();

  const isCompanyDelinquent =
    customerProfileSubscriptionCode ===
    CompanySubscriptionStatusCode.Delinquent;

  if (!isCompanyDelinquent) {
    return null;
  }

  return (
    <ECAlert
      severity="error"
      sx={themes => ({
        alignItems: 'center',
        '.MuiAlert-message': {
          paddingRight: '26px',
          width: '100% !important',
          height: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        marginTop: `${NAV_BAR_HEIGHT_PX}px`,
        marginBottom: `-${DELINQUENT_BANNER_HEIGHT}`,
        paddingY: 2,
        backgroundColor: themes.palette.error.background + ' !important',
        color: themes.palette.error.content + ' !important',
      })}
    >
      <ECBox
        display={'flex'}
        flexWrap={'wrap'}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <ECBox>
          <ECAlertTitle color="inherit !important">
            <ECTypography
              variant="body2"
              marginRight={'10px'}
              fontWeight={'bold'}
              color={'inherit'}
            >
              Oops! We couldn’t process your payment for your Ecotrak
              subscription.
            </ECTypography>
          </ECAlertTitle>
          <ECTypography variant="body2" marginRight={'10px'} color={'inherit'}>
            There may be an issue with your payment method on file. Please
            update your payment details below to avoid any service
            interruptions.
          </ECTypography>
        </ECBox>
        <ECButton
          variant="text"
          color="inherit"
          sx={{
            color: themes.light.palette.warning.content + ' !important',
            fontWeight: 'bold',
            minWidth: '218px',
          }}
          onClick={() => navigate?.('/panel/configuration/profile/billing')}
          loadingSpinnerColor={themes.light.palette.warning.content}
          endIcon={<Payment />}
        >
          Update Payment Method
        </ECButton>
      </ECBox>
    </ECAlert>
  );
};
