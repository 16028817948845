import {
  VisibilityOff,
  Visibility,
  CheckCircleOutline,
  ErrorOutline,
} from '@mui/icons-material';
import {
  FilledInput,
  InputLabel,
  FormHelperText,
  InputAdornment,
} from '@mui/material';
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ECFormControl } from '../ECForm';
import { ECGrid } from '../ECGrid';
import { ECIconButton } from '../ECIconButton';
import { ECTypography } from '../ECTypography';

interface Props {
  label: string;
  confirmLabel?: string;
  fieldName: string;
  sx?: any;
  inputProps?: any;
  error?: boolean;
  onChange?: (value: string | number) => void;
  onValidatePassword?: (isValidPassword: boolean) => void;
  placeholder: string;
  value: any;
  helperText?: string | null;
  variant?: string;
  isNumber?: boolean;
}

interface Rule {
  name: string;
  valid: boolean;
  validate: (value: string) => boolean;
}

export default function ECPasswordCheckList({
  fieldName,
  label,
  confirmLabel,
  inputProps,
  error,
  helperText,
  sx,
  onChange,
  onValidatePassword,
}: Props) {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [confirmValue, setConfirmValue] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const rules = useMemo(() => {
    return {
      insideLength: {
        valid: (value: string) => value.length >= 8 && value.length <= 20,
        message: t('translation:passwordChecklist.insideLength'),
      },
      oneNumber: {
        valid: (value: string) => /\d/g.test(value),
        message: t('translation:passwordChecklist.oneNumber'),
      },
      oneCapitalLetter: {
        valid: (value: string) => /[A-Z]/g.test(value),
        message: t('translation:passwordChecklist.oneCapitalLetter'),
      },
      oneSpecialCharacter: {
        valid: (value: string) => /[^a-zA-Z0-9]/g.test(value),
        message: t('translation:passwordChecklist.oneSpecialCharacter'),
      },
      match: {
        valid: (value: string, confirmValue: string) =>
          value.length > 0 && value === confirmValue,
        message: t('translation:passwordChecklist.match'),
      },
      noTrailingSpace: {
        valid: (value: string) =>
          !value.startsWith(' ') && !value.endsWith(' '),
        message: t('translation:passwordChecklist.noTrailingSpace'),
      },
    };
  }, []);

  const [validations, setValidations] = useState({
    insideLength: false,
    oneNumber: false,
    oneCapitalLetter: false,
    oneSpecialCharacter: false,
    match: false,
    noTrailingSpace: false,
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value: string = event.target.value;
      setValue(value);
      const newValidations = {
        insideLength: rules['insideLength'].valid(value),
        oneNumber: rules['oneNumber'].valid(value),
        oneCapitalLetter: rules['oneCapitalLetter'].valid(value),
        oneSpecialCharacter: rules['oneSpecialCharacter'].valid(value),
        match: rules['match'].valid(value, confirmValue),
        noTrailingSpace: rules['noTrailingSpace'].valid(value),
      };
      setValidations(newValidations);
      onChange?.(value);
      const isValid = Object.keys(newValidations).every(
        key => newValidations[key],
      );
      onValidatePassword?.(isValid);
    },
    [onChange, confirmValue],
  );

  const handleChangeConfirm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue: string = event.target.value;
      const newValidations = {
        ...validations,
        match: rules['match'].valid(value, newValue),
      };
      setValidations(newValidations);
      const isValid = Object.keys(newValidations).every(
        key => newValidations[key],
      );
      onValidatePassword?.(isValid);
      setConfirmValue(newValue);
    },
    [value],
  );

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const passwordAdornment = useMemo(() => {
    return (
      <InputAdornment position="end">
        <ECIconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </ECIconButton>
      </InputAdornment>
    );
  }, [showPassword]);

  const confirmPasswordAdornment = useMemo(() => {
    return (
      <InputAdornment position="end">
        <ECIconButton
          aria-label="toggle password visibility"
          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
        </ECIconButton>
      </InputAdornment>
    );
  }, [showConfirmPassword]);

  const renderValidation = validationName => (
    <ECGrid container spacing={1} pl={3}>
      <ECGrid item>
        {validations[validationName] ? (
          <CheckCircleOutline color="success" />
        ) : (
          <ErrorOutline color="error" />
        )}
      </ECGrid>
      <ECGrid item>
        <ECTypography>{rules[validationName]?.message}</ECTypography>
      </ECGrid>
    </ECGrid>
  );

  return (
    <>
      <ECGrid container spacing={2}>
        <ECGrid item xs={12} sm={12} md={6}>
          <ECFormControl variant="filled" sx={sx}>
            <InputLabel htmlFor={fieldName}>{label}</InputLabel>
            <FilledInput
              id={fieldName}
              name={fieldName}
              type={showPassword ? 'text' : 'password'}
              value={value}
              onChange={handleChange}
              inputProps={inputProps}
              error={error}
              endAdornment={passwordAdornment}
              autoComplete="new-password"
            />
            {error && helperText && (
              <FormHelperText
                sx={theme => ({
                  bgcolor: 'transparent',
                  color: theme.palette.graphic.alert.error,
                })}
              >
                {helperText}
              </FormHelperText>
            )}
          </ECFormControl>
        </ECGrid>

        <ECGrid item xs={12} sm={12} md={6}>
          <ECFormControl variant="filled" sx={sx}>
            <InputLabel htmlFor={`confirm${fieldName}`}>
              {confirmLabel || `Confirm ${label}`}
            </InputLabel>
            <FilledInput
              id={`confirm${fieldName}`}
              name={`confirm${fieldName}`}
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmValue}
              onChange={handleChangeConfirm}
              inputProps={inputProps}
              error={error}
              autoComplete="none"
              endAdornment={confirmPasswordAdornment}
            />
          </ECFormControl>
        </ECGrid>
      </ECGrid>

      <ECGrid container mb={1} mt={2}>
        <ECGrid item xs={12} sm={12} md={6}>
          {renderValidation('oneSpecialCharacter')}
          {renderValidation('oneNumber')}
          {renderValidation('oneCapitalLetter')}
        </ECGrid>

        <ECGrid item xs={12} sm={12} md={6}>
          {renderValidation('insideLength')}
          {renderValidation('noTrailingSpace')}
          {renderValidation('match')}
        </ECGrid>
      </ECGrid>
    </>
  );
}
