import { ECCheckbox } from 'app/components/ECCheckbox';
import { useEffect, useState } from 'react';
import { ECTableCheckBoxProps } from 'types/core/ECTable';

/**
 * Custom checkbox for the table component
 * that takes care of it's own state and
 * communicates with the parent component,
 * preventing unnecessary re-renders
 */
export const ECTableCheckBox: React.FC<ECTableCheckBoxProps> = ({
  handleCheckBoxClick,
  row,
  rowIndex,
  isDisabled,
  allChecked,
  selectedRows,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(allChecked ?? false);
  useEffect(() => {
    setIsSelected(selectedRows.some(selectedRow => selectedRow.id === row.id));
  }, [selectedRows]);
  return (
    <ECCheckbox
      checked={isSelected}
      disabled={isDisabled && !isSelected}
      indeterminate={isDisabled && !isSelected}
      name={`${row.id}-select-row-${rowIndex}`}
      onChange={() => {
        setIsSelected(!isSelected);
        handleCheckBoxClick(row);
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    />
  );
};
