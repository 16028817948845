import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { Close } from '@mui/icons-material';
import { Typography } from '@mui/material';
import MultiselectTable from './MultiselectTable';
import { ECModal } from 'app/components/ECModal';
import { P } from 'types/Permission';
import {
  useGetUnassignedWorkOrdersQuery,
  useGetPendingWorkOrdersQuery,
  useGetWorkordersAwaitingInvoiceQuery,
  useGetPendingProposalsQuery,
  useGetPendingInvoicesQuery,
  useGetPastEtaWorkordersQuery,
  useGetPendingInvoicesCountQuery,
  useGetWorkordersAwaitingInvoiceCountQuery,
  useGetPendingProposalsCountQuery,
  useGetPendingWorkOrdersCountQuery,
  useGetUnassignedWorkOrdersCountQuery,
} from 'services/dashboardApi';
import { DashboardTables, Modules } from 'utils/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { DashboardTableData } from 'types';
import {
  approveAction,
  addNoteAction,
  cancelAction,
  reassignAction,
  DashboardAction,
  QueryTag,
} from './actions';
import { useNavigate, useMatch } from 'react-router-dom';
import { Speed, Description, MonetizationOn } from '@mui/icons-material';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { DashboardTableFilter } from 'types/Dashboard';
import { ECDashboardCategoryFilter } from './ECDashboardCategoryFilter';
import { ECButton } from 'app/components/ECButton';
import { ECBox } from 'app/components/ECBox';
import { ECPaper } from 'app/components/ECPaper';

const EXPANDED_COUNT = 20;
const SMALL_COUNT = 5;

interface ExpandedData {
  table: DashboardTables;
  count: number | string;
  module: Modules;
  tableData?: DashboardTableData;
  actions: DashboardAction[];
  filters?: DashboardTableFilter;
  queryTag: QueryTag;
  handlePageChange?: (page: number) => void;
  handleRowsPerPageChange?: (page: number) => void;
}

interface DashboardDrawerProps {
  open: boolean;
  onClose?: () => void;
  handleCloseModal?: () => void;
  handleClearModal: () => void;
  handleFilterChange: (filter: DashboardTableFilter) => void;
  categories?: any;
  filters: DashboardTableFilter[];
  drawer?: DashboardTables;
  approveActionMemoProposal: any;
  rejectActionMemoProposal: any;
  rejectActionMemoInvoice: any;
  unassignedWorkordersCount?: number;
  pendingWorkordersCount?: number;
  pendingProposalsCount?: number;
  pendingInvoicesCount?: number;
  awaitingInvoiceCount?: number;
  pastEtaWoCount?: number;
  shouldUseOwnCount?: boolean;
}

export function DashboardDrawer({
  open,
  onClose,
  handleCloseModal,
  handleClearModal,
  handleFilterChange,
  categories,
  filters,
  drawer,
  approveActionMemoProposal,
  rejectActionMemoProposal,
  rejectActionMemoInvoice,
  unassignedWorkordersCount,
  pendingWorkordersCount,
  pendingProposalsCount,
  pendingInvoicesCount,
  awaitingInvoiceCount,
  pastEtaWoCount,
  shouldUseOwnCount,
}: DashboardDrawerProps) {
  const navigate = useNavigate();
  const match = useMatch('/panel/*');
  const [pageUnassignedWO, setPageUnassignedWO] = useState<number>(0);
  const [rowsPerPageUnassignedWO, setRowsPerPageUnassignedWO] =
    useState<number>(EXPANDED_COUNT);

  const [pagePendingWO, setPagePendingWO] = useState<number>(0);
  const [rowsPerPagePendingWO, setRowsPerPagePendingWO] =
    useState<number>(EXPANDED_COUNT);

  const [pageProposals, setPageProposals] = useState<number>(0);
  const [rowsPerPageProposals, setRowsPerPageProposals] =
    useState<number>(EXPANDED_COUNT);

  const [pagePendingInvoices, setPagePendingInvoices] = useState<number>(0);
  const [rowsPerPagePendingInvoices, setRowsPerPagePendingInvoices] =
    useState<number>(EXPANDED_COUNT);

  const [pageAwaitingInvoice, setPageAwaitingInvoice] = useState<number>(0);
  const [rowsPerPageAwaitingInvoice, setRowsPerPageAwaitingInvoice] =
    useState<number>(EXPANDED_COUNT);

  const [pagePastEtaWo, setPagePastEtaWo] = useState<number>(0);
  const [rowsPerPagePastEtaWo, setRowsPerPagePastEtaWo] =
    useState<number>(EXPANDED_COUNT);

  const [hasWorkorderPermission, hasProposalPermission, hasInvoicePermission] =
    useHasPermission([P.GetWorkOrder, P.GetProposal, P.GetInvoice]);

  const [expandedSelection, setExpandedSelection] = useState<any[]>([]);
  const [actionModal, setActionModal] = useState<string>();

  const onCloseActionModal = useCallback(() => {
    setActionModal(undefined);
    setExpandedSelection([]);
  }, []);

  const onCancelActionModal = useCallback(() => {
    setActionModal(undefined);
  }, []);

  const { data: categoriesQuery } = useGetCategoryListQuery(
    { st: 1 },
    { skip: categories },
  );

  const categoriesData = useMemo(() => {
    return categories || categoriesQuery;
  }, [categoriesQuery, categories]);

  const unassignedWOFilter = useMemo(
    () => filters.find(filter => filter.table === DashboardTables.unassignedWO),
    [filters],
  );
  const pendingWOFilter = useMemo(
    () => filters.find(filter => filter.table === DashboardTables.pendingWO),
    [filters],
  );
  const pastEtaWoFilter = useMemo(
    () => filters.find(filter => filter.table === DashboardTables.pastEtaWo),
    [filters],
  );
  const pendingProposalsFilter = useMemo(
    () =>
      filters.find(filter => filter.table === DashboardTables.pendingProposals),
    [filters],
  );
  const pendingInvoicesFilter = useMemo(
    () =>
      filters.find(filter => filter.table === DashboardTables.pendingInvoices),
    [filters],
  );
  const awaitingInvoiceFilter = useMemo(
    () => ({
      ...filters.find(
        filter => filter.table === DashboardTables.awaitingInvoice,
      ),
    }),
    [filters],
  );

  const { data: unassignedWorkordersExpanded } =
    useGetUnassignedWorkOrdersQuery(
      {
        ...filters.find(
          filter => filter.table === DashboardTables.unassignedWO,
        ),
        p: pageUnassignedWO,
        t:
          drawer === DashboardTables.unassignedWO
            ? rowsPerPageUnassignedWO
            : SMALL_COUNT,
      },
      {
        skip:
          !hasWorkorderPermission ||
          !filters.length ||
          drawer !== DashboardTables.unassignedWO,
      },
    );

  const { data: pendingWorkordersExpanded } = useGetPendingWorkOrdersQuery(
    {
      ...pendingWOFilter,
      p: pagePendingWO,
      t:
        drawer === DashboardTables.pendingWO
          ? rowsPerPagePendingWO
          : SMALL_COUNT,
    },
    {
      skip:
        !hasWorkorderPermission ||
        !filters.length ||
        drawer !== DashboardTables.pendingWO,
    },
  );

  const { data: awaitingInvoiceExpanded } =
    useGetWorkordersAwaitingInvoiceQuery(
      {
        ...awaitingInvoiceFilter,
        p: pageAwaitingInvoice,
        t:
          drawer === DashboardTables.awaitingInvoice
            ? rowsPerPageAwaitingInvoice
            : SMALL_COUNT,
      },
      {
        skip:
          !hasWorkorderPermission ||
          !filters.length ||
          drawer !== DashboardTables.awaitingInvoice,
      },
    );

  const { data: pastEtaWorkordersExpanded } = useGetPastEtaWorkordersQuery(
    {
      ...pastEtaWoFilter,
      p: pagePastEtaWo,
      t:
        drawer === DashboardTables.pastEtaWo
          ? rowsPerPagePastEtaWo
          : SMALL_COUNT,
    },
    {
      skip:
        !hasWorkorderPermission ||
        !filters.length ||
        drawer !== DashboardTables.pastEtaWo,
    },
  );

  const { data: pendingProposalsExpanded } = useGetPendingProposalsQuery(
    {
      ...pendingProposalsFilter,
      p: pageProposals,
      t:
        drawer === DashboardTables.pendingProposals
          ? rowsPerPageProposals
          : SMALL_COUNT,
    },
    {
      skip:
        !hasProposalPermission ||
        !filters.length ||
        drawer !== DashboardTables.pendingProposals,
    },
  );

  const { data: pendingInvoicesExpanded } = useGetPendingInvoicesQuery(
    {
      ...pendingInvoicesFilter,
      p: pagePendingInvoices,
      t:
        drawer === DashboardTables.pendingInvoices
          ? rowsPerPagePendingInvoices
          : SMALL_COUNT,
    },
    {
      skip:
        !hasInvoicePermission ||
        !filters.length ||
        drawer !== DashboardTables.pendingInvoices,
    },
  );

  const { data: unassignedWorkordersCountData } =
    useGetUnassignedWorkOrdersCountQuery(
      {
        ...unassignedWOFilter,
      },
      {
        skip: !hasWorkorderPermission || !shouldUseOwnCount,
      },
    );
  const { data: pendingWorkordersCountData } =
    useGetPendingWorkOrdersCountQuery(
      {
        ...pendingWOFilter,
      },
      {
        skip: !hasWorkorderPermission || !shouldUseOwnCount,
      },
    );
  const { data: awaitingInvoiceCountData } =
    useGetWorkordersAwaitingInvoiceCountQuery(
      {
        ...awaitingInvoiceFilter,
      },
      {
        skip: !hasWorkorderPermission || !shouldUseOwnCount,
      },
    );
  const { data: pendingProposalsCountData } = useGetPendingProposalsCountQuery(
    {
      ...pendingProposalsFilter,
    },
    {
      skip: !hasProposalPermission || !shouldUseOwnCount,
    },
  );
  const { data: pendingInvoicesCountData } = useGetPendingInvoicesCountQuery(
    {
      ...pendingInvoicesFilter,
    },
    {
      skip: !hasInvoicePermission || !shouldUseOwnCount,
    },
  );

  const expandedTableData: ExpandedData | undefined | any = useMemo(() => {
    switch (drawer) {
      case DashboardTables.unassignedWO:
        return {
          table: DashboardTables.unassignedWO,
          count: shouldUseOwnCount
            ? unassignedWorkordersCountData
            : unassignedWorkordersCount,
          tableData: {
            ...unassignedWorkordersExpanded,
            totalCount: shouldUseOwnCount
              ? unassignedWorkordersCountData
              : unassignedWorkordersCount,
          },
          actions: [reassignAction, cancelAction],
          module: Modules.WORKORDER,
          queryTag: [
            QueryTag.UnassignedWorkorders,
            QueryTag.PendingWorkorders,
            QueryTag.PastEtaWo,
          ],
          handlePageChange: setPageUnassignedWO,
          handleRowsPerPageChange: setRowsPerPageUnassignedWO,
          filters: unassignedWOFilter,
        };
      case DashboardTables.pendingWO:
        return {
          table: DashboardTables.pendingWO,
          count: shouldUseOwnCount
            ? pendingWorkordersCountData
            : pendingWorkordersCount,
          tableData: {
            ...pendingWorkordersExpanded,
            totalCount: shouldUseOwnCount
              ? pendingWorkordersCountData
              : pendingWorkordersCount,
          },
          actions: [reassignAction, cancelAction],
          module: Modules.WORKORDER,
          queryTag: [
            QueryTag.UnassignedWorkorders,
            QueryTag.PendingWorkorders,
            QueryTag.PastEtaWo,
          ],
          handlePageChange: setPagePendingWO,
          handleRowsPerPageChange: setRowsPerPagePendingWO,
          filters: pendingWOFilter,
        };
      case DashboardTables.pendingProposals:
        return {
          table: DashboardTables.pendingProposals,
          count: shouldUseOwnCount
            ? pendingProposalsCountData
            : pendingProposalsCount,
          tableData: {
            ...pendingProposalsExpanded,
            totalCount: shouldUseOwnCount
              ? pendingProposalsCountData
              : pendingProposalsCount,
          },
          actions: [
            addNoteAction,
            approveActionMemoProposal,
            rejectActionMemoProposal,
          ],
          module: Modules.PROPOSAL,
          queryTag: QueryTag.PendingProposals,
          handlePageChange: setPageProposals,
          handleRowsPerPageChange: setRowsPerPageProposals,
          filters: pendingProposalsFilter,
        };
      case DashboardTables.pendingInvoices:
        return {
          table: DashboardTables.pendingInvoices,
          count: shouldUseOwnCount
            ? pendingInvoicesCountData
            : pendingInvoicesCount,
          tableData: {
            ...pendingInvoicesExpanded,
            totalCount: shouldUseOwnCount
              ? pendingInvoicesCountData
              : pendingInvoicesCount,
          },
          actions: [
            addNoteAction,
            approveAction([P.ChangeInvoiceStatus]),
            rejectActionMemoInvoice,
          ],
          module: Modules.INVOICE,
          queryTag: QueryTag.PendingInvoices,
          handlePageChange: setPagePendingInvoices,
          handleRowsPerPageChange: setRowsPerPagePendingInvoices,
          filters: pendingInvoicesFilter,
        };
      case DashboardTables.awaitingInvoice:
        return {
          table: DashboardTables.awaitingInvoice,
          count: shouldUseOwnCount
            ? awaitingInvoiceCountData
            : awaitingInvoiceCount,
          tableData: {
            ...awaitingInvoiceExpanded,
            totalCount: shouldUseOwnCount
              ? awaitingInvoiceCountData
              : awaitingInvoiceCount,
          },
          module: Modules.WORKORDER,
          actions: [addNoteAction],
          queryTag: QueryTag.AwaitingInvoice,
          handlePageChange: setPageAwaitingInvoice,
          handleRowsPerPageChange: setRowsPerPageAwaitingInvoice,
          filters: awaitingInvoiceFilter,
        };
      case DashboardTables.pastEtaWo:
        return {
          table: DashboardTables.pastEtaWo,
          count: shouldUseOwnCount
            ? pastEtaWorkordersExpanded?.totalCount
            : pastEtaWoCount,
          tableData: {
            ...pastEtaWorkordersExpanded,
            totalCount: shouldUseOwnCount
              ? pastEtaWorkordersExpanded?.totalCount
              : pastEtaWoCount,
          },
          module: Modules.WORKORDER,
          actions: [reassignAction, cancelAction, addNoteAction],
          queryTag: [
            QueryTag.UnassignedWorkorders,
            QueryTag.PendingWorkorders,
            QueryTag.PastEtaWo,
          ],
          handlePageChange: setPagePastEtaWo,
          handleRowsPerPageChange: setRowsPerPagePastEtaWo,
          filters: pastEtaWoFilter,
        };
      default:
        return undefined;
    }
  }, [
    drawer,
    unassignedWorkordersExpanded,
    pendingWorkordersExpanded,
    pendingProposalsExpanded,
    pendingInvoicesExpanded,
    awaitingInvoiceExpanded,
    approveActionMemoProposal,
    rejectActionMemoProposal,
    rejectActionMemoInvoice,
    pastEtaWorkordersExpanded,
    unassignedWorkordersCount,
    pendingWorkordersCount,
    pendingProposalsCount,
    pendingInvoicesCount,
    awaitingInvoiceCount,
    pastEtaWoCount,
    shouldUseOwnCount,
    unassignedWorkordersCountData,
    pendingWorkordersCountData,
    awaitingInvoiceCountData,
    pendingProposalsCountData,
    pendingInvoicesCountData,
    unassignedWOFilter,
    pendingWOFilter,
    pendingProposalsFilter,
    pendingInvoicesFilter,
    awaitingInvoiceFilter,
    pastEtaWoFilter,
  ]);

  const selectedAdditionalData = useMemo(
    () =>
      expandedSelection.length > 0 &&
      expandedTableData?.tableData?.additionalData?.[0],
    [expandedSelection.length, expandedTableData],
  );

  const selectedAction = useMemo(
    () =>
      expandedTableData?.actions?.find(
        action => action.buttonLabel === actionModal,
      ),
    [actionModal, expandedTableData],
  );

  const renderOpenModuleButton = (module: Modules) => {
    switch (module) {
      case Modules.WORKORDER:
        return (
          <ECButton
            variant="contained"
            onClick={() => navigate(`${match?.pathnameBase}/work-orders`)}
            startIcon={<Speed />}
          >
            Open Work orders
          </ECButton>
        );
      case Modules.INVOICE:
        return (
          <ECButton
            variant="contained"
            onClick={() => navigate(`${match?.pathnameBase}/invoices`)}
            startIcon={<MonetizationOn />}
          >
            Open Invoices
          </ECButton>
        );
      case Modules.PROPOSAL:
        return (
          <ECButton
            variant="contained"
            onClick={() => navigate(`${match?.pathnameBase}/proposals`)}
            startIcon={<Description />}
          >
            Open Proposals
          </ECButton>
        );
      default:
        return '';
    }
  };

  // clear selected rows when drawer is closed
  useEffect(() => {
    if (!open) {
      setExpandedSelection([]);
    }
  }, [open]);

  return (
    <ECDrawerDetails open={open} anchor="right" onClose={onClose}>
      <ECPaper
        sx={{ height: '100%', paddingTop: '80px', boxShadow: 'none' }}
        role="presentation"
      >
        {expandedTableData && (
          <ECBox p={2} display="flex" gap={2} flexDirection="column">
            <Typography
              variant="h4"
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <span>
                {expandedTableData.count} {' |'}{' '}
                {expandedTableData.tableData?.title}
              </span>
              <ECButton variant="outlined" onClick={onClose}>
                <Close />
              </ECButton>
            </Typography>
            <ECBox display="flex" gap={2}>
              {renderOpenModuleButton(expandedTableData.module)}
              {expandedTableData.actions.map(action => (
                <ECButton
                  key={action.buttonLabel}
                  variant="outlined"
                  onClick={() => setActionModal(action.buttonLabel)}
                  scopes={action.scopes}
                  disabled={action.disabledRule(expandedSelection.length)}
                >
                  {action.buttonLabel}
                </ECButton>
              ))}
              <ECDashboardCategoryFilter
                categories={categoriesData}
                dashboardId={expandedTableData?.table}
                initialSelected={expandedTableData?.filters?.cat}
                onFiltersChange={handleFilterChange}
              />
            </ECBox>
            <ECBox display="flex" gap={2}>
              <Typography variant="h6">
                {expandedSelection.length} {' /'} {expandedTableData.count}
              </Typography>
              <ECButton variant="text" onClick={() => setExpandedSelection([])}>
                Deselect All
              </ECButton>
            </ECBox>
            {expandedTableData.tableData && (
              <MultiselectTable
                selected={expandedSelection}
                onSelect={setExpandedSelection}
                tableData={expandedTableData.tableData}
                handlePageChange={expandedTableData.handlePageChange}
                handleRowsPerPageChange={
                  expandedTableData.handleRowsPerPageChange
                }
                hasPagination={true}
              />
            )}
          </ECBox>
        )}
        {selectedAction && expandedTableData && (
          <ECModal isOpen={!!actionModal} onClose={handleClearModal} noPadding>
            <selectedAction.modalContent
              onClose={onCloseActionModal}
              onCancel={onCancelActionModal}
              selectedRows={expandedSelection}
              module={expandedTableData.tableData?.module}
              scopes={[P.AddComment]}
              queryTag={expandedTableData.queryTag}
              additionalData={selectedAdditionalData}
            />
          </ECModal>
        )}
      </ECPaper>
    </ECDrawerDetails>
  );
}
