import { ArrowDropDown, Timer } from '@mui/icons-material';
import { ECBox } from 'app/components/ECBox';
import { ECPopover } from 'app/components/ECPopover';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ECButton } from 'app/components/ECButton';
import {
  useGetTimeTrackCurrentStatusQuery,
  useUpdateTimeTrackStatusMutation,
} from 'services/timeTrackApi';
import { ECTypography } from 'app/components/ECTypography';
import { Divider } from '@mui/material';
import { useGetProfileQuery } from 'services/profileApi';
import { ECTimeTrackingItemButton } from './ECTimeTrackingItemButton';
import { NextTimeTrackStatusData, TimeTrackStatus } from 'types/TimeTrack';
import { setSnackbar } from 'store/slice/page';
import { useDispatch } from 'react-redux';

const STATUS_WITH_HALF_DRAWER = [
  TimeTrackStatus.CheckIn,
  TimeTrackStatus.StartDrive,
];

export const ECTimeTrackingButton = () => {
  const dispatch = useDispatch();
  const [anchorElTrackTime, setAnchorElDownload] =
    useState<HTMLButtonElement | null>(null);

  const { data: companyProfile } = useGetProfileQuery(undefined);

  const isTimeTrackEnabled = useMemo(() => {
    return (
      companyProfile?.spProfile?.spType?.code === 'INTERNAL' &&
      !companyProfile?.enableTimeTrack
    );
  }, [companyProfile]);

  const { data: statusData, isLoading: isLoadingStatus } =
    useGetTimeTrackCurrentStatusQuery(undefined, {
      skip: !isTimeTrackEnabled,
    });

  const currentStatus = statusData?.currentStatus;
  const nextStatuses = statusData?.nextStatus;
  const nextStatusRef = useRef<NextTimeTrackStatusData>();

  const [updateStatus, { isSuccess, isLoading: isLoadingStatusMutation }] =
    useUpdateTimeTrackStatusMutation();

  const handleButtonClick = (event: React.MouseEvent<any>) =>
    setAnchorElDownload(event?.currentTarget);

  const handleUpdateStatus = useCallback(
    (status: NextTimeTrackStatusData) => {
      nextStatusRef.current = status;

      if (STATUS_WITH_HALF_DRAWER.includes(status.name)) {
        // TODO: Add half drawer logic
      }

      updateStatus({
        prevStatus: currentStatus!.id,
        currentStatus: status.statusToId,
        woId: status.WOId,
        trackTime: new Date(),
      });
    },
    [currentStatus],
  );

  const handleClosePopup = () => {
    setAnchorElDownload(null);
  };

  const openTrackTimePopup = Boolean(anchorElTrackTime);
  const idTrackTimePopup = 'track-time-popover';

  const successMessageMapper = {
    [TimeTrackStatus.ClockIn]:
      'We successfully started tracking your working time',
    [TimeTrackStatus.ClockOut]: 'You successfully clocked out',
    [TimeTrackStatus.StartBreak]: 'Break was started',
    [TimeTrackStatus.EndBreak]: 'Break was ended',
  };

  useEffect(() => {
    if (
      isSuccess &&
      !isLoadingStatusMutation &&
      !!nextStatusRef?.current?.name
    ) {
      handleClosePopup();
      dispatch(
        setSnackbar({
          severity: 'success',
          message: successMessageMapper[nextStatusRef.current.name],
        }),
      );
    }
  }, [isSuccess, isLoadingStatusMutation]);

  if (!isTimeTrackEnabled) {
    return null;
  }

  return (
    <ECBox display="flex" mx={2}>
      <ECButton
        variant="contained"
        startIcon={<Timer />}
        endIcon={<ArrowDropDown />}
        onClick={e => handleButtonClick(e)}
        isLoading={isLoadingStatus || isLoadingStatusMutation}
      >
        Track time
      </ECButton>

      <ECPopover
        open={openTrackTimePopup}
        anchorEl={anchorElTrackTime}
        sx={{
          '& .MuiPopover-paper': {
            width: '227px',
          },
        }}
        onClose={handleClosePopup}
        id={idTrackTimePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ECBox display="flex" flexDirection="column" py={1}>
          <ECBox display="flex" py={1} px={2}>
            <ECTypography variant="body2">
              {currentStatus?.description || currentStatus?.name}
            </ECTypography>
          </ECBox>

          <Divider />

          {nextStatuses?.map(status => (
            <ECTimeTrackingItemButton
              key={status.statusToId}
              status={status}
              onClick={handleUpdateStatus}
            />
          ))}

          <Divider />

          <ECTypography
            variant="body1"
            color={theme => theme.palette.text.button.actionPrimary}
            py={1}
            px={2}
            sx={{ cursor: 'pointer' }}
          >
            View Tracked Today
          </ECTypography>
        </ECBox>
      </ECPopover>
    </ECBox>
  );
};
