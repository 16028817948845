import sha256 from 'crypto-js/sha256';

export const hashEmail = (email: string): string => {
  return sha256(email).toString();
};

export const trackEvent = (
  eventName: string,
  eventCategory: string,
  eventAction: string,
  eventLabel: string = '',
  userEmail: string = '',
): void => {
  const gtag = (window as any).gtag;

  if (!gtag) return;

  const userId = hashEmail(userEmail);

  gtag('event', eventName, {
    event_category: eventCategory,
    event_action: eventAction,
    event_label: eventLabel,
    user_id: userId,
  });
};
