import { ECDynamicPageTemplate } from 'app/components';
import * as _ from 'lodash';
import { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAddAssetProblemTroubleshootMutation,
  useLazyGetAssetProblemTroubleshootQuery,
  useGetAssetProblemTroubleshootQuery,
  useDeleteAssetProblemTroubleshootMutation,
  useEditAssetProblemTroubleshootMutation,
} from 'services/assetProblemTroubleshootApi';

//DROPDOWNS
import { populateDropDownFields } from 'utils/pageUtils';
import { useGetTroubleshootTipsQuery } from 'services/troubleshootingTipsApi';
import { loadPageScopes } from 'utils/pageScopes';
import { assetProblemTroubleshootBody } from 'types/AssetProblemTroubleshoot';
// END OF DROPDOWNS

import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { assetTypeToProblemApi } from 'services/assetTypeToProblemApi';

const tableConfig = require('./table_config.json');
const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsDetails = require('./fancy_form_config_details.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');

export function AssetProblemTroubleshootPage() {
  loadPageScopes('assetProblemTroubleshoot');
  const { t } = useTranslation();

  const [row, setRow] = useState<any>();

  // DROPDOWNS

  const {
    data: assetProblemTroubleshoot,
    isSuccess: isSuccessAssetProblemTroubleshoot,
  } = useGetAssetProblemTroubleshootQuery({});

  useEffect(() => {
    if (isSuccessAssetProblemTroubleshoot && assetProblemTroubleshoot) {
      const problems = assetProblemTroubleshoot?.data?.flatMap(assetGroup =>
        assetGroup.problemTroubleshoots?.map(problem => ({
          id: problem.problemId,
          name: problem.problemName,
        })),
      );

      const assetGroups = assetProblemTroubleshoot?.data?.map(assetGroup => ({
        id: assetGroup.assetGroupId,
        name: assetGroup.assetGroupName,
      }));

      populateDropDownFields({
        responseData: problems,
        editFormFields: fancyFormElementsEdit.fields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'problemName',
      });

      populateDropDownFields({
        responseData: assetGroups,
        editFormFields: fancyFormElementsEdit.fields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'assetGroupName',
      });
    }
  }, [isSuccessAssetProblemTroubleshoot, assetProblemTroubleshoot]);

  // END OF DROPDOWNS

  const [
    doDelete,
    {
      data: deleteData,
      isError: isDeleteError,
      error: deleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      reset: resetDelete,
    },
  ] = useDeleteAssetProblemTroubleshootMutation();

  const [
    doAdd,
    {
      data: addData,
      isError: isAddError,
      error: addError,
      isLoading: isAddLoading,
      isSuccess: isAddSuccess,
      reset: resetAdd,
    },
  ] = useAddAssetProblemTroubleshootMutation();

  const [
    doEdit,
    {
      data: editData,
      isError: isEditError,
      error: editError,
      isLoading: isEditLoading,
      isSuccess: isEditSuccess,
      reset: resetEdit,
    },
  ] = useEditAssetProblemTroubleshootMutation();

  const dispatch = useDispatch();

  function checkHasEmptyTroubleshoots(troubleshoots) {
    if (troubleshoots.some(troubleshoot => !troubleshoot.data)) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: 'Please select a troubleshoot tip for each dropdown',
        }),
      );
      return true;
    }
    return false;
  }

  const useUpdateAsset = useCallback(() => {
    const doUpdate = async data => {
      // need to find the troubleshoots that got deleted to return in the assetGroupProblemTroubleshootDeleteDto
      // and the troubleshoots that got added to return in the assetGroupProblemTroubleshootCreateDto
      // and also check if the troubleshoot order/position changed to return in the assetGroupProblemTroubleshootEditDto

      if (checkHasEmptyTroubleshoots(data.troubleshoots)) return;

      // remove any troubleshoot that was not selected
      const cleanedUpTroubleshoots = data.troubleshoots.filter(
        troubleshoot => troubleshoot.data,
      );

      const oldTroubleshootsIds = row?.troubleshoots.map(
        troubleshoot => troubleshoot.id,
      );
      const updatedTroubleshootsIds = cleanedUpTroubleshoots.map(
        troubleshoot => troubleshoot.id,
      );

      const deletedTroubleshootsIds: number[] = _.compact(
        _.difference(oldTroubleshootsIds, updatedTroubleshootsIds),
      );

      const unchangedTroubleshootsIds: number[] = _.compact(
        _.intersection(oldTroubleshootsIds, updatedTroubleshootsIds),
      );

      // the BE sends ORDER, so I need to compare the ORDER with the POSITION
      const troubleshootsThatChangedOrder = unchangedTroubleshootsIds.filter(
        troubleshootId =>
          cleanedUpTroubleshoots.find(x => x.id === troubleshootId)
            ?.position !==
          row.troubleshoots.find(x => x.id === troubleshootId)?.order,
      );

      const newTroubleshootsIds: number[] = _.compact(
        _.difference(updatedTroubleshootsIds, oldTroubleshootsIds),
      );

      doEdit({
        assetGroupProblemTroubleshootEditDto:
          troubleshootsThatChangedOrder.length > 0
            ? troubleshootsThatChangedOrder.map(troubleshootId => ({
                assetTradeProblemId: row.assetGroupTradeProblemId,
                troubleshootId,
                order: cleanedUpTroubleshoots.find(x => x.id === troubleshootId)
                  ?.position,
              }))
            : [],
        assetGroupProblemTroubleshootCreateDto:
          newTroubleshootsIds.length > 0
            ? newTroubleshootsIds?.map(troubleshootId => ({
                assetTradeProblemId: row.assetGroupTradeProblemId,
                troubleshootId,
                order: cleanedUpTroubleshoots?.find(
                  x => x.id === troubleshootId,
                )?.position,
              }))
            : [],
        assetGroupProblemTroubleshootDeleteDto:
          deletedTroubleshootsIds.length > 0
            ? deletedTroubleshootsIds?.map(troubleshootId => ({
                assetTradeProblemId: row.assetGroupTradeProblemId,
                troubleshootId,
              }))
            : [],
      });
    };

    return [
      doUpdate,
      {
        data: editData,
        isError: isEditError,
        error: editError,
        isLoading: isEditLoading,
        isSuccess: isEditSuccess,
        reset: resetEdit,
      },
    ];
  }, [
    editData,
    editError,
    isEditError,
    isEditLoading,
    isEditSuccess,
    row,
    resetEdit,
  ]);

  const useCreateAsset = useCallback(() => {
    const doUpdate = async data => {
      const troubleshoots =
        data.assetProblemTroubleshoot?.problemTroubleshoots?.filter(
          x => x.troubleshoots?.length > 0,
        );

      if (
        troubleshoots.some(problem =>
          checkHasEmptyTroubleshoots(problem?.troubleshoots),
        )
      )
        return;

      const troubleshootBody: assetProblemTroubleshootBody[] =
        troubleshoots.flatMap(problem => {
          return problem.troubleshoots
            .filter(tb => tb.data)
            .map(troubleshoot => ({
              troubleshootId: troubleshoot.id,
              assetTradeProblemId: problem.assetTradeProblemId,
              order: troubleshoot.position,
            }));
        });
      doAdd(troubleshootBody);
    };
    return [
      doUpdate,
      {
        data: addData,
        isError: isAddError,
        error: addError,
        isLoading: isAddLoading,
        isSuccess: isAddSuccess,
        reset: resetAdd,
      },
    ];
  }, [addData, addError, isAddError, isAddLoading, isAddSuccess, row, doAdd]);

  // after update is success, invalidate the tag of the asset group dropdown query to remove the problems that were added
  useEffect(() => {
    if (isAddSuccess) {
      dispatch(
        assetTypeToProblemApi.util.invalidateTags([
          'AssetTypeToProblem',
          'ProblemsByAssetTypeId',
        ]),
      );
    }
  }, [isAddSuccess, resetAdd]);

  const useDeleteAsset = useCallback(() => {
    const doRemoveRelationship = async data => {
      doDelete({
        assetGroupId: data.assetGroupId,
        body: data.troubleshoots.map(troubleshoot => {
          return {
            assetTradeProblemId: data.assetGroupTradeProblemId,
            troubleshootId: troubleshoot.id,
          };
        }),
      });
    };
    return [
      doRemoveRelationship,
      {
        data: deleteData,
        isError: isDeleteError,
        error: deleteError,
        isLoading: isDeleteLoading,
        isSuccess: isDeleteSuccess,
        reset: resetDelete,
      },
    ];
  }, [
    deleteData,
    deleteError,
    isDeleteError,
    isDeleteLoading,
    isDeleteSuccess,
    doDelete,
    resetDelete,
  ]);

  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      pageTitle={`${
        row?.assetGroupName ||
        t('translation:pages.assetProblemTroubleshoot.title')
      }`}
      useLazyGetListQuery={useLazyGetAssetProblemTroubleshootQuery}
      useCreateMutation={useCreateAsset}
      useUpdateMutation={useUpdateAsset}
      useDeleteMutation={useDeleteAsset}
      tableConfig={tableConfig.config}
      editFormConfig={fancyFormElementsEdit.config}
      editFormFields={fancyFormElementsEdit.fields}
      detailsConfig={fancyFormElementsDetails.data.config}
      detailsFields={fancyFormElementsDetails.data.fields}
      marginTop={false}
      createFormConfig={fancyFormElementsCreate.config}
      createFormFields={fancyFormElementsCreate.fields}
      onRowClick={setRow}
      onDrawerClose={() => setRow({})}
      disclaimer={t('translation:pages.assetProblemTroubleshoot.disclaimer')}
      deleteConfirmationTitle={t(
        'translation:pages.assetProblemTroubleshoot.delete.title',
      )}
      deleteConfirmationBody={t(
        'translation:pages.assetProblemTroubleshoot.delete.body',
      )}
      shouldNotUseActiveFilter
    />
  );
}
