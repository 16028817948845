import { formatPhone } from 'utils/strings/phone';
import _ from 'lodash';
import { currencyFormatter } from './strings/currency-formatter';
import defaultEmptyImage from '../assets/default-empty-image.svg';
import { Duration } from 'luxon';

const head = workOrderId => `
<head>
    <meta charset="utf-8" />
    <meta content="width=device-width, initial-scale=1" name="viewport" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="x-apple-disable-message-reformatting" />
    <meta name="format-detection" content="telephone=no,address=no,email=no,date=no,url=no" />
    <title>Work Order #${workOrderId}</title>
    <!--[if mso]>
    <style>
        * {
            font-family: sans-serif !important;
        }
    </style>
    <![endif]-->
    <link href="https://fonts.googleapis.com/css?family=Roboto:400" rel="stylesheet" type="text/css">
    <style>
        html, body {
            margin: 0;
            padding: 0;
        }
        * {
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
        }
        td {
            vertical-align: top;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
        }
        a {
            text-decoration: none;
        }
        img {
            -ms-interpolation-mode: bicubic;
        }
        @media only screen and (min-width: 320px) {
            u ~ div .email-container {
                min-width: 320px !important;
            }
        }
        @media only screen and (min-width: 375px) {
            u ~ div .email-container {
                min-width: 375px !important;
            }
        }
        @media only screen and (min-width: 414px) {
            u ~ div .email-container {
                min-width: 414px !important;
            }
        }
              .custom-table {
            width: 100%;
            border-collapse: collapse;
            padding: 0;
            margin: 0;
        }
        .custom-table-cell {
            padding: 15px;
            vertical-align: top;
            word-wrap: break-word;
            max-width: 200px;
        }
        .custom-table-row {
            border-bottom: 1px solid lightgrey;
        }
        .label {
            font-weight: bold;
            display: block;
        }
         .page-break {
            page-break-before: always;
        }
    </style>
    <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG />
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <style>
        @media only screen and (max-width: 611px) {
            .eh {
                height: auto !important;
            }
            .desktop {
                display: none !important;
                height: 0 !important;
                margin: 0 !important;
                max-height: 0 !important;
                overflow: hidden !important;
                padding: 0 !important;
                visibility: hidden !important;
                width: 0 !important;
            }
            .mobile {
                display: block !important;
                width: auto !important;
                height: auto !important;
                float: none !important;
            }
            .email-container {
                width: 100% !important;
                margin: auto !important;
            }
            .stack-column, .stack-column-center {
                display: block !important;
                width: 100% !important;
                max-width: 100% !important;
                direction: ltr !important;
            }
            .wid-auto {
                width: auto !important;
            }
            .table-w-full-mobile {
                width: 100%;
            }
            .mobile-center {
                text-align: center;
            }
            .mobile-center > table {
                display: inline-block;
                vertical-align: inherit;
            }
            .mobile-left {
                text-align: left;
            }
            .mobile-left > table {
                display: inline-block;
                vertical-align: inherit;
            }
            .mobile-right {
                text-align: right;
            }
            .mobile-right > table {
                display: inline-block;
                vertical-align: inherit;
            }
        }
    </style>
</head>
`;

const populateWOItem = (title, value) => {
  if (_.isNil(value)) {
    return '';
  }

  return `<tr>
       <td>
           <table cellspacing="0" cellpadding="0" border="0">
               <tr>
                   <td>
                       <table width="100%" border="0" cellpadding="0" cellspacing="0">
                           <tr>
                               <td width="154">
                                   <div style="text-align:left;">
                                       <span style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:12px;letter-spacing:0.15px;line-height:150%;text-align:left;">
                                           ${title}
                                       </span>
                                   </div>
                               </td>
                               <td style="width:16px; min-width:16px;" width="16"></td>
                               <td style="vertical-align: middle; border-radius:5px;">
                                   <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                       <tr>
                                           <td style="vertical-align: middle;">
                                               <div style="text-align:left;">
                                                   <span style="color:#181a1d;font-family:Roboto,Arial,sans-serif;font-size:12px;letter-spacing:0.15px;line-height:150%;text-align:left;">
                                                       ${value}
                                                   </span>
                                               </div>
                                           </td>
                                       </tr>
                                   </table>
                               </td>
                           </tr>
                       </table>
                   </td>
               </tr>
           </table>
       </td>
   </tr>`;
};

const populateWOSubItem1 = (title, value) => {
  if (_.isNil(value)) {
    return '';
  }
  return `<tr>
      <td width="100%">
         <table width="100%" border="0" cellpadding="0" cellspacing="0">
            <tr>
               <td width="154">
                  <div style="text-align:left;"><span style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:12px;letter-spacing:0.15000000596046448px;line-height:150%;text-align:left;">${title}</span></div>
               </td>
               <td style="width:16px; min-width:16px;" width="16"></td>
               <td>
                  <div style="text-align:left;"><span style="color:#181a1d;font-family:Roboto,Arial,sans-serif;font-size:12px;letter-spacing:0.15000000596046448px;line-height:150%;text-align:left;">${value}</span></div>
               </td>
            </tr>
         </table>
      </td>
   </tr>`;
};
const populateWOPhone = (phone, phone_ext) => {
  return `<tr>
            <td width="100%">
               <table width="100%"
                  border="0"
                  cellpadding="0"
                  cellspacing="0">
                  <tr>
                        <td width="50%">
                           <table
                              width="100%"
                              border="0"
                              cellpadding="0"
                              cellspacing="0">
                              <tr>
                                    <td
                                       width="100%">
                                       <table
                                          width="100%"
                                          border="0"
                                          cellpadding="0"
                                          cellspacing="0">
                                          <tr>
                                                <td>
                                                   <table
                                                      cellspacing="0"
                                                      cellpadding="0"
                                                      border="0">
                                                      <tr>
                                                            <td>
                                                               <div
                                                                  style="text-align:left;">
                                                                  <span
                                                                        style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:12px;letter-spacing:0.15000000596046448px;line-height:150%;text-align:left;">Phone
                                                                        Number</span>
                                                               </div>
                                                            </td>
                                                      </tr>
                                                   </table>
                                                </td>
                                          </tr>
                                          <tr>
                                                <td height="5.5"
                                                   style="height:5.5px; min-height:5.5px; line-height:5.5px;">
                                                </td>
                                          </tr>
                                          <tr>
                                                <td>
                                                   <div
                                                      style="text-align:left;">
                                                      <span
                                                            style="color:#181a1d;font-family:Roboto,Arial,sans-serif;font-size:12px;letter-spacing:0.15000000596046448px;line-height:150%;text-align:left;">${phone}</span>
                                                   </div>
                                                </td>
                                          </tr>
                                          <tr>
                                                <td height="5.5"
                                                   style="height:5.5px; min-height:5.5px; line-height:5.5px;">
                                                </td>
                                          </tr>
                                          <tr>
                                                <td
                                                   width="91">
                                                   <img src="https://plugin.markaimg.com/public/b028b873/UnEU4z7W55cLLrajO0tEq3ceEed60G.png"
                                                      width="91"
                                                      border="0"
                                                      style="width: 100%; height: auto; display: block;"></td>
                                          </tr>
                                       </table>
                                    </td>
                              </tr>
                           </table>
                        </td>
                        <td width="50%"
                           style="height:48px;  ">
                           <table
                              width="100%"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              style="margin-top: 3px;"
                              >
                              <tr>
                                    <td width="100%"
                                       style="height:48px;  ">
                                       <table
                                          width="100%"
                                          border="0"
                                          cellpadding="0"
                                          cellspacing="0">
                                          <tr>
                                                <td>
                                                   <table
                                                      cellspacing="0"
                                                      cellpadding="0"
                                                      border="0">
                                                      <tr>
                                                            <td>
                                                               <div
                                                                  style="line-height:12px;text-align:left;">
                                                                  <span
                                                                        style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:12px;letter-spacing:0.15000000596046448px;line-height:12px;text-align:left;">EXT</span>
                                                               </div>
                                                            </td>
                                                      </tr>
                                                   </table>
                                                </td>
                                          </tr>
                                          <tr>
                                                <td height="4"
                                                   style="height:4px; min-height:4px; line-height:4px;">
                                                </td>
                                          </tr>
                                          <tr>
                                                <td>
                                                   <div
                                                      style="line-height:24px;text-align:left;">
                                                      <span
                                                            style="color:#181a1d;font-family:Roboto,Arial,sans-serif;font-size:12px;letter-spacing:0.15000000596046448px;line-height:24px;text-align:left;">${phone_ext}</span>
                                                   </div>
                                                </td>
                                          </tr>
                                          <tr>
                                                <td height="4"
                                                   style="height:4px; min-height:4px; line-height:4px;">
                                                </td>
                                          </tr>
                                          <tr>
                                                <td
                                                   width="91">
                                                   <img src="https://plugin.markaimg.com/public/b028b873/5kTVBCNmI9mCkZemuYeSZWjvsiytn7.png"
                                                      width="91"
                                                      border="0"
                                                      style="width: 100%; height: auto; display: block;">
                                                </td>
                                          </tr>
                                       </table>
                                    </td>
                              </tr>
                           </table>
                        </td>
                  </tr>
               </table>
            </td>
         </tr>`;
};

const preloadedImage = new Image();
preloadedImage.src =
  'https://plugin.markaimg.com/public/b028b873/gHBEJbRCa6VncR7aHFh1F79PFimS00.png';

const populateWOSubItem2 = (title, value) => {
  if (_.isNil(value)) {
    return '';
  }
  return `<tr>
   <td width="100%">
      <table width="100%" border="0" cellpadding="0" cellspacing="0">
         <tr>
            <td>
               <table cellspacing="0" cellpadding="0" border="0">
                  <tr>
                     <td>
                        <div style="text-align:left;">
                            <span style="color:#828282;font-family:Roboto,Arial,sans-serif;font-size:12px;letter-spacing:0.15000000596046448px;line-height:150%;text-align:left;">
                                ${title}
                            </span>
                        </div>
                     </td>
                  </tr>
               </table>
            </td>
         </tr>
         <tr>
            <td height="5.5" style="height:5.5px; min-height:5.5px; line-height:5.5px;"></td>
         </tr>
         <tr>
            <td>
               <div style="text-align:left;">
                   <span style="color:#181a1d;font-family:Roboto,Arial,sans-serif;font-size:12px;letter-spacing:0.15000000596046448px;line-height:150%;text-align:left;">
                       ${value}
                   </span>
               </div>
            </td>
         </tr>
         <tr>
            <td height="5.5" style="height:5.5px; min-height:5.5px; line-height:5.5px;"></td>
         </tr>
         <tr>
            <td width="183">
                <img src="${preloadedImage.src}" width="183" border="0" style="width: 100%; height: auto; display: block;">
            </td>
         </tr>
      </table>
   </td>
</tr>`;
};

const populateInvoiceSubItem = (title, value, width = 100) => {
  if (_.isNil(value)) {
    return '';
  }
  return `
   <td width="${width}%">
      <table width="100%" border="0" cellpadding="0" cellspacing="0">
         <tr>
            <td>
               <table cellspacing="0" cellpadding="0" border="0">
                  <tr>
                     <td>
                        <div style="text-align:left;">
                            <span style="color:#828282;font-family:Roboto,Arial,sans-serif;font-size:12px;letter-spacing:0.15000000596046448px;line-height:150%;text-align:left;">
                                ${title}
                            </span>
                        </div>
                     </td>
                  </tr>
               </table>
            </td>
         </tr>
         <tr>
            <td height="5.5" style="height:5.5px; min-height:5.5px; line-height:5.5px;"></td>
         </tr>
         <tr>
            <td>
               <div style="text-align:left;">
                   <span style="color:#181a1d;font-family:Roboto,Arial,sans-serif;font-size:12px;letter-spacing:0.15000000596046448px;line-height:150%;text-align:left;">
                       ${value}
                   </span>
               </div>
            </td>
         </tr>
         <tr>
            <td height="5.5" style="height:5.5px; min-height:5.5px; line-height:5.5px;"></td>
         </tr>
         <tr>
            <td width="183">
                <img src="${preloadedImage.src}" width="183" border="0" style="width: 100%; height: auto; display: block;">
            </td>
         </tr>
      </table>
   </td>
`;
};

const generateCommentsHTMLContent = (comments, moduleName, id) => {
  const htmlContent = `
<div style="background-color:#e5e5e5" class="page-break">
   <table width="100%" cellpadding="0" cellspacing="0" border="0">
      <tr>
         <td valign="top" align="center">
            <table bgcolor="#ffffff" style="margin:0 auto;" align="center" id="brick_container" cellspacing="0" cellpadding="0" border="0" width="612" class="email-container">
               <tr>
                  <td width="612">
                     <table cellspacing="0" cellpadding="0" border="0">
                        <tr>
                           <td width="564" style="background-color:#ffffff;   padding-left:24px; padding-right:24px;" bgcolor="#ffffff">
                              <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                 <tr>
                                    <td height="24" style="height:24px; min-height:24px; line-height:24px;"></td>
                                 </tr>
                                 <tr>
                                    <td width="100%" align="center" class="q9Jde00TrjVFOHbILNhMJKy5LGu9RD invert-bg" style="vertical-align: middle; background-repeat:no-repeat !important; background-position: center center !important; background-size: cover !important; border-collapse:separate !important;" background="https://plugin.markaimg.com/public/b028b873/q9Jde00TrjVFOHbILNhMJKy5LGu9RD.png">
                                       <!--[if gte mso 9]>
                                       <v:image xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style=" border: 0;display: inline-block; width: 564px; height:28px;"
                                          src="https://plugin.markaimg.com/public/b028b873/q9Jde00TrjVFOHbILNhMJKy5LGu9RD.png"
                                          />
                                       <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style=" border: 0;display: inline-block;position: absolute; width: 564px; height:28px;">
                                          <v:fill opacity="0%" color="#000" />
                                          <v:textbox inset="0,0,0,0">
                                             <![endif]-->
                                             <div>
                                                <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                   <tr>
                                                      <td>
                                                         <table cellspacing="0" cellpadding="0" border="0" style="width: 100%;">
                                                            <tr>
                                                               <td style="vertical-align: middle; text-align: center;">
                                                                  <div style="display: flex; align-items: center; justify-content: center; width: 100%; position: relative; padding-top: 2px; padding-bottom: 2px">
                                                                     <img src="https://duploservices-prod01-ue2-api2-814951629518.s3.us-east-2.amazonaws.com/img/LogoEmailTemplates.png" width="97" border="0" style="min-width:97px; width:97px; height: auto; display: block; margin: auto;">
                                                                     <span style="color: white; position: absolute; right: 0;">${moduleName} ID: ${id}</span>
                                                                  </div>
                                                               </td>
                                                            </tr>
                                                         </table>
                                                      </td>
                                                   </tr>
                                                </table>
                                             </div>
                                             <!--[if gte mso 9]>
                                          </v:textbox>
                                          </v:fill>
                                       </v:rect>
                                       </v:image>
                                       <![endif]-->
                              </table>
                              <table class="custom-table">
                              ${comments
                                ?.map(comment => {
                                  return `
                                   <tr class="custom-table-row">
                                     <td class="custom-table-cell">
                                       <span class="label">${comment?.createdByUser?.firstName} ${comment?.createdByUser?.lastName}</span>
                                       <span>${comment?.createdByUser?.email}</span>
                                       <span>${comment?.createdByUser?.jobTitle?.name}</span>
                                       <span class="label">Notified Users:</span>
                                       <span>${
                                         comment?.recipients
                                           ?.map(
                                             r =>
                                               `${r.firstName} ${r.lastName}`,
                                           )
                                           .join(', ') ?? ''
                                       }</span>
                                     </td>
                                     <td class="custom-table-cell">
                                       ${comment?.body}
                                     </td>
                                     <td class="custom-table-cell">
                                       <span>${comment?.createdByUser?.company?.name}</span>
                                       <span>${comment?.createdAt}</span>
                                     </td>
                                   </tr>
                                 `;
                                })
                                .join('')}
                              </table>
                           </td>
                        </tr>
                     </table>
                  </td>
               </tr>
            </table>
         </td>
      </tr>
   </table>
</div>
   `;

  return htmlContent;
};

export const generateWODetailsHTMLContent = (
  workOrder,
  asset,
  warrantyStatus,
  includeComments = false,
  comments: any = [],
): string => {
  const htmlContent = ` ${head(workOrder.id)}
      <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
      
      <body width="100%" style="background-color:#e5e5e5;margin:0;padding:0!important;mso-line-height-rule:exactly;">
         <div style="background-color:#e5e5e5">
            <!--[if gte mso 9]>
            <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
               <v:fill type="tile" color="#e5e5e5" />
            </v:background>
            <![endif]-->
            <table width="100%" cellpadding="0" cellspacing="0" border="0">
               <tr>
                  <td valign="top" align="center">
                     <table bgcolor="#ffffff" style="margin:0 auto;" align="center" id="brick_container" cellspacing="0" cellpadding="0" border="0" width="612" class="email-container">
                        <tr>
                           <td width="612">
                              <table cellspacing="0" cellpadding="0" border="0">
                                 <tr>
                                    <td width="564" style="background-color:#ffffff;   padding-left:24px; padding-right:24px;" bgcolor="#ffffff">
                                       <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                          <tr>
                                             <td height="24" style="height:24px; min-height:24px; line-height:24px;"></td>
                                          </tr>
                                          <tr>
                                             <td width="100%" align="center" class="q9Jde00TrjVFOHbILNhMJKy5LGu9RD invert-bg" style="vertical-align: middle; background-repeat:no-repeat !important; background-position: center center !important; background-size: cover !important; border-collapse:separate !important;" background="https://plugin.markaimg.com/public/b028b873/q9Jde00TrjVFOHbILNhMJKy5LGu9RD.png">
                                                <!--[if gte mso 9]>
                                                <v:image xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style=" border: 0;display: inline-block; width: 564px; height:28px;"
                                                   src="https://plugin.markaimg.com/public/b028b873/q9Jde00TrjVFOHbILNhMJKy5LGu9RD.png"
                                                />
                                                <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style=" border: 0;display: inline-block;position: absolute; width: 564px; height:28px;">
                                                   <v:fill opacity="0%" color="#000" />
                                                   <v:textbox inset="0,0,0,0">
                                                      <![endif]-->
                                                      <div>
                                                         <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                            <tr>
                                                               <td>
                                                                     <table cellspacing="0" cellpadding="0" border="0" style="width: 100%;">
                                                                        <tr>
                                                                           <td style="vertical-align: middle; text-align: center;">
                                                                                 <div style="display: flex; align-items: center; justify-content: center; width: 100%; position: relative; padding-top: 2px; padding-bottom: 2px">
                                                                                    <img src="https://duploservices-prod01-ue2-api2-814951629518.s3.us-east-2.amazonaws.com/img/LogoEmailTemplates.png" width="97" border="0" style="min-width:97px; width:97px; height: auto; display: block; margin: auto;">
                                                                                    <span style="color: white; position: absolute; right: 0;">WO ID: ${workOrder.id}</span>
                                                                                 </div>
                                                                           </td>
                                                                        </tr>
                                                                     </table>
                                                               </td>
                                                            </tr>
                                                         </table>
                                                      </div>
                                                      <!--[if gte mso 9]>
                                                   </v:textbox>
                                                </v:fill>
                                             </v:rect>
                                          </v:image>
                                          <![endif]-->
                                    </td>
                                 </tr>
                                 <tr>
                                    <td width="100%">
                                       <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                          <tr>
                                             <td width="100%" style="background-color:#ffffff;  " bgcolor="#ffffff">
                                                <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                   <tr>
                                                      <td width="100%" align="center">
                                                         <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                            <tr>
                                                               <td height="8" style="height:8px; min-height:8px; line-height:8px;"></td>
                                                            </tr>
                                                            <tr>
                                                               <td>
                                                                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                     <tr>
                                                                        <td width="100%">
                                                                           <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                              ${populateWOItem('WO ID:', workOrder.id)}
                                                                              ${populateWOItem('WO Status:', workOrder.workflowStatus)}
                                                                              ${populateWOItem('Location:', workOrder.locationName)}
                                                                              ${populateWOItem('Store Number:', workOrder.locationStoreNumber)}
                                                                              ${populateWOItem('Service Provider:', workOrder.serviceProviderName)}
                                                                              <tr>
                                                                                 <td width="100%">
                                                                                    <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                       <tr>
                                                                                          <td width="100%" style="background-color:#f5f5f5;  " bgcolor="#f5f5f5">
                                                                                             <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                                ${populateWOSubItem1('Asset:', workOrder.assetName)}
                                                                                                ${populateWOSubItem1('Asset Group:', workOrder.assetGroupName)}
                                                                                                ${populateWOSubItem1('Serial #:', asset.serialNumber)}
                                                                                                ${populateWOSubItem1('Model #:', asset.modelNumber)}
                                                                                                ${populateWOSubItem1('Manufacturer:', asset?.manufacture?.name)}
                                                                                             </table>
                                                                                          </td>
                                                                                       </tr>
                                                                                    </table>
                                                                                 </td>
                                                                              </tr>
                                                                              ${populateWOSubItem1('Category:', workOrder.category)}
                                                                              ${populateWOSubItem1('Created Date:', workOrder.workOrderDate)}
                                                                              ${populateWOSubItem1('Priority:', workOrder.priorityName)}
                                                                           </table>
                                                                        </td>
                                                                     </tr>
                                                                  </table>
                                                               </td>
                                                               <td style="width:16px; min-width:16px;" width="16"></td>
                                                               <td width="123" align="center"><img src=${workOrder?.assetImageURL ?? defaultEmptyImage} width="123" border="0" style="min-width:123px; width:123px; height: auto; display: block;"></td>
                                                            </tr>
                                                         </table>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td height="16" style="height:16px; min-height:16px; line-height:16px;"></td>
                                                   </tr>
                                                </table>
                                             </td>
                                          </tr>
                                       </table>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td width="100%">
                                       <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                          <tr>
                                             <td width="100%" style="height:458px; background-color:#ffffff;  " bgcolor="#ffffff">
                                                <table border="0" cellpadding="0" cellspacing="0">
                                                   <tr>
                                                      <td width="32%">
                                                         <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                            ${populateWOSubItem2('Technician Assigned', workOrder?.spAssigneeUserName)}
                                                            ${populateWOSubItem2('User Assigned', workOrder?.assigneeUser?.fullName)}
                                                            ${populateWOSubItem2('Trade', workOrder?.tradeName)}
                                                            ${populateWOSubItem2('Problem', workOrder?.problem)}
                                                            ${populateWOSubItem2('NTE', currencyFormatter.format(workOrder?.nte))}                                                            
                                                            ${populateWOPhone(formatPhone(workOrder?.serviceProviderPhone), workOrder?.serviceProviderPhoneExt)}                                                            
                                                            ${populateWOSubItem2('Address', workOrder?.address)}
                                                            ${populateWOSubItem2('Requested by', workOrder?.requestedBy)}
                                                            ${populateWOSubItem2('Job Title', workOrder?.jobTitleName)}
                                                         </table>
                                                      </td>
                                                      <td style="width:8px; min-width:8px;" width="8"></td>
                                                      <td width="32%">
                                                         <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                            ${populateWOSubItem2('Work Order Submitted By', workOrder?.createdBy)}
                                                            ${populateWOSubItem2('Customer Code', workOrder?.customerCode)}
                                                            ${populateWOSubItem2('Service Provider Work Order ID', workOrder?.spwoId)}
                                                            ${populateWOSubItem2('Age of Work Order', workOrder?.workOrderAge)}
                                                            ${populateWOSubItem2('Current ETA', workOrder?.eta)}
                                                            ${populateWOSubItem2('Original ETA', workOrder?.originalEta)}
                                                            ${populateWOSubItem2('Created Date', workOrder?.workOrderDate)}
                                                            ${populateWOSubItem2('Completion date', workOrder?.completedDate)}
                                                            ${populateWOSubItem2(
                                                              'On - Site Time',
                                                              workOrder?.onSiteTime
                                                                ? (() => {
                                                                    const duration =
                                                                      Duration.fromObject(
                                                                        {
                                                                          hours:
                                                                            workOrder?.onSiteTime,
                                                                        },
                                                                      );
                                                                    return duration.as(
                                                                      'hours',
                                                                    ) < 1
                                                                      ? duration.toFormat(
                                                                          "m 'mins'",
                                                                        )
                                                                      : duration.toFormat(
                                                                          "h 'hrs' mm 'mins'",
                                                                        );
                                                                  })()
                                                                : '--',
                                                            )}
                                                         </table>
                                                      </td>
                                                      <td style="width:8px; min-width:8px;" width="8"></td>
                                                      <td width="32%">
                                                         <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                            ${populateWOSubItem2('Warranties', warrantyStatus)}
                                                            ${populateWOSubItem2('Over Time Approved', workOrder?.overtimeApproved ? 'Yes' : 'No')}
                                                            ${populateWOSubItem2('Internal', workOrder.internal ? 'Yes' : 'No')}
                                                            ${populateWOSubItem2(
                                                              'Flags',
                                                              workOrder?.flags
                                                                ?.length > 0
                                                                ? workOrder?.flags
                                                                    ?.slice(
                                                                      0,
                                                                      5,
                                                                    )
                                                                    .map(flag =>
                                                                      flag.label.slice(
                                                                        0,
                                                                        50,
                                                                      ),
                                                                    )
                                                                    .join(
                                                                      '<br>',
                                                                    ) +
                                                                    (workOrder
                                                                      .flags
                                                                      .length >
                                                                    5
                                                                      ? '<br>...'
                                                                      : '')
                                                                : null,
                                                            )}
                                                            ${populateWOSubItem2('Description', workOrder?.description)}
                                                         </table>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td height="24" style="height:24px; min-height:24px; line-height:24px;"></td>
                                                   </tr>
                                                </table>
                                             </td>
                                          </tr>
                                       </table>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td height="24" style="height:24px; min-height:24px; line-height:24px;"></td>
                                 </tr>
                              </table>
                           </td>
                        </tr>
                     </table>
                  </td>
               </tr>
            </table>
            ${includeComments && comments?.length > 0 ? generateCommentsHTMLContent(comments, 'WO', workOrder?.id) : ''}
         </div>   
      </body >
   </html >    
    `;
  return htmlContent;
};

export const generateInvoiceDetailsHTMLContent = (
  invoice,
  asset,
  isEnabledInventory,
  itemsInventory,
  includeComments = false,
  comments: any = [],
): string => {
  const htmlContent = ` ${head(invoice.id)}
      <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
      
      <body width="100%" style="background-color:#e5e5e5;margin:0;padding:0!important;mso-line-height-rule:exactly;">
         <div style="background-color:#e5e5e5">
            <!--[if gte mso 9]>
            <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
               <v:fill type="tile" color="#e5e5e5" />
            </v:background>
            <![endif]-->
            <table width="100%" cellpadding="0" cellspacing="0" border="0">
               <tr>
                  <td valign="top" align="center">
                     <table bgcolor="#ffffff" style="margin:0 auto;" align="center" id="brick_container" cellspacing="0" cellpadding="0" border="0" width="612" class="email-container">
                        <tr>
                           <td width="612">
                              <table cellspacing="0" cellpadding="0" border="0">
                                 <tr>
                                    <td width="564" style="background-color:#ffffff;   padding-left:24px; padding-right:24px;" bgcolor="#ffffff">
                                       <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                          <tr>
                                             <td height="24" style="height:24px; min-height:24px; line-height:24px;"></td>
                                          </tr>
                                          <tr>
                                             <td width="100%" align="center" class="q9Jde00TrjVFOHbILNhMJKy5LGu9RD invert-bg" style="vertical-align: middle; background-repeat:no-repeat !important; background-position: center center !important; background-size: cover !important; border-collapse:separate !important;" background="https://plugin.markaimg.com/public/b028b873/q9Jde00TrjVFOHbILNhMJKy5LGu9RD.png">
                                                <!--[if gte mso 9]>
                                                <v:image xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style=" border: 0;display: inline-block; width: 564px; height:28px;"
                                                   src="https://plugin.markaimg.com/public/b028b873/q9Jde00TrjVFOHbILNhMJKy5LGu9RD.png"
                                                />
                                                <v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style=" border: 0;display: inline-block;position: absolute; width: 564px; height:28px;">
                                                   <v:fill opacity="0%" color="#000" />
                                                   <v:textbox inset="0,0,0,0">
                                                      <![endif]-->
                                                      <div>
                                                         <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                            <tr>
                                                               <td>
                                                                     <table cellspacing="0" cellpadding="0" border="0" style="width: 100%;">
                                                                        <tr>
                                                                           <td style="vertical-align: middle; text-align: center;">
                                                                                 <div style="display: flex; align-items: center; justify-content: center; width: 100%; position: relative; padding-top: 2px; padding-bottom: 2px">
                                                                                    <img src="https://duploservices-prod01-ue2-api2-814951629518.s3.us-east-2.amazonaws.com/img/LogoEmailTemplates.png" width="97" border="0" style="min-width:97px; width:97px; height: auto; display: block; margin: auto;">
                                                                                 </div>
                                                                           </td>
                                                                        </tr>
                                                                     </table>
                                                               </td>
                                                            </tr>
                                                         </table>
                                                      </div>
                                                      <!--[if gte mso 9]>
                                                   </v:textbox>
                                                </v:fill>
                                             </v:rect>
                                          </v:image>
                                          <![endif]-->
                                    </td>
                                 </tr>
                                 <tr>
                                    <td width="100%">
                                       <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                          <tr>
                                             <td width="100%" style="background-color:#ffffff;  " bgcolor="#ffffff">
                                                <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                   <tr>
                                                      <td width="100%" align="center">
                                                         <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                            <tr>
                                                               <td height="8" style="height:8px; min-height:8px; line-height:8px;"></td>
                                                            </tr>
                                                            <tr>
                                                               <td>
                                                                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                     <tr>
                                                                        <td width="100%">
                                                                           <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                              ${populateWOItem('SP Invoice Number:', invoice?.invoiceNumber || '-')}
                                                                              ${populateWOItem('Invoice Status:', invoice?.workflowStatus?.name || '-')}
                                                                              ${populateWOItem('Location:', invoice?.request?.asset?.branch?.name || '-')}
                                                                              ${populateWOItem('Store Number:', invoice?.request?.asset?.branch?.storeNumber || '-')}
                                                                              ${populateWOItem('Customer:', invoice?.request?.customer?.name || '-')}
                                                                              <tr>
                                                                                 <td width="100%">
                                                                                    <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                       <tr>
                                                                                          <td width="100%" style="background-color:#f5f5f5;  " bgcolor="#f5f5f5">
                                                                                             <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                                ${populateWOSubItem1('Asset:', invoice?.request?.asset?.name || '-')}
                                                                                                ${populateWOSubItem1('Asset Group:', invoice?.request?.asset?.assetType?.name || '-')}
                                                                                                ${populateWOSubItem1('Serial #:', invoice?.invoiceSerial || '-')}
                                                                                                ${populateWOSubItem1('Model #:', asset?.[0]?.modelNumber || '-')}
                                                                                                ${populateWOSubItem1('Manufacturer:', invoice?.request?.category?.name || '-')}
                                                                                             </table>
                                                                                          </td>
                                                                                       </tr>
                                                                                    </table>
                                                                                 </td>
                                                                              </tr>
                                                                              ${populateWOSubItem1('Category:', 'Category')}
                                                                           </table>
                                                                        </td>
                                                                     </tr>
                                                                  </table>
                                                               </td>
                                                               <td style="width:16px; min-width:16px;" width="16"></td>
                                                               <td width="123" align="center">
                                                                  <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                     <tr>
                                                                        <td>
                                                                           <img src=${invoice?.assetProfilePhoto ?? defaultEmptyImage} width="123" border="0" style="min-width:123px; width:123px; height: auto; display: block;">
                                                                        </td>
                                                                     </tr>
                                                                     <tr>
                                                                        <td height="16" style="height:16px; min-height:16px; line-height:16px;">
                                                                        </td>
                                                                     </tr>
                                                                  </table>
                                                               </td>
                                                            </tr>
                                                         </table>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td height="16" style="height:16px; min-height:16px; line-height:16px;"></td>
                                                   </tr>
                                                </table>
                                             </td>
                                          </tr>
                                       </table>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td width="100%">
                                       <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                          <tr>
                                             <td width="100%" style="height:458px; background-color:#ffffff;  " bgcolor="#ffffff">
                                                <table border="0" cellpadding="0" cellspacing="0">
                                                   <tr>
                                                      <td>
                                                         <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                            <tr>
                                                               <td width="100%">
                                                                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                     <tr>
                                                                        ${populateInvoiceSubItem('Trade', invoice?.request?.trade?.name || '-', 49.32)}
                                                                        <td style="width:4px; min-width:4px;" width="4"></td>
                                                                        ${populateInvoiceSubItem('Problem', invoice?.request?.problem?.name || '-', 49.32)}
                                                                     </tr>
                                                                  </table>
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               <td width="100%">
                                                                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                     <tr>
                                                                        ${populateInvoiceSubItem('Invoice Resolution', '1231333', 49.32)}
                                                                        <td style="width:4px; min-width:4px;" width="4"></td>
                                                                        ${populateInvoiceSubItem('On - Site Time', invoice?.onSiteTime || '-', 49.32)}
                                                                     </tr>
                                                                  </table>
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               <td width="100%">
                                                                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                     <tr>
                                                                        ${populateInvoiceSubItem('Phone Number', invoice?.request?.asset?.branch?.phone || '-', 79.32)}
                                                                        <td style="width:4px; min-width:4px;" width="4"></td>
                                                                        ${populateInvoiceSubItem('EXT', invoice?.request?.asset?.branch?.phoneExt || '-', 19.32)}
                                                                     </tr>
                                                                  </table>
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               ${populateInvoiceSubItem('Address', invoice?.address || '-')}
                                                            </tr>
                                                            <tr>
                                                               ${populateInvoiceSubItem('Work Order Submitted By', invoice?.createdByUser.email || '-')}
                                                            </tr>
                                                            <tr>
                                                               <td width="100%">
                                                                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                     <tr>
                                                                        ${populateInvoiceSubItem('Customer Code', invoice?.request?.customerCode || '-', 49.32)}
                                                                        <td style="width:4px; min-width:4px;" width="4"></td>
                                                                        ${populateInvoiceSubItem('Invoice Submitted By', invoice?.createdByUser?.email || '-', 49.32)}
                                                                     </tr>
                                                                  </table>
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               <td width="100%">
                                                                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                     <tr>
                                                                        ${populateInvoiceSubItem('Invoice Date', invoice?.invoiceDate || '-', 49.32)}
                                                                        <td style="width:4px; min-width:4px;" width="4"></td>
                                                                        ${populateInvoiceSubItem('Approval Date', invoice?.approvedDate || '-', 49.32)}
                                                                     </tr>
                                                                  </table>
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               <td width="100%">
                                                                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                     <tr>
                                                                        ${populateInvoiceSubItem('WO Completed Date', invoice?.request?.workorders?.[0]?.completedDate || '-', 49.32)}
                                                                        <td style="width:4px; min-width:4px;" width="4"></td>
                                                                        ${populateInvoiceSubItem('Warranty Used in Repair', invoice?.warrantyUsed || '-', 49.32)}
                                                                     </tr>
                                                                  </table>
                                                               </td>
                                                            </tr>
                                                            <tr>
                                                               ${populateInvoiceSubItem('Flags', invoice?.flags?.label || '-')}
                                                            </tr>
                                                            <tr>
                                                               ${populateInvoiceSubItem('Description', invoice?.description || '-')}
                                                            </tr>
                                                         </table>
                                                      </td>
                                                      <td style="width:8px; min-width:8px;" width="8"></td>
                                                      <td width="264">
                                                      <table cellspacing="0" cellpadding="0" border="0">
                                                         <tr>
                                                            <td width="264" style="height:252px; background-color:#ffffff;  " bgcolor="#ffffff">
                                                               <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                     <tr>
                                                                        <td width="100%">
                                                                           <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                 <tr>
                                                                                    <td width="100%">
                                                                                       <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                             <tr>
                                                                                                <td width="100%"
                                                                                                   style="vertical-align: middle; height:33px; border-radius:2.4000000953674316px 2.4000000953674316px 0px 0px; border:0.6000000238418579px solid #000000; padding-left:14.40000057220459px; padding-right:4.800000190734863px;">
                                                                                                   <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                                         <tr>
                                                                                                            <td height="2.4000000953674316"
                                                                                                               style="height:2.4000000953674316px; min-height:2.4000000953674316px; line-height:2.4000000953674316px;">
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                         <tr>
                                                                                                            <td style="vertical-align: middle;" width="100%">
                                                                                                               <table width="100%" cellspacing="0" cellpadding="0"
                                                                                                                  border="0">
                                                                                                                     <tr>
                                                                                                                        <td width="100%"
                                                                                                                           style="vertical-align: middle; height:28px;  ">
                                                                                                                           <table border="0" cellpadding="0"
                                                                                                                              cellspacing="0">
                                                                                                                                 <tr>
                                                                                                                                    <td style="vertical-align: middle;">
                                                                                                                                       <div style="text-align:left;"><span
                                                                                                                                             style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:8px;letter-spacing:0.09000000357627869px;line-height:143%;text-align:left;">Failure Types</span></div>
                                                                                                                                    </td>
                                                                                                                                 </tr>
                                                                                                                           </table>
                                                                                                                        </td>
                                                                                                                     </tr>
                                                                                                               </table>
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                         <tr>
                                                                                                            <td height="2.4000000953674316"
                                                                                                               style="height:2.4000000953674316px; min-height:2.4000000953674316px; line-height:2.4000000953674316px;">
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                   </table>
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td width="100%"
                                                                                                   style="border-radius:0px 0px 2.4000000953674316px 2.4000000953674316px; border:1px solid #000000; padding-left:4.800000190734863px; padding-right:4.800000190734863px;">
                                                                                                   <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                                         <tr>
                                                                                                            <td height="9.600000381469727"
                                                                                                               style="height:9.600000381469727px; min-height:9.600000381469727px; line-height:9.600000381469727px;">
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                         <tr>
                                                                                                            <td width="100%" style="  padding-left:9.600000381469727px;">
                                                                                                               <table width="100%" border="0" cellpadding="0"
                                                                                                                  cellspacing="0">
                                                                                                                  ${
                                                                                                                    invoice
                                                                                                                      ?.warrantyComponents
                                                                                                                      ?.length >
                                                                                                                    0
                                                                                                                      ? invoice.warrantyComponents
                                                                                                                          .map(
                                                                                                                            warranty => {
                                                                                                                              return `<tr>
                                                                                                                     <td height="4.800000190734863"
                                                                                                                           style="height:4.800000190734863px; min-height:4.800000190734863px; line-height:4.800000190734863px;">
                                                                                                                        </td>
                                                                                                                     </tr>
                                                                                                                     <tr>
                                                                                                                        <td>
                                                                                                                           <table cellspacing="0" cellpadding="0"
                                                                                                                              border="0">
                                                                                                                                 <tr>
                                                                                                                                    <td>
                                                                                                                                       <div style="text-align:right;"><span
                                                                                                                                             style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:8px;letter-spacing:0.09000000357627869px;line-height:143%;text-align:left;">${warranty?.repair?.name}</span>
                                                                                                                                       </div>
                                                                                                                                    </td>
                                                                                                                                 </tr>
                                                                                                                           </table>
                                                                                                                        </td>
                                                                                                                     </tr>
                                                                                                                     <tr>
                                                                                                                        <td height="4.800000190734863"
                                                                                                                           style="height:4.800000190734863px; min-height:4.800000190734863px; line-height:4.800000190734863px;">
                                                                                                                        </td>
                                                                                                                     </tr>
                                                                                                                     
                                                                                                                     <tr>
                                                                                                                        <td width="100%" style="  padding-right:6px;">
                                                                                                                           <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                                                              <tr>
                                                                                                                                 <td>
                                                                                                                                    <div style="text-align:left;"><span
                                                                                                                                       style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:10px;letter-spacing:0.09000000357627869px;line-height:150%;text-align:left;">Warranty Periods: ${warranty?.duration || '-'} ${warranty?.period?.description || '-'}</span>
                                                                                                                                    </div>
                                                                                                                                 </td>
                                                                                                                                 <td>
                                                                                                                                    <div style="text-align:left;"><span
                                                                                                                                       style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:10px;letter-spacing:0.09000000357627869px;line-height:150%;text-align:left;">Resolution: ${warranty?.resolution?.name || '-'}</span></div>
                                                                                                                                 </td>
                                                                                                                              </tr>
                                                                                                                           </table>
                                                                                                                        </td>
                                                                                                                     </tr>`;
                                                                                                                            },
                                                                                                                          )
                                                                                                                          .join(
                                                                                                                            '',
                                                                                                                          )
                                                                                                                      : ''
                                                                                                                  }
                                                                                                               </table>
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                         <tr>
                                                                                                            <td height="9.600000381469727"
                                                                                                               style="height:9.600000381469727px; min-height:9.600000381469727px; line-height:9.600000381469727px;">
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                   </table>
                                                                                                </td>
                                                                                             </tr>
                                                                                       </table>
                                                                                    </td>
                                                                                 </tr>
                                                                              
                                                                           </table>
                                                                        </td>
                                                                     </tr>
                                                                     <tr>
                                                                        <td height="8" style="height:8px; min-height:8px; line-height:8px;"></td>
                                                                     </tr>
                                                                     <tr>
                                                                        <td width="100%">
                                                                           <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                 <tr>
                                                                                    <td width="100%"
                                                                                       style="vertical-align: middle; height:33px; border-radius:2.4000000953674316px 2.4000000953674316px 0px 0px; border:0.6000000238418579px solid #000000; padding-left:14.40000057220459px; padding-right:4.800000190734863px;">
                                                                                       <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                             <tr>
                                                                                                <td height="2.4000000953674316"
                                                                                                   style="height:2.4000000953674316px; min-height:2.4000000953674316px; line-height:2.4000000953674316px;">
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td style="vertical-align: middle;" width="100%">
                                                                                                   <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                                         <tr>
                                                                                                            <td width="100%"
                                                                                                               style="vertical-align: middle; height:28px;  ">
                                                                                                               <table width="100%" border="0" cellpadding="0"
                                                                                                                  cellspacing="0">
                                                                                                                     <tr>
                                                                                                                        <td style="vertical-align: middle;">
                                                                                                                           <div style="text-align:left;"><span
                                                                                                                                 style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:8px;letter-spacing:0.09000000357627869px;line-height:143%;text-align:left;">Total
                                                                                                                              </span></div>
                                                                                                                        </td>
                                                                                                                        <td>&nbsp;</td>
                                                                                                                        <td style="vertical-align: middle;">
                                                                                                                           <div style="text-align:right;"><span
                                                                                                                                 style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:14px;letter-spacing:0.09000000357627869px;line-height:133%;text-align:left;">${currencyFormatter.format(invoice?.totalWithDiscount) || '-'}</span></div>
                                                                                                                        </td>
                                                                                                                     </tr>
                                                                                                               </table>
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                   </table>
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td height="2.4000000953674316"
                                                                                                   style="height:2.4000000953674316px; min-height:2.4000000953674316px; line-height:2.4000000953674316px;">
                                                                                                </td>
                                                                                             </tr>
                                                                                       </table>
                                                                                    </td>
                                                                                 </tr>
                                                                                 <tr>
                                                                                    <td width="100%"
                                                                                       style="vertical-align: middle; height:20px;  border:0.6000000238418579px solid #000000; padding-left:14.40000057220459px; padding-right:4.800000190734863px;">
                                                                                       <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                             <tr>
                                                                                                <td height="2.4000000953674316"
                                                                                                   style="height:2.4000000953674316px; min-height:2.4000000953674316px; line-height:2.4000000953674316px;">
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td style="vertical-align: middle;" width="100%">
                                                                                                   <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                                         <tr>
                                                                                                            <td width="100%"
                                                                                                               style="vertical-align: middle; height:15px;  ">
                                                                                                               <table width="100%" border="0" cellpadding="0"
                                                                                                                  cellspacing="0">
                                                                                                                     <tr>
                                                                                                                        <td style="vertical-align: middle;">
                                                                                                                           <div style="text-align:left;"><span
                                                                                                                                 style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:8px;letter-spacing:0.09000000357627869px;line-height:143%;text-align:left;">NTE</span>
                                                                                                                           </div>
                                                                                                                        </td>
                                                                                                                        <td>&nbsp;</td>
                                                                                                                        <td style="vertical-align: middle;">
                                                                                                                           <div style="text-align:right;"><span
                                                                                                                                 style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:10px;letter-spacing:0.09000000357627869px;line-height:150%;text-align:left;">
                                                                                                                                 ${currencyFormatter.format(invoice?.request?.workorders[0]?.nte) || '-'}</span></div>
                                                                                                                        </td>
                                                                                                                     </tr>
                                                                                                               </table>
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                   </table>
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td height="2.4000000953674316"
                                                                                                   style="height:2.4000000953674316px; min-height:2.4000000953674316px; line-height:2.4000000953674316px;">
                                                                                                </td>
                                                                                             </tr>
                                                                                       </table>
                                                                                    </td>
                                                                                 </tr>
                                                                                 <tr>
                                                                                    <td width="100%"
                                                                                       style="border-radius:0px 0px 2.4000000953674316px 2.4000000953674316px; border:1px solid #e0e0e0; padding-left:4.800000190734863px; padding-right:4.800000190734863px;">
                                                                                       <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                             <tr>
                                                                                                <td height="9.600000381469727"
                                                                                                   style="height:9.600000381469727px; min-height:9.600000381469727px; line-height:9.600000381469727px;">
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td width="100%"
                                                                                                   style="vertical-align: middle;   padding-left:9.600000381469727px;">
                                                                                                   <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                                         <tr>
                                                                                                            <td style="vertical-align: middle;">
                                                                                                               <div style="text-align:left;"><span
                                                                                                                     style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:8px;letter-spacing:0.09000000357627869px;line-height:143%;text-align:left;">Labor</span>
                                                                                                               </div>
                                                                                                            </td>
                                                                                                            <td>&nbsp;</td>
                                                                                                            <td style="vertical-align: middle;">
                                                                                                               <div style="text-align:right;"><span
                                                                                                                     style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:10px;letter-spacing:0.09000000357627869px;line-height:150%;text-align:left;">
                                                                                                                     ${currencyFormatter.format(invoice?.costLabor)}</span></div>
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                   </table>
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td height="4.800000190734863"
                                                                                                   style="height:4.800000190734863px; min-height:4.800000190734863px; line-height:4.800000190734863px;">
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td width="100%"
                                                                                                   style="vertical-align: middle;   padding-left:9.600000381469727px;">
                                                                                                   <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                                         <tr>
                                                                                                            <td style="vertical-align: middle;">
                                                                                                               <div style="text-align:left;"><span
                                                                                                                     style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:8px;letter-spacing:0.09000000357627869px;line-height:143%;text-align:left;">Material</span>
                                                                                                               </div>
                                                                                                            </td>
                                                                                                            <td>&nbsp;</td>
                                                                                                            <td style="vertical-align: middle;">
                                                                                                               <div style="text-align:right;"><span
                                                                                                                     style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:10px;letter-spacing:0.09000000357627869px;line-height:150%;text-align:left;">
                                                                                                                     ${currencyFormatter.format(invoice?.costMaterial)}</span></div>
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                   </table>
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td height="4.800000190734863"
                                                                                                   style="height:4.800000190734863px; min-height:4.800000190734863px; line-height:4.800000190734863px;">
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td width="100%"
                                                                                                   style="vertical-align: middle;   padding-left:9.600000381469727px;">
                                                                                                   <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                                         <tr>
                                                                                                            <td style="vertical-align: middle;">
                                                                                                               <div style="text-align:left;"><span
                                                                                                                     style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:8px;letter-spacing:0.09000000357627869px;line-height:143%;text-align:left;">Freight</span>
                                                                                                               </div>
                                                                                                            </td>
                                                                                                            <td>&nbsp;</td>
                                                                                                            <td style="vertical-align: middle;">
                                                                                                               <div style="text-align:right;"><span
                                                                                                                     style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:10px;letter-spacing:0.09000000357627869px;line-height:150%;text-align:left;">
                                                                                                                     ${currencyFormatter.format(invoice?.costFreight) || '-'}</span></div>
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                   </table>
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td height="4.800000190734863"
                                                                                                   style="height:4.800000190734863px; min-height:4.800000190734863px; line-height:4.800000190734863px;">
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td width="100%"
                                                                                                   style="vertical-align: middle;   padding-left:9.600000381469727px;">
                                                                                                   <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                                         <tr>
                                                                                                            <td style="vertical-align: middle;">
                                                                                                               <div style="text-align:left;"><span
                                                                                                                     style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:8px;letter-spacing:0.09000000357627869px;line-height:143%;text-align:left;">Tax</span>
                                                                                                               </div>
                                                                                                            </td>
                                                                                                            <td>&nbsp;</td>
                                                                                                            <td style="vertical-align: middle;">
                                                                                                               <div style="text-align:right;"><span
                                                                                                                     style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:10px;letter-spacing:0.09000000357627869px;line-height:150%;text-align:left;">
                                                                                                                     ${currencyFormatter.format(invoice?.costTax) || '-'}</span></div>
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                   </table>
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td height="4.800000190734863"
                                                                                                   style="height:4.800000190734863px; min-height:4.800000190734863px; line-height:4.800000190734863px;">
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td width="100%"
                                                                                                   style="vertical-align: middle;   padding-left:9.600000381469727px;">
                                                                                                   <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                                                                         <tr>
                                                                                                            <td style="vertical-align: middle;">
                                                                                                               <div style="text-align:left;"><span
                                                                                                                     style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:8px;letter-spacing:0.09000000357627869px;line-height:143%;text-align:left;">Other</span>
                                                                                                               </div>
                                                                                                            </td>
                                                                                                            <td>&nbsp;</td>
                                                                                                            <td style="vertical-align: middle;">
                                                                                                               <div style="text-align:right;"><span
                                                                                                                     style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:10px;letter-spacing:0.09000000357627869px;line-height:150%;text-align:left;">
                                                                                                                     ${currencyFormatter.format(invoice?.costOther) || '-'}</span></div>
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                   </table>
                                                                                                </td>
                                                                                             </tr>
                                                                                             <tr>
                                                                                                <td height="9.600000381469727"
                                                                                                   style="height:9.600000381469727px; min-height:9.600000381469727px; line-height:9.600000381469727px;">
                                                                                                </td>
                                                                                             </tr>
                                                                                       </table>
                                                                                    </td>
                                                                                 </tr>
                                                                           </table>
                                                                        </td>
                                                                     </tr>
                                                                     <tr>
                                                                        <td height="8" style="height:8px; min-height:8px; line-height:8px;"></td>
                                                                     </tr>
                                                                     ${isEnabledInventory ? generateInventoryHTMLContent(itemsInventory) : ''}
                                                               </table>
                                                            </td>
                                                         </tr>
                                                   </table>      
                                                      </td>
                                                   </tr>
                                                </table>
                                             </td>
                                          </tr>
                                       </table>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td height="24" style="height:24px; min-height:24px; line-height:24px;"></td>
                                 </tr>
                              </table>
                           </td>
                        </tr>
                     </table>
                  </td>
               </tr>
            </table>
            ${includeComments && comments?.length > 0 ? generateCommentsHTMLContent(comments, 'Invoice', invoice?.id) : ''}
         </div>   
      </body >
   </html >    
    `;
  return htmlContent;
};

const generateInventoryHTMLContent = itemsInventory => {
  const totalSum =
    itemsInventory?.reduce((acc, item) => {
      if (item.total) {
        acc += Number(parseFloat(item.total.toString()).toFixed(2));
      }

      return acc;
    }, 0) || 0;
  const htmlContent = `
   <tr>
      <td width="100%" style="height:161px;  ">
         <table width="100%" border="0" cellpadding="0" cellspacing="0">
                  <tr>
                     <td width="100%">
                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                 <tr>
                                    <td width="100%"
                                          style="vertical-align: middle; height:28px; border-radius:2.051715850830078px 2.051715850830078px 0px 0px; border:0.5129289627075195px solid #000000; padding-left:12.310295104980469px; padding-right:4.103431701660156px;">
                                          <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                <tr>
                                                      <td height="2.051715850830078"
                                                         style="height:2.051715850830078px; min-height:2.051715850830078px; line-height:2.051715850830078px;">
                                                      </td>
                                                </tr>
                                                <tr>
                                                      <td style="vertical-align: middle;" width="100%">
                                                         <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                  <tr>
                                                                     <td width="100%"
                                                                        style="vertical-align: middle; height:24px;  ">
                                                                        <table width="100%" border="0" cellpadding="0"
                                                                              cellspacing="0">
                                                                                 <tr>
                                                                                    <td style="vertical-align: middle;">
                                                                                          <div style="text-align:left;">
                                                                                             <span
                                                                                                style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:7px;letter-spacing:0.07693934440612793px;line-height:143%;text-align:left;">Subtotal
                                                                                                for Inventory</span>
                                                                                          </div>
                                                                                    </td>
                                                                                    <td>&nbsp;</td>
                                                                                    <td style="vertical-align: middle;">
                                                                                          <div style="text-align:right;">
                                                                                             <span
                                                                                                style="color:#000000;font-weight:500;font-family:Roboto,Arial,sans-serif;font-size:10px;letter-spacing:0.07693934440612793px;line-height:160%;text-align:left;">
                                                                                                ${currencyFormatter.format(totalSum)}</span>
                                                                                          </div>
                                                                                    </td>
                                                                                 </tr>
                                                                        </table>
                                                                     </td>
                                                                  </tr>
                                                         </table>
                                                      </td>
                                                </tr>
                                                <tr>
                                                      <td height="2.051715850830078"
                                                         style="height:2.051715850830078px; min-height:2.051715850830078px; line-height:2.051715850830078px;">
                                                      </td>
                                                </tr>
                                          </table>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td width="100%"
                                          style="border-radius:0px 0px 2.051715850830078px 2.051715850830078px; border:0.5129289627075195px solid #000000; padding-left:8.206863403320312px; padding-right:8.206863403320312px;">
                                          <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                <tr>
                                                      <td height="8.206863403320312"
                                                         style="height:8.206863403320312px; min-height:8.206863403320312px; line-height:8.206863403320312px;">
                                                      </td>
                                                </tr>
                                                <tr>
                                                      <td width="100%" style="vertical-align: middle;  ">
                                                         <table border="0" cellpadding="0" cellspacing="0">
                                                                  <tr>
                                                                     <td style="vertical-align: middle;">
                                                                        <table cellspacing="0" cellpadding="0"
                                                                              border="0">
                                                                                 <tr>
                                                                                    <td>
                                                                                          <table width="100%" border="0"
                                                                                             cellpadding="0"
                                                                                             cellspacing="0">
                                                                                                <tr>
                                                                                                      <td>
                                                                                                         <div
                                                                                                            style="text-align:left;">
                                                                                                            <span
                                                                                                                  style="color:#000000;font-weight:500;font-family:Roboto,Arial,sans-serif;font-size:8px;letter-spacing:0.07693934440612793px;line-height:150%;text-align:left;">Allocated
                                                                                                                  Inventory</span>
                                                                                                         </div>
                                                                                                      </td>
                                                                                                </tr>
                                                                                          </table>
                                                                                    </td>
                                                                                 </tr>
                                                                        </table>
                                                                     </td>
                                                                  </tr>
                                                         </table>
                                                      </td>
                                                </tr>
                                                <tr>
                                                      <td height="4.103431701660156"
                                                         style="height:4.103431701660156px; min-height:4.103431701660156px; line-height:4.103431701660156px;">
                                                      </td>
                                                </tr>
                                                ${itemsInventory
                                                  .map(item => {
                                                    return `<tr>
                                                   <td width="100%" style="vertical-align: middle;  ">
                                                      <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                               <tr>
                                                                  <td style="vertical-align: middle;" width="60">
                                                                     <table cellspacing="0" cellpadding="0"
                                                                           border="0">
                                                                              <tr>
                                                                                 <td width="60">
                                                                                       <table width="100%" border="0"
                                                                                          cellpadding="0"
                                                                                          cellspacing="0">
                                                                                             <tr>
                                                                                                   <td>
                                                                                                      <div style="text-align:left;">
                                                                                                         <span style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:7px;letter-spacing:0.07693934440612793px;line-height:143%;text-align:left;">${item.name}</span>
                                                                                                         <span style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:7px;letter-spacing:0.07693934440612793px;line-height:143%;text-align:left;"></span>
                                                                                                         <span style="color:#000000;font-weight:700;font-family:Roboto,Arial,sans-serif;font-size:7px;letter-spacing:0.07693934440612793px;line-height:143%;text-align:left;">(${item.count})</span>
                                                                                                      </div>
                                                                                                   </td>
                                                                                             </tr>
                                                                                       </table>
                                                                                 </td>
                                                                              </tr>
                                                                     </table>
                                                                  </td>
                                                                  <td>&nbsp;</td>
                                                                  <td style="vertical-align: middle;">
                                                                     <div style="text-align:right;"><span
                                                                              style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:8px;letter-spacing:0.07693934440612793px;line-height:150%;text-align:left;">
                                                                              ${currencyFormatter.format(item?.totalWithDiscount)}</span></div>
                                                                  </td>
                                                               </tr>
                                                      </table>
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td height="8.206863403320312"
                                                      style="height:8.206863403320312px; min-height:8.206863403320312px; line-height:8.206863403320312px;">
                                                   </td>
                                                </tr>`;
                                                  })
                                                  .join('')}
                                          </table>
                                    </td>
                                 </tr>
                        </table>
                     </td>
                  </tr>
                  <tr>
                     <td height="4.103431701660156"
                        style="height:4.103431701660156px; min-height:4.103431701660156px; line-height:4.103431701660156px;">
                     </td>
                  </tr>
                  <tr style="display: none;">
                     <td width="100%" style=" border:0.5129289627075195px solid #000000;">
                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                 <tr>
                                    <td width="100%"
                                          style="vertical-align: middle; height:16px; border-radius:2.051715850830078px 2.051715850830078px 0px 0px;  padding-left:4.103431701660156px; padding-right:4.103431701660156px;">
                                          <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                <tr>
                                                      <td height="8.206863403320312"
                                                         style="height:8.206863403320312px; min-height:8.206863403320312px; line-height:8.206863403320312px;">
                                                      </td>
                                                </tr>
                                                <tr>
                                                      <td style="vertical-align: middle;" width="100%">
                                                         <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                  <tr>
                                                                     <td width="100%"
                                                                        style="vertical-align: middle; height:1px;  ">
                                                                        <table width="100%" border="0" cellpadding="0"
                                                                              cellspacing="0">
                                                                                 <tr>
                                                                                    <td style="vertical-align: middle;">
                                                                                          <div style="text-align:left;">
                                                                                             <span
                                                                                                style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:7px;letter-spacing:0.07693934440612793px;line-height:143%;text-align:left;">This
                                                                                                Period Budget </span>
                                                                                          </div>
                                                                                    </td>
                                                                                    <td>&nbsp;</td>
                                                                                    <td style="vertical-align: middle;">
                                                                                          <div style="text-align:right;">
                                                                                             <span
                                                                                                style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:8px;letter-spacing:0.07693934440612793px;line-height:150%;text-align:left;">
                                                                                                $ {{budget}}</span>
                                                                                          </div>
                                                                                    </td>
                                                                                 </tr>
                                                                        </table>
                                                                     </td>
                                                                  </tr>
                                                         </table>
                                                      </td>
                                                </tr>
                                                <tr>
                                                      <td height="8.206863403320312"
                                                         style="height:8.206863403320312px; min-height:8.206863403320312px; line-height:8.206863403320312px;">
                                                      </td>
                                                </tr>
                                          </table>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td width="100%"
                                          style="vertical-align: middle; height:28px; border-radius:0px 0px 2.051715850830078px 2.051715850830078px;  padding-left:4.103431701660156px; padding-right:4.103431701660156px;">
                                          <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                                <tr>
                                                      <td height="8.206863403320312"
                                                         style="height:8.206863403320312px; min-height:8.206863403320312px; line-height:8.206863403320312px;">
                                                      </td>
                                                </tr>
                                                <tr>
                                                      <td style="vertical-align: middle;" width="100%">
                                                         <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                  <tr>
                                                                     <td width="100%"
                                                                        style="vertical-align: middle; height:12px;  ">
                                                                        <table width="100%" border="0" cellpadding="0"
                                                                              cellspacing="0">
                                                                                 <tr>
                                                                                    <td style="vertical-align: middle;">
                                                                                          <div style="text-align:left;">
                                                                                             <span
                                                                                                style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:7px;letter-spacing:0.07693934440612793px;line-height:143%;text-align:left;">Budget
                                                                                                Remaining</span></div>
                                                                                    </td>
                                                                                    <td>&nbsp;</td>
                                                                                    <td style="vertical-align: middle;">
                                                                                          <div style="text-align:right;">
                                                                                             <span
                                                                                                style="color:#000000;font-family:Roboto,Arial,sans-serif;font-size:12px;letter-spacing:0.07693934440612793px;line-height:133%;text-align:left;">
                                                                                                $
                                                                                                {{budgetRemaining}}</span>
                                                                                          </div>
                                                                                    </td>
                                                                                 </tr>
                                                                        </table>
                                                                     </td>
                                                                  </tr>
                                                         </table>
                                                      </td>
                                                </tr>
                                                <tr>
                                                      <td height="8.206863403320312"
                                                         style="height:8.206863403320312px; min-height:8.206863403320312px; line-height:8.206863403320312px;">
                                                      </td>
                                                </tr>
                                          </table>
                                    </td>
                                 </tr>
                        </table>
                     </td>
                  </tr>
         </table>
      </td>
   </tr>
   `;
  return htmlContent;
};
