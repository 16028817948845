import { useCallback, useEffect, useMemo, useState } from 'react';
import { ECTableLayout } from './ECTableLayout';
import { ECTableContent } from './ECTableContent';
import {
  ECTableTemplateProps,
  THandlePaginationChange,
} from 'types/core/ECTable';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { ConfigPagination, ListConfig } from 'types/core/Pagination';
import { Sorting } from 'utils/ECEasyTableUtils';
import { useGetAllSavedColumnsFiltersQuery } from 'services/filterApi';
import { populateQueryParamsFromFilters } from 'utils/core/table';
import { useDispatch } from 'react-redux';
import { setPageContext } from 'store/slice/page';
import { useSearchParams } from 'react-router-dom';
import { ECBox } from 'app/components/ECBox';

export const ECTableTemplate: React.FC<ECTableTemplateProps> = ({
  useLazyGetListQuery,
  config,
  additionalChildren,
  hidePagination,
  hidePerPageSelector,
  isSendingRequest,
  onRowPress,
  onDuplicatePress,
  headerChildren,
  isEditable = false,
  headerBackgroundColor,
  onChange,
  onRemove,
  isDraggable,
  onDragEnd,
  multiSelectTable,
  showSelectAllPageRows = true,
  emptyType,
  onMultiTableSelect,
  multiSelectedRows,
  allChecked,
  disableTableContent,
  hideSearchHeadContent,
  isDraggableColumns,
  selectRowsMaxCount,
  shouldNotUseActiveFilter,
  sx,
  customFieldsData,
  customFieldsDataEdit,
  preSelectRowFilter,
  afterPaginationChildren,
  beforePaginationChildren,
  belowPaginationChildren,
  shouldCacheLazyQuery = true,
}) => {
  const dispatch = useDispatch();

  /**
   * All the page state and filters stored on redux
   */
  const pageState = useSelector((state: RootState) => state.page);
  const [URLSearchParams, setURLSearchParams] = useSearchParams();

  const endpoint = useMemo(() => {
    return pageState?.filter?.endpoint;
  }, [pageState]);

  const activeFilter = useMemo(() => {
    return pageState?.filter?.activeFilter?.[endpoint];
  }, [pageState]);

  const pageContext = useMemo(() => {
    return pageState?.context?.[window.location.pathname];
  }, [pageState]);

  const [pagination, setPagination] = useState(
    config?.pagination ||
      ({
        isPaginated: true,
        totalResults: -1,
        currentPage: Number(URLSearchParams.get('page') || 0),
        perPage: Number(URLSearchParams.get('perPage') || 50),
        page: Number(URLSearchParams.get('page') || 0),
      } as ConfigPagination),
  );

  const [curRequestedPage, setCurRequestedPage] = useState<number>(
    Number(URLSearchParams.get('page') || 0),
  );

  const [
    trigger,
    { data, isLoading, isSuccess, isUninitialized, isFetching, originalArgs },
  ] = useLazyGetListQuery({});

  /**
   * UseEffect so make the request and trigger
   * on pagination, filters, and sort changes
   */
  useEffect(() => {
    const queryParams: Record<string, any> = {
      p: curRequestedPage === pagination.page ? 0 : pagination.page,
      t: pagination.perPage,
      o: pagination.order === 'ASC' ? Sorting.ASC : Sorting.DESC,
      ob: pagination.orderBy,
    };
    setCurRequestedPage(queryParams.p);
    setURLSearchParams(prev => {
      if (prev.get('page')) {
        prev.set('page', String(queryParams.p));
      }
      return prev;
    });

    dispatch(
      setPageContext({
        endpoint: endpoint,
        isLoadingRequest: true,
      }),
    );

    populateQueryParamsFromFilters(activeFilter, pageContext, queryParams);

    trigger(
      {
        ...queryParams,
      },
      shouldCacheLazyQuery,
    );
  }, [pagination, activeFilter]);

  /**
   * UseEffect to sync the page context with the data
   */
  useEffect(() => {
    if (isSuccess && data) {
      dispatch(
        setPageContext({
          isLoadingRequest: false,
          endpoint: endpoint,
          columns: data?.config?.fields ?? [],
        }),
      );
    }
  }, [isSuccess, data]);

  /**
   * useMemo to sync data to other components
   * It's separated from the useEffect to avoid
   * unnecessary re-renders
   */
  const updatedPagination: ConfigPagination = useMemo(() => {
    return {
      isPaginated: true,
      totalResults: data?.config?.pagination?.totalCount ?? -1,
      currentPage: data?.config?.pagination?.page ?? pagination.currentPage,
      perPage: data?.config?.pagination?.perPage ?? pagination.perPage,
      page: data?.config?.pagination?.page ?? pagination.currentPage,
    };
  }, [pagination, data]);

  const isEmptyState = useMemo(
    () =>
      isSuccess &&
      !data?.data?.length &&
      (!originalArgs ||
        originalArgs?.p === 0 ||
        originalArgs?.queryParams?.p === 0),
    [isSuccess, data, originalArgs],
  );

  const configState = useMemo(() => {
    return config ?? (data?.config as ListConfig);
  }, [data]);

  const handlePaginationChange = useCallback(
    ({ page, perPage }: THandlePaginationChange) => {
      setPagination(prev => ({
        ...prev,
        page: page ?? prev.page,
        perPage: perPage ?? prev.perPage,
      }));
      setURLSearchParams(prev => {
        prev.set('page', String(page ?? prev.get('page') ?? 0));
        prev.set('perPage', String(perPage ?? prev.get('perPage') ?? 50));
        return prev;
      });
    },
    [],
  );

  const { data: allSavedColumnsFilters } = useGetAllSavedColumnsFiltersQuery(
    endpoint,
    {
      skip: !endpoint || shouldNotUseActiveFilter,
    },
  );

  const tableLayoutFilter = useMemo(
    () => allSavedColumnsFilters?.[0],
    [allSavedColumnsFilters],
  );

  return (
    <ECBox sx={{ maxWidth: '100%', paddingX: 2, paddingY: 0, ...sx }}>
      <ECTableLayout
        hidePagination={hidePagination}
        hidePerPageSelector={hidePerPageSelector}
        hideHeaderContent={hideSearchHeadContent}
        disableTableContent={disableTableContent}
        isSendingRequest={isSendingRequest}
        isLoading={isUninitialized || isLoading || !configState}
        isFetching={isFetching}
        isEmptyState={isEmptyState}
        pagination={updatedPagination}
        onPaginationChange={handlePaginationChange}
        headerChildren={headerChildren}
        beforePaginationChildren={beforePaginationChildren}
        afterPaginationChildren={afterPaginationChildren}
        belowPaginationChildren={belowPaginationChildren}
      >
        <ECTableContent
          config={configState}
          data={data?.data ?? []}
          additionalChildren={additionalChildren}
          isEmptyState={isEmptyState}
          shouldNotUseActiveFilter={shouldNotUseActiveFilter}
          onChange={onChange}
          onDragEnd={onDragEnd}
          onDuplicatePress={onDuplicatePress}
          onRemove={onRemove}
          onRowPress={onRowPress}
          onMultiTableSelect={onMultiTableSelect}
          isDraggable={isDraggable}
          preSelectRowFilter={preSelectRowFilter}
          isDraggableColumns={isDraggableColumns}
          isEditable={isEditable}
          multiSelectTable={multiSelectTable}
          showSelectAllPageRows={showSelectAllPageRows}
          emptyType={emptyType}
          allChecked={allChecked}
          headerBackgroundColor={headerBackgroundColor}
          selectRowsMaxCount={selectRowsMaxCount}
          multiSelectedRows={multiSelectedRows}
          customFieldsData={customFieldsData}
          customEditFieldsData={customFieldsDataEdit}
          tableLayoutFilter={tableLayoutFilter}
          sort={pagination}
          setSort={setPagination}
        />
      </ECTableLayout>
    </ECBox>
  );
};
