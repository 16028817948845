import { QueryParams } from './QueryParams';

export interface ExportReportParams {
  jobName?:
    | 'jobtitle'
    | 'proposal'
    | 'invoice'
    | 'workorder'
    | 'branch'
    | 'asset'
    | 'serviceprovider'
    | 'customer'
    | 'tradeassignmentbytrade'
    | 'tradeassignmentbybranch'
    | 'assetnameassetgroup'
    | 'users'
    | 'assettradeproblem'
    | 'locationassignment'
    | 'assetrepair'
    | 'trade'
    | 'problem'
    | 'failure'
    | 'inventorystorage';
  exportModule?:
    | 'JOBTITLE'
    | 'PROPOSAL'
    | 'INVOICE'
    | 'WORKORDER'
    | 'BRANCH'
    | 'ASSET'
    | 'SERVICEPROVIDER'
    | 'CUSTOMER'
    | 'TRADEASSIGNMENTBYTRADE'
    | 'TRADEASSIGNMENTBYBRANCH'
    | 'ASSETNAMEASSETGROUP'
    | 'USERS'
    | 'ASSETTRADEPROBLEM'
    | 'LOCATIONASSIGNMENT'
    | 'ASSETREPAIR'
    | 'TRADE'
    | 'PROBLEM'
    | 'FAILURE'
    | 'INVENTORYSTORAGE';
  queryParams?: QueryParams;
  tradeId?: number;
  branchId?: number;
  userId?: number;
}

export enum DefaultExportOrderBy {
  JOBTITLE = 'jbttle.id',
  PROPOSAL = 'prpsl.id',
  INVOICE = 'invce.id',
  WORKORDER = 'wrkordr.id',
  BRANCH = 'brnch.id',
  ASSET = 'asst.id',
  SERVICEPROVIDER = 'cmpny.id',
  CUSTOMER = 'cmpny.id',
  TRADEASSIGNMENTBYTRADE = 'brnch.name',
  TRADEASSIGNMENTBYBRANCH = 'trd.name',
  ASSETNAMEASSETGROUP = '', // no default order by
  USERS = 'usr.id',
}
