import {
  ServiceProdiverDirectorySearchParams,
  ServiceProdiverDirectoryTradeAreasSearchParams,
  ServiceProviderQueryParams,
  ServiceProviderTradeArea,
  ServiceProviderDirectoryTypes,
  ServiceProviderTypes,
  ServiceProviderBase,
  ServiceProviderDetails,
  ServiceProviderInvitation,
  ServiceProviderDetailsUpdate,
  ServiceProviderRatesAndTerms,
  SPRateAndTermsUpdateDTO,
  TradeRegion,
  TradeRegionCreateDTO,
  InvitationStatus,
  ServiceProviderInvitationCreate,
} from 'types/ServiceProviderTypes';
import * as _ from 'lodash';
import { BaseType } from 'types/BaseType';
import { QueryParams } from 'types/QueryParams';
import { concatAddress } from 'utils/strings/concat-address';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'ServiceProvider',
    'ServiceProviderById',
    'ServiceProviderRatesAndTerms',
    'ServiceProviderCustomerById',
    'AvailableSPsForWorkorder',
  ],
});

export const serviceProviderApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    // GRID VIEW
    getCompanyServiceProvidersList: builder.query<
      BaseType<ServiceProviderBase[]>,
      QueryParams
    >({
      query: params => {
        const { o, ob, ...restParams } = params;

        const defaultParams = {
          ...restParams,
          o: o !== undefined && o !== '' ? o : 'a',
          ob: ob !== undefined && ob !== '' ? ob : 'cmpny.id',
        };

        return {
          url: 'serviceprovider/company',
          params: defaultParams,
        };
      },
      providesTags: ['ServiceProvider'],
    }),

    getCompanyServiceProvidersDropdownList: builder.query<
      BaseType<ServiceProviderBase[]>,
      QueryParams
    >({
      query: params => {
        return {
          url: 'serviceprovider/company/dropdown',
          params,
        };
      },
      providesTags: ['ServiceProvider'],
    }),

    // GET BY ID
    getServiceProviderById: builder.query<ServiceProviderDetails, number>({
      query: id => {
        if (!id) {
          return {
            url: '',
          };
        }

        return {
          url: `serviceprovider/${id}`,
        };
      },
      transformResponse: (response: ServiceProviderDetails) => {
        response.serviceProvider.flags = response.flags;
        response.serviceProvider.status = response.status;
        response.serviceProvider.vendorId = response.vendorId;
        if (response?.serviceProvider?.contacts?.length > 0) {
          response.serviceProvider.contact =
            response.serviceProvider?.contacts[0];
        }
        response.spType =
          response?.internalTech === 1
            ? 'Internal Tech'
            : response?.serviceProvider?.spProfile?.internal === 1
              ? 'Internally Managed'
              : 'Third-party';
        return response;
      },
      providesTags: ['ServiceProviderById'],
    }),

    getServiceProviderConfigurationById: builder.query<any, number>({
      query: id => {
        if (!id) {
          return {
            url: '',
          };
        }

        return {
          url: `serviceprovider/${id}/configurations`,
        };
      },
      providesTags: ['ServiceProviderById'],
    }),

    getServiceProvidersList: builder.query<ServiceProviderBase[], QueryParams>({
      query: params => {
        return {
          url: 'serviceprovider',
        };
      },
    }),

    updateServiceProviderById: builder.mutation<
      void,
      ServiceProviderDetailsUpdate
    >({
      query: ({ id, ...body }) => {
        if (!id) {
          return {
            url: '',
          };
        }

        return {
          url: `serviceprovider/profile/${id}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['ServiceProviderById', 'ServiceProvider'],
    }),

    // CUSTOMERS

    getServiceProviderCustomerList: builder.query<BaseType<any>, QueryParams>({
      query: params => {
        return {
          url: 'serviceprovider/customers',
          params,
        };
      },
      transformResponse: (response: BaseType<ServiceProviderBase[]>) => {
        const dataWithModuleName = response.data.map(row => ({
          ...row,
          moduleName: 'customer',
          name: row?.company?.name,
        }));

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
    }),

    getServiceProviderCustomerById: builder.query<any, number>({
      query: id => {
        if (!id) {
          return {
            url: '',
          };
        }

        return {
          url: `serviceprovider/customer/${id}`,
        };
      },
      transformResponse: (response: any) => {
        return {
          ...response,
          //Address 1, Address 2, City, State,  Zip code, Country
          // Can't destructure because sometimes address is empty from BE
          addressUrl: concatAddress(
            [
              response.address?.line1,
              response.address?.line2,
              response.address?.cityName,
              response.address?.stateProvinceCode,
              response.address?.zipCodePostalCode,
              response.address?.countryCode,
            ].filter(Boolean),
          ),
          billingAddressUrl: concatAddress(
            [
              response.billingAddress?.billingLine1,
              response.billingAddress?.billingLine2,
              response.billingAddress?.billingCityName,
              response.billingAddress?.billingStateProvinceCode,
              response.billingAddress?.billingZipCodePostalCode,
              response.billingAddress?.billingCountryCode,
            ].filter(Boolean),
          ),
          isBillingSameAsAddress: response.isBillingSameAsAddress
            ? ['Use same for Billing Address']
            : false,

          moduleName: 'customer',
        };
      },
      providesTags: ['ServiceProviderCustomerById'],
    }),

    updateServiceProviderCustomer: builder.mutation<
      void,
      ServiceProviderDetailsUpdate
    >({
      query: ({ id, ...body }) => {
        if (!id) {
          return {
            url: '',
          };
        }

        return {
          url: `serviceprovider/customer/${id}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['ServiceProviderById', 'ServiceProvider'],
    }),

    // DIRECTORY
    getServiceProviderDirectoryList: builder.query<
      BaseType<ServiceProviderDirectoryTypes[]>,
      ServiceProdiverDirectorySearchParams & QueryParams
    >({
      query: params => {
        return {
          url: 'serviceprovider/directory',
          params: _.pickBy(params, _.identity),
        };
      },
      transformResponse: (response: any) => {
        if (response?.etErrorCode) {
          return response;
        }
        const result: ServiceProviderDirectoryTypes[] = response?.data.map(
          item => {
            const transformedItem = {
              serviceProviderId: item?.id,
              serviceProviderName: item?.name,
              tradeType: item?.tradeType,
              workOrdersCompleted: item?.spProfile?.finishedWorkOrderCount,
              workOrdersCompletedInArea:
                item?.spProfile?.finishedWorkOrdersInAreaCount,
              totalCustomers: item?.spProfile?.totalCustomers,
              rating: item?.spProfile?.finishedWorkOrderRating,
              tradeArea: item?.spTradeRegion,
              email: item?.email,
              selfPerform: item?.spTradeRegion[0]?.selfPerform,
              logo: item?.logo,
              phone: item?.phone,
              businessAddress: concatAddress(
                [
                  item?.address?.line1,
                  item?.address?.line2,
                  item?.address?.cityName,
                  item?.address?.stateProvinceCode,
                  item?.address?.zipCodePostalCode,
                  item?.address?.countryCode,
                ].filter(Boolean),
              ),
            };
            return transformedItem;
          },
        );
        return {
          ...response,
          data: result,
        };
      },
    }),
    getServiceProviderDirectoryTradeAreas: builder.query<
      BaseType<ServiceProviderTradeArea[]>,
      ServiceProdiverDirectoryTradeAreasSearchParams
    >({
      query: params => {
        return {
          url: `serviceprovider/directory/${params.id}/trade/area`,
        };
      },
    }),

    getServiceProviderList: builder.query<
      BaseType<ServiceProviderTypes[]>,
      ServiceProviderQueryParams
    >({
      query: params => {
        return {
          url: 'serviceprovider',
          params,
        };
      },
      providesTags: ['ServiceProvider'],
    }),
    getServiceProviderInvitationList: builder.query<
      BaseType<ServiceProviderInvitation[]>,
      { status: InvitationStatus; queryParams: QueryParams }
    >({
      query: params => {
        return {
          url: 'serviceprovider/invitation',
          params,
        };
      },
      providesTags: ['ServiceProvider'],
    }),
    getServiceProviderReceivedInvitatesList: builder.query<
      BaseType<ServiceProviderInvitation[]>,
      { status: InvitationStatus } & QueryParams
    >({
      query: params => {
        return {
          url: 'serviceprovider/invitation/received',
          params,
        };
      },
      providesTags: ['ServiceProvider'],
    }),
    acceptServiceProviderInvitate: builder.mutation<void, string>({
      query: inviteCode => {
        return {
          url: `serviceprovider/invite/accept/${inviteCode}`,
          method: 'put',
        };
      },
      invalidatesTags: ['ServiceProvider'],
    }),
    rejectServiceProviderInvitation: builder.mutation<void, string>({
      query: inviteCode => {
        return {
          url: `serviceprovider/invite/reject/${inviteCode}`,
          method: 'put',
        };
      },
      invalidatesTags: ['ServiceProvider'],
    }),
    getServiceProviderInvitationById: builder.query<
      any,
      ServiceProviderInvitation
    >({
      query: params => {
        return {
          url: `serviceprovider/invite/link/${params.id}`,
        };
      },
      providesTags: ['ServiceProvider'],
    }),
    sendInvite: builder.query<any, ServiceProviderInvitationCreate>({
      query: params => {
        return {
          url: 'serviceprovider/invite',
          body: params,
          method: 'post',
        };
      },
      providesTags: ['ServiceProvider'],
    }),
    resendInvite: builder.query<any, string>({
      query: id => {
        return {
          url: `serviceprovider/invite/resend`,
          method: 'post',
          body: {
            id,
          },
        };
      },
    }),
    // RATES AND TERMS
    getServiceProviderRatesAndTerms: builder.query<
      ServiceProviderRatesAndTerms,
      { spId: number; companyId?: number }
    >({
      query: ({ spId, companyId }) => {
        return {
          url: `serviceprovider/${spId}/rate-and-terms`,
          params: { cmpny: companyId },
        };
      },
      transformResponse: (response: ServiceProviderRatesAndTerms) => {
        return {
          ...response,
          preferredPayment:
            response?.preferredPayment === '-1'
              ? ''
              : response?.preferredPayment,
          paymentTerms: response?.paymentTermsAmount
            ? `${response?.paymentTermsAmount || 0} ${
                response?.paymentTermsUnit?.description || ''
              }`
            : '',
          partsTerms: response?.partsTermsAmount
            ? `${response?.partsTermsAmount || 0} ${
                response?.partsTermsUnit?.description || ''
              }`
            : '',
          laborTerms: response?.laborTermsAmount
            ? `${response?.laborTermsAmount || 0} ${
                response?.laborTermsUnit?.description || ''
              }`
            : '',
          paymentTermsUnitDescription: response?.paymentTermsUnit?.description,
          partsTermsUnitDescription: response?.partsTermsUnit?.description,
          laborTermsUnitDescription: response?.laborTermsUnit?.description,

          hasHelperHourlyCharges:
            response?.helperDoubleTime ||
            response?.helperOverTime ||
            response?.helperRegularTime ||
            response?.helperTravelTime ||
            response?.helperTripCharge ||
            response?.helperTruckCharge,
        };
      },
      providesTags: ['ServiceProviderRatesAndTerms'],
    }),

    getCustomerServiceProviderRatesAndTerms: builder.query<
      ServiceProviderRatesAndTerms,
      any
    >({
      query: ({ spId, cmpnyId }) => {
        return {
          url: `serviceprovider/${spId}/rate-and-terms?cmpny=${cmpnyId}`,
        };
      },
      transformResponse: (response: ServiceProviderRatesAndTerms) => {
        return {
          ...response,
          preferredPayment:
            response?.preferredPayment === '-1'
              ? ''
              : response?.preferredPayment,
          paymentTerms: response?.paymentTermsAmount
            ? `${response?.paymentTermsAmount || 0} ${
                response?.paymentTermsUnit?.description || ''
              }`
            : '',
          partsTerms: response?.partsTermsAmount
            ? `${response?.partsTermsAmount || 0} ${
                response?.partsTermsUnit?.description || ''
              }`
            : '',
          laborTerms: response?.laborTermsAmount
            ? `${response?.laborTermsAmount || 0} ${
                response?.laborTermsUnit?.description || ''
              }`
            : '',
          paymentTermsUnitDescription: response?.paymentTermsUnit?.description,
          partsTermsUnitDescription: response?.partsTermsUnit?.description,
          laborTermsUnitDescription: response?.laborTermsUnit?.description,

          hasHelperHourlyCharges:
            response?.helperDoubleTime ||
            response?.helperOverTime ||
            response?.helperRegularTime ||
            response?.helperTravelTime ||
            response?.helperTripCharge ||
            response?.helperTruckCharge,
        };
      },
      providesTags: ['ServiceProviderRatesAndTerms'],
    }),

    getServiceProviderCurrencyList: builder.query<any[], void>({
      query: () => {
        return {
          url: 'lookup/currency',
        };
      },
    }),

    getServiceProviderOperationHourList: builder.query<any[], void>({
      query: () => {
        return {
          url: 'lookup/operation-hour',
        };
      },
    }),

    getServiceTermsUnitList: builder.query<any[], void>({
      query: () => {
        return {
          url: '/lookup/period/payment',
        };
      },
    }),

    updateServiceProviderRatesAndTerms: builder.mutation<
      void,
      SPRateAndTermsUpdateDTO
    >({
      query: ({ id, ...body }) => {
        return {
          url: `serviceprovider/${id}/rate-and-terms`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['ServiceProviderRatesAndTerms'],
    }),

    //TRADES AND REGIONS
    getServiceProviderTradeRegionById: builder.query<
      { data: TradeRegion[] },
      { id: number; queryParams: QueryParams }
    >({
      query: ({ id, queryParams }) => {
        return {
          url: `serviceprovider/directory/${id}/trade/area`,
          params: queryParams,
        };
      },
      transformResponse: (response: BaseType<TradeRegion[]>) => {
        let transformed = response.data.map(tradeRegion => {
          tradeRegion.regionsQuantity = tradeRegion.regions.length;
          return tradeRegion;
        });
        return {
          config: {
            ...response.config,
          },
          data: transformed,
        };
      },
    }),

    getDesignatedSPs: builder.query<
      { id: number; name: string; group: string }[],
      { assetTypeId: number; branchId: number }
    >({
      query: ({ branchId, assetTypeId }) => ({
        url: `serviceprovider/branch/${branchId}/assetgroup/${assetTypeId}`,
      }),
    }),

    createServiceProviderTradesAndRegions: builder.mutation<
      void,
      TradeRegionCreateDTO
    >({
      query: ({ spId, tradeRegions }) => {
        return {
          url: `serviceprovider/directory/${spId}/trade/area`,
          method: 'put',
          body: tradeRegions,
        };
      },
      invalidatesTags: ['ServiceProviderRatesAndTerms'],
    }),

    getAvailableSPsForWorkorder: builder.query<
      { id: number; name: string }[],
      { workorderId: number; customerId?: number }
    >({
      query: ({ workorderId, customerId }) => ({
        url: `serviceprovider/workorder/${workorderId}`,
        params: { customerId },
      }),
      providesTags: ['AvailableSPsForWorkorder'],
    }),
  }),
});

export const {
  // Grid View Page
  useLazyGetCompanyServiceProvidersListQuery,
  useGetCompanyServiceProvidersListQuery,
  useGetServiceProviderInvitationByIdQuery,
  useLazyGetServiceProviderInvitationByIdQuery,

  //DROPDOWN (TRADE ASSIGNMENT)
  useGetCompanyServiceProvidersDropdownListQuery,
  useLazyGetCompanyServiceProvidersDropdownListQuery,

  // CUSTOMERS PAGE
  useGetServiceProviderCustomerListQuery,
  useLazyGetServiceProviderCustomerListQuery,
  useUpdateServiceProviderCustomerMutation,
  useGetServiceProviderCustomerByIdQuery,

  //Details Page
  useGetServiceProviderByIdQuery,
  useLazyGetServiceProvidersListQuery,
  useGetServiceProviderConfigurationByIdQuery,
  useUpdateServiceProviderByIdMutation,

  //Directory Page
  useGetServiceProviderDirectoryListQuery,
  useLazyGetServiceProviderDirectoryListQuery,
  useGetServiceProviderDirectoryTradeAreasQuery,
  useLazyGetServiceProviderDirectoryTradeAreasQuery,
  useGetServiceProviderListQuery,
  useLazyGetServiceProviderListQuery,
  useGetServiceProviderInvitationListQuery,
  useLazyGetServiceProviderInvitationListQuery,
  useLazySendInviteQuery,
  useSendInviteQuery,
  useResendInviteQuery,
  useLazyResendInviteQuery,
  useGetServiceProviderReceivedInvitatesListQuery,
  useLazyGetServiceProviderReceivedInvitatesListQuery,
  useAcceptServiceProviderInvitateMutation,
  useRejectServiceProviderInvitationMutation,

  //RATES AND TERMS
  useGetServiceProviderRatesAndTermsQuery,
  useGetCustomerServiceProviderRatesAndTermsQuery,
  useGetServiceProviderCurrencyListQuery,
  useGetServiceProviderOperationHourListQuery,
  useGetServiceTermsUnitListQuery,
  useUpdateServiceProviderRatesAndTermsMutation,

  // TRADES AND REGIONS
  useGetServiceProviderTradeRegionByIdQuery,
  useLazyGetServiceProviderTradeRegionByIdQuery,
  useCreateServiceProviderTradesAndRegionsMutation,

  useGetDesignatedSPsQuery,
  useLazyGetDesignatedSPsQuery,

  // WORK ORDER
  useGetAvailableSPsForWorkorderQuery,
  useLazyGetAvailableSPsForWorkorderQuery,
} = serviceProviderApi;
