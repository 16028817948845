import {
  useCreateAreaMutation,
  useLazyGetAreaByIdQuery,
  useLazyGetAreaListQuery,
  useUpdateAreaMutation,
} from 'services/areaApi';
import { ECDynamicPageTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import { loadPageScopes } from 'utils/pageScopes';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';
import { useCompanyCustomerProfile } from 'app/hooks/useCompanyCustomerProfile.use-case';

const fancyFormElementsCreate = require('./form_config_create.json');
const fancyFormElementsEdit = require('./form_config_edit.json');
const fancyFormElementsDetails = require('./form_config_details.json');

export function AreaPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('area');
  }, []);

  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/area'));
  }, [dispatch]);

  const customerProfile = useCompanyCustomerProfile();

  if (!customerProfile?.enableArea) return <></>;

  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      pageTitle={t('translation:pages.areaTypes.title')}
      useLazyGetListQuery={useLazyGetAreaListQuery}
      useLazyGetByIdQuery={useLazyGetAreaByIdQuery}
      useCreateMutation={useCreateAreaMutation}
      useUpdateMutation={useUpdateAreaMutation}
      createFormConfig={fancyFormElementsCreate.config}
      createFormFields={fancyFormElementsCreate.fields}
      editFormConfig={fancyFormElementsEdit.config}
      editFormFields={fancyFormElementsEdit.fields}
      detailsConfig={fancyFormElementsDetails.config}
      detailsFields={fancyFormElementsDetails.fields}
      downloadFileName="areas.csv"
      enableExport={true}
      hasDetails
      showOnlyHistoryTab
      marginTop={false}
      showStatusActiveFilter={true}
      shouldNotUseActiveFilter
      showDrawerDetailTitle={false}
    />
  );
}
