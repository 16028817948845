import { CSSProperties, ChangeEvent, useState, useEffect } from 'react';
import {
  minimalPhonePattern,
  formatPhone,
  validatePhoneNumber,
  stripPhoneNumber,
  removeCountryCodeFromPhone,
  getCountryCodeFromPhone,
  prependCountryCodeToPhone,
  CountryCodeOption,
} from 'utils/strings/phone';
import { ECTextField } from '../ECTextField';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ECBox } from '../ECBox';
import { CountryDropdown } from './countryDropdown';
import { SelectChangeEvent } from '@mui/material';

interface Props {
  onFocus?: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  onChange?: (
    event:
      | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent<string>,
  ) => void;
  onBlur?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  id: string;
  name: string;
  value?: string;
  variant?: 'standard' | 'filled';
  disabled?: boolean;
  label?: string;
  style?: CSSProperties;
  readOnly?: boolean;
  minimumValue?: number | string;
  error?: boolean;
  sx?: any;
  validationMessage?: string;
  maxLength?: number;
  maxValue?: number;
  hiddenLabel?: boolean;
  defaultValue?: number | string;
  initialPhoneCountryCodeOption?: CountryCodeOption;
  hideValidationMessage?: boolean;
  hideCountryCodeDropDown?: boolean;
}

export const ECPhoneField = ({ onChange, onBlur, ...props }: Props) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const {
    variant = 'standard',
    error,
    value,
    style,
    sx,
    validationMessage,
    readOnly,
    hiddenLabel = false,

    //I'm adding those below to remove all props being passed
    // it throws console errors when props are passed to the DOM
    onFocus,
    name,
    disabled,
    label,
    minimumValue,
    maxLength,
    maxValue,
    defaultValue,
    initialPhoneCountryCodeOption,
    hideValidationMessage,
    hideCountryCodeDropDown,
  } = props;

  useEffect(() => {
    const formattedInput = formatPhone(value || '');
    setPhoneNumber(formattedInput);
  }, [value]);
  const handlePhoneChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
  ) => {
    const countryCodeFromPhone =
      getCountryCodeFromPhone(event.target.value) ||
      getCountryCodeFromPhone(phoneNumber) ||
      'US';

    const input =
      event.target.value === ''
        ? ''
        : prependCountryCodeToPhone(
            stripPhoneNumber(removeCountryCodeFromPhone(event.target.value)),
            countryCodeFromPhone,
          );
    event.target.value = input;
    const formattedInput = formatPhone(input);
    onChange?.(event);
    const isValid = validatePhoneNumber(input);
    setPhoneNumber(formattedInput);
    setIsValid(isValid);
    setIsFocused(event.target.value.length > 0);
  };

  useEffect(() => {
    if (readOnly) {
      setIsValid(validatePhoneNumber(phoneNumber));
    }
  }, [readOnly, phoneNumber]);

  const handlePhoneBlur = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (
      phoneNumber?.length === 0 ||
      phoneNumber?.length < minimalPhonePattern.length
    ) {
      setIsFocused(false);
      setPhoneNumber('');
    }
  };

  return (
    <ECBox display="flex" flexDirection="row" gap={1} alignItems="flex-start">
      {!hideCountryCodeDropDown && !readOnly && (
        <CountryDropdown
          phoneNumber={phoneNumber}
          initialPhoneCountryCodeOption={initialPhoneCountryCodeOption}
          handlePhoneChange={handlePhoneChange}
        />
      )}
      <ECTextField
        name={name}
        disabled={disabled}
        label={label}
        defaultValue={defaultValue}
        value={readOnly ? phoneNumber : removeCountryCodeFromPhone(phoneNumber)}
        placeholder={minimalPhonePattern}
        helperText={
          hideValidationMessage
            ? null
            : validationMessage ||
              (!isValid ? `Must be in ${minimalPhonePattern} format!` : null)
        }
        variant={variant}
        error={(!isValid && !!phoneNumber?.length) || !!validationMessage}
        InputProps={{
          endAdornment: (
            <>
              {isFocused ? (
                isValid ? (
                  <CheckCircleOutlineIcon color="success" />
                ) : (
                  <ErrorOutlineIcon color="error" />
                )
              ) : null}
            </>
          ),
          readOnly,
        }}
        sx={{
          ...sx,
          ...style,
          cursor: readOnly ? 'pointer !important' : 'auto',
        }}
        InputLabelProps={value ? { shrink: true } : {}}
        hiddenLabel={hiddenLabel}
        onFocus={() => {
          if (!readOnly) {
            setIsFocused(true);
            setIsValid(validatePhoneNumber(phoneNumber));
          }
        }}
        onChange={handlePhoneChange as any}
        onBlur={handlePhoneBlur}
      />
    </ECBox>
  );
};
