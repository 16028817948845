import { ECDynamicPageTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useLazyGetAssetServiceHistoryListQuery } from 'services/assetApi';

export function ServiceHistoryPage() {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      idToGetListBy={id}
      marginTop={false}
      pageTitle={t('translation:pages.serviceHistory.title')}
      useLazyGetListQuery={useLazyGetAssetServiceHistoryListQuery}
      useCreateMutation={{}}
      withDrawer={false}
      enableExport
      showBadgeExpirationDate={true}
      shouldUseSearchParams={true}
      showTotal={true}
      showTotalHelperText={t(
        'translation:pages.serviceHistory.showTotalHelperText',
      )}
      shouldNotUseActiveFilter={true}
    />
  );
}
