import {
  useLazyGetJobTitlesListQuery,
  useCreateJobTitleMutation,
  useUpdateJobTitleMutation,
  useGetJobTitlesRolesQuery,
} from 'services/jobTitleApi';
import { ECDynamicPageTemplate, FieldTypes } from 'app/components';
import { loadPageScopes } from 'utils/pageScopes';
import { JobTitlesRolesTab } from './JobTitlesRolesTab';
import { P } from 'types/Permission';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { JobTitleMutate, JobTitleRoles } from 'types/JobTitle';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import {
  setFilterEndpoint,
  setCleanActiveFilter,
  setActiveFilter,
} from 'store/slice/page';
import { useGetControlledRolesQuery } from 'services/roleApi';
import { useGetInternalTechsCompaniesQuery } from 'services/userApi';
import { useGetProfileQuery } from 'services/profileApi';
import { ActivityType } from 'types/Activity';
import { historyApi } from 'services/historyApi';

const initialEditFormElements = require('./fancy_form_config_edit.json');
const initialCreateFormElements = require('./fancy_form_config_create.json');
const initialDetailsFormElements = require('./fancy_form_config_details.json');

export function JobTitlesPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    loadPageScopes('jobTitle');
    dispatch(setFilterEndpoint('/filter/jobtitles'));
    dispatch(setCleanActiveFilter());
  }, []);

  const [detailsFormFields, setDetailsFormFields] = useState(
    initialDetailsFormElements.fields,
  );
  const [editFormFields, setEditFormFields] = useState(
    initialEditFormElements.fields,
  );
  const [createFormFields, setCreateFormFields] = useState(
    initialCreateFormElements.fields,
  );
  const [row, setRow] = useState<any>();

  const { data: controlledRolesList, isSuccess: isSuccessControlledRolesList } =
    useGetControlledRolesQuery();

  const { data: rolesList } = useGetJobTitlesRolesQuery();
  const [jobTitleSelectedRolesList, setJobTitleSelectedRolesList] = useState<
    JobTitleRoles[]
  >([]);

  const { data: companyDetails, isSuccess: isSuccessCompanyDetails } =
    useGetProfileQuery();

  const resetRow = useCallback(() => {
    setJobTitleSelectedRolesList([]);
    setRow({});
  }, []);

  useEffect(() => {
    if (!_.isEmpty(row)) {
      const tempDetailsFileds = [...detailsFormFields];
      const tempEditFileds = [...editFormFields];
      controlledRolesList?.forEach(roleItem => {
        const detailsItemWithFieldName = tempDetailsFileds.find(
          item => item.fieldName === roleItem.name,
        );
        if (detailsItemWithFieldName) {
          detailsItemWithFieldName.value = row[roleItem.name] === 1;
        }

        const editItemWithFieldName = tempEditFileds.find(
          item => item.fieldName === roleItem.name,
        );
        if (editItemWithFieldName) {
          editItemWithFieldName.value = row[roleItem.name] === 1;
        }
      });
      if (row?.companyType?.code === 'SP') {
        const detailsItemWithFieldName = tempDetailsFileds.find(
          item => item.fieldName === 'viewAllLocations',
        );
        if (detailsItemWithFieldName) {
          detailsItemWithFieldName.value = row.viewAllLocations ? true : false;
        }

        const editItemWithFieldName = tempEditFileds.find(
          item => item.fieldName === 'viewAllLocations',
        );
        if (editItemWithFieldName) {
          editItemWithFieldName.value = row.viewAllLocations ? true : false;
        }
      }
      setDetailsFormFields(tempDetailsFileds);
      setEditFormFields(tempEditFileds);
      const rolesList: JobTitleRoles[] = row?.roles?.map(element => ({
        id: element.id,
        name: element.name,
        isControlled: element.isControlled,
      }));
      setJobTitleSelectedRolesList(rolesList);
    }
  }, [row]);

  const handleAddNewClick = () => {
    setJobTitleSelectedRolesList(
      rolesList?.data?.filter(
        r => !controlledRolesList?.find(cr => cr.id === r.id),
      ),
    );
  };

  useEffect(() => {
    if (isSuccessControlledRolesList && controlledRolesList) {
      const initialCreateFields: any[] = [...initialCreateFormElements.fields];
      const initialDetailsFields: any[] = [
        ...initialDetailsFormElements.fields,
      ];
      const initialEditFields: any[] = [...initialEditFormElements.fields];

      const isSP = row?.companyType?.code === 'SP';

      controlledRolesList?.forEach(controlledRole => {
        if (
          !controlledRole.companyTypeId ||
          controlledRole?.companyTypeId === companyDetails?.companyTypeId
        ) {
          initialCreateFields.push({
            type: FieldTypes.Switch,
            label: controlledRole?.name,
            placeholder: controlledRole?.name,
            options: [false, true],
            value: Boolean(row?.[controlledRole?.name]),
            overrideRowValue: true,
            fieldName: controlledRole?.name,
          });
        }
        if (!controlledRole.companyTypeId || controlledRole.companyTypeId === row?.companyTypeId) {
          initialDetailsFields.push({
            type: FieldTypes.Switch,
            label: controlledRole?.name,
            placeholder: controlledRole?.name,
            options: [false, true],
            value: Boolean(row?.[controlledRole?.name]),
            overrideRowValue: true,
            fieldName: controlledRole?.name,
            readOnly: true,
          });
          initialEditFields.push({
            type: FieldTypes.Switch,
            label: controlledRole?.name,
            placeholder: controlledRole?.name,
            options: [false, true],
            value: Boolean(row?.[controlledRole?.name]),
            overrideRowValue: true,
            fieldName: controlledRole?.name,
          });
        }
      });

      if (isSP) {
        initialDetailsFields.push({
          type: FieldTypes.Switch,
          label: 'See All Locations',
          placeholder: 'See All Locations',
          options: [false, true],
          value: row?.viewAllLocations ? true : false,
          overrideRowValue: true,
          readOnly: true,
          fieldName: 'viewAllLocations',
        });
        initialEditFields.push({
          type: FieldTypes.Switch,
          label: 'See All Locations',
          placeholder: 'See All Locations',
          options: [false, true],
          value: row?.viewAllLocations ? true : false,
          overrideRowValue: true,
          fieldName: 'viewAllLocations',
        });
      }
      setCreateFormFields(initialCreateFields);
      setDetailsFormFields(initialDetailsFields);
      setEditFormFields(initialEditFields);
    }
  }, [isSuccessControlledRolesList, controlledRolesList, rolesList, row]);

  const [
    doUpdateJobTitle,
    {
      data: updateData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isLoadingUpdate,
      isSuccess: isUpdateSuccess,
      reset: resetUpdate,
    },
  ] = useUpdateJobTitleMutation();

  const getControlledRoleIds = useCallback(
    jobTitleRoot => {
      if (!controlledRolesList) return [];
      return controlledRolesList
        .filter(role => jobTitleRoot[role.name] === 1)
        .map(r => r.id);
    },
    [controlledRolesList],
  );

  const useUpdateJobTitle = useCallback(() => {
    const doUpdate = async data => {
      const newRolesIds: number[] = [
        ...getControlledRoleIds(data),
        ...jobTitleSelectedRolesList.map(jtr => jtr.id),
      ];

      const previousRolesIds = [
        ...getControlledRoleIds(row),
        ...row.roles.map(role => role.id),
      ];

      const rolesIdsToAdd = _.difference(newRolesIds, previousRolesIds);
      const rolesIdsToRemove = _.difference(previousRolesIds, newRolesIds);

      const body: JobTitleMutate = {
        name: data.name,
        id: data.id,
        nte: 0,
        status: data.status,
        rolesIdsToAdd,
        rolesIdsToRemove,
      };
      if (Object.hasOwn(data, 'viewAllLocations')) {
        body.viewAllLocations = data.viewAllLocations === 1;
      }
      doUpdateJobTitle(body);
    };
    return [
      doUpdate,
      {
        data: updateData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isLoadingUpdate,
        isSuccess: isUpdateSuccess,
        reset: resetUpdate,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isLoadingUpdate,
    isUpdateSuccess,
    resetUpdate,
    doUpdateJobTitle,
    controlledRolesList,
    jobTitleSelectedRolesList,
    updateData,
  ]);

  const [
    doCreateJobTitle,
    {
      data: createData,
      isError: isCreateError,
      error: createError,
      isLoading: isLoadingCreate,
      isSuccess: isCreateSuccess,
      reset: resetCreate,
    },
  ] = useCreateJobTitleMutation();

  const useCreateJobTitle = useCallback(() => {
    const doCreate = data => {
      const rolesIds: number[] = [
        ...getControlledRoleIds(data),
        ...jobTitleSelectedRolesList.map(jtr => jtr.id),
      ];

      const body: JobTitleMutate = {
        name: data.name,
        nte: 0,
        status: data.status,
        rolesIds,
      };
      doCreateJobTitle(body);
    };
    return [
      doCreate,
      {
        data: createData,
        isError: isCreateError,
        error: createError,
        isLoading: isLoadingCreate,
        isSuccess: isCreateSuccess,
        reset: resetCreate,
      },
    ];
  }, [
    isCreateError,
    createError,
    isLoadingCreate,
    isCreateSuccess,
    resetCreate,
    doCreateJobTitle,
    controlledRolesList,
    createData,
    jobTitleSelectedRolesList,
  ]);

  const handleSelectedRolesChange = (role, action) => {
    if (action === 'checked') {
      setJobTitleSelectedRolesList([
        ...jobTitleSelectedRolesList,
        {
          id: role.id,
          name: role.name,
          isControlled: role.isControlled,
        },
      ]);
    } else {
      const newData = [...jobTitleSelectedRolesList];
      setJobTitleSelectedRolesList(newData.filter(item => item.id !== role.id));
    }
  };

  const userCompanyObject = useMemo(() => {
    if (isSuccessCompanyDetails && companyDetails) {
      return {
        label: companyDetails?.name,
        fieldName: companyDetails?.name,
        name: companyDetails?.name,
        id: companyDetails?.id,
      };
    }
    return null;
  }, [companyDetails, isSuccessCompanyDetails]);

  useEffect(() => {
    if (isSuccessCompanyDetails && companyDetails) {
      dispatch(
        setActiveFilter({
          manufacturer: [userCompanyObject],
          isSelectAllManufacturers: false,
        }),
      );
    }
  }, [companyDetails, isSuccessCompanyDetails]);

  useEffect(() => {
    if (!isLoadingUpdate && isUpdateSuccess) {
      dispatch(historyApi.util.invalidateTags(['History']));
      resetRow();
    }
  }, [isUpdateSuccess, isLoadingUpdate]);

  useEffect(() => {
    if (!isLoadingCreate && isCreateSuccess) {
      dispatch(historyApi.util.invalidateTags(['History']));
    }
  }, [isLoadingCreate, isCreateSuccess]);

  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      pageTitle={'Job Title'}
      useLazyGetListQuery={useLazyGetJobTitlesListQuery}
      moduleName="jobtitle"
      useCreateMutation={useCreateJobTitle}
      useUpdateMutation={useUpdateJobTitle}
      showStatusActiveFilter={true}
      statusFilterInitialSelected
      defaultSortConfig={{ fieldName: 'jbttle.name', value: 'ASC' }}
      editFormConfig={initialEditFormElements.config}
      editFormFields={editFormFields}
      createFormConfig={initialCreateFormElements.config}
      createFormFields={createFormFields}
      detailsConfig={initialDetailsFormElements.config}
      detailsFields={detailsFormFields}
      enableExport={true}
      downloadFileName="jobs.csv"
      exportModuleName="jobtitle"
      marginTop={false}
      hasDetails={true}
      module="Job Titles"
      onRowClick={setRow}
      onDrawerClose={resetRow}
      onAddNewClick={handleAddNewClick}
      additionalTabs={[
        {
          value: '1',
          label: t('translation:pages.jobTitles.roles'),
          additionalTabFieldnameData: 'roles',
          scopes: [P.EditJobTitle],
          editable: true,
          content: (
            <JobTitlesRolesTab
              data={[]}
              handleSelectedRolesChange={handleSelectedRolesChange}
              jobTitleSelectedRolesList={jobTitleSelectedRolesList}
              options={
                rolesList?.data?.filter(
                  r => !controlledRolesList?.find(cr => cr.id === r.id),
                ) || []
              }
            />
          ),
        },
      ]}
      showOnlyadditionalTabs={true}
      showManufacturerFilter
      useManufacturerFilterQuery={useGetInternalTechsCompaniesQuery}
      userCompany={userCompanyObject}
      shouldNotUseActiveFilter
      showActivityButton
      activityType={ActivityType.JobTitle}
      showDrawerDetailTitle={false}
    />
  );
}
