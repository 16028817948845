import { ArrowDropDown } from '@mui/icons-material';
import { ECBox } from '../ECBox';
import { ECButton, ECButtonProps } from '../ECButton';
import { useState } from 'react';
import { ECModal } from '../ECModal';
import { ECTypography } from '../ECTypography';
import { ECAlert } from '../ECAlert';
import { ECCircularProgress } from '../ECCircularProgress';

interface ECDeferredButtonProps extends ECButtonProps {
  handleConfirmSubmit?: () => void;
  setDeferSelected?: (value: boolean) => void;
  handleDeferredButtonFormValidation?: (
    e: React.FormEvent<HTMLFormElement>,
  ) => boolean;
}

export const ECDeferredButton = ({
  sx = {},
  handleConfirmSubmit,
  handleDeferredButtonFormValidation,
  setDeferSelected,
  ...props
}: ECDeferredButtonProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isDeferredSelected, setIsDeferredSelected] = useState(false);

  const baseSx = {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    flexGrow: 1,
  };
  const { width, height, ...otherSx } = sx;

  const handleCloseConfirmationModal = () => {
    setDeferSelected?.(false);
    setShowConfirmationModal(false);
  };

  const handleConfirmationModalSubmit = () => {
    setShowConfirmationModal(false);
    setIsDeferredSelected(false);
    handleConfirmSubmit?.();
  };

  const handleShowConfirmationModal = e => {
    const form = e.target?.form;
    const isValid = handleDeferredButtonFormValidation?.(form);
    if (isValid) {
      setDeferSelected?.(true);
      setShowConfirmationModal(true);
    }
  };

  return (
    <>
      <ECBox
        sx={{
          display: 'flex',
          columnGap: 0.25,
          position: 'relative',
          width: width || 'auto',
          height: height || 'auto',
        }}
      >
        <ECButton
          {...props}
          sx={theme => ({
            ...baseSx,
            ...otherSx,
            '&.Mui-disabled': {
              background: theme.palette.text.button.actionDisable,
              color: `${theme.palette.grey[600]} !important`,
            },
          })}
        />
        <ECBox
          sx={theme => ({
            backgroundColor: props.disabled
              ? theme.palette.text.button.actionDisable
              : isDeferredSelected
                ? theme.palette.button.active
                : theme.palette.button.actionPrimary,
            width: '41px',
            maxWidth: '41px',
            borderRadius: 4,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          })}
          onClick={() => {
            if (props.disabled) return;
            setIsDeferredSelected(prevSelectedDefer => !prevSelectedDefer);
          }}
        >
          <ArrowDropDown
            sx={theme => ({
              color: props.disabled
                ? theme.palette.grey[600]
                : theme.palette.primary.contrast,
            })}
          />
        </ECBox>
        {isDeferredSelected && (
          <ECButton
            sx={theme => ({
              position: 'absolute',
              top: '-56px',
              right: 0,
              width: '260px',
              height: '56px',
              zIndex: 20,
              backgroundColor: theme.palette.common.white,
              color: theme.palette.action.selected,
              alignItems: 'center',
              justifyContent: 'flex-start',
              display: 'flex',
              paddingLeft: 4,
              textTransform: 'none',
              borderRadius: 4,
              '&:hover': {
                backgroundColor: theme.palette.common.white,
              },
              boxShadow: theme.shadows[4],
            })}
            onClick={handleShowConfirmationModal}
          >
            <ECTypography variant="body1">Defer</ECTypography>
          </ECButton>
        )}
      </ECBox>
      <ECModal
        isOpen={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        noPadding
        noPaddingBottom
      >
        <ECBox pb={1} pt={2} pl={3} pr={1} width="509px" height="237.41px">
          <ECTypography variant="h6" fontWeight={700}>
            Confirm you want to set your Work Order to Deferred Status
          </ECTypography>

          <ECBox sx={{ paddingRight: 1 }}>
            <ECAlert severity="info" sx={{ mt: 1, alignItems: 'center' }}>
              <ECTypography
                variant="body2"
                sx={theme => ({
                  color: theme.palette.text.alert.info,
                })}
              >
                Your work order will not be assigned to a Service Provider until
                you move it from a deferred status and assign it to a Service
                Provider to perform the work.
              </ECTypography>
            </ECAlert>
          </ECBox>

          <ECBox mt={2} display="flex" gap={2} justifyContent="flex-end">
            <ECButton variant="text" onClick={handleCloseConfirmationModal}>
              Cancel
            </ECButton>
            <ECButton
              variant="contained"
              color="info"
              onClick={handleConfirmationModalSubmit}
            >
              {props.isLoading ? (
                <ECCircularProgress
                  size={'.5rem'}
                  sx={{ color: 'background.paper' }}
                />
              ) : (
                'Confirm'
              )}
            </ECButton>
          </ECBox>
        </ECBox>
      </ECModal>
    </>
  );
};
