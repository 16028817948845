import { useEffect, useState } from 'react';
import {
  ECBox,
  ECEasyFormFieldType,
  ECTextField,
  ECTypography,
} from 'app/components';
import { PaymentSelector } from 'app/pages/SignUpWizardPage/paymentSelector';
import { CreditCardNumberField } from '../ECCreditCardNumber';
import { ECEasyFormSectionGridItem } from '../ECForm/ECFormCell';
import { CreditCardCVVField } from '../ECCreditCardCVV';
import { CreditCardExpirationDate } from '../ECCreditCardExpirationDate';
import { RoutingNumberField } from '../ECRoutingNumber';
import { AccountNumberField } from '../ECAccountNumber';

import { useMediaQuery, useTheme } from '@mui/material';

type Props = ECEasyFormFieldType;

export const PaymentFormField = ({
  value: valueProps,
  validationMessage,
}: Props) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const [errorMessage, setErrorMessage] = useState<any>();
  const [value, setValue] = useState(valueProps);
  const [required, setRequired] = useState<boolean>(false);

  useEffect(() => {
    if (!validationMessage) return;
    const [field, message] = validationMessage?.split('-');
    setErrorMessage({
      [field]: [message],
    });
    setValue(prev => ({
      ...prev,
      credit_card_number: '',
      credit_card_cvv: '',
      credit_card_expire_date: '',
    }));
  }, [validationMessage]);

  useEffect(() => {
    if (
      value?.credit_card_number === '' &&
      value?.credit_card_cvv === '' &&
      value?.credit_card_expire_date === '' &&
      value?.ach_routing_number === '' &&
      value?.ach_account_number === ''
    )
      setRequired(false);
    else setRequired(true);
  }, [value]);

  const handleChangePaymentSelector = (section: 'creditcard' | 'ach') => {
    setValue(prev => ({
      ...prev,
      paymentMethod: section,
      credit_card_number: '',
      credit_card_cvv: '',
      credit_card_expire_date: '',
      ach_routing_number: '',
      ach_account_number: '',
    }));
  };

  const handleChangeCreditCardNumberField = (creditCardNumber: string) => {
    setValue(prev => ({ ...prev, credit_card_number: creditCardNumber }));
  };

  const handleChangeCreditCardCVVField = (creditCardCVV: string) => {
    setValue(prev => ({ ...prev, credit_card_cvv: creditCardCVV }));
  };

  const handleChangeCreditCardExpirationDateField = (
    creditCardExpirationDate: string,
  ) => {
    setValue(prev => ({
      ...prev,
      credit_card_expire_date: creditCardExpirationDate,
    }));
  };

  const handleChangeRoutingNumberField = (achRoutingNumber: string) => {
    setValue(prev => ({ ...prev, ach_routing_number: achRoutingNumber }));
  };

  const handleChangeAccountNumberField = (achAccountNumber: string) => {
    setValue(prev => ({ ...prev, ach_account_number: achAccountNumber }));
  };

  return (
    <ECBox>
      <ECBox display={'flex'} flex={1} justifyContent={'center'}>
        <PaymentSelector onChange={handleChangePaymentSelector} />
      </ECBox>

      <ECTextField
        name={'paymentType'}
        sx={{
          visibility: 'hidden',
        }}
        value={value?.paymentMethod}
      />

      <ECEasyFormSectionGridItem
        sx={{
          marginBottom: 2,
          flexDirection: 'row',
          display: 'flex',
          gap: 1,
        }}
      >
        <ECBox
          sx={theme => ({
            width: '32px',
            height: '32px',
            borderRadius: '16px',
            border: `1px solid ${theme.palette.button.hoverOutlinedText}`,
            background: theme.palette.background.sectionBg,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <ECTypography
            color={theme => theme.palette.primary.main}
            variant="h6"
          >
            2
          </ECTypography>
        </ECBox>
        <ECTypography variant={'h6'}>
          {value?.paymentMethod === 'creditcard'
            ? 'Credit Card Info'
            : 'ACH Info'}
        </ECTypography>
      </ECEasyFormSectionGridItem>
      {value?.paymentMethod === 'creditcard' ? (
        <ECBox display={'flex'} flex={1} flexDirection={'column'} gap={1}>
          <CreditCardNumberField
            required={required}
            fieldName="credit_card_number"
            label="Credit Card Number"
            value={value?.credit_card_number}
            onChange={handleChangeCreditCardNumberField}
            validationMessage={
              errorMessage && errorMessage['credit_card_number']
            }
          />
          <ECBox
            display={'flex'}
            flex={1}
            gap={1}
            flexDirection={isMobileSize ? 'column' : 'row'}
          >
            <CreditCardCVVField
              fieldName="credit_card_cvv"
              label={'CVV Code'}
              required={required}
              onChange={handleChangeCreditCardCVVField}
              value={value?.credit_card_cvv}
              sx={{
                width: isMobileSize ? '100%' : '50%',
              }}
              validationMessage={
                errorMessage && errorMessage['credit_card_cvv']
              }
            />
            <CreditCardExpirationDate
              fieldName="credit_card_expire_date"
              required={required}
              onChange={value =>
                handleChangeCreditCardExpirationDateField(value)
              }
              placeholder="MM/YY"
              label={'Expiration Date'}
              value={value?.credit_card_expire_date}
              sx={{
                width: isMobileSize ? '100%' : '50%',
              }}
              validationMessage={
                errorMessage && errorMessage['credit_card_expire_date']
              }
            />
          </ECBox>
        </ECBox>
      ) : (
        <ECBox display={'flex'} flex={1} flexDirection={'column'} gap={1}>
          <RoutingNumberField
            fieldName="ach_routing_number"
            required={required}
            label="Routing Number"
            value={value?.ach_routing_number}
            onChange={handleChangeRoutingNumberField}
            sx={{
              width: '100%',
            }}
            validationMessage={
              errorMessage && errorMessage['ach_routing_number']
            }
          />

          <AccountNumberField
            fieldName="ach_account_number"
            label={'Account Number'}
            required={required}
            onChange={handleChangeAccountNumberField}
            value={value?.ach_account_number}
            validationMessage={
              errorMessage && errorMessage['ach_account_number']
            }
          />
        </ECBox>
      )}
    </ECBox>
  );
};
