import { ECBox } from 'app/components/ECBox';
import React from 'react';
import { ECTableLayoutHeaderProps } from 'types/core/ECTable';

export const ECTableLayoutHeader: React.FC<ECTableLayoutHeaderProps> = ({
  color,
  children,
}) => {
  return (
    <React.Fragment>
      <ECBox
        sx={{
          display: 'flex',
          backgroundColor: color,
          coloredShadow: color,
          justifyContent: 'flex-start',
          alignItems: 'center',
          borderRadius: 'xl',
          bgColor: color,
        }}
      >
        {children}
      </ECBox>
    </React.Fragment>
  );
};
