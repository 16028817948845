import { QueryParams } from 'types/QueryParams';
import { BaseType } from 'types/BaseType';
import { emptyApi } from './emptyApi';
import { Manufacture } from 'types/Asset';

interface ManufactureSeries extends QueryParams {
  id?: number;
}

interface ModelsByManufactureSeries extends QueryParams {
  manufactureId?: number;
  seriesId?: number;
  modelId?: number;
}

export interface ManufactureSeriesResponse {
  id: number;
  name: string;
}

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['manufacture'],
});

export const manufactureApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getManufactureList: builder.query<BaseType<Manufacture[]>, QueryParams>({
      query: params => {
        return {
          url: 'manufacture',
          params,
        };
      },
      providesTags: ['manufacture'],
      keepUnusedDataFor: 300,
    }),
    getManufactureSeriesList: builder.query<
      BaseType<ManufactureSeriesResponse[]>,
      ManufactureSeries
    >({
      query: ({ id }) => {
        return {
          url: `manufacture/${id}/series`,
        };
      },
      providesTags: ['manufacture'],
    }),
    getModelsByManufactureSeries: builder.query<
      BaseType<Manufacture[]>,
      ModelsByManufactureSeries
    >({
      query: ({ manufactureId, seriesId }) => {
        return {
          url: `manufacture/${manufactureId}/series/${seriesId}/model`,
        };
      },
      providesTags: ['manufacture'],
    }),
    addModelForSeries: builder.mutation<
      BaseType<any>,
      ModelsByManufactureSeries
    >({
      query: ({ manufactureId, seriesId, ...body }) => {
        return {
          url: `manufacture/${manufactureId}/series/${seriesId}/model`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['manufacture'],
    }),
    getWarrantyPeriodsByManufactureIdSeriesIdModelId: builder.query<
      BaseType<any>,
      ModelsByManufactureSeries
    >({
      query: ({ manufactureId, seriesId, modelId }) => {
        return {
          url: `manufacture/${manufactureId}/series/${seriesId}/model/${modelId}/periods`,
        };
      },
      providesTags: ['manufacture'],
    }),
  }),
});

export const {
  useLazyGetManufactureListQuery,
  useGetManufactureSeriesListQuery,
  useLazyGetManufactureSeriesListQuery,
  useGetModelsByManufactureSeriesQuery,
  useLazyGetModelsByManufactureSeriesQuery,
  useAddModelForSeriesMutation,
  useGetWarrantyPeriodsByManufactureIdSeriesIdModelIdQuery,
  useLazyGetWarrantyPeriodsByManufactureIdSeriesIdModelIdQuery,
  useGetManufactureListQuery,
} = manufactureApi;
