import { useCallback, useMemo, useState } from 'react';
import { ECMenuItem, ECSelect } from '../../ECSelect';
import { ECTypography } from '../../ECTypography';
import { Checkbox, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { ECChip } from '../../ECChip';
import { ECOnlyMeFilter } from '../../ECTable/ECOnlyMeFilter';
import { useDispatch } from 'react-redux';
import { setActiveFilter } from 'store/slice/page';

type CategoryFilterProps = {
  categoryFilterOptions: any[];
};

export const ECCategoryFilter: React.FC<CategoryFilterProps> = ({
  categoryFilterOptions,
}) => {
  const dispatch = useDispatch();
  const { activeFilter: activeFilterFromSelector, endpoint } = useSelector(
    (state: RootState) => state.page.filter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[endpoint];
  }, [window.location.href, activeFilterFromSelector]);

  const [categoryFilter, setCategoryFilter] = useState<string[]>(
    activeFilter?.categories ||
      categoryFilterOptions
        ?.filter(option => option.isInitialSelected)
        ?.map(option => option.fieldName) ||
      [],
  );
  const [categoryFilterOpen, setCategoryFilterOpen] = useState<boolean>(false);

  const handleCatergoryFilterOpen = useCallback(() => {
    setCategoryFilterOpen(true);
  }, []);
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      width="200px"
      height="40px"
      border="1px solid #E0E0E0"
      borderRadius="4px"
      display="flex"
      justifyContent="space-around"
    >
      {!!categoryFilterOptions?.length && (
        <>
          <ECTypography
            color={theme => theme.palette.text.secondary}
            variant="caption"
          >
            Category:
          </ECTypography>
          <ECSelect
            labelId="mutiple-select-label"
            multiple
            variant="standard"
            open={categoryFilterOpen}
            onOpen={handleCatergoryFilterOpen}
            disableUnderline
            value={categoryFilter}
            displayEmpty
            MenuProps={{
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              anchorOrigin: {
                vertical: 40,
                horizontal: 90,
              },
            }}
            onClose={() => {
              if (
                categoryFilter.length === 0 ||
                categoryFilter.length === categoryFilterOptions.length
              ) {
                dispatch(
                  setActiveFilter({
                    categories: [],
                    filterPath: endpoint,
                  }),
                );
              } else {
                dispatch(
                  setActiveFilter({
                    categories: categoryFilter,
                    filterPath: endpoint,
                  }),
                );
              }
              setCategoryFilterOpen(false);
            }}
            onChange={e => {
              const value = e.target.value as any;
              if (value[value.length - 1] === 'all') {
                setCategoryFilter(
                  categoryFilter.filter(category => category !== 'all')
                    .length === categoryFilterOptions.length
                    ? []
                    : categoryFilterOptions?.map(option => option?.fieldName) ||
                        [],
                );
                return;
              }
              setCategoryFilter(value);
            }}
            renderValue={(selected: any) => {
              if (
                !selected?.length ||
                selected?.length === categoryFilterOptions?.length
              ) {
                return (
                  <ECChip
                    sx={{ width: '48px', height: '24px' }}
                    label="All"
                    color="Light Grey"
                  />
                );
              }

              return (
                <ECChip
                  sx={{ width: '48px', height: '24px' }}
                  label={selected?.length}
                  color="Dark Blue"
                />
              );
            }}
          >
            <ECMenuItem value="all">
              <ECTypography
                variant="body1"
                color={theme => theme.palette.primary.main}
              >
                {categoryFilter.length === categoryFilterOptions.length
                  ? 'Deselect All'
                  : 'Select All'}
              </ECTypography>
            </ECMenuItem>
            {categoryFilterOptions.map(option => (
              <ECMenuItem key={option?.fieldName} value={option?.fieldName}>
                <ListItemIcon>
                  <Checkbox
                    checked={categoryFilter.indexOf(option?.fieldName) > -1}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    '& span': {
                      maxWidth: '170px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                  primary={`${option?.status === 0 ? '(INACTIVE) ' : ''}${option?.label}`}
                />
                <ECOnlyMeFilter
                  closePopover={() => {
                    setCategoryFilter([option?.fieldName]);
                    setCategoryFilterOpen(false);
                    dispatch(
                      setActiveFilter({
                        categories: [option?.fieldName],
                        filterPath: endpoint,
                      }),
                    );
                  }}
                />
              </ECMenuItem>
            ))}
          </ECSelect>
        </>
      )}
    </Stack>
  );
};
