import { ECButton, ECDynamicPageTemplate } from 'app/components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import {
  useLazyGetWorkOrdersListQuery,
  useLazyGetWorkOrderByIdQuery,
} from 'services/workOrdersApi';
import { loadPageScopes } from 'utils/pageScopes';
import { setFilterEndpoint } from 'store/slice/page';
import { Contacts } from '@mui/icons-material';

export function WorkOrdersSearch({ mustTriggerListQuery }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const match = useMatch('/panel/*');

  useEffect(() => {
    loadPageScopes('workOrder');
    dispatch(setFilterEndpoint('/filter/workorder'));
  }, []);

  const handleOpenDetails = (row: any) => {
    const url = match ? match.pathname.replace('search', row.moduleName) : '';

    return row.moduleName && row.id ? navigate(`${url}/${row.id}`) : undefined;
  };

  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      pageTitle={t('translation:pages.workOrders.title')}
      useLazyGetListQuery={useLazyGetWorkOrdersListQuery}
      useCreateMutation={{}}
      useLazyGetByIdQuery={useLazyGetWorkOrderByIdQuery}
      moduleName="workorder"
      mustTriggerListQuery={mustTriggerListQuery}
      usingGlobalSearch={true}
      withDrawer={false}
      marginTop={false}
      enableExport
      downloadFileName="work-orders.csv"
      onRowClick={handleOpenDetails}
      ignoreHyperlink={true}
      moduleRedirectElement={
        <ECButton
          sx={{ marginBottom: '2rem' }}
          variant="contained"
          onClick={() => navigate(`${match?.pathnameBase}/work-orders`)}
          startIcon={<Contacts />}
        >
          Work Orders
        </ECButton>
      }
      shouldNotUseActiveFilter
    />
  );
}
