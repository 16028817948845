import { ECButton } from 'app/components/ECButton';
import { ECBox } from 'app/components/ECBox';
import { ECModal } from 'app/components/ECModal';
import { ProposalBase } from 'types/Proposal';
import { ECTypography } from 'app/components/ECTypography';
import { themes } from 'styles/theme/themes';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoadingButton?: boolean;
  openProposal: ProposalBase;
}

export const PendingProposalModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoadingButton,
  openProposal,
}: Props) => {
  return (
    <ECModal isOpen={isOpen} onClose={onClose} noPadding noPaddingBottom>
      <ECBox display="flex" py={2} px={3}>
        <ECTypography variant="h6">
          Change the Work Order Status to{' '}
          <ECTypography
            variant="h6"
            as="span"
            style={{ color: themes.dark.palette.success.main }}
          >
            Complete
          </ECTypography>
          ?
        </ECTypography>
      </ECBox>

      <ECBox display="flex" py={1} px={3}>
        <ECTypography variant="body1">
          There is an open Proposal {openProposal?.id} on the Work Order.
          Completing the Work Order will cancel the Proposal.
        </ECTypography>
      </ECBox>

      <ECBox display="flex" p={1} gap={1} justifyContent="flex-end">
        <ECButton onClick={onClose} variant="text">
          Cancel
        </ECButton>

        <ECButton
          isLoading={isLoadingButton}
          onClick={onConfirm}
          variant="contained"
        >
          Complete
        </ECButton>
      </ECBox>
    </ECModal>
  );
};
