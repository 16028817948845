import { ECButton } from '../ECButton';
import { ECCard, ECCardHeader } from '../ECCard';
import { ECDivider } from '../ECDivider';
import { ECGrid } from '../ECGrid';
import { ECList, ECListItem, ECListItemIcon, ECListItemText } from '../ECList';
import { ECTextField } from '../ECTextField';
import { ECCheckbox } from '../ECCheckbox';
import React, { useEffect } from 'react';
import { ECCircularProgress } from '../ECCircularProgress';
import {
  intersectionObjectArrays,
  intersection,
  notObjectArrays,
  not,
  union,
  unionObjectArrays,
} from '../../../utils/array';
import { useTranslation } from 'react-i18next';

export interface TransferListData {
  left: readonly any[];
  right: readonly any[];
  checked: readonly any[];
}

export interface ECEasyTransferListProps {
  // transferListData: TransferListData;
  // setTransferListData: (data: any) => void;
  initialData: TransferListData;
  dataPath: string;
  leftTitle?: string;
  rightTitle?: string;
  onLeftToRight?: (items: any) => void;
  onRightToLeft?: (items: any) => void;
  onDataChange: (data: TransferListData) => void;
  isLoading: boolean;
  isObjectArray?: boolean;
}

let statelessLeftSearchKeyword = '';
let statelessRightSearchKeyword = '';

let statelessLeftFilteredItems: readonly any[] = [];
let statelessRightFilteredItems: readonly any[] = [];

const CustomList = ({
  title,
  items,
  filteredItems,
  side,
  handleToggleAll,
  numberOfChecked,
  dataPath,
  handleToggle,
  checked,
  onFilter,
}) => {
  const { t } = useTranslation();
  return (
    <ECCard>
      <ECCardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <ECCheckbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <ECTextField
        label={t('translation:table.search')}
        sx={{ mx: 1, mb: 1 }}
        size={'small'}
        placeholder={'Keyword ...'}
        // value={searchKeyword}
        onChange={event => {
          const eventValue = event.target.value;
          onFilter(eventValue);
        }}
      />
      <ECDivider />
      <ECList
        sx={{
          width: '100%',
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        // component="div"
        role="list"
      >
        {filteredItems.map((value, index) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ECListItem
              key={value[dataPath] + index}
              role="listitem"
              // button
              onClick={handleToggle(value)}
            >
              <ECListItemIcon>
                <ECCheckbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ECListItemIcon>
              <ECListItemText
                id={labelId}
                primary={value[dataPath]}
                sx={{ whiteSpace: 'nowrap' }}
              />
            </ECListItem>
          );
        })}
        <ECListItem />
      </ECList>
    </ECCard>
  );
};

let statelessTransferListData: TransferListData;

export const ECEasyTransferList = (props: ECEasyTransferListProps) => {
  const {
    // transferListData,
    // setTransferListData,
    dataPath,
    initialData,
    onLeftToRight,
    onRightToLeft,
    onDataChange,
    isLoading,
    leftTitle,
    rightTitle,
    isObjectArray,
  } = props;

  const [transferListData, setTransferListData] =
    React.useState<TransferListData>({ ...initialData });

  const [filteredTransferListData, setFilteredTransferListData] =
    React.useState<TransferListData>({ ...initialData });

  useEffect(() => {
    statelessLeftSearchKeyword = '';
    statelessRightSearchKeyword = '';
    statelessTransferListData = { ...initialData };
  }, []);

  const leftChecked = isObjectArray
    ? intersectionObjectArrays(transferListData.checked, transferListData.left)
    : intersection(transferListData.checked, transferListData.left);
  const rightChecked = isObjectArray
    ? intersectionObjectArrays(transferListData.checked, transferListData.right)
    : intersection(transferListData.checked, transferListData.right);

  const updateData = (newData: TransferListData, updateFilter: boolean) => {
    setTransferListData(newData);
    statelessTransferListData = newData;
    onDataChange(newData);
    if (updateFilter) {
      refreshFilters();
    }
  };

  const handleToggle = (value: number) => () => {
    const currentIndex = transferListData.checked.indexOf(value);
    const newChecked = [...transferListData.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    const newData = {
      right: transferListData.right,
      left: transferListData.left,
      checked: newChecked,
    };
    updateData(newData, false);
  };

  const handleLeftFilter = keyword => {
    statelessLeftSearchKeyword = keyword;
    if (keyword.length === 0) {
      setFilteredTransferListData({
        ...statelessTransferListData,
        left: statelessTransferListData.left,
      });
      return;
    }
    setFilteredTransferListData({
      ...statelessTransferListData,
      left: statelessTransferListData.left.filter(value =>
        value[dataPath].includes(keyword),
      ),
    });
  };

  const handleRightFilter = keyword => {
    statelessRightSearchKeyword = keyword;
    if (keyword.length === 0) {
      setFilteredTransferListData({
        ...statelessTransferListData,
        right: statelessTransferListData.right,
      });
      return;
    }
    setFilteredTransferListData({
      ...statelessTransferListData,
      right: statelessTransferListData.right.filter(value =>
        value[dataPath].includes(keyword),
      ),
    });
  };

  const numberOfChecked = (items: readonly number[]) =>
    isObjectArray
      ? intersectionObjectArrays(transferListData.checked, items).length
      : intersection(transferListData.checked, items).length;

  const handleToggleAll = (items: readonly number[]) => () => {
    let newData;
    if (numberOfChecked(items) === items.length) {
      newData = {
        right: transferListData.right,
        left: transferListData.left,
        checked: isObjectArray
          ? notObjectArrays(transferListData.checked, items)
          : not(transferListData.checked, items),
      };
    } else {
      newData = {
        right: transferListData.right,
        left: transferListData.left,
        checked: isObjectArray
          ? unionObjectArrays(transferListData.checked, items)
          : union(transferListData.checked, items),
      };
    }
    updateData(newData, false);
  };

  const refreshFilters = () => {
    handleLeftFilter(statelessLeftSearchKeyword);
    handleRightFilter(statelessRightFilteredItems);
  };

  const handleCheckedRight = () => {
    const newData = {
      right: [...transferListData.right.concat(leftChecked)],
      left: isObjectArray
        ? [...notObjectArrays(transferListData.left, leftChecked)]
        : [...not(transferListData.left, leftChecked)],
      checked: isObjectArray
        ? [...notObjectArrays(transferListData.checked, leftChecked)]
        : [...not(transferListData.checked, leftChecked)],
    };
    updateData(newData, true);
    if (onLeftToRight) {
      onLeftToRight(leftChecked);
    }
  };

  const handleCheckedLeft = () => {
    const newData = {
      right: isObjectArray
        ? [...notObjectArrays(transferListData.right, rightChecked)]
        : [...not(transferListData.right, rightChecked)],
      left: [...transferListData.left.concat(rightChecked)],
      checked: isObjectArray
        ? [...notObjectArrays(transferListData.checked, rightChecked)]
        : [...not(transferListData.checked, rightChecked)],
    };
    updateData(newData, true);
    if (onRightToLeft) {
      onRightToLeft(rightChecked);
    }
  };

  const handleAllLeft = () => {
    const newData = {
      right: [],
      left: transferListData.left.concat(transferListData.right),
      checked: transferListData.checked,
    };
    updateData(newData, true);
    if (onRightToLeft) {
      onRightToLeft(transferListData.right);
    }
  };

  const handleAllRight = () => {
    const newData = {
      right: transferListData.right.concat(transferListData.left),
      left: [],
      checked: transferListData.checked,
    };
    updateData(newData, true);
    if (onLeftToRight) {
      onLeftToRight(transferListData.left);
    }
  };

  if (isLoading) {
    return (
      <ECGrid container spacing={2} justifyContent="center" alignItems="center">
        <ECGrid sx={{ flex: 1 }} item>
          <ECCard
            sx={{
              p: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ECCircularProgress />
          </ECCard>
        </ECGrid>
        <ECGrid item>
          <ECGrid container direction="column" alignItems="center">
            <ECButton
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              disabled={transferListData.left.length === 0}
              aria-label="move all right"
            >
              ≫
            </ECButton>
            <ECButton
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </ECButton>
            <ECButton
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </ECButton>
            <ECButton
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              disabled={transferListData.right.length === 0}
              aria-label="move all left"
            >
              ≪
            </ECButton>
          </ECGrid>
        </ECGrid>
        <ECGrid sx={{ flex: 1 }} item>
          <ECCard
            sx={{
              p: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ECCircularProgress />
          </ECCard>
        </ECGrid>
      </ECGrid>
    );
  }

  return (
    <ECGrid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      flexDirection={'row'}
    >
      <ECGrid xs={5} item>
        <CustomList
          title={leftTitle}
          items={transferListData.left}
          filteredItems={filteredTransferListData.left}
          side={'left'}
          handleToggleAll={handleToggleAll}
          numberOfChecked={numberOfChecked}
          dataPath={dataPath}
          handleToggle={handleToggle}
          checked={transferListData.checked}
          onFilter={handleLeftFilter}
        />
      </ECGrid>
      <ECGrid xs={2} item>
        <ECGrid container direction="column" alignItems="center">
          <ECButton
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={transferListData.left.length === 0}
            aria-label="move all right"
          >
            ≫
          </ECButton>
          <ECButton
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </ECButton>
          <ECButton
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </ECButton>
          <ECButton
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={transferListData.right.length === 0}
            aria-label="move all left"
          >
            ≪
          </ECButton>
        </ECGrid>
      </ECGrid>
      <ECGrid xs={5} item>
        <CustomList
          title={rightTitle}
          items={transferListData.right}
          filteredItems={filteredTransferListData.right}
          side={'right'}
          handleToggleAll={handleToggleAll}
          numberOfChecked={numberOfChecked}
          dataPath={dataPath}
          handleToggle={handleToggle}
          checked={transferListData.checked}
          onFilter={handleRightFilter}
        />
      </ECGrid>
    </ECGrid>
  );
};
