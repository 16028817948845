import {
  useCreateUserMutation,
  useLazyGetUsersListQuery,
  useUpdateUserMutation,
} from 'services/userApi';
import { ECDynamicPageTemplate, ECIconButton } from 'app/components';
import { useGetJobTitlesListQuery } from 'services/jobTitleApi';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadPageScopes } from 'utils/pageScopes';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint, setSnackbar } from 'store/slice/page';
import { useGetTimezonesQuery } from 'services/lookupApi';
import {
  useForgotPasswordMutation,
  useLazyGetEmailExistsQuery,
} from 'services/authApi';
import { isValidEmail } from 'utils/validate-fields';
import { normalizeZipcode } from 'utils/strings/zipcode';
import SendIcon from '@mui/icons-material/Send';
import { ECTooltip } from 'app/components/ECTooltip';

const createFormConfig = require('./form_config_create.json');

export function ServiceProviderUsersPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [csvFileName, setCsvFileName] = useState('users.csv');

  const [userAvailable, setUserAvailable] = useState(false);

  const [validPassword, setValidPassword] = useState(false);

  const [triggerEmail] = useLazyGetEmailExistsQuery({});

  const onValidatePassword = (isValidPassword: boolean) => {
    setValidPassword(isValidPassword);
  };
  const [signinUser, { isSuccess: isSucessResend }] =
    useForgotPasswordMutation();

  const renderResendInviteActionButton = (row: any) => {
    if (row?.lastLogin) {
      return null;
    } else {
      return (
        <ECIconButton
          squared
          withBorder
          withWhiteBackground
          sx={{ color: theme => theme.palette.primary.main }}
          key={`resend-invite-${row.id}`}
          onClick={() => {
            signinUser({
              email: `${row?.email}`?.toLowerCase(),
            });
          }}
        >
          <>
            <ECTooltip title={'Resend new user invitation'}>
              <SendIcon />
            </ECTooltip>
          </>
        </ECIconButton>
      );
    }
  };
  useEffect(() => {
    if (isSucessResend) {
      dispatch(
        setSnackbar({
          message: 'The new user registration was successfully sent',
          severity: 'success',
        }),
      );
    }
  }, [isSucessResend]);

  const handleBlurEmail = async (value: string, field: any) => {
    setUserAvailable(false);
    // being used to trigger re-render of component
    setCsvFileName('');
    if (value?.length > 0 && !isValidEmail(value)) {
      field.isValid = false;
      field.validationMessage = t(
        'translation:form.validation.correctFormatEmail',
        {
          name: field.placeholder,
        },
      );
    } else {
      field.isValid = true;
      field.validationMessage = null;
      setUserAvailable(true);
      const responseUserExists = await triggerEmail({
        email: value,
        ignoreUserPool: true,
      });
      if (responseUserExists?.data?.existed) {
        field.isValid = false;
        field.validationMessage = t('translation:pages.users.alreadyExists');
        setUserAvailable(false);
      }
    }
    // being used to trigger re-render of component
    setCsvFileName('users.csv');
  };

  useEffect(() => {
    loadPageScopes('user');
    dispatch(setFilterEndpoint('/filter/users'));
    const emailField = createFormConfig.fields[0]?.subFields?.find(
      field => field.fieldName === 'email',
    );
    emailField.onBlur = handleBlurEmail;

    const passwordField = createFormConfig.fields?.find(
      field => field.fieldName === 'password',
    );
    passwordField.onValidatePassword = onValidatePassword;
  }, []);

  const [
    doAddUser,
    {
      data: createUserData,
      isError: isAddError,
      error: addError,
      isLoading: isAddLoading,
      isSuccess: isAddSuccess,
      reset: resetAdd,
    },
  ] = useCreateUserMutation();

  const { data, isSuccess } = useGetJobTitlesListQuery({});

  const { data: timezonesData, isSuccess: isSuccessTimezones } =
    useGetTimezonesQuery();

  useEffect(() => {
    if (data && isSuccess) {
      const jobTitles = (data as any)?.data ?? data;
      const jobTittleField = createFormConfig.fields?.[0]?.subFields?.find(
        field => field.fieldName === 'jobTitle',
      );
      jobTittleField.options = jobTitles.map(({ name, id }) => ({
        label: name,
        id,
      }));
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (timezonesData && isSuccessTimezones) {
      const timezones = (timezonesData as any)?.data ?? timezonesData;
      const timezoneField = createFormConfig.fields?.find(
        field => field.fieldName === 'timezone',
      );
      timezoneField.options = timezones.map(({ timezone }) => ({
        label: timezone,
        id: timezone,
      }));
    }
  }, [timezonesData, isSuccessTimezones]);

  const useCreateUser = useCallback(() => {
    const doCreate = async data => {
      if (!userAvailable) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: t('translation:pages.users.alreadyExists'),
          }),
        );
        return;
      }
      if (!validPassword) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: t('translation:pages.users.provideValidPassword'),
          }),
        );
        return;
      }
      data.timezone = data.timezone?.id;
      data.userName = data.email;
      data.zipCodePostalCode = normalizeZipcode(data.zipCodePostalCode);
      doAddUser(data);
    };

    return [
      doCreate,
      {
        data: createUserData,
        isError: isAddError,
        error: addError,
        isLoading: isAddLoading,
        isSuccess: isAddSuccess,
        reset: resetAdd,
      },
    ];
  }, [
    createUserData,
    addError,
    isAddError,
    isAddLoading,
    isAddSuccess,
    userAvailable,
    validPassword,
    resetAdd,
  ]);

  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      pageTitle={t('translation:pages.users.title')}
      useLazyGetListQuery={useLazyGetUsersListQuery}
      showStatusActiveFilter
      statusFilterInitialSelected
      useCreateMutation={useCreateUser}
      useUpdateMutation={useUpdateUserMutation}
      createFormConfig={createFormConfig.config}
      createFormFields={createFormConfig.fields}
      downloadFileName={csvFileName}
      enableExport
      exportModuleName="users"
      editFormAdditionalProcess={({ row, formConfig, formFields }) => {
        formConfig.title = `${row?.firstName} ${row?.lastName}`;
      }}
      hasDetails
      renderCustomActionComponent={renderResendInviteActionButton}
      marginTop={false}
      withDrawer={false}
      shouldNotUseActiveFilter
    />
  );
}
