import { useCallback, useEffect, useState } from 'react';
import {
  ECBox,
  ECEasyForm,
  ECEasyFormFieldType,
  ECLink,
  ECTypography,
} from 'app/components';
import {
  getCountryCodeOptionFromCountryCode,
  prependCountryCodeToPhone,
  stripPhoneNumber,
} from 'utils/strings/phone';
import { useMediaQuery, useTheme } from '@mui/material';
import { ECCheckbox } from 'app/components/ECCheckbox';

interface Step4Props {
  existingData?: any;
  errorMessages?: string[];
  onClose?: () => void;
  onSubmit?: (formBody: any) => void;
}

const fancyFormElementsCreate = require('./fancy_form_config_step4_create.json');

export const Step4 = ({
  existingData,
  onClose,
  onSubmit,
  errorMessages,
}: Step4Props) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeTermError, setAgreeTermsError] = useState(false);
  const [data, setData] = useState(existingData);

  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      if (!field) return;

      if (field.type === 'group') {
        return {
          ...field,
          subFields: field?.subFields?.map(handleExistingData),
        };
      }

      if (field.fieldName === 'company.phone') {
        const initialPhoneCountryCodeOption =
          getCountryCodeOptionFromCountryCode(data['Country Code']);
        return {
          ...field,
          initialPhoneCountryCodeOption,
        };
      }

      return { ...field };
    },
    [data],
  );

  const [formFields, setFormFields] = useState<any[]>(
    () => fancyFormElementsCreate.fields?.map(handleExistingData) || [],
  );

  const handleShowErrosFieldsResponse = (errors: string[] | undefined) => {
    if (!errors) return;
    const field = formFields.find(field => field.fieldName === 'paymentForm');
    errors.forEach(errorMessage => {
      if (errorMessage.includes('cardNumber')) {
        field.isValid = false;
        field.validationMessage = 'credit_card_number-Incorrect card format';
      } else if (errorMessage.includes('Card is expired')) {
        field.isValid = false;
        field.validationMessage =
          'credit_card_expire_date-Looks like your card is Expired. Add another date or card.';
      } else if (errorMessage.includes('cvv')) {
        field.isValid = false;
        field.validationMessage = 'credit_card_cvv-CVV is incorrect';
      } else if (errorMessage.includes('expirationMonth')) {
        field.isValid = false;
        field.validationMessage =
          'credit_card_expire_date-Expiration Date incorrect.';
      } else if (errorMessage.includes('routingNumber')) {
        field.isValid = false;
        field.validationMessage =
          'ach_routing_number-Routing Number is incorrect.';
      }
    });

    setFormFields([...formFields]);
  };

  useEffect(() => {
    setFormFields(
      fancyFormElementsCreate.fields?.map(handleExistingData) || [],
    );
  }, [data, handleExistingData]);

  useEffect(() => {
    handleShowErrosFieldsResponse(errorMessages);
  }, [errorMessages]);

  const submitForm = useCallback(
    (formData, output, _, shouldSkipPaymentValidation) => {
      const rawCompanyPhone = formData.get('company.phone');
      const companyPhoneCountryCode = formData.get('Country Code');

      const companyPhone =
        rawCompanyPhone && companyPhoneCountryCode
          ? prependCountryCodeToPhone(
              stripPhoneNumber(rawCompanyPhone),
              companyPhoneCountryCode,
            )
          : '';

      const formBody = {
        user: {
          ...data?.user,
          firstName: formData.get('user.firstName'),
          lastName: formData.get('user.lastName'),
        },
        company: {
          ...data?.company,
          phone: stripPhoneNumber(companyPhone).trim().replaceAll(/\D/g, ''),
          email: formData.get('company.email'),
          address: {
            ...data?.company?.address,
            line1: formData.get('address.line1'),
            line2: formData.get('address.line2'),
            cityName: formData.get('address.cityName'),
            stateProvinceCode: formData.get('address.stateProvinceCode'),
            zipCodePostalCode: formData.get('address.zipCodePostalCode'),
            countryCode: output.find(
              field => field.fieldName === 'address.countryCode',
            )?.value,
          },
        },
        industryCode: data?.industryCode,
      };

      if (shouldSkipPaymentValidation) {
        setFormFields([...output]);
        onSubmit?.(formBody);
        return;
      }

      const paymentMethod = formData.get('paymentType');
      let body: any = {
        paymentType: paymentMethod === 'creditcard' ? 'CC' : 'ACH',
      };

      if (paymentMethod === 'creditcard') {
        const [expirationMonth, expirationYear] = formData
          .get('credit_card_expire_date')
          .split('/');

        body = {
          ...body,
          cardNumber: formData.get('credit_card_number').replaceAll(' ', ''),
          expirationMonth,
          expirationYear,
          cvv: formData.get('credit_card_cvv'),
        };
      }
      if (paymentMethod === 'ach') {
        body = {
          ...body,
          accountingNumber: formData.get('ach_account_number'),
          routingNumber: formData.get('ach_routing_number'),
        };
      }

      formBody['paymentInfo'] = body;
      setFormFields([...output]);
      onSubmit?.(formBody);
    },
    [formFields, agreeTerms],
  );

  return (
    <ECBox
      paddingInline={isMobileSize ? 0 : `15%`}
      alignItems={'center'}
      justifyContent={'center'}
      display={'flex'}
      flexDirection={'column'}
    >
      <ECEasyForm
        isLoadingForm={false}
        isSendingData={false}
        onFormSubmit={(formData, output, _, skipValidation) => {
          submitForm(formData, output, null, skipValidation);
        }}
        config={fancyFormElementsCreate.config}
        fields={formFields}
        existingData={data}
        saveButtonDisabled={!agreeTerms}
        showSaveButton={false}
        showWideSaveButton={true}
        shouldShowSkipButton={true}
        formFooter={
          <ECBox
            marginBottom={isMobileSize ? '35px' : ''}
            display={'flex'}
            gap={'12px'}
            padding={'26px 36px'}
            borderRadius={'4px'}
            marginInline={'20px'}
            alignItems={'center'}
            sx={theme => ({
              background: agreeTermError
                ? theme.palette.error.background
                : theme.palette.info.background,
            })}
          >
            <ECCheckbox
              value={agreeTerms}
              onClick={() => {
                setAgreeTerms(!agreeTerms);
                setAgreeTermsError(false);
              }}
            />
            <ECTypography variant="body2" color={theme.palette.info.content}>
              By checking this box, I agree Ecotrak’s Terms found here:{' '}
              <ECLink
                target="_blank"
                href="https://www.ecotrak.com/legal/ecotrak-terms-and-conditions"
              >
                Terms and Conditions
              </ECLink>{' '}
              and Ecotrak’s Payment Terms found here:{' '}
              <ECLink
                target="_blank"
                href="https://www.ecotrak.com/legal/payment-terms"
              >
                Payments Terms
              </ECLink>
            </ECTypography>
          </ECBox>
        }
      />
    </ECBox>
  );
};
