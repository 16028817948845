import { ColType, ECTableCell } from 'app/components/ECTable';
import { ECTypography } from 'app/components/ECTypography';
import { get } from 'lodash';
import { DynamicTableCellProps } from 'types/core/ECTable';
import { TableCellFieldsMap } from './ECTableCellFields.map';

/**
 * DynamicTableCell is responsible for rendering
 * the cells of the table dynamically
 * based on the map of possible fields
 */
export const DynamicTableCell: React.FC<DynamicTableCellProps> = ({
  col,
  index,
  row,
  rowIndex,
  parentRow,
  onRowPress,
  onDuplicatePress,
  onRemove,
  t,
  customFieldsData,
}) => {
  const cellData = get(row, col.dataPath);
  const elementKey = `dynamic-cell-${rowIndex}-${col.title}`;
  if (col.showNaIfEmpty && !cellData) {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECTypography noWrap>N/A</ECTypography>
      </ECTableCell>
    );
  }

  const componentToRender = TableCellFieldsMap[col.type as ColType];
  if (!componentToRender) {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECTypography noWrap>{cellData}</ECTypography>
      </ECTableCell>
    );
  }

  return componentToRender({
    elementKey,
    row,
    col,
    index,
    customData: customFieldsData,
    cellData,
    t: t,
    onRemove: onRemove,
    onRowPress: onRowPress,
    onDuplicatePress: onDuplicatePress,
    parentRow: parentRow,
  });
};
