import { Build, Construction, Contacts, Info } from '@mui/icons-material';
import {
  ECBox,
  ECButton,
  ECCard,
  ECCardHeader,
  ECGrid,
  ECTypography,
} from 'app/components';

const helpItems = [
  {
    icon: <Build fontSize={'large'} />,
    title: 'How to place a Service Request?',
    link: 'https://helpcenter.ecotrak.com/en/articles/9620828-creating-a-service-request',
  },
  {
    icon: <Contacts fontSize={'large'} />,
    title: 'How to Manage a Work Order?',
    link: 'https://helpcenter.ecotrak.com/en/articles/9620827-work-order-management',
  },
  {
    icon: <Construction fontSize={'large'} />,
    title: 'How to Manage Service Providers?',
    link: 'https://helpcenter.ecotrak.com/en/articles/9620816-how-to-invite-assign-manage-service-providers',
  },
];

function ECHelpCard({ icon, title, link }) {
  return (
    <ECGrid item xs>
      <ECCard sx={{ background: '#F9F8F5' }}>
        <ECCardHeader
          avatar={icon}
          title={
            <ECTypography variant="subtitle1" fontWeight={'bold'} ml={2}>
              {title}
            </ECTypography>
          }
          action={
            <ECButton href={link} aria-label="view">
              View
            </ECButton>
          }
        />
      </ECCard>
    </ECGrid>
  );
}

export default function DashboardHelpCenter() {
  return (
    <ECBox
      sx={{ padding: 3, borderRadius: 2, boxShadow: 3 }}
      mt={3}
      flexWrap="wrap"
      width="100%"
    >
      <ECBox display="flex" justifyContent="space-between" alignItems="center">
        <ECTypography variant="h6" gutterBottom fontWeight="bold" fontSize={16}>
          Help Center and Tutorials
        </ECTypography>
        <a href="https://helpcenter.ecotrak.com/en/" aria-label="view">
          <ECButton startIcon={<Info fontSize="small" />}>
            visit Help Center
          </ECButton>
        </a>
      </ECBox>
      <ECBox display="flex">
        <ECGrid container spacing={2}>
          {helpItems.map((item, index) => (
            <ECHelpCard key={index} {...item} />
          ))}
        </ECGrid>
      </ECBox>
    </ECBox>
  );
}
