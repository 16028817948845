import { ECActionsTableCell } from 'app/components/ECActionsTableCell';
import { ECBox } from 'app/components/ECBox';
import { ECButton } from 'app/components/ECButton';
import { ECChip } from 'app/components/ECChip';
import { ECChipOptions } from 'app/components/ECChipOptions';
import ECChipTextTableCell from 'app/components/ECChipTextTableCell';
import ECCurrencyField from 'app/components/ECCurrencyField';
import { ECImageTableCell } from 'app/components/ECImageTableCell';
import { ECLink } from 'app/components/ECLink';
import { ECRankTableCell } from 'app/components/ECRankTableCell';
import {
  ECTRCell,
  ColType,
  ECChipTooltipTableCell,
  ECJSONTableCell,
  ECTableCell,
  ECSwitchTableCell,
  renderLinearProgress,
} from 'app/components/ECTable';
import { ECChipListTableCell } from 'app/components/ECTable/ECChipTableCell';
import { ECTooltip } from 'app/components/ECTooltip';
import { ECTypography } from 'app/components/ECTypography';
import { ECVideoTableCell } from 'app/components/ECVideoTableCell';
import { ECWarrantyBadge } from 'app/components/ECWarrantyBadge';
import { get, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { themes } from 'styles/theme/themes';
import { TableCellFieldMap as ITableCellFieldMap } from 'types/core/ECTable';
import { formatEtaHours } from 'utils/strings/format-eta';
import {
  formatAgeForTable,
  formatDateForTable,
  formatDateOnlyForTable,
} from 'utils/strings/formatDate';
import { formatPhone } from 'utils/strings/phone';

/**
 * TableCellFieldsMap is responsible for storing
 * the possible fields to be rendered based
 * on the type of the column and also to
 * pass custom data to the fields if needed
 * see the type ITableCellFieldMap
 */
export const TableCellFieldsMap = {
  [ColType.Chip]: ({ elementKey, row, col, index }) => {
    return (
      <ECTRCell key={elementKey} scope="row">
        <ECChip
          key={`colored-chip-${index}`}
          label={get(row, col.fieldName)}
          variant="outlined"
          color={row?.color || ''}
        />
      </ECTRCell>
    );
  },
  [ColType.ChipOptions]: ({ elementKey, row, col, index }) => {
    const status = get(row, col.fieldName);
    return (
      <ECTRCell key={elementKey} scope="row">
        <ECChipOptions
          key={`colored-chip-${index}`}
          label={status?.name || ''}
          color={status?.backgroundColor}
          textColor={status?.textColor}
          variant={status?.variant || 'outlined'}
          borderColor={status?.borderColor}
        />
      </ECTRCell>
    );
  },
  [ColType.ChipsList]: ({ elementKey, row, col }) => {
    return <ECChipListTableCell key={elementKey} col={col} row={row} />;
  },
  [ColType.ChipAutocomplete]: ({ elementKey, row, col }) => {
    return <ECChipListTableCell key={elementKey} col={col} row={row} />;
  },
  [ColType.ChipTooltip]: ({ elementKey, row, col }) => {
    return <ECChipTooltipTableCell key={elementKey} col={col} row={row} />;
  },
  [ColType.ChipText]: ({ elementKey, row, col }) => {
    return <ECChipTextTableCell key={elementKey} col={col} row={row} />;
  },
  [ColType.JSONTooltip]: ({ row, col, index }) => {
    return <ECJSONTableCell key={`${index}json`} col={col} row={row} />;
  },
  [ColType.UsersTable]: ({ row, cellData, elementKey, customData }) => {
    const {
      hasUsersTablePreviewData,
      onClickViewUsersTable,
      selectedChipUsersTable,
    } = customData;
    const shouldDisable =
      !cellData || (Array.isArray(cellData) && cellData.length === 0);

    const previewData =
      hasUsersTablePreviewData &&
      cellData?.length > 0 &&
      cellData.map(data => data.fullName).join(', ');
    return (
      <ECTableCell key={elementKey} sx={{ maxWidth: '650px' }}>
        {cellData ? (
          <ECBox display="flex" alignItems="center">
            {previewData && (
              <ECTypography mr={1}>
                {previewData.length > 50
                  ? `${previewData.slice(0, 50)}...`
                  : previewData}
              </ECTypography>
            )}
            <ECChip
              key={elementKey}
              label="View"
              color={
                selectedChipUsersTable == (!shouldDisable && row?.id)
                  ? themes.light.palette.primary.main
                  : undefined
              }
              textColor="white"
              variant="filled"
              onClick={e => {
                onClickViewUsersTable?.(row!, e);
              }}
              disabled={shouldDisable}
            />
          </ECBox>
        ) : (
          <></>
        )}
      </ECTableCell>
    );
  },
  [ColType.NotificationEmail]: ({ row, elementKey, cellData, customData }) => {
    const {
      onClickRecipientsNotificationEmail,
      onClickViewNotificationEmail,
      selectedChipUsersTable,
    } = customData;
    return (
      <ECTableCell key={elementKey}>
        {cellData ? (
          <ECBox display="flex" gap={2}>
            <ECChip
              label="Recipients"
              color={
                selectedChipUsersTable === `email-recipients-${row?.id}`
                  ? themes.light.palette.background.tertiary
                  : undefined
              }
              disabled={row?.notification?.email?.recipients?.length === 0}
              textColor="white"
              variant="filled"
              onClick={e => {
                onClickRecipientsNotificationEmail?.(row!, e);
              }}
            />

            <ECChip
              label="View"
              color={
                selectedChipUsersTable === `email-view-${row?.id}`
                  ? themes.light.palette.background.tertiary
                  : undefined
              }
              disabled={!row?.notification?.email?.body}
              textColor="white"
              variant="filled"
              onClick={e => {
                onClickViewNotificationEmail?.(row!, e);
              }}
            />
          </ECBox>
        ) : (
          <></>
        )}
      </ECTableCell>
    );
  },
  [ColType.NotificationMobile]: ({ row, elementKey, cellData, customData }) => {
    const {
      onClickRecipientsNotificationMobile,
      onClickViewNotificationMobile,
      selectedChipUsersTable,
    } = customData;
    return (
      <ECTableCell key={elementKey}>
        {cellData ? (
          <ECBox display="flex" gap={2}>
            <ECChip
              label="Recipients"
              color={
                selectedChipUsersTable === `mobile-recipients-${row?.id}`
                  ? themes.light.palette.background.tertiary
                  : undefined
              }
              disabled={row?.notification?.push?.recipients?.length === 0}
              textColor="white"
              variant="filled"
              onClick={e => {
                onClickRecipientsNotificationMobile?.(row!, e);
              }}
            />

            <ECChip
              label="View"
              color={
                selectedChipUsersTable === `mobile-view-${row?.id}`
                  ? themes.light.palette.background.tertiary
                  : undefined
              }
              disabled={
                !row?.notification?.push?.body && row?.notification?.push?.title
              }
              textColor="white"
              variant="filled"
              onClick={e => {
                onClickViewNotificationMobile?.(row!, e);
              }}
            />
          </ECBox>
        ) : (
          <></>
        )}
      </ECTableCell>
    );
  },
  [ColType.NotificationText]: ({ row, elementKey, cellData, customData }) => {
    const {
      onClickRecipientsNotificationText,
      onClickViewNotificationText,
      selectedChipUsersTable,
    } = customData;
    return (
      <ECTableCell key={elementKey}>
        {cellData ? (
          <ECBox display="flex" gap={2}>
            <ECChip
              label="Recipients"
              color={
                selectedChipUsersTable === `text-recipients-${row?.id}`
                  ? themes.light.palette.background.tertiary
                  : undefined
              }
              disabled={row?.notification?.sms?.recipients?.length === 0}
              textColor="white"
              variant="filled"
              onClick={e => {
                onClickRecipientsNotificationText?.(row!, e);
              }}
            />

            <ECChip
              label="View"
              color={
                selectedChipUsersTable === `text-view-${row?.id}`
                  ? themes.light.palette.background.tertiary
                  : undefined
              }
              disabled={
                !row?.notification?.sms?.body && row?.notification?.sms?.title
              }
              textColor="white"
              variant="filled"
              onClick={e => {
                onClickViewNotificationText?.(row!, e);
              }}
            />
          </ECBox>
        ) : (
          <></>
        )}
      </ECTableCell>
    );
  },
  [ColType.SendingStatus]: ({ elementKey, cellData }) => {
    return (
      <ECTableCell key={elementKey}>
        {cellData ? (
          <ECChip
            key={elementKey}
            label="Sent"
            color="Dark Green"
            variant="outlined"
          />
        ) : (
          <ECChip
            key={elementKey}
            label="Failed"
            color="Red"
            variant="outlined"
          />
        )}
      </ECTableCell>
    );
  },
  [ColType.Switch]: ({ elementKey, row, col }) => {
    return <ECSwitchTableCell key={elementKey} col={col} row={row} />;
  },
  [ColType.Link]: ({ elementKey, cellData, row, col, customData }) => {
    const { moduleName, isSPUser, ignoreHyperlink } = customData;
    if (ignoreHyperlink) {
      return (
        <ECTableCell
          key={elementKey}
          scope="row"
          sx={{ minWidth: '10px', ...col.optionalSx }}
        >
          <ECTypography noWrap>{cellData}</ECTypography>
        </ECTableCell>
      );
    }
    return (
      <ECTableCell key={elementKey} scope="row">
        {!row?.hideDetailLink ? (
          <ECLink
            href={`/panel/${isSPUser ? 'sp/' : ''}${
              row?.hyperLinkModuleName?.includes(col?.moduleName)
                ? row?.hyperLinkModuleName
                : col?.moduleName || row?.moduleName || moduleName
            }/${col?.moduleId ? row?.[col?.moduleId] : row?.id}`}
          >
            {/* TODO: revert these changes and solve the issue on the backend */}
            {/* {cellData} */}
            {!cellData ||
            (typeof cellData === 'string' &&
              (cellData.includes('NaN') || cellData.includes('undefined')))
              ? null
              : typeof cellData === 'string' && cellData.startsWith('WO-')
                ? cellData.slice(3)
                : cellData}
          </ECLink>
        ) : (
          <ECTypography>
            {/* TODO: revert these changes and solve the issue on the backend */}
            {/* {cellData} */}
            {typeof cellData === 'string' && cellData.startsWith('WO-')
              ? cellData.slice(3)
              : cellData}
          </ECTypography>
        )}
      </ECTableCell>
    );
  },
  [ColType.CustomLink]: ({ elementKey, cellData, customData, col }) => {
    const { renderTableCustomLink } = customData;
    if (!renderTableCustomLink) {
      return (
        <ECTableCell
          key={elementKey}
          scope="row"
          sx={{ minWidth: '10px', ...col.optionalSx }}
        >
          <ECTypography noWrap>{cellData}</ECTypography>
        </ECTableCell>
      );
    }
    return (
      <ECTableCell key={elementKey} scope="row">
        {renderTableCustomLink(cellData)}
      </ECTableCell>
    );
  },
  [ColType.Date]: ({ elementKey, cellData }) => {
    const isCellDataIncludesInvalidString =
      typeof cellData === 'string' && cellData.includes('Invalid');

    if (!cellData || isCellDataIncludesInvalidString) {
      return (
        <ECTableCell key={elementKey} scope="row">
          N/A
        </ECTableCell>
      );
    }

    let dt: DateTime;

    if (typeof cellData === 'string') {
      dt = DateTime.fromISO(cellData);
      if (!dt.isValid) {
        dt = DateTime.fromFormat(cellData, 'MM/dd/yyyy, hh:mm a');
      }
    } else {
      return (
        <ECTableCell key={elementKey} scope="row">
          {formatDateForTable(cellData)}
        </ECTableCell>
      );
    }

    const isInvalidDate = !dt.isValid;

    if (isInvalidDate) {
      return (
        <ECTableCell key={elementKey} scope="row">
          <ECTypography color={theme => theme.palette.graphic.alert.error}>
            {cellData}
          </ECTypography>
        </ECTableCell>
      );
    }

    return (
      <ECTableCell key={elementKey} scope="row">
        {dt.toFormat('MMMM d, yyyy h:mm:ss a')}
      </ECTableCell>
    );
  },
  [ColType.DateOnly]: ({ elementKey, cellData }) => {
    const isCellDataIncludesInvalidString =
      typeof cellData === 'string' && cellData.includes('Invalid');

    const dt =
      typeof cellData === 'string'
        ? DateTime.fromFormat(cellData, 'MM/dd/yyyy')
        : DateTime.invalid('Not a string');

    const isInvalidDate = typeof cellData === 'string' && !dt.isValid;

    if (isInvalidDate) {
      return (
        <ECTableCell key={elementKey} scope="row">
          <ECTypography color={theme => theme.palette.graphic.alert.error}>
            {cellData}
          </ECTypography>
        </ECTableCell>
      );
    }

    return (
      <ECTableCell key={elementKey} scope="row">
        {!cellData || isCellDataIncludesInvalidString
          ? 'N/A'
          : typeof cellData === 'string'
            ? dt.toFormat('MMMM d, yyyy')
            : formatDateOnlyForTable(cellData)}
      </ECTableCell>
    );
  },
  [ColType.ExpiredableText]: ({ elementKey, cellData }) => {
    const isExpired: boolean = cellData === 'Expired';
    if (isExpired) {
      return (
        <ECTableCell key={elementKey} scope="row">
          <ECTypography color={theme => theme.palette.graphic.alert.error}>
            {cellData}
          </ECTypography>
        </ECTableCell>
      );
    }
    return (
      <ECTableCell key={elementKey} scope="row">
        {isNil(cellData) ? 'N/A' : <ECTypography>{cellData}</ECTypography>}
      </ECTableCell>
    );
  },
  [ColType.Age]: ({ elementKey, cellData, col }) => {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        {formatAgeForTable(cellData)}
      </ECTableCell>
    );
  },
  [ColType.Phone]: ({ elementKey, cellData, col }) => {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        {formatPhone(cellData)}
      </ECTableCell>
    );
  },
  [ColType.Boolean]: ({ elementKey, cellData }) => {
    return (
      <ECTableCell key={elementKey} scope="row">
        {cellData === 0 ? 'Inactive ' : 'Active'}
      </ECTableCell>
    );
  },
  [ColType.Image]: ({ elementKey, row, cellData }) => {
    return (
      <ECImageTableCell
        src={cellData}
        moduleName={row?.moduleName}
        id={row?.id || row?.assetId}
        key={elementKey}
        profileAttachment={row?.profileAttachment}
      />
    );
  },
  [ColType.Video]: ({ elementKey, cellData }) => {
    return <ECVideoTableCell src={cellData} key={elementKey} />;
  },
  [ColType.RichText]: ({ elementKey, cellData }) => {
    return (
      <ECTableCell key={elementKey} scope="row">
        <ECTypography noWrap>
          {cellData
            .replaceAll('&amp;', '&')
            .replaceAll('&amp', '&')
            .replaceAll('&nbsp;', '')
            .replaceAll('&lt;', '<')
            .replaceAll('&gt;', '>')
            .replaceAll('&quot;', '"')
            .replaceAll('&apos;', "'")
            .replace(/<[^>]{1,400}>/g, '')
            .replace(/&lt;[^>]{1,400}&gt;/g, '')}
        </ECTypography>
      </ECTableCell>
    );
  },
  [ColType.HourETA]: ({ elementKey, cellData }) => {
    return (
      <ECTableCell key={elementKey} scope="row">
        {formatEtaHours(cellData)}
      </ECTableCell>
    );
  },
  [ColType.Rank]: ({ elementKey, row, cellData, col }) => {
    return (
      <ECRankTableCell
        key={elementKey}
        ranks={cellData}
        placeholder={col.placeholder}
      />
    );
  },
  [ColType.Actions]: ({
    elementKey,
    row,
    cellData,
    customData,
    onDuplicatePress,
    onRowPress,
    parentRow,
  }) => {
    // in order for custom actions to show
    // the value of the field actions must be ['custom']
    const { renderCustomActionComponent } = customData;
    return (
      <ECActionsTableCell
        key={elementKey}
        actions={cellData}
        onEditClick={() => onRowPress?.(row!)}
        onDuplicateClick={() => onDuplicatePress?.(row!)}
        customActionComponent={renderCustomActionComponent?.(row!, parentRow)}
      />
    );
  },
  [ColType.Attachments]: ({
    elementKey,
    row,
    cellData,
    onRowPress,
    onDuplicatePress,
    parentRow,
    customData,
  }) => {
    // in order for custom actions to show
    // the value of the field actions must be ['custom']
    const { renderCustomAttachmentComponent } = customData;
    return (
      <ECActionsTableCell
        key={elementKey}
        actions={cellData}
        onEditClick={() => onRowPress?.(row!)}
        onDuplicateClick={() => onDuplicatePress?.(row!)}
        customActionComponent={renderCustomAttachmentComponent?.(
          row!,
          parentRow,
        )}
      />
    );
  },
  [ColType.RemoveButton]: ({ elementKey, rowIndex, onRemove, t }) => {
    return (
      <ECTableCell key={elementKey} scope="row" sx={{ minWidth: '10px' }}>
        <ECButton onClick={() => onRemove?.(rowIndex!)} variant="text">
          {t?.('translation:dynamicForm.remove')}
        </ECButton>
      </ECTableCell>
    );
  },
  [ColType.Select]: ({ elementKey, row, col }) => {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        {row?.[col.fieldName]?.[col.dataPath]}
      </ECTableCell>
    );
  },
  [ColType.Currency]: ({ elementKey, cellData, col, index }) => {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '150px', ...col.optionalSx }}
      >
        <ECCurrencyField
          id={`${elementKey}-${index}`}
          value={cellData as number}
          readOnly
          InputProps={{
            disableUnderline: true,
          }}
        />
      </ECTableCell>
    );
  },
  [ColType.WarrantyBadge]: ({ elementKey, cellData, col }) => {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECWarrantyBadge warrantyBadgeType={cellData} showExpiredText />
      </ECTableCell>
    );
  },
  [ColType.LinearProgress]: ({
    elementKey,
    cellData,
    col,
    row,
    customData,
  }) => {
    const { conditionalLinearProgressColor } = customData;
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '250px', ...col.optionalSx }}
      >
        <ECBox sx={{ display: 'flex', alignItems: 'center' }}>
          <ECBox sx={{ width: '100%', mr: 1 }}>
            {renderLinearProgress(
              cellData,
              row,
              conditionalLinearProgressColor,
            )}
          </ECBox>
          <ECBox sx={{ minWidth: 35 }}>
            <ECTypography variant="body2" color="text.secondary">{`${Math.round(
              cellData,
            )}%`}</ECTypography>
          </ECBox>
        </ECBox>
      </ECTableCell>
    );
  },
  [ColType.SingleChip]: ({ elementKey, row, col, cellData }) => {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '150px', ...col.optionalSx }}
      >
        <ECTooltip arrow title={col.showTooltip ? row?.tooltipContent : ''}>
          <ECChip
            label={cellData?.label || cellData?.name || ''}
            sx={{
              bgcolor: theme =>
                theme.palette[cellData.severity || 'error']
                  .outlinedHoverBackground,
              color: theme =>
                theme.palette[cellData.severity || 'error'].dark || 'black',
            }}
            variant="filled"
          />
        </ECTooltip>
      </ECTableCell>
    );
  },
  [ColType.TechAssigned]: ({ elementKey, col, cellData }) => {
    return (
      <ECTableCell
        key={elementKey}
        scope="row"
        sx={{ minWidth: '150px', ...col.optionalSx }}
      >
        {cellData ? (
          <ECTypography>{cellData}</ECTypography>
        ) : (
          <ECChip
            label="Tech Not Assigned"
            sx={{
              bgcolor: theme => theme.palette.error.outlinedHoverBackground,
              color: theme => theme.palette.error.dark,
            }}
            variant="filled"
          />
        )}
      </ECTableCell>
    );
  },
} as ITableCellFieldMap;
