import { DialogContent } from '@mui/material';
import { setActiveFilter, setSnackbar } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { useEffect, useMemo, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { isEmptyValue } from 'utils/common';
import { useTranslation } from 'react-i18next';
import { ECSimpleSearch } from './ECSimpleSearch';
import { SearchOutlined } from '@mui/icons-material';
import { ECSaveFilter, SaveError } from 'app/components/ECSaveFilter';
import { ECIconButton } from 'app/components/ECIconButton';
import { ECBadge } from 'app/components/ECBadge';
import { ECBox } from 'app/components/ECBox';
import { ECTypography } from 'app/components/ECTypography';
import { ECEasyInputTags } from 'app/components/ECEasyInputTags';
import { ECButton } from 'app/components/ECButton';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { ECDialog } from 'app/components/ECDialog';
import { ECSearchForm } from 'app/components/ECDynamicForm';
import {
  useGetAllSavedColumnsFiltersQuery,
  useGetAllSavedFiltersQuery,
} from 'services/filterApi';
import { sortColumns } from 'utils/core/table';

const MAX_ADVANCED_FILTERS_AMOUNT = 20;

interface ECAdvancedFilterProps {
  showSaveFilterButton?: boolean;
  hideAdvancedSearchIcon?: boolean;
}

export const ECAdvancedFilter: React.FC<ECAdvancedFilterProps> = ({
  showSaveFilterButton,
  hideAdvancedSearchIcon,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const pageState = useSelector((state: RootState) => state.page);

  const endpoint = useMemo(() => {
    return pageState.filter.endpoint;
  }, [pageState]);

  const context = useMemo(() => {
    return pageState.context[window.location.pathname] || {};
  }, [pageState]);

  const activeFilter = useMemo(() => {
    return pageState.filter.activeFilter?.[endpoint];
  }, [pageState]);

  const allSavedFilters = useGetAllSavedFiltersQuery(endpoint, {
    skip: !endpoint,
  });

  const { data: allSavedColumnsFilters } = useGetAllSavedColumnsFiltersQuery(
    endpoint,
    {
      skip: !endpoint,
    },
  );

  const tableLayoutFilter = useMemo(
    () => allSavedColumnsFilters?.[0],
    [allSavedColumnsFilters],
  );

  const { hiddenColumns, visibleColumns } = useMemo(() => {
    return {
      visibleColumns: sortColumns(context?.columns, tableLayoutFilter, true),
      hiddenColumns: context?.columns?.filter(col => !col.visible) || [],
    };
  }, [tableLayoutFilter, context]);

  const [openSaveFilterDialog, setOpenSaveFilterDialog] =
    useState<boolean>(false);

  const [isErrorSaveFilter, setIsErrorSaveFilter] = useState<SaveError>({
    isError: false,
    errorMessage: '',
  });

  const [isSuccessSaveFilter, setIsSuccessSaveFilter] =
    useState<boolean>(false);

  useEffect(() => {
    if (isErrorSaveFilter.isError) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: isErrorSaveFilter.errorMessage,
          onClose: () => {
            setIsErrorSaveFilter({ isError: false, errorMessage: '' });
          },
        }),
      );
    }
  }, [isErrorSaveFilter.isError, isErrorSaveFilter.errorMessage, dispatch]);

  useEffect(() => {
    if (isSuccessSaveFilter) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: t('translation:dynamicForm.saveSuccess'),
        }),
      );
    }
  }, [isSuccessSaveFilter, dispatch, t]);

  const [openSearch, setOpenSearch] = useState<boolean>(false);

  const isUsingAdvancedSearch = useMemo(() => {
    return activeFilter?.isAdvanced;
  }, [activeFilter]);

  const activeFilterFields = useMemo(() => {
    if (!isUsingAdvancedSearch) return [];
    return activeFilter?.filterFields;
  }, [activeFilter, isUsingAdvancedSearch]);

  const showFilterName = useMemo(
    () =>
      activeFilterFields &&
      activeFilterFields.length > 0 &&
      !isEmptyValue(activeFilter?.name),
    [activeFilter, activeFilterFields],
  );
  return (
    <>
      {!hideAdvancedSearchIcon && (
        <ECIconButton onClick={() => setOpenSearch?.(true)}>
          <ECBadge
            color="error"
            variant="dot"
            invisible={!activeFilterFields?.length}
          >
            <FilterAltIcon />
          </ECBadge>
        </ECIconButton>
      )}

      <ECBox
        borderColor={theme => theme.palette.action.active}
        sx={{
          tabIndex: '0',
          display: 'flex',
          width: '440px',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: 0,
          borderBottom: isUsingAdvancedSearch
            ? '1px solid rgba(0, 0, 0, 0.42)'
            : undefined,
          '&:focus, &:hover': theme => ({
            borderColor: theme.palette.action.selected,
          }),
          p: 0.5,
        }}
      >
        {showFilterName && (
          <ECTypography
            variant="h6"
            color={theme => theme.palette.text.secondary}
          >
            {activeFilter?.name && activeFilter?.name?.length > 25
              ? activeFilter?.name?.substring(0, 25) + '...'
              : activeFilter?.name}
          </ECTypography>
        )}
        {!isUsingAdvancedSearch ? (
          <ECSimpleSearch />
        ) : (
          <>
            <ECEasyInputTags
              tags={activeFilterFields}
              defaultSearchFieldName={
                context?.columns?.find(col => col.searchable)?.alias ||
                context?.columns?.find(col => col.searchable)?.name
              }
              defaultSearchFieldLabel={
                context?.columns?.find(col => col.searchable)?.title
              }
              selectedTags={selectedTags => {
                dispatch(
                  setActiveFilter({
                    filterFields: selectedTags,
                    isEdited: true,
                    isAdvanced: !!selectedTags?.length,
                    filterPath: endpoint,
                  }),
                );
              }}
            />
            {showSaveFilterButton && (
              <ECButton
                type="button"
                variant="text"
                sx={{ height: 42 }}
                onClick={() => {
                  if (
                    (allSavedFilters.data?.filter(
                      filter =>
                        !filter?.body?.some(field => field.isTableLayout),
                    )?.length ?? 0) >= MAX_ADVANCED_FILTERS_AMOUNT
                  ) {
                    setIsErrorSaveFilter({
                      isError: true,
                      errorMessage:
                        'you have reached the maximum number of saved filters',
                    });
                  } else {
                    setOpenSaveFilterDialog(true);
                  }
                }}
              >
                Save as
              </ECButton>
            )}
            <ECButton
              variant="contained"
              sx={{ height: '40px', width: '40px', minWidth: '40px' }}
            >
              {context?.isLoadingRequest ? (
                <ECCircularProgress
                  size="24px"
                  sx={{
                    color: theme => theme.palette.background.paper,
                  }}
                />
              ) : (
                <SearchOutlined />
              )}
            </ECButton>
          </>
        )}
      </ECBox>
      <ECDialog
        open={openSaveFilterDialog}
        container={() =>
          document.getElementsByTagName('main')[0] ?? document.body
        }
        sx={{
          position: 'absolute',
        }}
        onClose={() => setOpenSaveFilterDialog(false)}
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          variant: 'outlined',
          sx: { alignSelf: 'start', top: '100px' },
        }}
      >
        <DialogContent>
          <ECSaveFilter
            filterEndPoint={endpoint}
            filters={activeFilterFields ?? []}
            onClose={() => setOpenSaveFilterDialog(false)}
            onSuccessSavefilter={setIsSuccessSaveFilter}
            onErrorSaveFilter={setIsErrorSaveFilter}
          />
        </DialogContent>
      </ECDialog>
      {openSearch && context?.columns && (
        <ECSearchForm
          open={openSearch}
          filterEndPoint={endpoint}
          cols={[...visibleColumns, ...hiddenColumns]}
          onClose={() => setOpenSearch(false)}
          shouldPopulateWithActiveFilter
          shouldUseFilterPath
        />
      )}
    </>
  );
};
