import { ECBox } from 'app/components/ECBox';
import { ECTypography } from 'app/components/ECTypography';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { themes } from 'styles/theme/themes';
import { StyleConstants } from 'styles/StyleConstants';
import { ECEasyFormCreate, ECImage, ECLink } from 'app/components';
import { useGetSupplierByIdQuery } from 'services/supplierApi';
import { DocumentCoreTypes } from 'types/Attachment';
import { Language } from '@mui/icons-material';
import { SupplierContactInfo } from './supplier-info-modal';
import { CostTableData } from 'app/components/ECWorkflowCostTable';
import { useCreateInvoiceMutation } from 'services/invoiceApi';
import { isoDateWithTimezoneAtZero } from 'utils/strings/formatDate';
import { useDispatch } from 'react-redux';
import { purchaseOrderApi } from 'services/purchaseOrderApi';
import { formatPhone } from 'utils/strings/phone';
import { buildAddress } from 'utils/address';
import { useNavigate } from 'react-router';

const fancyFormElementsCreate = require('./fancy_form_config_create.json');

interface EditProps {
  purchaseOrder: any;
  newPO: any;
  onClose?: () => void;
  onChangeIdForSnackBar?: (val: string, id: string) => void;
}

export const NewPOInvoiceForm = ({
  purchaseOrder,
  newPO,
  onClose,
  onChangeIdForSnackBar,
}: EditProps) => {
  const dispatch = useDispatch();

  const { data: supplier, isLoading: isLoadingSupplier } =
    useGetSupplierByIdQuery(Number(purchaseOrder?.supplier?.id));

  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsCreate.fields,
  );

  useLayoutEffect(() => {
    const supplierLogoUrl = supplier?.attachments?.find(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.SupplierProfilePhoto,
    )?.thumbnailURL;

    const supplierHeaderField = formFields.find(
      field => field.fieldName === 'supplierHeader',
    );
    supplierHeaderField.value = (
      <ECBox display="flex" flexDirection="column" gap={2}>
        <ECBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <ECBox display="flex" gap={1} alignItems="center">
            <ECImage
              sx={{
                width: '100px',
                height: '50px',
                objectFit: 'cover',
                mr: 2,
              }}
              alt="supplier logo"
              src={supplierLogoUrl}
            />

            <ECTypography fontWeight="bold">{supplier?.name}</ECTypography>
          </ECBox>

          <ECLink
            id="hyperlink"
            target="_blank"
            href={
              supplier?.website
                ? supplier?.website.startsWith('http://') ||
                  supplier?.website.startsWith('https://')
                  ? supplier?.website
                  : `http://${supplier?.website}`
                : ''
            }
          >
            <Language sx={{ marginRight: 1 }} /> WEBSITE
          </ECLink>
        </ECBox>

        <ECBox display="flex" flexDirection="column" gap={1} p={2}>
          <ECBox display="flex" gap={1}>
            <ECTypography variant="body2" fontWeight="bold">
              Phone:{' '}
            </ECTypography>
            <ECTypography variant="body2">
              {formatPhone(supplier?.phone, supplier?.address?.countryCode)}
            </ECTypography>
          </ECBox>

          <ECBox display="flex" gap={1}>
            <ECTypography variant="body2" fontWeight="bold">
              Email:{' '}
            </ECTypography>
            <ECTypography variant="body2">{supplier?.email}</ECTypography>
          </ECBox>

          <ECBox display="flex" gap={1}>
            <ECTypography variant="body2" fontWeight="bold">
              Address:{' '}
            </ECTypography>
            <ECTypography variant="body2">
              {buildAddress(supplier?.address)}
            </ECTypography>
          </ECBox>
        </ECBox>
      </ECBox>
    );

    const supplierContactsField = formFields.find(
      field => field.fieldName === 'supplierContacts',
    );
    supplierContactsField.value = (
      <ECBox display="flex" flexDirection="column" gap={1} mb={1} px={2}>
        {supplier?.contacts?.map(contact => (
          <SupplierContactInfo
            key={contact.id}
            supplierCountryCode={supplier?.address?.countryCode}
            {...contact}
          />
        ))}
      </ECBox>
    );

    const invoiceDateField = formFields
      .find(field => field.groupName === 'invoiceNumberAndDate')
      ?.subFields?.find(field => field?.fieldName === 'invoiceDate');
    invoiceDateField.required =
      !!purchaseOrder?.invoiceRequiredFields?.invoiceDateRequired;

    const attachmentsField = formFields.find(
      field => field.fieldName === 'files',
    );
    attachmentsField.required =
      !!purchaseOrder?.invoiceRequiredFields?.invoiceDocumentRequired;

    setFormFields([...formFields]);
  }, [supplier, purchaseOrder]);

  const costTableData = useMemo(
    (): CostTableData => ({
      itemsRepair: [
        {
          label: 'Freight',
          dataPath: 'costFreight',
        },
        {
          label: 'Tax',
          dataPath: 'costTax',
        },
        {
          label: 'Other',
          dataPath: 'costOther',
        },
        {
          label: 'Purchase Order Items',
          value: purchaseOrder?.purchaseOrderItems?.map(item => {
            return {
              ...item,
              subTotal: item?.costPerUnit * (item?.quantityReceived || 0),
            };
          }),
          dataPath: 'purchaseOrderItems',
          hidden: true,
        },
      ],
    }),
    [purchaseOrder?.purchaseOrderItems],
  );

  const [doCreate, { data, isError, error, isSuccess, reset, isLoading }] =
    useCreateInvoiceMutation();

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(purchaseOrderApi.util.invalidateTags(['PurchaseOrderById']));
      onChangeIdForSnackBar && onChangeIdForSnackBar('PO invoice', newPO?.id);
    }
  }, [isSuccess, data, newPO]);

  const submit = useCallback(() => {
    const doSubmit = async data => {
      const params: any = {
        requestId: purchaseOrder?.requestId,
        invoiceNumber: data.invoiceNumber,
        description: data.description,
        costFreight: data.costFreight,
        costOther: data.costOther,
        costTax: data.costTax,
        invoiceDate: data.invoiceDate
          ? isoDateWithTimezoneAtZero(data.invoiceDate)
          : undefined,
        purchaseOrderItems: JSON.stringify(
          data.purchaseOrderItems.map(item => ({
            id: item?.inventoryItemId,
            price: item.costPerUnit,
          })),
        ),
      };

      if (data.files) {
        params.files = data.files.map(
          file => file.rawFile ?? file.processedFile ?? file,
        );
      }

      doCreate?.(params);
    };

    return [doSubmit, { data, isError, error, isSuccess, reset, isLoading }];
  }, [
    data,
    isError,
    error,
    isSuccess,
    reset,
    isLoading,
    purchaseOrder?.requestId,
    doCreate,
  ]);

  return (
    <ECBox
      display="flex"
      position="relative"
      height="100%"
      flexDirection="column"
    >
      <ECBox
        display="flex"
        justifyContent="start"
        px={4}
        py={2}
        height={StyleConstants.DRAWER_TITLE_HEIGHT}
        bgcolor={themes.light.palette.other.divider}
      >
        <ECTypography variant="h4">Create Invoice</ECTypography>
      </ECBox>

      <ECEasyFormCreate
        useCreateMutation={submit}
        hideToolBar
        showTitle={false}
        formConfig={fancyFormElementsCreate.config}
        formFields={formFields}
        onClose={onClose}
        costTableData={costTableData}
        showSuccessSnackBar={false}
      />
    </ECBox>
  );
};
