import { useEffect, useState } from 'react';
import { ECButton } from '../ECButton';
import { ECChip } from '../ECChip';
import { ECChipAutocomplete } from '../ECChipAutocomplete';
import { ECGrid } from '../ECGrid';
import { ECTextField } from '../ECTextField';
import { FieldTypes } from '../ECForm';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import RemoveIcon from '@mui/icons-material/Remove';
import ECCurrencyField from '../ECCurrencyField';
import { ECInputTextField } from '../ECTextField/InputTextField';

export interface RowTextFieldValues {
  id: number;
  label: string;
  name: string;
  quantityOnHand?: number;
  costPerUnit?: string;
  bin?: number;
  lot?: number;
}

interface ECStorageLocationsProps {
  onChange?: (value: RowTextFieldValues[]) => void;
  value?: RowTextFieldValues[] | null;
  required?: boolean;
  validationMessage?: string;
  label?: string;
  placeholder?: string;
  options?: any[];
}

export const ECStorageLocations: React.FC<ECStorageLocationsProps> = ({
  onChange,
  required,
  validationMessage,
  placeholder,
  label,
  options,
  value,
}) => {
  const { t } = useTranslation();
  const [selectedStgLoc, setSelectedStgLoc] = useState<RowTextFieldValues[]>(
    [],
  );

  const handleTextFieldChange = (index, field, inputValue) => {
    const updatedValues = [...selectedStgLoc];
    updatedValues[index] = {
      ...updatedValues[index],
      [field]: inputValue,
    };
    setSelectedStgLoc(updatedValues);
    onChange?.(updatedValues);
  };

  const handleChipAutocompleteChange = newValue => {
    if (!Array.isArray(newValue)) {
      return;
    }
    const transformedValues = newValue.map(chip => ({
      id: chip.id,
      label: chip.label,
      name: chip.name,
      quantityOnHand: chip.quantityOnHand || 0,
      costPerUnit: chip.costPerUnit,
      bin: chip.bin,
      lot: chip.lot,
    }));
    setSelectedStgLoc(transformedValues);
  };

  useEffect(() => {
    onChange?.(selectedStgLoc);
  }, [selectedStgLoc, setSelectedStgLoc]);

  return (
    <>
      <ECChipAutocomplete
        loading={false}
        options={options ? options : []}
        placeholder={placeholder}
        title={label}
        values={selectedStgLoc}
        variant={'filled'}
        onChange={handleChipAutocompleteChange}
      />
      {selectedStgLoc?.map((row, index) => {
        return (
          <ECGrid container spacing={1} sx={{ marginTop: 1 }} key={index}>
            <ECGrid item xs={2}>
              <ECChip
                label={row?.label}
                variant="outlined"
                sx={{ flex: '0 0 auto', marginTop: 2, width: '100%' }}
              />
            </ECGrid>
            <ECGrid item xs={2}>
              <ECTextField
                key={`quantityOnHand_${row.id}`}
                id={`quantityOnHand_${row.id}`}
                name={`quantityOnHand_${row.id}`}
                variant="filled"
                sx={{ width: '100%' }}
                label="Quantity on Hand"
                placeholder="Quantity on Hand"
                value={row?.quantityOnHand}
                onChange={event => {
                  const clearedValue = event.target.value.replace(
                    /[^0-9]/g,
                    '',
                  );
                  handleTextFieldChange(
                    index,
                    'quantityOnHand',
                    clearedValue !== '' ? Number(clearedValue) : clearedValue,
                  );
                }}
                error={Number(row?.quantityOnHand) < 1 && !!validationMessage}
              />
            </ECGrid>
            <ECGrid item xs={2.5}>
              <ECCurrencyField
                id={`costPerUnit_${row.id}`}
                label="Cost per Unit"
                placeholder="Cost per Unit"
                value={row?.costPerUnit}
                onChange={(event, value) => {
                  handleTextFieldChange(
                    index,
                    'costPerUnit',
                    value?.toString().includes('.') ||
                      value?.toString() === '0' ||
                      value?.toString() === ''
                      ? value
                      : Number(value),
                  );
                }}
                error={
                  (!row?.costPerUnit ||
                    row?.costPerUnit === '' ||
                    Number(row?.costPerUnit) < 0) &&
                  !!validationMessage
                }
                variant="filled"
              />
            </ECGrid>
            <ECGrid item xs={2}>
              <ECInputTextField
                fieldName={`bin_${row.id}`}
                sx={{ width: '100%' }}
                label="bin #"
                placeholder="bin #"
                type={FieldTypes.Text}
                value={row?.bin || ''}
                onChange={value => {
                  handleTextFieldChange(index, 'bin', value);
                }}
              />
            </ECGrid>
            <ECGrid item xs={2}>
              <ECInputTextField
                fieldName={`lot_${row.id}`}
                sx={{ width: '100%' }}
                variant="filled"
                label="lot #"
                placeholder="lot #"
                type={FieldTypes.Text}
                value={row?.lot || ''}
                onChange={value => {
                  handleTextFieldChange(index, 'lot', value);
                }}
              />
            </ECGrid>
            <ECGrid item xs={1.5}>
              <ECButton
                variant="text"
                color="error"
                startIcon={<RemoveIcon color="error" />}
                onClick={() => {
                  setSelectedStgLoc(prevStgLoc =>
                    prevStgLoc.filter(item => item.id !== row.id),
                  );
                }}
                sx={{
                  marginTop: 2,
                }}
              >
                Remove
              </ECButton>
            </ECGrid>
          </ECGrid>
        );
      })}
    </>
  );
};
