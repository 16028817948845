import {
  ECBox,
  ECButton,
  ECDynamicPageTemplate,
  ECIconButton,
  ECTypography,
} from 'app/components';
import {
  useLazyGetAvtLocationsListQuery,
  useEnableAvtLocationsMutation,
  useDisableAvtLocationsMutation,
} from 'services/avtApi';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Popover } from '@mui/material';
import { CheckOutlined } from '@mui/icons-material';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { useDispatch } from 'react-redux';
import { setActiveFilter, setSnackbar } from 'store/slice/page';

import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';

const formAddLocations = require('./form_add_locations.json');

export function AssetVerificationToolPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [row, setSelectedRow] = useState<any>(null);

  const handleCloseModal = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const renderActions = (row: any) => {
    return (
      <ECBox>
        <ECButton
          variant="contained"
          onClick={() => {
            if (activeFilter?.simpleSearchQuery) {
              dispatch(
                setActiveFilter({
                  simpleSearchQuery: '',
                  filterFields: [],
                }),
              );
            }
            navigate('../avt-assets/' + row.id, { state: { row } });
          }}
          key={`${row.id}-view-assets`}
          sx={{ marginRight: '8px' }}
        >
          {t('translation:pages.avt.viewAssets')}
        </ECButton>
        <ECIconButton
          type="default"
          key="avt-complete-verification"
          withBorder={true}
          sx={{ color: 'green', borderColor: 'green' }}
          onClick={e => {
            setAnchorEl(e.currentTarget);
            setSelectedRow(row);
          }}
        >
          <CheckOutlined />
        </ECIconButton>
      </ECBox>
    );
  };

  const [
    doCreate,
    {
      isError: isErrorCreate,
      error: errorCreate,
      isLoading: isLoadingCreate,
      isSuccess: isSuccessCreate,
      reset: resetCreate,
    },
  ] = useEnableAvtLocationsMutation();

  const useCreateMutation = useCallback(() => {
    const doCreateAssetName = async createData => {
      const body = {
        branchIds: createData?.branchIds
          ?.split(',')
          ?.filter(x => x)
          ?.map(x => +x),
      };
      doCreate(body);
    };

    return [
      doCreateAssetName,
      {
        isError: isErrorCreate,
        error: errorCreate,
        isLoading: isLoadingCreate,
        isSuccess: isSuccessCreate,
        reset: resetCreate,
      },
    ];
  }, [
    isSuccessCreate,
    doCreate,
    errorCreate,
    isErrorCreate,
    isLoadingCreate,
    resetCreate,
  ]);

  const [
    doDisableAvt,
    {
      error: disableAvtError,
      isError: isDisableAvtError,
      isLoading: isDisableAvtLoading,
      isSuccess: isDisableAvtSuccess,
      reset: resetUpdateEta,
    },
  ] = useDisableAvtLocationsMutation();

  useEffect(() => {
    if (isDisableAvtSuccess && !isDisableAvtLoading) {
      handleCloseModal();
      resetUpdateEta();
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Location verification completed successfully',
        }),
      );
    }
  }, [isDisableAvtSuccess, isDisableAvtLoading]);

  useEffect(() => {
    if (isDisableAvtError && disableAvtError) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: (disableAvtError as any)?.data?.message,
        }),
      );
    }
  }, [disableAvtError, isDisableAvtError]);

  const handleSaveEta = (id: any) => () => {
    doDisableAvt({ branchId: id });
  };

  return (
    <>
      <ECDynamicPageTemplate
        shouldCacheLazyQuery
        pageTitle={'Asset Verification'}
        useLazyGetListQuery={useLazyGetAvtLocationsListQuery}
        showStatusActiveFilter={true}
        useCreateMutation={useCreateMutation}
        createFormConfig={formAddLocations.config}
        createFormFields={formAddLocations.fields}
        renderCustomActionComponent={renderActions}
        withDrawer={false}
        shouldNotUseActiveFilter
      />
      <Popover
        id="avt-complete-verification"
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={handleCloseModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ECBox p={1} bgcolor="transparent" mt={-1}>
          <ECBox
            sx={theme => ({
              borderRadius: 1,
              boxShadow: 2,
              maxWidth: '444px',
              bgcolor: theme.palette.common.white,
            })}
          >
            <ECBox display="flex" flexDirection="column" p={2}>
              <ECTypography variant="h6" px={1}>
                Are you sure you want to complete the verification for location
                with ID {row?.id}? All pending assets will be removed from AVT.
              </ECTypography>
              <ECBox mt={2} display="flex" gap={2} justifyContent="flex-end">
                <ECButton variant="text" onClick={handleCloseModal}>
                  Cancel
                </ECButton>
                <ECButton variant="contained" onClick={handleSaveEta(row?.id)}>
                  {isDisableAvtLoading ? (
                    <ECCircularProgress
                      size={'2rem'}
                      sx={{ color: 'background.paper' }}
                    />
                  ) : (
                    'Confirm'
                  )}
                </ECButton>
              </ECBox>
            </ECBox>
          </ECBox>
        </ECBox>
      </Popover>
    </>
  );
}
