import { ArrowDropDown } from '@mui/icons-material';
import { IconButton, InputAdornment, Stack } from '@mui/material';
import {
  ECBox,
  ECButton,
  ECChip,
  ECIconButton,
  ECInfiniteScroll,
  ECList,
  ECListItemButton,
  ECListItemText,
  ECTextField,
  ECTypography,
  TreeNode,
} from 'app/components';
import { ECPopover } from 'app/components/ECPopover';
import { RootState } from 'store/configureStore';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetAllCompanyUsersQuery } from 'services/userApi';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { setActiveFilter } from 'store/slice/page';
import { ECCheckbox } from '../ECCheckbox';
import { debounce } from 'lodash';
import { QueryParams } from 'types/QueryParams';

const LABEL_MAX_LENGTH = 24;
const DEBOUNCE_TIME = 800;

type ECUserTableFilterProps = {
  onChange?: (items: TreeNode[]) => void;
  maxSelection?: number;
};

export function ECUserTableFilter({
  onChange,
  maxSelection,
}: ECUserTableFilterProps) {
  const dispatch = useDispatch();

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const { data: userProfile } = useGetUserProfileQuery();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    t: 20,
    p: 0,
    sb: 'usr.firstName',
    companyId: userProfile?.company?.id ?? 0,
  });

  const [anchorElHierarchy, setAnchorElHierarchy] =
    useState<HTMLDivElement | null>(null);

  const hierarchyRef = useRef(null);

  const inputChip = useMemo(() => {
    // if none or all, text should be ALL
    if (!activeFilter?.users?.length) {
      return <ECChip sx={{ width: '48px', height: '24px', }} label="All" color="Light Grey" />;
    }

    return <ECChip sx={{ width: '48px', height: '24px' }}  label={activeFilter?.users?.length} color="Dark Blue" />;
  }, [activeFilter?.users]);

  const handleClickHierarchy = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      setAnchorElHierarchy(hierarchyRef?.current);
    },
    [],
  );
  const handleCloseHierarchy = useCallback(() => {
    setAnchorElHierarchy(null);
    setSearchTerm('');
  }, []);

  const debouncedHandleSearchChange = useCallback(
    debounce(newValue => {
      setSearchTerm(newValue);
    }, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    const users = selectedUsers.map(u => ({
      nodeId: u.id,
      label: u.name,
    })) as TreeNode[];
    dispatch(setActiveFilter({ users }));
    onChange?.(users);
  }, [onChange, selectedUsers]);

  const handleClickItem = useCallback(
    (e: any, user: { id: number; name: string }) => {
      setSelectedUsers(users => {
        if (!users.some(u => u.id === user.id)) {
          if (users.length >= (maxSelection || 10)) return users;
          return [...users, user];
        } else {
          return [...users.filter(u => u.id !== user.id)];
        }
      });
    },
    [setSelectedUsers],
  );

  const renderItem = useMemo(
    () => (user: { id: number; name: string }, index: any) => {
      const isSelected = selectedUsers.some(u => u.id === user.id);
      return (
        <ECListItemButton key={index}>
          <ECCheckbox
            size="medium"
            onChange={e => handleClickItem(e, user)}
            defaultChecked={selectedUsers.some(u => u.id === user.id)}
            disabled={
              isSelected ? false : (maxSelection || 10) <= selectedUsers?.length
            }
          />
          <ECListItemText primary={user.name} />
        </ECListItemButton>
      );
    },
    [selectedUsers],
  );

  const unselectAll = useCallback(() => {
    setSelectedUsers([]);
    setQueryParams({ ...queryParams });
  }, [selectedUsers, queryParams]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      width="200px"
      height="40px"
      border="1px solid #E0E0E0"
      borderRadius="4px"
      display='flex'
      justifyContent='space-around'
    >
      <ECTypography
        color={theme => theme.palette.text.secondary}
        variant="caption"
      >
        Users: 
      </ECTypography>
      <ECBox display="flex" alignItems='center' onClick={handleClickHierarchy} ref={hierarchyRef}>
        {inputChip}  
        <ECIconButton
          type="default"
          size="small"
          noPadding
          sx={{ marginLeft: '5px' }}
        >
          <ArrowDropDown />
        </ECIconButton>
      </ECBox>
      <ECPopover
        id={'hierarchy-popover'}
        open={Boolean(anchorElHierarchy)}
        anchorEl={anchorElHierarchy}
        onClose={handleCloseHierarchy}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          bgcolor: 'transparent',
          '& .MuiPopover-paper': {
            minWidth: '350px',
            bgcolor: 'transparent',
          },
        }}
      >
        <ECBox
          bgcolor="white !important"
          height="auto"
          width="350px"
          overflow="auto"
          p={1}
        >
          <ECTextField
            fullWidth
            label="Search"
            variant="filled"
            onChange={e => debouncedHandleSearchChange(e.target.value)}
            sx={{
              marginBottom: '10px',
            }}
          />
          <ECBox
            display="flex"
            justifyContent={'space-between'}
            alignItems="center"
            px={1}
          >
            <ECButton variant="text" onClick={unselectAll}>
              Unselect All
            </ECButton>
            {maxSelection && (
              <ECTypography variant="caption">
                {selectedUsers?.length} / {maxSelection}
              </ECTypography>
            )}
          </ECBox>
          <ECInfiniteScroll
            queryFn={useLazyGetAllCompanyUsersQuery}
            ContainerComponent={ECList}
            containerProps={{ noPadding: true }}
            queryParams={queryParams}
            renderItem={renderItem}
            wrapperProps={{
              height: '350px',
            }}
            searchParam={searchTerm}
          />
        </ECBox>
      </ECPopover>
    </Stack>
  );
}
