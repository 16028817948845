// Need to use the React-specific entry point to import createApi
import { Locations } from '../types';
import { BaseType } from 'types/BaseType';

import { emptyApi } from './emptyApi';
import { QueryParams } from 'types/QueryParams';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Location'],
});

// Define a service using a base URL and expected endpoints
export const locationApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getLocations: builder.query<BaseType<Locations[]>, QueryParams>({
      query: params => {
        return {
          url: 'branch',
          params,
        };
      },
      providesTags: ['Location'],
      keepUnusedDataFor: 300,
    }),
    getSPLocations: builder.query<BaseType<Locations[]>, QueryParams>({
      query: params => {
        return {
          url: 'branch/sp',
          params,
        };
      },
      providesTags: ['Location'],
    }),
    addLocation: builder.mutation({
      query: body => {
        return {
          url: 'branch',
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['Location'],
    }),
    editLocation: builder.mutation({
      query: body => {
        const reduced = {
          name: body.name,
          status: body.status,
        };
        return {
          url: `branch/${body.id}`,
          method: 'put',
          body: reduced,
        };
      },
      invalidatesTags: ['Location'],
    }),
    deleteLocation: builder.mutation({
      query: body => {
        const reduced = {
          locationCategoryId: body.locationCategoryId,
          name: body.name,
          status: body.status,
        };
        return {
          url: 'branch',
          method: 'delete',
          body: reduced,
        };
      },
      invalidatesTags: ['Location'],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useLazyGetLocationsQuery,
  useGetSPLocationsQuery,
  useLazyGetSPLocationsQuery,
  useAddLocationMutation,
  useEditLocationMutation,
  useDeleteLocationMutation,
} = locationApi;
