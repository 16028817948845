import { ECMenuItem, ECSelect } from '../ECSelect';
import { ECFormControl } from '../ECForm';
import { InputLabel, SelectChangeEvent } from '@mui/material';
import {
  CountryCodeOption,
  getCountryCodeFromPhone,
  prependCountryCodeToPhone,
  removeCountryCodeFromPhone,
  stripPhoneNumber,
  SUPPORTED_PHONE_COUNTRY_CODES,
} from 'utils/strings/phone';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import * as Flags from './assets/icons';
type CountryDropdownProps = {
  phoneNumber: string;
  initialPhoneCountryCodeOption?: CountryCodeOption;
  handlePhoneChange: (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>,
  ) => void;
};
export const CountryDropdown: React.FC<CountryDropdownProps> = ({
  phoneNumber,
  initialPhoneCountryCodeOption,
  handlePhoneChange,
}) => {
  const options = SUPPORTED_PHONE_COUNTRY_CODES;
  const phoneCountryCode = getCountryCodeFromPhone(phoneNumber);
  const shouldValidateDefaultCountryCode = useRef(true);

  const [selectedCountryCode, setSelectedCountryCode] = useState<
    (typeof options)[0]
  >(
    initialPhoneCountryCodeOption ??
      (options.find(option => option.countryCode === phoneCountryCode) ||
        options[0]),
  );

  const handleChange = (e: SelectChangeEvent<string>) => {
    const newCountryCode = e.target.value;
    setSelectedCountryCode(
      options.find(option => option.countryCode === newCountryCode) ||
        options[0],
    );
    const newPhoneNumber = prependCountryCodeToPhone(
      removeCountryCodeFromPhone(phoneNumber),
      newCountryCode,
      false,
    );
    e.target.value = newPhoneNumber;
    handlePhoneChange(e);
  };

  useEffect(() => {
    if (
      phoneNumber.indexOf('+') === -1 &&
      stripPhoneNumber(removeCountryCodeFromPhone(phoneNumber))?.length ===
        10 &&
      !initialPhoneCountryCodeOption
    ) {
      const fakeEvent = {
        target: {
          value: 'US',
        },
      };
      handleChange(fakeEvent as ChangeEvent<HTMLInputElement>);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (
      phoneNumber &&
      shouldValidateDefaultCountryCode.current &&
      phoneCountryCode !== selectedCountryCode?.countryCode &&
      !initialPhoneCountryCodeOption
    ) {
      const fakeEvent = {
        target: {
          value: phoneCountryCode,
        },
      };
      handleChange(fakeEvent as ChangeEvent<HTMLInputElement>);
      shouldValidateDefaultCountryCode.current = false;
    }
  }, [phoneNumber]);

  return (
    <ECFormControl sx={{ width: '220px' }}>
      <InputLabel variant="filled">Country Code</InputLabel>
      <ECSelect
        variant="filled"
        value={selectedCountryCode?.countryCode || ''}
        sx={{
          width: '100%',
        }}
        label={'Country Code'}
        name={'Country Code'}
        labelId={'Country Code'}
        placeholder={'Country Code'}
        onChange={e => handleChange(e as SelectChangeEvent<string>)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {options.map(option => (
          <ECMenuItem
            key={option.countryCode}
            value={option.countryCode}
            disabled={selectedCountryCode?.countryCode === option.countryCode}
          >
            <CountryDropdownItem
              countryCode={option.countryCode}
              prefix={option.prefix}
            />
          </ECMenuItem>
        ))}
      </ECSelect>
    </ECFormControl>
  );
};

type countryDropdownItemProps = {
  countryCode: string;
  prefix: string;
};
const CountryDropdownItem: React.FC<countryDropdownItemProps> = ({
  countryCode,
  prefix,
}) => {
  const Flag = getCountryFlag(countryCode);
  return (
    <ECBox display="flex" flexDirection="row" gap={1} width="fit-content">
      <Flag width={20} />
      <ECTypography variant="body1">
        {countryCode} ({prefix})
      </ECTypography>
    </ECBox>
  );
};

const getCountryFlag = (countryCode: string) => {
  if (countryCode === 'US') return Flags.US;
  if (countryCode === 'MX') return Flags.MX;
  if (countryCode === 'PL') return Flags.PL;
  return Flags.US;
};
