import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { setFilterEndpoint } from 'store/slice/page';
import { AccountingViewsEnum } from 'types/Accounting';
import { ECDynamicPageTemplate, MAX_SELECTABLE_OPTIONS } from 'app/components';
import { useCustomerUser } from 'app/hooks/customerUser.use-case';
import { ForbiddenPage } from '../ForbiddenPage';
import { useLazyGetAccountingGLCodesQuery } from 'services/accountingApi';
import { useGetBranchesListQuery } from 'services/branchApi';
import { useGetAssetTypesListQuery } from 'services/assetTypeApi';
import { orderBy } from 'lodash';

function DefaultView() {
  const { t } = useTranslation();

  const { data: assetTypes } = useGetAssetTypesListQuery({
    t: 0,
  });

  const assetTypeFilterOptions = useMemo(() => {
    return (
      orderBy(assetTypes?.data, 'name')?.map(assetType => ({
        id: assetType?.id,
        label: assetType?.name,
        fieldName: assetType?.name,
        isInitialSelected:
          assetTypes?.data &&
          assetTypes?.data?.length <= MAX_SELECTABLE_OPTIONS,
      })) || []
    );
  }, [assetTypes?.data]);

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.accounting.title')}
      useLazyGetListQuery={useLazyGetAccountingGLCodesQuery}
      customParams={{
        t: undefined,
      }}
      useCreateMutation={{}}
      marginTop={false}
      withDrawer={false}
      shouldNotUseActiveFilter
      showAssetTypeNameFilter
      assetTypeFilterOptions={assetTypeFilterOptions}
      exportModuleName="accounting"
      enableExport
      enableTableCsvExport
      hidePerPageSelector
      moduleName="glcode"
      shouldCacheLazyQuery
    />
  );
}

function LocationView() {
  const { t } = useTranslation();
  const { data: assetTypes } = useGetAssetTypesListQuery({
    t: 0,
  });

  const assetTypeFilterOptions = useMemo(() => {
    return (
      orderBy(assetTypes?.data, 'name')?.map(assetType => ({
        id: assetType?.id,
        label: assetType?.name,
        fieldName: assetType?.name,
        isInitialSelected:
          assetTypes?.data &&
          assetTypes?.data?.length <= MAX_SELECTABLE_OPTIONS,
      })) || []
    );
  }, [assetTypes?.data]);

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.accounting.title')}
      useLazyGetListQuery={useLazyGetAccountingGLCodesQuery}
      customParams={{
        t: undefined,
      }}
      useCreateMutation={{}}
      marginTop={false}
      withDrawer={false}
      shouldNotUseActiveFilter
      showLocationFilter
      useLocationFilterQuery={useGetBranchesListQuery}
      showAssetTypeNameFilter
      assetTypeFilterOptions={assetTypeFilterOptions}
      exportModuleName="accounting"
      enableExport
      enableTableCsvExport
      hidePerPageSelector
      moduleName="glcode"
      shouldCacheLazyQuery
    />
  );
}

function ConceptsView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/accounting'));
  }, [dispatch]);

  const { data: assetTypes } = useGetAssetTypesListQuery({
    t: 0,
  });

  const assetTypeFilterOptions = useMemo(() => {
    return (
      orderBy(assetTypes?.data, 'name')?.map(assetType => ({
        id: assetType?.id,
        label: assetType?.name,
        fieldName: assetType?.name,
        isInitialSelected:
          assetTypes?.data &&
          assetTypes?.data?.length <= MAX_SELECTABLE_OPTIONS,
      })) || []
    );
  }, [assetTypes?.data]);

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.accounting.title')}
      useLazyGetListQuery={useLazyGetAccountingGLCodesQuery}
      customParams={{
        t: undefined,
      }}
      useCreateMutation={{}}
      marginTop={false}
      withDrawer={false}
      shouldNotUseActiveFilter
      showConceptsFilter
      showAssetTypeNameFilter
      assetTypeFilterOptions={assetTypeFilterOptions}
      exportModuleName="accounting"
      enableExport
      enableTableCsvExport
      hidePerPageSelector
      moduleName="glcode"
      shouldCacheLazyQuery
    />
  );
}

function FullView() {
  const { t } = useTranslation();
  const { data: assetTypes } = useGetAssetTypesListQuery({
    t: 0,
  });

  const assetTypeFilterOptions = useMemo(() => {
    return (
      orderBy(assetTypes?.data, 'name')?.map(assetType => ({
        id: assetType?.id,
        label: assetType?.name,
        fieldName: assetType?.name,
        isInitialSelected:
          assetTypes?.data &&
          assetTypes?.data?.length <= MAX_SELECTABLE_OPTIONS,
      })) || []
    );
  }, [assetTypes?.data]);

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.accounting.title')}
      useLazyGetListQuery={useLazyGetAccountingGLCodesQuery}
      customParams={{
        t: undefined,
      }}
      useCreateMutation={{}}
      marginTop={false}
      withDrawer={false}
      shouldNotUseActiveFilter
      showLocationFilter
      useLocationFilterQuery={useGetBranchesListQuery}
      showAssetTypeNameFilter
      assetTypeFilterOptions={assetTypeFilterOptions}
      showConceptsFilter
      exportModuleName="accounting"
      enableExport
      enableTableCsvExport
      hidePerPageSelector
      moduleName="glcode"
      shouldCacheLazyQuery
    />
  );
}
interface GetGlCodeViewProps {
  view: AccountingViewsEnum | undefined;
}
export function GetGlCodeView({ view }: GetGlCodeViewProps) {
  switch (view) {
    case AccountingViewsEnum.LOCATIONS:
      return <LocationView />;
    case AccountingViewsEnum.CONCEPTS:
      return <ConceptsView />;
    case AccountingViewsEnum.ALL:
      return <FullView />;
    default:
      return <DefaultView />;
  }
}
