import {
  ECBox,
  ECButton,
  ECFormControl,
  ECIconButton,
  ECMenuItem,
  ECSearch,
  ECSelect,
  ECTabContainer,
  ECTextField,
  ECTypography,
} from 'app/components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { loadPageScopes } from 'utils/pageScopes';
import { Helmet } from 'react-helmet-async';
import { StyleConstants } from 'styles/StyleConstants';
import { WorkOrdersSearch } from './workOrdersSearch';
import { ProposalsSearch } from './proposalsSearch';
import { InvoicesSearch } from './invoicesSearch';
import { RootState } from 'store/configureStore';
import {
  GLOBAL_SEARCH_MODULE_OPTIONS,
  GLOBAL_SEARCH_MODULE_OPTION_VALUES,
  GlobalSearchModules,
  setGlobalFilter,
} from 'store/slice/page';
import { Close } from '@mui/icons-material';
import { InputAdornment, InputLabel } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useNavigationType } from 'react-router';
import { useCustomerUser } from 'app/hooks/customerUser.use-case';
import { ForbiddenPage } from '../ForbiddenPage';

export function GlobalSearchPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const isCustomer = useCustomerUser();

  const [selectedTab, setSelectedTab] = useState('1');

  const { module, globalQuery, mustTriggerListQuery } = useSelector(
    (state: RootState) => state.page.globalFilter,
  );

  const getTabFromModule = (module: number = 0) => {
    switch (module) {
      case 0:
        return [
          {
            value: '1',
            label: 'Work Orders',
            content: (
              <WorkOrdersSearch mustTriggerListQuery={mustTriggerListQuery} />
            ),
          },
          {
            value: '2',
            label: 'Proposals',
            content: (
              <ProposalsSearch mustTriggerListQuery={mustTriggerListQuery} />
            ),
          },
          {
            value: '3',
            label: 'Invoices',
            content: (
              <InvoicesSearch mustTriggerListQuery={mustTriggerListQuery} />
            ),
          },
        ];
      case 1:
        return [
          {
            value: '1',
            label: 'Work Orders',
            content: (
              <WorkOrdersSearch mustTriggerListQuery={mustTriggerListQuery} />
            ),
          },
        ];
      case 2:
        return [
          {
            value: '1',
            label: 'Proposals',
            content: (
              <ProposalsSearch mustTriggerListQuery={mustTriggerListQuery} />
            ),
          },
        ];
      case 3:
        return [
          {
            value: '1',
            label: 'Invoices',
            content: (
              <InvoicesSearch mustTriggerListQuery={mustTriggerListQuery} />
            ),
          },
        ];
      default:
        return [];
    }
  };

  const tabs = useMemo(
    () => getTabFromModule(module),
    [module, mustTriggerListQuery, getTabFromModule],
  );

  const handleGlobalSearchKeyDown = useCallback(e => {
    if (e.key === 'Enter') {
      dispatch(setGlobalFilter({ mustTriggerListQuery: true }));
    }
  }, []);

  const handleReturnClick = () => {
    if (navigationType === 'POP') {
      navigate('/panel/dashboard');
    } else {
      navigate(-1);
    }
  };

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Helmet
        titleTemplate={`${t('translation:pages.globalSearch.title')} - %s`}
        defaultTitle={t('translation:pages.globalSearch.title')}
      />
      <ECBox
        mt={StyleConstants.GLOBAL_SEARCH_HEIGHT}
        p={2}
        sx={{ display: 'flex', alignItems: 'baseline' }}
      >
        <ECButton
          variant="text"
          onClick={handleReturnClick}
          startIcon={<Close />}
        >
          Exit Search
        </ECButton>
      </ECBox>
      <ECBox p={2} sx={{ display: 'flex', alignItems: 'baseline' }}>
        <ECFormControl variant="filled" sx={{ width: '10%' }}>
          <InputLabel>{t('translation:pages.globalSearch.module')}</InputLabel>
          <ECSelect
            id="global-search-module-select-page"
            name="global-search-module-select-name-page"
            placeholder="Select a module"
            label="Module"
            labelId="global-search-module-select-label-page"
            value={module}
            renderValue={value => {
              const optionIndex =
                GLOBAL_SEARCH_MODULE_OPTION_VALUES?.findIndex(
                  optionValue => optionValue === value,
                ) || GlobalSearchModules.All;

              const optionLabel =
                GLOBAL_SEARCH_MODULE_OPTIONS?.[optionIndex]?.label ??
                GLOBAL_SEARCH_MODULE_OPTIONS?.[optionIndex];

              return (
                <ECBox display="flex" alignItems="center">
                  <ECTypography>{optionLabel}</ECTypography>
                </ECBox>
              );
            }}
            onChange={event => {
              setSelectedTab('1');
              dispatch(
                setGlobalFilter({ module: event.target.value as number }),
              );
            }}
          >
            {GLOBAL_SEARCH_MODULE_OPTIONS.map((option, index) => {
              return (
                <ECMenuItem value={GLOBAL_SEARCH_MODULE_OPTION_VALUES[index]}>
                  <ECBox
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ECTypography>{option.label ?? option}</ECTypography>
                  </ECBox>
                </ECMenuItem>
              );
            })}
          </ECSelect>
        </ECFormControl>
        <ECSearch
          sx={theme => ({ backgroundColor: theme.palette.common.white })}
        >
          <ECTextField
            id="global-search-query-input"
            variant="standard"
            sx={{
              minWidth: '100%',
              '.MuiFormHelperText-root': {
                color: theme => theme.palette.graphic.alert.error,
              },
            }}
            placeholder={t('translation:table.search')}
            value={globalQuery}
            onKeyDown={handleGlobalSearchKeyDown}
            onChange={e => {
              dispatch(
                setGlobalFilter({
                  globalQuery: e.target.value,
                  mustTriggerListQuery: false,
                }),
              );
            }}
            inputProps={{ maxLength: 500 }}
            InputProps={{
              endAdornment: globalQuery?.length ? (
                <InputAdornment position="end">
                  <ECIconButton
                    onClick={() => {
                      dispatch(
                        setGlobalFilter({
                          globalQuery: '',
                          mustTriggerListQuery: false,
                        }),
                      );
                    }}
                  >
                    <Close />
                  </ECIconButton>
                </InputAdornment>
              ) : undefined,
            }}
          />
        </ECSearch>
        <ECButton
          variant="contained"
          sx={{ height: '40px' }}
          onClick={() =>
            dispatch(
              setGlobalFilter({
                mustTriggerListQuery: true,
              }),
            )
          }
        >
          {t('translation:table.search')}
        </ECButton>
      </ECBox>
      <ECTabContainer
        p={2}
        variant="standard"
        color="marketingSuggestion"
        noPadding
        selectedTab={selectedTab}
        onChangeSelectedTab={setSelectedTab}
        tabs={tabs}
      />
    </>
  );
}
