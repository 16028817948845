import {
  ECEasyFormFieldType,
  ECLoadingProgress,
  ECPaper,
} from 'app/components';
import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { populateDropDownFields } from 'utils/pageUtils';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { useUpdateMultipleStatusMutation } from 'services/dashboardApi';
import { themes } from 'styles/theme/themes';
import _ from 'lodash';
import { StatusGroupNames } from 'types/StatusGroups';
import { useDispatch } from 'react-redux';
import { QueryTag } from '../DashboardPage/actions';
import {
  useBulkWorkOrderAddCommentChangeMutation,
  useBulkWorkOrderCategoryChangeMutation,
  useBulkWorkOrderFlagsChangeMutation,
  useBulkWorkOrderNTERaiseMutation,
  workOrdersApi,
} from 'services/workOrdersApi';
import { WorkflowStatus } from 'app/components/ECWorkflowStatusBadge';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { FlagBulkEditOptionEnum } from 'types/WorkflowStatus';
import { useScheduledJob } from 'app/hooks/useScheduledJob.use-case';

const fancyFormElementsBulkEdit = require('./fancy_form_config_bulk_edit.json');
const fancyFormElementsBulkEditDone = require('./fancy_form_config_bulk_done.json');

interface BulkEditDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  selectedRows?: any[];
}

export const BulkEditDrawer = ({
  isOpen,
  selectedRows,
  onClose,
}: BulkEditDrawerProps) => {
  const dispatch = useDispatch();
  const [formFields, setFormFields] = useState<any[]>(
    _.cloneDeep(fancyFormElementsBulkEdit.fields),
  );
  const [doneFields, setDoneFields] = useState<any[]>(
    fancyFormElementsBulkEditDone.fields,
  );
  const [isDone, setIsDone] = useState(false);
  const [comment, setComment] = useState(null);
  const [cancelSelected, setCancelSelected] = useState(false);
  const [raiseNTEValue, setRaiseNTEValue] = useState<number>(0);
  const [addComment, setAddComment] = useState(null);
  const [flagsValue, setFlagsValue] = useState<any>([]);
  const [flagsOperationTypeValue, setFlagsOperationTypeValue] =
    useState<FlagBulkEditOptionEnum>();
  const [newCategoryValue, setNewCategoryValue] = useState<number>(-1);
  const statusFieldName = 'statusCommand';
  const statusCancelId = '1';
  const categoryFieldName = 'category';

  const { data: categoryListData, isSuccess: isSuccessCategoryListData } =
    useGetCategoryListQuery({ st: 1 }, { skip: !isOpen });

  const {
    data: flags,
    isLoading: isLoadingFlags,
    isSuccess: isSuccessFlags,
  } = useGetFlagsListQuery({});

  useEffect(() => {
    if (flags && isSuccessFlags) {
      const localEditFormElements = [...formFields];
      let radioFields = localEditFormElements?.find(
        field => field.fieldName === 'checkboxGroup',
      )?.options;
      let flagsGroupSubFields = radioFields?.find(
        field => field.groupName === 'flagsGroup',
      )?.fields;
      const flagsField = flagsGroupSubFields?.find(
        field => field.fieldName === 'flags',
      );

      flagsField.options =
        flags?.data.map(d => ({
          label: d.label,
          color: d.color,
          id: d.id,
        })) || [];
      flagsField.optionValues = flags?.data.map(d => d.label) || [];
    }
  }, [isSuccessFlags, flags, isOpen]);

  const categoryFilterOptions = useMemo(() => {
    return (
      _.orderBy(categoryListData?.data, 'name')?.map(category => ({
        ...category,
        id: category?.id,
        label: category?.name,
        fieldName: category?.name,
        isInitialSelected: true,
      })) || []
    );
  }, [categoryListData]);

  useEffect(() => {
    if (categoryListData && isSuccessCategoryListData) {
      const localEditFormElements = [...formFields];
      let radioFields = localEditFormElements?.find(
        field => field.fieldName === 'checkboxGroup',
      )?.options;
      let categoryGroupSubFields = radioFields?.find(
        field => field.groupName === 'categoryGroup',
      )?.fields;
      populateDropDownFields({
        responseData: categoryFilterOptions,
        createFormFields: categoryGroupSubFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: categoryFieldName,
      });
      setFormFields(localEditFormElements);
    }
  }, [
    categoryListData,
    isSuccessCategoryListData,
    categoryFilterOptions,
    isOpen,
  ]);

  useEffect(() => {
    const localEditFormElements = [...formFields];
    let radioFields = localEditFormElements?.find(
      field => field.fieldName === 'checkboxGroup',
    )?.options;
    let statusGroupSubFields = radioFields?.find(
      field => field.groupName === 'statusGroup',
    )?.fields;
    populateDropDownFields({
      responseData: [{ name: 'Cancel', id: statusCancelId }],
      createFormFields: statusGroupSubFields,
      dataOptionsFieldName: 'name',
      dataValuesFieldName: 'id',
      formFieldName: statusFieldName,
    });
    setFormFields(localEditFormElements);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const updated = [...formFields];
      const woCountLabelField = updated?.find(
        field => field.fieldName === 'woCountLabel',
      );
      if (woCountLabelField) {
        woCountLabelField.label =
          'Work Orders Selected: ' + selectedRows?.length;
      }
      const selectedWOsField = updated?.find(
        field => field.fieldName === 'selectedWOs',
      );
      selectedWOsField.value =
        selectedRows?.map(d => ({
          label: d.id,
          color: 'Grey',
          id: d.id,
        })) || [];

      const radioGroupField = updated?.find(
        field => field.fieldName === 'checkboxGroup',
      );
      const addCommentField = radioGroupField?.options
        ?.find(option => option.groupName === 'addCommentsGroup')
        ?.fields?.find(field => field.fieldName === 'addComment');
      addCommentField.moduleIds = selectedRows?.map(row => row.id);
      setFormFields(updated);
    }
  }, [isOpen, selectedRows]);

  const [
    doStatusChangeMutation,
    { data: updateStausData, isSuccess, isError, isLoading, reset },
  ] = useUpdateMultipleStatusMutation();

  const [
    doWorkOrderNTERaiseMutation,
    {
      data: workOrderNTERaiseData,
      isSuccess: workOrderNTERaiseSuccess,
      isError: workOrderNTERaiseError,
      isLoading: workOrderNTERaiseLoading,
      reset: resetWorkOrderNTERaise,
    },
  ] = useBulkWorkOrderNTERaiseMutation();

  const [
    doWorkOrderAddCommentMutation,
    {
      data: workOrderAddCommentData,
      isSuccess: workOrderAddCommentSuccess,
      isError: workOrderAddCommentError,
      isLoading: workOrderAddCommentLoading,
      reset: resetWorkOrderAddComment,
    },
  ] = useBulkWorkOrderAddCommentChangeMutation();

  const [
    doWorkOrderCategoryChangeMutation,
    {
      data: workOrderCategoryChangeData,
      isSuccess: workOrderCategoryChangeSuccess,
      isError: workOrderCategoryChangeError,
      isLoading: workOrderCategoryChangeLoading,
      reset: resetWorkOrderCategoryChange,
    },
  ] = useBulkWorkOrderCategoryChangeMutation();

  const [
    doWorkOrderFlagsChangeMutation,
    {
      data: workOrderFlagsChangeData,
      isSuccess: workOrderFlagsChangeSuccess,
      isError: workOrderFlagsChangeError,
      isLoading: workOrderFlagsChangeLoading,
      reset: resetWorkOrderFlagsChange,
    },
  ] = useBulkWorkOrderFlagsChangeMutation();

  const {
    scheduledJobData,
    isScheduledJobCompleted,
    currentJobProgress,
    showScheduledJobProgress,
    clearJobIdAndProgress,
    isScheduledJobSuccess,
  } = useScheduledJob({
    isLoadingJobInfo: isLoading,
    isJobInfoSuccess: isSuccess,
    jobInfo: updateStausData,
  });

  const isLoadingDrawer =
    isLoading ||
    showScheduledJobProgress ||
    workOrderNTERaiseLoading ||
    workOrderCategoryChangeLoading ||
    workOrderFlagsChangeLoading ||
    workOrderAddCommentLoading;

  const useBulkEdit = useCallback(() => {
    const bulkEdit = async data => {
      delete data.undefined;
      const woIds = data?.selectedWOs?.map(wo => wo.id);
      const params: any = {
        itemIds: woIds,
      };
      params.sendNotification = data.sendNotification?.includes(
        'Send email for this update',
      );

      if (data?.statusCommand === statusCancelId) {
        setCancelSelected(true);
        setComment(data?.changeStatusReason);
        const statusUpdateList = {
          note: data?.changeStatusReason,
          recipientIds: data?.changeStatusReason?.selectedUsers?.map(
            item => item.nodeId,
          ),
          itemIds: woIds,
          statusGroupName: StatusGroupNames.Cancelled,
        };
        params.note = data?.changeStatusReason;
        params.recipientIds = data?.changeStatusReason?.selectedUsers?.map(
          item => item.nodeId,
        );
        params.statusGroupName = StatusGroupNames.Cancelled;
        doStatusChangeMutation({
          module: 'workorder',
          statusUpdateList: params,
        });
      } else if (data?.nte) {
        setRaiseNTEValue(Number(data?.nte));
        params.nte = Number(data?.nte);
        if (data?.addComment) {
          setAddComment(data?.addComment.comment);
          params.note = data?.addComment.comment;
          params.recipientsIds = data?.addComment?.selectedUsers?.map(
            user => user.nodeId,
          );
        }
        doWorkOrderNTERaiseMutation(params);
      } else if (data?.category) {
        setNewCategoryValue(Number(data?.category));
        params.categoryId = Number(data?.category);
        if (data?.addComment) {
          setAddComment(data?.addComment.comment);
          params.note = data?.addComment.comment;
          params.recipientsIds = data?.addComment?.selectedUsers?.map(
            user => user.nodeId,
          );
        }
        doWorkOrderCategoryChangeMutation(params);
      } else if (data?.flags && data?.flagOperationType) {
        setFlagsValue(data?.flags);
        setFlagsOperationTypeValue(
          FlagBulkEditOptionEnum[data?.flagOperationType] as any,
        );
        params.flagIds = data?.flags.map(flag => flag.id);
        params.flagOptionId = FlagBulkEditOptionEnum[data?.flagOperationType];
        if (data?.addComment) {
          setAddComment(data?.addComment.comment);
          params.note = data?.addComment.comment;
          params.recipientsIds = data?.addComment?.selectedUsers?.map(
            user => user.nodeId,
          );
        }
        doWorkOrderFlagsChangeMutation(params);
      } else if (data?.addComment) {
        setAddComment(data?.addComment.comment);
        params.note = data?.addComment.comment;
        params.recipientsIds = data?.addComment?.selectedUsers?.map(
          user => user.nodeId,
        );
        doWorkOrderAddCommentMutation(params);
      }
    };
    return [
      bulkEdit,
      {
        updateStausData,
        isError: false,
        error: null,
        isLoading: false,
        isSuccess: false,
      },
    ];
  }, [
    doStatusChangeMutation,
    doWorkOrderNTERaiseMutation,
    doWorkOrderCategoryChangeMutation,
    setCancelSelected,
    setComment,
    setRaiseNTEValue,
    setNewCategoryValue,
  ]);

  useEffect(() => {
    let operationRows = (selectedRows || []).map(
      ({ id, flags, workflowStatus, nte, request }) => ({
        itemId: id,
        statusFrom: workflowStatus.name,
        nteFrom: nte,
        flagsFrom: flags,
        categoryIdFrom: request?.category?.id,
        CategoryNameFrom: request?.category?.name,
      }),
    );

    if (!isLoading && isScheduledJobSuccess && isScheduledJobCompleted) {
      // status change
      const cancelStatus = (
        itemId: number,
        success: boolean,
        reason?: string,
      ) => {
        return operationRows?.map(row =>
          row.itemId === itemId
            ? {
                ...row,
                statusTo: WorkflowStatus.Cancelled,
                statusOutcome: success,
                statusFailReason: success ? undefined : reason,
              }
            : row,
        );
      };
      scheduledJobData?.success?.forEach(itemId => {
        operationRows = cancelStatus(itemId, true);
      });

      scheduledJobData?.failed?.forEach(item => {
        operationRows = cancelStatus(item?.id, false, item?.reason);
      });
    }

    if (workOrderAddCommentSuccess && !workOrderAddCommentLoading) {
      // add comment
      const addCommentPerform = (
        itemId: number,
        success: boolean,
        reason?: string,
      ) => {
        return operationRows?.map(row =>
          row.itemId === itemId
            ? {
                ...row,
                note: addComment,
              }
            : row,
        );
      };
      (workOrderAddCommentData as any)?.success?.forEach(itemId => {
        operationRows = addCommentPerform(itemId, true);
      });

      (workOrderAddCommentData as any)?.failed?.forEach(item => {
        operationRows = addCommentPerform(item?.id, false, item?.reason);
      });

      updateFieldsAndReset(operationRows);
      resetWorkOrderAddComment();
      reset();
    }

    if (workOrderNTERaiseSuccess && !workOrderNTERaiseLoading) {
      // nte raise
      const raisNTEPerform = (
        itemId: number,
        success: boolean,
        reason?: string,
      ) => {
        return operationRows?.map(row =>
          row.itemId === itemId
            ? {
                ...row,
                nteTo: raiseNTEValue,
                nteOutcome: success,
                nteFailReason: success ? undefined : reason,
                note: addComment ? addComment : undefined,
              }
            : row,
        );
      };
      (workOrderNTERaiseData as any)?.success?.forEach(itemId => {
        operationRows = raisNTEPerform(itemId, true);
      });

      (workOrderNTERaiseData as any)?.failed?.forEach(item => {
        operationRows = raisNTEPerform(item?.id, false, item?.reason);
      });
    }

    if (workOrderCategoryChangeSuccess && !workOrderCategoryChangeLoading) {
      const categortToName = categoryListData?.data?.find(
        item => item.id === newCategoryValue,
      )?.name;
      const categoryChangePerform = (
        itemId: number,
        success: boolean,
        reason?: string,
      ) => {
        return operationRows?.map(row =>
          row.itemId === itemId
            ? {
                ...row,
                categoryTo: categortToName,
                categoryOutcome: success,
                categoryFailReason: success ? undefined : reason,
                note: addComment ? addComment : undefined,
              }
            : row,
        );
      };
      (workOrderCategoryChangeData as any)?.success?.forEach(itemId => {
        operationRows = categoryChangePerform(itemId, true);
      });

      (workOrderCategoryChangeData as any)?.failed?.forEach(item => {
        operationRows = categoryChangePerform(item?.id, false, item?.reason);
      });
    }

    if (cancelSelected && raiseNTEValue === 0 && newCategoryValue === -1) {
      if (!isLoading && isScheduledJobSuccess && isScheduledJobCompleted) {
        updateFieldsAndReset(operationRows);
        reset();
      }
    }
    if (cancelSelected && raiseNTEValue > 0 && newCategoryValue === -1) {
      if (
        !isLoading &&
        isScheduledJobSuccess &&
        isScheduledJobCompleted &&
        workOrderNTERaiseSuccess &&
        !workOrderNTERaiseLoading
      ) {
        updateFieldsAndReset(operationRows);
        resetWorkOrderNTERaise();
        reset();
      }
    }
    if (cancelSelected && raiseNTEValue > 0 && newCategoryValue > -1) {
      if (
        !isLoading &&
        isScheduledJobSuccess &&
        isScheduledJobCompleted &&
        workOrderNTERaiseSuccess &&
        !workOrderNTERaiseLoading &&
        workOrderCategoryChangeSuccess &&
        !workOrderCategoryChangeLoading
      ) {
        updateFieldsAndReset(operationRows);
        reset();
        resetWorkOrderNTERaise();
        resetWorkOrderCategoryChange();
      }
    }

    if (!cancelSelected && raiseNTEValue > 0 && newCategoryValue === -1) {
      if (workOrderNTERaiseSuccess && !workOrderNTERaiseLoading) {
        updateFieldsAndReset(operationRows);
        resetWorkOrderNTERaise();
      }
    }
    if (!cancelSelected && raiseNTEValue > 0 && newCategoryValue > -1) {
      if (
        workOrderNTERaiseSuccess &&
        !workOrderNTERaiseLoading &&
        workOrderCategoryChangeSuccess &&
        !workOrderCategoryChangeLoading
      ) {
        updateFieldsAndReset(operationRows);
        resetWorkOrderNTERaise();
        resetWorkOrderCategoryChange();
      }
    }
    if (!cancelSelected && raiseNTEValue === 0 && newCategoryValue > -1) {
      if (workOrderCategoryChangeSuccess && !workOrderCategoryChangeLoading) {
        updateFieldsAndReset(operationRows);
        resetWorkOrderCategoryChange();
      }
    }

    if (workOrderFlagsChangeSuccess && !workOrderFlagsChangeLoading) {
      const flagsValuesIds = flagsValue?.map(flag => flag.id);
      operationRows = operationRows.map(row => ({
        ...row,
        note: addComment ? addComment : undefined,
        flagsOutcome: true,
        flagsFrom:
          flagsOperationTypeValue !==
          FlagBulkEditOptionEnum['Add Selected Flags']
            ? row.flagsFrom?.map(flag => ({
                label: flag.label,
                isStrikeThrough:
                  flagsOperationTypeValue ===
                    FlagBulkEditOptionEnum['Override with Selected Flags'] ||
                  flagsValuesIds?.includes(flag.id),
                id: flag.id,
              }))
            : [],
        flagsTo:
          flagsOperationTypeValue ===
          FlagBulkEditOptionEnum['Remove Selected Flags']
            ? row.flagsFrom
                ?.filter(flag => !flagsValuesIds?.includes(flag.id))
                ?.map(flag => ({
                  label: flag.label,
                  id: flag.id,
                }))
            : flagsValue?.map(flag => ({
                label: flag.label,
                id: flag.id,
              })),
      }));
      updateFieldsAndReset(operationRows);
      resetWorkOrderFlagsChange();
      setComment(null);
      setAddComment(null);
    }
  }, [
    updateStausData,
    isError,
    isLoading,
    isSuccess,
    onClose,
    reset,
    comment,
    selectedRows,
    doneFields,
    setDoneFields,
    workOrderNTERaiseSuccess,
    workOrderNTERaiseLoading,
    workOrderCategoryChangeSuccess,
    workOrderCategoryChangeLoading,
    raiseNTEValue,
    setRaiseNTEValue,
    cancelSelected,
    newCategoryValue,
    workOrderFlagsChangeSuccess,
    workOrderFlagsChangeLoading,
    workOrderNTERaiseData,
    categoryListData?.data,
    workOrderCategoryChangeData,
    updateFieldsAndReset,
    resetWorkOrderNTERaise,
    resetWorkOrderCategoryChange,
    resetWorkOrderFlagsChange,
    flagsValue,
    flagsOperationTypeValue,
    isScheduledJobCompleted,
  ]);

  function updateFieldsAndReset(operationRows) {
    const updatedFields = [...doneFields];
    const bulkActivityViewField = updatedFields?.find(
      field => field.fieldName === 'bulkActivityView',
    );
    bulkActivityViewField.value = operationRows;
    setDoneFields(updatedFields);
    setIsDone(true);
  }

  const handleOutputChange = (
    output: ECEasyFormFieldType[],
    fieldName?: string,
  ) => {
    if (fieldName === statusFieldName) {
      const radioGroupField = output?.find(
        field => field.fieldName === 'checkboxGroup',
      );
      const statusGroupFields = radioGroupField?.options?.find(
        field => field.groupName === 'statusGroup',
      )?.fields;
      const statusField = statusGroupFields?.find(
        field => field.fieldName === statusFieldName,
      );

      const isSelectedStatusCancel = statusField?.value === statusCancelId;

      const changeStatusReasonField = statusGroupFields?.find(
        field => field.fieldName === 'changeStatusReason',
      );
      changeStatusReasonField.required = isSelectedStatusCancel;
    }
  };

  const handleClose = useCallback(() => {
    dispatch(
      workOrdersApi.util.invalidateTags([
        QueryTag.PendingWorkorders,
        'WorkOrderById',
      ]),
    );
    setIsDone(false);
    setCancelSelected(false);
    setRaiseNTEValue(0);
    setNewCategoryValue(-1);
    reset();
    resetWorkOrderNTERaise();
    onClose?.();
    setFormFields(_.cloneDeep(fancyFormElementsBulkEdit.fields));
    setComment(null);
    setAddComment(null);
    clearJobIdAndProgress();

    return {
      data: null,
      isError: false,
      error: null,
      isLoading: false,
      isSuccess: false,
    };
  }, [
    dispatch,
    workOrdersApi.util,
    setIsDone,
    setCancelSelected,
    setRaiseNTEValue,
    reset,
    resetWorkOrderNTERaise,
    onClose,
  ]);

  const useDone = useCallback(() => {
    return [
      () => {
        handleClose();
      },
      {
        data: null,
        isError: false,
        error: null,
        isLoading: false,
        isSuccess: false,
      },
    ];
  }, [
    dispatch,
    workOrdersApi.util,
    setIsDone,
    setCancelSelected,
    setRaiseNTEValue,
    reset,
    resetWorkOrderNTERaise,
    onClose,
  ]);

  return (
    <ECDrawerDetails open={isOpen} anchor="right" onClose={handleClose}>
      <ECPaper
        sx={{
          height: '100%',
          paddingTop: '80px',
          boxShadow: 'none',
        }}
        role="presentation"
      >
        <ECEasyFormCreate
          useCreateMutation={isDone ? useDone : useBulkEdit}
          formConfig={
            isDone
              ? fancyFormElementsBulkEditDone.config
              : fancyFormElementsBulkEdit.config
          }
          formFields={isDone ? doneFields : formFields}
          onClose={handleClose}
          drawerTitleBarBGColor={themes.light.palette.other.divider}
          CustomLoadingScreen={
            <ECLoadingProgress
              operationProgress={currentJobProgress}
              linearWithLabel
            />
          }
          CustomErrorScreen={
            <ECLoadingProgress
              operationProgress={currentJobProgress}
              linearWithLabel
              error
            />
          }
          CustomSuccessScreen={
            <ECLoadingProgress
              operationProgress={currentJobProgress}
              linearWithLabel
              success
            />
          }
          isLoading={isLoadingDrawer}
          onChange={handleOutputChange}
          hideCancelButton={isDone}
          noTopMargin
        />
      </ECPaper>
    </ECDrawerDetails>
  );
};
