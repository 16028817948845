import { Fragment, useMemo } from 'react';
import type { ECTableRowsProps, row } from 'types/core/ECTable';
import { ColType } from 'app/components/ECTable';
import { ECTableRowMemo } from './ECTableRowMemo';

export const ECTableRows: React.FC<ECTableRowsProps> = ({
  data = [],
  orderedColumns,
  isEditable,
  onChange,
  onRowPress,
  onDuplicatePress,
  onRemove,
  t,
  customFieldsData,
  multiSelectTable,
  allChecked,
  setIsOpenRowNumber,
  isOpenRowNumber,
  isDraggableColumns,
  setOpennedRowId,
  customEditFieldsData,
  headerBackgroundColor,
  setSearchParams,
  tableLayoutFilter,
  sort,
  setSort,
  handleCheckBoxClick,
  isDisabled,
  selectedRows,
  isEveryDataRowSelected,
}) => {
  const collapsibleCol = useMemo(() => {
    return orderedColumns.find(col => col.type === ColType.Collapsible);
  }, []);

  /**
   * This memoization is done to prevent the whole table re-rendering when a row is selected
   * Note that the component below the checkbox is memoized, so if you need some side effect
   * that is not happening, update the dependency array. Also, if you update the dependency array,
   * check if selecting a row is laggy again.
   */
  const memoizedRows = useMemo(() => {
    return data?.map((row: row, rowIndex: number) => {
      return (
        <ECTableRowMemo
          handleCheckBoxClick={handleCheckBoxClick}
          isDisabled={isDisabled}
          orderedColumns={orderedColumns}
          isEditable={isEditable}
          onChange={onChange}
          onRowPress={onRowPress}
          onDuplicatePress={onDuplicatePress}
          onRemove={onRemove}
          t={t}
          customFieldsData={customFieldsData}
          multiSelectTable={multiSelectTable}
          allChecked={allChecked}
          setIsOpenRowNumber={setIsOpenRowNumber}
          isOpenRowNumber={isOpenRowNumber}
          isDraggableColumns={isDraggableColumns}
          setOpennedRowId={setOpennedRowId}
          customEditFieldsData={customEditFieldsData}
          headerBackgroundColor={headerBackgroundColor}
          setSearchParams={setSearchParams}
          tableLayoutFilter={tableLayoutFilter}
          sort={sort}
          setSort={setSort}
          row={row}
          rowIndex={rowIndex}
          collapsibleCol={collapsibleCol}
          key={`${row?.id}-${rowIndex}`}
          selectedRows={selectedRows}
        />
      );
    });
  }, [data, isEveryDataRowSelected, orderedColumns, isDisabled]);

  return <Fragment>{memoizedRows}</Fragment>;
};
