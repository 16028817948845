import { ECDynamicPageTemplate } from 'app/components';

import {
  useAddTradeTypeMutation,
  useEditTradeTypeMutation,
  useLazyGetTradesListQuery,
  useGetCoreTradeTypesQuery,
} from 'services/tradeApi';

import { findToken } from 'services/utils';

const tableConfig = require('./tableConfigTable2.json');
const fancyFormElementsCreate = require('./fancy_form_config_create.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');

export function TradeTypesPage() {
  type Token = string;
  const token: Token = findToken();
  const {
    data: dataCore,
    error: errorCore,
    isLoading: isLoadingCore,
    isSuccess: isSuccessCore,
  } = useGetCoreTradeTypesQuery();
  type DataCore = any;
  const tradeTypeCore: DataCore = dataCore;

  if (isSuccessCore && tradeTypeCore) {
    const createFormFields = fancyFormElementsCreate.data.fields;
    let createSelectField = createFormFields.find(
      field => field.fieldName === 'coreTradeId',
    );
    createSelectField.options = tradeTypeCore?.data?.map(d => d.name) || [];
    createSelectField.optionValues =
      tradeTypeCore?.data?.map(d => d.coreTradeId) || [];

    const editFormFields = fancyFormElementsEdit.data.fields;
    const editFormSelectField = editFormFields.find(
      field => field.fieldName === 'coreTradeId',
    );
    editFormSelectField.options = tradeTypeCore?.data?.map(d => d.name) || [];
    editFormSelectField.optionValues =
      tradeTypeCore?.data?.map(d => d.coreTradeId) || [];
  }

  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      pageTitle={'Trades'}
      useLazyGetListQuery={useLazyGetTradesListQuery}
      useCreateMutation={useAddTradeTypeMutation}
      useUpdateMutation={useEditTradeTypeMutation}
      tableConfig={tableConfig.data.config}
      createFormConfig={fancyFormElementsCreate.data.config}
      createFormFields={fancyFormElementsCreate.data.fields}
      editFormConfig={fancyFormElementsEdit.data.config}
      editFormFields={fancyFormElementsEdit.data.fields}
      shouldNotUseActiveFilter
    />
  );
}
