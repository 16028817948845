import { Skeleton } from '@mui/material';
import { ECBox } from 'app/components/ECBox';

/**
 * Table Skeleton Loading. It's done in a way that the number
 * of rows is dynamic to match all screen sizes
 */
export const TableSkeletonContainer: React.FC<{ height: number }> = ({
  height,
}) => {
  const cellHeight = 33;
  const rowsGap = 32;
  const rowsHeight = cellHeight + rowsGap;
  const rows = Array.from({ length: height / rowsHeight }).fill(0);
  const headerSize = 36;
  const columnGap = 6;
  return (
    <ECBox
      position={'relative'}
      width={'100%'}
      overflow={'hidden'}
      borderRadius={'12px'}
      sx={{
        animation: 'fade-in 0.5s',
      }}
    >
      <Skeleton
        sx={theme => ({
          backgroundColor: theme.palette.grey[200],
        })}
        variant="rounded"
        height={height}
        width="100%"
        animation={'wave'}
      />
      <Skeleton
        key={'header-skeleton'}
        variant="rounded"
        height={headerSize}
        width={'100%'}
        sx={theme => ({
          backgroundColor: theme.palette.primary.dark[400],
          position: 'absolute',
          top: 0,
          left: 0,
        })}
        animation={'wave'}
      />
      {rows.map((row, index) => (
        <SkeletonRow
          key={'index' + index}
          cellHeight={cellHeight}
          rowIndex={index}
          initialGap={headerSize}
          rowHeight={rowsHeight}
          columnsGap={columnGap}
        />
      ))}
    </ECBox>
  );
};

const SkeletonRow: React.FC<{
  cellHeight: number;
  rowIndex: number;
  initialGap?: number;
  rowHeight?: number;
  columnsGap?: number;
}> = ({
  cellHeight,
  rowIndex,
  initialGap = 0,
  rowHeight = 0,
  columnsGap = 2,
}) => {
  const columns = Array.from({ length: 6 }).fill(0);
  return (
    <ECBox
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      columnGap={columnsGap}
      width={'100%'}
      sx={{
        position: 'absolute',
        top: initialGap + rowHeight / 2 + rowIndex * rowHeight,
        left: 16,
      }}
    >
      {columns.map((column, index) => {
        const multiplier = index % 2 === 0 ? 0.5 : 2;
        return (
          <Skeleton
            key={'index' + index + rowIndex}
            variant="rounded"
            height={cellHeight}
            width={`${(100 / columns.length) * multiplier}%`}
            animation={'wave'}
          />
        );
      })}
    </ECBox>
  );
};
