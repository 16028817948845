import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setActiveFilter } from 'store/slice/page';
import { Filter } from 'types/Filter';
import { AutoCompleteDropDownOption } from 'types/User';
import { ECBox } from '../ECBox';
import { ECChip } from '../ECChip';
import { FieldTypes } from '../ECForm';
import { ECTooltip } from '../ECTooltip';
import { ECInputBase } from '../ECSearch';
import { useTheme } from '@mui/material';

export interface ECEasyInputTagsProps {
  tags?: Filter[];
  defaultSearchFieldName?: string;
  defaultSearchFieldLabel?: string;
  selectedTags: (selectedTags) => void;
}

export const ECEasyInputTags = ({
  tags,
  defaultSearchFieldName,
  defaultSearchFieldLabel,
  selectedTags,
}: ECEasyInputTagsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [tagsState, setTagsState] = useState<Filter[] | undefined>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setTagsState(tags);
  }, [tags]);

  const removeTag = indexToRemove => {
    const newTagsState = tagsState?.filter(
      (_, index) => index !== indexToRemove,
    );
    selectedTags(newTagsState);
  };

  const addTag = event => {
    if (event.target.value !== '') {
      dispatch(
        setActiveFilter({
          filterFields: [
            {
              type: FieldTypes.Text,
              label: defaultSearchFieldLabel ?? '',
              fieldName: defaultSearchFieldName ?? '',
              value: event.target.value,
            },
          ],
        }),
      );
      event.target.value = '';
    }
  };

  return (
    <ECBox
      sx={{
        tabIndex: '0',
        display: 'flex',
        flex: 1,
        justifyContent: 'left',
        flexWrap: 'wrap',
        alignItems: 'center',
        border: 0,
      }}
    >
      {tagsState?.slice(0, 3).map((tag, index) => {
        let chipLabel = '';
        switch (tag.type) {
          case FieldTypes.Switch:
            chipLabel = Number(tag.value) === 1 ? 'Active' : 'Inactive';
            break;
          case FieldTypes.Autocomplete:
            chipLabel = (tag.value as AutoCompleteDropDownOption).label;
            break;
          default:
            chipLabel = tag.value?.toString() ?? '';
            break;
        }
        const chipLabelFormatted =
          chipLabel.length > 15
            ? `${chipLabel.substring(0, 15)}...`
            : chipLabel;

        return (
          <ECTooltip title={tag.label} arrow key={tag.fieldName}>
            <ECChip
              variant="outlined"
              label={`${chipLabelFormatted}`}
              onDelete={() => removeTag(index)}
              key={`input tag ${tag.value} ${index}`}
              borderColor={theme.palette.border.button.actionPrimaryLight}
              sx={{
                ml: 1,
              }}
            />
          </ECTooltip>
        );
      })}
      {tagsState && tagsState?.length > 3 && (
        <ECChip
          variant="filled"
          label={tagsState?.length > 3 ? `+${tagsState?.length - 3}` : ''}
          sx={{
            ml: 1,
          }}
        />
      )}
      {!tags?.length && (
        <ECInputBase
          placeholder={t('translation:table.search')}
          onKeyUp={event => (event.key === 'Enter' ? addTag(event) : null)}
          onBlur={event => addTag(event)}
          sx={theme => ({
            tabIndex: '1',
            ml: 1,
            flexGrow: 1,
          })}
        />
      )}
    </ECBox>
  );
};
