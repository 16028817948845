import { useTranslation } from 'react-i18next';
import { ECDynamicPageTemplate } from 'app/components';
import {
  useLazyGetServiceProviderTradeRegionByIdQuery,
  useCreateServiceProviderTradesAndRegionsMutation,
  useGetServiceProviderByIdQuery,
} from 'services/serviceProviderApi';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { useParams } from 'react-router';
import { useGetProfileQuery } from 'services/profileApi';
import { loadPageScopes } from 'utils/pageScopes';

const createFormConfig = require('./form_config_create.json');

export function ServiceProviderTradeAndRegionPage() {
  const { id } = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { data: companyDetails } = useGetProfileQuery();
  const { data: profile } = useGetServiceProviderByIdQuery(id ? Number(id) : 0);

  const [
    doAdd,
    {
      data: addData,
      isError: isAddError,
      error: addError,
      isLoading: isAddLoading,
      isSuccess: isAddSuccess,
      reset: resetAdd,
    },
  ] = useCreateServiceProviderTradesAndRegionsMutation();

  const useCreateTradeRegion = useCallback(() => {
    const doCreate = async data => {
      if (
        !data.tradeRegions ||
        !data.tradeRegions ||
        data.tradeRegions?.tradeRegions?.length === 0
      ) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: t(
              'translation:pages.spProfile.tradesRegions.noTradeRegionSelected',
            ),
          }),
        );
        return;
      }
      data.tradeRegions.tradeRegions.forEach(tradeRegion => {
        tradeRegion.companyId = companyDetails?.id;
        tradeRegion.status = 1;
      });
      doAdd({
        spId: companyDetails?.id || 0,
        tradeRegions: data.tradeRegions.tradeRegions,
      });
    };

    return [
      doCreate,
      {
        data: addData,
        isError: isAddError,
        error: addError,
        isLoading: isAddLoading,
        isSuccess: isAddSuccess,
        reset: resetAdd,
      },
    ];
  }, [
    profile?.spCompanyId,
    addData,
    addError,
    isAddError,
    isAddLoading,
    isAddSuccess,
    resetAdd,
    doAdd,
  ]);
  useEffect(() => {
    loadPageScopes('trade');
  }, []);

  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      idToGetListBy={profile?.spCompanyId ?? companyDetails?.id}
      pageTitle={t('translation:pages.serviceProvider.title')}
      useLazyGetListQuery={useLazyGetServiceProviderTradeRegionByIdQuery}
      useCreateMutation={useCreateTradeRegion}
      createFormConfig={createFormConfig.config}
      createFormFields={createFormConfig.fields}
      showSelfPerformingFilter={true}
      showStatusActiveFilter={true}
      statusAlias="sptrdrgn.status"
      defaultSortConfig={{ fieldName: 'crtrd.name', value: 'ASC' }}
      marginTop={false}
      withDrawer={false}
      shouldNotUseActiveFilter
    />
  );
}
