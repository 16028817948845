export function formatPhone(phone?: string, countryCode?: string | null) {
  if (!phone) return '';
  if (phone === '+') return '+';
  const countryCodeFromPhone =
    countryCode?.toLocaleUpperCase() || getCountryCodeFromPhone(phone);
  let normalizedPhone = stripPhoneNumber(removeCountryCodeFromPhone(phone));

  if (normalizedPhone.length > 10) {
    normalizedPhone = normalizedPhone.slice(0, 10);
  }

  if (normalizedPhone.length >= 7) {
    normalizedPhone = normalizedPhone.replace(
      /(\d{3})(\d{3})(\d*)/,
      '($1) $2-$3',
    );
  } else if (normalizedPhone.length >= 4) {
    normalizedPhone = normalizedPhone.replace(/(\d{3})(\d+)/, '($1) $2');
  }

  return prependCountryCodeToPhone(
    normalizedPhone,
    countryCodeFromPhone,
    false,
  );
}

export const getCountryCodeFromPhone = (phone: string) => {
  if (!phone) return '';
  if (phone.indexOf('+1') >= 0) return 'US';
  if (phone.indexOf('+52') >= 0) return 'MX';
  if (phone.indexOf('+48') >= 0) return 'PL';
  return '';
};

export const removeCountryCodeFromPhone = (phone: string) => {
  if (!phone) return '';
  if (phone.indexOf('+1') >= 0) return phone.replace('+1', '');
  if (phone.indexOf('+52') >= 0) return phone.replace('+52', '');
  if (phone.indexOf('+48') >= 0) return phone.replace('+48', '');
  if (phone.indexOf('+') >= 0) return phone.replace('+', '');
  return phone;
};

export const prependCountryCodeToPhone = (
  phone: string,
  countryCode?: string,
  preventWhitespace: boolean = true,
) => {
  const whiteSpace = phone.length >= 4 && !preventWhitespace ? ' ' : '';
  const upperCasedCountryCode =
    countryCode?.toUpperCase() || getCountryCodeFromPhone(phone);
  if (upperCasedCountryCode === 'US' || upperCasedCountryCode === 'CA') {
    return '+1' + whiteSpace + phone;
  }
  if (upperCasedCountryCode === 'MX') {
    return '+52' + whiteSpace + phone;
  }
  if (upperCasedCountryCode === 'PL') {
    return '+48' + whiteSpace + phone;
  }
  return phone;
};

export const cleanedPhoneNumber = (
  phoneNumber: string | undefined | null,
): string => {
  if (phoneNumber === null || phoneNumber === undefined) {
    return '';
  }
  const countryCodeFromPhone = getCountryCodeFromPhone(phoneNumber);
  const tempCleanedPhoneNumber = stripPhoneNumber(
    removeCountryCodeFromPhone(phoneNumber),
  );
  const cleanedMinimalPattern = minimalPhonePattern.replace(/[^#]/g, '');

  if (tempCleanedPhoneNumber.length === cleanedMinimalPattern.length) {
    return prependCountryCodeToPhone(
      tempCleanedPhoneNumber,
      countryCodeFromPhone,
    );
  } else if (tempCleanedPhoneNumber.length > cleanedMinimalPattern.length) {
    return prependCountryCodeToPhone(
      tempCleanedPhoneNumber.slice(0, cleanedMinimalPattern.length),
      countryCodeFromPhone,
    );
  }

  return prependCountryCodeToPhone(
    tempCleanedPhoneNumber,
    countryCodeFromPhone,
  );
};

export const minimalPhonePattern = '(###) ###-####';

export const validatePhoneNumber = (phone: string) => {
  if (!phone) {
    return false;
  }

  const phoneNumber = stripPhoneNumber(removeCountryCodeFromPhone(phone));
  return phoneNumber.length >= minimalPhonePattern.replace(/[^#]/g, '').length;
};

// MX: +52 (##) ####-#### | US/CA: +1 (###) ###-#### | Other/Without: (###) ###-####

export const stripPhoneNumber = (phone: string) => {
  if (!phone) return '';
  return phone.replace(/(?!^)\+/g, '').replace(/[^\d+]/g, '');
};

export type CountryCodeOption = {
  countryCode: string;
  prefix: string;
};

export const SUPPORTED_PHONE_COUNTRY_CODES: CountryCodeOption[] = [
  {
    countryCode: 'US',
    prefix: '+1',
  },
  {
    countryCode: 'MX',
    prefix: '+52',
  },
  {
    countryCode: 'PL',
    prefix: '+48',
  },
];

export function getCountryCodeOptionFromCountryCode(
  countryCode: string,
): CountryCodeOption | undefined {
  return SUPPORTED_PHONE_COUNTRY_CODES.find(
    option => option.countryCode === countryCode,
  );
}
