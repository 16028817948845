import { closestCenter, DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Fragment } from 'react';
import { ECTableDraggableRowProps } from 'types/core/ECTable';
import { SortableItem } from './ECSortableItem';

export const ECTableDraggableRows: React.FC<ECTableDraggableRowProps> = ({
  data,
  sensors,
  onDragEnd,
  onRowPress,
  orderedColumns,
  isEditable,
  onChange,
  tableLayoutFilter,
  t,
  customFieldsData,
  customEditFieldsData,
}) => {
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd || (() => false)}
    >
      <SortableContext
        items={data || []}
        strategy={verticalListSortingStrategy}
      >
        {data
          ? data?.map((row, rowIndex) => (
              <Fragment key={row?.id || `row-${rowIndex}-fragment`}>
                <SortableItem
                  key={row?.id || `row-${rowIndex}-sortable`}
                  row={row}
                  rowIndex={rowIndex}
                  onRowPress={onRowPress}
                  isEditable={isEditable}
                  onChange={onChange}
                  cols={orderedColumns}
                  tableLayoutFilter={tableLayoutFilter}
                  t={t}
                  customFieldsData={customFieldsData}
                  customEditFieldsData={customEditFieldsData}
                />
              </Fragment>
            ))
          : null}
      </SortableContext>
    </DndContext>
  );
};
