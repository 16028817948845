import { useCallback, useEffect, useState } from 'react';
import { ECEasyFormCreate } from 'app/components';
import { useCreateBranchMutation } from 'services/branchApi';
import { useDispatch } from 'react-redux';
import { profileApi } from 'services/profileApi';
import { invalidateCompanyProfile } from 'utils/invalidateCache';

interface Step3Props {
  existingData?: any;
  onClose?: () => void;
}

interface LeaseOptionData {
  leaseOption1PeriodCode?: { id: string };
  leaseOption2PeriodCode?: { id: string };
}

const fancyFormElementsCreate = require('./fancy_form_config_step3_create.json');

export const Step3 = ({ existingData, onClose }: Step3Props) => {
  const dispatch = useDispatch();

  const [localData, setLocalData] = useState<LeaseOptionData>(existingData);
  const [createFormFields, setCreateFormFields] = useState<any>(
    fancyFormElementsCreate.fields,
  );

  useEffect(() => {
    if (existingData) {
      const isBillingSameAsAddressChecked =
        existingData?.isBillingSameAsAddress?.includes(
          'Use same for Billing Address',
        );

      if (isBillingSameAsAddressChecked) {
        // Remove billing address fields from createFormFields
        const filterFields = fields => {
          return fields.reduce((acc, field) => {
            if (field.type === 'group' && field.subFields) {
              // Recursively filter subFields within groups
              const filteredSubFields = filterFields(field.subFields);
              if (filteredSubFields.length > 0) {
                acc.push({ ...field, subFields: filteredSubFields });
              }
            } else if (
              ![
                'billingAddress.countryCode',
                'billingAddress.zipCodePostalCode',
                'billingAddress.cityName',
                'billingAddress.stateProvinceCode',
                'billingAddress.line1',
                'billingAddress.line2',
              ].includes(field.fieldName)
            ) {
              acc.push(field);
            }
            return acc;
          }, []);
        };
        const filteredFormFields = filterFields(createFormFields);

        setCreateFormFields(filteredFormFields);
      }

      setLocalData({
        ...existingData,
        leaseOption1PeriodCode: existingData.leaseOption1PeriodCode?.id,
        leaseOption2PeriodCode: existingData.leaseOption2PeriodCode?.id,
      });
    }
  }, [existingData]);

  const [
    doCreateLocation,
    { data, isError, error, isSuccess, reset, isLoading },
  ] = useCreateBranchMutation();

  const useCreateaMutation = useCallback(() => {
    const create = (data: any) => {
      doCreateLocation(existingData);
    };

    return [
      create,
      {
        data,
        isError,
        error,
        isSuccess,
        reset,
        isLoading,
      },
    ];
  }, [
    data,
    isError,
    error,
    isSuccess,
    reset,
    isLoading,
    existingData,
    doCreateLocation,
  ]);

  // need to remove company profile from local storage and invalidate the tag after updating
  useEffect(() => {
    if (isSuccess) {
      invalidateCompanyProfile();
      dispatch(profileApi.util.invalidateTags(['Profile']));
    }
  }, [isSuccess]);

  return (
    <ECEasyFormCreate
      useCreateMutation={useCreateaMutation}
      onClose={onClose}
      formConfig={fancyFormElementsCreate.config}
      formFields={createFormFields}
      existingData={localData}
      isLoading={isLoading}
    />
  );
};
