import * as Icons from '@mui/icons-material';
import { ECBox } from 'app/components/ECBox';
import { ECTypography } from 'app/components/ECTypography';
import { NextTimeTrackStatusData } from 'types/TimeTrack';

interface Props {
  status: NextTimeTrackStatusData;
  onClick: (status: NextTimeTrackStatusData) => void;
}

export const ECTimeTrackingItemButton = ({ status, onClick }: Props) => {
  const Icon = Icons[status.icon as keyof typeof Icons];

  return (
    <ECBox
      display="flex"
      py={1}
      px={2}
      gap={1}
      sx={{
        ':hover': { bgcolor: theme => theme.palette.table.selectedRow },
        cursor: 'pointer',
      }}
      onClick={() => onClick(status)}
    >
      <Icon sx={{ color: theme => theme.palette.graphic.secondary }} />
      <ECTypography variant="body2">{status.name}</ECTypography>
    </ECBox>
  );
};
