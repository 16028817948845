import { Delete } from '@mui/icons-material';
import {
  ECAlert,
  ECBox,
  ECButton,
  ECGrid,
  ECImage,
  ECTypography,
} from 'app/components';
import EmaHelloImage from './assets/Ema_hello.png';
import { DateTime } from 'luxon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  formatDateForCancelSubscription,
  formatDateOnlyForTable,
} from 'utils/strings/formatDate';
import { useEffect, useState } from 'react';
import { useCancelSubscriptionMutation } from 'services/subscriptionApi';
import { useDispatch } from 'react-redux';
import { invalidateCompanyProfile } from 'utils/invalidateCache';
import { profileApi } from 'services/profileApi';
import { setSnackbar } from 'store/slice/page';
import { ECModal } from 'app/components/ECModal';
import { P } from 'types/Permission';

export const FeatureList = ({ features }: { features: string[] }) => {
  return (
    <ECBox>
      {features.map((feature, index) => (
        <ECTypography
          key={index}
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            marginBottom: 1,
            color: theme.palette.background.default,
          })}
        >
          <CheckCircleIcon
            sx={theme => ({
              marginRight: 1,
              color: theme.palette.background.default,
            })}
          />
          {feature}
        </ECTypography>
      ))}
    </ECBox>
  );
};

const SubscriptionPlanCard = ({ subscription, refetch, paymentCost }) => {
  const isSubscriptionIsEnded = !!subscription?.cancellationTime;

  const dispatch = useDispatch();
  const [isOpenSubscriptionModal, setIsOpenSubscriptionModal] = useState(false);

  const [doCancelSubscription, { isSuccess, isError }] =
    useCancelSubscriptionMutation({});

  useEffect(() => {
    if (isSuccess && !isError) {
      invalidateCompanyProfile();
      dispatch(profileApi.util.invalidateTags(['Profile']));
      refetch();
      handleSubscriptionModal();
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Subscription was Successfully Canceled',
        }),
      );
    }
  }, [isSuccess, isError]);

  const handleSubscriptionModal = () => {
    setIsOpenSubscriptionModal(prev => !prev);
  };

  const handleCancelSubscription = () => {
    if (subscription?.id) doCancelSubscription({ id: Number(subscription.id) });
  };

  return (
    <>
      <ECBox sx={{ paddingLeft: 2, paddingRight: 2, marginTop: -5 }}>
        {/* Header Section */}
        <ECGrid container alignItems="center">
          <ECGrid item xs={9}>
            <ECTypography variant="h6">Subscription Plan</ECTypography>
          </ECGrid>
          {!isSubscriptionIsEnded && subscription?.nextPayment && (
            <ECGrid
              item
              xs={3}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <ECButton
                scopes={[P.CancelSubscription]}
                variant="text"
                color="error"
                startIcon={<Delete color="error" />}
                onClick={handleSubscriptionModal}
              >
                Cancel Subscription
              </ECButton>
            </ECGrid>
          )}
        </ECGrid>
        {isSubscriptionIsEnded && (
          <ECBox marginY={'10px'}>
            <ECAlert severity="error">
              {`Access credentials will expire on ${formatDateForCancelSubscription(subscription?.nextPayment)} and will not be renewed. You will lose access to Ecotrak and all information stored in the system, to cancel deactivation please contact support.`}
            </ECAlert>
          </ECBox>
        )}

        {/* Content Section */}
        <ECBox
          sx={theme => ({
            borderRadius: 5,
            backgroundColor: theme.palette.grey[900],
            padding: 4,
          })}
        >
          <ECGrid container alignItems="center" spacing={2}>
            {/* Image Section */}
            <ECGrid item xs={2}>
              <ECImage
                src={EmaHelloImage}
                alt="Ema Hello"
                sx={{ height: '150px', width: '150px' }}
              />
            </ECGrid>

            {/* Subscription Info */}
            <ECGrid item xs={3}>
              <ECBox
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  height: '100%',
                }}
              >
                <ECTypography
                  variant="h5"
                  sx={theme => ({
                    fontWeight: 'bold',
                    color: theme.palette.background.default,
                  })}
                >
                  Ecotrak Build
                </ECTypography>
                {subscription && Object.keys(subscription).length > 0 ? (
                  <>
                    <ECTypography
                      variant="h4"
                      sx={theme => ({
                        fontWeight: 'bold',
                        display: 'inline',
                        marginTop: 2,
                        color: theme.palette.background.default,
                      })}
                    >
                      ${paymentCost}/
                      <ECTypography
                        variant="h5"
                        component="span"
                        sx={theme => ({
                          marginLeft: 0.5,
                          color: theme.palette.background.default,
                        })}
                      >
                        mo
                      </ECTypography>
                    </ECTypography>
                    <ECTypography
                      sx={theme => ({
                        marginTop: 1,
                        color: theme.palette.background.default,
                      })}
                    >
                      Subscription Date:{' '}
                      <ECTypography
                        component="span"
                        sx={theme => ({
                          fontWeight: 'bold',
                          color: theme.palette.background.default,
                        })}
                      >
                        {subscription.startTime
                          ? DateTime.fromISO(subscription.startTime).toFormat(
                              'MMM dd, yyyy',
                            )
                          : ''}
                      </ECTypography>
                    </ECTypography>
                    <ECTypography
                      sx={theme => ({
                        marginTop: 1,
                        color: theme.palette.background.default,
                      })}
                    >
                      Next Charge Date:{' '}
                      <ECTypography
                        component="span"
                        sx={theme => ({
                          fontWeight: 'bold',
                          color: theme.palette.background.default,
                        })}
                      >
                        {subscription?.nextPayment
                          ? DateTime.fromISO(subscription.nextPayment).toFormat(
                              'MMM dd, yyyy',
                            )
                          : ''}
                      </ECTypography>
                    </ECTypography>
                  </>
                ) : (
                  <ECTypography
                    sx={theme => ({
                      height: '50px',
                      marginTop: 2,
                      fontStyle: 'italic',
                      color: theme.palette.grey[500],
                    })}
                  >
                    No subscription details available
                  </ECTypography>
                )}
              </ECBox>
            </ECGrid>

            {/* Features Section (Left) */}
            <ECGrid item xs={3.5}>
              <FeatureList
                features={[
                  'All-in-One Command Center: Stay on top of work orders — all from one easy-to-use dashboard.',
                  'Streamlined Workflows: Crush maintenance tasks to save time and headaches.',
                ]}
              />
            </ECGrid>

            {/* Features Section (Right) */}
            <ECGrid item xs={3.5}>
              <FeatureList
                features={[
                  'Multi-Site Mastery: Effortlessly manage all your locations without breaking a sweat.',
                  'Real-Time Support: Get answers fast and keep things moving.',
                ]}
              />
            </ECGrid>
          </ECGrid>
        </ECBox>
      </ECBox>

      {subscription?.nextPayment && (
        <ECModal
          onClose={handleSubscriptionModal}
          isOpen={isOpenSubscriptionModal}
          sx={{
            width: '600px',
          }}
        >
          <ECBox height={'64px'}>
            <ECTypography variant="h6">We’re sorry to see you go!</ECTypography>
          </ECBox>
          <ECBox height={'64px'}>
            <ECTypography variant="body2">
              You’ll lose access to your all-in-one command center for managing
              work orders, invoices, and more - plus the tools that make running
              every location smooth and stress-free.
            </ECTypography>
          </ECBox>
          <ECBox
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ECAlert
              severity="error"
              sx={{
                '& .MuiAlert-icon': {
                  alignSelf: 'center',
                },
              }}
            >
              {`If you cancel your membership, your access credentials will
            expire on ${formatDateOnlyForTable(subscription.nextPayment)} and will not be renewed. This means you will
            lose access to Ecotrak and all information stored in the system. To
            reactivate your account in the future, please contact Ecotrak
            support.`}
            </ECAlert>
          </ECBox>
          <ECBox
            justifyContent={'flex-end'}
            display={'flex'}
            gap={'8px'}
            marginTop={'10px'}
          >
            <ECButton onClick={handleCancelSubscription}>
              Cancel subscription
            </ECButton>

            <ECButton
              onClick={handleSubscriptionModal}
              variant="contained"
              sx={{
                borderRadius: '12px',
              }}
            >
              Keep subscription
            </ECButton>
          </ECBox>
        </ECModal>
      )}
    </>
  );
};

export default SubscriptionPlanCard;
