import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { MonetizationOn } from '@mui/icons-material';
import { ECBox, ECButton } from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { ECDrawerDetailsContent } from 'app/components/ECDrawerDetailsContent';
import { StyleConstants } from 'styles/StyleConstants';
import { NewPOInvoiceForm } from './NewPOInvoiceForm';
import { PurchaseOrderStatusType } from 'app/components/ECPurchaseOrderItemsTable';

const statusToShowCreateInvoice = [
  PurchaseOrderStatusType.RECONCILED,
  PurchaseOrderStatusType.PARTIALLY_RECONCILED,
];

export const NewPOInvoice = forwardRef(
  ({ handleChangeIdForSnackBar, purchaseOrder }: any, ref) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<any>(null);

    useImperativeHandle(
      ref,
      () => ({
        openDrawer: data => {
          setData(data);
          setOpen(true);
        },
      }),
      [],
    );

    const handleClose = useCallback(() => {
      if (data) {
        handleChangeIdForSnackBar('invoice', data?.id);
      }
      setOpen(false);
    }, [data]);

    if (
      !statusToShowCreateInvoice?.includes(purchaseOrder?.workflowStatusName)
    ) {
      return null;
    }

    return (
      <ECDrawerDetails
        open={open}
        anchor="right"
        onClose={() => setOpen(false)}
      >
        <ECBox pt={StyleConstants.NAV_BAR_HEIGHT} minHeight="100vh">
          <ECDrawerDetailsContent
            data={{}}
            EditForm={
              <NewPOInvoiceForm
                purchaseOrder={purchaseOrder}
                newPO={data}
                onClose={handleClose}
                onChangeIdForSnackBar={handleChangeIdForSnackBar}
              />
            }
            showOnlyDetailsTab={true}
            onClose={() => setOpen(false)}
            editSelected={true}
            showEditButton={false}
            isCreateNewStep={true}
            hasTopPadding={false}
          />
        </ECBox>
      </ECDrawerDetails>
    );
  },
);
