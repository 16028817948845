import { Description } from '@mui/icons-material';
import { ECButton, ECDynamicPageTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import {
  useLazyGetProposalByIdQuery,
  useLazyGetProposalListQuery,
} from 'services/proposalApi';

export function ProposalsSearch({ mustTriggerListQuery }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const match = useMatch('/panel/*');

  const handleOpenDetails = (row: any) => {
    const url = match ? match.pathname.replace('search', row.moduleName) : '';

    return row.moduleName && row.id ? navigate(`${url}/${row.id}`) : undefined;
  };

  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      pageTitle={t('translation:panelDrawer.proposals')}
      useLazyGetListQuery={useLazyGetProposalListQuery}
      useLazyGetByIdQuery={useLazyGetProposalByIdQuery}
      useCreateMutation={{}}
      moduleName="proposal"
      mustTriggerListQuery={mustTriggerListQuery}
      usingGlobalSearch={true}
      withDrawer={false}
      marginTop={false}
      enableExport
      downloadFileName="proposals.csv"
      onRowClick={handleOpenDetails}
      ignoreHyperlink={true}
      moduleRedirectElement={
        <ECButton
          sx={{ marginBottom: '2rem' }}
          variant="contained"
          onClick={() => navigate(`${match?.pathnameBase}/proposals`)}
          startIcon={<Description />}
        >
          Proposals
        </ECButton>
      }
      shouldNotUseActiveFilter
    />
  );
}
