import { Address } from './Address';
import { BranchDetail } from './Branch';
import { ServiceProviderProfile } from './ServiceProviderTypes';

export interface Company {
  id: number;
  name: string;
  email: string;
  logo: string;
  companyType: CompanyType;
  industryId: number;
  phone?: string;
  phoneExt?: string;
  parentId?: number;
  spProfile?: ServiceProviderProfile;
}

export interface CompanyEntity {
  id: number;
  name: string;
  email: string;
  phone: string;
  phoneExt?: string;
  address?: Address;
  linkIsActive?: boolean;
  invitationIsPending?: boolean;
}

export interface CompanyQueryParams {
  name?: string;
  email?: string;
  phone?: string;
}

export interface CompanyResponse {
  addressId: number;
  id: number;
  createdAt: string;
  logo: string;
  name: string;
  type: number;
  phone?: string;
}

export enum CompanyTypeId {
  Customer = 1,
  SP = 2,
}

export enum CompanyTypeCode {
  Customer = 'C',
  SP = 'SP',
}

export interface CompanyType {
  code: CompanyTypeCode;
  id: number;
  name: string;
}

export interface Locale {
  id: number;
  localeCode: string;
  languageCode: string;
  currencyCode: string;
  currencySymbol: string;
  measurementSystem: string;
  measurementUnit: string;
  displayName: string;
}

export interface CustomerProfile {
  companyId?: number;
  proposalDocumentRequired?: number;
  invoiceDateRequired?: number;
  invoiceDocumentRequired?: number;
  invoiceCreditEnabled?: number;
  woUnassignCommentRequired?: number;
  enableWoReassign?: number;
  enableWoReview?: number;
  enablePmReassign?: number;
  enableWoPriorityReassign?: number;
  enablePmPriorityReassign?: number;
  enableRejectReason?: number;
  enableCoupa?: number;
  enableSynuma?: number;
  enableInventory?: number;
  enableDispatch?: number;
  synumaHost?: number | string;
  synumaHmacSecret?: number | string;
  synumaApiHost?: number | string;
  synumaApiKey?: number | string;
  costCenterEnabled?: number;
  enableArea?: number;
  enableBudget?: number;
  spInvitationTtl?: number;
  enableInventoryMarkup?: number;
  enablePriceEdit?: number;
  enableRefrigerantTracking?: number;
  enableOnboardingDashboard?: number;
  locale?: Locale;
}

export interface Contact {
  id: string;
  addressId: number;
  address?: Address;
  email: string;
  fax: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface PaymentInfo {
  id: number;
  name: string | null;
  type: string;
  lastFour: string;
  expiration: string;
  routingNumber?: string | null;
  subscription?: {
    id: number;
    startTime: any;
    payPerPeriod: number;
    nextPayment: any;
  };
}

export interface SupplierIntegration {
  enabled: number;
  supplierId: number;
  title: string;
  supplierName: string;
  logo: string;
}

export interface CompanyProfile {
  viewId: string;
  createdBy: number;
  createdAt: string;
  updatedBy: number;
  updatedAt: string;
  id: number;
  name: string;
  email: string;
  phone: string;
  formattedPhone: string;
  logo: string;
  addressId: number;
  companyTypeId: number;
  phoneExt: string;
  phone24: string;
  formattedPhone24: string;
  phone24Ext: string;
  billingAddressId: number;
  isBillingSameAsAddress: string[];
  fein?: string;
  dba: string;
  federalTaxId: number;
  sic: SIC;
  branches: BranchDetail[];
  address?: Address;
  billingAddress?: Address;
  spProfile: ServiceProviderProfile;
  enableTimeTrack?: number;
  federalTaxClassification: FederalTax;
  companyType: CompanyType;
  industryId: number;
  industry: Industry;
  moduleName: string;
  customerProfile: CustomerProfile;
  contacts: Contact[];
  contact?: Contact;
  advanceMobileDashboard?: boolean;
  assignOnCall?: boolean;
  workorderSurvey?: boolean;
  parentId?: number;
  smsEnabled?: number;
  paymentInfo?: PaymentInfo[];
  subscription?: SubscritionStatus;
  si?: {
    [key: string]: SupplierIntegration;
  };
  totalBranchCount?: number;
  hitMaxBranchLimit?: boolean;
  locale?: Locale;
}

interface SubscritionStatus {
  code: string;
  name: string;
  score: number;
  cancellationTime?: string;
  companyHasTrialSubscription?: boolean;
  nextChargeDate?: string;
  daysNumber?: number;
  isEzTrak?: boolean;
  maxBranchCount?: number;
}

interface FederalTax {
  id: number;
  name: string;
}

interface Industry {
  name: string;
}
interface SIC {
  name?: string;
  code?: string;
  id: number;
}

export interface CompanyConfiguration {
  costCenterEnabled: number;
  defaultGlRequestCategoryId: number;
  enableArea: number;
  enableBudget: number;
  enableCoupa: number;
  enableRejectReason: number;
  enableWorkday: number;
  invoiceDateRequired: number;
  invoiceDocumentRequired: number;
  maintenanceWorkOrderAllotedTime: any[];
  pmPriorityWoAutomationPreferenceId: number;
  pmPriorityWoAutomationPreferenceName: string;
  pmRejectWoAutomationPreferenceId: number;
  pmRejectWoAutomationPreferenceName: string;
  priorityWoAutomationPreferenceId: number;
  priorityWoAutomationPreferenceName: string;
  proposalDocumentRequired: number;
  spRejectWoAutomationPreferenceId: number;
  spRejectWoAutomationPreferenceName: string;
  workOrderAllotedTime: any[];
  enableInventory: number;
  enableInventoryMarkup: number;
}
