import { DeleteForever } from '@mui/icons-material';
import { ECChipAutocomplete } from 'app/components/ECChipAutocomplete';
import ECCurrencyField from 'app/components/ECCurrencyField';
import { ECIconButton } from 'app/components/ECIconButton';
import { ECPhoneField } from 'app/components/ECPhoneField';
import { ECMenuItem, ECSelect } from 'app/components/ECSelect';
import { ColType, ECTableCell } from 'app/components/ECTable';
import { ECTextField } from 'app/components/ECTextField';
import _ from 'lodash';
import { Chip } from 'types/Chip';
import { TableCellEditableFieldMap as ITableCellEditableFieldMap } from 'types/core/ECTable';
import { cleanedPhoneNumber } from 'utils/strings/phone';

/**
 * TableCellEditableFieldsMap is responsible for storing
 * the possible fields to be rendered based
 * on the type of the column and also to
 * pass custom data to the fields if needed
 * see the type ITableCellEditableFieldMap
 */
export const TableCellEditableFieldsMap = {
  [ColType.ChipAutocomplete]: ({ col, index, row, isNestedCell, onChange }) => {
    return (
      <ECTableCell
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECChipAutocomplete
          loading={false}
          options={(col?.options as Chip[]) || []}
          title={col.title}
          values={
            Array.isArray(row?.[col.dataPath]) && col['chipTitleDataPath']
              ? (row[col.dataPath].map(x => {
                  return {
                    label: x[col['chipTitleDataPath'] || 'name' || 'label'],
                    id: x.id,
                  };
                }) as Chip[])
              : undefined
          }
          getOptionLabel={option => {
            return option?.label || option?.name || option?.text || option;
          }}
          onChange={(newValue, event, action) => {
            onChange?.(newValue, col, row, undefined, index, action);
          }}
          readOnly={col.disabled}
          key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        />
      </ECTableCell>
    );
  },
  [ColType.Select]: ({ col, index, row, onChange }) => {
    return (
      <ECTableCell
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECSelect
          id={`${col?.title}-${col?.fieldName}-${row?.id}`}
          key={`${col?.title}-${col?.fieldName}-${row?.id}`}
          name={`${col?.title}-${col?.fieldName}-${row?.id}`}
          variant="filled"
          hiddenLabel
          sx={{
            width: '100%',
            '&:focus': {
              borderColor: 'white',
            },
            '&:before': {
              borderColor: 'white',
            },
            '&:after': {
              borderColor: 'white',
            },
          }}
          value={row?.[col.fieldName]?.[col.dataPath]}
          renderValue={() => {
            return row?.[col.fieldName]?.[col.dataPath];
          }}
          onChange={event => {
            onChange?.(event.target.value, col, row, undefined, index);
          }}
        >
          {Array.isArray(col.options) &&
            col.options?.map((option, index) => {
              return (
                <ECMenuItem
                  value={
                    col.optionValues ? col.optionValues[index] : option.label
                  }
                  key={`${option?.title}-${index}`}
                  disabled={option.disabled}
                >
                  {option.label}
                </ECMenuItem>
              );
            })}
        </ECSelect>
      </ECTableCell>
    );
  },
  [ColType.Currency]: ({ col, index, row, onChange, cellData }) => {
    return (
      <ECTableCell
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
        sx={{ minWidth: '150px', ...col.optionalSx }}
      >
        <ECCurrencyField
          id={col.fieldName}
          value={cellData}
          onChange={(event, value) => {
            onChange?.(value, col, row, undefined, index);
          }}
          InputProps={{
            disableUnderline: true,
          }}
        />
      </ECTableCell>
    );
  },
  [ColType.Phone]: ({ col, index, row, onChange, cellData }) => {
    return (
      <ECTableCell
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
        sx={{ minWidth: '150px', ...col.optionalSx }}
      >
        <ECPhoneField
          id={`${col?.title}-${col?.fieldName}-${row?.id}`}
          name={`${col?.title}-${col?.fieldName}-${row?.id}`}
          key={`${col?.title}-${col?.fieldName}-${row?.id}`}
          onChange={e => {
            e.preventDefault();
            onChange?.(e.target.value, col, row, undefined, index);
          }}
          value={cleanedPhoneNumber(cellData)}
        />
      </ECTableCell>
    );
  },
  [ColType.DeleteButton]: ({ col, index, row, onChange }) => {
    return (
      <ECTableCell
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
        sx={{ minWidth: '10px' }}
      >
        <ECIconButton
          onClick={() => onChange?.('delete', col, row, undefined, index)}
          size="small"
        >
          <DeleteForever color="error" />
        </ECIconButton>
      </ECTableCell>
    );
  },
  [ColType.Number]: ({ col, index, row, onChange, cellData }) => {
    return (
      <ECTableCell
        key={`${col?.title}-${col?.fieldName}-${row?.id}`}
        scope="row"
        sx={{ minWidth: '10px', ...col.optionalSx }}
      >
        <ECTextField
          key={`${col?.title}-${col?.fieldName}-${row?.id}`}
          type="number"
          sx={{
            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
              {
                WebkitAppearance: 'none',
                margin: 0,
              },
            'input[type=number]': {
              MozAppearance: 'textfield',
            },
          }}
          onChange={e => {
            e.preventDefault();
            const minValue = col?.minValue;
            const newValue = !_.isNil(minValue)
              ? Math.max(Number(e.target.value), minValue)
              : Number(e.target.value);

            onChange?.(newValue, col, row, undefined, index);
          }}
          value={cellData}
          inputProps={{
            min: col?.minValue || 0,
          }}
        />
      </ECTableCell>
    );
  },
} as ITableCellEditableFieldMap;
