import { ECAlert, ECAlertTitle } from 'app/components/ECAlert';
import { ECBox } from 'app/components/ECBox';
import { ECButton } from 'app/components/ECButton';
import { ECLink } from 'app/components/ECLink';
import { AutoGraph } from '@mui/icons-material';
import { Modal, Typography } from '@mui/material';

type BranchLimitUpgradeModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  maxBranchCount?: number;
};

export const BranchLimitUpgradeModal = ({
  isOpen,
  onClose,
  maxBranchCount,
}: BranchLimitUpgradeModalProps) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ECBox
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 497,
          bgcolor: 'background.paper',
          boxShadow: 24,
          px: 4,
          pt: 4,
          pb: 1,
          borderRadius: 2,
        }}
      >
        <ECAlert severity="info" sx={{ borderRadius: 3, alignItems: 'center' }}>
          <ECAlertTitle color="inherit !important" sx={{ fontWeight: '500' }}>
            You’ve reached your maximum limit of {maxBranchCount || ''}{' '}
            locations.
          </ECAlertTitle>
          <Typography variant="body2" color="inherit !important" gutterBottom>
            Upgrade your plan to add more locations.
          </Typography>
        </ECAlert>
        <ECBox display="flex" justifyContent={'flex-end'} mt={3}>
          <ECButton onClick={onClose} variant="outlined" sx={{ mr: 2 }}>
            Close
          </ECButton>
          <ECLink
            color="inherit"
            href="https://www.ecotrak.com/resources/gated-content/contact-us-form"
          >
            <ECButton
              variant="contained"
              color="primary"
              startIcon={<AutoGraph />}
            >
              Upgrade Your Plan
            </ECButton>
          </ECLink>
        </ECBox>
      </ECBox>
    </Modal>
  );
};
