import { ECBox } from 'app/components/ECBox';
import { ECAlert, ECAlertTitle } from 'app/components/ECAlert';
import { ECTypography } from 'app/components/ECTypography';
import { themes } from 'styles/theme/themes';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ECButton } from 'app/components/ECButton';
import { useCreateServiceRequestContext } from 'store/contexts';

export const NoLocationAlert = () => {
  const navigate = useNavigate();

  const { dispatch } = useCreateServiceRequestContext();

  const handleNavigate = () => {
    dispatch({ type: 'close' });
    navigate('/panel/locations/management?add=true');
  };

  return (
    <ECAlert
      severity="warning"
      sx={{
        '.MuiAlert-message': {
          paddingRight: '26px',
          width: '100% !important',
          height: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        marginBottom: 2,
      }}
    >
      <ECBox
        display={'flex'}
        flexWrap={'wrap'}
        sx={{
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <ECBox>
          <ECAlertTitle color={themes.light.palette.warning.title}>
            <ECTypography
              variant="body2"
              marginRight={'10px'}
              fontWeight={'bold'}
              color={'inherit'}
              fontSize={16}
            >
              Location Required
            </ECTypography>
          </ECAlertTitle>
          <ECTypography variant="body2" marginRight={'10px'} color={'inherit'}>
            Add a Location to submit a Service Request.
          </ECTypography>
        </ECBox>
        <ECButton
          variant="text"
          sx={{
            color: themes.light.palette.warning.title + ' !important',
          }}
          onClick={handleNavigate}
          loadingSpinnerColor={themes.light.palette.warning.title}
          endIcon={<Add />}
        >
          Add Location
        </ECButton>
      </ECBox>
    </ECAlert>
  );
};
