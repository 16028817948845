import {
  useLazySSOloginUserEnhancedQuery,
  useLazyDecideUserLoginQuery,
} from 'services/authApi';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { ECLoginTemplate } from 'app/components/ECLoginTemplate';
import { ECBox } from 'app/components/ECBox';
import { ECButton } from 'app/components/ECButton';
import { LoginType } from 'types/Auth';
import { ECAlertError } from 'app/components/ECAlert';
import { ECEasyForm, FieldTypes } from 'app/components/ECForm';
import { useMediaQuery, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { jwtDecode } from 'jwt-decode';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { LockOpen } from '@mui/icons-material';

const webPage: string = process.env.REACT_APP_URL || '';

export function SSOLoginPage() {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const [emailLogin, setEmaillogin] = useState('');
  const [err, setErr] = useState('');
  const { state } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const existingIdToken = localStorage.getItem('idToken');
    const existingSessionInfo = localStorage.getItem('sessionInfo');
    const existingSessionInfoUserName = localStorage.getItem(
      'sessionInfoUserName',
    );
    if (existingIdToken && existingSessionInfo && existingSessionInfoUserName) {
      navigate('/home');
    }
  }, []);

  const [signinUser, { data, isError, error, isLoading, isSuccess }] =
    useLazyDecideUserLoginQuery();

  let [searchParams, setSearchParams] = useSearchParams();

  const errorMessage = useMemo(() => {
    return searchParams?.get('message');
  }, [searchParams]);

  const [
    signinSSOEnhanced,
    {
      data: dataSSO,
      isError: isErrorSSO,
      error: errorSSO,
      isLoading: isLoadingSSO,
      isSuccess: isSuccessSSO,
    },
  ] = useLazySSOloginUserEnhancedQuery();

  useEffect(() => {
    if (isSuccessSSO && dataSSO) {
      localStorage.setItem('idToken', dataSSO?.token?.id_token);
      localStorage.setItem('accessToken', dataSSO?.token?.access_token);
      localStorage.setItem('isSSO', '1');
      localStorage.setItem('refreshToken', dataSSO?.token?.refresh_token);
      localStorage.setItem('sessionInfo', dataSSO?.decoded?.email);
      localStorage.setItem(
        'sessionInfoUserName',
        dataSSO?.decoded?.['cognito:username'],
      );
      if (dataSSO?.ssoTimeout > 0) {
        localStorage.setItem(
          'customSSOSessionTimeoutTimestamp',
          DateTime.now()
            .plus({ hours: dataSSO?.ssoTimeout })
            .toMillis()
            .toString(),
        );
      } else {
        localStorage.removeItem('customSSOSessionTimeoutTimestamp');
      }
      window.location.replace('/home');
    }
  }, [isSuccessSSO, dataSSO]);

  useEffect(() => {
    if (window.location.hash) {
      const i = window.location.hash.indexOf('id_token=');
      const idToken = window.location.hash.substring(i + 'id_token='.length);
      var justIdToken = idToken.split('&');

      const accessToken = window.location.hash.substring(
        '#access_token='.length,
      );
      const decoded: any = jwtDecode(accessToken);

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('idToken', justIdToken[0]);
      localStorage.setItem('sessionInfo', decoded?.username);
      localStorage.setItem('sessionInfoUserName', decoded?.username);
      window.location.replace('/home');
    }
  }, [window.location.hash]);

  useEffect(() => {
    if (window.location.search) {
      const code = searchParams?.get('code');
      if (code) {
        signinSSOEnhanced({
          authCode: code,
          redirectUrl: encodeURIComponent(webPage),
        });
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (isError && error) {
      setErr(
        (error as any)?.data?.message ||
          (error as any)?.error ||
          (error as any)?.message ||
          'Unknown Error',
      );
    } else if (isErrorSSO && errorSSO) {
      setErr(
        (errorSSO as any)?.data?.message ||
          (errorSSO as any)?.error ||
          'Unknown Error',
      );
    }
  }, [isError, error, isErrorSSO, errorSSO]);

  useEffect(() => {
    if (isSuccess) {
      if (data.type === LoginType.SSO && data?.sso?.url) {
        window.location = data?.sso?.url;
      } else if (data.type === LoginType.OTP) {
        localStorage.setItem('challengeName', data.otp?.challengeName);
        localStorage.setItem('email', data.otp?.email);
        localStorage.setItem('session', data.otp?.session);
        localStorage.setItem('username', data.otp?.username);
        navigate('/otp', {
          state: {
            ...state,
            ...data?.otp,
            email: emailLogin,
            sentEmailLogin: String(emailLogin),
          },
        });
      } else if (data.type === LoginType.MFA) {
        const message =
          (error as any)?.data?.message || (error as any)?.message;
        localStorage.setItem('challengeName', data.mfa?.challengeName);
        localStorage.setItem('email', data.mfa?.email);
        localStorage.setItem('session', data.mfa?.session);
        localStorage.setItem('username', data.mfa?.username);
        navigate(`/login${message ? '?message=' + message : ''}`, {
          state: {
            ...state,
            ...data?.mfa,
            email: emailLogin,
            sentEmailLogin: String(emailLogin),
          },
        });
      } else {
        const message =
          (error as any)?.data?.message || (error as any)?.message;
        navigate(`/login${message ? '?message=' + message : ''}`, {
          state: {
            ...(state as any),
            sentEmailLogin: String(emailLogin),
          },
        });
      }
    }
  }, [isSuccess, data]);

  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>

      <ECLoginTemplate>
        <ECEasyForm
          pattern="standard"
          drawerTitleBarBGColor="transparent"
          fullHeight={false}
          config={{
            variant: 'standard',
            title: 'Login',
            subtitle: '',
            cols: 1,
            submitTitle: 'Next',
            titleStyle: {
              textAlign: isMobileSize ? 'center' : 'left',
            },
          }}
          fields={[
            {
              type: FieldTypes.Text,
              label: 'Email',
              placeholder: 'Email',
              value: null,
              isNumeric: false,
              checkEmail: true,
              required: true,
              minLength: 3,
              fieldName: 'email',
            },
          ]}
          isSendingData={isLoading || isLoadingSSO}
          isLoadingForm={false}
          onFormSubmit={formData => {
            const email = formData.get('email');
            setEmaillogin(String(email));
            signinUser({
              email: `${email}`.toLowerCase(),
              redirectUrl: encodeURIComponent(webPage),
            });
          }}
        />
        {(isLoading || isLoading) && <p>Loading...</p>}
        {(isError || isErrorSSO || errorMessage || err) && (
          <ECAlertError> {err || errorMessage} </ECAlertError>
        )}{' '}
        <ECBox
          p={2}
          px={4}
          display="flex"
          justifyContent={isMobileSize ? 'center' : 'space-between'}
        >
          <ECButton
            variant="text"
            startIcon={<LockOpen />}
            onClick={() => navigate('/forgot-password/send-link')}
            sx={theme => ({
              color: `${theme.palette.common.white} !important`,
            })}
          >
            Reset password
          </ECButton>
        </ECBox>
      </ECLoginTemplate>
    </>
  );
}
