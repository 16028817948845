import { useCallback } from 'react';
import { DashboardTableFilter } from 'types/Dashboard';
import { DashboardTables } from 'utils/constants';

export const persistedDashboardKey = 'dashboardFilters';
export const persistedDashboardFilterFor = 'dashboardFiltersFor';
const persistedUserProfile = 'userProfile';

export const usePersistedDashboardFilters = () => {
  const validateAuthUserDashboardFilters = useCallback(() => {
    const userProfile = localStorage.getItem(persistedUserProfile);
    const userId = userProfile ? JSON.parse(userProfile).userId : null;
    const validationErrorRes = { isValid: false, userId };

    if (!userId) return validationErrorRes;

    const dashboardFilterFor = localStorage.getItem(
      persistedDashboardFilterFor,
    );
    return dashboardFilterFor
      ? { isValid: userId === JSON.parse(dashboardFilterFor), userId }
      : validationErrorRes;
  }, []);

  const getDashboardPersistedFilters = useCallback(() => {
    const dashboardFilters = localStorage.getItem(persistedDashboardKey);
    const parsedDashboardFilters = dashboardFilters
      ? JSON.parse(dashboardFilters)
      : null;
    const authUserFiltersValidation = validateAuthUserDashboardFilters();
    if (authUserFiltersValidation.isValid) {
      return parsedDashboardFilters;
    } else {
      localStorage.setItem(
        persistedDashboardFilterFor,
        authUserFiltersValidation.userId,
      );
      return null;
    }
  }, []);

  const updateDashboardFilter = (
    key: DashboardTables,
    data: DashboardTableFilter,
  ) => {
    const currentDashboardFilters = getDashboardPersistedFilters();
    if (currentDashboardFilters) {
      const newDashboardFilters = currentDashboardFilters.map(filter => {
        if (filter.table === key) {
          return data;
        }
        return filter;
      });
      localStorage.setItem(
        persistedDashboardKey,
        JSON.stringify(newDashboardFilters),
      );
    } else {
      localStorage.setItem(persistedDashboardKey, JSON.stringify([data]));
    }
  };

  const saveDashboardFilters = (data: DashboardTableFilter[]) => {
    if (data) {
      localStorage.setItem(persistedDashboardKey, JSON.stringify(data));
    }
  };

  return {
    getDashboardPersistedFilters,
    saveDashboardFilters,
    updateDashboardFilter,
    validateAuthUserDashboardFilters,
  };
};
