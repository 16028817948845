import { ApiDataWithConfig } from 'types/ApiTableConfig';
import { Filter } from 'types/Filter';
import { emptyApi } from './emptyApi';
import * as _ from 'lodash';
import { convertRouteNameToFilterEndpoint } from './utils';

export interface SavedFilter {
  _id?: string;
  name: string;
  body: Filter[];
  favorite?: boolean;
  module?: string;
}

interface SaveFilterParams {
  endpoint: string;
  body: SavedFilter;
  filterId?: string;
}

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Filter', 'FilterColumns'],
});

interface SavedFiltersResponseObject extends ApiDataWithConfig<SavedFilter> {}
export const filterApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getAllSavedFilters: builder.query<SavedFilter[], string>({
      query: endpoint => endpoint,
      transformResponse: (
        response: SavedFiltersResponseObject,
        _meta,
        endpoint: string,
      ) => {
        const isCurrentRouteTheSameAsFilterEndpoint = _.compact(
          endpoint?.split('/'),
        )?.some(e => convertRouteNameToFilterEndpoint()?.includes(e));

        if (isCurrentRouteTheSameAsFilterEndpoint) {
          return response?.data || [];
        }

        return [];
      },
      providesTags: ['Filter'],
    }),
    getAllSavedColumnsFilters: builder.query<SavedFilter[], string>({
      query: endpoint => `${endpoint}Columns`,
      transformResponse: (response: SavedFiltersResponseObject, _meta) => {
        return response?.data || [];
      },
      providesTags: ['FilterColumns'],
    }),
    saveFilter: builder.mutation<SavedFilter, SaveFilterParams>({
      query: params => {
        const { endpoint, body } = params;

        if (!endpoint) {
          return {
            url: '',
          };
        }

        if (body?.body?.[0]?.isTableLayout) {
          return {
            url: `${endpoint}Columns`,
            method: 'post',
            body,
          };
        }

        return {
          url: endpoint,
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['Filter', 'FilterColumns'],
    }),
    updateFilter: builder.mutation<SavedFilter, SaveFilterParams>({
      query: params => {
        const { endpoint, filterId, body } = params;

        if (!endpoint || !filterId) {
          return {
            url: '',
          };
        }

        if (body?.body?.[0]?.isTableLayout) {
          return {
            url: `${endpoint}Columns/${filterId}`,
            method: 'put',
            body,
          };
        }

        return {
          url: `${endpoint}/${filterId}`,
          method: 'put',
          body,
        };
      },
      async onQueryStarted(
        { endpoint, filterId, body },
        { dispatch, queryFulfilled },
      ) {
        const optimisticGetAllSavedFiltersUpdate = dispatch(
          filterApi.util.updateQueryData(
            'getAllSavedFilters',
            endpoint,
            draft => {
              const newValue = [...draft];
              const index = newValue?.findIndex(filter =>
                filter?.body?.some(body => body.isTableLayout),
              );
              newValue[index] = body;
              return newValue;
            },
          ),
        );
        const optimisticGetAllSavedColumnsFilter = dispatch(
          filterApi.util.updateQueryData(
            'getAllSavedColumnsFilters',
            endpoint,
            draft => {
              const newValue = [...draft];
              const index = newValue?.findIndex(filter =>
                filter?.body?.some(body => body.isTableLayout),
              );
              newValue[index] = body;
              return newValue;
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          optimisticGetAllSavedFiltersUpdate.undo();
          optimisticGetAllSavedColumnsFilter.undo();
        }
      },
      invalidatesTags: ['Filter', 'FilterColumns'],
    }),
    deleteFilter: builder.mutation<void, string>({
      query: endpoint => {
        return {
          url: endpoint,
          method: 'delete',
        };
      },
      invalidatesTags: ['Filter'],
    }),
    updateFavoriteFilter: builder.mutation<void, any>({
      query: ({ endpoint, filterId, isFavorite }) => {
        return {
          url: `${endpoint}/${filterId}/favorite/${isFavorite ? 1 : 0}`,
          method: 'put',
        };
      },
      invalidatesTags: ['Filter'],
    }),
  }),
});

export const {
  useSaveFilterMutation,
  useGetAllSavedFiltersQuery,
  useLazyGetAllSavedFiltersQuery,
  useGetAllSavedColumnsFiltersQuery,
  useLazyGetAllSavedColumnsFiltersQuery,
  useDeleteFilterMutation,
  useUpdateFavoriteFilterMutation,
  useUpdateFilterMutation,
} = filterApi;
