import { ECBox, ECTypography } from 'app/components';
import { ECToggleButton } from 'app/components/ECToggleButton';
import React from 'react';
import { themes } from 'styles/theme/themes';
import { useTheme } from '@mui/material/styles';

type SectorToggleButtonProps = {
  value: any;
  selected: boolean;
  onClick: () => void;
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  sx?: object;
};

const SectorToggleButton: React.FC<SectorToggleButtonProps> = ({
  value,
  selected,
  onClick,
  icon,
  title,
  subtitle,
  sx,
}) => {
  const theme = useTheme();

  return (
    <ECToggleButton
      value={value}
      selected={selected}
      onClick={onClick}
      selectedBgColor={theme.palette.background.sectionBg}
      hoverColor={themes.light.palette.info.outlinedHoverBackground}
      sx={{
        width: 'auto',
        height: '76px',
        maxWidth: '100%',
        textAlign: 'left',
        flexDirection: 'column',
        alignItems: 'flex-start',
        border: 0,
        borderRadius: 2,
        padding: 1,
        ...sx,
      }}
    >
      <ECBox display="flex" alignItems="center" gap={1}>
        <ECBox
          sx={theme => ({
            width: 44,
            height: 44,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: selected
              ? theme.palette.grey[600]
              : theme.palette.common.white,
            color: selected
              ? theme.palette.common.white
              : theme.palette.grey[600],
            borderRadius: 2,
          })}
        >
          {icon}
        </ECBox>
        <ECBox>
          <ECTypography
            variant="body1"
            sx={{ textTransform: 'none', fontWeight: 500 }}
          >
            {title}
          </ECTypography>
          <ECTypography
            variant="subtitle2"
            sx={theme => ({
              textTransform: 'none',
              color: theme.palette.text.secondary,
            })}
          >
            {subtitle}
          </ECTypography>
        </ECBox>
      </ECBox>
    </ECToggleButton>
  );
};

export default SectorToggleButton;
