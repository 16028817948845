import { Check, ViewColumn } from '@mui/icons-material';
import { ECBox } from 'app/components/ECBox';
import { FieldTypes } from 'app/components/ECForm';
import { ECIconButton } from 'app/components/ECIconButton';
import { ECPopover } from 'app/components/ECPopover';
import { ECTypography } from 'app/components/ECTypography';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetAllSavedColumnsFiltersQuery,
  useSaveFilterMutation,
  useUpdateFilterMutation,
} from 'services/filterApi';
import { RootState } from 'store/configureStore';

export const ECColumnVisibilityFilter: React.FC = () => {
  const pageState = useSelector((state: RootState) => state.page);

  const pageContext = useMemo(() => {
    return pageState?.context?.[window.location.pathname];
  }, [pageState]);

  const endpoint = useMemo(() => {
    return pageContext?.endpoint;
  }, [pageContext]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleViewColumns = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openViewColumns = Boolean(anchorEl);
  const idViewColumns = openViewColumns ? 'view-columns-popover' : undefined;

  const handleCloseViewColumns = () => {
    setAnchorEl(null);
  };

  const visibleColFromBE = useMemo(
    () => pageContext?.columns?.filter(col => col.visible),
    [pageContext],
  );

  const { data: allSavedColumnsFilters } = useGetAllSavedColumnsFiltersQuery(
    endpoint,
    {
      skip: !endpoint,
    },
  );

  const tableLayoutFilter = useMemo(
    () => allSavedColumnsFilters?.[0],
    [allSavedColumnsFilters],
  );

  const [doSaveFilter] = useSaveFilterMutation();
  const [doUpdateFilter] = useUpdateFilterMutation();
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  useEffect(() => {
    if (tableLayoutFilter?.body?.[0]?.visibleColumns) {
      setVisibleColumns(tableLayoutFilter?.body?.[0]?.visibleColumns || []);
    } else {
      setVisibleColumns(
        visibleColFromBE?.map(col => col.label || col.title) || [],
      );
    }
  }, [tableLayoutFilter, visibleColFromBE]);

  const handleToggleColVisibiltyClick = (colTitle: string) => () => {
    const newVisibleColumns = visibleColumns?.includes(colTitle)
      ? visibleColumns?.filter(col => col !== colTitle)
      : [...visibleColumns, colTitle];

    if (
      newVisibleColumns?.length < 2 &&
      !newVisibleColumns?.includes(colTitle)
    ) {
      return;
    }

    if (tableLayoutFilter) {
      doUpdateFilter({
        endpoint,
        filterId: tableLayoutFilter?._id,
        body: {
          name: '',
          body: [
            {
              ...tableLayoutFilter?.body?.[0],
              type: FieldTypes.Text,
              label: '',
              fieldName: '',
              value: '',
              isTableLayout: true,
              visibleColumns: newVisibleColumns,
            },
          ],
        },
      });
    } else {
      doSaveFilter({
        endpoint,
        body: {
          name: '',
          body: [
            {
              type: FieldTypes.Text,
              label: '',
              fieldName: '',
              value: '',
              isTableLayout: true,
              visibleColumns: newVisibleColumns,
            },
          ],
        },
      });
    }
  };
  return (
    <Fragment>
      <ECBox>
        <ECIconButton
          size="medium"
          withBorder
          onClick={handleViewColumns}
          sx={theme => ({
            '&:hover': { backgroundColor: 'transparent' },
            color: theme.palette.primary.main || 'blue',
          })}
        >
          <ViewColumn />
        </ECIconButton>
      </ECBox>
      <ECPopover
        id={idViewColumns}
        open={openViewColumns}
        anchorEl={anchorEl}
        onClose={handleCloseViewColumns}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {visibleColFromBE?.map((col, colIndex) => {
          const isSelected = visibleColumns?.includes(
            col?.label || col.fieldName,
          );
          return (
            <ECBox
              key={`${col.fieldName}-${colIndex}`}
              sx={{ cursor: 'pointer' }}
              onClick={handleToggleColVisibiltyClick(
                col?.label || col.fieldName,
              )}
              p={1}
              gap={3}
              display="flex"
              bgcolor={theme =>
                isSelected
                  ? theme.palette.primary.outlinedHoverBackground
                  : theme.palette.common.white
              }
            >
              <Check
                sx={theme => ({
                  visibility: isSelected ? 'visible' : 'hidden',
                  color: theme.palette.primary.main,
                })}
              />
              <ECTypography>{col.label || col.title}</ECTypography>
            </ECBox>
          );
        })}
      </ECPopover>
    </Fragment>
  );
};
