import { DateTime, Duration } from 'luxon';

interface FormatDateOptionsStrict {
  raw: Date | string | null | undefined;
  format?: string;
  fallback: string;
}

interface FormatDateOptionsOptional {
  raw: Date | string | null | undefined;
  format?: string;
  fallback?: string | null;
}

export function formatHoursWithAmOrPm(hour: number, minutes: number) {
  const formatedHour = `${hour > 12 ? hour - 12 : hour}`.padStart(2, '0');
  const formatedMinutes = `${minutes}`.padStart(2, '0');
  return `${formatedHour}:${formatedMinutes} ${hour >= 12 ? 'PM' : 'AM'}`;
}

export function formatHoursAndMinutes(hour: number, minutes: number) {
  const formatedHour = `${hour}`.padStart(2, '0');
  const formatedMinutes = `${minutes}`.padStart(2, '0');
  return `${formatedHour}:${formatedMinutes}`;
}

/**
 * This function converts a decimal representation of hours into a human-readable string format
 * @param {number} hours - The decimal representation of time (e.g., 3.5 for 3 hours and 30 minutes).
 * @returns {string} The formatted string representing the time (e.g., "3 hr(s) 30 min(s)").
 */
export function formatDecimalHours(hours: number): string {
  if (hours < Number.EPSILON) return '0 min';

  const duration = Duration.fromObject({ hours });
  const hrs = Math.floor(duration.as('hours'));
  const mins = Math.round(duration.as('minutes') % 60);
  return `${hrs ? `${hrs} hr(s)` : ''} ${mins ? `${mins} min(s)` : ''}`.trim();
}

// also ignores system timezone difference;
export function convertToISOString(date?: Date | string): string | undefined {
  if (!date) return undefined;
  let dt: DateTime;
  if (typeof date === 'string') {
    dt = DateTime.fromISO(date, { setZone: true });
    if (!dt.isValid) {
      const parsed = new Date(date);
      dt = DateTime.fromJSDate(parsed, { zone: 'utc' });
    }
  } else {
    dt = DateTime.fromJSDate(date, { zone: 'utc' });
  }
  return dt.toUTC().toISO() || undefined;
}

// overload signatures:
export function customDateFormatter(options: FormatDateOptionsStrict): string;
export function customDateFormatter(
  options: FormatDateOptionsOptional,
): string | null;

export function customDateFormatter({
  raw,
  format = 'MM/dd/yyyy',
  fallback = null,
}: {
  raw: Date | string | null | undefined;
  format?: string;
  fallback?: string | null;
}): string | null {
  if (!raw) return fallback;
  let dt: DateTime;
  if (raw instanceof Date) {
    dt = DateTime.fromJSDate(raw);
  } else {
    dt = DateTime.fromISO(String(raw));
    if (!dt.isValid) {
      const parsedDate = new Date(raw);
      if (!isNaN(parsedDate.getTime())) {
        dt = DateTime.fromJSDate(parsedDate);
      }
    }
  }
  return dt?.isValid ? dt.toFormat(format) : fallback;
}

export function normalizeToDateTime(
  input: any,
  dateFormat: string = 'MM/dd/yyyy',
): DateTime | null {
  if (!input) return null;

  if (typeof input === 'string') {
    const isoDate = DateTime.fromISO(input);
    if (isoDate.isValid) {
      return isoDate;
    }
    return DateTime.fromFormat(input, dateFormat);
  }

  if (input instanceof Date) {
    return DateTime.fromJSDate(input);
  }

  if (input?.isValid) {
    return input;
  }

  return null;
}
