import { DateTime } from 'luxon';

export default function daysPassedSince(
  date: string | Date,
  withSuffix = true,
): string {
  if (!date) {
    return 'N/A';
  }

  let dt: DateTime;
  if (typeof date === 'string') {
    dt = DateTime.fromISO(date);
    if (!dt.isValid) {
      dt = DateTime.fromFormat(date, 'MM/dd/yyyy, hh:mm a');
    }
  } else {
    dt = DateTime.fromJSDate(date);
  }

  if (!dt.isValid) {
    return 'N/A';
  }

  const today = DateTime.now().startOf('day');
  const mdate = dt.startOf('day');
  const diffDays = Math.floor(today.diff(mdate, 'days').days);

  return `${diffDays}${withSuffix ? ' days' : ''}`;
}
