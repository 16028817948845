import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { styled } from '@mui/material/styles';
import { ECTextField } from '../ECTextField';
import { LocalizationProvider as XLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns as XAdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const ECTimePicker = styled(TimePicker)(({ theme }) => ({}));

export interface ECBasicTimePickerProps {
  value: any;
  label: string;
  onChange: any;
  sx: any;
  error?: boolean | null;
  helperText?: string | null;
  fieldName?: string;
  variant?: 'filled' | 'outlined' | 'standard' | undefined;
  inputProps?: any;
}

export const ECBasicTimePicker = (props: ECBasicTimePickerProps) => {
  const {
    value,
    label,
    onChange,
    sx,
    error,
    helperText,
    fieldName,
    variant,
    inputProps,
  } = props;

  return (
    <XLocalizationProvider dateAdapter={XAdapterDateFns}>
      <ECTextField
        inputProps={inputProps}
        type="time"
        sx={sx}
        variant={variant}
        id={fieldName}
        name={fieldName}
        label={label}
        value={value}
        onChange={onChange}
        error={error === true}
        helperText={helperText ? helperText : null}
      />
    </XLocalizationProvider>
  );
};
