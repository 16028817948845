import {
  FilterAlias,
  GetAliasForSearchArgs,
  GetCustomDataForFilterArgs,
  GetTypeForSearchArgs,
} from 'types/store/pageFilters';

/**
 * getAliasFromPath allows us to get custom aliases for a filter
 * based on the path, I.e the page.
 */
const getAliasFromPath = ({ defaultAlias, options }: GetAliasForSearchArgs) => {
  const curPath = window.location.pathname;
  const aliasMatch = options.find(option => {
    return option.path === curPath;
  })?.alias;

  return aliasMatch ?? defaultAlias;
};
/**
 * getTypeForArraySearch allows us to get custom types for a filter
 * based on the path, I.e the page.
 */
const getTypeForArraySearch = ({
  defaultType,
  options,
}: GetTypeForSearchArgs) => {
  const curPath = window.location.pathname;
  const typeMatch = options.find(option => {
    return option.path === curPath;
  })?.type;

  return typeMatch ?? defaultType;
};

/**
 * getCustomParamsFromPath allows us to get custom params for a filter
 * based on the path, I.e the page.
 */
const getCustomParamsFromPath = ({
  options,
  defaultParams,
}: GetCustomDataForFilterArgs) => {
  const curPath = window.location.pathname;
  const customParamsMatch = options.find(option => {
    return option.path === curPath;
  })?.data;

  return customParamsMatch ?? defaultParams ?? {};
};

// Remember to update paths to include SP side if needed
/**
 * ActiveFilterAlias, AKA Filters Config, it's how we tell which handler
 * and which alias should be set for the filter in a given path or by default.
 */
export const ActiveFilterAlias = (): FilterAlias => {
  return {
    statuses: {
      alias: getAliasFromPath({
        defaultAlias: 'st',
        options: [
          {
            path: '/panel/work-orders',
            alias: 'wrkflwstts.groupNameId',
          },
        ],
      }),
      handler: 'activeStatus',
    },
    missingVendorId: { alias: 'missingVendorId', handler: 'default' },
    statusAlias: { alias: 'stal', handler: 'default' },
    isAdvanced: { alias: 'so', handler: 'toggle' },
    setBy: { alias: 'setBy', handler: 'default' },
    assetType: { alias: 'assetTypeAssignedToMe', handler: 'toggle' },
    assignedLocations: { alias: 'ignoreAssignedLocations', handler: 'toggle' },
    assignedToMe: { alias: 'assignedToMe', handler: 'toggle' },
    selfPerforming: { alias: 'self-perform', handler: 'toggle' },
    awaitingInvoice: { alias: 'awaitingInvoice', handler: 'toggle' },
    activeStatus: {
      alias: getAliasFromPath({
        defaultAlias: 'active',
        options: [],
      }),
      handler: 'toggle',
    },
    techNotAssigned: {
      alias: 'techNotAssigned',
      handler: 'toggle',
    },
    assetTypes: {
      alias: getAliasFromPath({
        defaultAlias: 'assttp.id',
        options: [
          {
            path: '/panel/configuration/accounting/gl-codes',
            alias: 'gnrlldgr.assetTypeId',
          },
        ],
      }),
      handler: 'arraySearch',
      customParams: {
        sbo: 'in',
      },
    },
    locations: {
      alias: getAliasFromPath({
        defaultAlias: 'brnch.id',
        options: [
          {
            path: '/panel/configuration/accounting/gl-codes',
            alias: 'gnrlldgr.branchId',
          },
          {
            path: '/panel/inventory-parts',
            alias: 'invntrystrg.inventoryStorageId',
          },
          {
            path: '/panel/inventory-parts/inventory-list',
            alias: 'invntrystrg.inventoryStorageId',
          },
        ],
      }),
      handler: 'arraySearch',
      customParams: {
        sbo: 'in',
      },
    },
    concepts: {
      alias: getAliasFromPath({
        defaultAlias: 'brnchtp.id',
        options: [
          {
            path: '/panel/configuration/accounting/gl-codes',
            alias: 'gnrlldgr.branchTypeId',
          },
        ],
      }),
      handler: 'arraySearch',
      customParams: {
        sbo: 'in',
      },
    },
    manufacturer: {
      alias: getAliasFromPath({
        defaultAlias: 'mnfctr.id',
        options: [],
      }),
      handler: getTypeForArraySearch({
        defaultType: 'arraySearch',
        options: [
          {
            path: '/panel/configuration/users/management',
            type: 'companyFilter',
          },
          {
            path: '/panel/configuration/users/job-titles',
            type: 'companyFilter',
          },
        ],
      }),
      customParams: getCustomParamsFromPath({
        options: [
          {
            path: '/panel/configuration/users/management',
            data: {},
          },
          {
            path: '/panel/configuration/users/job-titles',
            data: {},
          },
        ],
        defaultParams: {
          sbo: 'in',
        },
      }),
    },
    internalTechs: {
      alias: getAliasFromPath({
        defaultAlias: 'spAssigneeUserId',
        options: [
          {
            path: '/panel/work-orders',
            alias: 'wrkordr.spAssigneeUserId',
          },
        ],
      }),
      handler: 'arraySearch',
      customParams: {
        sbo: 'in',
      },
    },
    eta: {
      alias: getAliasFromPath({
        defaultAlias: 'eta',
        options: [
          {
            path: '/panel/work-orders',
            alias: 'wrkordr.eta',
          },
        ],
      }),
      customParams: getCustomParamsFromPath({
        options: [
          {
            path: 'panel/notifications',
            data: {
              dateSearchGroup: 'date',
              dateAliasFromProp: 'sent',
            },
          },
        ],
      }),
      handler: 'daysSearch',
    },
    dateAfter: {
      alias: 'dateAfter',
      handler: 'skip',
    },
    dateBefore: {
      alias: 'dateBefore',
      handler: 'skip',
    },
    dateAt: {
      alias: 'dateAt',
      handler: 'skip',
    },
    dateAlias: {
      alias: 'dateAlias',
      handler: 'skip',
    },
    dateAliasLabel: {
      alias: 'dateAliasLabel',
      handler: 'skip',
    },
    dateRangeFilterType: {
      alias: 'dateRangeFilterType',
      handler: 'defaultDateSearch',
      customParams: getCustomParamsFromPath({
        options: [
          {
            path: 'panel/notifications',
            data: {
              dateSearchGroup: 'date',
              dateAliasFromProp: 'sent',
            },
          },
        ],
      }),
    },
    hierarchy: {
      alias: getAliasFromPath({
        defaultAlias: 'prrt.id',
        options: [],
      }),
      handler: 'hierarchy',
    },
    categories: {
      alias: getAliasFromPath({
        defaultAlias: 'ctgry.id',
        options: [
          {
            path: '/panel/work-orders',
            alias: 'rqstctgry.id',
          },
        ],
      }),
      handler: 'arraySearch',
    },
    customers: {
      alias: getAliasFromPath({
        defaultAlias: 'cmpny.id',
        options: [],
      }),
      handler: 'arraySearch',
    },
    filterFields: {
      alias: 'filterFields',
      handler: 'skip',
    },
    simpleSearchQuery: {
      alias: 'simpleSearchQuer',
      handler: 'searchFields',
    },
  };
};
