import { Download } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { ECBox } from 'app/components/ECBox';
import ECExportPopover, {
  ExportFormat,
  ExportType,
} from 'app/components/ECExportPopover';
import { ECIconButton } from 'app/components/ECIconButton';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useRequestExportMutation } from 'services/exportApi';
import { RootState } from 'store/configureStore';
import { setSnackbar } from 'store/slice/page';
import { ExportActionProps } from 'types/core/Actions';
import { populateQueryParamsFromFilters } from 'utils/core/table';

export const ECExportAction: React.FC<ExportActionProps> = ({
  exportParams,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showExportPopover, setShowExportPopover] = useState(false);
  const [URLSearchParams] = useSearchParams();
  const pageState = useSelector((state: RootState) => state.page);

  const pageFilters = useMemo(() => {
    return pageState?.filter?.activeFilter?.[pageState?.filter?.endpoint];
  }, [pageState]);

  const pageContext = useMemo(() => {
    return pageState?.context?.[window.location.pathname];
  }, [pageState]);

  const [exportAnchorEl, setExportAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [
    doRequestExport,
    {
      isError: exportError,
      error: exportErrorDetails,
      isLoading: isExportLoading,
      isSuccess: exportSuccess,
    },
  ] = useRequestExportMutation();

  const handleExportRequest = useCallback(
    (exportType: ExportType, format: ExportFormat) => {
      const updatedQueryParams = {
        t: Number(URLSearchParams.get('perPage') || 50),
        p: Number(URLSearchParams.get('page') || 0),
      } as Record<string, any>;
      if (exportType === ExportType.ALL) {
        delete updatedQueryParams.t;
        delete updatedQueryParams.p;
      }
      populateQueryParamsFromFilters(
        pageFilters,
        pageContext,
        updatedQueryParams,
      );

      if (exportParams?.jobName && exportParams?.exportModule) {
        doRequestExport({
          jobName: exportParams?.jobName,
          exportModule: exportParams?.exportModule,
          // if the user selected EXCEL in the dropdown, we add excel param. Otherside send nothing
          tradeId: exportParams?.tradeId,
          branchId: exportParams?.branchId,
          userId: exportParams?.userId,
          queryParams:
            format === ExportFormat.EXCEL
              ? { ...updatedQueryParams, excel: 1 }
              : updatedQueryParams,
        });
      } else {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: t('translation:table.export.missingModuleName'),
          }),
        );
      }
    },
    [exportParams, pageFilters, pageContext],
  );

  useEffect(() => {
    setShowExportPopover(false);
    if (exportSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: t('translation:table.export.successfulRequest'),
        }),
      );
    } else if (exportError) {
      if (exportErrorDetails) {
        if ('data' in exportErrorDetails) {
          dispatch(
            setSnackbar({
              severity: 'error',
              message: (exportErrorDetails as { data: { message: string } })
                .data?.message,
            }),
          );
        }
      }
    }
  }, [exportError, exportSuccess, exportErrorDetails]);

  const handleExportClick = (e: any) => {
    setExportAnchorEl(e.currentTarget);
    setShowExportPopover(true);
  };

  return (
    <ECBox>
      <ECIconButton
        size="medium"
        withBorder
        onClick={handleExportClick}
        sx={{ '&:hover': { backgroundColor: 'transparent' } }}
      >
        {pageContext?.isLoadingRequest ? (
          <CircularProgress size={24} />
        ) : (
          <Download sx={theme => ({ color: theme.palette.grey[600] })} />
        )}
      </ECIconButton>
      <ECExportPopover
        open={showExportPopover}
        anchorEl={exportAnchorEl}
        onClose={() => setShowExportPopover(false)}
        loading={isExportLoading}
        handleExportRequest={handleExportRequest}
      />
    </ECBox>
  );
};
