import { useState, useRef, useEffect, useCallback } from 'react';
import { ECTextField } from '../ECTextField';
import { Box } from '@mui/material';
import { ECChip } from '../ECChip';
import { ECTypography } from '../ECTypography';

interface ECTagInputProps {
  initialTags: string[];
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  onDuplicate?: () => void;
  onChange: (tags: string[]) => void;
  readOnly?: boolean;
  forceShowLabel?: boolean;
}

export const ECTagInput = ({
  initialTags,
  disabled,
  placeholder,
  label,
  onDuplicate,
  onChange,
  readOnly,
  forceShowLabel = false,
}: ECTagInputProps) => {
  const [tags, setTags] = useState(initialTags);
  const [tagValue, setTagValue] = useState('');
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const textFieldRef = useRef<any>(null);

  const handleDeleteClick = useCallback(
    (deletedTag: string) => () => {
      const filteredTags = tags.filter(tag => tag !== deletedTag);
      setTags(filteredTags);
      onChange(filteredTags);
    },
    [tags, onChange],
  );

  const focusTextField = () => {
    setIsTextFieldFocused(true);
    textFieldRef.current?.focus?.();
  };

  const handleBlur = () => {
    setIsTextFieldFocused(false);
  };

  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (tagValue.trim() !== '') {
          const alreadyPresent = tags.find(
            tag => tag.toUpperCase() === tagValue.toUpperCase(),
          );
          if (onDuplicate && alreadyPresent) {
            onDuplicate();
          } else {
            const newTags = [...tags, tagValue];
            setTags(newTags);
            onChange(newTags);
            setTagValue('');
          }
        }
      }
    },
    [tags, tagValue, onChange, onDuplicate],
  );

  useEffect(() => {
    setTags(initialTags);
  }, [initialTags]);

  const handleChange = e => {
    setTagValue(e.target.value);
  };

  return (
    <>
      {forceShowLabel && (
        <ECTypography
          variant="caption"
          sx={theme => ({
            color: theme.palette.other.backdropOverlay,
          })}
        >
          {label}
        </ECTypography>
      )}
      <Box
        sx={theme => ({
          border: isTextFieldFocused ? 2 : 1,
          borderColor: isTextFieldFocused
            ? theme.palette.primary.main
            : theme.palette.action.active,
          borderRadius: 1,
          p: isTextFieldFocused ? 1.875 : 2,
        })}
        onClick={focusTextField}
      >
        <Box sx={{ mb: 1 }} onClick={focusTextField}>
          {tags.map((tag, index) => (
            <ECChip
              label={tag}
              key={`chip-${tag}-${index}`}
              onDelete={disabled ? undefined : handleDeleteClick(tag)}
              sx={{ m: 0.5 }}
            />
          ))}
        </Box>

        {!disabled && (
          <ECTextField
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            inputRef={textFieldRef}
            label={label}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            value={tagValue}
            onChange={handleChange}
            placeholder={placeholder}
            sx={{ m: 0.5, width: '100%', input: { color: 'black' } }}
          />
        )}
      </Box>
    </>
  );
};
