import { useEffect, useRef, useState } from 'react';
import { BoxProps } from '@material-ui/core';
import { ECTextField } from '../ECTextField';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import YouTube from 'react-youtube';
import { getParamsFromUrl } from 'utils/strings/get-params-from-url';
import { useTheme, useMediaQuery } from '@mui/material';
import ReactPlayer from 'react-player';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import FullscreenIcon from '@mui/icons-material/Launch';
import { ECIconButton } from '../ECIconButton';
import { themes } from 'styles/theme/themes';

export interface ECVideoFieldProps extends Omit<BoxProps, 'onChange'> {
  label?: string;
  placeholder?: string;
  error?: boolean | null;
  helperText?: string | null;
  fieldName?: string;
  sx?: any;
  defaultValue?: string;
  isLoading?: boolean;
  isReadOnly?: boolean;
  videoUrl?: string;
  onChange?: (url: string) => void;
}

const YOUTUBE_VIDEO_ID_PARAM = 'v';

const playerOverIconStyle = {
  width: '80px',
  height: '80px',
  color: themes.light.palette.common.white,
};

export const ECVideoField = ({
  label,
  placeholder,
  error,
  helperText,
  fieldName,
  defaultValue,
  isLoading,
  videoUrl,
  isReadOnly,
  onChange,
  ...boxProps
}: ECVideoFieldProps) => {
  const [videoId, setVideoId] = useState<string | null>(null);
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const [platform, setPlatform] = useState<'youtube' | 'vimeo' | null>(null);
  const videoRef = useRef<ReactPlayer>(null!);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!videoUrl) {
      setVideoId(null);
      return;
    }

    const youtubeMatch = videoUrl.match(
      /(?:youtube\.com\/.*[?&]v=|youtu\.be\/)([^&]+)/,
    );
    const vimeoMatch = videoUrl.match(/vimeo\.com\/(\d+)/);

    if (youtubeMatch) {
      setVideoId(youtubeMatch[1]);
      setPlatform('youtube');
    } else if (vimeoMatch) {
      setVideoId(vimeoMatch[1]);
      setPlatform('vimeo');
    } else {
      setPlatform(null);
      const params = getParamsFromUrl(videoUrl);
      setVideoId(params.get(YOUTUBE_VIDEO_ID_PARAM));
    }
  }, [videoUrl]);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.getInternalPlayer().play();
    }
  };

  const toggleFullscreen = () => {
    if (videoRef.current) {
      const playerElement = videoRef.current.getInternalPlayer();

      if (isFullscreen) {
        document?.exitFullscreen();
      } else {
        if (playerElement.requestFullscreen) {
          playerElement?.requestFullscreen();
        } else if (playerElement.mozRequestFullScreen) {
          // Firefox
          playerElement?.mozRequestFullScreen();
        } else if (playerElement.webkitRequestFullscreen) {
          // Chrome, Safari
          playerElement?.webkitRequestFullscreen();
        } else if (playerElement.msRequestFullscreen) {
          // IE/Edge
          playerElement?.msRequestFullscreen();
        }
      }
      setIsFullscreen(!isFullscreen);
    }
  };

  if (isReadOnly) {
    if (!videoId) {
      return <></>;
    }
    if (platform === 'vimeo') {
      return (
        <ECBox
          display="flex"
          mt={2}
          justifyContent="center"
          sx={{
            position: 'relative',
            zIndex: 10,
            width: { xs: 384, md: '100%' },
            height: { xs: 216, md: '100%' },
          }}
        >
          <ReactPlayer
            ref={videoRef}
            volume={1}
            url={videoUrl}
            onPlay={handlePlay}
            onPause={handlePause}
            controls
          />
          <ECBox
            position="absolute"
            top="50%"
            left="50%"
            display="flex"
            gap={2}
            zIndex={20}
            sx={{
              transform: 'translate(-50%, -50%)',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '8px',
              p: 2,
              display: isPlaying || isMobileSize ? 'none' : 'flex',
            }}
          >
            <ECIconButton onClick={playVideo} sx={playerOverIconStyle}>
              <PlayArrowOutlinedIcon fontSize="large" />
            </ECIconButton>
            <ECIconButton onClick={toggleFullscreen} sx={playerOverIconStyle}>
              <FullscreenIcon fontSize="large" />
            </ECIconButton>
          </ECBox>
        </ECBox>
      );
    }
    return (
      <ECBox
        display="flex"
        mt={2}
        justifyContent="center"
        sx={{ position: 'relative', zIndex: 10 }}
      >
        <YouTube
          videoId={videoId}
          opts={{
            ...(isMobileSize
              ? { height: '180', width: '320' }
              : { height: '360', width: '640' }),
            playerVars: {
              rel: 0,
              modestbranding: 1,
              controls: 1,
              showinfo: 0,
            },
          }}
        />
      </ECBox>
    );
  }

  return (
    <ECBox display="flex" flexDirection="column" {...boxProps}>
      {label && (
        <ECTypography
          variant="caption"
          color={theme => theme.palette.text.secondary}
          mb={2}
        >
          {label}
        </ECTypography>
      )}

      <ECTextField
        id={fieldName}
        name={fieldName}
        label={placeholder}
        placeholder={placeholder}
        value={videoUrl}
        onChange={event => onChange?.(event.target.value)}
      />

      {videoId && (
        <ECBox mt={2}>
          <YouTube
            videoId={videoId}
            opts={isMobileSize ? { height: '180', width: '320' } : undefined}
          />
        </ECBox>
      )}
    </ECBox>
  );
};
