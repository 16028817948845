import { useEffect, useState } from 'react';
import { ECBox, ECButton, ECTypography, ECChip } from 'app/components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUpdateWorkOrderEtaMutation } from 'services/workOrdersApi';
import { setSnackbar } from 'store/slice/page';
import { formatDateForTable } from 'utils/strings/formatDate';
import { ECBasicDateTimePicker } from 'app/components/ECDateTimePicker';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { DateTime } from 'luxon';
import { moduleApi } from 'services/moduleApi';

export interface ECWorkOrderUpdateEtaModalContentProps {
  row: any;
  handleCloseModal: () => void;
}

export const updateEtaStatusToShow = [
  'ACCEPTED',
  'EN ROUTE',
  'ENROUTE',
  'EN_ROUTE',
  'RETURN_VISIT_REQUIRED',
  'RETURN VISIT REQUIRED',
  'RETURNVISITREQUIRED',
];

export const ECWorkOrderUpdateEtaModalContent = ({
  row,
  handleCloseModal,
}: ECWorkOrderUpdateEtaModalContentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const defaultEtaDate = row?.eta
    ? DateTime.fromISO(row.eta).toFormat("yyyy-MM-dd'T'HH:mm")
    : '';

  const minDate = DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm");

  const [modalEta, setModalEta] = useState<Date | string | undefined>(
    defaultEtaDate,
  );

  const [validationModalEta, setValidationModalEta] = useState('');

  const [
    doUpdateEta,
    {
      error: updateEtaError,
      isError: isUpdateEtaError,
      isLoading: isUpdateEtaLoading,
      isSuccess: isUpdateEtaSuccess,
      reset: resetUpdateEta,
    },
  ] = useUpdateWorkOrderEtaMutation();

  const handleSaveEta = (id: any) => () => {
    if (!modalEta) {
      setValidationModalEta('Please select a date and time');
      return;
    }

    if (new Date(modalEta) < new Date(minDate)) {
      setValidationModalEta('Please select a date and time in the future');
      return;
    }

    if (!validationModalEta)
      doUpdateEta({ id: id, eta: modalEta ? new Date(modalEta) : modalEta });
  };

  useEffect(() => {
    if (isUpdateEtaError && updateEtaError) {
      handleCloseModal();
      resetUpdateEta();
      dispatch(
        setSnackbar({
          severity: 'error',
          message: (updateEtaError as any)?.data?.message,
        }),
      );
    }
  }, [updateEtaError, isUpdateEtaError]);

  useEffect(() => {
    if (isUpdateEtaSuccess && !isUpdateEtaLoading) {
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
      resetUpdateEta();
      handleCloseModal();
      setModalEta(defaultEtaDate);
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'ETA Updated Successfully',
        }),
      );
    }
  }, [isUpdateEtaSuccess, isUpdateEtaLoading]);

  return (
    <ECBox p={1} bgcolor="transparent" mt={-1}>
      <ECBox
        sx={theme => ({
          borderRadius: 1,
          boxShadow: 2,
          maxWidth: '444px',
          bgcolor: theme.palette.common.white,
        })}
      >
        <ECBox display="flex" flexDirection="column" p={2}>
          <ECTypography variant="h6" px={1}>
            Confirm you want to Update ETA
          </ECTypography>

          <ECBox display={'flex'} mt={3} alignItems={'center'}>
            <ECTypography fontWeight="bold" variant="body1" px={1}>
              Original ETA:
            </ECTypography>
            <ECTypography variant="body1" px={1}>
              {formatDateForTable(row?.originalEta)}
            </ECTypography>
          </ECBox>
          <ECBox display={'flex'} mt={3} alignItems={'center'}>
            <ECTypography fontWeight="bold" variant="body1" px={1}>
              Overtime Approved:
            </ECTypography>
            <ECChip
              label={row?.overtimeApproved ? 'Yes' : 'No'}
              variant="filled"
              sx={{
                bgcolor: theme =>
                  row?.overtimeApproved
                    ? theme.palette.success.outlinedHoverBackground
                    : theme.palette.error.outlinedHoverBackground,
                color: theme =>
                  row?.overtimeApproved
                    ? theme.palette.success.dark
                    : theme.palette.error.dark,
              }}
            />
          </ECBox>
          <ECBasicDateTimePicker
            fieldName={'newEta'}
            helperText={validationModalEta}
            error={!!validationModalEta}
            sx={{ marginTop: '24px' }}
            label={'New ETA'}
            value={modalEta}
            variant={'filled'}
            readOnly={false}
            disabled={false}
            minDate={minDate}
            onChange={dateTime => {
              setModalEta(dateTime);
              if (new Date(dateTime) < new Date(minDate)) {
                setValidationModalEta(
                  'Please select a date and time in the future',
                );
              } else {
                setValidationModalEta('');
              }
            }}
          />
          <ECBox mt={2} display="flex" gap={2} justifyContent="flex-end">
            <ECButton variant="text" onClick={handleCloseModal}>
              Cancel
            </ECButton>
            <ECButton variant="contained" onClick={handleSaveEta(row?.id)}>
              {isUpdateEtaLoading ? (
                <ECCircularProgress
                  size={'2rem'}
                  sx={{ color: 'background.paper' }}
                />
              ) : (
                'Confirm'
              )}
            </ECButton>
          </ECBox>
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
