import { useMemo, useState } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router';
import {
  ECBox,
  ECButton,
  ECRequirePermission,
  ECStack,
  ECTypography,
  ECWorkflowTemplate,
} from 'app/components';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import { useGetAssetWarrantyQuery } from 'services/assetApi';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import { useGetSPRfpItemByIdQuery } from 'services/requestForPricingApi';
import { concatAddress } from 'utils/strings/concat-address';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { customDateFormatter } from 'utils/dates';
import { P } from 'types/Permission';
import { AcceptRfpProposalButton } from '../ServiceProviderProposalDetailsPage/AcceptRfpProposalButton';
import { Close } from '@mui/icons-material';
import { useGetProposalNextStatusListQuery } from 'services/proposalApi';
import {
  ECWorkflowStatusBadge,
  WorkflowStatus,
} from 'app/components/ECWorkflowStatusBadge';
import { ECModal } from 'app/components/ECModal';
import { ServiceProviderDeclineProposalModal } from '../ServiceProviderProposalDetailsPage/DeclineRfpModal';

export const ServiceProviderRfpDetailsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isDetailsTab = useMemo(() => {
    const url = window.location.href;
    return !url.includes('attachments') && !url.includes('activity');
  }, [window.location.href]);

  const { data: rfpItem, isLoading: isLoadingRfpItem } =
    useGetSPRfpItemByIdQuery(Number(id), {
      skip: !id,
    });

  const summaryData = useMemo(() => {
    const mailingAddress = rfpItem?.request?.asset?.branch?.mailingAddress;
    return [
      {
        id: 'status',
        label: 'RFP Status',
        data: rfpItem?.workflowStatus?.name,
        type: SummaryFieldTypes.Status,
      },
      {
        id: 'id',
        label: 'RFP ID',
        data: rfpItem?.id,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'name',
        label: 'RFP Title',
        data: rfpItem?.name ?? '',
        type: SummaryFieldTypes.Text,
        maxLength: 100,
        required: true,
      },
      {
        id: 'location',
        label: 'Location',
        data: rfpItem?.request?.asset?.branch?.name ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'storeNumber',
        label: 'Store Number',
        data: rfpItem?.request?.asset?.branch?.storeNumber ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'asset',
        label: 'Asset',
        data:
          rfpItem?.request?.asset?.generalAsset?.name ??
          rfpItem?.request?.asset?.name ??
          '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'customer',
        label: 'Customer',
        data: rfpItem?.request?.customer?.name ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'category',
        label: 'Category',
        data: rfpItem?.request?.category?.name ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'assetGroup',
        label: 'Asset Group',
        data: rfpItem?.request?.asset?.assetType?.name ?? '',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'problem',
        label: 'Problem',
        data: rfpItem?.request?.problem?.name,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'priority',
        label: 'Priority',
        data: rfpItem?.request?.priority?.name,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'address',
        label: 'Address',
        data: concatAddress(
          [
            mailingAddress?.line1,
            mailingAddress?.line2,
            mailingAddress?.cityName,
            mailingAddress?.stateProvinceCode,
            mailingAddress?.zipCodePostalCode,
            mailingAddress?.countryCode,
          ].filter(Boolean),
        ),
        type: SummaryFieldTypes.Address,
      },
      {
        id: 'createdDate',
        label: 'Created Date',
        data: customDateFormatter({
          raw: rfpItem?.createdAt,
          format: 'MMM dd, yyyy',
          fallback: '-',
        }),
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'awardedDate',
        label: 'Awarded Date',
        data: customDateFormatter({
          raw: rfpItem?.awardedAt,
          format: 'MMM dd, yyyy',
          fallback: '-',
        }),
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'submittedBy',
        label: 'Submitted By',
        data: rfpItem?.createdByUser?.email ?? '-',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'requestedBy',
        label: 'Requested By',
        data: rfpItem?.requestedBy ?? '-',
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'description',
        label: 'RFP Description',
        data: rfpItem?.description,
        type: SummaryFieldTypes.Text,
        maxLength: 10000,
        multiline: true,
      },
    ];
  }, [rfpItem]);

  const { data: assetWarranty } = useGetAssetWarrantyQuery(
    rfpItem?.request?.asset?.id ?? 0,
    {
      skip: !id || !rfpItem || !isDetailsTab,
    },
  );

  const isAsset = useMemo(
    () => _.isNil(rfpItem?.request?.asset?.generalAsset),
    [rfpItem?.request?.asset],
  );

  const moduleName = useMemo(() => {
    return isAsset ? 'asset' : 'general-asset';
  }, [isAsset]);

  const { data: attachments } = useGetAttachmentQuery(
    {
      module: moduleName,
      moduleId: isAsset
        ? rfpItem?.request?.asset?.id ?? 0
        : rfpItem?.request?.asset?.generalAsset?.id ?? 0,
      category: AttachmentCategory.All,
    },
    {
      skip: !isDetailsTab || !rfpItem?.request?.asset?.id,
    },
  );

  const assetProfileAttachment = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.assetProfilePhoto,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  const rfpItemAsset = useMemo(() => {
    return !rfpItem?.request?.asset?.generalAsset?.id && rfpItem?.assetId
      ? {
          href: rfpItem?.assetId?.toString(),
          text: rfpItem?.request?.asset?.name || '',
        }
      : undefined;
  }, [rfpItem]);

  const rfpItemGeneralAsset = useMemo(() => {
    return rfpItem?.request?.asset?.generalAsset?.id
      ? {
          href: rfpItem?.request?.asset?.generalAsset?.id?.toString(),
          text: rfpItem?.request?.asset?.generalAsset?.name || '',
        }
      : undefined;
  }, [rfpItem]);

  const rfpItemWO = useMemo(() => {
    const workOrderId = rfpItem?.workOrderId?.toString();
    return workOrderId
      ? {
          href: workOrderId,
          text: `#${workOrderId}`,
        }
      : undefined;
  }, [rfpItem]);

  const rfpProposals = useMemo(() => {
    return rfpItem?.request?.proposals && rfpItem?.request?.proposals.length
      ? {
          content: rfpItem.request.proposals.map(proposal => {
            return {
              href: proposal.id.toString(),
              text: proposal.id.toString(),
            };
          }),
        }
      : undefined;
  }, [rfpItem?.request?.proposals]);

  const proposal = rfpItem?.request?.proposals?.[0];

  const { data: proposalNextStatus, isSuccess: isSuccessProposalNextStatus } =
    useGetProposalNextStatusListQuery(Number(proposal?.id) || 0, {
      skip: !proposal?.id || !isDetailsTab,
    });

  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);

  const match = useMatch('/panel/*');

  // additionalActions should behave based on the status. If status is Pending Submission (workflowStatusGroupId 34)
  // we show the custom ACCEPT and REJECT buttons
  // otherwise show regular proposalNextStatus
  const additionalActions = useMemo(() => {
    const statusToAccepted =
      proposalNextStatus?.find(
        status => status.name === WorkflowStatus.AcceptedPendingSubmission,
      )?.statusTo || 0;

    const hasDeclinedButtonInProposalNextStatus = proposalNextStatus?.some(
      status => status.name === WorkflowStatus.Declined,
    );
    if (statusToAccepted || hasDeclinedButtonInProposalNextStatus) {
      return [
        <ECBox border="1px solid #e0e0e0" px={2} py={3}>
          <ECStack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <ECTypography
              variant="body1"
              noWrap
              sx={{
                fontWeight: 'bold',
                flexGrow: 1,
                minWidth: '200px',
                paddingRight: 1,
              }}
            >
              Proposal :
            </ECTypography>
            <ECWorkflowStatusBadge status={proposal?.workflowStatus?.name} />
            <ECStack direction="row" spacing={2} ml={4} sx={{ paddingLeft: 5 }}>
              {!!statusToAccepted && (
                <ECRequirePermission scopes={[P.GetProposal, P.EditProposal]}>
                  <AcceptRfpProposalButton
                    proposal={{
                      ...proposal,
                      companyName: rfpItem?.customerName,
                    }}
                    statusToId={statusToAccepted}
                    onSave={() =>
                      navigate(
                        `${match?.pathnameBase}/sp/proposal/${proposal?.id}`,
                      )
                    }
                  />
                </ECRequirePermission>
              )}
              {hasDeclinedButtonInProposalNextStatus && (
                <ECRequirePermission scopes={[P.GetProposal, P.EditProposal]}>
                  <ECButton
                    variant="outlined"
                    color="error"
                    startIcon={
                      <Close
                        sx={{
                          color: theme => theme.palette.graphic.alert.error,
                        }}
                      />
                    }
                    sx={theme => ({
                      border: 1,
                      borderColor: `${theme.palette.error.outlinedRestingBackground} !important`,
                      color: `${theme.palette.graphic.alert.error} !important`,
                      marginRight: 1,
                    })}
                    onClick={e => setIsOpenRejectModal(true)}
                  >
                    Decline
                  </ECButton>
                </ECRequirePermission>
              )}
            </ECStack>
          </ECStack>
        </ECBox>,
      ];
    }
    return [];
  }, [
    proposal?.workflowStatus?.name,
    proposalNextStatus,
    rfpItem?.customerName,
  ]);

  return (
    <>
      <ECWorkflowTemplate
        pageTitle={t('translation:pages.requestForPricing.title')}
        tabsAsLinks
        summaryData={summaryData}
        title={rfpItem?.name ?? ''}
        detailsData={{}}
        editConfig={{
          submitTitle: 'Save',
        }}
        editFields={[]}
        detailsConfig={{}}
        detailsFields={[]}
        imgSrc={assetProfileAttachment?.url}
        profileAttachment={assetProfileAttachment}
        moduleName={moduleName}
        moduleId={id}
        useUpdateMutation={undefined}
        hideNoteTab
        warrantyBadgeType={assetWarranty?.[0]?.status}
        asset={rfpItemAsset}
        generalAsset={rfpItemGeneralAsset}
        workOrder={rfpItemWO}
        rfps={{
          content: [
            {
              href: rfpItem?.id?.toString() ?? '',
              text: rfpItem?.id?.toString() ?? '',
            },
          ],
        }}
        proposals={rfpProposals}
        additionalActions={additionalActions}
        isEditAllowed={false}
        showSkeletonLoader={isLoadingRfpItem}
      />
      {isDetailsTab && (
        <>
          <ECModal
            isOpen={isOpenRejectModal}
            onClose={() => setIsOpenRejectModal(false)}
            noPadding
          >
            <ServiceProviderDeclineProposalModal
              proposal={proposal}
              onCloseModal={() => setIsOpenRejectModal(false)}
              companyName={rfpItem?.customerName}
              statusToId={
                proposalNextStatus?.find(
                  status => status.name === WorkflowStatus.Declined,
                )?.statusTo || 0
              }
              onSuccess={() =>
                navigate(`${match?.pathnameBase}/sp/proposal/${proposal?.id}`)
              }
            />
          </ECModal>
        </>
      )}
    </>
  );
};
