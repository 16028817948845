import { ECDynamicPageTemplate } from 'app/components';
import { useParams } from 'react-router';
import { t } from 'i18next';
import { useLazyGetCompanyAssetsQuery } from 'services/profileApi';
import { useGetCompanyBranchesListFroSpQuery } from 'services/branchApi';
import { useEffect } from 'react';
import { setFilterEndpoint } from 'store/slice/page';
import { useDispatch } from 'react-redux';

export function CustomerAssetsPage() {
  const { id } = useParams();

  const locationFilterQueryParams = {
    companyId: Number(id),
  };

  const dispatchRedux = useDispatch();
  useEffect(() => {
    dispatchRedux(setFilterEndpoint('/filter/asset'));
  }, []);

  return (
    <ECDynamicPageTemplate
      shouldCacheLazyQuery
      pageTitle={t('translation:panelDrawer.managedAssets')}
      useLazyGetListQuery={useLazyGetCompanyAssetsQuery}
      idToGetListBy={id}
      useCreateMutation={{}}
      marginTop={false}
      withDrawer={false}
      showLocationFilter
      useLocationFilterQuery={useGetCompanyBranchesListFroSpQuery}
      locationFilterQueryParams={locationFilterQueryParams}
    />
  );
}
