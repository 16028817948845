import { emptyApi } from './emptyApi';
import { PaymentMethodMutate, PaymentMethodUpdate } from 'types/paymentMethod';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['paymentMethod'],
});

export const paymentMethodApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    createPaymentMethod: builder.mutation<void, PaymentMethodMutate>({
      query: body => {
        return {
          url: 'payment-method',
          method: 'post',
          body: body,
        };
      },
    }),
    updatePaymentMethod: builder.mutation<void, PaymentMethodUpdate>({
      query: body => {
        return {
          url: `payment-method/${body.id}`,
          method: 'put',
          body,
        };
      },
    }),
  }),
});

export const {
  useCreatePaymentMethodMutation,
  useUpdatePaymentMethodMutation,
} = paymentMethodApi;
