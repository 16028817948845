import { useCallback, useEffect, useMemo, useState } from 'react';
import { ECDynamicPageTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import {
  useLazyGetRegionsQuery,
  useUpdateOrganizationNodeMutation,
} from 'services/organizationApi';
import { setSnackbar } from 'store/slice/page';
import {
  useCreateOrganizationNodeMutation,
  useGetOrganizationListQuery,
  useGetOrganizationTypeListQuery,
} from 'services/organizationApi';
import { OrganizationTypeCode } from 'types/Organization';
import { useDispatch } from 'react-redux';
import { historyApi } from 'services/historyApi';
import { ActivityType } from 'types/Activity';
import { themes } from 'styles/theme/themes';

const formElementsDetails = require('./form_config_details.json');
const formElementsCreate = require('./form_config_create.json');
const formElementsEdit = require('./form_config_edit.json');

export const Regions = ({ marginTop = true }) => {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const { data: orgTypes } = useGetOrganizationTypeListQuery();
  const { data: organization } = useGetOrganizationListQuery();

  const [
    doAdd,
    {
      data: addData,
      isError: isAddError,
      error: addError,
      isLoading: isAddLoading,
      isSuccess: isAddSuccess,
      reset: resetAdd,
    },
  ] = useCreateOrganizationNodeMutation();

  const [
    doUpdate,
    {
      data: updateData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdate,
    },
  ] = useUpdateOrganizationNodeMutation();

  const useUpdateOrganizationNode = useCallback(() => {
    const update = async data => {
      doUpdate(data);
    };

    return [
      update,
      {
        data: updateData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: resetUpdate,
      },
    ];
  }, [
    updateData,
    updateError,
    isUpdateError,
    isUpdateLoading,
    isUpdateSuccess,
    resetUpdate,
    doUpdate,
  ]);

  useEffect(() => {
    if (!isUpdateLoading && isUpdateSuccess) {
      dispatch(historyApi.util.invalidateTags(['History']));
    }
  }, [isUpdateSuccess, isUpdateLoading]);

  useEffect(() => {
    if (!isAddLoading && isAddSuccess) {
      dispatch(historyApi.util.invalidateTags(['History']));
    }
  }, [isAddLoading, isAddSuccess]);

  const orgTypeId = useMemo(() => {
    return orgTypes?.find(item => item.code === OrganizationTypeCode.Region)
      ?.id;
  }, [orgTypes]);

  const parentId = useMemo(() => {
    return organization?.[0]?.id;
  }, [organization]);

  const useCreateRegion = useCallback(() => {
    const doCreate = async data => {
      if (!parentId) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: 'Error in finding parent organization',
          }),
        );
      } else if (!orgTypeId) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: 'Organization type for region not found',
          }),
        );
      } else {
        doAdd({
          name: data?.name,
          organizationTypeId: orgTypeId,
          parentId: parentId,
        });
      }
    };

    return [
      doCreate,
      {
        data: addData,
        isError: isAddError,
        error: addError,
        isLoading: isAddLoading,
        isSuccess: isAddSuccess,
        reset: resetAdd,
      },
    ];
  }, [
    addData,
    addError,
    isAddError,
    isAddLoading,
    isAddSuccess,
    resetAdd,
    doAdd,
    orgTypes,
    organization,
  ]);

  return (
    <>
      <ECDynamicPageTemplate
        shouldCacheLazyQuery
        pageTitle={t('translation:pages.locations.regions')}
        useLazyGetListQuery={useLazyGetRegionsQuery}
        useCreateMutation={useCreateRegion}
        useUpdateMutation={useUpdateOrganizationNode}
        editFormConfig={formElementsEdit.config}
        editFormFields={formElementsEdit.fields}
        createFormConfig={formElementsCreate.config}
        createFormFields={formElementsCreate.fields}
        detailsConfig={formElementsDetails.config}
        detailsFields={formElementsDetails.fields}
        onRowClick={null as any}
        onDrawerClose={null as any}
        showOnlyAttachmentsTab={true}
        marginTop={marginTop}
        onAddNewClick={() => setIsDrawerOpen(true)}
        showOnlyDetailsTab
        showActivityButton
        shouldNotUseActiveFilter
        activityType={ActivityType.Organization}
        drawerTitleBarBGColor={themes.light.palette.other.divider}
        showDrawerDetailTitle={false}
      />
    </>
  );
};
