import { ActiveFilterAlias } from 'store/slice/getters/pageFilters';
import { FilterAliasTypes } from 'types/store/pageFilters';
import {
  handleActiveStatusFilter,
  handleArraySearchFilter,
  handleCompanyFilter,
  handleDefaultDateSearchFilter,
  handleSearchFieldsFilter,
  handleHierarchyFilter,
  handleDaysFilter,
  handleDefaultFilter,
  handleToggleFilter,
} from './table.filters.handlers';
import { isDefined } from 'utils/common';
import { ConfigField } from 'types/core/Pagination';
import { SavedFilter } from 'services/filterApi';
import { sortBy } from 'lodash';
import { ActiveFilter, Context } from 'types/core/Filters';

/**
 * Loops through the active filters and populates the queryParams
 * based on the handler, alias, value, and customParams.
 * This replaces the old fetchList.
 */
export const populateQueryParamsFromFilters = (
  filters: ActiveFilter = {},
  context: Context,
  queryParams: Record<string, any>,
) => {
  let searchIndex = 0;
  let searchGroupIndex = 1;
  const activeFilterAliasMap = ActiveFilterAlias();
  Object.keys(filters).forEach(filterKeys => {
    const filterValue = filters[filterKeys as keyof ActiveFilter];
    const filterInfo = activeFilterAliasMap[filterKeys as keyof ActiveFilter];
    if (isDefined(filterValue) && filterInfo) {
      const { alias, handler: handlerName, customParams } = filterInfo;
      const handler = filterHandlerMap[handlerName];
      const searchIndexToIncrement =
        handler?.(
          alias,
          filterValue,
          queryParams,
          filters,
          searchIndex,
          searchGroupIndex,
          customParams,
          context,
        ) ?? 0;
      searchGroupIndex += 1;
      searchIndex += searchIndexToIncrement;
    }
  });
};

/**
 * Map of the handlers for each filter type
 */
const filterHandlerMap: Record<
  FilterAliasTypes,
  (
    alias: string,
    value: any,
    queryParams: Record<string, any>,
    activeFilter: ActiveFilter,
    searchIndex: number,
    searchGroupIndex: number,
    customParams?: Record<string, any>,
    context?: Context,
  ) => number
> = {
  default: handleDefaultFilter,
  arraySearch: handleArraySearchFilter,
  daysSearch: handleDaysFilter,
  defaultDateSearch: handleDefaultDateSearchFilter,
  companyFilter: handleCompanyFilter,
  activeStatus: handleActiveStatusFilter,
  hierarchy: handleHierarchyFilter,
  searchFields: handleSearchFieldsFilter,
  toggle: handleToggleFilter,
  skip: () => 0,
};

export const sortColumns = (
  cols?: ConfigField[],
  tableLayoutFilter?: SavedFilter,
  isDraggableColumns?: boolean,
  showOnlyVisibleColumns: boolean = true,
) => {
  const visibleColumns = tableLayoutFilter?.body?.[0]?.visibleColumns;

  if (cols?.length && isDraggableColumns) {
    return sortBy(
      cols.filter(
        col =>
          (showOnlyVisibleColumns ? col.visible : true) &&
          (!visibleColumns || visibleColumns?.includes(col.label || col.title)),
      ),
      col => {
        if (tableLayoutFilter?.body?.[0]?.columnsOrder) {
          return tableLayoutFilter?.body?.[0]?.columnsOrder?.indexOf(
            col.title || col.label || '',
          );
        }
        return 0;
      },
    );
  }
  return cols ?? [];
};
