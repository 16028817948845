import { ECTabContainer } from 'app/components';
import { useTranslation } from 'react-i18next';
import { StyleConstants } from 'styles/StyleConstants';
import { useCustomerUser } from 'app/hooks/customerUser.use-case';
import { ForbiddenPage } from '../ForbiddenPage';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';
import { SPGridViewPage } from '../SPGridViewPage';
import { P } from 'types/Permission';

export function ServiceProvidersPage() {
  const { t } = useTranslation();

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Helmet
        titleTemplate={`${t('translation:pages.serviceProvider.title')} - %s`}
        defaultTitle={t('translation:pages.serviceProvider.title')}
      />
      <ECTabContainer
        tabsAsLinks
        mt={StyleConstants.NAV_BAR_HEIGHT}
        p={2}
        variant="standard"
        color="marketingSuggestion"
        noPadding
        tabs={[
          {
            value: 'service-providers',
            label: t('translation:panelDrawer.serviceProviders'),
            content: <SPGridViewPage />,
            scopes: [P.MenuServiceProviders],
            scores: [P.MenuServiceProviders],
            link: 'service-providers',
          },
          {
            value: 'invited-service-providers',
            label: t('translation:panelDrawer.invitedServiceProviders'),
            content: <Outlet />,
            link: 'invited-service-providers',
          },
          {
            value: 'sp-directory',
            label: t('translation:panelDrawer.spDirectory'),
            content: <Outlet />,
            link: 'sp-directory',
          },
          {
            value: 'trade-assignment',
            label: t('translation:panelDrawer.tradeAssignment'),
            content: <Outlet />,
            scopes: [P.MenuTradeAssignment],
            scores: [P.MenuTradeAssignment],
            link: 'trade-assignment',
          },
          {
            value: 'required-docs',
            label: t('translation:panelDrawer.requiredDocuments'),
            content: <Outlet />,
            scopes: [P.MenuRequiredDocuments],
            scores: [P.MenuRequiredDocuments],
            link: 'required-docs',
          },
        ]}
      />
    </>
  );
}
