import { P } from 'types/Permission';

export * from './addNote';
export * from './approve';
export * from './reject';
export * from './cancel';
export * from './reassign';
export * from './unassign';
export * from './assignTech';

export enum QueryTag {
  UnassignedWorkorders = 'UnassignedWorkorders',
  PendingWorkorders = 'PendingWorkorders',
  PendingProposals = 'PendingProposals',
  PendingInvoices = 'PendingInvoices',
  AwaitingInvoice = 'AwaitingInvoice',
  PastEtaWo = 'PastEtaWorkorders',
}

export interface DashboardAction {
  buttonLabel: string;
  disabledRule: (selection: number, customRule?: boolean | null) => boolean;
  modalContent: (props) => JSX.Element;
  scopes?: P[];
}
