import { ECTabContainer } from '../../components';
import { StyleConstants } from 'styles/StyleConstants';
import { useTranslation } from 'react-i18next';
import { TroubleshootingTipsPage } from '../TroubleshootingTipsPage';
import { useCustomerUser } from 'app/hooks/customerUser.use-case';
import { ForbiddenPage } from '../ForbiddenPage';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';
import { P } from 'types/Permission';

export function TroubleshootingPage() {
  const { t } = useTranslation();

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Helmet
        titleTemplate={`${t('translation:pages.troubleshooting.title')} - %s`}
        defaultTitle={t('translation:pages.troubleshooting.title')}
      />

      <ECTabContainer
        tabsAsLinks
        mt={StyleConstants.NAV_BAR_HEIGHT}
        p={2}
        variant="standard"
        color="marketingSuggestion"
        noPadding
        tabs={[
          {
            value: 'library',
            label: t(
              'translation:pages.troubleshooting.troubleshootTipLibraryTitle',
            ),
            content: <TroubleshootingTipsPage marginTop={false} />,
            scopes: [P.GetAllTroubleshoot],
            link: 'library',
          },
          {
            value: 'asset-problem-troubleshoot',
            label: t(
              'translation:pages.troubleshooting.assetProblemTroubleshootTitle',
            ),
            content: <Outlet />,
            scopes: [P.GetAllAssetGroupProblemTroubleshoot],
            link: 'asset-problem-troubleshoot',
          },
        ]}
      />
    </>
  );
}
