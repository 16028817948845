import { getLocale } from 'services/utils';
import { Locale } from 'types/Company';

export const currencyFormatter = {
  format: (value: number, localeFromApi?: Locale) => {
    const locale = localeFromApi || getLocale();
    const currencySymbol = locale?.currencySymbol || '$';
    const formattedValue = new Intl.NumberFormat(
      locale?.languageCode || 'en-US',
      {
        style: 'currency',
        currency: locale?.currencyCode || 'USD',
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      },
    ).format(value);

    return `${currencySymbol} ${formattedValue?.replace(currencySymbol, '')}`;
  },
};
