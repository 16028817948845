import { useMemo } from 'react';
import { useGetProfileQuery } from 'services/profileApi';
import { getLocale } from 'services/utils';

export const useLocaleCurrency = () => {
  const locale = useMemo(() => {
    return getLocale();
  }, []);

  const { data: companyDetails } = useGetProfileQuery(undefined, {
    skip: !!locale,
  });

  const localeFromAPI = useMemo(() => {
    return companyDetails?.customerProfile?.locale || companyDetails?.locale;
  }, [companyDetails]);

  return locale || localeFromAPI;
};
