import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme, useMediaQuery } from '@mui/material';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { ECSignUpWizardTemplate } from 'app/components';
import { ECStepsContainer } from 'app/components/ECSteps';
import { useSignUpUserMutation } from 'services/userApi';
import { Step1 } from './step-1';
import { Step2 } from './step-2';
import { Step3 } from './step-3';
import { Step4 } from './step-4';
import { trackEvent } from 'utils/analyticsGtag';

export function SignUpWizardPage() {
  const theme = useTheme();
  const [data, setData] = useState<any>(null);
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const [currentStep, setCurrentStep] = useState(1);
  const [errorMessages, setErrorMessages] = useState<string[] | undefined>(
    undefined,
  );
  const navigate = useNavigate();
  const previousStep = () => {
    if (currentStep > 1) setCurrentStep(prev => prev - 1);
  };

  const [doSignUpUser, { isSuccess, isError, error }] = useSignUpUserMutation();

  useEffect(() => {
    if (isSuccess) navigate('/signup/completion');
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const response = (error as any)?.data;
      if (response?.message) {
        if (response?.message !== 'Bad Request Exception')
          setErrorMessages([response?.message]);
        else if (response?.description) {
          if (typeof response?.description === 'string')
            setErrorMessages([response.description]);
          setErrorMessages(response.description);
        }
      }
      // DTO validation
      else if (response?.description) {
        if (typeof response?.description === 'string')
          setErrorMessages([response.description]);
        setErrorMessages(response.description);
      }
    }
  }, [isError]);

  const renderTabs = useMemo(
    () => [
      {
        value: 1,
        label: '1',
        subLabel: 'Your Info',
        subLabelBold: true,
        hasBreakLineName: true,
        content: (
          <Step1
            existingData={data}
            onSubmit={(fields, output) => {
              trackEvent(
                'Signup',
                'Customer Wizard',
                'Step Completed',
                'Step 1 - Add User Info',
                fields?.user?.email,
              );
              setData(fields);
              setCurrentStep(2);
            }}
          />
        ),
      },
      {
        value: 2,
        label: '2',
        subLabel: 'Company Info',
        subLabelBold: true,
        hasBreakLineName: true,
        content: (
          <Step2
            existingData={data}
            onSubmit={(fields, output) => {
              const body = {
                user: {
                  ...data.user,
                },
                company: fields.company,
                address: fields.company.address,
                'Country Code': fields['Country Code'],
              };
              trackEvent(
                'Signup',
                'Customer Wizard',
                'Step Completed',
                'Step 2 - Add Company Info',
                fields?.user?.email,
              );
              setData(body);
              setCurrentStep(3);
            }}
          />
        ),
      },
      {
        value: 3,
        label: '3',
        subLabel: 'Industry',
        subLabelBold: true,
        hasBreakLineName: true,
        content: (
          <Step3
            existingData={data}
            onSubmit={(fields, output) => {
              trackEvent(
                'Signup',
                'Customer Wizard',
                'Step Completed',
                'Step 3 - Add Industry Type',
                fields?.user?.email,
              );
              setData(fields);
              setCurrentStep(4);
            }}
          />
        ),
      },
      {
        value: 4,
        label: '4',
        subLabel: 'Payment',
        subLabelBold: true,
        content: (
          <Step4
            existingData={data}
            errorMessages={errorMessages}
            onSubmit={async formBody => {
              const countryCode =
                formBody.company.address.countryCode?.value ||
                formBody.company.address.countryCode;
              const body: any = {
                user: formBody.user,
                company: {
                  ...formBody.company,
                  address: {
                    ...formBody.company.address,
                    countryCode,
                  },
                },
                industryCode: formBody.industryCode,
              };
              if (formBody?.paymentInfo) {
                body['paymentInfo'] = formBody.paymentInfo;
              }
              setData(body);

              const isPaymentInfoProvided = Boolean(formBody?.paymentInfo);
              const stepLabel = `Step 4 - Complete ${isPaymentInfoProvided ? 'With' : 'Without'} Payment Info`;

              trackEvent(
                'Signup',
                'Customer Wizard',
                'Step Completed',
                stepLabel,
                formBody?.user?.email,
              );
              doSignUpUser(body);
            }}
          />
        ),
        hasBreakLineName: true,
      },
    ],
    [data, doSignUpUser, errorMessages],
  );

  return (
    <>
      <Helmet>
        <title>Sign Up Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECSignUpWizardTemplate>
        <ECStepsContainer
          showBackButton={currentStep > 1}
          onClickBackButton={previousStep}
          stepsContainerProps={{
            paddingLeft: isMobileSize ? 2 : 18,
            paddingTop: isMobileSize ? 10 : 4,
            paddingRight: isMobileSize ? 4 : 18,
          }}
          tabs={renderTabs}
          currentStep={currentStep}
          onChangeStep={setCurrentStep}
        />
      </ECSignUpWizardTemplate>
    </>
  );
}
