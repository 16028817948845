import {
  useGetAssetByIdQuery,
  useGetAssetWarrantyQuery,
} from 'services/assetApi';
import {
  ECBox,
  ECButton,
  ECEasyFormCreate,
  ECEasyFormFieldType,
  ECWorkflowTemplate,
} from 'app/components';
import { Outlet, useParams } from 'react-router';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { StyleConstants } from 'styles/StyleConstants';
import { useCreateProposalMutation } from 'services/proposalApi';
import { CostTableData } from 'app/components/ECWorkflowCostTable';
import { populateDropDownFields } from 'utils/pageUtils';
import { useGetProblemListByCompanyIdAndAssetTypeIdQuery } from 'services/problemApi';
import { useGetPrioritiesListForSPQuery } from 'services/prioritiesApi';
import { useGetCompletionTimeUnitQuery } from 'services/lookupApi';
import _ from 'lodash';
import { P } from 'types/Permission';
import { useGetDefaultRequestCategoryQuery } from 'services/requestApi';
import { setSnackbar } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { useGetServiceProviderRatesAndTermsQuery } from 'services/serviceProviderApi';
import { useGetUserProfileQuery } from 'services/userProfileApi';

const formElementsDetails = require('./fancy_form_config_details.json');
const formElementsProposalManagedAsset = require('./fancy_form_config_proposal_managed_asset.json');

export function SPAssetDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const {
    data: asset,
    isSuccess: isSuccessAsset,
    isLoading: isLoadingAsset,
  } = useGetAssetByIdQuery(id ? Number(id) : 0);

  const [selectedProblemId, setSelectedProblemId] = useState();

  const {
    data: defaultRequestCategory,
    isSuccess: isSuccessDefaultRequestCategory,
    isFetching: isFetchingDefaultRequestCategory,
  } = useGetDefaultRequestCategoryQuery(
    {
      problemId: selectedProblemId,
      assetTypeId: asset?.assetTypeId,
    },
    {
      skip: !selectedProblemId || !asset?.assetTypeId,
    },
  );

  useEffect(() => {
    if (isSuccessAsset && asset) {
      if (!!asset?.requiredFields?.proposalDocumentRequired) {
        const attachmentField = formElementsProposalManagedAsset?.fields?.find(
          field => field.fieldName === 'files',
        );
        attachmentField.required = true;
      }
    }
  }, [asset, isSuccessAsset]);

  const isAsset = useMemo(
    () => _.isNil(asset?.generalAssetId),
    [asset?.generalAssetId],
  );

  const moduleName = useMemo(() => {
    return isAsset ? 'asset' : 'general-asset';
  }, [isAsset]);

  const { data: attachments } = useGetAttachmentQuery({
    module: moduleName,
    moduleId: asset?.generalAssetId || asset?.id,
    category: AttachmentCategory.Photo,
  });

  const { data: userProfile } = useGetUserProfileQuery();
  const { data: ratesAndTerms } = useGetServiceProviderRatesAndTermsQuery(
    {
      spId: Number(userProfile?.company?.id),
      companyId: asset?.companyId,
    },
    {
      skip: !(userProfile?.company?.id && asset?.companyId),
    },
  );

  const assetProfileAttachment = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.assetProfilePhoto,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  const { data: assetWarranty } = useGetAssetWarrantyQuery(asset?.id || null);

  const handleClose = useCallback(() => setIsOpen(false), []);
  const handleOpen = useCallback(() => setIsOpen(true), []);

  const costTableData = useMemo(
    (): CostTableData | undefined =>
      asset && {
        showIncurred: true,
        itemsRepair: [
          {
            label: 'Labor',
            dataPath: 'costLabor',
            readOnly: true,
          },
          {
            label: 'Labor Charge',
            dataPath: 'costLaborItems',
            extraData: ratesAndTerms,
          },
          {
            label: 'Material',
            dataPath: 'costMaterial',
          },
          {
            label: 'Freight',
            dataPath: 'costFreight',
          },
          {
            label: 'Tax',
            dataPath: 'costTax',
          },
          {
            label: 'Other',
            dataPath: 'costOther',
          },
          {
            label: 'Incurred',
            dataPath: 'Incurred',
            hidden: true,
          },
        ],
      },
    [asset, ratesAndTerms],
  );

  const { data: problems, isSuccess: isSuccessProblems } =
    useGetProblemListByCompanyIdAndAssetTypeIdQuery(
      {
        companyId: asset?.companyId ?? 0,
        assetTypeId: asset?.assetTypeId ?? 0,
      },
      {
        skip: !asset?.companyId || !asset?.assetTypeId,
      },
    );

  useEffect(() => {
    if (problems && isSuccessProblems) {
      populateDropDownFields({
        responseData: problems,
        createFormFields: formElementsProposalManagedAsset.fields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'problem',
      });
    }
  }, [problems, isSuccessProblems]);

  const { data: priorities, isSuccess: isSuccessPriorities } =
    useGetPrioritiesListForSPQuery(
      {
        moduleName: 'asset',
        moduleId: asset?.id ?? 0,
      },
      {
        skip: !asset?.id,
      },
    );

  useEffect(() => {
    if (priorities && isSuccessPriorities) {
      populateDropDownFields({
        responseData: priorities,
        createFormFields: formElementsProposalManagedAsset.fields?.find(
          field => field.fieldName === 'group2',
        )?.subFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'priority',
      });
    }
  }, [priorities, isSuccessPriorities]);

  const { data: period, isSuccess: isSuccessPeriod } =
    useGetCompletionTimeUnitQuery();

  useEffect(() => {
    if (period && isSuccessPeriod) {
      populateDropDownFields({
        responseData: period,
        createFormFields: formElementsProposalManagedAsset.fields?.find(
          field => field.fieldName === 'group2',
        )?.subFields,
        dataOptionsFieldName: 'description',
        dataValuesFieldName: 'code',
        formFieldName: 'completionTimeUnit',
        defaultSelectedOption: 0,
      });
    }
  }, [period, isSuccessPeriod]);

  useEffect(() => {
    if (
      !defaultRequestCategory?.requestCategoryId &&
      selectedProblemId &&
      isSuccessDefaultRequestCategory &&
      !isFetchingDefaultRequestCategory
    ) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: t('translation:defaultCategory.failure'),
        }),
      );
    }
  }, [
    defaultRequestCategory,
    isSuccessDefaultRequestCategory,
    selectedProblemId,
  ]);

  const existingData = useMemo(() => {
    return {
      ...asset,
      description: undefined,
    };
  }, [asset]);

  const additionalTabs = useMemo(() => {
    return [
      {
        value: 'refrigerant',
        label: 'Refrigerant Charges',
        scopes: [P.GetAsset],
        content: <Outlet />,
        link: 'refrigerant',
        editable: false,
        visible: asset?.customerRTEnabled && asset?.refrigerantTrackingEnable,
      },
    ].filter(tab => tab.visible);
  }, [asset?.refrigerantTrackingEnable, asset?.customerRTEnabled]);

  const handleOutputChange = (
    output: ECEasyFormFieldType[],
    fieldName?: string,
  ) => {
    if (fieldName == 'problem') {
      const problemEdit = output.find(field => field.fieldName === 'problem');
      setSelectedProblemId(problemEdit?.value);
    }
  };

  const [
    doCrateProposal,
    {
      isError: isErrorCreate,
      error: errorCreate,
      isSuccess: isSuccessCreate,
      isLoading: isLoadingCreate,
      reset: resetCreate,
    },
  ] = useCreateProposalMutation();

  const useCreateProposal = useCallback(() => {
    const doCreate = async data => {
      const proposalDto: any = {
        ...data,
        costLaborItems: data.costLaborItems?.map(item => ({
          ...item,
          costTypeId: item?.costType?.id,
          unitCount: Number(item?.unitCount),
          unitCost: Number(item?.unitCost),
          totalCost: Number(item?.totalCost),
        })),
      };

      delete proposalDto.costLabor;
      delete proposalDto.labor;
      doCrateProposal(proposalDto);
    };
    return [
      doCreate,
      {
        isError: isErrorCreate,
        error: errorCreate,
        isSuccess: isSuccessCreate,
        isLoading: isLoadingCreate,
        reset: resetCreate,
      },
    ];
  }, [
    doCrateProposal,
    isErrorCreate,
    isSuccessCreate,
    isLoadingCreate,
    errorCreate,
    resetCreate,
  ]);

  return (
    <>
      <ECWorkflowTemplate
        tabsAsLinks
        title={asset?.name ?? ''}
        summaryData={[
          {
            id: 'asset',
            label: 'Asset',
            data: asset?.name,
            type: SummaryFieldTypes.Text,
          },
          {
            id: 'state',
            label: 'State',
            data: asset?.status,
            type: SummaryFieldTypes.State,
          },
          {
            id: 'assetId',
            label: 'Asset ID',
            data: asset?.id ?? '',
            type: SummaryFieldTypes.Text,
          },
          {
            id: 'assetGroup',
            label: 'Asset Group',
            data: asset?.assetType?.name,
            type: SummaryFieldTypes.Text,
          },
          {
            id: 'location',
            label: 'Location',
            data: asset?.branch?.name,
            type: SummaryFieldTypes.Text,
          },
          {
            id: 'area',
            label: 'Area',
            data: asset?.area?.name,
            type: SummaryFieldTypes.Text,
          },
          {
            id: 'priority',
            label: 'Priority',
            data: asset?.priority?.name,
            type: SummaryFieldTypes.Text,
          },
          {
            id: 'nte',
            label: 'NTE',
            data: asset?.nte,
            type: SummaryFieldTypes.Currency,
          },
          {
            id: 'warrantyNte',
            label: 'Warranty NTE',
            data: asset?.warrantyNte,
            type: SummaryFieldTypes.Currency,
          },
        ]}
        warrantyBadgeType={assetWarranty?.[0]?.status}
        editConfig={{}}
        editFields={{}}
        detailsConfig={formElementsDetails.data.config}
        detailsFields={formElementsDetails.data.fields}
        detailsData={asset}
        imgSrc={assetProfileAttachment?.url}
        profileAttachment={assetProfileAttachment}
        moduleName={asset?.moduleName ?? ''}
        moduleId={id}
        disableTabsOnEdit={true}
        isEditAllowed={false}
        disableSidebarOnEdit={true}
        disableAditionalActionWhenEditing={true}
        additionalActions={
          asset?.branch?.status === 1 && asset?.branch?.onHold !== 1
            ? [
                <ECButton
                  variant="contained"
                  startIcon={<Add />}
                  onClick={handleOpen}
                >
                  {t('translation:managedAsset.createProposal')}
                </ECButton>,
              ]
            : []
        }
        additionalTabs={additionalTabs}
        asset={
          asset
            ? {
                href: asset?.id?.toString(),
                text: asset?.name,
              }
            : undefined
        }
        workOrder={
          asset?.workorderId && asset?.workorderName
            ? {
                href: asset.workorderId.toString(),
                text: asset.workorderName,
              }
            : undefined
        }
        showEditTitleStartAdornment
        showSkeletonLoader={isLoadingAsset}
      />
      <ECDrawerDetails open={isOpen} anchor="right" onClose={handleClose}>
        <ECBox display="flex" pt={StyleConstants.NAV_BAR_HEIGHT}>
          <ECEasyFormCreate
            useCreateMutation={useCreateProposal}
            formConfig={formElementsProposalManagedAsset.config}
            formFields={formElementsProposalManagedAsset.fields}
            existingData={existingData}
            costTableData={costTableData}
            onChange={handleOutputChange}
            onClose={handleClose}
            defaultRequestCategory={defaultRequestCategory}
            saveButtonDisabled={
              !defaultRequestCategory?.requestCategoryId || !selectedProblemId
            }
          />
        </ECBox>
      </ECDrawerDetails>
    </>
  );
}
